import * as React from 'react'

import styled, { css } from 'styled-components'

import { Button, ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { LinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'
import { useWizardDispatch, useWizardState } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

const linkStyleStrategy = css`
    display: grid;
    grid-template-areas: 'back next';
    grid-template-columns: 1fr 1fr;
    justify-items: end;
    @media (max-width: ${Breakpoint.Mobile}) {
        grid-template-columns: min-content 1fr;
    }
`
const buttonStyleStrategy = css`
    display: flex;
    justify-content: end;
    gap: ${Spacing.Large};
    a {
        text-decoration: none;
    }
`

const StepNavContainer = styled.div<StepNavContainerProps>`
    ${({ $useLinkStrategy }) => ($useLinkStrategy ? linkStyleStrategy : buttonStyleStrategy)}
    > * {
        text-transform: none;
    }
`
interface StepNavContainerProps {
    readonly $useLinkStrategy: boolean
}

const StyledLinkButton = styled(LinkButton)`
    text-decoration: underline;
    cursor: pointer;
    grid-area: back;
    padding-left: ${Spacing.Medium};
    padding-right: ${Spacing.Medium};
`

const NextButton = styled(Button)`
    grid-area: next;
`

export const StepNav = ({
    stepFwdButtonText,
    previousPageHandler,
    disableFwdButton,
    hideNextButton = false,
    stepBackButtonText = 'BACK',
    styleStepBackAsButton = false,
}: StepNavProps) => {
    const { previousPage } = useWizardDispatch()
    const { hasPrevious } = useWizardState()

    const onNextClick = () => {
        window.scrollTo(0, 0)
    }

    const onPrevClick = fn => {
        window.scrollTo(0, 0)
        if (fn) {
            fn()
        }
    }

    return (
        <StepNavContainer $useLinkStrategy={!styleStepBackAsButton}>
            {hasPrevious && (
                <StyledLinkButton
                    buttonStyle={styleStepBackAsButton ? ButtonStyleType.Secondary : ButtonStyleType.Highlight}
                    dataTestId="step-back"
                    onClick={() => onPrevClick(previousPageHandler ? previousPageHandler : previousPage)}
                >
                    {stepBackButtonText}
                </StyledLinkButton>
            )}
            {!hideNextButton && (
                <NextButton disabled={disableFwdButton} onClick={onNextClick} dataTestId="step-forward" type="submit">
                    {disableFwdButton ? 'Submitting' : stepFwdButtonText}
                </NextButton>
            )}
        </StepNavContainer>
    )
}

interface StepNavProps {
    readonly previousPageHandler?: () => void
    readonly stepFwdButtonText?: string
    readonly stepBackButtonText?: string
    readonly disableFwdButton?: boolean
    readonly hideNextButton?: boolean
    readonly styleStepBackAsButton?: boolean
}
