import { MoneyFormat } from '@igs-web/common-utilities/utilities/currency-utilities'

export interface CompanyFeatures {
    readonly displayPlanAtAGlanceTerminationFee: boolean
    readonly displayPlanAtAGlanceProgressCircle: boolean
    readonly displayMoreTimeToPay: boolean
    readonly displayUtilityInCustomerInformation: boolean
    readonly displayDashboardProspects: boolean
    readonly canEnrollInHeat: boolean
    readonly hasSolarMyAccount: boolean
    readonly canChangeNameFromProfile: boolean
    readonly canMyAccountRateChange: boolean
    readonly canMyAccountRenew: boolean
    readonly canAddAndRemoveAccounts: boolean
    readonly preferRoutingOverHref: boolean
    readonly displayPaymentHistoryChart: boolean
    readonly hasOnlineChat: boolean
    readonly displayLoginPageSubheader: boolean
    readonly canMakeServiceRequest: boolean
    readonly useDashboardScrollIntoView: boolean
    readonly canEnrollOnline: boolean
    readonly canRenewAnyStatus: boolean
    readonly displayCreditCheckDisclosure: boolean
    readonly displayFieldSpecificAddressErrors: boolean
    readonly canManagePaymentsAndBilling: boolean
    readonly forceDisplayUsageTableRateInDollars: boolean
    readonly variableRateFormatDecimalPlaces: number
    readonly variablRateFormatMoneyPrecision: number
    readonly variablRateMoneyFormat: MoneyFormat
    readonly useLegacydata: boolean
    readonly dataStartDate: Date
    readonly displayLegacyDataMessage: boolean
    readonly displayDataNotAvailableMessage: boolean
    readonly useBillingAccounts: boolean
    readonly canCreateOnlinePaymentExtension: boolean
}

export const defaultCompanyFeatures: CompanyFeatures = {
    displayPlanAtAGlanceTerminationFee: true,
    displayMoreTimeToPay: false,
    displayUtilityInCustomerInformation: true,
    displayDashboardProspects: true,
    canEnrollInHeat: false,
    canChangeNameFromProfile: true,
    canMyAccountRateChange: false,
    canMyAccountRenew: true,
    hasSolarMyAccount: true,
    hasOnlineChat: true,
    displayPlanAtAGlanceProgressCircle: true,
    canAddAndRemoveAccounts: true,
    preferRoutingOverHref: false,
    displayPaymentHistoryChart: false,
    displayLoginPageSubheader: true,
    canMakeServiceRequest: true,
    useDashboardScrollIntoView: true,
    canEnrollOnline: true,
    canRenewAnyStatus: true,
    displayCreditCheckDisclosure: false,
    displayFieldSpecificAddressErrors: false,
    canManagePaymentsAndBilling: false,
    forceDisplayUsageTableRateInDollars: false,
    variableRateFormatDecimalPlaces: 2,
    variablRateFormatMoneyPrecision: 2,
    variablRateMoneyFormat: MoneyFormat.Money,
    useLegacydata: false,
    dataStartDate: new Date('Janaury 01, 1970'),
    displayLegacyDataMessage: false,
    displayDataNotAvailableMessage: true,
    useBillingAccounts: false,
    canCreateOnlinePaymentExtension: false,
}
