import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Action } from 'redux'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

import { CommonReduxState } from '../common-redux'
import { userActions, UserSelectors } from '../user/user-redux'
import { CommunicationPreferenceFields, CommunicationPreferencesForm } from './forms/communication-preferences-form'

export const CommunicationPreferencesSection = (): JSX.Element => {
    const isPaperless = useSelector((store: CommonReduxState) => UserSelectors.selectIsPaperless(store))
    const isIndustrial = useSelector((store: CommonReduxState) => UserSelectors.selectIsCommercialIndustrial(store))
    const dispatch = useDispatch()
    const loadUserProfile = (): Action => dispatch(userActions.loadUser())

    const updatePreferences = async (submission: CommunicationPreferenceFields) => {
        try {
            await customerInfoApiClient.updateCommunicationPreferences(submission.isPaperless, { showGlobalLoader: false })
            loadUserProfile()
        } catch (e) {
            throw new Error('Save changes failed')
        }
    }

    return (
        <div className="communication-preferences row ">
            <div className="communication-preferences-form col-12">
                {isIndustrial ? (
                    <IndustrialCommnicationMessage />
                ) : (
                    <CommunicationPreferencesForm onSubmit={updatePreferences} initialValues={{ isPaperless }} />
                )}
            </div>
        </div>
    )
}

const IndustrialCommnicationMessage = () => {
    const { phoneNumber, displayName } = useCompany()
    return (
        <p>
            To setup paperless billing please contact {displayName} at <PhoneNumberLink phoneNumber={phoneNumber.commecialSupport} />.
        </p>
    )
}
