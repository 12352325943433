import styled from 'styled-components'

import { ButtonSection } from '@igs-web/common-ui-components/_atoms/buttons/button-section'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { smWidth } from '@igs-web/common-ui-components/styles/theme'

export const AccountContainer = styled.div`
    display: grid;
    grid-gap: ${Spacing.Medium};
    text-align: center;
    align-self: center;
`
export const TermContainer = styled.div`
    grid-area: term;
    width: clamp(100%, 300px);
`
export const ActiveFooter = styled.div`
    grid-area: footer;
    border-top: 1px solid var(--accent);
    font-size: calc(0.6 * var(--default-font-size));
    text-align: center;
    padding-top: ${Spacing.Medium};
`
export const ProductTitle = styled.div`
    grid-area: productTitle;
    font-weight: bold;
    font-size: var(--default-font-size);
`
export const ChartContainer = styled.div`
    grid-area: chart;
    align-self: center;
    justify-self: center;
`

export const StyledButtonSection = styled(ButtonSection)`
    grid-area: rateButton;
`

export const ActiveAccountContainer = styled(AccountContainer)<ContainerProps>`
    text-align: left;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        'planRate chart'
        'productTitle chart'
        ${({ $showTerm }) => ($showTerm ? '"term term"' : '')}
        'rateButton rateButton'
        ${({ $showFooter }) => ($showFooter ? '"footer footer"' : '')};

    @media (max-width: ${smWidth}) {
        text-align: center;
        grid-template-columns: 1fr;
        grid-template-areas:
            'planRate'
            'productTitle'
            'chart'
            ${({ $showTerm }) => ($showTerm ? '"term"' : '')}
            'rateButton'
            ${({ $showFooter }) => ($showFooter ? '"footer"' : '')};
    }
`

interface ContainerProps {
    readonly $showTerm?: boolean
    readonly $showFooter?: boolean
    readonly $isHomeWarranty?: boolean
}
