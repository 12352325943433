import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { myAccountActions, MyAccountSelectors } from '@igs-web/common-components/domain/myaccount-redux'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { UserProfileAccount, UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'
import { Header4 } from '@igs-web/common-ui-components/_atoms/typography'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { getFormattedAddress } from '@igs-web/common-utilities/utilities/address-utilities'

import { ServiceAddressForm } from './service-address-form'

export const SingleAddress = styled.span`
    font-weight: ${FontWeight.Default};
`
export const AddressSelectorWrapper = styled.div`
    text-align: center;
    margin-bottom: ${Spacing.ExtraLarge};
`
const ServiceAddressDisplay = ({ serviceAddress, className, hideTitleOnSingle }: ServiceAddressDisplayProps): JSX.Element => (
    <div className={className} data-testid="service-address-display">
        {!hideTitleOnSingle && <Header4>Service Address:</Header4>}
        <SingleAddress>{getFormattedAddress(serviceAddress)}</SingleAddress>
    </div>
)
interface ServiceAddressDisplayProps {
    readonly serviceAddress: UserProfileServiceAddress
    readonly className?: string
    readonly hideTitleOnSingle: boolean
}

export const ServiceAddressSelectorDisplay = ({
    dropdownHeaderText,
    onSelect,
    className,
    serviceAddresses,
    selectedServiceAddressKey,
    accounts,
    hideTitleOnSingle = false,
}: ServiceAddressSelectorDisplayProps) => {
    switch (serviceAddresses.length) {
        case 0:
            return (
                <AddressSelectorWrapper className={className}>
                    <Header4>There are no accounts tied to this MyAccount:</Header4>
                </AddressSelectorWrapper>
            )
        case 1:
            return <ServiceAddressDisplay className={className} serviceAddress={serviceAddresses[0]} hideTitleOnSingle={hideTitleOnSingle} />
        default:
            return (
                <AddressSelectorWrapper className={className}>
                    <Header4>{dropdownHeaderText || 'Please select a service address in the dropdown below:'}</Header4>
                    <ServiceAddressForm
                        serviceAddresses={serviceAddresses}
                        selectedServiceAddressKey={selectedServiceAddressKey}
                        onSelect={onSelect}
                        accounts={accounts}
                    />
                </AddressSelectorWrapper>
            )
    }
}

export const ServiceAddressSelector = ({ dropdownHeaderText, selectAddress, className }: Props) => {
    const dispatch = useDispatch()
    const selectServiceAddress = React.useCallback((serviceAddressKey: string) => dispatch(myAccountActions.selectServiceAddress(serviceAddressKey)), [
        dispatch,
    ])
    const accounts = useSelector(UserSelectors.selectPartyAccounts)
    const serviceAddresses = useSelector(UserSelectors.selectUserServiceAddresses)
    const selectedServiceAddress = useSelector(MyAccountSelectors.selectSelectedServiceAddress)

    React.useEffect(() => {
        if (serviceAddresses.length === 1) {
            if (selectedServiceAddress && selectedServiceAddress.addressHash === serviceAddresses[0].addressHash) {
                return
            }

            selectServiceAddress(serviceAddresses[0].serviceAddressKey)
            if (selectAddress) {
                selectAddress(serviceAddresses[0].serviceAddressKey)
            }
        }
    }, [serviceAddresses, selectedServiceAddress, selectServiceAddress, selectAddress])

    const onSelect = (address: UserProfileServiceAddress) => {
        if (selectAddress) {
            selectAddress(address.serviceAddressKey)
        }
        selectServiceAddress(address.serviceAddressKey)
    }

    return (
        <ServiceAddressSelectorDisplay
            onSelect={onSelect}
            serviceAddresses={serviceAddresses}
            selectedServiceAddressKey={selectedServiceAddress && selectedServiceAddress.serviceAddressKey}
            className={className}
            dropdownHeaderText={dropdownHeaderText}
            accounts={accounts}
        />
    )
}
export interface ServiceAddressSelectorDisplayProps {
    readonly accounts?: ReadonlyArray<UserProfileAccount>
    readonly serviceAddresses: ReadonlyArray<UserProfileServiceAddress>
    readonly selectedServiceAddressKey?: string
    readonly onSelect: (address: UserProfileServiceAddress) => void
    readonly className?: string
    readonly dropdownHeaderText?: string
    readonly hideTitleOnSingle?: boolean
}

interface Props {
    readonly dropdownHeaderText?: string
    readonly selectAddress?: (addressKey: string) => void
    readonly className?: string
}
