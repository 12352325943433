import * as React from 'react'
import styled from 'styled-components'

const FaqContainer = styled.div`
    & > p {
        text-align: left;
    }
`

export const FaqEntry = ({ answer }: FaqEntryProps): JSX.Element => <FaqContainer>{React.isValidElement(answer) ? answer : <p>{answer}</p>}</FaqContainer>

interface FaqEntryProps {
    readonly answer: React.ReactNode | string
}
