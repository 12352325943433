import { Address } from '@igs-web/common-models/models/address'
import { ContactModel } from '@igs-web/common-models/models/contact-model'
import { EnrollmentModel, ShoppingCartItem } from '@igs-web/common-models/models/enrollment-model'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { PaymentMethodModel } from '@igs-web/common-models/models/payment-model'
import { createAction } from '@igs-web/common-utilities/utilities/reducer-utilities'

export const enrollmentModelActionTypes = {
    CLEAR_SERVICE_ADDRESS: '[enrollment] CLEAR_SERVICE_ADDRESS',
    UPDATE_SERVICE_ADDRESS: '[enrollment] UPDATE_SERVICE_ADDRESS',
    UPDATE_BILLING_ADDRESS: '[enrollment] UPDATE_BILLING_ADDRESS',
    UPDATE_SHIPPING_ADDRESS: '[enrollment] UPDATE_SHIPPING_ADDRESS',
    UPDATE_ENROLLMENT_MODEL: '[enrollment] UPDATE_ENROLLMENT_MODEL',
    SET_PAPERLESS: '[enrollment] SET_PAPERLESS',
    UPDATE_DEFAULT_CONTACT: '[enrollment] UPDATE_DEFAULT_CONTACT',
    UPDATE_SHOPPING_CART_ITEM: '[enrollment] UPDATE_SHOPPING_CART_ITEM',
    UPDATE_PAYMENT_MODEL: '[enrollment] UPDATE_PAYMENT_MODEL',
    UPDATE_REALTOR_PROMO: '[enrollment] UPDATE_REALTOR_PROMO',
    CLEAR_AFTER_SUBMIT: '[enrollment] CLEAR_AFTER_SUBMIT',
    UPDATE_MARKET_SWITCH: '[enrollment] UPDATE_MARKET_SWITCH',
    INITIALIZE: '[enrollment] INITIALIZE',
}

export const enrollmentModelActions = {
    clearServiceAddress: createAction(enrollmentModelActionTypes.CLEAR_SERVICE_ADDRESS),
    updateServiceAddress: createAction<UpdateServiceAddressRequest>(enrollmentModelActionTypes.UPDATE_SERVICE_ADDRESS),
    updateBillingAddress: createAction<Address | undefined>(enrollmentModelActionTypes.UPDATE_BILLING_ADDRESS),
    updateShippingAddress: createAction<Address | undefined>(enrollmentModelActionTypes.UPDATE_SHIPPING_ADDRESS),
    updatePaymentModel: createAction<PaymentModelUpdateRequest>(enrollmentModelActionTypes.UPDATE_PAYMENT_MODEL),
    updateEnrollmentModel: createAction<Partial<EnrollmentModel>>(enrollmentModelActionTypes.UPDATE_ENROLLMENT_MODEL),
    setPaperless: createAction<boolean>(enrollmentModelActionTypes.SET_PAPERLESS),
    updateDefaultContact: createAction<Partial<ContactModel>>(enrollmentModelActionTypes.UPDATE_DEFAULT_CONTACT),
    updateShoppingCartItem: createAction<ShoppingCartUpdateRequest>(enrollmentModelActionTypes.UPDATE_SHOPPING_CART_ITEM),
    updateRealtorPromo: createAction<RealtorPromo>(enrollmentModelActionTypes.UPDATE_REALTOR_PROMO),
    clearAfterSubmit: createAction(enrollmentModelActionTypes.CLEAR_AFTER_SUBMIT),
    initialize: createAction<EnrollmentInitializationModel>(enrollmentModelActionTypes.INITIALIZE),
}

export interface RealtorPromo {
    readonly realtorName: string
    readonly realtorId?: string
}

export interface PaymentModelUpdateRequest {
    readonly paymentModel: PaymentMethodModel
    readonly shoppingCartItem: ShoppingCartItem
}

export interface UpdateServiceAddressRequest {
    readonly address: Address & { readonly addressHash?: string }
}

export interface ShoppingCartUpdateRequest extends Partial<ShoppingCartItem> {
    readonly lineOfBusinessCode: LineOfBusinessCode
}

export interface EnrollmentInitializationModel {
    readonly agentCode?: string
    readonly referralId?: string
}
