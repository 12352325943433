import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const CircleServiceAddress = ({ altText = 'Service Address', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} altText={altText} width="50" height="50" viewBox="0 0 50 50">
        <g id="Group_537" data-name="Group 537" transform="translate(4838 -3355)">
            <g id="Ellipse_208" data-name="Ellipse 208" transform="translate(-4838 3355)" className="icon-border-circle" strokeWidth="3">
                <circle cx="25" cy="25" r="25" stroke="none" />
                <circle cx="25" cy="25" r="23.5" fill="none" />
            </g>
            <path id="map-marker-alt-solid" d="M7.956,23.168C1.246,13.44,0,12.442,0,8.867a8.867,8.867,0,0,1,17.734,0c0,3.575-1.246,4.573-7.956,14.3a1.109,1.109,0,0,1-1.823,0Zm.911-10.606A3.695,3.695,0,1,0,5.172,8.867,3.694,3.694,0,0,0,8.867,12.561Z" transform="translate(-4822.181 3368.178)" className="icon-core" />
        </g>
    </SvgIcon>
)

export default CircleServiceAddress