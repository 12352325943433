import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { ElectronicCommunicationConsentNotice } from '@igs-web/common-components/domain/legal/electronic-communication-consent-notice'
import { CreditCheckLayout } from '@igs-web/common-components/domain/payment-capture/credit-card-capture-form'
import { BasicPaymentCaptureForm } from '@igs-web/common-components/domain/payment-capture/payment-capture-form-basic'
import { LineOfBusinessCode, PaymentMethodModel, PaymentType } from '@igs-web/common-models/models'
import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { BoxedErrorAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { LegalCheckBox } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { GridSpacer, commonGridCss } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText, InfoTextType } from '@igs-web/common-ui-components/_molecules/info-text'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

const FormWrapper = styled.form`
    ${commonGridCss}
    button {
        justify-self: right;
    }
    && ${BoxedErrorAlert} {
        grid-column: span 1;
    }
    @media (min-width: ${Breakpoint.Tablet}) {
        ${CreditCheckLayout} {
            --cc-grid-template-areas: 'name name' 'number number' 'type type' 'month year' 'cvv tooltip' 'zip blank';
            --cc-grid-template-columns: 1fr 1fr;
        }
    }
`

const PastDueLabel = () => <span>I authorize SCANA Home Warranty to collect any past due balance on my account with this new payment method</span>

interface PaymentFormData {
    readonly acceptedTerms: boolean
    readonly authorizeForPastDue: boolean
    readonly payment: PaymentMethodModel
}

export const UpdatePaymentForm = ({ accountId, onSuccess }: Props) => {
    const company = useCompany()
    const [submissionError, setSubmissionError] = React.useState(false)
    const formRef = React.useRef<HTMLFormElement>(null)
    const methods = useForm({
        mode: 'onChange',
    })
    const onSubmit = async (data: PaymentFormData) => {
        const response = await customerInfoApiClient.updatePaymentProfile(accountId, data.payment)
        if (response?.isSuccessful) {
            onSuccess(accountId)
            setSubmissionError(false)
            window.scrollTo(0, 0)
        } else {
            formRef.current?.scrollIntoView({ behavior: 'auto', block: 'start' })
            setSubmissionError(true)
        }
    }

    const {
        handleSubmit,
        register,
        watch,
        formState: { errors },
    } = methods

    const formKey = '[payment].'
    const paymentType = watch(`${formKey}paymentType`)
    return (
        <FormProvider {...methods}>
            <FormWrapper ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                {submissionError && (
                    <>
                        <InfoText $infoTextType={InfoTextType.Error}>
                            Something went wrong while processing your request. Please call us {company.businessHours.homwWarrantyAbbreviated} at{' '}
                            <PhoneNumberLink phoneNumber={company.phoneNumber.homeWarranty} />.
                        </InfoText>
                        <GridSpacer spacing={Spacing.Large} />
                    </>
                )}
                <BasicPaymentCaptureForm
                    formKey={formKey}
                    idPrefix="payment"
                    paymentTypes={[PaymentType.CreditCard, PaymentType.Ach]}
                    defaultPaymentOptionType={undefined}
                    isRecurring={true}
                />
                {paymentType && (
                    <>
                        <LegalCheckBox
                            {...register('authorizeForPastDue', { required: 'Payment Authorization Required' })}
                            defaultChecked={false}
                            dataTestId="authorizeForPastDueCheckbox"
                            id="authorizeForPastDue"
                            label={<PastDueLabel />}
                            $error={!!errors.authorizeForPastDue?.message}
                        />

                        <ElectronicCommunicationConsentNotice lineOfBusinessCode={LineOfBusinessCode.HomeWarranty} />
                    </>
                )}
                <Button data-testid="update-payment-form-submit-btn" className="form-button" type="submit">
                    Update Payment
                </Button>
            </FormWrapper>
        </FormProvider>
    )
}

interface Props {
    readonly accountId: number
    readonly onSuccess: (accountId: number) => void
}
