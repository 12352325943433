import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import { get } from 'lodash-es'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { StateCode, states } from '@igs-web/common-models/constants/states'
import { Address } from '@igs-web/common-models/models/address'
import { zipCodePattern } from '@igs-web/common-ui-components/_atoms/react-hooks-helpers'
import { Input, Select, SelectOption } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { Grid1fr1fr } from '@igs-web/common-ui-components/_molecules/grid-layout'

export const AddressForm = ({ address, labelPrefix, idPrefix, disableZip, formKey, readOnlyState, readOnly = false }: AddressFormProps) => {
    const {
        register,
        formState: { errors },
    } = useFormContext()

    const { enrollment, features } = useCompany()

    const address1Field = `${formKey}address1`
    const address1FieldErrors = get(errors, `${address1Field}`)?.message?.toString()
    const address2Field = `${formKey}address2`
    const cityField = `${formKey}city`
    const cityFieldErrors = get(errors, `${cityField}`)?.message?.toString()
    const stateField = `${formKey}state`
    const stateFieldErrors = get(errors, `${stateField}`)?.message?.toString()
    const zipField = `${formKey}zipCode`
    const zipFieldErrors = get(errors, `${zipField}`)?.message?.toString()

    const stateValue = address?.state?.toString() ?? enrollment.defaultState?.toString() ?? ''
    const address1RequiredMessage = features.displayFieldSpecificAddressErrors ? 'Please provide address.' : 'Required'
    const cityRequiredMessage = features.displayFieldSpecificAddressErrors ? 'Please provide city.' : 'Required'
    const stateRequiredMessage = features.displayFieldSpecificAddressErrors ? 'Please provide state.' : 'Required'
    const zipCodeRequiredMessage = features.displayFieldSpecificAddressErrors ? 'Please provide zip code.' : 'Required'

    return (
        <>
            <Input
                {...register(address1Field, { required: address1RequiredMessage, maxLength: 100, shouldUnregister: true })}
                dataTestId={`${idPrefix}line-1`}
                defaultValue={address?.address1 ?? ''}
                label={`${labelPrefix}Address`}
                autoComplete="address-line1"
                error={address1FieldErrors}
                hideHelperText={!address1FieldErrors}
                maxLength={100}
                readOnly={readOnly}
            />
            <Input
                {...register(address2Field, { shouldUnregister: true, maxLength: 100 })}
                dataTestId={`${idPrefix}line-2`}
                defaultValue={address?.address2 ?? ''}
                label={`${labelPrefix}Address Line 2`}
                autoComplete="address-line2"
                maxLength={100}
                readOnly={readOnly}
                hideHelperText={true}
            />
            <Grid1fr1fr>
                <Input
                    {...register(cityField, { required: cityRequiredMessage, maxLength: 100, shouldUnregister: true })}
                    dataTestId={`${idPrefix}city`}
                    defaultValue={address?.city ?? ''}
                    label={`${labelPrefix}City`}
                    autoComplete="address-level2"
                    error={cityFieldErrors}
                    hideHelperText={!cityFieldErrors}
                    maxLength={100}
                    readOnly={readOnly}
                />
                {readOnly || readOnlyState ? (
                    <Input
                        {...register(stateField, { shouldUnregister: true })}
                        dataTestId={`${idPrefix}state`}
                        defaultValue={address?.state?.toString() ?? readOnlyState ?? enrollment.defaultState}
                        label={`${labelPrefix}State`}
                        hideHelperText={true}
                        readOnly={true}
                    />
                ) : (
                    <Select
                        {...register(stateField, { required: stateRequiredMessage, shouldUnregister: true })}
                        dataTestId={`${idPrefix}state`}
                        defaultValue={stateValue}
                        label={`${labelPrefix}State`}
                        error={stateFieldErrors}
                        autoComplete="state"
                        hideHelperText={!stateFieldErrors}
                    >
                        {states.map(s => (
                            <SelectOption key={s} dataTestId={idPrefix + s} value={s}>
                                {s}
                            </SelectOption>
                        ))}
                    </Select>
                )}
            </Grid1fr1fr>
            <Input
                {...register(zipField, {
                    required: zipCodeRequiredMessage,
                    pattern: zipCodePattern,
                    shouldUnregister: true,
                    setValueAs: (v: string) => v.substring(0, 5),
                })}
                label={`${labelPrefix}ZIP Code`}
                defaultValue={address?.zipCode ?? ''}
                dataTestId={`${idPrefix}zip`}
                readOnly={disableZip || readOnly}
                error={zipFieldErrors}
                hideHelperText={!zipFieldErrors}
                maxLength={5}
                inputMode="numeric"
                autoComplete="zip-code"
            />
        </>
    )
}

interface AddressFormProps {
    readonly address?: Address
    readonly idPrefix: string
    readonly labelPrefix: string
    readonly disableZip: boolean
    readonly readOnlyState?: StateCode
    readonly formKey: string
    readonly readOnly?: boolean
}
