import * as React from 'react'
import { useSelector } from 'react-redux'

import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { useBreakpoints } from '@igs-web/common-components/hooks/use-breakpoints'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'

import { LinkTab, LinkTabs } from 'molecules/tabs/LinkTabs'

import * as Routes from 'domain/routes'

export enum LobTab {
    NaturalGas = 'naturalgas',
    HomeWarranty = 'homewarranty',
}

const getTabs = (activeTab: LobTab): ReadonlyArray<LinkTab> => [
    {
        key: LobTab.NaturalGas,
        route: Routes.AccountSummary,
        label: 'Natural Gas',
        isActive: activeTab === LobTab.NaturalGas,
    },
    {
        key: LobTab.HomeWarranty,
        route: Routes.HwAccountSummary,
        label: 'Home Warranty',
        isActive: activeTab === LobTab.HomeWarranty,
    },
]

export const LobTabs = ({ activeTab }: Props) => {
    const { lessThanOrEqualTo } = useBreakpoints()
    const isMobile = lessThanOrEqualTo(Breakpoint.Tablet)
    const userProfile = useSelector(UserSelectors.selectProfile)

    if (isMobile || (!userProfile?.isEligibleForScanaHW && !userProfile?.hasScanaHW)) {
        return <></>
    }
    const tabs = getTabs(activeTab)
    return <LinkTabs linkTabs={tabs} />
}

interface Props {
    readonly activeTab: LobTab
}
