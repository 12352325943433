import * as React from 'react'

export enum Verbiage {
    billsTableAmountDueHeaderText,
    usageTableHeadersQuantity,
    usageTableHeadersChargesPerUnit,
    paymentButtonText,
    paymentNavigationText,
    paperlessCommunicationMessage,
    creditCheckVerificationMessage,
    changeEmail,
    loginUserNotFound,
    tooManyLoginAttempts,
}

export const CompanyVerbiage = ({ verbiage }: Props) => {
    return verbiage
}
interface Props {
    readonly verbiage: React.ReactNode | React.ReactElement | React.JSX.Element
}

export type CompanyVerbiage = Partial<Record<Verbiage, React.ReactNode | React.ReactElement>>
