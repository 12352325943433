export enum OfferAccountType {
    Residential = 'R',
    Commercial = 'C',
    ChoiceIndustrial = 'I',
}
export enum AccountTypeName {
    Residential = 'Residential',
    Commercial = 'Commercial', //Small Comm
    ChoiceIndustrial = 'Choice Industrial',
}
