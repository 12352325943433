import { OfferModel } from '@igs-web/common-models/models'
import { createAction } from '@igs-web/common-utilities/utilities/reducer-utilities'

import { OffersRequest, SelectOfferParameters } from './offers-context-models'

export const offersActionTypes = {
    LOAD_OFFERS: '[offers] LOAD_OFFERS',
    SET_AVAILABLE_OFFERS: '[offers] SET_AVAILABLE_OFFERS',
    SELECT_OFFER: '[offers] SELECT_OFFER',
    DESELECT_OFFER: '[offers] DESELECT_OFFER',
    CLEAR_OFFER_STATE: '[offers] CLEAR_STATE',
}

export const offersActionCreators = {
    loadOffers: createAction<OffersRequest>(offersActionTypes.LOAD_OFFERS),
    setAvailableOffers: createAction<ReadonlyArray<OfferModel>>(offersActionTypes.SET_AVAILABLE_OFFERS),
    selectOffer: createAction<OfferModel | null>(offersActionTypes.SELECT_OFFER),
    deselectOffer: createAction(offersActionTypes.DESELECT_OFFER),
    clearOfferState: createAction(offersActionTypes.CLEAR_OFFER_STATE),
}

export interface IOffersActions {
    readonly loadOffers: (offersRequest: OffersRequest) => Promise<void>
    readonly selectOffer: (selectOfferParameters: SelectOfferParameters) => void
    readonly deselectOffer: (navigateToRoute?: string) => void
    readonly clearOfferState: () => void
}
