import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const IgsCircleIcon = ({ altText = 'IGS', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M5.162 10.588h3.084v10.86h-3.084v-10.86z" />
        <path d="M18.871 13.57l0.47-2.717c-1.084-0.353-2.332-0.556-3.627-0.556-0.487 0-0.966 0.029-1.438 0.085l0.057-0.005c-2.702 0.316-4.736 2.842-4.685 5.874 0.051 3.143 1.931 5.14 4.685 5.426 0.296 0.024 0.64 0.038 0.988 0.038 1.408 0 2.763-0.229 4.029-0.652l-0.090 0.026v-5.61h-3.106v3.363c-0.231 0.043-0.496 0.068-0.767 0.068s-0.537-0.025-0.794-0.072l0.027 0.004c-0.573-0.154-1.799-0.646-1.726-2.864s1.564-2.834 2.937-2.937c1.1 0.040 2.139 0.241 3.114 0.581l-0.074-0.023z" />
        <path d="M26.728 13.070l0.125-2.57c-0.602-0.125-1.3-0.205-2.014-0.22l-0.013-0c-2.203-0.059-4.567 1.19-4.531 3.583 0 2.049 1.85 2.981 2.599 3.231 0.631 0.213 1.579 0.609 1.469 1.292s-0.83 0.734-1.469 0.734c-0.030 0.001-0.064 0.001-0.099 0.001-0.7 0-1.363-0.16-1.954-0.446l0.027 0.012-0.125 2.695s0.734 0.404 2.687 0.279c1.828-0.11 4.2-1.131 4.31-3.238 0.088-1.637-0.367-2.394-1.212-2.937-1.028-0.676-3.018-1.094-2.937-1.887s0.778-0.815 1.3-0.793c0.679 0.024 1.322 0.135 1.933 0.322l-0.053-0.014z" />
        <path d="M27.345 10.933c0 0.199 0.161 0.36 0.36 0.36s0.36-0.161 0.36-0.36c0-0.199-0.161-0.36-0.36-0.36v0c-0.199 0-0.36 0.161-0.36 0.36v0zM28.079 10.933c0 0.227-0.184 0.411-0.411 0.411s-0.411-0.184-0.411-0.411c0-0.227 0.184-0.411 0.411-0.411v0c0.227 0 0.411 0.184 0.411 0.411v0z" />
        <path d="M27.616 10.889h0.066c0.066 0 0.11 0 0.11-0.081s-0.037-0.073-0.103-0.073h-0.073zM27.543 10.669h0.125c0.103 0 0.206 0 0.206 0.139-0.006 0.060-0.051 0.108-0.109 0.117l-0.001 0c0.024 0.009 0.042 0.028 0.051 0.051l0 0.001 0.095 0.206h-0.081l-0.081-0.169c0-0.044-0.044-0.044-0.088-0.044h-0.044v0.228h-0.073z" />
        <path d="M15.985 0c-0.007 0-0.014 0-0.022 0-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-0.002 0-0.005 0-0.007 0-8.827-7.152-15.984-15.977-15.993h-0.001zM15.985 30.458c-0.004 0-0.010 0-0.015 0-7.985 0-14.458-6.473-14.458-14.458s6.473-14.458 14.458-14.458c7.985 0 14.458 6.473 14.458 14.458v0c0 0.004 0 0.010 0 0.015 0 7.977-6.466 14.443-14.443 14.443v0z" />
    </SvgIcon>
)
export default IgsCircleIcon
