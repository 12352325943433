import * as React from 'react'
import styled from 'styled-components'

import { ColorwayContainer, ColorwayType } from '@igs-web/common-ui-components/_molecules/theming/colorway-container'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

const Container = styled(ColorwayContainer)`
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    color: var(--body-color);
    background-color: var(--background);
    padding: ${Spacing.Medium};
    align-items: baseline;
    a {
        color: var(--body-color);
        font-weight: normal;
        font-size: var(--font-size-small);
        text-decoration: underline;
        &:hover {
            color: var(--link-hover-text);
        }
    }
`
export const AccountDetailsCardHeader = ({ children }: Props) => <Container colorwayType={ColorwayType.DetailsCard}>{children}</Container>

interface Props {
    readonly children?: React.ReactNode
}
