import * as React from 'react'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { Address } from '@igs-web/common-models/models/address'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { UserProfileAccount } from '@igs-web/common-models/models/user-profile-model'
import { EllipsisOverflowText } from '@igs-web/common-ui-components/_atoms/ellipsis-overflow-text'
import { AddressDisplay } from '@igs-web/common-ui-components/_molecules/address/address-display'
import { DetailList, DetailListItem } from '@igs-web/common-ui-components/_molecules/detail-list'
import { formatPhoneNumberForDisplay } from '@igs-web/common-utilities/utilities/phone-utilities'

import {
    AccountDetailBase,
    CommodityAccountDetails,
    HwAccountDetails,
    mapToCommodityAccountDetails,
    mapToHwAccountDetails,
} from '../models/account-detail-models'

export const AccountDetailBaseListItems = ({ accountDetails, omitContactInfo = false }: AccountDetailBaseListItemsProps): JSX.Element => {
    const { customerName, serviceAddress, customerEmailAddress, customerPhoneNumber } = accountDetails
    return (
        <>
            {customerName.length > 0 && <DetailListItem title="Customer Name:">{customerName}</DetailListItem>}
            <DetailListItem title="Service Address:">{serviceAddress && <AddressDisplay address={serviceAddress} />}</DetailListItem>
            {!omitContactInfo && (
                <>
                    <DetailListItem title="Email:">
                        <EllipsisOverflowText $width="22ch" $showHover={false}>
                            {customerEmailAddress || ''}
                        </EllipsisOverflowText>
                    </DetailListItem>
                    <DetailListItem title="Phone Number:">{formatPhoneNumberForDisplay(customerPhoneNumber || '')}</DetailListItem>
                </>
            )}
        </>
    )
}
interface AccountDetailBaseListItemsProps {
    readonly accountDetails: AccountDetailBase
    readonly omitContactInfo?: boolean
}

export const CommodityAccountDetailList = ({ accountDetails, ...rest }: CommodityAccountDetailsListProps): JSX.Element => {
    const { features } = useCompany()
    return (
        <DetailList>
            {features.displayUtilityInCustomerInformation && (
                <>
                    <DetailListItem title="Utility Name:">{accountDetails.utilityName || ''}</DetailListItem>
                    <DetailListItem title={(accountDetails.accountNumberLabel || 'Utility Account Number') + ':'}>
                        {accountDetails.accountNumber || ''}
                    </DetailListItem>
                    {accountDetails.meterNumber && (
                        <DetailListItem title={(accountDetails.meterNumberLabel || 'Meter Number') + ':'}>{accountDetails.meterNumber}</DetailListItem>
                    )}
                </>
            )}
            {accountDetails.businessName.length > 0 && <DetailListItem title="Business Name:">{accountDetails.businessName}</DetailListItem>}
            <AccountDetailBaseListItems accountDetails={accountDetails} {...rest} />
        </DetailList>
    )
}
interface CommodityAccountDetailsListProps extends AccountDetailBaseListItemsProps {
    readonly accountDetails: CommodityAccountDetails
}

export const HwAccountDetailList = ({ accountDetails, omitPlanName, ...rest }: HwAccountDetailsListItemsProps): JSX.Element => (
    <DetailList>
        {!omitPlanName && <DetailListItem title="Protection Plan:">{accountDetails.protectionPlan || 'Custom Plan'}</DetailListItem>}
        <DetailListItem title="Customer Number:">{accountDetails.partyNumber}</DetailListItem>
        <AccountDetailBaseListItems accountDetails={accountDetails} {...rest} />
    </DetailList>
)
interface HwAccountDetailsListItemsProps extends AccountDetailBaseListItemsProps {
    readonly accountDetails: HwAccountDetails
    readonly omitPlanName?: boolean
}

export const AccountDetailList = ({ account, serviceAddress, omitContactInfo = false, omitPlanName = false }: AccountDetailListProps): JSX.Element => {
    const isHomeWarranty = account.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty

    return isHomeWarranty ? (
        <HwAccountDetailList accountDetails={mapToHwAccountDetails(account, serviceAddress)} omitContactInfo={omitContactInfo} omitPlanName={omitPlanName} />
    ) : (
        <CommodityAccountDetailList accountDetails={mapToCommodityAccountDetails(account, serviceAddress)} omitContactInfo={omitContactInfo} />
    )
}
interface AccountDetailListProps {
    readonly account: UserProfileAccount
    readonly serviceAddress: Address
    readonly omitContactInfo?: boolean
    readonly omitPlanName?: boolean
}
