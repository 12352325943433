import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import styled from 'styled-components'

import { UserProfileAccount } from '@igs-web/common-models/models/user-profile-model'
import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { CommonReduxState } from '../common-redux'
import { useCompany } from '../company/hooks/useCompany'
import { CustomerUsageChart } from './customer-usage-chart'
import { UsageGrid } from './usage-grid'
import { usageRedux } from './usage-redux'

//NOTE(BF) - setting min-height on tablet is to deal with clipping issues for popups;
// setting overflow-x instantiates a new clipping context. The other options is to position
// the menus with javascript which is potentially hairy for a single use-case
const UsageContainer = styled.div`
    display: grid;
    max-width: 100%;
    grid-gap: ${Spacing.Medium};
    & > * {
        overflow-x: auto;
        @media (min-width: ${Breakpoint.Tablet}) {
            min-height: 300px;
        }
    }
`
const LegacyUsageMessage = styled.p`
    min-height: unset;
    text-align: center;
`

export const UsageDetails = ({ account }: Props) => {
    const { accountId, lineOfBusinessCode, isDirectBilledUtilityAccount } = account
    const { features } = useCompany()
    const dispatch = useDispatch()

    const usages = useSelector((store: CommonReduxState) => usageRedux.selectors.selectData(store, accountId))
    const usageIsLoading = useSelector((store: CommonReduxState) => usageRedux.selectors.selectLoading(store, accountId))

    useEffectOnce(() => {
        if (!usages) {
            dispatch(usageRedux.actions.load({ key: account.accountId, showGlobalLoader: false }))
        }
    })

    return (
        <>
            <LocalLoader isLoading={usageIsLoading ?? false} />
            {!usageIsLoading && (
                <UsageContainer>
                    <CustomerUsageChart usages={usages} lineOfBusinessCode={lineOfBusinessCode} />
                    {features.useBillingAccounts && (
                        <LegacyUsageMessage>
                            To view bill information at a service address level prior to October 2022, click on the BILLS tab.
                        </LegacyUsageMessage>
                    )}
                    <UsageGrid usages={usages ?? []} accountId={accountId} hideCharges={isDirectBilledUtilityAccount} />
                </UsageContainer>
            )}
        </>
    )
}
interface Props {
    readonly account: UserProfileAccount
}
