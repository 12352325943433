import * as React from 'react'
import { AccountContainer } from '@igs-web/common-components/domain/plan-at-a-glance/account-container'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'

export const PendingAccount = () => {
    return (
        <AccountContainer>
            <Header3>Account Pending</Header3>
        </AccountContainer>
    )
}
