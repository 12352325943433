export enum AlertDisplayLocationType {
    ModalOnLogin = 'MODAL-ONLOGIN',
    BannerMyAccount = 'BANNER-MYACCT',
    SidebarBillPage = 'SIDEBAR-BILLPAGE',
    SidebarAutoBillPage = 'SIDEBAR-AUTOBILLPAGE',
    SidebarMyAccount = 'SIDEBAR-MYACCT',
    SidebarServiceRequestLanding = 'SIDEBAR-SERVICEREQUESTLANDING',
    SidebarUsageTab = 'SIDEBAR-USAGETAB',
}

export interface Alert {
    readonly alertKey: string
    readonly title: string
    readonly message: string
    readonly displayLocation: AlertDisplayLocationType
    readonly actionLinkText?: string
    readonly actionLinkUrl?: string
    readonly priority: number
}
