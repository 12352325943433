import { OfferModel } from '@igs-web/common-models/models'
import { reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'
import { putInStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

import { OFFERS_CONTEXT_STORAGE_KEY } from './offers-context'
import { offersActionTypes } from './offers-context-actions'
import { initialOffersContextState, OffersContextModel, OffersRequest } from './offers-context-models'

const setOffersContextState = updatedOffersState => {
    putInStorage(updatedOffersState, OFFERS_CONTEXT_STORAGE_KEY, false)
    return updatedOffersState
}

export const OffersContextReducer = reducer<OffersContextModel>(initialOffersContextState)
    .add<OffersRequest>(
        offersActionTypes.LOAD_OFFERS,
        (state, offersRequest): OffersContextModel => {
            const updatedOffersContextState = {
                ...state,
                offersRequest,
                offersAreLoaded: false,
                offersAreLoading: true,
            }
            return setOffersContextState(updatedOffersContextState)
        },
    )
    .add<ReadonlyArray<OfferModel>>(
        offersActionTypes.SET_AVAILABLE_OFFERS,
        (state, offers): OffersContextModel => {
            const updatedOffersContextState = {
                ...state,
                offers,
                offersAreLoaded: true,
                offersAreLoading: false,
            }
            return setOffersContextState(updatedOffersContextState)
        },
    )
    .add<number>(
        offersActionTypes.SELECT_OFFER,
        (state, offer): OffersContextModel => {
            const updatedOffersContextState = {
                ...state,
                selectedOffer: offer,
            }
            return setOffersContextState(updatedOffersContextState)
        },
    )
    .add(
        offersActionTypes.DESELECT_OFFER,
        (state): OffersContextModel => {
            const updatedOffersContextState = {
                ...state,
                selectedOffer: undefined,
            }
            return setOffersContextState(updatedOffersContextState)
        },
    )
    .add(
        offersActionTypes.CLEAR_OFFER_STATE,
        (): OffersContextModel => {
            const updatedOffersContextState = {
                ...initialOffersContextState,
            }
            return setOffersContextState(updatedOffersContextState)
        },
    )
    .build()
