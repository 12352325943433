import * as React from 'react'
import { FaRegAddressCard } from 'react-icons/fa'

import { getContactName } from '@igs-web/common-components/domain/checkout/business/enrollment-service'
import { CheckoutStepPageIds } from '@igs-web/common-components/domain/checkout/checkout-step-page-ids'
import { EnrollmentModel } from '@igs-web/common-models/models/enrollment-model'
import { Card } from '@igs-web/common-ui-components/_atoms/card'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { OverflowWrap } from '@igs-web/common-ui-components/_atoms/typography'
import { AddressSummary } from '@igs-web/common-ui-components/_molecules/address/address-summary'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { useWizardDispatch, useWizardState } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { formatPhoneNumberForDisplay } from '@igs-web/common-utilities/utilities/phone-utilities'

import { EditHeading } from '../shared/checkout-components'

export const MyDetailsCard = ({ enrollment, supressBillingAccountDisplay, hideEditButton = false }: Props) => {
    const { moveToSubPage } = useWizardDispatch()
    const { activePageId } = useWizardState()
    const hideEditCalc =
        hideEditButton ||
        activePageId === CheckoutStepPageIds.ContactInfo ||
        activePageId === CheckoutStepPageIds.CustomerAddress ||
        activePageId === CheckoutStepPageIds.SelectRate
    return (
        <MyDetailsCardDisplay
            enrollment={enrollment}
            supressBillingAccountDisplay={supressBillingAccountDisplay}
            header={
                <EditHeading
                    data-testid="my-details-card-edit-button"
                    onClick={() => moveToSubPage(CheckoutStepPageIds.ContactInfo, '')}
                    hideEdit={hideEditCalc}
                >
                    <FaRegAddressCard /> My Details
                </EditHeading>
            }
        />
    )
}

export const MyDetailsCardDisplay = ({ enrollment, supressBillingAccountDisplay, header }: DisplayProps) => {
    const { defaultContact, isBillingSameAsServiceAddress, isPaperless, billingAddress } = enrollment
    return (
        <Card
            header={
                header ? (
                    header
                ) : (
                    <IconHeading>
                        <FaRegAddressCard /> My Details
                    </IconHeading>
                )
            }
        >
            <div data-testid="my-details-card-contact-name">{getContactName(defaultContact)}</div>
            <div data-testid="my-details-card-contact-phone-number">
                {formatPhoneNumberForDisplay(defaultContact.phoneNumber)} ({defaultContact.phoneType?.toLowerCase()})
            </div>
            <div data-testid="my-details-card-contact-email">
                <OverflowWrap>{defaultContact.emailAddress}</OverflowWrap>
            </div>
            {(defaultContact.isEmailMarketable || isPaperless) && (
                <>
                    <GridSpacer spacing={Spacing.ExtraLarge} />
                    {defaultContact.isEmailMarketable && <div>Signed up for email communications</div>}
                    {isPaperless && <div data-testid="my-details-card-is-paperless">Paperless</div>}
                </>
            )}
            {!supressBillingAccountDisplay && (
                <>
                    <GridSpacer spacing={Spacing.ExtraLarge} />
                    {isBillingSameAsServiceAddress ? (
                        <div>Billing address is the same as service address</div>
                    ) : (
                        <AddressSummary title="Billing Address" address={billingAddress} dataTestIdPrefix="my-details-card-billing-address-" />
                    )}
                </>
            )}
        </Card>
    )
}

interface Props {
    readonly enrollment: EnrollmentModel
    readonly supressBillingAccountDisplay: boolean
    readonly hideEditButton?: boolean
}
interface DisplayProps {
    readonly header?: React.ReactNode
    readonly enrollment: EnrollmentModel
    readonly supressBillingAccountDisplay: boolean
}
