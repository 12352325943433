import * as React from 'react'
import styled from 'styled-components'

import { useWizardItem } from './use-wizard-item'

export const WizardPageSection = styled.div<WizardPageSectionProps>`
    ${({ gridarea }) => (gridarea ? `grid-area: ${gridarea}` : '')}
`
interface WizardPageSectionProps {
    readonly gridarea?: string
}
export const WizardPage = ({ children, pageId, subPageId }: WizardPageProps) => {
    const { isActive, registerItem, isRegistered } = useWizardItem(pageId, subPageId)

    React.useEffect(() => {
        if (!isRegistered) {
            registerItem()
        }
    }, [registerItem, isRegistered])

    if (!isActive) {
        return <></>
    }

    return <React.Fragment>{children}</React.Fragment>
}
interface WizardPageProps {
    readonly pageId: string
    readonly subPageId?: string
    readonly children: React.ReactNode
}
