import { AiFillQuestionCircle } from 'react-icons/ai'

import styled from 'styled-components'

import { Grid1fr1fr } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { headerColor } from '@igs-web/common-ui-components/styles/theme'

export const TermsContainer = styled.p`
    font-size: 1rem;
    padding-right: ${Spacing.ExtraSmall};
`

export const LinksContainer = styled(TermsContainer)`
    text-align: center;
    &:hover {
        cursor: pointer;
    }
`
export const QuestionMarkIcon = styled(AiFillQuestionCircle)`
    color: ${headerColor};
    padding-right: ${Spacing.ExtraSmall};
`

export const LoginFormGrid = styled(Grid1fr1fr)`
    button {
        border-radius: 0;
        width: 100%;
    }
    padding-bottom: ${Spacing.Medium};
`

export const SpanColumns = styled.div`
    width: 100%;
    grid-column: -1 / 1;
`

export const ButtonCell = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: ${Spacing.ExtraLarge};
    grid-column: 2;
    row-gap: ${Spacing.Medium};
    [type='submit'] {
        grid-column: 2;
    }
    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-columns: 1fr;
        grid-column: 1;
    }
`
