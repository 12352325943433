import { all, put, select, takeEvery } from 'redux-saga/effects'

import _isEqual from 'lodash-es/isEqual'
import _omit from 'lodash-es/omit'
import _pickBy from 'lodash-es/pickBy'
import _uniqBy from 'lodash-es/uniqBy'
import _without from 'lodash-es/without'

/* eslint-disable max-lines */
import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { PhoneType } from '@igs-web/common-models/constants/phone'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { Address } from '@igs-web/common-models/models/address'
import { ContactModel } from '@igs-web/common-models/models/contact-model'
import { EnrollmentModel, ShoppingCartItem } from '@igs-web/common-models/models/enrollment-model'
import { EnrollmentTypeCode } from '@igs-web/common-models/models/enrollment-type-code'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { PaymentMethodModel, PaymentType } from '@igs-web/common-models/models/payment-model'
import { UserProfile, UserProfileAccount, UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'
import { getPaymentIdenifier } from '@igs-web/common-utilities/services/payment-info-service'
import { formatPhoneNumberForStorage } from '@igs-web/common-utilities/utilities/phone-utilities'
import { reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'
import { generateUuid } from '@igs-web/common-utilities/utilities/uuid-utilities'

import { selectNonDuplicateAccounts } from 'root/shared-services/account-service'

import { UserSelectors, userActionTypes } from '../user/user-redux'

import {
    EnrollmentInitializationModel,
    PaymentModelUpdateRequest,
    RealtorPromo,
    ShoppingCartUpdateRequest,
    UpdateServiceAddressRequest,
    enrollmentModelActionTypes,
    enrollmentModelActions,
} from './enrollment-redux-actions'
import { OfferSelectors } from './offer-redux'

const defaultContactModel: ContactModel = {
    firstName: '',
    middleInitial: '',
    lastName: '',
    commercialName: '',
    phoneNumber: '',
    emailAddress: '',
    isMobilePhone: false,
    isMobilePhoneMarketable: true,
    isEmailMarketable: true,
    phoneType: PhoneType.Home,
}

const initialEnrollmentModel: EnrollmentModel = {
    shoppingCartItems: [],
    defaultContact: defaultContactModel,
    isBillingSameAsServiceAddress: true,
    isShippingSameAsServiceAddress: true,
    forceMoving: false,
    hasCentralAc: true,
    isPaperless: true,
    hasElectronicCommunicationConsent: true,
    enrollmentTypeCode: EnrollmentTypeCode.Standard,
    electronicAgreementNumber: '',
}

export const enrollmentModelReducer = reducer<EnrollmentReduxState>(initialEnrollmentModel)
    .add<UpdateServiceAddressRequest>(enrollmentModelActionTypes.UPDATE_SERVICE_ADDRESS, (state, { address: serviceAddress }) => ({
        ...state,
        serviceAddress,
        billingAddress: state.isBillingSameAsServiceAddress ? serviceAddress : state.billingAddress,
        shippingAddress: state.isShippingSameAsServiceAddress ? serviceAddress : state.billingAddress,
    }))
    .add<Address>(enrollmentModelActionTypes.UPDATE_BILLING_ADDRESS, (state, billingAddress) => {
        return {
            ...state,
            billingAddress,
        }
    })
    .add<Address>(enrollmentModelActionTypes.UPDATE_SHIPPING_ADDRESS, (state, shippingAddress) => {
        return {
            ...state,
            shippingAddress,
        }
    })
    .add<boolean>(enrollmentModelActionTypes.SET_PAPERLESS, (state, isPaperless) => {
        return {
            ...state,
            isPaperless,
            hasElectronicCommunicationConsent: isPaperless || undefined,
        }
    })
    .add<PaymentModelUpdateRequest>(enrollmentModelActionTypes.UPDATE_PAYMENT_MODEL, (state, request) => {
        if (!request.shoppingCartItem || !request.paymentModel) {
            return state
        }

        return {
            ...state,
            shoppingCartItems: state.shoppingCartItems.map(i =>
                i.lineOfBusinessCode === request.shoppingCartItem.lineOfBusinessCode ? { ...i, payment: { ...i.payment, ...request.paymentModel } } : i,
            ),
        }
    })
    .add<Partial<ContactModel>>(enrollmentModelActionTypes.UPDATE_DEFAULT_CONTACT, (state, contact) => ({
        ...state,
        defaultContact: {
            ...state.defaultContact,
            ...contact,
        },
        shoppingCartItems: state.shoppingCartItems.map(i =>
            i.useDefaultContact
                ? {
                      ...i,
                      contact: {
                          ...i.contact,
                          ...contact,
                      },
                  }
                : i,
        ),
    }))
    .add<ShoppingCartUpdateRequest>(enrollmentModelActionTypes.UPDATE_SHOPPING_CART_ITEM, (state, changeRequest) => {
        const existingItem = state.shoppingCartItems.find(i => i.lineOfBusinessCode === changeRequest.lineOfBusinessCode)
        const shoppingCartItems = existingItem ? _without(state.shoppingCartItems, existingItem) : state.shoppingCartItems

        const contactToUse = changeRequest.useDefaultContact
            ? state.defaultContact
            : existingItem && existingItem.contact
            ? { ...existingItem.contact, ...changeRequest.contact }
            : defaultContactModel

        const updatedItem: ShoppingCartItem = {
            payment: undefined,
            useDefaultContact: true,
            ...existingItem,
            ...changeRequest,
            contact: contactToUse,
            shoppingCartItemKey: (existingItem && existingItem.shoppingCartItemKey) || generateUuid(),
        }

        return {
            ...state,
            shoppingCartItems: [...shoppingCartItems, updatedItem],
        }
    })
    .add<Partial<EnrollmentModel>>(enrollmentModelActionTypes.UPDATE_ENROLLMENT_MODEL, (state, enrollmentModel) => ({
        ...state,
        // The not included items are handled in the saga (this is to handle things like default contact and service address cascading down)
        ..._omit(enrollmentModel, 'serviceAddress', 'billingAddress', 'shippingAddress', 'defaultContact', 'shoppingCartItems'),
    }))
    .add<Partial<EnrollmentModel>>(enrollmentModelActionTypes.CLEAR_AFTER_SUBMIT, () => initialEnrollmentModel)
    .add(enrollmentModelActionTypes.CLEAR_SERVICE_ADDRESS, state => ({
        ...state,
        serviceAddress: undefined,
        billingAddress: state.isBillingSameAsServiceAddress ? undefined : state.billingAddress,
        shippingAddress: state.isShippingSameAsServiceAddress ? undefined : state.billingAddress,
    }))
    .add<RealtorPromo>(enrollmentModelActionTypes.UPDATE_REALTOR_PROMO, (state, realtor) => {
        return {
            ...state,
            realtorId: realtor.realtorId,
            realtorName: realtor.realtorName,
        }
    })
    .build()

export class EnrollmentModelSelectors {
    public static readonly selectEnrollmentModel = (state: CommonReduxState) => state.enrollment.enrollmentCore
    public static readonly selectShoppingCartItems = (state: CommonReduxState): ReadonlyArray<ShoppingCartItem> =>
        EnrollmentModelSelectors.selectEnrollmentModel(state).shoppingCartItems

    public static readonly selectShoppingCartItem = (state: CommonReduxState, lineOfBusinessCode: LineOfBusinessCode) =>
        EnrollmentModelSelectors.selectShoppingCartItems(state).find(i => i.lineOfBusinessCode === lineOfBusinessCode)

    public static readonly selectServiceAddress = (state: CommonReduxState) => EnrollmentModelSelectors.selectEnrollmentModel(state).serviceAddress
    public static readonly selectServiceAddressKey = (state: CommonReduxState) => EnrollmentModelSelectors.selectServiceAddress(state)?.serviceAddressKey
    public static readonly selectShippingAddress = (state: CommonReduxState) => EnrollmentModelSelectors.selectEnrollmentModel(state).shippingAddress
    public static readonly selectBillingAddress = (state: CommonReduxState) => EnrollmentModelSelectors.selectEnrollmentModel(state).billingAddress

    public static readonly selectOfferByLob = (state: CommonReduxState, lobCode: LineOfBusinessCode) => {
        const item = EnrollmentModelSelectors.selectShoppingCartItem(state, lobCode)
        if (item) {
            return OfferSelectors.selectOffers(state).find(o => o.offerId === item.offerId)
        }
    }

    public static readonly selectSelectedPaymentOptions = (state: CommonReduxState): ReadonlyArray<PaymentMethodModel> => {
        const exisitingPaymentMethods = [
            ...EnrollmentModelSelectors.selectShoppingCartItems(state).map(i => i.payment),
            EnrollmentModelSelectors.selectEnrollmentModel(state).depositPayment,
        ]
            .filter(p => p && [PaymentType.Ach, PaymentType.CreditCard].includes(p.paymentType))
            .map(p => p!)

        return _uniqBy(exisitingPaymentMethods, p => `${p.paymentType}:${getPaymentIdenifier(p)}`)
    }
}

const sagas = {
    //this is doing some side-effect logic, what is its real puprose? BF-removing action parameter for linting
    *updateServiceAddress() {
        const serviceAddress = (yield select(EnrollmentModelSelectors.selectServiceAddress)) as Address | undefined
        const userServiceAddress = (yield select(UserSelectors.selectUserServiceAddress, serviceAddress?.serviceAddressKey)) as UserProfileServiceAddress | null
        const shoppingCartItems: ReadonlyArray<ShoppingCartItem> = (yield select(EnrollmentModelSelectors.selectShoppingCartItems)) || []
        const accounts = (yield select(UserSelectors.selectPartyAccounts)) as ReadonlyArray<UserProfileAccount>
        const accountIds = accounts.filter(s => s.serviceAddressKey === userServiceAddress?.serviceAddressKey).map(a => a.accountId)

        for (const item of shoppingCartItems) {
            if (item.accountId && !accountIds.includes(item.accountId)) {
                yield put(
                    enrollmentModelActions.updateShoppingCartItem({
                        lineOfBusinessCode: item.lineOfBusinessCode,
                        accountId: undefined,
                        partyId: undefined,
                        accountNumber: undefined,
                        meterNumber: undefined,
                        useDefaultContact: true,
                        shoppingCartItemKey: generateUuid(),
                    }),
                )
            }
        }

        if (userServiceAddress) {
            const nonDuplicateAccounts = selectNonDuplicateAccounts(accounts)
            yield put(enrollmentModelActions.updateDefaultContact(defaultContactModel))

            for (const account of nonDuplicateAccounts) {
                const enrollmentModel: EnrollmentModel = yield select(EnrollmentModelSelectors.selectEnrollmentModel)
                const accountContact: ContactModel = {
                    commercialName: account.businessName,
                    firstName: account.customerFirstName,
                    lastName: account.customerLastName,
                    middleInitial: account.customerMiddleInitial,
                    phoneType: account.customerPhoneTypeCode === 'CELL' ? PhoneType.Cell : PhoneType.Home,
                    phoneNumber: formatPhoneNumberForStorage(account.customerPhoneNumber || ''),
                    emailAddress: account.customerEmailAddress,
                }

                const enrollmentDefaultContactExists = !!(enrollmentModel.defaultContact.firstName || enrollmentModel.defaultContact.lastName)

                if (!enrollmentDefaultContactExists) {
                    yield put(enrollmentModelActions.updateDefaultContact(accountContact))
                }

                const currentAccountContactIsDefaultContact =
                    enrollmentModel.defaultContact.firstName === accountContact.firstName && enrollmentModel.defaultContact.lastName === accountContact.lastName
                const currentAccountIsActiveOrPending = account.status === AccountStatus.active || account.status === AccountStatus.pending
                const shoppingCartItem = shoppingCartItems.find(i => i.lineOfBusinessCode === account.lineOfBusinessCode)

                yield put(
                    enrollmentModelActions.updateShoppingCartItem({
                        lineOfBusinessCode: account.lineOfBusinessCode,
                        accountId: account.accountId,
                        partyId: account.partyId,
                        accountNumber: currentAccountIsActiveOrPending ? account.fullAccountNumber : shoppingCartItem ? shoppingCartItem.accountNumber : '',
                        meterNumber: currentAccountIsActiveOrPending ? account.meterNumber : shoppingCartItem ? shoppingCartItem.meterNumber : '',
                        useDefaultContact: !enrollmentDefaultContactExists || currentAccountContactIsDefaultContact,
                        contact: accountContact,
                    }),
                )
            }
        }
    },

    *updateEnrollmentModel(action: { readonly payload: Partial<EnrollmentModel> }) {
        const enrollmentModel = action.payload
        const { shoppingCartItems, serviceAddress, billingAddress, shippingAddress, defaultContact } = enrollmentModel

        const currentEnrollmentModel = yield select(EnrollmentModelSelectors.selectEnrollmentModel)

        for (const i of shoppingCartItems || []) {
            const shoppingCartItem = yield select(EnrollmentModelSelectors.selectShoppingCartItem, i.lineOfBusinessCode)

            if (!_isEqual(i, shoppingCartItem)) {
                yield put(enrollmentModelActions.updateShoppingCartItem(i))
            }
        }

        if (serviceAddress && !_isEqual(serviceAddress, currentEnrollmentModel.serviceAddress)) {
            yield put(enrollmentModelActions.updateServiceAddress({ address: serviceAddress }))
        }

        if (billingAddress && !_isEqual(billingAddress, currentEnrollmentModel.billingAddress)) {
            yield put(enrollmentModelActions.updateBillingAddress(billingAddress))
        }

        if (shippingAddress && !_isEqual(shippingAddress, currentEnrollmentModel.shippingAddress)) {
            yield put(enrollmentModelActions.updateShippingAddress(shippingAddress))
        }

        if (defaultContact && !_isEqual(defaultContact, currentEnrollmentModel.defaultContact)) {
            yield put(enrollmentModelActions.updateDefaultContact(defaultContact))
        }
    },

    *initialize(action: { readonly payload: EnrollmentInitializationModel }) {
        const model = action.payload

        const request = _pickBy(model, v => v !== '' && v !== null && v !== undefined)
        yield put(enrollmentModelActions.updateEnrollmentModel(request))
    },

    *handleUserProfileLoad(action: { readonly payload: UserProfile }) {
        const userProfile = action.payload
        const existingServiceAddress: Address = yield select(EnrollmentModelSelectors.selectServiceAddress)
        const addressesInZipCode =
            existingServiceAddress && existingServiceAddress.zipCode
                ? userProfile.serviceAddresses.filter(sa => sa.zipCode === existingServiceAddress.zipCode)
                : userProfile.serviceAddresses

        if (addressesInZipCode.length === 1) {
            yield put(enrollmentModelActions.updateServiceAddress({ address: addressesInZipCode[0] }))
        }
    },
}

export function* enrollmentModelSaga() {
    yield all([
        takeEvery(enrollmentModelActionTypes.UPDATE_ENROLLMENT_MODEL, sagas.updateEnrollmentModel as any),
        takeEvery(enrollmentModelActionTypes.UPDATE_SERVICE_ADDRESS, sagas.updateServiceAddress as any),
        takeEvery(enrollmentModelActionTypes.INITIALIZE, sagas.initialize as any),
        takeEvery(userActionTypes.LOAD_USER_SUCCESS, sagas.handleUserProfileLoad as any),
    ])
}

export type EnrollmentReduxState = EnrollmentModel
