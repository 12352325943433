import _ from 'lodash'

export const areEqual = (object1: any, object2: any, ignoreNullOrUndefinedProperties = false) => {
    if (ignoreNullOrUndefinedProperties) {
        const cleanObject1 = omitByRecursively(object1, (v: any) => !v)
        const cleanObject2 = omitByRecursively(object2, (v: any) => !v)

        const equal = _.isEqual(cleanObject1, cleanObject2)
        return equal
    }

    const equal = _.isEqual(object1, object2)
    return equal
}

export const omitByRecursively = (value: any, iteratee: _.ValueKeyIteratee<never>) => {
    const cb = (v: any) => omitByRecursively(v, iteratee)
    return _.isObject(value) ? (_.isArray(value) ? _.map(value, cb) : _(value).omitBy(iteratee).mapValues(cb).value()) : value
}
