import * as React from 'react'
import { FaDollarSign } from 'react-icons/fa'
import { IoMdHelpCircle, IoMdMail, IoMdPhonePortrait } from 'react-icons/io'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeExtraLarge, primaryBase } from '@igs-web/common-ui-components/styles/theme'

import { ExternalLinkWithArrow, LayoutSidebar, LinkWithArrow } from 'molecules/layout/layout-styles'

import * as Routes from 'domain/routes'

const ContactLayoutSidebar = styled(LayoutSidebar)`
    padding: ${Spacing.Medium};
    display: flex;
    flex-direction: column;
    gap: ${Spacing.Medium};
`
const SidebarItemContainer = styled.div`
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: ${Spacing.Medium};
    svg {
        font-size: ${fontSizeExtraLarge};
        color: ${primaryBase};
    }
    p {
        margin: 0;
    }
`

const SidebarItem = ({ icon, heading, children }: SidebarItemProps) => (
    <SidebarItemContainer>
        {icon}
        <div>
            <StrongText>{heading}</StrongText>
            {children}
        </div>
    </SidebarItemContainer>
)

interface SidebarItemProps {
    readonly icon?: React.ReactNode
    readonly heading?: React.ReactNode
    readonly children?: React.ReactNode
}

export const ContactSidebar = () => {
    const { phoneNumber, externalWebsite, businessHours } = useCompany()

    return (
        <ContactLayoutSidebar>
            <SidebarItem icon={<IoMdPhonePortrait />} heading={<PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />}>
                {businessHours.businessHoursAbbreviated}
            </SidebarItem>
            <SidebarItem icon={<FaDollarSign />} heading="Payments">
                PO Box 105046 <br />
                Atlanta, GA 30348-5046
            </SidebarItem>
            <SidebarItem icon={<IoMdMail />} heading="General Correspondence Address">
                SCANA Energy
                <br />
                2231 S. Centennial Ave
                <br />
                Aiken, SC 29803
            </SidebarItem>
            <SidebarItem icon={<IoMdHelpCircle />} heading="Frequent Requests">
                <ExternalLinkWithArrow href={`${externalWebsite}/paying-my-bill/payment-centers`}>Find Payment Location</ExternalLinkWithArrow>
                <LinkWithArrow to={Routes.ForgotPassword}>Reset My Password</LinkWithArrow>
                <LinkWithArrow to={Routes.LoginRoute}>Go Paperless</LinkWithArrow>
                <ExternalLinkWithArrow href={`${externalWebsite}/paying-my-bill/`}>Payment Options</ExternalLinkWithArrow>
                <ExternalLinkWithArrow href={`${externalWebsite}/my-service/moving/`}>Turn Off or Transfer Service</ExternalLinkWithArrow>
                <LinkWithArrow to={Routes.HelpRoute}>FAQs</LinkWithArrow>
            </SidebarItem>
        </ContactLayoutSidebar>
    )
}
