import styled from 'styled-components'

import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { bannerBackground, bannerForeground } from '@igs-web/common-ui-components/styles/theme'

export const FullWidthNavigationBanner = styled.div`
    --banner-content-width: ${Breakpoint.Desktop};
    && {
        grid-column: 1/-1;
    }

    display: grid;
    justify-content: center;
    align-content: center;
    justify-items: start;
    grid-row-gap: ${Spacing.Medium};
    grid-template-columns: 1fr var(--banner-content-width) 1fr;
    & > * {
        grid-column: 2;
    }
    background: ${bannerBackground};
    color: ${bannerForeground};
    padding: ${Spacing.Large};

    @media (min-width: ${Breakpoint.Tablet}) {
        --banner-height: 150px;
    }
    @media (max-width: calc(${Breakpoint.Desktop} + ${Spacing.XxLarge} + ${Spacing.Large})) {
        grid-template-columns: 1fr;
        & > * {
            grid-column: 1;
        }
    }
`
export const wideBanner = { '--banner-content-width': Breakpoint.Desktop } as React.CSSProperties
