import { Alert } from '@igs-web/common-models/models/alert'
import * as React from 'react'

import { alertActions, alertReducer } from './alert-reducer'

const defaultAlertsState = []

export interface AlertDispatch {
    readonly setAlerts: (alerts: ReadonlyArray<Alert> | undefined) => void
    readonly addAlertInteraction: (alertKey: string, interactedWith: boolean) => void
}

const AlertStateContext = React.createContext<ReadonlyArray<Alert>>(defaultAlertsState)
const AlertDispatchContext = React.createContext<AlertDispatch>({} as AlertDispatch)

export const useAlertState = (): ReadonlyArray<Alert> => React.useContext(AlertStateContext)
export const useAlertDispatch = (): AlertDispatch => React.useContext(AlertDispatchContext)

export const AlertProvider = ({ children }: AlertProviderProps) => {
    const [state, dispatch] = React.useReducer(alertReducer, defaultAlertsState)

    const setAlerts = React.useCallback(alerts => dispatch(alertActions.setAlerts(alerts)), [dispatch])
    const addAlertInteraction = React.useCallback((alertKey, interactedWith) => dispatch(alertActions.addInteraction({ alertKey, interactedWith })), [dispatch])

    const alertDispatch = {
        setAlerts,
        addAlertInteraction,
    }

    return (
        <AlertStateContext.Provider value={state}>
            <AlertDispatchContext.Provider value={alertDispatch}>{children}</AlertDispatchContext.Provider>
        </AlertStateContext.Provider>
    )
}

interface AlertProviderProps {
    readonly children: React.ReactNode
}
