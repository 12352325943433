import styled from 'styled-components'

import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

export const GridWrapper = styled.div<GridWrapperProps>`
    display: grid;
    grid-template-columns: repeat(${({ $columnCount }) => $columnCount}, 1fr);
    grid-column-gap: ${Spacing.Large};
    grid-row-gap: ${Spacing.Medium};
    align-items: baseline;
    @media (max-width: ${Breakpoint.Mobile}) {
        grid-template-rows: repeat(${({ $columnCount }) => $columnCount}, 1fr);
        grid-template-columns: min-content 1fr;
        grid-auto-flow: column;
        align-items: center;
    }
`

interface GridWrapperProps {
    readonly $columnCount: number
}

export const NoData = styled.p`
    grid-column: 1/-1;
    place-self: center center;
`

export const GridWrapperActionButtons = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${Spacing.Large};
    place-items: center center;

    button {
        height: min-content;
    }
    button:disabled {
        opacity: 0.5;
    }
    @media (max-width: ${Breakpoint.Mobile}) {
        grid-row: -1;
        grid-column: 1 / -1;
    }
`

export const GridHeader = styled.p`
    font-weight: bold;
`
