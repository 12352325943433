import * as React from 'react'

import { useCheckoutDispatch, useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'

export const useQueryStringRealtorCheck = () => {
    const { setEnrollmentState } = useCheckoutDispatch()
    const enrollmentState = useCheckoutState()

    const getRealtorFlag = () => {
        const search = new URLSearchParams(window.location.search)
        const isRealtor = search.get('isrealtor')
        return isRealtor === 'true'
    }

    React.useEffect(() => {
        setEnrollmentState({ ...enrollmentState, isRealtor: getRealtorFlag() })
    }, [])
    return {
        isRealtor: getRealtorFlag(),
    }
}
