import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useInterval } from 'react-use'

import styled from 'styled-components'

import { CustomerInformation } from '@igs-web/common-components/domain/account-details/customer-information/customer-information'
import { HwAccountOptions } from '@igs-web/common-components/domain/myaccount/billing-accounts/account-options-tile'
import { HwPlanAtAGlance } from '@igs-web/common-components/domain/myaccount/billing-accounts/plan-at-a-glance/plan-at-a-glance'
import { useMyAccountDashboard } from '@igs-web/common-components/domain/myaccount/dashboard-context'
import { userActions } from '@igs-web/common-components/domain/user/user-redux'
import { LineOfBusinessCode, UserProfileAccount } from '@igs-web/common-models/models'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { NavigationBanner } from 'molecules/header/navigation-banner'
import { ScrollToTop } from 'molecules/routing/scroll-to-top'

import { useHwMyaccountState } from 'domain/home-warranty/hw-myaccount-context'
import { HwMyAccountAddressSelector } from 'domain/home-warranty/hw-service-address-selector'
import { getSelectedHwAccount } from 'domain/home-warranty/scana-hw-service'
import * as Routes from 'domain/routes'

import { CustomerServicePanel } from './home-warranty/customer-service-panel'
import { ScanaHwAccountDetailsTabs } from './home-warranty/hw-account-details-tabs'
import { HwPastDueBalanceAlert } from './home-warranty/hw-past-due-balance-alert'

const DEFAULT_RELOAD_TIME = 4000
const MAX_RELOAD_ATTEMPTS = 3

const DashboardContainer = styled.div`
    display: grid;
    text-align: left;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${Spacing.Large};
    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-columns: 1fr;
    }
`

export const HwAccountSummaryPage = () => {
    const { profile } = useMyAccountDashboard()
    const { selectedAddressHash } = useHwMyaccountState()
    const selectedAccount = getSelectedHwAccount(profile, selectedAddressHash)
    const selectedAddress = profile.serviceAddresses.find(s => s.addressHash === selectedAddressHash)
    const [reloadAttempts, setReloadAttempts] = React.useState<number>(
        profile.accounts.some(a => a.status === AccountStatus.pendingReload) ? MAX_RELOAD_ATTEMPTS : 0,
    )
    const hwAccountCount = profile.accounts.filter(a => a.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty).length
    const dispatch = useDispatch()
    const reloadUserProfile = (account: UserProfileAccount) => dispatch(userActions.reloadUser(account))

    useInterval(
        () => {
            const pendingReloadAccount = profile.accounts.find(a => a.status === AccountStatus.pendingReload)
            if (pendingReloadAccount && reloadAttempts > 0) {
                reloadUserProfile(pendingReloadAccount)
                setReloadAttempts(reloadAttempts - 1)
            } else {
                setReloadAttempts(0)
            }
        },
        reloadAttempts ? DEFAULT_RELOAD_TIME : null,
    )

    return (
        <>
            <ScrollToTop />
            <NavigationBanner title="Home Warranty - Account Summary" />
            <PageContentContainer>
                <GridSpacer spacing={Spacing.Large} />
                <HwMyAccountAddressSelector dropdownHeaderText="Select an address to view account details:" />
                <GridSpacer spacing={Spacing.Large} />
                <HwPastDueBalanceAlert accountId={selectedAccount?.accountId} />
                <DashboardContainer>
                    <HwPlanAtAGlance account={selectedAccount} />
                    <HwAccountOptions
                        accountId={selectedAccount?.accountId}
                        isPaperless={profile.isPaperless}
                        isInactive={selectedAccount?.status === AccountStatus.inactive}
                    />
                    {selectedAccount && (
                        <CustomerInformation
                            account={selectedAccount}
                            serviceAddress={selectedAddress}
                            editLinkUrl={Routes.HwAccountInformation}
                            omitPlanName={true}
                        />
                    )}
                    <CustomerServicePanel isInactive={selectedAccount?.status === AccountStatus.inactive} hwAccountCount={hwAccountCount} />
                </DashboardContainer>
                <GridSpacer spacing={Spacing.Large} />
                {selectedAccount && <ScanaHwAccountDetailsTabs accountId={selectedAccount.accountId} />}
                <GridSpacer spacing={Spacing.Large} />
            </PageContentContainer>
        </>
    )
}
