import { OfferAccountType } from '@igs-web/common-models/constants/account-type'
import { PriceUnit } from '@igs-web/common-models/constants/price-units'

/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { DiscountRequest, DiscountRequestLineItem, DiscountResponse, SubsidizingLineOfBusinessCode } from '@igs-web/common-models/models/discount-dto'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { OfferAndUtilityResponse, ProductResponse, UtilityLineOfBusinessResponse } from '@igs-web/common-models/models/offer-and-utility-dto'
import { OfferModel, PricingTier, ProductModel, ProductTypeCode } from '@igs-web/common-models/models/offer-model'
import { PriceInformationModel, PriceInformationType } from '@igs-web/common-models/models/price-information-model'
import { UtilityLineOfBusinessModel } from '@igs-web/common-models/models/utility-line-of-business-model'
import { enrollmentApiClient } from '@igs-web/common-utilities/api/enrollment-api-client'
import { getAccountTypeName } from '@igs-web/common-utilities/services/account-service'
import { getEasternTimezoneDate } from '@igs-web/common-utilities/utilities/date-utilities'

import { RequestConfig } from '../api/api-client'

const primaryProductLobs: ReadonlyArray<LineOfBusinessCode> = [LineOfBusinessCode.HomeWarranty, LineOfBusinessCode.Electric, LineOfBusinessCode.Gas]

export async function getOffers(
    zipCode: string,
    accountType: OfferAccountType,
    campaignCode?: string,
    invitationCode?: string,
    offerId?: string,
    priceEffectiveDate?: Date,
    ignoreCache = false,
    pricingTier?: PricingTier,
    distributionZone?: string,
    showGlobalLoader = true,
) {
    const priceEffectiveDateUtc = priceEffectiveDate ? priceEffectiveDate.toJSON() : undefined
    const response = await enrollmentApiClient.getOffers(
        { accountType: getAccountTypeName(accountType), zipCode, campaignCode, invitationCode, offerId, priceEffectiveDateUtc, pricingTier, distributionZone },
        ignoreCache,
        { showGlobalLoader },
    )

    const offers = response
        ? mapOfferAndUtilityResponse(response).filter(o => getAccountTypeName(o.primaryProduct.accountType) === getAccountTypeName(accountType))
        : []

    return offers
}

export async function getRenewalOffers(zipCode: string, partyAccountId: number, ignoreCache = false, config?: RequestConfig) {
    const response = await enrollmentApiClient.getRenewalOffers(zipCode, partyAccountId, ignoreCache, config)
    const renewalOffers = response ? mapOfferAndUtilityResponse(response) : []
    return renewalOffers
}

export async function getDiscounts(allOffers: ReadonlyArray<OfferModel>, selectedOffers: ReadonlyArray<OfferModel>, addressHash?: string) {
    const subsidizingLineOfBusinessCodes = selectedOffers.map(o => {
        return {
            subsidizingLineOfBusinessCode: o.primaryProduct.lineOfBusinessCode,
            subsidizingProductCode: o.primaryProduct.productCode,
            preventDiscounts: o.primaryProduct.preventDiscounts || false,
        } as SubsidizingLineOfBusinessCode
    })

    const lineItems = allOffers
        .filter(f => f.primaryProduct.price != null)
        .map(o => {
            return {
                basePrice: o.primaryProduct.price,
                discountCode: null,
                discountedLineOfBusinessCode: o.primaryProduct.lineOfBusinessCode,
                discountedProductCode: o.primaryProduct.productCode,
                unitOfMeasureCode: o.primaryProduct.unitOfMeasure,
            } as DiscountRequestLineItem
        })

    const request: DiscountRequest = {
        requests: lineItems,
        subsidizingLineOfBusinessCodes,
        addressHash,
    }

    const discountResponse = (await enrollmentApiClient.getDiscounts(request)) as ReadonlyArray<DiscountResponse>
    return discountResponse
}

export async function getAndApplyDiscounts(
    offers: ReadonlyArray<OfferModel>,
    selectedOffers: ReadonlyArray<OfferModel>,
    addressHash?: string,
): Promise<ReadonlyArray<OfferModel>> {
    const discounts = await getDiscounts(offers, selectedOffers, addressHash)

    return offers.map(o => {
        const discount: DiscountResponse = discounts
            .filter(d => d.discountedProductCode === o.primaryProduct.productCode)
            .sort((a, b) => (a.discountAmount < b.discountAmount ? -1 : a.discountAmount > b.discountAmount ? 1 : 0))
            .reverse()[0]

        if (discount && discount.discountedPrice !== o.primaryProduct.price) {
            const { displayPriceUnit } = o.primaryProduct

            return {
                ...o,
                primaryProduct: {
                    ...o.primaryProduct,
                    discountedPrice: discount.discountedPrice,
                    discountAmount: discount.discountAmount,
                    discountAmountType: discount.discountAmountType,
                    discountAmountDisplay: displayPriceUnit === PriceUnit.cents ? discount.discountAmountCents.toString() : discount.discountAmount.toString(),
                    discountedPriceDisplay:
                        displayPriceUnit === PriceUnit.cents ? discount.discountedPriceCents.toString() : discount.discountedPrice.toString(),
                    discountCode: discount.discountCode,
                    discountId: discount.discountId,
                },
            }
        } else {
            return {
                ...o,
                primaryProduct: {
                    ...o.primaryProduct,
                    discountedPrice: null,
                    discountAmount: null,
                    discountedPriceDisplay: null,
                    discountAmountDisplay: null,
                    discountAmountType: null,
                    discountCode: null,
                    discountId: null,
                },
            }
        }
    })
}

function cleanUtilityName(utilityName: string): string {
    return utilityName.replace(/ ?\[.*\]/, '').replace(/ ?\(.*\)/, '')
}

function mapUlobResponse(ulob: UtilityLineOfBusinessResponse): UtilityLineOfBusinessModel {
    return {
        ...ulob,
        utility: cleanUtilityName(ulob.utility),
        accountNumberLabel: ulob.accountNumberLabel.replace(/[^\w -]/g, ''),
        meterNumberLabel: ulob.meterNumberLabel?.replace(/[^\w -]/g, ''),
        currentPtc: ulob.currentPtc,
    }
}

function mapOfferAndUtilityResponse(data: OfferAndUtilityResponse): ReadonlyArray<OfferModel> {
    const ulobs = data.utilityLineOfBusinesses.map(mapUlobResponse)
    const offers = data.offers
    const campaignCode = data.campaignCode
    const invitationCode = data.invitationCode

    const mappedOffersAndUtilities = offers
        .map(o => {
            const primaryProduct = o.products.find(p => primaryProductLobs.some(lobCode => lobCode === p.lineOfBusinessCode))
            const offerAndProduct = {
                offerId: o.offerId,
                priceEffectiveDate: o.priceEffectiveDate ? new Date(o.priceEffectiveDate) : undefined,
                primaryProduct: mapProduct(primaryProduct, ulobs, campaignCode, invitationCode)!,
            }

            return offerAndProduct
        })
        .filter(o => o.primaryProduct)

    return mappedOffersAndUtilities
}

function getUlob(ulobs: ReadonlyArray<UtilityLineOfBusinessModel>, utilityCode: string, lineOfBusinessCode: string): UtilityLineOfBusinessModel | undefined {
    return ulobs.find(u => u.utilityCode === utilityCode && u.lineOfBusinessCode === lineOfBusinessCode)
}

function mapPriceInformation(
    priceInformation: ReadonlyArray<{ readonly type: string; readonly amount: number; readonly unit: string }>,
): ReadonlyArray<PriceInformationModel> {
    const filteredPriceInformation = priceInformation.filter(t => PriceInformationType[t.type] !== undefined)
    return filteredPriceInformation.map(p => {
        return { amount: p.amount, unitOfMeasure: p.unit, type: PriceInformationType[p.type] }
    })
}

function mapProduct(
    product: ProductResponse | null | undefined,
    ulobs: ReadonlyArray<UtilityLineOfBusinessModel>,
    campaignCode: string,
    invitationCode?: string,
): ProductModel | null {
    if (!product) {
        return null
    }

    const ulob = getUlob(ulobs, product.utilityCode, product.lineOfBusinessCode)

    const productModel: ProductModel = {
        description: product.productDescription,
        price: product.price,
        displayPrice:
            !product.displayPrice && product.displayPrice !== 0
                ? ''
                : product.displayPriceUnit === PriceUnit.dollars
                  ? product.displayPrice.toFixed(Math.max(2, (product.displayPrice.toString().split('.')[1] || []).length))
                  : product.displayPrice.toString(),
        displayPriceUnit: product.displayPriceUnit,
        title: product.productTitle,
        unitOfMeasure: product.unitOfMeasure,
        monthlyCharge: product.monthlyCharge,
        productCode: product.productCode,
        productType: product.productType as ProductTypeCode,
        accountType: product.accountType,
        isGreen: product.isGreen,
        isSpecialPricing: product.isSpecialPricing,
        isHeroProduct: product.isHeroProduct,
        preventDiscounts: product.preventDiscounts,
        termEndMonth: product.termEndMonth && getEasternTimezoneDate(product.termEndMonth),
        termMonths: product.termMonths,
        territoryCode: product.territoryCode,
        hpOfferCode: product.offerCode,
        lineOfBusinessCode: product.lineOfBusinessCode,
        supportedUtilities: product.supportedUtilities ? product.supportedUtilities.map(u => getUlob(ulobs, u.utilityCode, u.lineOfBusinessCode)!) : [],
        ulob,
        isFriendsAndFamily: product.isFriendsAndFamily,
        cancelFee: product.cancelFee,
        cancelFeeDescription: product.cancelFeeDescription,
        customTerms: product.customTerms,
        supplementalPriceInformation: product.priceInformation ? mapPriceInformation(product.priceInformation) : undefined,
        discountedPrice: product.discountedPrice,
        discountedPriceDisplay:
            !product.discountedPriceDisplay && product.discountedPriceDisplay !== 0
                ? ''
                : product.discountedPriceDisplayUnit === PriceUnit.dollars
                  ? product.discountedPriceDisplay.toFixed(Math.max(2, (product.discountedPriceDisplay.toString().split('.')[1] || []).length))
                  : product.discountedPriceDisplay.toString(),
        discountedPriceDisplayUnit: product.discountedPriceDisplayUnit,
        ratePlanName: product.ratePlanName,
        overrideCampaignCode: product.overrideCampaignCode,
        campaignCode,
        invitationCode,
    }

    return productModel
}
