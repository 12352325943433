import * as React from 'react'
import { Switch } from 'react-router-dom'

import { ProtectedRoute } from 'molecules/routing/protected-route'

import { DashboardReload } from 'domain/dashboard/dashboard-reload'
import { Dashboard } from 'domain/dashboard/gas-dashboard'
import { GasServiceRequestPage } from 'domain/myaccount/gas-service-request-page'
import { LobTab, LobTabs } from 'domain/navigation/lob-tabs'
import { PaymentExtensionPage } from 'domain/payment-extension/payment-extension-page'
import { PayBillPage } from 'domain/payment/pay-bill-page'
import { GasMyProfilePage } from 'domain/profile/gas-my-profile'
import { ConfirmRateChange } from 'domain/rate-change/confirm-rate-change'
import { RateChange } from 'domain/rate-change/rate-change-page'
import * as Routes from 'domain/routes'

export const GasPages = () => {
    return (
        <>
            <LobTabs activeTab={LobTab.NaturalGas} />
            <Switch>
                <ProtectedRoute path={Routes.AccountInformation}>
                    <GasMyProfilePage />
                </ProtectedRoute>
                <ProtectedRoute path={Routes.PayBill}>
                    <PayBillPage />
                </ProtectedRoute>
                <ProtectedRoute exact={true} path={Routes.DashboardReload}>
                    <DashboardReload />
                </ProtectedRoute>
                <ProtectedRoute path={Routes.AccountSummary}>
                    <Dashboard />
                </ProtectedRoute>
                <ProtectedRoute exact={true} path={Routes.RateChange}>
                    <RateChange />
                </ProtectedRoute>
                <ProtectedRoute exact={true} path={Routes.RateChangeConfirmation}>
                    <ConfirmRateChange />
                </ProtectedRoute>
                <ProtectedRoute exact={true} path={Routes.ServiceRequest}>
                    <GasServiceRequestPage />
                </ProtectedRoute>
                <ProtectedRoute exact={true} path={Routes.PaymentExtension}>
                    <PaymentExtensionPage />
                </ProtectedRoute>
                <ProtectedRoute>
                    <Dashboard />
                </ProtectedRoute>
            </Switch>
        </>
    )
}
