import * as React from 'react'

import { IconType } from '@igs-web/common-models/models/icon-type'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'

import { IconSvg } from '../_atoms/SVGs/icon-svg'
import { SvgIconProps } from '../_atoms/SVGs/svg-icon'

const getLobIconType = (lineOfBusinessCode: LineOfBusinessCode): IconType => {
    switch (lineOfBusinessCode) {
        case LineOfBusinessCode.Electric:
            return IconType.ElectricIcon
        case LineOfBusinessCode.Gas:
            return IconType.GasIcon
        case LineOfBusinessCode.HomeWarranty:
            return IconType.HomeWarrantyIcon
        case LineOfBusinessCode.CommunitySolar:
            return IconType.SolarIcon
        default:
            throw Error(`Unsupported line of business: ${lineOfBusinessCode}`)
    }
}

interface LineOfBusinessIconProps extends SvgIconProps {
    readonly lineOfBusinessCode: LineOfBusinessCode
    readonly icon?: IconType
}

export const LineOfBusinessIcon = <Props extends LineOfBusinessIconProps>({ lineOfBusinessCode, icon, ...svgProps }: Props): JSX.Element => (
    <IconSvg iconType={icon ? icon : getLobIconType(lineOfBusinessCode)} {...svgProps} />
)
