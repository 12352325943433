import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { subheaderColor } from '@igs-web/common-ui-components/styles/theme'
import styled from 'styled-components'

export const SummaryWrapper = styled.div``

export const SummaryTitle = styled.p`
    font-weight: ${FontWeight.Bold};
    text-transform: uppercase;
    color: ${subheaderColor};
`
export const SummaryBody = styled.div`
    display: grid;
    border-left: 2px grey solid;
    padding: ${Spacing.XxSmall} 0 ${Spacing.ExtraSmall} ${Spacing.Medium};
    grid-row-gap: ${Spacing.XxSmall};
    margin-left: ${Spacing.Medium};
`
