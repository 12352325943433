import * as React from 'react'

import { CheckoutWizardMessagesModel } from './checkout-wizard-messages-models'
import {
    checkoutWizardMessagesActions, checkoutWizardMessagesInitialState, checkoutWizardMessagesReducer, CheckoutWizardMessagesState
} from './checkout-wizard-messages-reducer'

type CheckoutWizardMessagesContextState = CheckoutWizardMessagesModel

interface CheckoutWizardMessagesContextDispatch {
    readonly setShowCantMatchAddressMessage: (showCantMatchAddressMessage: boolean) => void
    readonly setShowIsCommercialServiceTypeAddressMessage: (showIsCommercialServiceTypeAddressMessage: boolean) => void
    readonly setShowSelectedOfferExpiredMessage: (showSelectedOfferExpiredMessage: boolean) => void
    readonly setShowServerErrorMessage: (showServerErrorMessage: boolean) => void
    readonly setShowOfferNotAvailableForZipMessage: (showOfferNotAvailableForZipMessage: boolean) => void
}

const CheckoutWizardMessagesContextState = React.createContext<CheckoutWizardMessagesContextState | undefined>(undefined)
const CheckoutWizardMessagesContextDispatch = React.createContext<CheckoutWizardMessagesContextDispatch | undefined>(undefined)

export const useCheckoutWizardMessagesState = (): CheckoutWizardMessagesContextState => {
    const context = React.useContext(CheckoutWizardMessagesContextState)
    if (!context) {
        throw Error('Must use useCheckoutWizardMessagesState inside of CheckoutWizardMessagesContextProvider')
    }

    return context
}

export const useCheckoutWizardMessagesDispatch = (): CheckoutWizardMessagesContextDispatch => {
    const context = React.useContext(CheckoutWizardMessagesContextDispatch)
    if (!context) {
        throw Error('Must use useCheckoutWizardMessagesDispatch inside of CheckoutWizardMessagesContextProvider')
    }

    return context
}

export const CheckoutWizardMessagesProvider = ({ children, storageKey, checkoutWizardMessagesState }: CheckoutWizardMessagesProviderProps): JSX.Element => {
    const initialState: CheckoutWizardMessagesState = {
        checkoutWizardMessagesState: checkoutWizardMessagesState
            ? { ...checkoutWizardMessagesState }
            : { ...checkoutWizardMessagesInitialState.checkoutWizardMessagesState },
        storageKey,
    }
    const [state, dispatch] = React.useReducer(checkoutWizardMessagesReducer, initialState)

    const stateValue = state.checkoutWizardMessagesState

    const dispatchValue = {
        setShowCantMatchAddressMessage: (showCantMatchAddressMessage: boolean) =>
            dispatch(checkoutWizardMessagesActions.showCantMatchAddressMessage(showCantMatchAddressMessage)),
        setShowIsCommercialServiceTypeAddressMessage: (showIsCommercialServiceTypeAddressMessage: boolean) =>
            dispatch(checkoutWizardMessagesActions.showIsCommsercialServiceTypeAddressMessage(showIsCommercialServiceTypeAddressMessage)),
        setShowSelectedOfferExpiredMessage: (showSelectedOfferExpiredMessage: boolean) =>
            dispatch(checkoutWizardMessagesActions.showSelectedOfferExpiredMessage(showSelectedOfferExpiredMessage)),
        setShowServerErrorMessage: (showServerErrorMessage: boolean) => dispatch(checkoutWizardMessagesActions.showServerErrorMessage(showServerErrorMessage)),
        setShowOfferNotAvailableForZipMessage: (showOfferNotAvailableForZipMessage: boolean) =>
            dispatch(checkoutWizardMessagesActions.showOfferUnavailableForZipMessage(showOfferNotAvailableForZipMessage)),
    }

    return (
        <CheckoutWizardMessagesContextState.Provider value={stateValue}>
            <CheckoutWizardMessagesContextDispatch.Provider value={dispatchValue}>{children}</CheckoutWizardMessagesContextDispatch.Provider>
        </CheckoutWizardMessagesContextState.Provider>
    )
}

interface CheckoutWizardMessagesProviderProps {
    readonly children: React.ReactNode
    readonly checkoutWizardMessagesState?: CheckoutWizardMessagesModel
    readonly storageKey: string
}
