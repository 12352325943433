import { CachedReduxDataStateType, setupCachedReduxData } from '@igs-web/common-components/domain/cached-redux/cached-redux-data'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

export interface FiserveAutopayResponse {
    readonly invoiceGroupId: number
    readonly isFiservAutoPayEnrolled: boolean
}

const load = async (invoiceGroupId: number, config: RequestConfig): Promise<FiserveAutopayResponse | undefined> => {
    try {
        const fiserveAutopayResult = await customerInfoApiClient.getIsFiservAutoPayEnrolled([invoiceGroupId], config)

        const autopayInfo = fiserveAutopayResult?.find(x => x.invoiceGroupId === invoiceGroupId)

        return autopayInfo
    } catch (e) {
        console.error(`There was a problem getting usage: ${e.message}`)
    }
}

const loadMultiple = async (invoiceGroupIds: ReadonlyArray<number>, config: RequestConfig): Promise<Record<string, FiserveAutopayResponse | undefined>> => {
    const fiserveAutopayResult = await customerInfoApiClient.getIsFiservAutoPayEnrolled(invoiceGroupIds, config)

    if (fiserveAutopayResult) {
        const response = fiserveAutopayResult?.reduce(
            (o, cur) => ({
                ...o,
                [cur.invoiceGroupId]: cur,
            }),
            {},
        )

        return response
    }
    return invoiceGroupIds.reduce(
        (o, cur) => ({
            ...o,
            [cur]: undefined,
        }),
        {},
    )
}

const { actions, selectors, saga, reducers } = setupCachedReduxData('fiserveAutopay', load, loadMultiple)

export const fiserveAutopayRedux = { actions, selectors }
export const fiserveAutopaySaga = saga
export const fiserveAutopayReducer = reducers
export type FiserveAutopayState = CachedReduxDataStateType<typeof load>
