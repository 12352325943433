import * as React from 'react'

import { UserProfileAccount, UserProfileServiceAddress } from '@igs-web/common-models/models'
import { getFormattedAddress } from '@igs-web/common-utilities/utilities/address-utilities'

import { CommodityAccountDetails } from '../account-details/models/account-detail-models'
import { commodityAccountDetailColumnDefs } from '../account-details/models/account-detail-table-data'
import { useCompany } from '../company/hooks/useCompany'
import { DataTable, DataTableProps } from '../data-table/data-table'
import { useMyAccountDashboard } from '../myaccount/dashboard-context'

const prependAccountDetails = <T extends object>(record: T, accountDetails: CommodityAccountDetails) => {
    return {
        ...accountDetails,
        ...record,
    }
}

const mapCommodityAccountDetails = (serviceAddress: UserProfileServiceAddress, account: UserProfileAccount) => {
    const commodityAccountDetails: CommodityAccountDetails = {
        utilityName: account.utilityName ?? '',
        accountNumberLabel: account.accountNumberLabel ?? '',
        accountNumber: account.accountNumber ?? '',
        meterNumberLabel: account.meterNumberLabel ?? '',
        meterNumber: account.meterNumber ?? '',
        businessName: account.businessName ?? '',
        customerName: `${account.customerFirstName} ${account.customerLastName}`,
        serviceAddressFormatted: getFormattedAddress(serviceAddress),
        serviceAddress,
        customerEmailAddress: account.customerEmailAddress ?? undefined,
        customerPhoneNumber: account.customerPhoneNumber ?? undefined,
    }
    return commodityAccountDetails
}

export const CommodityAccountTable = <T extends object>({ columns, data, dataTestId, ...rest }: DataTableProps<T>): JSX.Element => {
    const { serviceAddress, profile } = useMyAccountDashboard()
    const account = profile.accounts.find(a => a.serviceAddressKey === serviceAddress?.serviceAddressKey)
    if (!serviceAddress) {
        throw new Error('AccountDataTable can not be rendered without a serviceAccount')
    }
    if (!account) {
        throw new Error('AccountDataTable can not be rendered without a partyAccount')
    }
    const commodityAccountDetails = mapCommodityAccountDetails(serviceAddress, account)

    const { features } = useCompany()
    const hideUtility = !features.displayUtilityInCustomerInformation

    const prependedColumnDefs = [...commodityAccountDetailColumnDefs(hideUtility), ...columns]
    const prependedData = data.map(c => prependAccountDetails(c, commodityAccountDetails))

    return (
        <DataTable<T>
            {...rest}
            columns={prependedColumnDefs}
            data={prependedData}
            handlePdfDownload={rest.handlePdfDownload}
            handleCsvDownload={rest.handleCsvDownload}
            dataTestId={dataTestId}
        />
    )
}
