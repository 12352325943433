import * as React from 'react'

import { useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { CreditCheckStatus } from '@igs-web/common-utilities/api/enrollment-api-client'
import { formatDate } from '@igs-web/common-utilities/utilities/date-utilities'

import { esitmatedMarketSwitchDate } from '../../shared/market-switch-date-service'

export const MarketSwitchEstimatedStartDate = () => {
    const enrollmentState = useCheckoutState()

    return (
        <>
            <div>
                <StrongText as="span">Estimated Start Date:</StrongText> {formatDate(esitmatedMarketSwitchDate())}
            </div>

            {enrollmentState.creditCheckResults?.energyScoreStatus === CreditCheckStatus.Collect && (
                <StrongText>The security deposit must be paid before your request for service can be scheduled.</StrongText>
            )}

            <p>
                If you signed up on or before 5 PM on the 19th of this month, your SCANA service will begin on the 1st of next month. If you signed up after 5
                PM on the 19th, service will begin the 1st of the month following next. We will be in touch with any questions.
            </p>
        </>
    )
}
