import { RequestConfig, apiClient } from './api-client'

class PaymentApiClient {
    public readonly createPaymentExtension = async (request: CreatePaymentExtensionRequest, config?: RequestConfig) =>
        await apiClient.postAuthed<never>(`${apiClient.apiBaseUrl}/Payment/SetupPaymentExtension`, request, config)
}

export const paymentApiClient = new PaymentApiClient()

export interface CreatePaymentExtensionRequest {
    readonly customerName: string
    readonly billingAccountNumber?: string
    readonly proposedPaymentExtensionDueDate?: Date
}
