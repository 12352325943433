import * as React from 'react'

import styled from 'styled-components'

import { UserProfileAccount } from '@igs-web/common-models/models/user-profile-model'

import { useCompany } from '../../company/hooks/useCompany'

import { BillingAccountTabs } from './billing-account-tabs'
import { CommodityTabs } from './commodity-tabs'
import { DirectBillAccountTabs } from './direct-bill-account-tabs'

const SelectDetailsLabel = styled.h4`
    text-align: center;
`

export const CommodityDetails = ({ account }: Props): JSX.Element => {
    const { features } = useCompany()
    const showBilling = account.isDirectBilledUtilityAccount

    const showBillingAccountTabs = features.useBillingAccounts
    const showDirectBillAccountTabs = !features.useBillingAccounts && showBilling
    const showCommodityTabs = !features.useBillingAccounts && !showBilling

    return (
        <>
            <div className="details-tab-container">
                <SelectDetailsLabel>SELECT THE ACCOUNT DETAILS YOU WOULD LIKE TO VIEW</SelectDetailsLabel>
            </div>
            {showBillingAccountTabs && <BillingAccountTabs selectedAccount={account} />}
            {showDirectBillAccountTabs && <DirectBillAccountTabs account={account} />}
            {showCommodityTabs && <CommodityTabs selectedAccount={account} />}
        </>
    )
}

interface Props {
    readonly account: UserProfileAccount
}
