import * as React from 'react'

import { ExpandSection } from '@igs-web/common-ui-components/_molecules/expandable/expand-section'

import { useAccordionItem } from './accordion-item'

export const AccordionPanel = ({ header, children, panelId, dataTestId }: AccordionPanelProps) => {
    const { isExpanded, toggleExpanded } = useAccordionItem(panelId)

    return (
        <ExpandSection id={panelId} header={header} isExpanded={isExpanded} toggleExpand={toggleExpanded} dataTestId={dataTestId}>
            {children}
        </ExpandSection>
    )
}

export interface AccordionPanelProps {
    readonly panelId: string
    readonly header: string | JSX.Element
    readonly children: JSX.Element
    readonly dataTestId: string
}
