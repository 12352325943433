import React, { FormEvent, ReactElement, useCallback } from 'react'
import { TableInstance } from 'react-table'

import styled from 'styled-components'

import { useBreakpoints } from '@igs-web/common-components/hooks/use-breakpoints'
import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { Panel } from '@igs-web/common-ui-components/_atoms/panel'
import { PopPanel } from '@igs-web/common-ui-components/_molecules/pop-panel'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { lightLight } from '@igs-web/common-ui-components/styles/theme'

import { AccordionAnimation } from 'root/views/product-selection-new/accordion-animation'

const StyledColumnContainer = styled.div`
    padding: 20px;
    display: grid;
    justify-content: space-between;
`
const StyledFilterPage = styled.div`
    padding: 20px;
    display: grid;
    justify-content: space-between;
    background-color: ${lightLight};
    @media (max-width: ${Breakpoint.Mobile}) {
        align-self: center;
        padding-left: ${Spacing.Medium};
    }
    button:disabled {
        opacity: 0.5;
    }
`
const StyledLink = styled.div`
    margin-right: 50px;
    display: inline-block;
`
const StyledPanel = styled(Panel)`
    position: static;
    background-color: ${lightLight};
    height: 100%;
`

export const FilterPage = ({ instance, onClose }: FilterPageProps<any>): ReactElement => {
    const { allColumns, setAllFilters } = instance
    const [filterValues, setFilterValues] = React.useState<object>({})
    const [disableSubmit, setDisableSubmit] = React.useState(false)

    const onSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            onClose()
        },
        [onClose],
    )

    const setFilterValueById = (id, value) => {
        const newObject = {
            ...filterValues,
            [id]: value,
        }
        setFilterValues(newObject)
    }

    const resetFilters = useCallback(() => {
        setAllFilters([])
        onClose()
    }, [setAllFilters])

    const applyFilters = () => {
        const allFilterValues = allColumns.map(column => {
            return { id: column.id, value: filterValues[column.id] ? filterValues[column.id] : column.filterValue }
        })
        setAllFilters(allFilterValues)
        onClose()
    }

    return (
        <StyledFilterPage>
            <form onSubmit={onSubmit}>
                <StyledColumnContainer>
                    {allColumns
                        .filter(it => it.canFilter)
                        .map(column => (
                            <div key={column.id}>
                                {column.render('Filter', { setFilterValue: val => setFilterValueById(column.id, val), setDisableSubmit })}
                            </div>
                        ))}
                </StyledColumnContainer>
                <StyledLink>
                    <Link onClick={resetFilters}>Reset</Link>
                </StyledLink>
                <Button disabled={disableSubmit} onClick={applyFilters}>
                    Apply
                </Button>
            </form>
        </StyledFilterPage>
    )
}

export const FilterPageResponsive = ({ instance, onClose }: FilterPageProps<any>) => {
    const { lessThan } = useBreakpoints()
    const isMobile = lessThan(Breakpoint.Tablet)
    return (
        <>
            {!isMobile && (
                <PopPanel isOpen={true} alignRight={true}>
                    <FilterPage instance={instance} onClose={onClose} />
                </PopPanel>
            )}
            <AccordionAnimation show={isMobile} animationKey={'filterPage'}>
                <StyledPanel>
                    <FilterPage instance={instance} onClose={onClose} />
                </StyledPanel>
            </AccordionAnimation>
        </>
    )
}

type FilterPageProps<T extends Record<string, unknown>> = {
    readonly instance: TableInstance<T>
    readonly onClose: () => void
}
