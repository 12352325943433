import * as React from 'react'
import { useForm } from 'react-hook-form'
import { BsTagFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { OfferRequestState, OfferSelectors, offersActions } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { ErrorField } from '@igs-web/common-ui-components/_atoms/form-inputs/error-field'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { Input } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { SmallButton } from '../shared/checkout-components'

const StyledForm = styled.form`
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    grid-column-gap: ${Spacing.ExtraLarge};
`
const StyledInvitationSection = styled.div`
    display: grid;
    grid-row-gap: ${Spacing.Medium};
`

export const InvitationCodeForm = () => {
    const { register, handleSubmit } = useForm<InvitationCodeFormFields>()
    const dispatch = useDispatch()
    const offerRequest = useSelector((store: CommonReduxState) => OfferSelectors.selectOfferRequest(store))

    const onSubmit = (data: InvitationCodeFormFields) => {
        dispatch(offersActions.submitOfferRequestForm({ zipOrInviteCode: data.invitationCode }))
    }

    return (
        <StyledInvitationSection>
            <IconHeading>
                <BsTagFill /> Invitation Code
            </IconHeading>
            {(!isCampiagnCodeValid(offerRequest) || !offerRequest.codeEnteredByCustomer) && (
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        {...register('invitationCode', { required: true, shouldUnregister: true })}
                        dataTestId="select-rate-invitation-code"
                        defaultValue={offerRequest.codeEnteredByCustomer ?? ''}
                        label="Invitation Code"
                        hideHelperText={true}
                    />
                    <SmallButton type="submit" buttonStyle={ButtonStyleType.Secondary}>
                        Apply
                    </SmallButton>
                    {!isCampiagnCodeValid(offerRequest) && (
                        <ErrorField showErrorMessage={true} errorMessage={`"${offerRequest.codeEnteredByCustomer}" is an invalid invitation code.`} />
                    )}
                </StyledForm>
            )}
            {isCampiagnCodeValid(offerRequest) && offerRequest.codeEnteredByCustomer && <AppliedInvitationCodeForm offerRequest={offerRequest} />}
        </StyledInvitationSection>
    )
}

const AppliedInvitationCodeForm = ({ offerRequest }: AppliedInvitationCodeFormProps) => {
    const { handleSubmit } = useForm()
    const dispatch = useDispatch()
    const onSubmit = () => {
        dispatch(offersActions.removeCampaignCode())
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <strong>{offerRequest.codeEnteredByCustomer?.toUpperCase()}</strong> has been applied to your order.{' '}
            <SmallButton type="submit" buttonStyle={ButtonStyleType.Secondary}>
                Remove
            </SmallButton>
        </form>
    )
}

interface AppliedInvitationCodeFormProps {
    readonly offerRequest: OfferRequestState
}
const isCampiagnCodeValid = (offerRequest: OfferRequestState) => {
    if (offerRequest.isCodeInvalid || offerRequest.isCodeExpired) {
        return false
    }

    return true
}

interface InvitationCodeFormFields {
    readonly invitationCode: string
}
