import { getDeviceId } from 'root/shared-services/device-id-service'

import { EnrollmentModel, ShoppingCartItem } from '@igs-web/common-models/models/enrollment-model'
import { OfferModel } from '@igs-web/common-models/models/offer-model'
import { getIpAddress } from '@igs-web/common-utilities/api/ip-address-client'
import { generateUuid } from '@igs-web/common-utilities/utilities/uuid-utilities'

export const mapToEnrollmentModel = (
    enrollmentState: EnrollmentModel,
    selectedOffers: ReadonlyArray<OfferModel>,
    agentCode?: string | null,
    referralId?: string | null,
    partyId?: number ,
): EnrollmentModel => {
    const shoppingCartItems: ReadonlyArray<ShoppingCartItem> = selectedOffers.map(selectedOffer => {
        return {
            ...enrollmentState.productDetails![selectedOffer.primaryProduct.lineOfBusinessCode],
            shoppingCartItemKey: generateUuid(),
            offerId: selectedOffer.offerId,
            priceEffectiveDate: selectedOffer.priceEffectiveDate,
            acceptedTerms: true,
            contact: enrollmentState.defaultContact,
            payment: enrollmentState.productDetails![selectedOffer.primaryProduct.lineOfBusinessCode].payment,
            partyId,
        }
    })
    const billingAddress = enrollmentState.isBillingSameAsServiceAddress ? enrollmentState.serviceAddress : enrollmentState.billingAddress
    const deviceId = getDeviceId()
    const ipAddress = getIpAddress()
    return {
        deviceId,
        ...enrollmentState,
        billingAddress,
        shoppingCartItems,
        agentCode,
        referralId,
        ipAddress,
    }
}
