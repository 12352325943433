import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BillingAccountTransactionHistory } from '@igs-web/common-models/models/billing-account-transaction-history'
import { LegacyBill } from '@igs-web/common-models/models/legacy-data'
import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'

import { CommonReduxState } from '../../common-redux'
import { useCompany } from '../../company/hooks/useCompany'
import { useMyAccountDashboard } from '../../myaccount/dashboard-context'
import { legacyBillRedux } from '../../myaccount/legacy-data/legacy-bills-redux'
import { BillingAccountBillHistoryTable } from '../bill-history-table'

export const BillingAccountBillHistoryTableWrapper = ({ accountIds }: BillingAccountBillHistoryTableProps) => {
    const { features } = useCompany()
    const { getTransactionHistory, billingAccount } = useMyAccountDashboard()
    const dispatch = useDispatch()

    const [transactionHistory, setTransactionHistory] = React.useState<BillingAccountTransactionHistory | null>()
    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const legacyIsLoading = useSelector((store: CommonReduxState) => legacyBillRedux.selectors.selectLoading(store, billingAccount?.billingAccountNumber || ''))
    const legacyBills =
        useSelector((store: CommonReduxState) => legacyBillRedux.selectors.selectData(store, billingAccount?.billingAccountNumber)) ??
        ([] as ReadonlyArray<LegacyBill>)

    React.useEffect(() => {
        if (features.useLegacydata && billingAccount?.billingAccountNumber && legacyBills.length === 0) {
            dispatch(legacyBillRedux.actions.load({ key: billingAccount?.billingAccountNumber, showGlobalLoader: false }))
        }
        setIsLoading(true)
        getTransactionHistory().then(result => {
            setIsLoading(false)
            setTransactionHistory(result)
        })
    }, [billingAccount])

    return (
        <>
            <LocalLoader isLoading={isLoading || !!legacyIsLoading} />
            {!isLoading && !legacyIsLoading && (
                <div>
                    {transactionHistory?.invoices && billingAccount && (
                        <BillingAccountBillHistoryTable
                            invoices={transactionHistory?.invoices}
                            accountIds={accountIds}
                            billingAccountNumber={billingAccount?.billingAccountNumber}
                            dataTestId="billing-history-table"
                        />
                    )}
                </div>
            )}
        </>
    )
}

interface BillingAccountBillHistoryTableProps {
    readonly accountIds: ReadonlyArray<number>
}
