import { CoverageDetailItem } from '@igs-web/common-models/models/account-detail-model'

export interface CoverageChartDetail {
    readonly coverageCode: string
    readonly color: string
    readonly category: string
}

interface CoverageChartDetailItem extends CoverageDetailItem, CoverageChartDetail {}

const coverageChartDetails: ReadonlyArray<CoverageChartDetail> = [
    { coverageCode: 'IG', color: '#85BAB6', category: 'INSIDE LINES' },
    { coverageCode: 'IE', color: '#63A7A2', category: 'INSIDE LINES' },
    { coverageCode: 'IW', color: '#42958E', category: 'INSIDE LINES' },
    { coverageCode: 'IS', color: '#20827A', category: 'INSIDE LINES' },
    { coverageCode: 'OG', color: '#F8BF73', category: 'OUTSIDE LINES' },
    { coverageCode: 'BH', color: '#F8BF73', category: 'OUTSIDE LINES' },
    { coverageCode: 'OW', color: '#F7AD4D', category: 'OUTSIDE LINES' },
    { coverageCode: 'OE', color: '#F59C26', category: 'OUTSIDE LINES' },
    { coverageCode: 'OS', color: '#F38B00', category: 'OUTSIDE LINES' },
    { coverageCode: 'FR', color: '#99B6CF', category: 'HVAC SYSTEMS' },
    { coverageCode: 'AC', color: '#739BBD', category: 'HVAC SYSTEMS' },
    { coverageCode: 'WH', color: '#4E80AB', category: 'HVAC SYSTEMS' },
    { coverageCode: 'BO', color: '#256599', category: 'HVAC SYSTEMS' },
    { coverageCode: 'HP', color: '#004987', category: 'HVAC SYSTEMS' },
]

const getColorForOtherCoverages = (idx: number, length: number): string => {
    const step = 210 / length
    const value = 210 - idx * step
    return `rgb(${value}, ${value}, ${value})`
}

const FilteredCoverages = (coverages: ReadonlyArray<CoverageDetailItem>): ReadonlyArray<CoverageChartDetailItem> => {
    const foundCoverages = coverageChartDetails
        .map(ccd => {
            const coverage = coverages.find(cov => cov.coverageCode === ccd.coverageCode)
            return coverage ? { ...coverage, ...ccd } : null
        })
        .filter(c => c)
        .map(c => c!)

    const otherCoverages = coverages
        .filter(c => coverageChartDetails.every(ccd => ccd.coverageCode !== c.coverageCode))
        .map((coverage: CoverageDetailItem, idx, arr) => ({
            ...coverage,
            color: getColorForOtherCoverages(idx, arr.length),
            category: coverage.coverageType.toUpperCase(),
        }))

    return [...foundCoverages, ...otherCoverages]
}

export default FilteredCoverages
