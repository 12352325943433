/* eslint-disable max-len */
import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

const FlameIcon = ({ altText = 'Natural Gas', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} id="Layer_1" viewBox="0 0 40 40" altText={altText}>
        <g id="Symbols_8_">
            <g id="Gas" transform="translate(-7 -1)">
                <g id="gas" transform="translate(7 1)">
                    <path
                        id="Fill-1_8_"
                        className="st1"
                        d="M18.2,4c0,0,0.1,0.3,0.3,0.8c3.6,10.3-9,13.5-8.6,22.7c0.3,5.1,4.8,9,10,8.8 c0.3,0,0.6,0,1-0.1c0-1-0.6-2.8-3-6.2c-4.6-6.4-0.4-9.9,1.3-11.9c0,0-1.3,3.5,2.5,9c2.6,3.8,1,7.3-0.3,9c4.5-0.9,7.8-4.9,7.6-9.5 C28.8,22,18.2,4,18.2,4"
                    />
                </g>
            </g>
        </g>
    </SvgIcon>
)

export default FlameIcon
