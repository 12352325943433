declare global {
    interface Window {
        // eslint-disable-next-line functional/prefer-readonly-type
        readonly Genesys: any
    }
}

class ChatService {
    public readonly openGenesysChat = () => {
        window.Genesys(
            'command',
            'Messenger.open',
            {},
            () => {}, // if resolved
        )
    }
    public readonly openNewGenesysCoBrowse = () => {
        window.Genesys('subscribe', 'Launcher.ready', () => {
            window.Genesys('command', 'Messenger.open', {}, () => {
                window.Genesys('command', 'Messenger.openCobrowse')
            })
        })
    }
    public readonly openGenesysCoBrowse = () => {
        window.Genesys('subscribe', 'Launcher.ready', () => {
            window.Genesys('command', 'Messenger.openCobrowse')
        })
    }
}

export const chatService = new ChatService()
