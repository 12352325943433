import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { Address } from '@igs-web/common-models/models/address'
import { CreditCheckRequest } from '@igs-web/common-models/models/credit-check-request'
import { OfferModel } from '@igs-web/common-models/models/offer-model'
import { ResidentialDepositTypes } from '@igs-web/common-models/models/residential-deposit-types'
import { FormWrapper } from '@igs-web/common-ui-components/_atoms/form-wrapper'
import { useWizardDispatch } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { enrollmentApiClient } from '@igs-web/common-utilities/api/enrollment-api-client'
import { fireCustomFullStoryEvent, FsEvent } from '@igs-web/common-utilities/utilities/fs-logger'

import { getBillingAddress, getResidentialDepositOffer, purgePayments } from '../../business/enrollment-service'
import { CheckoutStepProps } from '../../checkout-models'
import { CreditCheckFields, CreditCheckForm } from '../../forms/credit-check-form'
import { useCheckoutDispatch, useCheckoutState } from '../../hooks/use-checkout'
import { StepNav } from '../step-nav'

const generateCheckCreditRequest = (billingAddress: Address, creditCheck: CreditCheckFields, offer: OfferModel): CreditCheckRequest => {
    if (!offer.primaryProduct.ulob) {
        throw Error('No Credit Check Ulob Found')
    }
    return {
        billingAddress1: billingAddress.address1,
        billingCity: billingAddress.city,
        billingState: billingAddress.state,
        billingZip: billingAddress.zipCode,
        dateOfBirth: new Date(creditCheck.date),
        firstName: creditCheck.firstName,
        lastName: creditCheck.lastName,
        phoneNumber: creditCheck.phoneNumber,
        socialSecurityNumber: creditCheck.socialSecurityNumber ? creditCheck.socialSecurityNumber.replace(/[^\d?]/g, '') : creditCheck.socialSecurityNumber,
        utilityCode: offer.primaryProduct.ulob.utilityCode,
        lineOfBusinessCode: offer.primaryProduct.ulob.lineOfBusinessCode,
    }
}

export const CreditCheckFormStep = ({
    beforeSubmit,
    onServerError,
    stepFwdButtonText,
    advanceOnSubmit = true,
    checkIsSelectedOfferExpired,
    styleStepBackAsButton = false,
}: CreditCheckProps) => {
    const enrollmentState = useCheckoutState()
    const { setEnrollmentState } = useCheckoutDispatch()
    const { nextPage } = useWizardDispatch()
    const { defaultContact } = enrollmentState
    const formMethods = useForm<CreditCheckFields>()
    const selectedOffers = useSelector((store: CommonReduxState) => OfferSelectors.selectSelectedOffers(store))
    const stateCode = useSelector((store: CommonReduxState) => OfferSelectors.selectStateCode(store))
    const { handleSubmit } = formMethods

    const onSubmit = async (formData: CreditCheckFields) => {
        try {
            const isSelectedOfferExpired = checkIsSelectedOfferExpired ? await checkIsSelectedOfferExpired() : false
            if (!isSelectedOfferExpired) {
                const residentialCreditCheckOffer = getResidentialDepositOffer(selectedOffers, stateCode)
                const billingAddress = getBillingAddress(enrollmentState)
                if (!billingAddress || !residentialCreditCheckOffer) {
                    throw Error('Credit check not supported')
                }
                const request = generateCheckCreditRequest(billingAddress, formData, residentialCreditCheckOffer)
                const creditCheckResults = await enrollmentApiClient.checkCredit(request)

                if (!creditCheckResults) {
                    throw Error('Credit Check Service failure')
                }

                if (beforeSubmit) {
                    beforeSubmit(creditCheckResults)
                }

                setEnrollmentState({
                    ...enrollmentState,
                    depositAmount: creditCheckResults.depositAmount,
                    residentialDepositType: ResidentialDepositTypes.creditCheck,
                    creditCheckResults: {
                        ...creditCheckResults,
                        serviceAddress: enrollmentState.serviceAddress,
                        contact: enrollmentState.defaultContact,
                    },
                })
                if (advanceOnSubmit) {
                    nextPage()
                }
            }
        } catch (e) {
            fireCustomFullStoryEvent(FsEvent.creditCheckException, {
                enrollmentModel: JSON.stringify(purgePayments(enrollmentState)),
                error: JSON.stringify(e),
            })

            if (onServerError) {
                onServerError(e)
            }
        }
    }

    return (
        <React.Fragment>
            <FormProvider {...formMethods}>
                <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                    <CreditCheckForm defaultContact={defaultContact} confirmedCreditCheckInfo={enrollmentState.confirmedCreditCheckInfo} />
                    <StepNav stepFwdButtonText={stepFwdButtonText} styleStepBackAsButton={styleStepBackAsButton} />
                </FormWrapper>
            </FormProvider>
        </React.Fragment>
    )
}
interface CreditCheckProps extends CheckoutStepProps {
    readonly beforeSubmit?: (number) => void
    readonly onServerError?: (Error) => void
    readonly advanceOnSubmit?: boolean
    readonly checkIsSelectedOfferExpired?: () => Promise<boolean>
    readonly styleStepBackAsButton?: boolean
}
