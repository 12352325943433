import * as React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Action, Dispatch } from 'redux'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'

import { modalActions, ModalSelectors } from './modal-redux'

export const useModal = (uiKey: string) => {
    const dispatch = useDispatch()

    return {
        isOpen: useSelector((state: CommonReduxState) => ModalSelectors.isOpen(state, uiKey)),
        close: () => dispatch(modalActions.closeModal(uiKey)),
        open: () => dispatch(modalActions.openModal(uiKey)),
    }
}

const ConnectedModalBase = ({ children, uiKey, ...rest }: ConnectedModalProps): JSX.Element => <>{children({ ...rest })}</>

interface OwnProps {
    readonly uiKey: string
    readonly children: (props: ModalRenderProps) => React.ReactNode
}

interface StateProps {
    readonly isOpen: boolean
}

const mapStateToProps = (store: CommonReduxState, { uiKey }: OwnProps): StateProps => ({
    isOpen: ModalSelectors.isOpen(store, uiKey),
})

interface DispatchProps {
    readonly close: () => void
    readonly open: () => void
}

const mapDispatchToProps = (dispatch: Dispatch, { uiKey }: OwnProps): DispatchProps => ({
    close: (): Action => dispatch(modalActions.closeModal(uiKey)),
    open: (): Action => dispatch(modalActions.openModal(uiKey)),
})

interface ConnectedModalProps extends StateProps, DispatchProps, OwnProps {}

export interface ModalRenderProps extends StateProps, DispatchProps {}

export const ConnectedModal = connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(ConnectedModalBase)
