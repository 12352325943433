import * as React from 'react'

import { useModal } from '@igs-web/common-components/domain/ui-state/connected-modal'
import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { ButtonSection } from '@igs-web/common-ui-components/_atoms/buttons/button-section'

import { Modal } from './modal'

export const ConfirmModal = ({ children, onConfirm, uiKey, showCancel = false }: Props): JSX.Element => {
    const { isOpen, close } = useModal(uiKey)
    return (
        <Modal isOpen={isOpen} onClose={close}>
            {children}
            <ButtonSection alignment="center">
                {showCancel && <Button onClick={(): void => { close() }}>
                    Cancel
                </Button>
                }
                <Button
                    id="confirm-button"
                    dataTestId="confirm-button"
                    onClick={(): void => {
                        onConfirm()
                        close()
                    }}
                >
                    OK
                </Button>
            </ButtonSection>
        </Modal>
    )
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly uiKey: string
    readonly onConfirm: () => void
    readonly showCancel?: boolean
}
