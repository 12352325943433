/* eslint-disable max-len */
import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

const SolarIcon = ({ altText = 'Solar', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} id="Layer_1" viewBox="0 0 47.16 44.88" altText={altText}>
        <path d="M42.23,26.42,39.4,8.4a3.23,3.23,0,0,0-3.08-2.63H10.84A3.23,3.23,0,0,0,7.76,8.4l-2.83,18a2.21,2.21,0,0,0,2.25,2.64H40A2.22,2.22,0,0,0,42.23,26.42Zm-34.81.15,2.8-17.78a.77.77,0,0,1,.62-.53H36.32a.77.77,0,0,1,.62.53l2.79,17.78Zm11-16-.23,4.87a.7.7,0,0,1-.69.67H11.94a.58.58,0,0,1-.59-.67l.58-4.87a.77.77,0,0,1,.75-.66h5.09A.62.62,0,0,1,18.4,10.54Zm1.49,4.87.17-4.87a.71.71,0,0,1,.7-.66H26.4a.71.71,0,0,1,.69.66l.17,4.87a.63.63,0,0,1-.64.67H20.53A.64.64,0,0,1,19.89,15.41Zm9.09,0-.23-4.87a.62.62,0,0,1,.63-.66h5.09a.77.77,0,0,1,.75.66l.58,4.88a.57.57,0,0,1-.58.66H29.68A.71.71,0,0,1,29,15.41Zm-11,2.7-.29,5.53a.72.72,0,0,1-.7.66H10.74a.56.56,0,0,1-.58-.66l.72-5.53a.77.77,0,0,1,.75-.66h5.72A.64.64,0,0,1,18,18.11Zm9.42,0,.22,5.53a.64.64,0,0,1-.64.66H20.17a.64.64,0,0,1-.64-.66l.22-5.53a.69.69,0,0,1,.69-.66h6.27A.71.71,0,0,1,27.41,18.11Zm8.87,0L37,23.64a.56.56,0,0,1-.58.66H30.16a.71.71,0,0,1-.7-.66l-.29-5.53a.62.62,0,0,1,.63-.66h5.73A.79.79,0,0,1,36.28,18.11Zm-2.69,21h-20c0-.54-.12-3,5.71-3.6l.79-.06v-5h7v5l.79.07C33.71,36.06,33.59,38.5,33.59,39.11Z" />
    </SvgIcon>
)

export default SolarIcon
