import * as Routes from 'domain/routes'
import { MyAccountPanel } from 'molecules/navigation/myaccount-panel'
import * as React from 'react'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { useBreakpoints } from '@igs-web/common-components/hooks/use-breakpoints'
import { PhoneLink } from '@igs-web/common-components/scana-myaccount/scana-header'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'

import { HeaderContainer, HeaderLogo, TopNavContainer, TopNavLink } from './header-components'

export const MyAccountHeader = () => {
    const company = useCompany()
    const { lessThanOrEqualTo } = useBreakpoints()
    const isMobile = lessThanOrEqualTo(Breakpoint.Tablet)

    return (
        <HeaderContainer>
            <HeaderLogo />
            <TopNavContainer>
                <MyAccountPanel />
                {!isMobile && (
                    <>
                        <PhoneLink phoneNumber={company.phoneNumber.help} />
                        <TopNavLink href={Routes.ContactUsRoute}>Contact Us</TopNavLink>
                    </>
                )}
            </TopNavContainer>
        </HeaderContainer>
    )
}