import * as React from 'react'

import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { UserProfileAccount } from '@igs-web/common-models/models/user-profile-model'
import { LinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'
import { canEnterNewContract } from '@igs-web/common-utilities/services/contract-eligibility-service'

import { useCompany } from '../company/hooks/useCompany'

export const RatesButton = ({ account, hasSmallCommercialAccount }: Props): JSX.Element | null => {
    const { features, routes } = useCompany()

    const { lineOfBusinessCode } = account
    const buttonText = lineOfBusinessCode === LineOfBusinessCode.HomeWarranty ? 'COMPARE PLANS' : 'SEE NEW RATES'
    const shouldShowRateButton = features.canEnrollOnline && canEnterNewContract(account)

    return !shouldShowRateButton ? null : <LinkButton href={routes.product.productSelection(hasSmallCommercialAccount)}>{buttonText}</LinkButton>
}

interface Props {
    readonly account: UserProfileAccount
    readonly hasSmallCommercialAccount: boolean
}
