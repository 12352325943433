import styled from 'styled-components'

import { NavItem, NavItemLink } from './nav-item'

export const ColumnNavItem = styled(NavItem)`
    flex-direction:column;
`

export const ColumnNavItemLink = styled(NavItemLink)`
    flex-direction:column;
`