import { BodyCell, BodyRow } from '@igs-web/common-ui-components/_atoms/tables'
import { range } from '@igs-web/common-utilities/utilities/array-utilities'
import * as React from 'react'
import styled from 'styled-components'

const EmptyRow = styled(BodyRow)`
    ${BodyCell} {
        border: none;
    }
`

export const TableFiller = ({ rowCount, columnCount }: Props) => {
    const rowRange = range(rowCount)

    return (
        <>
            {rowRange.map(row => (
                <EmptyRow key={row}>
                    <BodyCell colSpan={columnCount}>&nbsp;</BodyCell>
                </EmptyRow>
            ))}
        </>
    )
}

interface Props {
    readonly rowCount: number
    readonly columnCount: number
}
