import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import { get } from 'lodash-es'

import { PaymentLegalAuth } from '@igs-web/common-components/domain/payment/payment-legal-auth'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { BankAccountFields, CreditCardFields, PaymentMethodModel, PaymentType, UtilityBillFields } from '@igs-web/common-models/models/payment-model'
import { UtilityLineOfBusinessModel } from '@igs-web/common-models/models/utility-line-of-business-model'
import { Input, LegalCheckBox, Select, SelectOption } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { InputHelperText } from '@igs/react-styled-components'

import { AchCaptureForm } from './ach-capture-form'
import { CreditCardCaptureForm } from './credit-card-capture-form'
import { UtilityBillCaptureForm } from './utility-bill-capture-form'

export const BasicPaymentCaptureForm = ({
    formKey,
    idPrefix,
    defaultPayment,
    defaultPaymentOptionType,
    paymentTypes,
    showAchZip,
    ulobs,
    hideLegalCheckbox,
    isRecurring,
}: BasicPaymentCaptureFormProps) => {
    const {
        register,
        watch,
        formState: { errors },
    } = useFormContext()
    const paymentSelectorField = 'paymentOptionSelector'
    const paymentTypeField = `${formKey}paymentType`
    const paymentSelectorFieldErrors = get(errors, `${paymentSelectorField}`)?.message?.toString()
    const selectedPaymentOption = watch(paymentTypeField, defaultPayment?.paymentType ?? defaultPaymentOptionType)

    return (
        <React.Fragment>
            {paymentTypes.length === 1 ? (
                <Input
                    {...register(paymentTypeField)}
                    defaultValue={defaultPayment?.paymentType}
                    dataTestId={`${idPrefix}-payment-type-selector`}
                    hideHelperText={true}
                    readOnly={true}
                    label="Payment Type"
                />
            ) : (
                <Select
                    {...register(paymentTypeField, { required: 'Required' })}
                    defaultValue={defaultPayment?.paymentType ?? defaultPaymentOptionType}
                    dataTestId={`${idPrefix}-payment-type-selector`}
                    error={paymentSelectorFieldErrors}
                    hideHelperText={!paymentSelectorFieldErrors}
                    allowClear={false}
                    label="Payment Type"
                >
                    {paymentTypes.map(p => (
                        <SelectOption dataTestId={`${idPrefix}-payment-type-${p}`} key={p} value={p}>
                            {p}
                        </SelectOption>
                    ))}
                </Select>
            )}

            {selectedPaymentOption === PaymentType.CreditCard && (
                <CreditCardCaptureForm
                    isReadonly={selectedPaymentOption?.existing}
                    payment={defaultPayment?.paymentType === PaymentType.CreditCard ? (defaultPayment as CreditCardFields) : undefined}
                    formKey={formKey}
                    idPrefix={idPrefix}
                    paymentSelectorField={paymentSelectorField}
                />
            )}
            {selectedPaymentOption === PaymentType.Ach && (
                <AchCaptureForm
                    payment={defaultPayment?.paymentType === PaymentType.Ach ? (defaultPayment as BankAccountFields) : undefined}
                    formKey={formKey}
                    idPrefix={idPrefix}
                    isReadonly={selectedPaymentOption?.existing}
                    paymentSelectorField={paymentSelectorField}
                    showZipCode={showAchZip}
                />
            )}
            {selectedPaymentOption === PaymentType.UtilityBill && (
                <UtilityBillCaptureForm
                    payment={defaultPayment?.paymentType === PaymentType.UtilityBill ? (defaultPayment as UtilityBillFields) : undefined}
                    formKey={formKey}
                    idPrefix={idPrefix}
                    isReadonly={selectedPaymentOption?.existing}
                    paymentSelectorField={paymentSelectorField}
                    ulobs={ulobs}
                />
            )}
            {selectedPaymentOption && !hideLegalCheckbox && (
                <div>
                    <LegalCheckBox
                        {...register('acceptedTerms', { required: 'Payment Authorization Required', shouldUnregister: true })}
                        defaultChecked={false}
                        dataTestId="hasPaymentAuthorizationConsent"
                        id="hasPaymentAuthorizationConsent"
                        label={
                            <PaymentLegalAuth
                                lineOfBusinessCode={LineOfBusinessCode.HomeWarranty}
                                isRecurring={isRecurring}
                                isUtilityBillPaymentMethod={selectedPaymentOption.paymentType === PaymentType.UtilityBill}
                            />
                        }
                        $error={!!errors.acceptedTerms?.message}
                    />
                    {errors.acceptedTems && <InputHelperText error={errors.acceptedTerms?.message?.toString()} currentLength={0} />}
                </div>
            )}
        </React.Fragment>
    )
}

export interface CommonPaymentCaptureFormProps {
    readonly formKey?: string
    readonly idPrefix: string
    readonly paymentTypes: ReadonlyArray<PaymentType>
    readonly ulobs?: ReadonlyArray<UtilityLineOfBusinessModel>
    readonly showAchZip?: boolean
    readonly hideLegalCheckbox?: boolean
    readonly isRecurring?: boolean
    readonly merchantName?: string
}

interface BasicPaymentCaptureFormProps extends CommonPaymentCaptureFormProps {
    readonly defaultPayment?: PaymentMethodModel
    readonly defaultPaymentOptionType?: PaymentType
}
