import * as React from 'react'
import { useEffectOnce } from 'react-use'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { useOffersContextDispatch } from '@igs-web/common-components/domain/offers/context/offers-context'
import { GaSalesType } from '@igs-web/common-models/models/google-analytics-models'
import { OfferModel } from '@igs-web/common-models/models/offer-model'
import { SubSectionHeadline } from '@igs-web/common-ui-components/_atoms/typography'
import { getEventModel, pushStartedCheckoutEvent } from '@igs-web/common-utilities/services/google-analytics-services'
import { FsEvent, fireCustomFullStoryEvent } from '@igs-web/common-utilities/utilities/fs-logger'

import { OfferListView } from 'domain/offers/offer-list-view'
import * as Routes from 'domain/routes'

const RenewalMessage = styled.div`
    text-align: left;
`

export const RenewalOffers = ({ offers, endDate, displayOnlyHeroProducts, campaignCode, distributionZone, zipCode }: RenewalOffersProps) => {
    const { features } = useCompany()
    const termEndDate = new Date(endDate || '')

    const { selectOffer } = useOffersContextDispatch()

    const handleSelectedOffer = (offer: OfferModel) => {
        pushStartedCheckoutEvent(getEventModel([offer]), GaSalesType.Renewal)
        fireCustomFullStoryEvent(FsEvent.renewalActionClicked, { offer })
        selectOffer({ offerId: offer.offerId, navigateToRoute: Routes.RateChangeConfirmation })
    }

    const renewRef = React.useRef<HTMLDivElement>(null)
    useEffectOnce(() => {
        fireCustomFullStoryEvent(FsEvent.renewalActionDisplayed, { offers: offers.map(x => x.primaryProduct), campaignCode, distributionZone, zipCode })
    })

    React.useEffect(() => {
        if (features.useDashboardScrollIntoView) {
            renewRef.current?.scrollIntoView({ behavior: 'auto', block: 'start' })
        }
    }, [])

    return (
        <div>
            <RenewalMessage>
                <SubSectionHeadline ref={renewRef} data-testid="offers-headline">
                    Renew My Rate Plan
                </SubSectionHeadline>
                {!isNaN(termEndDate.getDate()) && (
                    <p data-testid="offers-message-1">Renew by {termEndDate.toLocaleDateString()} and choose from a variety of rates.</p>
                )}
            </RenewalMessage>
            <OfferListView offers={offers} handleSelectedOffer={handleSelectedOffer} showHeroOnly={displayOnlyHeroProducts} />
        </div>
    )
}

interface RenewalOffersProps {
    readonly offers: ReadonlyArray<OfferModel>
    readonly campaignCode: string
    readonly zipCode: string
    readonly distributionZone?: string
    readonly endDate?: number
    readonly displayOnlyHeroProducts?: boolean
}
