import * as React from 'react'
import styled from 'styled-components'

import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeExtraLarge, fontSizeLarge } from '@igs-web/common-ui-components/styles/theme'
import { formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'
import { formatDate } from '@igs-web/common-utilities/utilities/date-utilities'

import { useCompany } from '../company/hooks/useCompany'
import { PaymentExtensionMessage } from './payment-extension-message'

const BalanceDueContainer = styled.div`
background-color: var(--light-accent);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr min-content 1fr;
    grid-row-gap: ${Spacing.Medium};
    padding: ${Spacing.Medium};
    grid-template-areas:
        'amountDue amountDue paymentButton paymentButton paymentButton'
        'accountStatus accountStatus accountStatus accountStatus accountStatus'
        'dueDate dueDate dueDate downloadBill downloadBill';
        
    @media (max-width: ${Breakpoint.Mobile}) {
        grid-template-areas:
            'amountDue amountDue amountDue downloadBill downloadBill'
            'accountStatus accountStatus accountStatus accountStatus accountStatus'
            'dueDate dueDate dueDate dueDate dueDate'
            'paymentButton paymentButton paymentButton paymentButton paymentButton';
    }
`
const AmountDueArea = styled.div`
    grid-area: amountDue;
`
const Title = styled.div`
    text-transform: uppercase;
    font-weight: ${FontWeight.SemiBold};
`
const AmountDueTitle = styled(Title)`
    font-size: 0.875rem;
`
const AmountDue = styled.div`
    font-size: ${fontSizeExtraLarge};
`
const AccountStatusArea = styled.div`
    grid-area: accountStatus;
    justify-self: right;
    @media (max-width: ${Breakpoint.Mobile}) {
        justify-self: center;
    }
`
const AccountStatusStyles = styled.span`
    font-size: ${fontSizeLarge};
    line-height: ${fontSizeLarge};
    font-weight: ${FontWeight.SemiBold};
`
const PaymentButtonArea = styled.div`
    grid-area: paymentButton;
    height: min-content;
    justify-self: end;
    align-self: center;
    @media (max-width: ${Breakpoint.Mobile}) {
        justify-self: center;
    }
`
const BillArea = styled.div`
    grid-area: downloadBill;

    justify-self: end;
    align-self: end;
    @media (max-width: ${Breakpoint.Mobile}) {
        align-self: start;
    }
`
const DueDateArea = styled.div`
    grid-area: dueDate;
    align-self: end;
`
const PaymentExtensionDueDate = styled.div`
    display: grid;
    grid-column-gap: ${Spacing.Small};
    grid-template-columns: max-content auto;
`
const PaymentPlan = styled.div`
    grid-column: 1 / span 5;
    align-self: end;
    grid-row-start: 4;
    @media (max-width: ${Breakpoint.Mobile}) {
        grid-row-start: 5;
    }
`
const StartServiceArea = styled.div`
    grid-row-start: 3;
    grid-column: 1 / span 5;
    font-weight: ${FontWeight.SemiBold};
    align-self: center;
`

export const BalanceDueDetailsBillingAccount = ({
    paymentDueDate,
    totalAmountDue,
    isOnPaymentPlan,
    billingAccountNumber,
    isActive,
    paymentExtensionDueDate,
    mostRecentInvoiceLink,
    paymentButton,
}: BalanceDueDetailsBillingAccountProps) => {
    const { phoneNumber } = useCompany()
    const hasPaymentExtension = !!paymentExtensionDueDate
    const isAccountClosed = !isActive && totalAmountDue === 0
    return (
        <div>
            <BalanceDueContainer>
                <AmountDueArea>
                    <AmountDueTitle>Amount Due</AmountDueTitle>
                    <AmountDue>{formatAsMoney(totalAmountDue)}</AmountDue>
                </AmountDueArea>
                {!isActive && (
                    <AccountStatusArea>
                        <AccountStatusStyles>ACCOUNT NOT ACTIVE</AccountStatusStyles>
                    </AccountStatusArea>
                )}
                {!isAccountClosed && (
                    <BillArea>
                       {mostRecentInvoiceLink}
                    </BillArea>
                )}
                {!isAccountClosed && (
                    <DueDateArea>
                        {paymentDueDate && !hasPaymentExtension && <Title>Due Date: {formatDate(paymentDueDate)}</Title>}
                        {hasPaymentExtension && (
                            <PaymentExtensionDueDate>
                                <Title>Extension Due Date:</Title> <Title>{formatDate(paymentExtensionDueDate)}</Title>
                                <Title>Original Due Date:</Title> <Title>{formatDate(paymentDueDate)}</Title>
                            </PaymentExtensionDueDate>
                        )}
                        <PaymentExtensionMessage billingAccountNumber={billingAccountNumber} />
                    </DueDateArea>
                )}
                {isAccountClosed && (
                    <StartServiceArea>
                        To start service, please call us at <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />
                    </StartServiceArea>
                )}
                 {!isAccountClosed && (
                <PaymentButtonArea>
                   
                    {paymentButton}
                   
                </PaymentButtonArea>
                 )}
                {isOnPaymentPlan && <PaymentPlan> The Amount Due reflects the terms of your payment arrangement.</PaymentPlan>}
            </BalanceDueContainer>
        </div>
    )
}
interface BalanceDueDetailsBillingAccountProps {
    readonly paymentDueDate?: Date
    readonly totalAmountDue: number
    readonly showPayBillLink: boolean
    readonly isOnPaymentPlan: boolean
    readonly billingAccountNumber?: string
    readonly isActive: boolean
    readonly paymentExtensionDueDate?: Date | null
    readonly mostRecentInvoiceLink?: React.ReactNode
    readonly paymentButton?: React.ReactNode
}
