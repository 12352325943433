import { FormApi } from 'final-form'
import * as React from 'react'
import { Form, FormProps } from 'react-final-form'

export interface TypedFormProps<T extends object> extends FormProps {
    readonly onSubmit: FormSubmitFunc<T>
    readonly initialValues?: RecursivePartial<T>
    readonly validate?: FormValidateFunc<T>
}

type FormSubmitResult = object | Promise<object | undefined> | undefined | void
export type FormSubmitFunc<T> = (values: T, form: FormApi, callback?: (errors?: object) => void) => FormSubmitResult

export type FormValidateFunc<T> = (values: T) => object | Promise<object>

export function TypedForm<T extends object>(props: TypedFormProps<T>): JSX.Element {
    return <Form {...props} />
}

// Recursively apply Partial rules to children
// Source: https://www.reddit.com/r/typescript/comments/9sfqb8/recursive_partial_type/
export type RecursivePartial<T> = T extends object ? { [K in keyof T]?: RecursivePartial<T[K]> } : T
