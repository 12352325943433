import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const ShieldCircleIcon = ({ altText = 'Shield', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M19.539 14.172l-4.163 4.090-1.733-1.696-1.469 1.513 1.733 1.696 1.469 1.505 5.639-5.691-1.469-1.417zM9.759 10.61h12.483v7.115c0 3.025-2.511 5.691-6.219 7.115-3.715-1.425-6.227-4.090-6.227-7.115zM7.982 8.811v8.914c0 3.738 3.231 7.115 8.004 8.892 4.773-1.777 8.004-5.14 8.004-8.892v-8.914z" />
        <path d="M15.985 0c-0.007 0-0.014 0-0.022 0-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-0.002 0-0.005 0-0.007 0-8.827-7.152-15.984-15.977-15.993h-0.001zM15.985 30.458c-0.004 0-0.010 0-0.015 0-7.985 0-14.458-6.473-14.458-14.458s6.473-14.458 14.458-14.458c7.985 0 14.458 6.473 14.458 14.458v0c0 0.004 0 0.010 0 0.015 0 7.977-6.466 14.443-14.443 14.443v0z" />
    </SvgIcon>
)

export default ShieldCircleIcon
