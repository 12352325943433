const todayDates = new Date()
const cutOffDay = 19
const cutOffHour = 17
const supplierSwitchCutOffDate = new Date(todayDates.getFullYear(), todayDates.getMonth(), cutOffDay, cutOffHour)
const NEXT_MONTH_SWITCH_ADDER = 2

export const esitmatedMarketSwitchDate = () => {
    if (todayDates < supplierSwitchCutOffDate) {
        return new Date(todayDates.getFullYear(), todayDates.getMonth() + 1, 1)
    }
    return new Date(todayDates.getFullYear(), todayDates.getMonth() + NEXT_MONTH_SWITCH_ADDER, 1)
}
