import * as React from 'react'

import { AccordionGroupProvider } from './accordion-group-context'

export const AccordionGroup = ({ children, initiallyExpanded }: AccordionGroupProps) => (
    <div>
        <AccordionGroupProvider initiallyExpanded={initiallyExpanded}>{children}</AccordionGroupProvider>
    </div>
)

export interface AccordionGroupProps {
    readonly initiallyExpanded?: ReadonlyArray<string> | string
    readonly children: React.ReactNode
}
