import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const CircleMyDetails = ({ altText = 'My Details', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} altText={altText} width="50" height="50" viewBox="0 0 50 50">
        <g id="Group_538" data-name="Group 538" transform="translate(4776 -3355)">
            <g id="Ellipse_208" data-name="Ellipse 208" transform="translate(-4776 3355)" className="icon-border-circle" strokeWidth="3">
                <circle cx="25" cy="25" r="25" stroke="none" />
                <circle cx="25" cy="25" r="23.5" fill="none" />
            </g>
            <path id="user-solid" d="M10.345,11.822A5.911,5.911,0,1,0,4.433,5.911,5.911,5.911,0,0,0,10.345,11.822ZM14.482,13.3h-.771a8.039,8.039,0,0,1-6.733,0H6.207A6.208,6.208,0,0,0,0,19.507v1.921a2.217,2.217,0,0,0,2.217,2.217H18.472a2.217,2.217,0,0,0,2.217-2.217V19.507A6.208,6.208,0,0,0,14.482,13.3Z" transform="translate(-4761 3367)" className="icon-core" />
        </g>
    </SvgIcon>
)

export default CircleMyDetails