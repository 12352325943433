import { BaseCompany, SupportedCompanies } from '@igs-web/common-components/domain/company/company'

import { sitefinityApiClient } from '../api/sitefinity-api-client'

//this list comes back from the API based on powers-of-two based on order
// current order on 4/21/23 is both, scanaEnergy, ScanaRegulated. if that changes,
// this will break.

const showOnBoth = '1'
const showOnReg = '2'
const showOnDereg = '4'
export interface SiteMessage {
    readonly id: string
    readonly message: string
    readonly isDismissable: boolean
    readonly beginDate: Date
    readonly endDate: Date
    readonly showOn: string
}

const getShowOn = (company: string) => {
    if (company === SupportedCompanies.Scana) {
        return showOnDereg
    }
    if (company === SupportedCompanies.ScanaRegulated) {
        return showOnReg
    }

    return showOnBoth
}

export const getSiteMessage = async (company: BaseCompany) => {
    const showOn = getShowOn(company.name)
    const date = new Date()
    const messageResults = (await sitefinityApiClient.getSiteMessages({ showGlobalLoader: false })) || []
    const messages: ReadonlyArray<SiteMessage> = messageResults
        .filter(x => x.showOn === showOnBoth || x.showOn === showOn)
        .map(x => ({
            id: x.id,
            message: x.message,
            isDismissable: x.isDismissable,
            beginDate: new Date(x.beginDate),
            endDate: new Date(x.endDate),
            showOn: x.showOn,
        }))
        .filter(x => x.beginDate <= date && x.endDate >= date)

    if (messages.length) {
        return messages[0]
    } else {
        return null
    }
}
