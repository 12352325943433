export interface PriceInformationModel {
    readonly type: PriceInformationType
    readonly amount: number
    readonly unitOfMeasure: string
}

export enum PriceInformationType {
    FixedEnergy = 'FIXEDENERGY',
    DiscountedFixedEnergy = 'DISCOUNTEDFIXEDENERGY',
    MonthlyCharge = 'MONTHLY',
    DisCountedMonthlyCharge = 'DISCOUNTEDMONTHLY',
    Variable = 'VARIABLE',
    PipelineCapacity = 'PIPELINECAPACITY',
}
