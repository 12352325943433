export enum Breakpoint {
    MobileExtraSmall = '375px',
    MobileSmall = '450px',
    Mobile = '576px',
    Tablet = '768px',
    Desktop = '992px',
    LargeDesktop = '1200px',
    MaxContent = '1440px',
}

export const maxWidthQuery = (breakpoint: Breakpoint) => `(max-width:${breakpoint})`
export const minWidthQuery = (breakpoint: Breakpoint) => `(min-width:${breakpoint})`
