import { FaSpinner } from 'react-icons/fa'

import styled from 'styled-components'

import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

export const FaSpinnerAnimated = styled(FaSpinner)<{ readonly marginLeft?: string | null }>`
    margin-left: ${({ marginLeft }) => marginLeft || Spacing.Small};
    animation: spin 1s infinite linear;

    @keyframes spin {
        from {
            transform: scale(1) rotate(0deg);
        }
        to {
            transform: scale(1) rotate(360deg);
        }
    }
`
