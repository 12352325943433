import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const CircleProductDetails = ({ altText = 'Product Details', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} altText={altText} width="50" height="50" viewBox="0 0 50 50">
        <g id="Group_540" data-name="Group 540" transform="translate(4776 -3419)">
            <g id="Ellipse_208" data-name="Ellipse 208" transform="translate(-4776 3419)" className="icon-border-circle" strokeWidth="3">
                <circle cx="25" cy="25" r="25" stroke="none" />
                <circle cx="25" cy="25" r="23.5" fill="none" />
            </g>
            <path id="search-solid" d="M22.626,19.834l-4.467-4.467a1.074,1.074,0,0,0-.762-.314h-.73a9.315,9.315,0,1,0-1.613,1.613v.73a1.074,1.074,0,0,0,.314.762l4.467,4.467a1.071,1.071,0,0,0,1.519,0l1.268-1.268A1.08,1.08,0,0,0,22.626,19.834ZM9.319,15.054a5.735,5.735,0,1,1,5.735-5.735A5.731,5.731,0,0,1,9.319,15.054Z" transform="translate(-4762 3432)" className="icon-core" />
        </g>
    </SvgIcon>
)

export default CircleProductDetails