import * as React from 'react'

import styled from 'styled-components'

import { LinkContainer } from '@igs-web/common-ui-components/_atoms/link/link-container'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { apiClient } from '@igs-web/common-utilities/api/api-client'

const StyledSocialBar = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 37px);
    grid-template-rows: 37px;
    column-gap: ${Spacing.Medium};
`
const StyledSocialBarImg = styled.img`
    height: 100%;
`
const facebookLogo = 'social-icons/facebook-logo.png'
const instagramLogo = 'social-icons/instagram-logo.png'
const twitterLogo = 'social-icons/twitter-logo.png'
const youtubeLogo = 'social-icons/youtube-logo.png'

export const SocialBar = () => {
    return (
        <StyledSocialBar>
            <LinkContainer href="https://www.facebook.com/scanaenergy" openInNewTab={true}>
                <StyledSocialBarImg src={apiClient.getAssetPath(facebookLogo)} alt="Facebook" />
            </LinkContainer>
            <LinkContainer href="https://www.instagram.com/scanaenergy" openInNewTab={true}>
                <StyledSocialBarImg src={apiClient.getAssetPath(instagramLogo)} alt="Instagram" />
            </LinkContainer>
            <LinkContainer href="https://twitter.com/scanaenergy" openInNewTab={true}>
                <StyledSocialBarImg src={apiClient.getAssetPath(twitterLogo)} alt="Twitter" />
            </LinkContainer>
            <LinkContainer href="https://www.youtube.com/user/scanaenergyga" openInNewTab={true}>
                <StyledSocialBarImg src={apiClient.getAssetPath(youtubeLogo)} alt="Youtube" />
            </LinkContainer>
        </StyledSocialBar>
    )
}
