import { format, add } from 'date-fns'
import * as React from 'react'

export const PriceEffective = () => {
    const today = new Date()
    const thisMonth = format(today, 'LLLL 5')
    const nextMonthAndYear = format(add(today, { months: 1 }), 'LLLL 5 y')
    return (
        <span>
            Price effective {thisMonth} - {nextMonthAndYear}.
        </span>
    )
}
