import * as React from 'react'

import { PaymentExtension } from '@igs-web/common-models/models/user-profile-model'
import { formatAsMoney, MoneyFormat } from '@igs-web/common-utilities/utilities/currency-utilities'
import { formatDate } from '@igs-web/common-utilities/utilities/date-utilities'

import { PaymentExtensionNavigationButtons } from './payment-extension-nav-buttons'
import { PaymentExtensionProps } from './payment-extension-page'

export const ExistingPaymentExtension = ({
    billingAccountNumber,
    amountDue: pastDueBalance,
    orginalDueDate,
    paymentExtension,
    isNewExtension,
}: ExistingPaymentExtensionProps) => {
    return (
        <>
            {isNewExtension && <p>Your payment extension has been created.</p>}
            {!isNewExtension && <p>You already have a payment extension.</p>}
            {isNewExtension && <p>Check for an email confirmation in your inbox</p>}
            <p>
                <strong>Extension:</strong> Pay <strong>{formatAsMoney(pastDueBalance, MoneyFormat.Dollars)}</strong> on or before{' '}
                <strong>{formatDate(paymentExtension?.exisitingPaymentExtensionDueDate)}</strong>
                <br />
                (original due date was {formatDate(orginalDueDate)})
            </p>
            <PaymentExtensionNavigationButtons billingAccountNumber={billingAccountNumber} />
        </>
    )
}

interface ExistingPaymentExtensionProps extends PaymentExtensionProps {
    readonly orginalDueDate?: number
    readonly paymentExtension?: PaymentExtension
    readonly isNewExtension?: boolean
}
