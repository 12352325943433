import * as React from 'react'
import { BsFillExclamationCircleFill } from 'react-icons/bs'

import styled from 'styled-components'

import { AlertCard } from '@igs-web/common-components/domain/alerts/alert-card'
import { secondaryDark } from '@igs-web/common-ui-components/styles/theme'

const ExclamationPointIcon = styled(BsFillExclamationCircleFill)`
    background-color: ${secondaryDark};
`

export const PendingContractAlert = () => (
    <AlertCard dataTestId="pending-contract-alert">
        <span>
            <ExclamationPointIcon />
        </span>{' '}
        Contract has pending changes
    </AlertCard>
)
