import * as React from 'react'

import { AnimatePresence } from 'framer-motion'

import { Caret } from '@igs-web/common-ui-components/_atoms/caret'
import { Panel } from '@igs-web/common-ui-components/_atoms/panel'

const panel = {
    open: {
        clipPath: 'inset(-10px -16px -7%)',
        opacity: 1,
    },
    close: {
        clipPath: 'inset(0 0 100%)',
        opacity: 0,
    },
}

const transitionProperties = {
    type: 'spring',
    damping: 20,
    stiffness: 150,
}

export const PopPanel = ({ children, isOpen, alignRight = false, width, showOnMobile = false, className }: Props) => (
    <AnimatePresence>
        {isOpen && (
            <>
                <Caret
                    style={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={transitionProperties}
                    data-testid="caret"
                    $showOnMobile={showOnMobile}
                />
                <Panel
                    key="menu"
                    style={{ clipPath: 'inset(0 0 100%)', opacity: 0 }}
                    animate="open"
                    exit="closed"
                    variants={panel}
                    transition={transitionProperties}
                    $alignRight={alignRight}
                    data-testid="nav-menu-popout"
                    $width={width}
                    $showOnMobile={showOnMobile}
                    className={className}
                >
                    {children}
                </Panel>
            </>
        )}
    </AnimatePresence>
)

interface Props {
    readonly children: React.ReactNode
    readonly isOpen: boolean
    readonly alignRight?: boolean
    readonly width?: string
    readonly showOnMobile?: boolean
    readonly className?: string
}
