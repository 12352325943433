import * as React from 'react'

import { MembershipApplication, scanaMemberships } from '@igs-web/common-models/constants/membership-application'
import { BoxedWarningAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { ExternalLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { InfoText } from '@igs-web/common-ui-components/_molecules/info-text'

import { SupportedCompanies } from '../company/company'
import { igsCompany } from '../company/igs/igs-company'
import { scanaRegulatedCompany } from '../company/scana-regulated/scana-regulated-company'
import { scanaCompany } from '../company/scana/scana-company'

export const GetMultipleMembershipWarning = ({ memberships: userMemberships, companyName }: Props) => {
    if (companyName === SupportedCompanies.Igs && userMemberships.filter(m => scanaMemberships.includes(m)).length > 0) {
        return (
            <BoxedWarningAlert>
                <p>
                    Please note: Your IGS Energy
                    <GetScanaLinks memberships={userMemberships} /> accounts are linked.{' '}
                    <strong>If you update your profile info here, it will also update your SCANA Energy profile.</strong>
                </p>
                <p>You may receive emails from both IGS Energy and SCANA Energy confirming the change.</p>
            </BoxedWarningAlert>
        )
    }
    if (companyName === SupportedCompanies.Scana || companyName === SupportedCompanies.ScanaRegulated) {
        if (userMemberships.includes(MembershipApplication.Igs)) {
            return (
                <InfoText>
                    <p>
                        Please note: Your SCANA Energy and <ExternalLink href={igsCompany().externalWebsite}>IGS Energy</ExternalLink> accounts are linked.{' '}
                        <strong>If you update your profile info here, it will also update your IGS Energy profile.</strong>
                    </p>
                    <p>You may receive emails from both IGS Energy and SCANA Energy confirming the change.</p>
                </InfoText>
            )
        }
    }

    return <></>
}

const GetScanaLinks = ({ memberships }: GetScanaLinksProps) => {
    const isRegAndDereg = memberships.includes(MembershipApplication.Scana) && memberships.includes(MembershipApplication.ScanaRegulated)
    return (
        <>
            {!isRegAndDereg ? ' and ' : ', '}
            {memberships.includes(MembershipApplication.Scana) && <ExternalLink href={scanaCompany().externalWebsite}>SCANA Energy</ExternalLink>}
            {isRegAndDereg && ' and '}
            {memberships.includes(MembershipApplication.ScanaRegulated) && (
                <ExternalLink href={scanaRegulatedCompany().externalWebsite}>{scanaRegulatedCompany().displayName}</ExternalLink>
            )}
        </>
    )
}

interface Props {
    readonly memberships: ReadonlyArray<string>
    readonly companyName: SupportedCompanies | ''
}

interface GetScanaLinksProps {
    readonly memberships: ReadonlyArray<string>
}
