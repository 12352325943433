import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { ConfirmationSelectors } from '@igs-web/common-components/domain/enrollment/confirmation/confirmation-redux'
import { enrollmentModelActions } from '@igs-web/common-components/domain/enrollment/enrollment-redux-actions'
import { OfferRequestState, OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { EnrollmentModel } from '@igs-web/common-models/models/enrollment-model'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { CreditCheckStatus } from '@igs-web/common-utilities/api/enrollment-api-client'
import { CHECKOUT_STORAGE_KEY } from '@igs-web/common-utilities/constants/constants'
import { clearFromStorage, getFromStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

import { NavigationBanner } from 'molecules/header/navigation-banner'

import * as Routes from 'domain/routes'

import { Checkout2x2CardGrid } from '../shared/checkout-components'
import { ConfirmationContainer, ConfirmationRateCard, ConfirmationTitle, OrderDetailsTitle } from '../shared/confirmation-components'
import { AddressCardDisplay } from '../your-plan/address-card'
import { MyDetailsCardDisplay } from '../your-plan/my-details-card'
import { StartDateCardDisplay } from '../your-plan/start-date-card'

import { DepositInstructions } from './deposit-instructions'

export const Confirmation = () => {
    const confirmation = useSelector((store: CommonReduxState) => ConfirmationSelectors.selectConfirmationInfo(store))
    const storedEnrollmentModel = getFromStorage<EnrollmentModel>(CHECKOUT_STORAGE_KEY, false)
    const storedOfferRequest = useSelector((store: CommonReduxState) => OfferSelectors.selectOfferRequest(store))
    const dispatch = useDispatch()
    const company = useCompany()
    const history = useHistory()

    const [enrollmentRequest, setEnrollmentRequest] = React.useState<EnrollmentModel | null>(null)
    const [offerRequest, setOfferRequest] = React.useState<OfferRequestState | null>(null)

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    React.useEffect(() => {
        if (!confirmation || confirmation.offers.filter(o => o.confirmationNumber).length === 0) {
            history.push(Routes.CheckoutRoute)
        }
    }, [confirmation, history])

    React.useEffect(() => {
        if (!!storedEnrollmentModel) {
            setEnrollmentRequest(storedEnrollmentModel)
            setOfferRequest(storedOfferRequest)
            dispatch(enrollmentModelActions.clearAfterSubmit())
            clearFromStorage(CHECKOUT_STORAGE_KEY)
        }
    }, [storedEnrollmentModel, dispatch])

    if (!confirmation || confirmation.offers.length === 0) {
        return <></>
    }

    const confirmationOffer = confirmation.offers[0]
    return (
        <>
            <NavigationBanner title="Confirmation" />
            <ConfirmationContainer>
                <ConfirmationTitle
                    displayName={company.displayName}
                    emailAddress={enrollmentRequest?.defaultContact.emailAddress}
                    confirmationNumber={confirmationOffer.confirmationNumber}
                />
                {enrollmentRequest?.creditCheckResults?.energyScoreStatus === CreditCheckStatus.Collect && (
                    <>
                        <GridSpacer spacing={Spacing.ExtraLarge} />
                        <DepositInstructions
                            referenceNumber={confirmationOffer.depositReferenceNumber}
                            depositAmount={enrollmentRequest.creditCheckResults.depositAmount}
                        />
                    </>
                )}
                <GridSpacer spacing={Spacing.ExtraLarge} />
                <OrderDetailsTitle />
                <p>
                    <strong>
                        Detailed information about your rate plan, along with terms and conditions of service, will be sent to you via your preferred method of
                        communication.
                    </strong>
                </p>
                <Checkout2x2CardGrid>
                    {offerRequest && <ConfirmationRateCard offer={confirmationOffer} offerRequest={offerRequest} />}
                    {enrollmentRequest?.serviceAddress && <AddressCardDisplay serviceAddress={enrollmentRequest?.serviceAddress} />}
                    {enrollmentRequest && <MyDetailsCardDisplay enrollment={enrollmentRequest} supressBillingAccountDisplay={false} />}
                    {enrollmentRequest && <StartDateCardDisplay enrollment={enrollmentRequest} />}
                </Checkout2x2CardGrid>
                <GridSpacer spacing={Spacing.ExtraLarge} />
                <IconHeading>We're committed to our local community</IconHeading>
                <p>
                    Our commitment to our customers includes being a good neighbor and making a difference in the lives of the customers we serve. As a company
                    and as individuals, we volunteer time and donate money and resources to a variety of organizations in Georgia.
                </p>

                <p>
                    <Link href={`${company.externalWebsite}/community`}>Learn about our community support</Link>
                </p>
                <GridSpacer spacing={Spacing.ExtraLarge} />
            </ConfirmationContainer>
        </>
    )
}
