import { EnrollmentModel } from '@igs-web/common-models/models/enrollment-model'
import { EnrollmentOrderItemResponse, EnrollmentResponse } from '@igs-web/common-models/models/enrollment-response'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { OfferModel, ProductModel } from '@igs-web/common-models/models/offer-model'

const addConfirmationNumberToFieldsForProduct = (response: EnrollmentOrderItemResponse, fields: UniversalAnalytics.FieldsObject) => {
    switch (response.lineOfBusinessCode) {
        case LineOfBusinessCode.Electric:
            fields.dimension1 = response.confirmationNumber
            break
        case LineOfBusinessCode.Gas:
            fields.dimension2 = response.confirmationNumber
            break
        case LineOfBusinessCode.HomeWarranty:
            fields.dimension3 = response.confirmationNumber
            break
        default:
            throw Error(`Line of business code ${response.lineOfBusinessCode} is not recognized`)
    }
}

export const pushConfirmationNumbersToGA = (response: EnrollmentResponse) => {
    const fields: UniversalAnalytics.FieldsObject = {}
    return new Promise<void>((resolve, reject) => {
        response.orderItems.forEach(r => addConfirmationNumberToFieldsForProduct(r, fields))
        fields.dimension4 = 'B_Page'
        fields.hitCallback = () => {
            resolve()
        }
        ga('send', 'pageview', fields)
    })
}

const addTransaction = (transaction: Transaction) => {
    return new Promise((resolve, reject) => {
        if (ga) {
            ga('ecommerce:addTransaction', {
                id: transaction.transactionId,
                affiliation: transaction.affiliation,
                revenue: transaction.total,
                shipping: transaction.shipping,
                tax: transaction.tax,
            })
        } else {
            reject()
        }
    })
}

const addItem = (item: ECommerceItem) => {
    return new Promise((resolve, reject) => {
        if (ga) {
            ga('ecommerce:addItem', {
                id: item.transactionId,
                name: item.productName,
                sku: item.sku,
                category: item.category,
                price: item.unitPrice,
                quantity: item.quantity,
            })
        }
    })
}

const getSerivceTypeCode = (lineOfBusinessCode: LineOfBusinessCode): string => {
    switch (lineOfBusinessCode) {
        case LineOfBusinessCode.Electric:
            return 'EL'
        case LineOfBusinessCode.Gas:
            return 'GA'
        case LineOfBusinessCode.HomeWarranty:
            return 'HP'
        default:
            throw Error(`Line of business code ${lineOfBusinessCode} is not recognized`)
    }
}

const getECommerceItem = (identifier: string, product: ProductModel): ECommerceItem => {
    const { productCode, lineOfBusinessCode } = product

    const serviceTypeCode = getSerivceTypeCode(lineOfBusinessCode)

    return {
        transactionId: identifier,
        sku: productCode,
        productName: serviceTypeCode,
        category: serviceTypeCode,
        unitPrice: 100,
        quantity: 1,
    }
}

export const pushECommerceTrackingToGA = (identifier: string, enrollmentModel: EnrollmentModel, selectedOffers: ReadonlyArray<OfferModel>) => {
    const items = selectedOffers.map(offer => getECommerceItem(identifier, offer.secondaryProduct || offer.primaryProduct))

    const transaction = {
        transactionId: identifier,
        affiliation: enrollmentModel.serviceAddress && enrollmentModel.serviceAddress.state,
        total: items.reduce((t, i) => t + i.unitPrice, 0),
    }

    addTransaction(transaction)
    items.map(addItem)

    ga('ecommerce:send')
}

interface ECommerceItem {
    readonly transactionId: string
    readonly sku: string
    readonly productName: string
    readonly category: string
    readonly unitPrice: number
    readonly quantity: number
}

interface Transaction {
    readonly transactionId: string
    readonly affiliation?: string
    readonly total?: number
    readonly tax?: string
    readonly shipping?: number
}
