import { InvitationCodeDetailsResponse } from '@igs-web/common-models/models/invitation-code-detail-response'
import { apiClient, RequestConfig } from '@igs-web/common-utilities/api/api-client'

class InvitationCodeApiClient {
    public readonly getInvitationCodeDetails = async (code: string, config?: RequestConfig) =>
        await apiClient.get<InvitationCodeDetailsResponse>(`${apiClient.apiBaseUrl}/CampaignDetails?code=${code}`, config)

    public readonly getPageExists = async (url: string, config?: RequestConfig) =>
        await apiClient.get<boolean>(`${apiClient.sitefinityApiUrl}/data-api/DoesPageExist?url=${url}`, config)
}

export const invitationCodeApiClient = new InvitationCodeApiClient()
