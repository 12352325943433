/* eslint-disable max-lines */
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { Link as StandardLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { zipCodePattern } from '@igs-web/common-ui-components/_atoms/react-hooks-helpers'
import { Checkbox, Input, Select, SelectOption, Textarea } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { GridSpacer, commonGridCss } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText, InfoTextType } from '@igs-web/common-ui-components/_molecules/info-text'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeSmall } from '@igs-web/common-ui-components/styles/theme'
import { contactUsApiClient } from '@igs-web/common-utilities/api/contact-us-api-client'
import { emailRegex } from '@igs-web/common-utilities/utilities/form-validation'
import { InputHelperText } from '@igs/react-styled-components'

import { LayoutSection } from 'molecules/layout/layout-styles'

import { TermsContainer } from 'domain/authentication/authentication-components'
import * as Routes from 'domain/routes'

const ContactFormGrid = styled.div`
    ${commonGridCss}
    grid-template-columns: 1fr;
    > label {
        margin-bottom: 0;
        span {
            font-size: ${fontSizeSmall};
        }
    }
`

const InfoContainer = styled.div`
    grid-area: info;
`

enum ContactFormType {
    accountInformation = 'ACCOUNTINFORMATION',
    payMyBill = 'PAYMYBILL',
    ratesPricing = 'RATESPRICING',
    manageCosts = 'MANAGECOSTS',
    usernamePassword = 'USERNAMEPASSWORD',
    onlineSupport = 'ONLINESUPPORT',
    other = 'OTHER',
    homeWarranty = 'HOMEWARRANTY',
}

const contactFormOptions = [
    {
        value: ContactFormType.accountInformation,
        text: 'Account Information',
    },
    {
        value: ContactFormType.payMyBill,
        text: 'Paying My Bill',
    },

    {
        value: ContactFormType.homeWarranty,
        text: 'Home Warranty',
    },

    {
        value: ContactFormType.ratesPricing,
        text: 'Rates & Pricing',
    },

    {
        value: ContactFormType.manageCosts,
        text: 'Manage My Energy Costs',
    },

    {
        value: ContactFormType.usernamePassword,
        text: 'Email / Password',
    },

    {
        value: ContactFormType.onlineSupport,
        text: 'Online Support',
    },

    {
        value: ContactFormType.other,
        text: 'Something Else',
    },
]

export const ContactUsForm = ({ afterSubmit, setIsHomeWarrantyRequest }: Props) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid, isSubmitted },
    } = useForm<ContactUsFormFields>()
    const company = useCompany()
    const history = useHistory()
    const [formSubmissionError, setFormSubmissionError] = React.useState(false)
    const onSubmit = async data => {
        const question = contactFormOptions.find(opt => opt.value === data.question)?.text
        try {
            setIsHomeWarrantyRequest(isHomeWarrantyInquiry)
            setFormSubmissionError(false)
            await contactUsApiClient.contactUs({
                ...data,
                question,
            })
            if (afterSubmit) {
                afterSubmit()
            }
        } catch (e) {
            setFormSubmissionError(true)
        }
    }
    const { externalWebsite, displayName } = useCompany()
    const questionType = watch('question')
    const billingAccountNumber = watch('billingAccountNumber', '')
    const email = watch('email', '')
    const phoneNumber = watch('phoneNumber', '')

    const isHomeWarrantyInquiry = questionType === ContactFormType.homeWarranty
    const showUsernamePassword = questionType === ContactFormType.usernamePassword
    const homeWarrantyResponseTime = 24
    const commodityResponseTime = 72

    return (
        <>
            <InfoContainer>
                {!isSubmitted && (
                    <InfoText>
                        Please allow {isHomeWarrantyInquiry ? homeWarrantyResponseTime : commodityResponseTime} hours for response.
                        <br />
                        If you have an urgent request call{' '}
                        <PhoneNumberLink phoneNumber={isHomeWarrantyInquiry ? company.phoneNumber.homeWarranty : company.phoneNumber.customerSupport} />
                    </InfoText>
                )}
                {formSubmissionError && (
                    <>
                        <InputHelperText error="There was an error submitting the form." currentLength={0} />
                        <GridSpacer spacing={Spacing.Medium} />
                    </>
                )}
                {!isValid && isSubmitted && (
                    <>
                        <InfoText $infoTextType={InfoTextType.Error}> There are items that need your attention.</InfoText>
                        <GridSpacer spacing={Spacing.Medium} />
                    </>
                )}
            </InfoContainer>
            <LayoutSection>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ContactFormGrid>
                        <Select
                            {...register('question', { required: 'Required. Please select a question.' })}
                            dataTestId="question-select"
                            allowClear={false}
                            clearOptionText="Select One"
                            label="I have a question about:"
                            error={errors.question?.message}
                            hideHelperText={!errors.question}
                        >
                            {contactFormOptions.map(q => (
                                <SelectOption key={q.value} dataTestId={`question-${q.value}`} value={q.value}>
                                    {q.text}
                                </SelectOption>
                            ))}
                        </Select>
                        {showUsernamePassword ? (
                            <>
                                <Button onClick={() => history.push(Routes.ForgotPassword)}>Forgot My Email/Password</Button>
                                <div>
                                    Otherwise call us for immediate help <PhoneNumberLink phoneNumber={company.phoneNumber.customerSupport} />
                                </div>
                            </>
                        ) : (
                            <>
                                <Input
                                    {...register('fullName', { required: 'Required. Please enter your full name.' })}
                                    dataTestId="full-name"
                                    defaultValue=""
                                    label="Full Name"
                                    maxLength={100}
                                    autoComplete="name"
                                    error={errors.fullName?.message}
                                    hideHelperText={!errors.fullName}
                                />
                                {!isHomeWarrantyInquiry && (
                                    <>
                                        <Input
                                            {...register('billingAccountNumber', {
                                                validate: { required: value => value !== '' || watch('noBillingAccountNumber') },
                                                shouldUnregister: true,
                                            })}
                                            dataTestId="billing-account-number"
                                            defaultValue=""
                                            label="Billing Account Number"
                                            maxLength={100}
                                            error={errors.billingAccountNumber ? 'Required.' : undefined}
                                            hideHelperText={true}
                                        />
                                        <div>
                                            <Checkbox
                                                {...register('noBillingAccountNumber', {
                                                    validate: { required: value => billingAccountNumber !== '' || value },
                                                    shouldUnregister: true,
                                                })}
                                                dataTestId="no-billing-account-number"
                                                label="I do not have an account number"
                                                $error={!!errors.billingAccountNumber}
                                            />
                                            {!!errors.billingAccountNumber && (
                                                <InputHelperText
                                                    error="Required. Please enter your billing account number or indicate you do not have one."
                                                    currentLength={0}
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                                <Input
                                    {...register('phoneNumber', {
                                        validate: { required: value => value !== '' || email !== '' },
                                    })}
                                    dataTestId="contact-us-phone-number"
                                    label="Phone Number"
                                    maxLength={15}
                                    type="tel"
                                    error={errors.phoneNumber ? 'Required. Please enter a contact phone number or email.' : ''}
                                    hideHelperText={!errors.phoneNumber}
                                />
                                {isHomeWarrantyInquiry ? (
                                    <Input
                                        {...register('email', {
                                            pattern: { value: emailRegex, message: 'Invalid Email' },
                                            validate: { required: value => value === '' || value !== '' },
                                        })}
                                        dataTestId="contact-us-email"
                                        label="Contact Email Address (Optional)"
                                        type="email"
                                        autoComplete="email"
                                        maxLength={100}
                                        error={errors.email ? errors.email.message : ''}
                                        hideHelperText={true}
                                    />
                                ) : (
                                    <Input
                                        {...register('email', {
                                            pattern: { value: emailRegex, message: 'Invalid Email' },
                                            validate: { required: value => value !== '' || phoneNumber !== '' },
                                            shouldUnregister: true,
                                        })}
                                        dataTestId="contact-us-email"
                                        label="Contact Email Address"
                                        type="email"
                                        autoComplete="email"
                                        maxLength={100}
                                        error={errors.email ? 'Required. Please enter a contact email or phone number.' : ''}
                                        hideHelperText={!errors.email}
                                    />
                                )}
                                <Input
                                    {...register('serviceAddress')}
                                    dataTestId="service-address"
                                    label="Service Address"
                                    autoComplete="address-line1"
                                    error={errors.serviceAddress?.message}
                                    maxLength={100}
                                    hideHelperText={!errors.serviceAddress}
                                />

                                <Input
                                    {...register('zipCode', {
                                        pattern: zipCodePattern,
                                    })}
                                    label="Zip Code"
                                    dataTestId="zip-code"
                                    error={errors.zipCode?.message}
                                    maxLength={12}
                                    inputMode="numeric"
                                    hideHelperText={!errors.zipCode}
                                />
                                <Textarea
                                    {...register('message', {
                                        required: 'Required. Please enter a message.',
                                    })}
                                    label="Message"
                                    dataTestId="contact-us-message"
                                    error={errors.message?.message}
                                    rows={10}
                                    hideHelperText={!errors.message}
                                />
                                <Button type="submit">Submit</Button>
                            </>
                        )}
                    </ContactFormGrid>
                </form>
                {!showUsernamePassword && (
                    <TermsContainer>
                        By using this form and proceeding, you agree that you have read and accepted {displayName}'s{' '}
                        <StandardLink href={`${externalWebsite}/home/terms-conditions`}>Legal Terms and Conditions</StandardLink> and{' '}
                        <StandardLink href={`${externalWebsite}/home/privacy`}>Privacy.</StandardLink>
                    </TermsContainer>
                )}
            </LayoutSection>
        </>
    )
}

interface Props {
    readonly afterSubmit?: () => void
    readonly setIsHomeWarrantyRequest: (isHomeWarrantyRequest: boolean) => void
}

interface ContactUsFormFields {
    readonly question: ContactFormType
    readonly fullName: string
    readonly billingAccountNumber: string
    readonly noBillingAccountNumber: boolean
    readonly phoneNumber: string
    readonly email: string
    readonly serviceAddress: string
    readonly zipCode: string
    readonly message: string
}
