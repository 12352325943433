import { EnrollmentOrderItemResponse, EnrollmentResponse, OfferModel } from '@igs-web/common-models/models'
import { GaEcommerceEventModel, GaEvent, GaSalesType, ItemEventModel } from '@igs-web/common-models/models/google-analytics-models'
import { fireCustomFullStoryEvent } from '@igs-web/common-utilities/utilities/fs-logger'

import { Environment, getEnvironment } from '../utilities/environment-utilities'

const isProd = () => getEnvironment() === Environment.Prod

export const getEventModel = (selectedOffers: ReadonlyArray<OfferModel>, enrollmentResponse?: EnrollmentResponse): GaEcommerceEventModel => {
    return {
        campaignCode: selectedOffers[0]?.primaryProduct.campaignCode,
        coupon: selectedOffers[0]?.primaryProduct.invitationCode,
        transction_Id: enrollmentResponse?.orderId,
        currency: 'USD',
        items: getItemEventModel(selectedOffers, enrollmentResponse?.orderItems),
    }
}

export const getItemEventModel = (
    selectedOffers: ReadonlyArray<OfferModel>,
    orderItems?: ReadonlyArray<EnrollmentOrderItemResponse>,
): ReadonlyArray<ItemEventModel> => {
    return selectedOffers.map((selectedOffer, index) => {
        const primaryProduct = selectedOffer?.primaryProduct
        const orderItem = orderItems?.find(o => o.lineOfBusinessCode === primaryProduct.lineOfBusinessCode)
        return {
            index,
            item_id: selectedOffer?.offerId,
            item_name: primaryProduct?.productCode,
            discount: primaryProduct?.discountAmount,
            price: primaryProduct?.price,
            unitOfMeasure: primaryProduct?.unitOfMeasure,
            priceType: primaryProduct?.productType,
            cancelFee: primaryProduct?.cancelFee,
            termLength: primaryProduct?.termMonths ?? primaryProduct?.termEndMonth,
            ulob: primaryProduct?.ulob?.utilityLineOfBusinessCode,
            lob: primaryProduct.lineOfBusinessCode,
            state: primaryProduct?.ulob?.stateCode,
            confirmationNumber: orderItem?.confirmationNumber,
        } as ItemEventModel
    })
}

export const getCartInteractionModel = (offer: OfferModel): GaEcommerceEventModel => {
    const primaryProduct = offer.primaryProduct
    const item = {
        index: 0,
        item_id: offer?.offerId,
        item_name: primaryProduct?.productCode,
        discount: primaryProduct?.discountAmount,
        price: primaryProduct?.price,
        unitOfMeasure: primaryProduct?.unitOfMeasure,
        priceType: primaryProduct?.productType,
        cancelFee: primaryProduct?.cancelFee,
        termLength: primaryProduct?.termMonths ?? primaryProduct?.termEndMonth,
        ulob: primaryProduct?.ulob?.utilityLineOfBusinessCode,
        state: primaryProduct?.ulob?.stateCode,
    } as ItemEventModel
    return {
        campaignCode: offer.primaryProduct.campaignCode,
        coupon: offer.primaryProduct.invitationCode,
        currency: 'USD',
        items: [item],
    } as GaEcommerceEventModel
}

const clearEcommerce = () => {
    if (window.dataLayer && isProd()) {
        window.dataLayer.push({ ecommerce: null })
    }
}

const pushMessageToGa = (message: any) => {
    if (window.dataLayer) {
        if (isProd()) {
            window.dataLayer.push(message)
        } else {
            console.log(message)
        }
    } else {
        throw new Error('GTM dataLayer is not defined')
    }
    fireCustomFullStoryEvent(message.event ?? 'GA-Event', message)
}

export const pushGaEventWithAction = (event: string, eventItems: GaEcommerceEventModel, action: () => void) => {
    try {
        pushMessageToGa({
            event,
            ecommerce: eventItems,
        })
    } catch (e) {
        console.error('There was a problem sending data to Google Analytics', e)
    } finally {
        action()
    }
}

export const pushAddToCartEventtoGa = (eventItems: GaEcommerceEventModel) => {
    clearEcommerce()
    pushMessageToGa({
        event: GaEvent.AddToCart,
        ecommerce: eventItems,
    })
}

export const pushRemoveFromCartEventtoGa = (eventItems: GaEcommerceEventModel) => {
    clearEcommerce()
    pushMessageToGa({
        event: GaEvent.RemoveFromCart,
        ecommerce: eventItems,
    })
}

export const pushLoginEventToGa = () =>
    pushMessageToGa({
        event: GaEvent.Login,
    })

export const pushEnrollmentSubmittedToGa = (eventItems: GaEcommerceEventModel) => {
    pushMessageToGa({
        event: GaEvent.Purchase,
        ecommerce: eventItems,
    })
}
export const pushAddedContactInfoEventToGa = (eventItems: GaEcommerceEventModel, action: () => void) => {
    pushGaEventWithAction(GaEvent.AddContactInfo, eventItems, action)
}
export const pushAddedServiceAddressEvent = (eventItems: GaEcommerceEventModel, action: () => void) => {
    pushGaEventWithAction(GaEvent.AddServiceAddress, eventItems, action)
}

export const pushStartedCheckoutEvent = (eventItems: GaEcommerceEventModel, saleType: GaSalesType) => {
    pushMessageToGa({ event: GaEvent.BeginCheckout, ecommerce: { ...eventItems, saleType } })
}

export const pushAddedProductDetailsEvent = (eventItems: GaEcommerceEventModel, action: () => void) => {
    pushGaEventWithAction(GaEvent.AddProductDetails, eventItems, action)
}

export const pushCreditCheckEvent = (eventItems: GaEcommerceEventModel, action: () => void) => {
    pushGaEventWithAction(GaEvent.CreditCheck, eventItems, action)
}

export const pushSelectedRateEvent = (eventItems: GaEcommerceEventModel, action: () => void) => {
    pushGaEventWithAction(GaEvent.SelectRate, eventItems, action)
}

export const pushReviewOrderEvent = (eventItems: GaEcommerceEventModel) => {
    pushMessageToGa({
        event: GaEvent.ReviewOrder,
        ecommerce: eventItems,
    })
}
export const pushStartDateEvent = (eventItems: GaEcommerceEventModel, action: () => void) => {
    pushGaEventWithAction(GaEvent.StartDate, eventItems, action)
}
export const pushAddedPaymentMethodEvent = (eventItems: GaEcommerceEventModel, action: () => void) => {
    pushGaEventWithAction(GaEvent.AddPaymentMethod, eventItems, action)
}
export const pushEnergyProfileCustInfoEvent = () => {
    pushMessageToGa({
        event: GaEvent.EnergyProfileCustomerInfo,
    })
}
export const pushEnergyProfileRequestInfoEvent = () => {
    pushMessageToGa({
        event: GaEvent.EnergyProfileRequestInfo,
    })
}
export const pushContactUsSubmitEvent = () => {
    pushMessageToGa({
        event: GaEvent.ContactUsSubmission,
    })
}
export const pushEmailMeratesSubmissionEvent = () => {
    pushMessageToGa({
        event: GaEvent.EmailMeRatesSubmission,
    })
}

declare global {
    interface Window {
        // eslint-disable-next-line functional/prefer-readonly-type
        dataLayer?: Record<string, unknown>[]
    }
}
