import styled from 'styled-components'

import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { FontWeight } from '../styles/font-weight'
import { borderBoxColor, fontSizeLarge, headerColor } from '../styles/theme'

export const IconHeading = styled(Header3)`
    font-size: ${fontSizeLarge};
    margin-block: 0;
    padding-bottom: ${Spacing.Large};
    border-bottom: 1px solid ${borderBoxColor};
    svg {
        color: ${headerColor};
        position: relative;
        top: 0.125rem;
    }
`
export const IconSubHeading = styled.span`
    font-weight: ${FontWeight.Light};
    font-size: .75em;
`
