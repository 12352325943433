/* eslint-disable max-len */
import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

const GasIcon = ({ altText = 'Natural Gas', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} id="Layer_1" viewBox="0 0 47.16 44.88" altText={altText}>
        <path d="M34.5,24.58c0,5.35-3.34,8.74-7,9.62,2.65-4.14.37-6.58-1.19-8.25-.8-.86-2.61-2.75-2.18-5.53-2.95,1.26-5.45,5.73-5.45,8.21a8.42,8.42,0,0,0,2.13,5.52,11.65,11.65,0,0,1-8.19-11.5c0-8,6-14.89,14.25-18.49C24,13.18,34.5,16.22,34.5,24.58Zm1.21,13H11.45a.86.86,0,0,0-.86.86v1.42a.85.85,0,0,0,.86.86H35.71a.86.86,0,0,0,.86-.86V38.44A.87.87,0,0,0,35.71,37.58Z" />
    </SvgIcon>
)

export default GasIcon
