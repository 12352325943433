import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { primaryLight } from '@igs-web/common-ui-components/styles/theme'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import * as React from 'react'
import styled from 'styled-components'

const footerNavBorder = 'rgb(230, 230, 230)'

export const ScanaFooterNavContainer = styled.div<ScanaFooterNavContProps>`
    display: grid;
    grid-template-columns: repeat(${p => React.Children.count(p.children)}, 1fr);
    border-top: 1px solid ${footerNavBorder};
    border-bottom: 1px solid ${footerNavBorder};
    padding: ${Spacing.ExtraSmall} ${Spacing.None};
    text-align: center;
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        background: ${footerNavBorder};
    }
`
const StyledFooterNavLink = styled(Link)`
    color: ${primaryLight};
    text-decoration: none;
    font-weight: 600;
    padding: 11px 20px;
    border-left: 1px solid ${footerNavBorder};
    :first-of-type {
        border: none;
    }
`

export const ScanaFooterNavLink = ({ children, ...rest }: FooterNavLinkProps): JSX.Element => <StyledFooterNavLink {...rest}>{children}</StyledFooterNavLink>

type FooterNavLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>

type ScanaFooterNavContProps = React.HTMLAttributes<HTMLDivElement>
