import * as React from 'react'
import { useForm } from 'react-hook-form'

import styled from 'styled-components'

import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { ErrorAlert, SuccessAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { Checkbox } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { CompanyVerbiage, Verbiage } from '../../company/companyVerbiage'
import { useCompany } from '../../company/hooks/useCompany'

const FormGrid = styled.form`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: ${Spacing.Small};
    p {
        margin: 0;
    }
`

export const CommunicationPreferencesForm = ({ onSubmit, initialValues }: Props) => {
    const { register, handleSubmit, reset } = useForm<CommunicationPreferenceFields>({
        defaultValues: initialValues,
    })
    const { verbiage } = useCompany()

    const [formError, setFormError] = React.useState<string | undefined>(undefined)
    const [submitSucceeded, setSubmitSucceeded] = React.useState(false)
    const formSubmit = e => {
        handleSubmit(onSubmit)(e)
            .then(() => {
                setSubmitSucceeded(true)
            })
            .catch(error => {
                reset()
                setFormError(error)
            })
    }

    return (
        <FormGrid onSubmit={formSubmit}>
            <SuccessAlert message="Communication Preferences Changed Successfully" show={submitSucceeded} />
            <ErrorAlert message={formError} />
            <Checkbox {...register('isPaperless')} dataTestId="myprofile-paperless" label="Paperless Documents" />
            <CompanyVerbiage verbiage={verbiage[Verbiage.paperlessCommunicationMessage]} />
            <Button type="submit" data-testid="save-changes-button">
                Save Changes
            </Button>
        </FormGrid>
    )
}

interface Props {
    readonly initialValues: CommunicationPreferenceFields
    readonly onSubmit: (values: CommunicationPreferenceFields) => Promise<object | void>
}

export interface CommunicationPreferenceFields {
    readonly isPaperless: boolean
}
