import * as React from 'react'
import { Tab, TabList, TabPanel } from 'react-tabs'

import styled from 'styled-components'

import { ClaimsDetails } from '@igs-web/common-components/domain/claims/claims-tab'
import { CoverageTab } from '@igs-web/common-components/domain/coverage/coverage-tab'
import { DocumentTable } from '@igs-web/common-components/domain/documents/document-table'
import { HwBillingHistoryTab } from '@igs-web/common-components/domain/hw-billing-history/hw-billing-history-tab'
import { MyAccountTabs } from '@igs-web/common-ui-components/_molecules/myaccount-tabs'

const TabsTitle = styled.h4`
    text-align: center;
`

export const ScanaHwAccountDetailsTabs = ({ accountId }: Props): JSX.Element => {
    const initialTab = -1
    const [tabIndex, setTabIndex] = React.useState(initialTab)

    return (
        <div>
            <TabsTitle>SELECT THE ACCOUNT DETAILS YOU WOULD LIKE TO VIEW</TabsTitle>
            <MyAccountTabs className="react-tabs myaccount-detail-tabs" selectedIndex={tabIndex} onSelect={setTabIndex}>
                <TabList>
                    <Tab data-testid="commodity-details-bills-tab">Bills</Tab>
                    <Tab data-testid="commodity-details-protection-tab">Protection</Tab>
                    <Tab data-testid="commodity-details-documents-tab">Documents</Tab>
                    <Tab data-testid="commodity-details-service-requests-tab">Service Requests</Tab>
                </TabList>
                <TabPanel>
                    <HwBillingHistoryTab accountId={accountId} />
                </TabPanel>
                <TabPanel>
                    <CoverageTab accountId={accountId} />
                </TabPanel>
                <TabPanel>
                    <DocumentTable accountId={accountId} />
                </TabPanel>
                <TabPanel>
                    <ClaimsDetails accountId={accountId} />
                </TabPanel>
            </MyAccountTabs>
        </div>
    )
}

interface Props {
    readonly accountId: number
}
