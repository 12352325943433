import * as React from 'react'
import { useSelector } from 'react-redux'

import debounce from 'lodash-es/debounce'

import { useCheckoutDispatch, useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { LineOfBusinessCode } from '@igs-web/common-models/models'
import { Address } from '@igs-web/common-models/models/address'
import { AddressVerifyResponse } from '@igs-web/common-models/models/address-verify-response'
import { useWizardDispatch } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { addressApiClient } from '@igs-web/common-utilities/api/address-api-client'
import { getEventModel, pushAddedServiceAddressEvent } from '@igs-web/common-utilities/services/google-analytics-services'

import { ScrollToTop } from 'molecules/routing/scroll-to-top'

import { useOffersHandler } from 'domain/checkout/hooks/use-offers-handler'

import { AddressExistingService } from './hw-address-existing-service-step'
import { AddressNotEligibleStep } from './hw-address-not-eligible-step'
import { HwAddressSearchResults } from './hw-address-results-step'
import { AddressSearch } from './hw-address-search-step'

export const enum AddressSearchSteps {
    search = 'search',
    results = 'results',
    existingService = 'existingService',
    addressNotEligible = 'addressNotEligible',
}

export const HwServiceAddressStep = () => {
    const [currentStep, setCurrentStep] = React.useState<string>(AddressSearchSteps.search)
    const [searchResults, setSearchResults] = React.useState<AddressVerifyResponse | null>()
    const offerRequest = useSelector(OfferSelectors.selectOfferRequest)
    const selectedOffers = useSelector(OfferSelectors.selectSelectedOffers)
    const { setEnrollmentState } = useCheckoutDispatch()
    const enrollmentState = useCheckoutState()
    const { nextPage } = useWizardDispatch()
    const offersHandler = useOffersHandler()
    const isHwofferAvailable = async (address: Address) => {
        const selectedHwProduct = selectedOffers.find(o => o.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty)
        return await offersHandler.isHwOfferAvailable(selectedHwProduct?.offerId, offerRequest.campaignCode, address.zipCode, false)
    }

    const selectAddress = async (address: Address) => {
        const isAddressServiced = await addressApiClient.isAddressServiced(address)
        const isZipChanged = offerRequest.zipCode !== address.zipCode
        const isOfferAvaiable = isZipChanged ? await isHwofferAvailable(address) : true

        if (!isOfferAvaiable) {
            setEnrollmentState({
                ...enrollmentState,
                serviceAddress: undefined,
            })
            setCurrentStep(AddressSearchSteps.addressNotEligible)
        } else if (isAddressServiced) {
            setCurrentStep(AddressSearchSteps.existingService)
        } else {
            setEnrollmentState({
                ...enrollmentState,
                serviceAddress: address,
            })
            pushAddedServiceAddressEvent(getEventModel(selectedOffers), nextPage)
        }
    }

    const debouncedSelectAddress = debounce(selectAddress, 500)

    const onSubmit = async (data: Address) => {
        const response = await addressApiClient.verifyAddress(data)
        if (!response) {
            //TODO Add fullstory error log event move customer forward
            selectAddress(data)
        } else {
            const { originalAddress, verifiedAddress } = response
            setSearchResults(response)
            if (response?.isValidAddress || JSON.stringify(originalAddress).toLocaleUpperCase() === JSON.stringify(verifiedAddress).toLocaleUpperCase()) {
                selectAddress(response.verifiedAddress)
            } else {
                setCurrentStep(AddressSearchSteps.results)
            }
        }
    }

    return (
        <>
            <ScrollToTop />
            {currentStep === AddressSearchSteps.search && <AddressSearch enrollmentState={enrollmentState} onSubmit={onSubmit} />}
            {currentStep === AddressSearchSteps.results && searchResults && (
                <HwAddressSearchResults searchResults={searchResults} selectAddress={debouncedSelectAddress} />
            )}
            {currentStep === AddressSearchSteps.existingService && searchResults && <AddressExistingService address={searchResults.originalAddress} />}
            {currentStep === AddressSearchSteps.addressNotEligible && <AddressNotEligibleStep setCurrentStep={setCurrentStep} />}
        </>
    )
}
