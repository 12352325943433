/* eslint-disable max-len */

import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

const HouseIcon = ({ altText = 'Home', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} id="Layer_1" viewBox="0 0 40 40" altText={altText}>
        <g id="Symbols">
            <g transform="translate(-1)" id="House">
                <polygon id="Fill-1" className="st0" points="16.5,24 20.5,24 20.5,20 16.5,20" />
                <polygon id="Fill-3" className="st0" points="21.5,24 25.5,24 25.5,20 21.5,20" />
                <polygon id="Fill-5" className="st0" points="16.5,29 20.5,29 20.5,25 16.5,25" />
                <polygon id="Fill-7" className="st0" points="21.5,29 25.5,29 25.5,25 21.5,25" />
                <g id="Group-11">
                    <path
                        id="Fill-9"
                        className="st0"
                        d="M40.1,22.7L20.8,3.5L9.9,14.3V9.8H5.9v8.5l-4.4,4.3l0,0h4.3v9.5v4h4.1h21.8h4.1v-4v-9.5 L40.1,22.7L40.1,22.7z M9.9,32.2V19.7L20.8,8.9l11,10.9v12.4H9.9z"
                    />
                </g>
            </g>
        </g>
    </SvgIcon>
)

export default HouseIcon
