import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { utilityApiClient } from '@igs-web/common-utilities/api/utility-api-client'
import { CachedReduxDataStateType, setupCachedReduxData } from '../cached-redux/cached-redux-data'

const load = async (zipCode: string, config: RequestConfig) => {
    try {
        return (await utilityApiClient.getUlobsByZipCode(zipCode, config)) || undefined
    } catch (e) {
        console.error(`There was a problem getting ulobs for zip code: ${zipCode}`)
    }
}

const { actions, selectors, saga, reducers } = setupCachedReduxData('ulob', load)

export const ulobActions = actions
export const ulobSelectors = selectors

export const ulobSaga = saga
export const ulobReducer = reducers
export type UlobState = CachedReduxDataStateType<typeof load>
