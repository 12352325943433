import * as React from 'react'

import { ConditionalElement } from '../_atoms/conditional-element'
import { Link } from '../_atoms/link/link'

const solarMyAccount = 'https://myaccount.igssolarpower.com/#/Login'

export const SolarMyAccountLanguage = ({ show }: Props): JSX.Element => (
    <ConditionalElement show={show}>
        <Link href={solarMyAccount} className="login-form-subtitle" dataTestId="solar-my-account-link">
            Looking for your Solar My Account?
        </Link>
    </ConditionalElement>
)

interface Props {
    readonly show: boolean
}
