import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { apiClient } from '@igs-web/common-utilities/api/api-client'
import { buildUrl, GoActionConfig, Url } from '@igs-web/common-utilities/utilities/url-utilities'

class URLHelper {
    public readonly areasWeServe = (isCommercial = false): Url => buildUrl((isCommercial ? '/for-your-business' : '/for-your-home') + '/service-areas')
    public readonly contactUs = (): Url => buildUrl('/contact-us')
    public readonly gateway = (): Url => buildUrl('/')
    public readonly termsOfUse = (): Url => buildUrl('/terms-of-use')
    public readonly privacyPolicy = (): Url => buildUrl('/privacy-policy')
    public readonly usageProfileResults = (): Url => buildUrl('/commercial/energy-profile/results')
    public readonly dashboard = (): Url => buildUrl('/MyAccount/Dashboard')
    public readonly emailVerification = (): Url => buildUrl('/MyAccount/Verification')
    public readonly myHelp = (): Url => buildUrl('/MyAccount/Help')
    public readonly commercial = (): Url => buildUrl('/commercial')
    public readonly netMetering = (): Url => buildUrl('/net-metering')
    public readonly payBill = (): Url => buildUrl('/MyAccount/PayBill')
    public readonly myAccountSelection = (): Url => buildUrl('/my-account-selection')

    public readonly login = (setReturnUrl = false, email?: string | null, resetSuccess = false): Url => {
        let loginUrl = '/login'

        if (setReturnUrl) {
            loginUrl += loginUrl.includes('/login?') ? '&' : '?'
            loginUrl += `returnUrl=${encodeURIComponent(location.href)}`
        }

        if (email) {
            loginUrl += loginUrl.includes('/login?') ? '&' : '?'
            loginUrl += `email=${email}`
        }

        if (resetSuccess) {
            loginUrl += loginUrl.includes('/login?') ? '&' : '?'
            loginUrl += `resetSuccess=${resetSuccess}`
        }

        return buildUrl(loginUrl)
    }

    public readonly hwServiceRequest = (accountId: number): Url => buildUrl(`/MyAccount/ServiceRequest?accountId=${accountId}`)
    public readonly hwUpdatePayment = (): Url => buildUrl('/MyAccount/update-payment')
    private readonly residentialEnrollment = (lineOfBusiness?: LineOfBusinessCode) => buildUrl(`/signup${lineOfBusiness ? `?section=${lineOfBusiness}` : ''}`)
    private readonly commercialEnrollment = (zipCode?: string) =>
        zipCode ? buildUrl(`/product-selection/small-commercial?zipCode=${zipCode}`) : buildUrl('/signup/small-commercial')
    public readonly enrollment = (isCommercial: boolean, zipCode?: string, lineOfBusinessCode?: LineOfBusinessCode) =>
        isCommercial ? this.commercialEnrollment(zipCode) : this.residentialEnrollment(lineOfBusinessCode)
    public readonly error = (): Url => buildUrl('/error')

    public readonly commercialSignup = (): Url => buildUrl('/commercial/energy-profile')

    public readonly getMyaccountTutorialUrl = (folder: string, fileName: string, slideNumber: number, isMobile: boolean) =>
        apiClient.getAssetPath(`tutorial/${folder}/${isMobile ? 'mobile/' : 'desktop/'}${fileName}${isMobile ? '-mobile' : ''}-${slideNumber}.jpg`)

    public readonly authorizationForPayment = (isRecurring: boolean, lineOfBusinessCode?: LineOfBusinessCode) => {
        const lob =
            lineOfBusinessCode && (lineOfBusinessCode === LineOfBusinessCode.HomeWarranty || lineOfBusinessCode === LineOfBusinessCode.Commercial)
                ? lineOfBusinessCode.toLowerCase()
                : 'commodity'
        return apiClient.getDocPath(`legal/payment/${lob}/${isRecurring ? 'recurring' : 'single'}/Authorization%20for%20Payment.pdf`)
    }

    public readonly electronicCommunicationConsent = () => apiClient.getDocPath('legal/Consent%20for%20Electronic%20Communications.pdf')

    public readonly energyChoice = (): Url => buildUrl('/energy-resource-center/blog/-in-tags/tags/choice')
    public readonly ourCommitment = (): Url => buildUrl('/energy-resource-center/blog/our-2040-commitment')
    public readonly go = (url: string, config?: GoActionConfig) => {
        if (config && config.preventIfInDesignMode && (window as any).isSitefinityDesignMode) {
            return
        }

        window.location.assign(url)
    }
}

export const urlHelper = new URLHelper()
