import { PricingTier } from '@igs-web/common-models/models/offer-model'
import { enrollmentApiClient } from '@igs-web/common-utilities/api/enrollment-api-client'

import { DefaultRoutes, PaymentRoutes } from '../payment/payment-routes'
import { productSelectionUrl } from '../general-routes'

export interface ProductRoutes {
    readonly termsAndConditions: (
        productCode?: string,
        accountType?: string,
        zipCode?: string,
        offerCode?: string | null,
        campaignCode?: string | null,
        priceTeir?: PricingTier,
    ) => string | undefined

    readonly productSelection: (hasSmallCommercialAccount?: boolean) => string | undefined
}

export interface GeneralRoutes {
    readonly faq: string
}

export const defaultGeneralRoutes: GeneralRoutes = {
    faq: '/MyAccount/Help',
}

export const defaultProductRoutes: ProductRoutes = {
    termsAndConditions: enrollmentApiClient.getTermsAndConditionsUrl,
    productSelection: hasSmallCommercialAccount => productSelectionUrl(undefined, hasSmallCommercialAccount).asString(),
}

export interface RouteFeatures {
    readonly payment: PaymentRoutes
    readonly general: GeneralRoutes
    readonly product: ProductRoutes
}

export const defaultRouteFeatures: RouteFeatures = {
    payment: DefaultRoutes,
    general: { ...defaultGeneralRoutes },
    product: { ...defaultProductRoutes },
}
