import { useAccordionGroup } from './accordion-group-context'

interface AccordionItem {
    readonly isExpanded: boolean
    readonly expand: () => void
    readonly collapse: () => void
    readonly toggleExpanded: () => void
}

export const useAccordionItem = (itemId: string): AccordionItem => {
    const { expandedIds, setExpandedIds, onlyOneExpanded } = useAccordionGroup()
    const isExpanded = expandedIds.includes(itemId)

    const expand = () => (onlyOneExpanded ? setExpandedIds([itemId]) : setExpandedIds(expandedIds.concat(itemId)))
    const collapse = () => setExpandedIds(expandedIds.filter(s => s !== itemId))
    const toggleExpanded = () => (isExpanded ? collapse() : expand())

    return {
        isExpanded,
        expand,
        collapse,
        toggleExpanded,
    }
}
