import { Duration, Interval, addMilliseconds, format, intervalToDuration, parseISO } from 'date-fns'

import { rangeInclusive } from './array-utilities'
import { Locale, locales } from './internationalization-utilities'
import { percentOfRange } from './number-utilites'

const maxDateTicks = 8640000000000000
export const maxDate = new Date(maxDateTicks)
export const minDate = new Date(-maxDateTicks)

export const SECONDS_PER_MINUTE = 60
export const MINUTES_PER_HOUR = 60
export const HOURS_PER_DAY = 24

export const TICKS_PER_SECOND = 1000
export const TICKS_PER_MINUTE = TICKS_PER_SECOND * SECONDS_PER_MINUTE
export const TICKS_PER_HOUR = TICKS_PER_MINUTE * MINUTES_PER_HOUR
export const TICKS_PER_DAY = TICKS_PER_HOUR * HOURS_PER_DAY
export const AVG_DAYS_PER_YEAR = 365 //being used as an estimate for terms

export const MONTHS_PER_YEAR = 12

type DateLike = Date | number | string

export { addMilliseconds }

export const asDate = (date: DateLike) => (typeof date === 'number' || typeof date === 'string' ? new Date(date) : date)

export function isWeekendDay(date: Date): boolean {
    const dayOfWeek = date.getDay()
    return dayOfWeek === weekDay.Saturday || dayOfWeek === weekDay.Sunday
}

export const addBusinessDays = (startDate: Date, days: number): Date => {
    let tempDate = startDate
    let daysToAdd = days

    while (daysToAdd > 0 || isWeekendDay(tempDate)) {
        if (!isWeekendDay(tempDate)) {
            daysToAdd--
        }
        tempDate = addDays(tempDate, 1)
    }

    return tempDate
}

export const atStartOfBussinessDay = (date: Date, locale?: Locale) => {
    const temp = new Date(date)
    temp.setHours(8, 0, 0)
    const localeDate = locale ? changeTimezone(temp, locale) : temp
    return localeDate
}

export const atEndOfBussinessDay = (date: Date, locale?: Locale) => {
    const temp = new Date(date)
    temp.setHours(17, 0, 0)
    const localeDate = locale ? changeTimezone(temp, locale) : temp
    return localeDate
}

const changeTimezone = (date: Date, locale: Locale) => {
    const localeDate = convertToLocale(date, locale)

    const diff = date.getTime() - localeDate.getTime()

    return new Date(date.getTime() + diff)
}

export function addDays(date: Date, days: number): Date {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
}

export function subtractDays(date: Date, days: number): Date {
    return addDays(date, -days)
}

export function addMonth(date: Date, monthAdder: number): Date {
    const result = new Date(date)
    result.setMonth(result.getMonth() + monthAdder)
    return result
}

export function addYears(date: Date, years: number): Date {
    const result = new Date(date)
    result.setFullYear(result.getFullYear() + years)
    return result
}

export function subtractYears(date: Date, years: number): Date {
    return addYears(date, -years)
}

export function yearsAgo(years: number): number {
    const currentDate = new Date()
    return subtractYears(currentDate, years).getTime()
}

export const getEasternTimezoneDate = (date: DateLike): number => {
    return convertToLocale(date, locales.eastern).getTime()
}

const convertToLocale = (date: DateLike, locale: Locale): Date => {
    const parsedDate = asDate(date)
    try {
        const localeDateStr = parsedDate.toLocaleString(locale.language, { timeZone: locale.timeZone })
        const localeDate = new Date(localeDateStr)

        if (isNaN(localeDate.getTime())) {
            throw Error(`Getting ${locale.name} time resulted in an invalid date`)
        }

        return localeDate
    } catch {
        return parsedDate
    }
}

export const formatDate = (date: Date | null | undefined | number | string, dateFormat?: dateFormats | string): string => {
    if (typeof date === 'number' || typeof date === 'string') {
        date = new Date(date)
    }
    dateFormat = dateFormat || dateFormats.slashSeparatedMMDDYYYY
    return date ? format(date, dateFormat) : ''
}

export const getMonthName = (date: DateLike) => getMonth(date).name

export const getMonth = (date: DateLike) => {
    const index = asDate(date).getMonth()
    const month = months[index]
    if (!month) {
        throw new Error(`Cannot find month ${index}`)
    }

    return month
}

export const formatTime = (date: Date | null | undefined | number): string => {
    if (!date) {
        return ''
    }

    if (typeof date === 'number') {
        date = new Date(date)
    }

    const hours = date.getHours()
    const minutes = date.getMinutes()
    const ampm = hours >= 12 ? 'PM' : 'AM'
    const formattedHours = hours % 12 || 12 // 0 should be 12
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
    return `${formattedHours}:${formattedMinutes}${ampm}`
}

export const datesAreEqual = (date1: Date | null | undefined, date2: Date | null | undefined): boolean => {
    return date1 === date2 || (date1 && date2 ? date1.getTime() === date2.getTime() : false)
}

export const getDurationInMilliseconds = (start: Date, end: Date): number => {
    return end.getTime() - start.getTime()
}

export const asDays = (milliseconds: number): number => {
    return Math.floor(milliseconds / TICKS_PER_DAY)
}

export const getDuration = (toDate: DateLike, fromDate: DateLike = Date.now()): Duration => {
    const interval: Interval = {
        start: asDate(fromDate),
        end: asDate(toDate),
    }
    return intervalToDuration(interval)
}

export const getUTCDate = (date: DateLike) => {
    const typedDate = asDate(date)
    return new Date(typedDate.getUTCFullYear(), typedDate.getUTCMonth(), typedDate.getUTCDate())
}

export const getUTCDateTime = (date: DateLike) => {
    const typedDate = asDate(date)
    return new Date(
        typedDate.getUTCFullYear(),
        typedDate.getUTCMonth(),
        typedDate.getUTCDate(),
        typedDate.getUTCHours(),
        typedDate.getUTCMinutes(),
        typedDate.getUTCSeconds(),
    )
}

export const getLocalDateTimestamp = (): string => {
    return new Date().toLocaleDateString(['en-us'], {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short',
    })
}

export const getDurationInDays = (start: Date, end: Date): number => {
    const differenceInMilliseconds = getDurationInMilliseconds(start, end)
    return asDays(differenceInMilliseconds)
}
export const getDayRange = (monthOrdinal?: number) => {
    const month = monthOrdinal && months.find(m => m.ordinal === monthOrdinal)
    const daysInMonth = month ? month.numberOfDays : 31
    return rangeInclusive(1, daysInMonth)
}

export const getDurationInMonths = (start: Date, end: Date): number => {
    const yearDiff = end.getFullYear() - start.getFullYear()
    const monthDiff = end.getMonth() - start.getMonth()
    return yearDiff * MONTHS_PER_YEAR + monthDiff
}

export const percentOfDateRange = (start: Date, end: Date, value: Date): number => {
    return percentOfRange(start.getTime(), end.getTime(), value.getTime())
}

export const parseDate = (input: string | undefined): Date | undefined => {
    if (!input) {
        return undefined
    }

    return parseISO(input)
}

export const dateToInputTypeDateString = (date: Date): string => {
    const STANDARDDATEFORMATLENGTH = 10
    return date.toISOString().substring(0, STANDARDDATEFORMATLENGTH)
}

export enum weekDay {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

export const months: ReadonlyArray<Month> = [
    { ordinal: 1, name: 'January', abbreviation: 'Jan', numberOfDays: 31 },
    { ordinal: 2, name: 'February', abbreviation: 'Feb', numberOfDays: 29 },
    { ordinal: 3, name: 'March', abbreviation: 'Mar', numberOfDays: 31 },
    { ordinal: 4, name: 'April', abbreviation: 'Apr', numberOfDays: 30 },
    { ordinal: 5, name: 'May', abbreviation: 'May', numberOfDays: 31 },
    { ordinal: 6, name: 'June', abbreviation: 'Jun', numberOfDays: 30 },
    { ordinal: 7, name: 'July', abbreviation: 'Jul', numberOfDays: 31 },
    { ordinal: 8, name: 'August', abbreviation: 'Aug', numberOfDays: 31 },
    { ordinal: 9, name: 'September', abbreviation: 'Sep', numberOfDays: 30 },
    { ordinal: 10, name: 'October', abbreviation: 'Oct', numberOfDays: 31 },
    { ordinal: 11, name: 'November', abbreviation: 'Nov', numberOfDays: 30 },
    { ordinal: 12, name: 'December', abbreviation: 'Dec', numberOfDays: 31 },
]

export interface Month {
    readonly ordinal: number
    readonly name: string
    readonly abbreviation: string
    readonly numberOfDays: number
}

export enum dateFormats {
    slashSeparatedMMDDYYYY = 'P',
    fullMonthYear = 'MMMM yyyy',
    fullDayMonthYear = 'dd MMMM yyyy',
    fullDateTime = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    dashSeparatedyyyyMMdd = 'yyyy-MM-dd',
    shortMonthYear = 'MMM `yy',
}
