import * as React from 'react'
import styled, { css } from 'styled-components'

import { ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { LinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'
import { StatusBox, StatusData, StatusHeader, StatusItem } from '@igs-web/common-ui-components/_molecules/status-box'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'
import { formatDate } from '@igs-web/common-utilities/utilities/date-utilities'

import { Verbiage } from '../company/companyVerbiage'
import { useCompany } from '../company/hooks/useCompany'
import { InactiveStyle } from '../myaccount/myaccount-components'
import { ProfileSection, userProfileRoutes } from '../user-profile/user-profile-routes'
import { PaymentExtensionMessage } from './payment-extension-message'

const mobileStrategy = css`
    @media (max-width: ${Breakpoint.Tablet}) {
        grid-column: -1 / 1;
    }
`

const FooterMessage = styled.div<FooterMessageProps>`
    grid-column: 1 / span ${({ $columns }) => $columns};
    ${mobileStrategy}
`

const PaymentExtensionDueDates = styled.div`
    grid-row: span 2;
    ${mobileStrategy}
`

const PaymentExtensionDueDateStatusItem = styled(StatusItem)`
    margin-bottom: ${Spacing.Medium};
`

const MobileStatusItem = styled(StatusItem)`
    justify-self: center;
    ${mobileStrategy}
`

const InactiveStyleWithBottomMargin = styled(InactiveStyle)`
    margin-bottom: ${Spacing.Large};
`

interface FooterMessageProps {
    readonly $columns: number
}

// eslint-disable-next-line complexity
export const BalanceDueDetailsPartyAccount = ({
    paymentDueDate,
    totalAmountDue,
    isOnPaymentPlan,
    billingAccountNumber,
    isActive,
    paymentExtensionDueDate,
}: BalanceDueDetailsProps) => {
    const { routes, verbiage } = useCompany()
    const hasPaymentExtension = !!paymentExtensionDueDate
    const columns = 3

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const footerMessageColumns = hasPaymentExtension ? columns - 2 : columns - 1

    return (
        <StatusBox $columns={columns}>
            <header>Current Balance</header>
            <StatusItem>
                <StatusHeader>Amount Due:</StatusHeader>
                <StatusData>{formatAsMoney(totalAmountDue)}</StatusData>
            </StatusItem>

            {paymentDueDate && !hasPaymentExtension && (
                <StatusItem>
                    <StatusHeader>Due Date:</StatusHeader>
                    <StatusData>{formatDate(paymentDueDate)}</StatusData>
                </StatusItem>
            )}

            {hasPaymentExtension && (
                <PaymentExtensionDueDates>
                    <PaymentExtensionDueDateStatusItem>
                        <StatusHeader>Extension Due Date:</StatusHeader>
                        <StatusData>{formatDate(paymentExtensionDueDate)}</StatusData>
                    </PaymentExtensionDueDateStatusItem>
                    <StatusItem>
                        <StatusHeader>Original Due Date:</StatusHeader>
                        <StatusData>{formatDate(paymentDueDate)}</StatusData>
                    </StatusItem>
                </PaymentExtensionDueDates>
            )}

            {!paymentDueDate && !hasPaymentExtension && <StatusItem />}

            <MobileStatusItem>
                {!isActive && totalAmountDue === 0 && <InactiveStyle>Inactive</InactiveStyle>}
                {!isActive && totalAmountDue > 0 && <InactiveStyleWithBottomMargin>Inactive</InactiveStyleWithBottomMargin>}
                {(isActive || totalAmountDue > 0) && (
                    <LinkButton buttonStyle={ButtonStyleType.Primary} href={routes.payment.payBill(billingAccountNumber)}>
                        {verbiage[Verbiage.paymentNavigationText]}
                    </LinkButton>
                )}
            </MobileStatusItem>
            <FooterMessage $columns={footerMessageColumns}>
                {!isOnPaymentPlan && <PaymentExtensionMessage billingAccountNumber={billingAccountNumber} />}
                {isOnPaymentPlan && <span> The Amount Due reflects the terms of your payment arrangement.</span>}
            </FooterMessage>
            <MobileStatusItem>
                <Link href={userProfileRoutes.userProfileUrl(ProfileSection.CommunicationPreferences).asString()} dataTestId="paperless-billing-link">
                    Paperless Billing
                </Link>
            </MobileStatusItem>
        </StatusBox>
    )
}
interface BalanceDueDetailsProps {
    readonly paymentDueDate?: Date
    readonly totalAmountDue: number
    readonly showPayBillLink: boolean
    readonly isOnPaymentPlan: boolean
    readonly billingAccountNumber?: string
    readonly isActive: boolean
    readonly paymentExtensionDueDate?: Date | null
}
