import React from 'react'

import styled from 'styled-components'

import { useBreakpoints } from '@igs-web/common-components/hooks/use-breakpoints'
import { IconType } from '@igs-web/common-models/models/icon-type'
import { IconSvg, IconSvgProps } from '@igs-web/common-ui-components/_atoms/SVGs/icon-svg'
import { SvgSize } from '@igs-web/common-ui-components/_atoms/SVGs/svg-icon'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { brandLight, lightDark, lightLight } from '@igs-web/common-ui-components/styles/theme'

const ANIMATION_TIMING = '1.5s'

export const ProgressTrack = styled.div<ProgressTrackProps>`
    display: grid;
    grid-template-rows: auto;
    grid-auto-flow: column;
    padding: ${Spacing.XxLarge} 0 ${Spacing.ExtraLarge} 0;
    --progress-border-complete: ${props => props.borderCompleteColor || brandLight};
    --progress-border-active: ${props => props.borderActiveColor || brandLight};
    --progress-border-incomplete: ${props => props.borderIncompleteColor || lightDark};
    --progress-icon-complete: ${props => props.iconCompleteColor || lightLight};
    --progress-icon-active: ${props => props.iconActiveColor || brandLight};
    --progress-icon-incomplete: ${props => props.iconIncompleteColor || lightDark};
    --progress-spacer-complete: ${props => props.spacerCompleteColor || brandLight};
    --progress-spacer-active: ${props => props.spacerActiveColor || brandLight};
    --progress-spacer-incomplete: ${props => props.spacerIncompleteColor || lightDark};
    --progress-background-complete: ${props => props.bgCompleteColor || brandLight};
    --progress-background-active: ${props => props.bgColor || lightLight};
    --progress-background-incomplete: ${props => props.bgColor || lightLight};
`

interface ProgressTrackProps {
    readonly borderCompleteColor?: string
    readonly borderActiveColor?: string
    readonly borderIncompleteColor?: string
    readonly bgCompleteColor?: string
    readonly bgColor?: string
    readonly iconCompleteColor?: string
    readonly iconActiveColor?: string
    readonly iconIncompleteColor?: string
    readonly spacerCompleteColor?: string
    readonly spacerActiveColor?: string
    readonly spacerIncompleteColor?: string
}

const StyledTrackIcon = styled.div`
    width: min-content;
    transform: scale(1.25);
    z-index: 5;
`

const StyledIconSvg = styled(IconSvg)<StyledIconSvgProps>`
    height: 2.5rem;
    width: 2.5rem;
    transform: ${({ $isActive }: StyledIconSvgProps) => ($isActive ? 'scale(1.2)' : 'scale(1)')};
    transition-duration: ${ANIMATION_TIMING};
    .icon-core {
        fill: ${({ $iconColor }: StyledIconSvgProps) => $iconColor};
    }
    .icon-border-circle {
        stroke: ${({ $borderColor }: StyledIconSvgProps) => $borderColor};
        fill: ${({ $backgroundColor }: StyledIconSvgProps) => $backgroundColor};
    }
`

export const TrackIcon = ({ iconType, label, iconColor, borderColor, backgroundColor, isActive, ...htmlProps }: TrackIconProps) => {
    const { lessThanOrEqualTo } = useBreakpoints()
    const isMobile = lessThanOrEqualTo(Breakpoint.Mobile)

    return (
        <StyledTrackIcon {...htmlProps}>
            <StyledIconSvg
                $isActive={isActive}
                iconType={iconType}
                size={isMobile ? SvgSize.Small : SvgSize.Medium}
                altText={label}
                $iconColor={iconColor}
                $borderColor={borderColor}
                $backgroundColor={backgroundColor}
            />
        </StyledTrackIcon>
    )
}

interface TrackIconProps extends React.HtmlHTMLAttributes<HTMLElement> {
    readonly iconType: IconType
    readonly label: string
    readonly isActive: boolean
    readonly iconColor: string
    readonly borderColor: string
    readonly backgroundColor: string
}

interface StyledIconSvgProps extends IconSvgProps {
    readonly $isActive: boolean
    readonly $iconColor: string
    readonly $borderColor: string
    readonly $backgroundColor: string
}

export const TrackStrip = styled.div<TrackStripProps>`
    background-color: ${({ $color }: TrackStripProps) => $color || lightDark};
    transition: all ${ANIMATION_TIMING} ease;
    height: ${Spacing.Small};
    width: 101%;

    z-index: 0;
`
interface TrackStripProps {
    readonly $color?: string
}
