export type CreatedAction<TPayload> = (payload?: TPayload) => { readonly type: string; readonly payload: TPayload }
export const createAction = <TPayload>(type: string): CreatedAction<TPayload> => (payload: TPayload) => ({ type, payload })

class Reducer<TState> {
    private readonly reducers: { readonly type: string; readonly fn: (state: TState, payload: any) => TState }[] = []

    constructor(private initialState: TState) {}

    public readonly add = <TPayload>(type: string, fn: (state: Readonly<TState>, payload: TPayload) => TState) => {
        this.reducers.push({ type, fn })
        return this
    }

    public readonly build = () => {
        return (s: TState = this.initialState, a) => {
            const method = this.reducers.find(m => m.type === a.type)
            return method ? method.fn(s, a.payload) : s
        }
    }
}

export const reducer = <TState>(initialState: TState) => new Reducer<TState>(initialState)
