import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const CirclePayment = ({ altText = 'Payment Details', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} altText={altText} width="50" height="50" viewBox="0 0 50 50">
        <g id="Group_539" data-name="Group 539" transform="translate(4838 -3419)">
            <g id="Ellipse_208" data-name="Ellipse 208" transform="translate(-4838 3419)" className="icon-border-circle" strokeWidth="3">
                <circle cx="25" cy="25" r="25" stroke="none" />
                <circle cx="25" cy="25" r="23.5" fill="none" />
            </g>
            <path id="dollar-sign-solid" d="M11.334,12.607,5.5,10.9a1.593,1.593,0,0,1,.448-3.122H9.53a3.3,3.3,0,0,1,1.847.567.842.842,0,0,0,1.053-.108L14.311,6.4a.876.876,0,0,0-.1-1.323,7.638,7.638,0,0,0-4.672-1.62V.864A.867.867,0,0,0,8.677,0H6.948a.867.867,0,0,0-.864.864V3.457H5.949A5.918,5.918,0,0,0,.061,9.917a6.183,6.183,0,0,0,4.526,5.218l5.537,1.62a1.593,1.593,0,0,1-.448,3.122H6.095a3.3,3.3,0,0,1-1.847-.567.842.842,0,0,0-1.053.108l-1.88,1.837a.876.876,0,0,0,.1,1.323A7.638,7.638,0,0,0,6.084,24.2v2.593a.867.867,0,0,0,.864.864H8.677a.867.867,0,0,0,.864-.864v-2.6a6.1,6.1,0,0,0,5.709-3.927A5.931,5.931,0,0,0,11.334,12.607Z" transform="translate(-4821.037 3430)" className="icon-core" />
        </g>
    </SvgIcon>
)

export default CirclePayment