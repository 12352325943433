import * as React from 'react'
import { FaSearch } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components'

import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { ActionLink } from '@igs-web/common-ui-components/_atoms/link/action-link'
import { Header4 } from '@igs-web/common-ui-components/_atoms/typography'
import { BreadcrumbList } from '@igs-web/common-ui-components/_molecules/breadcrumbs'
import { MasterGrid } from '@igs-web/common-ui-components/_molecules/grid/master-grid'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import {
    brandLight,
    fontSizeExtraLarge,
    fontSizeLarge,
    lightAccent,
    myaccountSpacing,
    stripeColor1,
    stripeColor2,
} from '@igs-web/common-ui-components/styles/theme'

//TODO do something about that width
export const DashboardContainer = styled(MasterGrid)`
    display: grid;
    place-items: center;
    grid-template-columns: unset;
    grid-row-gap: ${Spacing.Medium};
    @media (max-width: ${Breakpoint.Tablet}) {
        padding-left: ${Spacing.Small};
        padding-right: ${Spacing.Small};
    }
`

export const StyledBreadCrumbList = styled(BreadcrumbList)`
    place-self: start;
    padding-top: ${Spacing.Small};
`

export const InfoColumn = styled.div`
    --gap: ${Spacing.Small};
    --align-items: start;
    display: flex;
    flex-direction: column;
    align-items: var(--align-items);
    justify-items: center;
    justify-content: center;
    place-self: start center;
    gap: var(--gap);
`
export const HighlightedInfo = styled.div`
    font-size: 3rem;
    line-height: 1;
`
export const KeyInfo = styled.div`
    text-transform: uppercase;
    font-weight: bold;
`
export const InfoHeaderStyle = styled.div`
    font-weight: ${FontWeight.Bold};
    text-transform: uppercase;
`
export const InactiveStyle = styled(InfoHeaderStyle)`
    text-align: center;
    font-size: ${fontSizeExtraLarge};
    padding-inline: ${Spacing.Small};
`

const linkStyle = css`
    text-transform: uppercase;
    font-weight: bold;
    font-size: ${fontSizeLarge};
    color: ${brandLight};
`

export const KeyLink = styled(Link)`
    ${linkStyle}
`

export const KeyActionLink = styled(ActionLink)`
    ${linkStyle}
`

export const ActionButtons = styled.div`
    display: grid;
    place-items: center end;
    @media (max-width: ${Breakpoint.Mobile}) {
        place-items: center center;
    }
`

export const SearchGrid = styled.div<SearchGridProps>`
    --template-columns: repeat(${({ columns }) => columns || 1}, 1fr);
    --item-padding: ${Spacing.Large};
    display: grid;
    width: 100%;
    text-align: left;
    max-width: ${Breakpoint.Desktop};
    grid-template-columns: var(--template-columns);
    grid-row-gap: ${Spacing.Medium};
    align-items: center;
    padding-bottom: ${Spacing.Medium};
    & > * {
        height: 100%;
        width: 100%;
        align-content: center;
        padding: var(--item-padding);
    }
    .even {
        background: ${stripeColor2};
    }
    .odd {
        background: ${stripeColor1};
    }
    @media (max-width: ${Breakpoint.Desktop}) {
        grid-template-columns: 1fr;
    }
    @media (max-width: ${Breakpoint.Mobile}) {
        grid-template-columns: 1fr;
        row-gap: ${Spacing.XxSmall};
    }
`

export const BillingAccountGrid = styled(SearchGrid)`
    --template-columns: 2fr 3fr min-content;
    --item-padding: ${Spacing.Medium};
    @media (max-width: ${Breakpoint.Desktop}) {
        grid-row-gap: 0;
    }
`
export const DashboardGrid = styled(SearchGrid)`
    --template-columns: 1fr 1fr;
    --item-padding: 0;
    grid-column-gap: ${Spacing.Medium};
    max-width: ${Breakpoint.Desktop};
    margin: auto;
    margin-top: ${Spacing.Large};
`
export const DashboardGridColumnSpan = styled.div`
    grid-column: span 2;
    @media (max-width: ${Breakpoint.Desktop}) {
        grid-column: auto;
    }
`
export const DashboardGridRowSpan = styled.div`
    grid-row: span 2;
    @media (max-width: ${Breakpoint.Desktop}) {
        grid-column: auto;
    }
`
interface SearchGridProps {
    readonly columns?: number
}

export const SearchHeader = styled.header`
    max-width: ${Breakpoint.Tablet};
    width: 100%;

    padding-bottom: ${Spacing.ExtraLarge};
    display: grid;
    grid-row-gap: ${myaccountSpacing};
    ${Header4}, p {
        text-align: center;
        margin: 0;
    }
    label {
        justify-self: center;
    }
`
const mobileButtonPadding = '15px'
const SearchWithButton = styled.div`
    display: grid;
    box-sizing: border-box;
    grid-template-columns: 1fr min-content;
    position: relative;
    input {
        height: 3rem;
        font-size: ${fontSizeLarge};
        padding-left: ${Spacing.Medium};
        @media (max-width: ${Breakpoint.Mobile}) {
            padding-left: ${Spacing.Small};
        }
        &::placeholder {
            font-size: ${fontSizeLarge};
        }
    }
    button {
        height: 3rem;
        position: absolute;
        right: 0;
        z-index: 2;
        @media (max-width: ${Breakpoint.Mobile}) {
            padding-left: ${mobileButtonPadding};
            padding-right: ${mobileButtonPadding};
        }
    }
`
export const SearchInput = ({ children, comboboxProps }: SearchInputProps) => (
    <SearchWithButton {...comboboxProps}>
        {children}
        <Button>
            <FaSearch />
        </Button>
    </SearchWithButton>
)

export const SearchSelect = ({ children, comboboxProps }: SearchInputProps) => <SearchWithButton {...comboboxProps}>{children}</SearchWithButton>

type Dictionary = {
    readonly [key: string]: unknown
}
interface SearchInputProps {
    readonly children?: React.ReactNode
    readonly comboboxProps: Dictionary
}

export const wideInformationBoxColumns = { '--template-columns': 'max-content 1fr' } as React.CSSProperties
export const bgLightAccent = { '--bg-color': lightAccent } as React.CSSProperties
