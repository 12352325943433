import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const RealtorCircleIcon = ({ altText = 'Realtor', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M23.989 12.556c0 0.207-0.168 0.374-0.374 0.374v0c-0.207-0.007-0.374-0.169-0.389-0.373l-0-0.001c0-0.207 0.168-0.374 0.374-0.374v0c0 0 0 0 0 0 0.21 0 0.381 0.166 0.389 0.374l0 0.001zM20.765 16.749v2.408c-0.004 0.157-0.13 0.282-0.286 0.286h-1.535v-1.402c0-0.002 0-0.005 0-0.007 0-0.166-0.135-0.301-0.301-0.301-0.003 0-0.005 0-0.008 0h-0.895c-0.002-0-0.005-0-0.007-0-0.166 0-0.301 0.135-0.301 0.301 0 0.003 0 0.005 0 0.008v-0 1.402h-1.535c-0.157-0.004-0.282-0.13-0.286-0.286v-2.453l-1.050-0.044 3.51-3.143c0.039-0.036 0.092-0.059 0.15-0.059 0.002 0 0.003 0 0.005 0h-0c0.056 0.005 0.107 0.026 0.147 0.059l-0.001-0 1.366 1.256v-0.925h1.028v1.887l1.057 0.969zM12.74 12.931c-0.002 0-0.005 0-0.007 0-0.207 0-0.374-0.168-0.374-0.374s0.168-0.374 0.374-0.374c0.207 0 0.374 0.168 0.374 0.374v0c0 0.004 0 0.010 0 0.015 0 0.199-0.161 0.36-0.36 0.36-0.003 0-0.005-0-0.008-0h0zM13.107 10.559h10.118v0.903h-10.118zM25.134 10.559l0.734-0.786-0.734-0.793h-14.855v-1.865l-1.116-0.734-1.087 0.734v1.865h-1.241l-0.734 0.793 0.734 0.786h1.241v15.053h2.203v-15.053h2.085v0.903h-0.507c-0.002-0-0.005-0-0.007-0-0.361 0-0.653 0.293-0.653 0.653 0 0 0 0 0 0v0 9.237c0 0.365 0.296 0.661 0.661 0.661v0h12.329c0.365 0 0.661-0.296 0.661-0.661v0-9.259c0 0 0-0 0-0 0-0.361-0.293-0.653-0.654-0.653-0.003 0-0.005 0-0.008 0h-0.242v-0.903h1.19z" />
        <path d="M18.614 15.626h-0.83c-0.122 0-0.22 0.099-0.22 0.22v0.22c0 0.122 0.099 0.22 0.22 0.22v0h0.83c0.122 0 0.22-0.099 0.22-0.22v0-0.22c0-0.005 0.001-0.010 0.001-0.015 0-0.114-0.092-0.206-0.206-0.206-0.005 0-0.011 0-0.016 0.001l0.001-0z" />
        <path d="M15.985 0c-0.007 0-0.014 0-0.022 0-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-0.002 0-0.005 0-0.007 0-8.827-7.152-15.984-15.977-15.993h-0.001zM15.985 30.458c-0.004 0-0.010 0-0.015 0-7.985 0-14.458-6.473-14.458-14.458s6.473-14.458 14.458-14.458c7.985 0 14.458 6.473 14.458 14.458v0c0 0.004 0 0.010 0 0.015 0 7.977-6.466 14.443-14.443 14.443v0z" />
    </SvgIcon>
)

export default RealtorCircleIcon
