import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const HeartCircleIcon = ({ altText = 'Heart', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M26.126 13.731c0 0.002 0 0.005 0 0.008 0 1.699-0.728 3.228-1.89 4.291l-0.004 0.004-6.337 6.359c-0.639 0.639-1.263 1.263-1.902 1.263s-1.263-0.646-1.909-1.263l-6.33-6.359c-1.181-1.071-1.919-2.611-1.919-4.324 0-3.22 2.61-5.83 5.83-5.83 1.711 0 3.25 0.737 4.316 1.911l0.004 0.005c1.070-1.172 2.605-1.904 4.31-1.904 3.22 0 5.83 2.61 5.83 5.83 0 0.003 0 0.007 0 0.010v-0.001z" />
        <path d="M15.985 0c-0.007 0-0.014 0-0.022 0-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-0.002 0-0.005 0-0.007 0-8.827-7.152-15.984-15.977-15.993h-0.001zM15.985 30.458c-0.004 0-0.010 0-0.015 0-7.985 0-14.458-6.473-14.458-14.458s6.473-14.458 14.458-14.458c7.985 0 14.458 6.473 14.458 14.458v0c0 0.004 0 0.010 0 0.015 0 7.977-6.466 14.443-14.443 14.443v0z" />
    </SvgIcon>
)

export default HeartCircleIcon
