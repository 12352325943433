import { CachedReduxDataStateType, setupCachedReduxData } from '@igs-web/common-components/domain/cached-redux/cached-redux-data'
import { LegacyPayment } from '@igs-web/common-models/models/legacy-data'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

const load = async (billingAccountNumber: number, config: RequestConfig): Promise<ReadonlyArray<LegacyPayment> | undefined> => {
    try {
        const payments = await customerInfoApiClient.getLegacyPayments(billingAccountNumber, config)

        if (!payments) {
            throw Error(`Payments are not defined for ${billingAccountNumber}`)
        }

        return payments.map(u => ({ ...u }))
    } catch (e) {
        console.error(`There was a problem getting payments: ${e.message}`)
    }
}

const { actions, selectors, saga, reducers } = setupCachedReduxData('legacyPayments', load)

export const legacyPaymentRedux = { actions, selectors }
export const legacyPaymentSaga = saga
export const legacyPaymentReducer = reducers
export type LegacyPaymentState = CachedReduxDataStateType<typeof load>
