import { SupportedCompanies } from '@igs-web/common-components/domain/company/company'
import {
    getApiUrl,
    getCacheBusterValue,
    getCdnUrl,
    getDefaultCampaign,
    getEnvironment,
    getSitefinityApiUrl,
} from '@igs-web/common-utilities/utilities/environment-utilities'
import { getFromStorage, putInStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

import { apiClient } from './api-client'

interface Configuration {
    readonly ApiUrl: string
    readonly CacheBuster: string
    readonly CdnUrl: string
    readonly Environment: string
    readonly Company: string
    readonly SitefinityApiUrl: string
    readonly versionNumber: number
    readonly defaultCampaign: string
}
class ConfigurationApiClient {
    // eslint-disable-next-line functional/prefer-readonly-type
    private configurationPromise: Promise<Configuration>

    public readonly loadConfiguration = async (companyName = 'igs') => {
        const isIgs: boolean = companyName.toLowerCase() === SupportedCompanies.Igs.toLowerCase()

        if (!this.configurationPromise) {
            // eslint-disable-next-line no-async-promise-executor
            this.configurationPromise = new Promise(async resolve => {
                const versionNumber = 5
                const environment = getEnvironment()
                const storedResult = getFromStorage<Configuration & { readonly versionNumber: number }>('ui-configuration')
                if (
                    storedResult &&
                    versionNumber === storedResult.versionNumber &&
                    environment === storedResult.Environment &&
                    companyName === storedResult.Company
                ) {
                    resolve(storedResult)
                    return
                }

                const sitefinityApiUrl: string = getSitefinityApiUrl(environment, companyName)
                const serverResult = isIgs
                    ? await apiClient.get<Configuration>(`${sitefinityApiUrl}/data-api/UiConfiguration`)
                    : {
                          ApiUrl: getApiUrl(environment, companyName),
                          CacheBuster: getCacheBusterValue(),
                          CdnUrl: getCdnUrl(environment),
                          Environment: environment,
                      }

                if (serverResult) {
                    putInStorage({ ...serverResult, versionNumber, Company: companyName, SitefinityApiUrl: sitefinityApiUrl }, 'ui-configuration')
                    resolve({
                        ...serverResult,
                        versionNumber,
                        Company: companyName,
                        SitefinityApiUrl: sitefinityApiUrl,
                        defaultCampaign: getDefaultCampaign(companyName),
                    })
                    return
                }

                throw Error('Error loading configuration')
            })
        }

        const configuration = await this.configurationPromise
        apiClient.apiBaseUrl = `${configuration.ApiUrl}/api`
        apiClient.cacheBuster = configuration.CacheBuster
        apiClient.cdnUrl = configuration.CdnUrl
        apiClient.environment = configuration.Environment
        apiClient.sitefinityApiUrl = configuration.SitefinityApiUrl
        apiClient.defaultCampaign = configuration.defaultCampaign

        if (isIgs) {
            // @ts-ignore
            __webpack_public_path__ = apiClient.cdnUrl + 'dist/'
        }
    }
}

export const configurationApiClient = new ConfigurationApiClient()
