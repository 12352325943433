import { useDispatch } from 'react-redux'

import { offersActions } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { OfferModel } from '@igs-web/common-models/models'
import { getCartInteractionModel, pushAddToCartEventtoGa, pushRemoveFromCartEventtoGa } from '@igs-web/common-utilities/services/google-analytics-services'

export const useSelectOffer = () => {
    const dispatch = useDispatch()

    const selectOffer = (offer: OfferModel | undefined) => {
        if (offer) {
            pushAddToCartEventtoGa(getCartInteractionModel(offer))
        }
        dispatch(offersActions.selectOffer(offer))
    }

    const unSelectOffer = (offer: OfferModel | undefined) => {
        if (offer) {
            pushRemoveFromCartEventtoGa(getCartInteractionModel(offer))
        }
        dispatch(offersActions.unselectOffer(offer))
    }

    return {
        selectOffer,
        unSelectOffer,
    }
}
