import * as React from 'react'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'

import { PaymentExtensionNavigationButtons } from './payment-extension-nav-buttons'
import { PaymentExtensionProps } from './payment-extension-page'

const PaymentExtensionMaxViableAccounts = 50

export const IneligibleForPaymentExtension = ({ billingAccountNumber, billingAccountCount }: IneligibleForPaymentExtensionProps) => {
    const { phoneNumber } = useCompany()
    return (
        <>
            {billingAccountCount >= PaymentExtensionMaxViableAccounts && <p>We are unable to create a payment extension online for your billing account.</p>}
            {billingAccountCount < PaymentExtensionMaxViableAccounts && <p>Your billing account is currently not eligible for a payment extension.</p>}
            <p>
                If you need assistance, please call us at <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />
            </p>
            <PaymentExtensionNavigationButtons billingAccountNumber={billingAccountNumber} />
        </>
    )
}

interface IneligibleForPaymentExtensionProps extends PaymentExtensionProps {
    readonly billingAccountCount: number
}
