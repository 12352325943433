import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FaRegFileAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CheckoutStepProps } from '@igs-web/common-components/domain/checkout/checkout-models'
import { ReviewOrderForm } from '@igs-web/common-components/domain/checkout/forms/review-order-form'
import { useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { StepNav } from '@igs-web/common-components/domain/checkout/steps/step-nav'
import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { SetConfirmationInfoRequest, confirmationActions } from '@igs-web/common-components/domain/enrollment/confirmation/confirmation-redux'
import { EnrollmentModelSelectors } from '@igs-web/common-components/domain/enrollment/enrollment-redux'
import { enrollmentModelActions } from '@igs-web/common-components/domain/enrollment/enrollment-redux-actions'
import { submitEnrollment } from '@igs-web/common-components/domain/enrollment/enrollment-submission-service'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { EnrollmentModel } from '@igs-web/common-models/models/enrollment-model'
import { FormWrapper } from '@igs-web/common-ui-components/_atoms/form-wrapper'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { getEventModel, pushReviewOrderEvent } from '@igs-web/common-utilities/services/google-analytics-services'

import { mapToEnrollmentModel } from 'domain/checkout/shared/checkout-service'

import { useCheckoutWizardMessagesDispatch } from '../../checkout-wizard-messages/checkout-wizard-messages-context'
import { Checkout2x2CardGrid } from '../../shared/checkout-components'
import { AddressCard } from '../../your-plan/address-card'
import { MyDetailsCard } from '../../your-plan/my-details-card'
import { RateCard } from '../../your-plan/rate-card'
import { StartDateCard } from '../../your-plan/start-date-card'

export const OrderReviewStep = ({ confirmationUrl, stepFwdButtonText, checkIsSelectedOfferExpired }: OrderReviewStepProps) => {
    const methods = useForm()
    const enrollmentState = useCheckoutState()
    const history = useHistory()
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

    const { agentCode, referralId } = useSelector((store: CommonReduxState) => EnrollmentModelSelectors.selectEnrollmentModel(store))
    const selectedOffers = useSelector((store: CommonReduxState) => OfferSelectors.selectSelectedOffers(store))
    const offerRequest = useSelector((store: CommonReduxState) => OfferSelectors.selectOfferRequest(store))

    const dispatch = useDispatch()

    const updateEnrollmentModel = (enrollmentModel: Partial<EnrollmentModel>) => dispatch(enrollmentModelActions.updateEnrollmentModel(enrollmentModel))
    const setConfirmationInfo = (request: SetConfirmationInfoRequest) => dispatch(confirmationActions.setConfirmationInfo(request))
    const clearConfirmationInfo = () => dispatch(confirmationActions.clearConfirmationInfo())

    const { setShowServerErrorMessage } = useCheckoutWizardMessagesDispatch()
    React.useEffect(() => {
        window.scrollTo(0, 0)
        pushReviewOrderEvent(getEventModel(selectedOffers))
    }, [])

    const goToConfirmation = () => {
        history.push(confirmationUrl)
    }

    const onSubmit = async () => {
        const isSelectedOfferExpired = await checkIsSelectedOfferExpired()
        if (!isSelectedOfferExpired) {
            setIsSubmitting(true)
            const enrollmentModel = mapToEnrollmentModel(enrollmentState, selectedOffers, agentCode, referralId)
            try {
                setShowServerErrorMessage(false)
                await submitEnrollment(enrollmentModel, selectedOffers, updateEnrollmentModel, setConfirmationInfo, clearConfirmationInfo, goToConfirmation)
            } catch (e) {
                window.scrollTo(0, 0)
                console.error(e)
                setShowServerErrorMessage(true)
                setIsSubmitting(false)
            }
        }
    }

    const primaryProduct = selectedOffers[0].primaryProduct

    return (
        <FormProvider {...methods}>
            <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
                <IconHeading>
                    <FaRegFileAlt /> Review
                </IconHeading>

                <Checkout2x2CardGrid>
                    <RateCard primaryProduct={primaryProduct} offerRequest={offerRequest} hideEditButton={false} />
                    {enrollmentState.serviceAddress && <AddressCard serviceAddress={enrollmentState.serviceAddress} />}
                    <MyDetailsCard enrollment={enrollmentState} supressBillingAccountDisplay={false} />
                    <StartDateCard enrollment={enrollmentState} />
                </Checkout2x2CardGrid>
                <ReviewOrderForm enrollmentState={enrollmentState} idPrefix="order-summary-" />
                <StepNav disableFwdButton={isSubmitting} stepFwdButtonText={stepFwdButtonText} styleStepBackAsButton={true} />
            </FormWrapper>
        </FormProvider>
    )
}

interface OrderReviewStepProps extends CheckoutStepProps {
    readonly confirmationUrl: string
    readonly checkIsSelectedOfferExpired: () => Promise<boolean>
}
