import * as React from 'react'

import { Address } from '@igs-web/common-models/models/address'

import { AddressDisplay } from './address-display'

export const AddressSummary = ({ address, title, dataTestIdPrefix }: Props) => {
    if (!address) {
        return null
    }

    return (
        <>
            <div className="tiny-header"><strong>{title}</strong></div>
            <AddressDisplay address={address} dataTestIdPrefix={dataTestIdPrefix} />
        </>
    )
}

interface Props {
    readonly address: Address | undefined
    readonly title: string
    readonly dataTestIdPrefix?: string
}
