import * as React from 'react'
import { FaEnvelope, FaPhone, FaRegCreditCard, FaUserAlt } from 'react-icons/fa'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { IconHeading, IconSubHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import { GridSpacer, Layout1fr1frColumn } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { borderBoxColor } from '@igs-web/common-ui-components/styles/theme'
import { formatPhoneNumberForDisplay } from '@igs-web/common-utilities/utilities/phone-utilities'
import { Card } from '@igs/react-styled-components'

import { getUrlParameter } from 'root/shared-services/query-string-utilities'

import { NavigationBanner } from 'molecules/header/navigation-banner'
import { ScrollToTop } from 'molecules/routing/scroll-to-top'

import { BillingAccountSearch } from './billing-account-search'

const WaysToPayGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: ${Spacing.Medium};
    align-items: baseline;
    @media (max-width: ${Breakpoint.Tablet}) {
        display: flex;
        flex-direction: column-reverse;
    }
    > h3 {
        grid-column: -1/1;
    }
`
const AssistanceHeader = styled(Header3)`
    border-bottom: 1px solid ${borderBoxColor};
    padding-bottom: ${Spacing.Medium};
`

export const PayBillPage = () => {
    const { phoneNumber, lockboxPaymentCompanyName, lockboxPaymentPOBox, lockboxPaymentCityStateZip, externalWebsite, routes } = useCompany()
    const understandingMyBillSite = `${externalWebsite}/paying-my-bill/understanding-my-bill`
    const paymentCentersSite = `${externalWebsite}/paying-my-bill/payment-centers`
    const billingAccountNumber = getUrlParameter('billingAccountNumber')
    return (
        <>
            <ScrollToTop />
            <NavigationBanner title="Ways to Pay" />
            <PageContentContainer>
                <GridSpacer spacing={Spacing.Large} />
                <WaysToPayGrid>
                    <div>
                        <PaymentMethod icon={<FaEnvelope />} title={'Mail'}>
                            <>
                                <p>
                                    <strong>
                                        {lockboxPaymentCompanyName}
                                        <br />
                                        {lockboxPaymentPOBox}
                                        <br />
                                        {lockboxPaymentCityStateZip}
                                    </strong>
                                </p>
                                <p>Payments post to your account in 3-6 days. No fee.</p>
                            </>
                        </PaymentMethod>
                        <PaymentMethod icon={<FaPhone />} title={'Phone'}>
                            <>
                                <p>
                                    <strong>
                                        <Link href={`tel:${phoneNumber.payment}`}>{formatPhoneNumberForDisplay(phoneNumber.payment)}</Link>
                                    </strong>
                                </p>
                                <p>
                                    Payments post to your account immediately.
                                    <strong> $3.50</strong> transaction fee.
                                </p>
                            </>
                        </PaymentMethod>
                        <PaymentMethod icon={<FaUserAlt />} title={'In Person'}>
                            <>
                                <p>
                                    You can pay at any Kroger store in Georgia, and additional locations throughout Georgia. Find a{' '}
                                    <Link href={paymentCentersSite}>payment location</Link> near you.
                                </p>
                                <p>Cash, check, or money order. Credit cards are not accepted.</p>
                                <p>
                                    Payments post to your account immediately.
                                    <strong> $2.25 fee per transaction</strong> (payable in cash).
                                </p>
                            </>
                        </PaymentMethod>
                    </div>
                    <div>
                        <IconHeading>
                            <FaRegCreditCard /> Online Payments
                            <br />
                            <IconSubHeading> Debit/Credit Card/Electronic Payment</IconSubHeading>
                        </IconHeading>
                        <p>
                            You can make secure payment through BillMatrix using a debit/credit card or electronic check. Payments post to your account
                            immediately.
                        </p>
                        <p>Please select the billing account for payment.</p>
                        <BillingAccountSearch initialSearch={billingAccountNumber} />
                    </div>
                </WaysToPayGrid>
                <AssistanceHeader>Assistance</AssistanceHeader>
                <Layout1fr1frColumn>
                    <div>
                        <strong>Need more time to pay?</strong>
                        <p>
                            <Link href={routes.payment.paymentExtension()}>Create a payment extension</Link>.
                        </p>
                    </div>
                    <div>
                        <strong>What goes into my bill?</strong>
                        <p>
                            Click <Link href={understandingMyBillSite}>here</Link> to learn more about your bill.
                        </p>
                    </div>
                </Layout1fr1frColumn>
            </PageContentContainer>
        </>
    )
}

const PaymentMethod = ({ icon, title, children }: PaymentMethodProps) => {
    return (
        <Card>
            <IconHeading>
                {icon} {title}
            </IconHeading>
            <div>{children}</div>
        </Card>
    )
}

interface PaymentMethodProps {
    readonly icon: JSX.Element
    readonly title: string
    readonly children?: JSX.Element
}
