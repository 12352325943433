import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FaRegAddressCard } from 'react-icons/fa'
import { MdHome, MdLocationOn } from 'react-icons/md'
import { useSelector } from 'react-redux'

import { CheckoutStepProps } from '@igs-web/common-components/domain/checkout/checkout-models'
import { CustomerContactForm } from '@igs-web/common-components/domain/checkout/forms/customer-contact-form'
import { useCheckoutDispatch, useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { StepNav } from '@igs-web/common-components/domain/checkout/steps/step-nav'
import { SummaryBody } from '@igs-web/common-components/domain/checkout/summaries/summary-wrapper'
import { CompanyVerbiage, Verbiage } from '@igs-web/common-components/domain/company/companyVerbiage'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { AddressForm } from '@igs-web/common-components/domain/forms/address-form'
import { EmailMarketingConsentNotice } from '@igs-web/common-components/domain/legal/email-marketing-consent'
import { Address } from '@igs-web/common-models/models/address'
import { ContactModel } from '@igs-web/common-models/models/contact-model'
import { FormWrapper } from '@igs-web/common-ui-components/_atoms/form-wrapper'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { Checkbox } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { AddressDisplay } from '@igs-web/common-ui-components/_molecules/address/address-display'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText } from '@igs-web/common-ui-components/_molecules/info-text'
import { useWizardDispatch } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { CreditCheckStatus } from '@igs-web/common-utilities/api/enrollment-api-client'
import { getEventModel, pushAddedContactInfoEventToGa } from '@igs-web/common-utilities/services/google-analytics-services'

import { RealtorForm, RealtorFormFields } from '../../rate/realtor-form'

export const ContactDetailsStep = ({ stepFwdButtonText, checkIsSelectedOfferExpired }: ContactDetailsStepProps) => {
    const enrollmentState = useCheckoutState()
    const company = useCompany()

    const methods = useForm<ContactInfoStepFields>({
        defaultValues: {
            isBillingSameAsServiceAddress: enrollmentState.isBillingSameAsServiceAddress,
        },
    })
    const { handleSubmit, register, watch, unregister } = methods

    const { setEnrollmentState, setContactInfo } = useCheckoutDispatch()
    const { nextPage } = useWizardDispatch()

    const selectedOffers = useSelector(OfferSelectors.selectSelectedOffers)
    const offerRequest = useSelector(OfferSelectors.selectOfferRequest)
    const offerAccountType = offerRequest.offerAccountType

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const onSubmit = async (data: ContactInfoStepFields) => {
        setContactInfo(data.defaultContact, data.isPaperless)
        setEnrollmentState({
            ...enrollmentState,
            ...data,
            realtorName: data.realtorName ?? '',
            realtorId: data.realtorId ?? '',
        })

        const isSelectedOfferExpired = await checkIsSelectedOfferExpired()
        if (!isSelectedOfferExpired) {
            pushAddedContactInfoEventToGa(getEventModel(selectedOffers), nextPage)
        }
    }

    const formKey = '[defaultContact].'
    const idPrefix = 'default-contact'

    const billingAddressSame = watch('isBillingSameAsServiceAddress')

    React.useEffect(() => {
        if (billingAddressSame) {
            unregister('billingAddress')
        }
    }, [billingAddressSame, unregister])

    return (
        <FormProvider {...methods}>
            <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                <IconHeading>
                    <FaRegAddressCard /> My Details
                </IconHeading>
                {(enrollmentState.creditCheckResults?.energyScoreStatus === CreditCheckStatus.Waive ||
                    enrollmentState.creditCheckResults?.energyScoreStatus === CreditCheckStatus.Collect) && (
                    <InfoText>
                        <strong>Warning</strong>
                        <br />
                        Updating your First Name, Middle Initial, or Last Name will require you to run another Credit Check
                    </InfoText>
                )}
                <CustomerContactForm
                    formKey={formKey}
                    offerAccountType={offerAccountType}
                    contact={enrollmentState.defaultContact}
                    idPrefix={idPrefix}
                    showMarketingCheckBox={false}
                />
                <div>
                    <Checkbox
                        {...register('isPaperless')}
                        defaultChecked={enrollmentState.isPaperless}
                        dataTestId={`${idPrefix}-paperless-documents`}
                        label="Enroll in paperless"
                    />
                    <CompanyVerbiage verbiage={company.verbiage[Verbiage.paperlessCommunicationMessage]} />
                </div>
                <div>
                    <Checkbox
                        {...register('defaultContact.isEmailMarketable', { shouldUnregister: true })}
                        dataTestId={`${idPrefix}-is-email-marketable`}
                        defaultChecked={enrollmentState.defaultContact.isEmailMarketable ?? false}
                        label="Sign up for marketing email communications"
                    />
                    <p>Yes, I want to receive additional information, such as new product offerings and special promotions, by email.</p>
                    <EmailMarketingConsentNotice />
                </div>
                <GridSpacer spacing={Spacing.Medium} />
                <IconHeading>
                    <MdLocationOn /> Billing Address
                </IconHeading>
                <Checkbox
                    {...register('isBillingSameAsServiceAddress')}
                    defaultChecked={enrollmentState.isBillingSameAsServiceAddress}
                    label="My billing address is the same as my service address"
                    dataTestId="billingAddressMatchesServiceAddress"
                />

                {billingAddressSame && (
                    <SummaryBody>
                        <AddressDisplay address={enrollmentState.serviceAddress} />
                    </SummaryBody>
                )}
                {!billingAddressSame && (
                    <AddressForm
                        address={enrollmentState.billingAddress}
                        formKey="[billingAddress]."
                        idPrefix="mailing-address-"
                        disableZip={false}
                        labelPrefix="Billing "
                    />
                )}
                {enrollmentState.isRealtor && (
                    <>
                        <GridSpacer spacing={Spacing.Medium} />
                        <IconHeading>
                            <MdHome /> Realtor Information
                        </IconHeading>
                        <RealtorForm />
                    </>
                )}
                <StepNav stepFwdButtonText={stepFwdButtonText} styleStepBackAsButton={true} />
            </FormWrapper>
        </FormProvider>
    )
}
interface ContactInfoStepFields extends RealtorFormFields {
    readonly defaultContact: ContactModel
    readonly isPaperless: boolean
    readonly isBillingSameAsServiceAddress: boolean
    readonly billingAddress: Address
}

interface ContactDetailsStepProps extends CheckoutStepProps {
    readonly checkIsSelectedOfferExpired: () => Promise<boolean>
}
