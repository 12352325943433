import * as React from 'react'
import styled from 'styled-components'

import { formatPhoneNumberForDisplay } from '@igs-web/common-utilities/utilities/phone-utilities'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'

const StyledPhoneNumber = styled.div`
    font-size: 36px;
    line-height: 1.25em;
    font-weight: 600;
`
const StyledHours = styled.div`
    font-size: 14px;
`
const StyledScanaBusinessInfo = styled.div`
    display: grid;
`

export const ScanaBusinessInfo = ({ phoneNumber, hours }: BusinessInfoProps) => {
    return (
        <StyledScanaBusinessInfo>
            <StyledPhoneNumber>
                <PhoneNumberLink phoneNumber={phoneNumber}>{formatPhoneNumberForDisplay(phoneNumber)}</PhoneNumberLink>
            </StyledPhoneNumber>
            <StyledHours>{hours}</StyledHours>
        </StyledScanaBusinessInfo>
    )
}
interface BusinessInfoProps {
    readonly phoneNumber: string
    readonly hours: string
}
