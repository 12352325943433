import styled from 'styled-components'

import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { fontSizeExtraLarge, fontSizeSmall } from '@igs-web/common-ui-components/styles/theme'

export const HomeWarrantyPrice = styled.span``
export const HomeWarrantyPriceDisplay = styled.div`
    position: relative;
    font-weight: ${FontWeight.Bold};
    font-size: ${fontSizeSmall};
    width: 100%;
    ${HomeWarrantyPrice} {
        font-size: ${fontSizeExtraLarge};
    }
`
