import * as React from 'react'

import styled from 'styled-components'

import { UserProfileAccount, UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'
import { TypeaheadSelect } from '@igs-web/common-ui-components/_molecules/typeahead/typeahead-select'
import { clampReadingWidth } from '@igs-web/common-ui-components/styles/theme'
import { getFormattedAddress } from '@igs-web/common-utilities/utilities/address-utilities'

export const matchServiceAddress = (address: UserProfileServiceAddress, inputValue: string, accounts?: ReadonlyArray<UserProfileAccount>): boolean =>
    address.address1?.toLowerCase().includes(inputValue) ||
    address.address2?.toLowerCase().includes(inputValue) ||
    address.city?.toLowerCase().includes(inputValue) ||
    address.state?.toLowerCase().includes(inputValue) ||
    address.zipCode?.includes(inputValue) ||
    (accounts ? accounts.filter(x => x.accountNumber?.includes(inputValue)).some(x => x.serviceAddressKey === address.serviceAddressKey) : false)

const getSelectedServiceAddress = (addresses: ReadonlyArray<UserProfileServiceAddress>, selectedKey?: string): UserProfileServiceAddress | null => {
    if (selectedKey) {
        return addresses.find(address => address.serviceAddressKey === selectedKey) || null
    }
    return null
}
const itemToString = (address: UserProfileServiceAddress | null) => (address ? getFormattedAddress(address) : '')

export const ServiceAddressSelectWrapper = styled.div`
    margin: auto;
    max-width: ${clampReadingWidth};
`

export const ServiceAddressForm = ({ selectedServiceAddressKey, serviceAddresses, onSelect, accounts }: Props): JSX.Element => {
    const filter = (address: UserProfileServiceAddress, inputValue: string) => matchServiceAddress(address, inputValue, accounts)

    return (
        <ServiceAddressSelectWrapper data-testid="service-address-selector">
            <TypeaheadSelect
                items={serviceAddresses}
                onSelect={onSelect}
                selectedItem={getSelectedServiceAddress(serviceAddresses, selectedServiceAddressKey)}
                itemToString={itemToString}
                filter={filter}
                label="Service Address"
                dataTestId="service-address-form"
            />
        </ServiceAddressSelectWrapper>
    )
}
interface Props {
    readonly accounts?: ReadonlyArray<UserProfileAccount>
    readonly serviceAddresses: ReadonlyArray<UserProfileServiceAddress>
    readonly selectedServiceAddressKey?: string
    readonly onSelect: (address: UserProfileServiceAddress) => void
}
