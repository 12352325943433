import * as React from 'react'

import styled from 'styled-components'

import { SmallText } from '@igs-web/common-ui-components/_atoms/typography'
import { TypeaheadSelect } from '@igs-web/common-ui-components/_molecules/typeahead/typeahead-select'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { BillingAccountListItem } from '@igs-web/common-utilities/utilities/billing-account-utilities'

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Spacing.Small};
`

export const BillingAccountSelectForm = ({ billingAccounts, selectedBillingAccount, onSelect }: Props) => {
    const itemToString = (s: BillingAccountListItem) => (s ? `${s.billingAccountNumber}${!s.isActive ? ' - INACTIVE' : ''}` : '')
    const filter = (billingAccount: BillingAccountListItem, inputValue: string) => billingAccount.billingAccountNumber.toLowerCase().includes(inputValue)

    const selectedItem = billingAccounts.find(x => x.billingAccountNumber === selectedBillingAccount) || null

    return (
        <FormContainer>
            <TypeaheadSelect
                items={billingAccounts}
                selectedItem={selectedItem}
                onSelect={(billingAccount: BillingAccountListItem) => onSelect(billingAccount.billingAccountNumber)}
                itemToString={itemToString}
                filter={filter}
                label="Select Billing Account"
                dataTestId="billing-account-select-form"
            />
            <SmallText as="span">Ordered by due date</SmallText>
        </FormContainer>
    )
}

interface Props {
    readonly billingAccounts: ReadonlyArray<BillingAccountListItem>
    readonly selectedBillingAccount?: string
    readonly onSelect: (billingAccountNumber: string) => void
}
