import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const IgsEnergyMark = ({ altText = 'IGS', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 1021 1024" altText={altText}>
        <path d="M510.688 0c-0.361-0.001-0.788-0.001-1.216-0.001-282.717 0-511.904 229.187-511.904 511.904s229.187 511.904 511.904 511.904c282.716 0 511.903-229.186 511.904-511.902v0c0 0 0-0.001 0-0.001 0-282.289-228.495-511.211-510.622-511.902h-0.066zM404.596 900.887c-172.214-48.608-296.333-204.284-296.456-388.969v-0.014c0-13.821 0.768-27.387 2.112-40.824 25.631-6.083 55.087-9.622 85.353-9.726h0.071c84.803 0.043 163.39 26.619 227.921 71.874l-1.275-0.848c-27.692 60.849-43.833 131.964-43.833 206.849 0 57.836 9.628 113.423 27.367 165.247l-1.068-3.589zM486.309 739.509c0.016-216.685 170.991-393.432 385.393-402.712l0.838-0.029c14.85 29.874 26.608 64.546 33.514 100.935l0.4 2.534c-3.135 0-6.399 0-9.342 0-169.806 0.189-307.387 137.887-307.387 307.718 0 54.801 14.325 106.256 39.435 150.825l-0.798-1.541c-30.289 9.827-65.289 16.238-101.542 17.823l-0.839 0.029c-25.034-50.868-39.676-110.726-39.676-174.003 0-0.555 0.001-1.11 0.003-1.665v0.085zM727.224 851.040c-18.076-29.429-28.783-65.071-28.783-103.214 0-109.947 88.962-199.115 198.831-199.45h0.032c4.735 0 9.406 0.64 14.077 0.96-12.351 127.091-82.064 235.686-182.581 300.747l-1.577 0.956z" />
        <path d="M766.064 968.33h13.757v3.455h-5.119v13.629h-3.583v-13.31h-5.055zM800.17 985.095h-3.583v-10.11l-5.183 7.807h-1.152l-5.119-7.871v10.494h-3.711v-16.765h3.135l6.399 9.086 6.399-9.086h3.071z" />
    </SvgIcon>
)
export default IgsEnergyMark
