import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import { CheckoutStepPageIds } from '@igs-web/common-components/domain/checkout/checkout-step-page-ids'
import { useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { OfferSelectors, offersActions } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { OfferModel } from '@igs-web/common-models/models/offer-model'
import { useWizardDispatch } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { getEventModel, pushSelectedRateEvent } from '@igs-web/common-utilities/services/google-analytics-services'

import { ExternalLinkWithArrow } from 'molecules/layout/layout-styles'

import { OfferListView, RatesSectionHeading } from 'domain/offers/offer-list-view'

import { useCheckoutWizardMessagesDispatch } from '../checkout-wizard-messages/checkout-wizard-messages-context'
import { useSelectOffer } from '../hooks/use-select-offer'

import { InvitationCodeForm } from './invitation-code-form'

const StepContainer = styled.div`
    display: grid;
    row-gap: ${Spacing.ExtraLarge};
`

export const SelectRateStep = () => {
    const dispatch = useDispatch()
    const { selectOffer } = useSelectOffer()
    const company = useCompany()
    const { serviceAddress, distributionZone } = useCheckoutState()
    const { setActivePageId, nextPage } = useWizardDispatch()
    const offerRequest = useSelector((store: CommonReduxState) => OfferSelectors.selectOfferRequest(store))
    const offers = useSelector((store: CommonReduxState) => OfferSelectors.selectOffers(store))
    const campaignCode = useSelector((store: CommonReduxState) => OfferSelectors.selectCampaignCode(store))

    const { setShowSelectedOfferExpiredMessage, setShowOfferNotAvailableForZipMessage } = useCheckoutWizardMessagesDispatch()

    React.useEffect(() => {
        window.scrollTo(0, 0)
        if (offerRequest.codeEnteredByCustomer && !offerRequest.isCodeInvalid) {
            setInvitationCode(offerRequest.codeEnteredByCustomer)
        }
    }, [])

    React.useEffect(() => {
        const { isLoading, offerAccountType, invitationCode } = offerRequest
        const zip = serviceAddress?.zipCode
        const cCode = campaignCode || company.enrollment.campaignCodes.default

        if (!isLoading && offerAccountType && zip && cCode) {
            dispatch(
                offersActions.loadOffers({
                    zipCode: zip,
                    campaignCode: cCode,
                    offerAccountType,
                    invitationCode,
                    companyName: company.name,
                    ignoreCache: true,
                    distributionZone,
                }),
            )
        }

        if (zip) {
            dispatch(offersActions.loadMunicipality(zip))
        }
    }, [campaignCode, serviceAddress?.zipCode, company.name, company.enrollment.campaignCodes.default, distributionZone])

    const setInvitationCode = async invitationCode => {
        dispatch(offersActions.submitOfferRequestForm({ zipOrInviteCode: invitationCode }))
    }

    const handleSelectedOffer = (offer: OfferModel) => {
        selectOffer(offer)
        setShowSelectedOfferExpiredMessage(false)
        setShowOfferNotAvailableForZipMessage(false)
        pushSelectedRateEvent(getEventModel([offer]), nextPage)
    }

    const handleBackToAddressStep = () => {
        setShowOfferNotAvailableForZipMessage(false)
        setActivePageId(CheckoutStepPageIds.CustomerAddress)
    }

    return (
        <StepContainer>
            <ExternalLinkWithArrow onClick={handleBackToAddressStep} arrowLeft={true}>
                Back to Address
            </ExternalLinkWithArrow>
            <InvitationCodeForm />
            <RatesSectionHeading>
                <span data-testid="offers-headline">Rates</span>
                {!offerRequest.isCodeInvalid && offerRequest.codeEnteredByCustomer ? (
                    <span data-testid="offers-message-1"> for {offerRequest.codeEnteredByCustomer.toUpperCase()}</span>
                ) : (
                    ''
                )}
            </RatesSectionHeading>
            <OfferListView offers={offers} handleSelectedOffer={handleSelectedOffer} showHeroOnly={false} />
        </StepContainer>
    )
}
