import styled from 'styled-components'

import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

export const PageContentContainer = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: calc(${Breakpoint.Desktop} + (${Spacing.Large} * 2))) {
        margin: ${Spacing.Large};
        width: auto;
    }
    @media (max-width: ${Breakpoint.Desktop}) {
        margin: ${Spacing.Medium};
    }

    @media (max-width: ${Breakpoint.Mobile}) {
        margin-left: ${Spacing.Small};
        margin-right: ${Spacing.Small};
    }
`
