import * as React from 'react'
import { Link } from './link'

export const EmailLink = ({ toAddress, subject }: Props): JSX.Element => {
    const queryParams = subject ? `?subject=${subject}` : ''
    return (
        <Link href={`mailto:${toAddress}${queryParams}`} dataTestId="email-link">
            {toAddress}
        </Link>
    )
}

interface Props {
    readonly toAddress: string
    readonly subject?: string
}
