import * as React from 'react'
import { BsFillExclamationCircleFill } from 'react-icons/bs'

import styled, { css } from 'styled-components'

import { Button, ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { ColorwayContainer, ColorwayType } from '@igs-web/common-ui-components/_molecules/theming/colorway-container'
import { mdWidth, myaccountSpacing, secondaryDark } from '@igs-web/common-ui-components/styles/theme'
import { formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'
import { formatPhoneNumberForDisplay } from '@igs-web/common-utilities/utilities/phone-utilities'

import { urlHelper } from 'root/shared-services/url-helper'

import { AlertCard } from '../alerts/alert-card'
import { SupportedCompanies } from '../company/company'
import { useCompany } from '../company/hooks/useCompany'

const ExclamationPointIcon = styled(BsFillExclamationCircleFill)`
    background-color: ${secondaryDark};
`
const buttonStyles = css`
    margin-left: auto;
    @media (max-width: ${mdWidth}) {
        margin-left: unset;
        margin-top: ${myaccountSpacing};
    }
`
const PayBillLink = styled(Button)`
    ${buttonStyles}
`

const StyledPastDueBalanceCard = styled(AlertCard)`
    button {
        ${buttonStyles}
    }
`

export const PastDueBalanceAlert = ({ pastDueBalance, billingAccountNumber, pendingDisconnect = false, updateAutoPay = false }: Props): JSX.Element => {
    const hasBalanceDue = pastDueBalance !== 0
    const { routes, name, phoneNumber } = useCompany()

    if (!hasBalanceDue) {
        return <React.Fragment />
    }
    if (!updateAutoPay) {
        if (pendingDisconnect && name !== SupportedCompanies.Igs) {
            return (
                <LinkedPastDueBalanceAlertDisplay linkUrl={routes.payment.payBill(billingAccountNumber)}>
                    Your account is past due and scheduled to be disconnected. Your past due balance is {formatAsMoney(pastDueBalance)}. Please call{' '}
                    {formatPhoneNumberForDisplay(phoneNumber.customerSupport)} to arrange payment.
                </LinkedPastDueBalanceAlertDisplay>
            )
        } else {
            return (
                <LinkedPastDueBalanceAlertDisplay linkUrl={routes.payment.payBill(billingAccountNumber)}>
                    You have a past due account balance of {formatAsMoney(pastDueBalance)}
                </LinkedPastDueBalanceAlertDisplay>
            )
        }
    }
    return (
        <PastDueBalanceAlertDisplay>
            You have a past due account balance of {formatAsMoney(pastDueBalance)}
            <PayBillLink buttonStyle={ButtonStyleType.Highlight} onClick={() => urlHelper.payBill().go()}>
                Update Auto-pay
            </PayBillLink>
        </PastDueBalanceAlertDisplay>
    )
}

export const LinkedPastDueBalanceAlertDisplay = ({ linkUrl, children }: LinkedDisplayProps) => {
    return (
        <Link href={linkUrl}>
            <PastDueBalanceAlertDisplay>{children}</PastDueBalanceAlertDisplay>
        </Link>
    )
}

export const PastDueBalanceAlertDisplay = ({ children }: DisplayProps) => {
    return (
        <ColorwayContainer colorwayType={ColorwayType.RustOrange}>
            <StyledPastDueBalanceCard dataTestId="past-due-balanace-alert">
                <span>
                    <ExclamationPointIcon />
                </span>
                {children}
            </StyledPastDueBalanceCard>
        </ColorwayContainer>
    )
}

interface DisplayProps {
    readonly children: React.ReactNode
}

interface LinkedDisplayProps extends DisplayProps {
    readonly linkUrl: string
}

interface Props {
    readonly pastDueBalance: number
    readonly pendingDisconnect?: boolean
    readonly billingAccountNumber?: string
    readonly updateAutoPay?: boolean
}
