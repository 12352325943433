import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { UserSelectors, userActions } from '@igs-web/common-components/domain/user/user-redux'
import { getAuthToken, hasValidAuthToken, isAuthTokenExpired } from '@igs-web/common-utilities/utilities/auth-token-utilities'

import * as Routes from 'domain/routes'

export const ProtectedRoute = ({ children, ...rest }: RouteProps) => {
    const isAuthenticated = useSelector(UserSelectors.selectLoggedIn) && hasValidAuthToken()
    const dispatch = useDispatch()
    const token = getAuthToken()
    if (!isAuthenticated) {
        dispatch(userActions.logUserOut())
        if (token && isAuthTokenExpired(token)) {
            dispatch(userActions.setLogoutMessage('Your session has expired. Please log in again.'))
        }
    }

    const returnTo = rest.location?.pathname === Routes.LoginRoute ? undefined : rest.location?.pathname
    return (
        <Route
            {...rest}
            render={() =>
                isAuthenticated ? (
                    <>{children}</>
                ) : (
                    <Redirect
                        to={{
                            pathname: Routes.LoginRoute,
                            state: returnTo,
                        }}
                    />
                )
            }
        />
    )
}
