import * as React from 'react'

import { ActionLink } from '@igs-web/common-ui-components/_atoms/link/action-link'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { downloadBlob } from '@igs-web/common-utilities/utilities/document-utilities'

const downloadInvoice = async (accountId: number, invoiceNumber: string): Promise<void> => {
    const blob = await customerInfoApiClient.getInvoice(accountId, invoiceNumber)
    const filename = `Invoice-${invoiceNumber}`
    downloadBlob(blob, filename, 'pdf')
}

export const InvoicePdfLink = ({ accountId, invoiceNumber }: InvoicePdfLinkProps): JSX.Element => {
    return (
        <ActionLink
            onClick={(): void => {
                downloadInvoice(accountId, invoiceNumber)
            }}
        >
            {invoiceNumber}
        </ActionLink>
    )
}

interface InvoicePdfLinkProps {
    readonly accountId: number
    readonly invoiceNumber: string
}
