import * as React from 'react'

import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'

export const DefaultEmptyTable = ({ tableDataType, phoneNumber, emptyTableMessage }: Props) =>
    tableDataType && phoneNumber ? (
        <>
            No historical {tableDataType} available. Please call <PhoneNumberLink phoneNumber={phoneNumber} /> for more information.
        </>
    ) : (
        <>{emptyTableMessage ? emptyTableMessage : 'No Results'}</>
    )

interface Props {
    readonly tableDataType?: string
    readonly phoneNumber?: string
    readonly emptyTableMessage?: string
}
