import { CachedReduxDataStateType, setupCachedReduxData } from '@igs-web/common-components/domain/cached-redux/cached-redux-data'
import { LegacyUsage } from '@igs-web/common-models/models/legacy-data'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

const load = async (billingAccountNumber: number, config: RequestConfig): Promise<ReadonlyArray<LegacyUsage> | undefined> => {
    try {
        const usages = await customerInfoApiClient.getLegacyUsages(billingAccountNumber, config)

        if (!usages) {
            throw Error(`Usage is not defined for ${billingAccountNumber}`)
        }

        return usages.map(u => ({
            ...u,
            fromDate: u.fromDate ? Date.parse(u.fromDate) : undefined,
            toDate: Date.parse(u.toDate),
        }))
    } catch (e) {
        console.error(`There was a problem getting usage: ${e.message}`)
    }
}

const { actions, selectors, saga, reducers } = setupCachedReduxData('legacyUsage', load)

export const legacyUsageRedux = { actions, selectors }
export const legacyUsageSaga = saga
export const legacyUsageReducer = reducers
export type LegacyUsageState = CachedReduxDataStateType<typeof load>
