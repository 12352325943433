import * as React from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'

import styled from 'styled-components'

import { LinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { ScrollToTop } from 'molecules/routing/scroll-to-top'

import { WarningHeading } from 'domain/checkout/shared/checkout-components'

import { AddressSearchSteps } from './hw-address-step'

const GridWrapper = styled.div`
    display: grid;
    grid-gap: ${Spacing.Large};
`

const StyledButton = styled(LinkButton)`
    width: max-content;
    justify-self: right;
`

export const AddressNotEligibleStep = ({ setCurrentStep }: Props) => {
    return (
        <GridWrapper>
            <ScrollToTop />
            <WarningHeading>
                <AiOutlineInfoCircle /> Address Not Eligible
            </WarningHeading>
            <p>
                You've selected an address with a zip code that lies outside of our service area. We apologize, this address is not eligible for a Home Warranty
                plan.
            </p>
            <StyledButton onClick={() => setCurrentStep(AddressSearchSteps.search)}>Back</StyledButton>
        </GridWrapper>
    )
}

interface Props {
    readonly setCurrentStep: React.Dispatch<React.SetStateAction<string>>
}
