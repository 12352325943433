import * as React from 'react'

import styled from 'styled-components'

import { Button, ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { InlineInput } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

const SearchContainer = styled.form`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: ${Spacing.ExtraSmall};
    ${InlineInput} {
        flex-grow: 2;
    }
`

export const HWZipCodeSearch = ({ setZipCode }: Props) => {
    const [zipCodeValue, setZipCodeValue] = React.useState<string>()

    const handleChange = e => {
        setZipCodeValue(e.target.value)
    }

    const handleSubmit = e => {
        e.preventDefault()
        if (zipCodeValue) {
            setZipCode(zipCodeValue)
        }
    }

    return (
        <>
            <div>Enter your ZIP code to browse plans in your area.</div>
            <SearchContainer onSubmit={handleSubmit}>
                <InlineInput type="text" name="zipCode" placeholder="Enter ZIP Code" onChange={handleChange} data-testid="hw-zip-code-search" />

                <Button buttonStyle={ButtonStyleType.Primary} type="submit" data-testid="hw-zip-code-search-button">
                    Search
                </Button>
            </SearchContainer>
        </>
    )
}

interface Props {
    readonly setZipCode: (zipCode: string) => void
}
