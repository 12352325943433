import './billing-overview-style.scss'

import * as React from 'react'
import { useSelector } from 'react-redux'

import { UtilityAccountDetail } from '@igs-web/common-models/models/account-detail-model'
import { ChoiceTransactionHistory } from '@igs-web/common-models/models/choice-transaction-history'
import { UtilityAccountActivity } from '@igs-web/common-models/models/utility-account-activity'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { LinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'
import { formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'
import { formatDate } from '@igs-web/common-utilities/utilities/date-utilities'

import { useCompany } from '../company/hooks/useCompany'
import { MyAccountSelectors } from '../myaccount-redux'
import { ProfileSection, userProfileRoutes } from '../user-profile/user-profile-routes'
import { isErcotUtility } from './business/billing-service'

const DataField = ({ title, children }: DataFieldProps) => (
    <div className="info col-12 col-sm-6 col-md">
        <span className="title">{title}</span>
        <span className="value">
            <span className="amount">{children || '-'}</span>
        </span>
    </div>
)
interface DataFieldProps {
    readonly title: string
    readonly children: React.ReactNode
}

const PaymentSection = ({ accountId, allowAutopayPaymentProfiles }: UtilityAccountDetail) => {
    const { routes } = useCompany()

    return (
        <div>
            <LinkButton href={routes.payment.payBill()} dataTestId="make-a-payment-button">
                Make a Payment
            </LinkButton>
            <br />
            <div className="link-group">
                {!!allowAutopayPaymentProfiles && (
                    <Link href={routes.payment.autoPay(accountId)} dataTestId="auto-pay-link">
                        Auto-Pay
                    </Link>
                )}
                <Link href={userProfileRoutes.userProfileUrl(ProfileSection.CommunicationPreferences).asString()} dataTestId="paperless-billing-link">
                    Paperless Billing
                </Link>
            </div>
        </div>
    )
}

export const BillingAccountStatus = ({ accountActivity, accountDetails }: BillingAccountStatusProps) => {
    const selectedServiceAddress = useSelector(MyAccountSelectors.selectSelectedServiceAddress)
    const displayErcotFields = isErcotUtility(selectedServiceAddress?.state)
    const { features } = useCompany()

    return (
        <>
            <h3 className="tab-content-header">CURRENT STATUS</h3>
            <div className="billing-overview">
                <div className="info-container">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="row no-gutters">
                                {features.useBillingAccounts && <DataField title="Billing Account Number">{accountActivity.billingAccountNumber}</DataField>}
                                {displayErcotFields && (
                                    <>
                                        <DataField title="ERCOT ESI ID">{accountDetails.accountNumber}</DataField>
                                        <DataField title="Utility">{accountActivity.nickname}</DataField>
                                    </>
                                )}
                                {accountActivity.currentBillDueDate && <DataField title="Due Date">{formatDate(accountActivity.currentBillDueDate)}</DataField>}
                                <DataField title="Amount Due">{formatAsMoney(accountActivity.totalAmountDue)}</DataField>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <PaymentSection {...accountDetails} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

interface BillingAccountStatusProps {
    readonly accountId: number
    readonly accountActivity: ChoiceTransactionHistory | UtilityAccountActivity
    readonly accountDetails: UtilityAccountDetail
}
