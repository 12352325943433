import * as React from 'react'

import styled from 'styled-components'

import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import {
    alertBackgroundColor,
    alertBorderColor,
    error,
    lightAccent,
    primaryBase,
    success,
    warning,
    warningContrast,
} from '@igs-web/common-ui-components/styles/theme'

const Alert = ({ message, children, show = true, className, dataTestId }: AlertProps): JSX.Element | null =>
    !show || (!message && !children) ? null : (
        <div className={className} data-testid={dataTestId}>
            {message}
            {children}
        </div>
    )

export interface AlertProps {
    readonly show?: boolean
    readonly className?: string
    readonly message?: string | JSX.Element
    readonly children?: React.ReactNode
    readonly dataTestId?: string
}

export const BootstrapAlert = styled(Alert)`
    font-weight: ${FontWeight.Default};
    padding: 0 ${Spacing.Large};

    &.form-alert-success {
        color: ${success};
        padding: 0;
    }

    &.form-alert-error {
        color: ${error};
    }
    &.alert-warning {
        color: ${warningContrast};
        background-color: ${warning};
    }

    .usage-profile-grid & {
        grid-column: -1/1;
    }
`
export const BoxedErrorAlert = styled(Alert)`
    color: ${error};
    background-color: ${alertBackgroundColor};
    border-color: ${alertBorderColor};
    font-weight: ${FontWeight.Default};
    padding: 0px ${Spacing.Large};
    margin-bottom: 1rem;
    border: 1px solid transparent;
`

export const StyledSuccessAlert = styled(BootstrapAlert)`
    color: ${primaryBase};
    background-color: ${lightAccent};
    font-weight: ${FontWeight.Default};
    padding: 0px ${Spacing.Large};
    margin-bottom: 1rem;
`

export const ErrorAlert = ({ className, ...props }: AlertProps): JSX.Element => <BootstrapAlert className={`form-alert-error ${className || ''}`} {...props} />

export const SuccessAlert = ({ className, ...props }: AlertProps): JSX.Element => (
    <BootstrapAlert className={`form-alert-success ${className || ''}`} dataTestId="communication-preferences-message" {...props} />
)

export const BoxedSuccessAlert = ({ className, ...props }: AlertProps): JSX.Element => (
    <BootstrapAlert className={`alert alert-success ${className || ''}`} {...props} />
)

export const BoxedWarningAlert = ({ className, ...props }: AlertProps): JSX.Element => (
    <BootstrapAlert className={`alert alert-warning ${className || ''}`} {...props} />
)

export const BoxedSecondaryAlert = ({ className, ...props }: AlertProps): JSX.Element => (
    <BootstrapAlert className={`alert alert-secondary ${className || ''}`} {...props} />
)
