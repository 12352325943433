import styled from 'styled-components'

import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fullBleedWidth, maxContentWidth } from '@igs-web/common-ui-components/styles/theme'

export const MasterGrid = styled.div<Props>`
    display: grid;
    grid-template-columns: 1fr ${({ fullBleed }: Props) => (fullBleed ? fullBleedWidth : maxContentWidth)} 1fr;
    grid-gap: ${Spacing.None};
    align-items: center;
    justify-items: center;
    background: var(--background);
    > * {
        grid-column: 2;
    }
    @media (max-width: ${({ fullBleed }: Props) => (fullBleed ? fullBleedWidth : maxContentWidth)}) {
        grid-template-columns: 1fr;
        max-width: 100%;
        > * {
            grid-column: 1;
            max-width: 100%;
        }
    }
`

interface Props {
    readonly fullBleed?: boolean
}
