import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const ExclamationShadowCircle = ({ altText = 'Warning', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 1021 1024" altText={altText}>
        <path d="M512 0c-282.624 0-512 229.376-512 512s229.376 512 512 512 512-229.376 512-512-229.376-512-512-512zM565.248 737.28h-108.544v-108.544h108.544v108.544zM569.344 280.576l-30.72 296.96h-57.344l-30.72-296.96v-14.336h118.784v14.336z" />
    </SvgIcon>
)
export default ExclamationShadowCircle
