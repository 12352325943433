import _noop from 'lodash-es/noop'
import * as React from 'react'
import styled from 'styled-components'

import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { bodyColor, darkBase, fontSizeSmall, lightAccent } from '@igs-web/common-ui-components/styles/theme'
import { getPosition } from '@igs-web/common-utilities/utilities/element-utilities'

export const ToolTipContainer = styled.div`
    position: relative;
    cursor: default;

    input[type='checkbox'] {
        display: none;
    }
    .igs-icon,
    svg {
        height: 100%;
        width: 1.5rem;
        vertical-align: middle;
    }

    .tooltip-text {
        display: none;
        font-weight: ${FontWeight.Medium};
        font-size: ${fontSizeSmall};
        //width: px-to-rem(300px);
        background-color: ${lightAccent};
        color: ${bodyColor};
        text-align: left;
        padding: 20px;
        border-radius: 1px;
        box-shadow: 0 0 40px ${darkBase};

        position: absolute;
        z-index: 1000;
        top: 75%;
        left: 50%;
        //margin-left: px-to-rem(-200px);
        opacity: 0;
        transition: opacity 1s;
    }

    .tooltip-arrow {
        display: none;
        width: 12px;
        height: 12px;
        background: ${lightAccent};
        position: absolute;
        top: -6px;
        z-index: 201;
        transform: rotate(45deg);
        box-sizing: border-box;
        transition: opacity 1s;
    }

    &.checkbox-mode {
        input:checked + .tooltip-text,
        input:checked + .tooltip-text .tooltip-arrow {
            display: block;
            opacity: 1;
        }
    }

    &:not(.checkbox-mode) {
        &:hover .tooltip-text,
        &:hover .tooltip-arrow {
            display: block;
            opacity: 1;
        }
    }
`
export class ElementWithTooltip extends React.Component<ToolTipProps, ToolTipState> {
    // eslint-disable-next-line functional/prefer-readonly-type
    private divElement: HTMLDivElement | null
    private readonly mode: string

    constructor(props: ToolTipProps) {
        super(props)
        this.state = {
            tooltipWidth: 0,
            marginLeft: 0,
            checked: false,
        }
        this.mode = navigator.userAgent.match(/(iPad|iPhone|iPod|android)/i) ? 'checkbox-mode' : ''
    }

    private readonly getPosition = () => {
        if (this.divElement) {
            const pos = getPosition(this.divElement)
            return { offsetLeft: pos.x, screenWidth: window.innerWidth, divWidth: this.divElement.clientWidth }
        }
    }

    private readonly updatePositionAttributes = () => {
        const position = this.getPosition()
        if (!position) {
            return { tooltipText: {}, tooltipArrow: {} }
        }

        const defaultWidth = this.props.defaultTooltipWidth || 400
        const tooltipWidth = position.screenWidth > defaultWidth + 30 ? defaultWidth : position.screenWidth - 20
        // eslint-disable-next-line functional/no-let
        let marginLeft = position.offsetLeft > tooltipWidth / 2 + 20 ? 0 - tooltipWidth / 2 : 0 - position.offsetLeft - position.divWidth / 2

        if (position.screenWidth < tooltipWidth + position.offsetLeft + marginLeft) {
            marginLeft = 0 - (tooltipWidth - (position.screenWidth - position.offsetLeft - position.divWidth))
        }

        this.setState({ tooltipWidth, marginLeft })
    }

    private readonly getTooltipTextStyle = () => ({
        width: `${this.state.tooltipWidth}px`,
        marginLeft: `${this.state.marginLeft}px`,
    })

    private readonly getTooltipArrowStyle = () => ({
        left: `${0 - this.state.marginLeft - 6}px`,
    })

    private readonly toggleCheckbox = () => {
        this.setState({ checked: !this.state.checked })
        this.updatePositionAttributes()
    }

    public render() {
        const { children, tooltipContents, className, ...rest } = this.props
        return (
            <ToolTipContainer
                className={`igs-tooltip ${className || ''} ${this.mode}`}
                ref={r => (this.divElement = r)}
                onMouseOver={e => {
                    this.updatePositionAttributes()
                    ;(rest.onMouseOver || _noop)(e)
                }}
                {...rest}
            >
                <label>
                    {children}
                    <input type="checkbox" checked={this.state.checked} onChange={this.toggleCheckbox} />
                    <span className="tooltip-text" style={this.getTooltipTextStyle()}>
                        {tooltipContents}
                        <span className="tooltip-arrow" style={this.getTooltipArrowStyle()} />
                    </span>
                </label>
            </ToolTipContainer>
        )
    }
}

interface ToolTipProps extends React.HTMLAttributes<HTMLDivElement> {
    readonly defaultTooltipWidth?: number
    readonly tooltipContents: JSX.Element | string | React.ReactNode
}

interface ToolTipState {
    readonly tooltipWidth: number
    readonly marginLeft: number
    readonly checked: boolean
}

export const IconWithTooltip = (props: ToolTipProps): JSX.Element => (
    <ElementWithTooltip {...props} style={{ display: 'inline-block' }}>
        <span className="igs-icon info-icon" />
    </ElementWithTooltip>
)
