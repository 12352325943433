import * as React from 'react'

import { ExternalLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { Button } from '@igs/react-styled-components'

import { chatService } from 'root/shared-services/chat-service'

import { NavigationBanner } from 'molecules/header/navigation-banner'

import { useCompany } from '../company/hooks/useCompany'

export const ScreenSharePage = (): JSX.Element => {
    const company = useCompany()
    const screenShareName = `${company.displayName} Screen Share`
    return (
        <>
            <NavigationBanner title={screenShareName} />
            <PageContentContainer>
                <GridSpacer spacing={Spacing.Large} />
                <Header3>Thank you for accessing {screenShareName}.</Header3>

                <p>Please click the button below to share your screen with the agent.</p>
                <Button dataTestId="start-co-browse-button" onClick={chatService.openGenesysCoBrowse}>
                    Join Co-browse
                </Button>
                <p>
                    By entering the meeting ID, you accept and agree to be bound and abide by these{' '}
                    <ExternalLink href="https://cdn.igs.com/cdn/web/ScreenShare/screenshare-terms-of-use.pdf">Screen Share Terms of Use</ExternalLink>
                </p>
            </PageContentContainer>
        </>
    )
}
