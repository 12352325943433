import * as React from 'react'
import styled from 'styled-components'

export enum ButtonStyleType {
    Primary = 'Primary',
    Secondary = 'Secondary',
    Highlight = 'Hightlight',
}

export type ButtonType = 'button' | 'reset' | 'submit' | undefined

export const mapToStyle = (buttonStyle: ButtonStyleType): string => {
    switch (buttonStyle) {
        case ButtonStyleType.Primary:
            return 'btn-primary'
        case ButtonStyleType.Secondary:
            return 'btn-secondary'
        case ButtonStyleType.Highlight:
            return 'btn-highlight'
        default:
            throw Error(`Button Style not supported: ${buttonStyle}`)
    }
}
const StyledButton = styled.button`
    &.btn-highlight {
        background-color: var(--btn-highlight-background);
        color: var(--btn-highlight-text);
        border: 1px solid var(--btn-highlight-text);
        border-radius: var(--border-radius);

        &:hover,
        &:focus,
        &:active {
            background-color: var(--btn-highlight-hover);
            border-color: var(--btn-highlight-hover);
        }
    }
`

export const Button = ({ className, type = 'button', buttonStyle = ButtonStyleType.Primary, dataTestId, ...htmlProps }: ButtonProps): JSX.Element => {
    const buttonStyleClass = mapToStyle(buttonStyle)
    return <StyledButton data-testid={dataTestId} type={type} className={`btn ${buttonStyleClass} ${className || ''}`} {...htmlProps} />
}

export interface ButtonProps extends ButtonBaseProps, React.ButtonHTMLAttributes<HTMLButtonElement> {}

export interface ButtonBaseProps {
    readonly buttonStyle?: ButtonStyleType
    readonly dataTestId?: string
}
