export const getUrlParameter = (name: string): string => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)', 'i')
    const results = regex.exec(location.search)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const buildQueryStringUrl = (obj: object, url: string): string => {
    return Object.keys(obj).reduce((result, key) => updateQueryStringUrl(key, obj[key], result), url) || url
}

export const getQueryStringObject = () => {
    const search = location.search.substring(1)
    return search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : {}
}

export const updateQueryString = (obj: { readonly [key: string]: string | null | undefined }): void => {
    Object.keys(obj).forEach(key => {
        const url = updateQueryStringUrl(key, obj[key])
        window.history.pushState({ path: url }, '', url)
    })
}

const updateQueryStringUrl = (key: string, value: string | null | undefined, url?: string): string | undefined => {
    if (!url) {
        url = window.location.href
    }
    value = value ? encodeURIComponent(value) : ''

    const regex = new RegExp('([?&])' + key + '=.*?(&|#|$)(.*)', 'gi')
    let hash

    if (regex.test(url)) {
        if (typeof value !== 'undefined' && value !== null) {
            return url.replace(regex, '$1' + key + '=' + value + '$2$3')
        } else {
            hash = url.split('#')
            url = hash[0].replace(regex, '$1$3').replace(/(&|\?)$/, '')
            if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
                url += '#' + hash[1]
            }
            return url
        }
    } else {
        if (typeof value !== 'undefined' && value !== null) {
            const separator = url.includes('?') ? '&' : '?'
            hash = url.split('#')
            url = hash[0] + separator + key + '=' + value
            if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
                url += '#' + hash[1]
            }
            return url
        } else {
            return url
        }
    }
}
