import React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { Button, ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { TextInput } from '@igs-web/common-ui-components/_atoms/form-inputs/text-input'

import { LoginRoute } from 'domain/routes'

import { LoginFormGrid, SpanColumns } from './authentication-components'

export const RequestPasswordForm = ({ onSubmit }: RequestPasswordForm) => {
    const history = useHistory()

    const { control, handleSubmit } = useForm<RequestPasswordFormFields>()

    return (
        <form onSubmit={handleSubmit(data => onSubmit(data))}>
            <LoginFormGrid>
                <SpanColumns>
                    <TextInput
                        control={control}
                        name="email"
                        type="email"
                        dataTestId="reset-password-email"
                        label="Account Email Address"
                        rules={{ required: 'Required' }}
                    />
                </SpanColumns>
                <Button data-testid="sign_in_help_cancel_button" buttonStyle={ButtonStyleType.Secondary} onClick={() => history.push(LoginRoute)}>
                    Cancel
                </Button>
                <Button data-testid="sign_in_help_submit_button" type="submit">
                    Submit
                </Button>
            </LoginFormGrid>
        </form>
    )
}
export interface RequestPasswordFormFields {
    readonly email: string
}
interface RequestPasswordForm {
    readonly onSubmit: (data: RequestPasswordFormFields) => void
}
