import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FaRegAddressCard } from 'react-icons/fa'
import { useSelector } from 'react-redux'

import { CustomerContactForm } from '@igs-web/common-components/domain/checkout/forms/customer-contact-form'
import { useCheckoutDispatch, useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { StepNav } from '@igs-web/common-components/domain/checkout/steps/step-nav'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { OfferAccountType } from '@igs-web/common-models/constants/account-type'
import { ContactModel } from '@igs-web/common-models/models/contact-model'
import { FormWrapper } from '@igs-web/common-ui-components/_atoms/form-wrapper'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { Checkbox } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { useWizardDispatch } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { getEventModel, pushAddedContactInfoEventToGa } from '@igs-web/common-utilities/services/google-analytics-services'

import { ScrollToTop } from 'molecules/routing/scroll-to-top'

export const HwContactDetailsStep = () => {
    const enrollmentState = useCheckoutState()
    const selectedOffers = useSelector(OfferSelectors.selectSelectedOffers)
    const { setEnrollmentState } = useCheckoutDispatch()
    const { nextPage } = useWizardDispatch()
    const formKey = '[defaultContact].'
    const idPrefix = 'default-contact'
    const methods = useForm<ContactInfoStepFields>({
        defaultValues: {
            isBillingSameAsServiceAddress: enrollmentState.isBillingSameAsServiceAddress,
        },
    })
    const { handleSubmit, register } = methods

    const onSubmit = data => {
        setEnrollmentState({ ...enrollmentState, ...data })
        pushAddedContactInfoEventToGa(getEventModel(selectedOffers), nextPage)
    }

    return (
        <FormProvider {...methods}>
            <ScrollToTop />
            <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                <IconHeading>
                    <FaRegAddressCard /> My Details
                </IconHeading>
                <CustomerContactForm
                    formKey={formKey}
                    offerAccountType={OfferAccountType.Residential}
                    contact={enrollmentState.defaultContact}
                    idPrefix={idPrefix}
                    showMarketingCheckBox={false}
                />
                <div>
                    <Checkbox
                        {...register('defaultContact.isEmailMarketable', { shouldUnregister: true })}
                        dataTestId={`${idPrefix}-is-email-marketable`}
                        defaultChecked={enrollmentState.defaultContact.isEmailMarketable ?? false}
                        label="Sign up for marketing email communications"
                    />
                    <p>Yes, I want to receive additional information, such as new product offerings and special promotions, by email.</p>
                </div>
                <div>
                    <Checkbox
                        {...register('isPaperless')}
                        defaultChecked={enrollmentState.isPaperless}
                        dataTestId={`${idPrefix}-paperless-documents`}
                        label="Enroll in paperless"
                    />
                    <p>
                        Yes, I want to receive my account and service related communications by email, and I verify that the email address I’ve provided is my
                        primary email.
                    </p>
                </div>
                <StepNav stepFwdButtonText="CONTINUE" styleStepBackAsButton={true} />
            </FormWrapper>
        </FormProvider>
    )
}

interface ContactInfoStepFields {
    readonly defaultContact: ContactModel
    readonly isPaperless: boolean
    readonly isBillingSameAsServiceAddress: boolean
}
