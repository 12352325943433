import * as React from 'react'

import { formatPhoneNumberForDisplay } from '@igs-web/common-utilities/utilities/phone-utilities'

import { ButtonStyleType } from '../buttons/button'
import { Link, LinkProps } from './link'
import { LinkButton } from './link-button'

export const PhoneNumberLink = ({ phoneNumber, ...rest }: PhoneNumberLinkProps): JSX.Element | null =>
    !!phoneNumber ? (
        <Link href={`tel:${phoneNumber}`} {...rest}>
            {formatPhoneNumberForDisplay(phoneNumber)}
        </Link>
    ) : null

export const PhoneNumberLinkButton = ({ phoneNumber,children, ...rest }: PhoneNumberLinkButtonProps): JSX.Element | null =>
    !!phoneNumber ? (
        <LinkButton href={`tel:${phoneNumber}`} {...rest}>
          {children}
        </LinkButton>
    ) : null

interface PhoneNumberLinkProps extends Omit<LinkProps, 'href'> {
    readonly phoneNumber: string
}

interface PhoneNumberLinkButtonProps extends PhoneNumberLinkProps{
    readonly children: React.ReactNode
    readonly buttonStyle?: ButtonStyleType
}