import * as React from 'react'

import styled, { css } from 'styled-components'

import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { lightLight, mdWidth, primaryDark, primaryLight } from '@igs-web/common-ui-components/styles/theme'

export const navItemStyle = css<BorderProp>`
    --_item-border-color: var(--panel-border-color, rgba(255, 255, 255, 0.5));
    --_item-text-color: var(--panel-text-color, ${lightLight});
    --_nav-item-font-size: var(--nav-item-font-size, 1.25rem);
    --_nav-item-font-weight: var(--nav-item-font-weight, 500);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${({ $noBorder }: BorderProp) => ($noBorder ? 'border-top:0' : 'border-top: 1px solid var(--_item-border-color);')}
    cursor: pointer;
    color: var(--_item-text-color);
    font-size: var(--_nav-item-font-size);
    padding: ${Spacing.Medium} ${Spacing.Large};
    width: 100%;
    font-weight: var(--_nav-item-font-weight);
    text-transform: uppercase;

    span.icon {
        color: var(--_item-text-color);
        font-size: 1.25rem;
    }
    a {
        display: flex;
        flex-direction: column;
        color: var(--_item-text-color);
    }
    &.link {
        color: var(--_item-text-color);
        &:hover {
            color: ${primaryLight};
            text-decoration: none;
        }
    }
    &:hover,
    &:active {
        text-decoration: none;
    }

    .btn {
        max-width: 100%;
        border-color: var(--_item-text-color);

        &.btn-primary {
            background-color: ${lightLight};
            color: ${primaryDark};
            &:hover {
                background-color: transparent;
                color: ${lightLight};
            }
        }
        &.btn-secondary {
            &:hover {
                background-color: ${lightLight};
                color: ${primaryDark};
            }
        }
    }

    @media (min-width: ${mdWidth}) {
        font-size: 1rem;
    }
`
interface BaseNavItemProps extends React.HTMLAttributes<HTMLDivElement> {
    readonly dataTestId: string
    readonly noBorder?: boolean
}

export interface BorderProp {
    readonly $noBorder?: boolean
}

export const StyledNavItem = styled.div<BorderProp>`
    ${navItemStyle}
`
export const NavItem = ({ dataTestId, noBorder = false, ...divProps }: BaseNavItemProps) => (
    <StyledNavItem data-testid={dataTestId} $noBorder={noBorder} {...divProps} />
)

export const navItemLinkStyle = css`
    ${navItemStyle}
    &.link:not([href]) {
        color: var(--panel-text-color);
    }
    /* iOS is doing something wierd with the hover color this code, which shouldn't activte fixes that wierdness */
    @media (hover: none) {
        &.link:hover {
            color: var(--panel-text-color);
        }
    }
`

export const NavItemLink = styled(Link)`
    ${navItemLinkStyle}
`

export const NavItemPhoneLink = styled(PhoneNumberLink)`
    ${navItemLinkStyle}
`
