import { SubmitButton } from '@igs-web/common-components/_molecules/final-form/submit-button'
import { ChangeUserNameRequest } from '@igs-web/common-models/models/user-profile-model'
import { TextField } from '@igs-web/common-ui-components/_atoms/form-inputs/text-field'
import { ErrorAlert, SuccessAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { HtmlForm } from '@igs-web/common-ui-components/_atoms/forms/html-form'
import { TypedForm } from '@igs-web/common-ui-components/_atoms/forms/typed-form'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { clearFormValues, formError } from '@igs-web/common-utilities/services/form-service'
import { required } from '@igs-web/common-utilities/utilities/form-validation'
import { FormApi } from 'final-form'
import * as React from 'react'
import { FormRenderProps } from 'react-final-form'
import styled from 'styled-components'

const SubmitContainer = styled.div`
    text-align: right;
`

export const ChangeUserNameForm = ({ onComplete }: Props): JSX.Element => {
    const changeName = async (request: ChangeUserNameRequest, form: FormApi): Promise<object | undefined> => {
        try {
            await customerInfoApiClient.changeUsername(request.firstName, request.lastName)
            onComplete()
            clearFormValues(form)
        } catch (e) {
            return formError('Update name failed')
        }
    }

    return (
        <TypedForm<ChangeUserNameRequest>
            onSubmit={changeName}
            render={({ handleSubmit, submitSucceeded, submitError }: FormRenderProps): JSX.Element => (
                <HtmlForm onSubmit={handleSubmit}>
                    <SuccessAlert message="Name Changed Successfully" show={submitSucceeded} />
                    <ErrorAlert message={submitError} />
                    <TextField<ChangeUserNameRequest>
                        name="firstName"
                        id="myprofile-update-firstname"
                        dataTestId="myprofile-update-firstname"
                        label="First Name"
                        validate={required('First Name is required')}
                    />
                    <TextField<ChangeUserNameRequest>
                        name="lastName"
                        id="myprofile-update-lastname"
                        dataTestId="myprofile-update-lastname"
                        label="Last Name"
                        validate={required('Last Name is required')}
                    />
                    <SubmitContainer>
                        <SubmitButton id="change-username-submit" dataTestId="change-username-submit">
                            SAVE CHANGES
                        </SubmitButton>
                    </SubmitContainer>
                </HtmlForm>
            )}
        />
    )
}

interface Props {
    readonly onComplete: () => void
}
