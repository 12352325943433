import * as React from 'react'

import { UserProfileAccount, UserProfileBillingAccount, UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'
import { TypeaheadSelect } from '@igs-web/common-ui-components/_molecules/typeahead/typeahead-select'
import { getFormattedAddress } from '@igs-web/common-utilities/utilities/address-utilities'
import { getBillingAccountServiceAddresses } from '@igs-web/common-utilities/utilities/billing-account-utilities'

export const BillingAccountSelect = ({ billingAccounts, serviceAddresses, serviceAccounts, selectedBillingAccountKey, onSelect }: Props): JSX.Element => {
    const matchBillingAccount = (billingAccount: UserProfileBillingAccount, inputValue: string) =>
        billingAccount.billingAccountNumber.includes(inputValue) || billingAccount.name.toLowerCase().includes(inputValue)

    const getSelectedBillingAccount = (selectedKey?: string | null): UserProfileBillingAccount | null => {
        if (selectedKey) {
            return billingAccounts.find(ba => ba.billingAccountNumber === selectedKey) || null
        }

        return null
    }

    const itemToString = (billingAccount: UserProfileBillingAccount | null) => {
        if (!billingAccount) {
            return ''
        }
        const billingAccountServiceAddress = getBillingAccountServiceAddresses(billingAccount, serviceAddresses, serviceAccounts)[0]
        const displayString = billingAccountServiceAddress
            ? `${billingAccount.billingAccountNumber} - ${getFormattedAddress(billingAccountServiceAddress)}`
            : ''
        return displayString
    }

    return (
        <TypeaheadSelect<UserProfileBillingAccount>
            items={billingAccounts}
            onSelect={onSelect}
            selectedItem={getSelectedBillingAccount(selectedBillingAccountKey)}
            itemToString={itemToString}
            filter={matchBillingAccount}
            label="Billing Account"
            dataTestId="billing-account-selector"
        />
    )
}
interface Props {
    readonly billingAccounts: ReadonlyArray<UserProfileBillingAccount>
    readonly serviceAddresses: ReadonlyArray<UserProfileServiceAddress>
    readonly serviceAccounts: ReadonlyArray<UserProfileAccount>
    readonly selectedBillingAccountKey?: string | null
    readonly onSelect: (billingAccount?: UserProfileBillingAccount | null) => void
}
