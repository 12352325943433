import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CheckoutStepPageIds } from '@igs-web/common-components/domain/checkout/checkout-step-page-ids'
import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { offersActions, OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { useWizardDispatch } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { getOfferAccountType } from '@igs-web/common-utilities/services/account-service'
import { getOffers } from '@igs-web/common-utilities/services/offer-retrieval'
import { areEqual } from '@igs-web/common-utilities/utilities/comparison-utilities'

import { useSelectOffer } from './use-select-offer'

const CHECKOUT_TIME_LIMIT = 3600000 //-- 1 hour

export const useCheckoutWizardOfferMonitor = () => {
    const dispatch = useDispatch()
    const { setActivePageId } = useWizardDispatch()

    const offerRequestTimestamp = useSelector((store: CommonReduxState) => OfferSelectors.selectOfferRequestTimestamp(store))
    const selectedOffers = useSelector((store: CommonReduxState) => OfferSelectors.selectSelectedOffers(store))
    const selectedZipCode = useSelector((store: CommonReduxState) => OfferSelectors.selectZipCode(store))
    const selectedInvitationCode = useSelector((store: CommonReduxState) => OfferSelectors.selectInvitationCode(store)) || undefined
    const selectedCampaignCode = useSelector((store: CommonReduxState) => OfferSelectors.selectCampaignCode(store))

    const [isSelectedOfferExpired, setIsSelectedOfferExpired] = React.useState(false)
    const { unSelectOffer } = useSelectOffer()

    const checkIsSelectedOfferExpired = async (zipCode?: string): Promise<boolean> => {
        zipCode = zipCode ?? selectedZipCode

        if (offerRequestTimestamp && selectedOffers.length && zipCode && (selectedInvitationCode || selectedCampaignCode)) {
            const currentTimestamp = Date.now()

            const elapsedTime = currentTimestamp - offerRequestTimestamp

            const isCheckoutTimeLimitMet = elapsedTime / CHECKOUT_TIME_LIMIT >= 1

            if (isCheckoutTimeLimitMet) {
                const offerId = selectedOffers[0].offerId
                const priceEffectiveDate = new Date(selectedOffers[0].priceEffectiveDate!)
                const offerAccountType = getOfferAccountType(selectedOffers[0].primaryProduct.accountType)
                const currentOffers = await getOffers(
                    zipCode,
                    offerAccountType,
                    selectedCampaignCode,
                    selectedInvitationCode,
                    offerId.toString(),
                    priceEffectiveDate,
                    true,
                )

                const previousOffer = selectedOffers[0]
                const currentOffer = currentOffers[0]

                if (
                    !currentOffers.length ||
                    !areEqual(previousOffer.primaryProduct, currentOffer.primaryProduct, true) ||
                    !areEqual(previousOffer.secondaryProduct, currentOffer.secondaryProduct, true)
                ) {
                    setActivePageId(CheckoutStepPageIds.CustomerAddress)
                    setIsSelectedOfferExpired(true)
                    dispatch(offersActions.removeCampaignCode())
                    unSelectOffer(previousOffer)
                    return true
                } else {
                    setIsSelectedOfferExpired(false)
                    return false
                }
            }
        }

        setIsSelectedOfferExpired(false)
        return false
    }

    return {
        isSelectedOfferExpired,
        checkIsSelectedOfferExpired,
    }
}
