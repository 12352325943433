import * as React from 'react'
import { MdLocationOn } from 'react-icons/md'

import { CheckoutStepPageIds } from '@igs-web/common-components/domain/checkout/checkout-step-page-ids'
import { Address } from '@igs-web/common-models/models/address'
import { Card } from '@igs-web/common-ui-components/_atoms/card'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { AddressDisplay } from '@igs-web/common-ui-components/_molecules/address/address-display'
import { useWizardDispatch } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'

import { EditHeading } from '../shared/checkout-components'

export const AddressCard = ({ serviceAddress, hideEditButton = false, dataTestId }: Props) => {
    const { moveToSubPage } = useWizardDispatch()

    return (
        <AddressCardDisplay
            serviceAddress={serviceAddress}
            header={
                <EditHeading
                    onClick={() => moveToSubPage(CheckoutStepPageIds.CustomerAddress, '')}
                    hideEdit={hideEditButton}
                    data-testid="address-card-edit-button"
                >
                    <MdLocationOn /> Service Address
                </EditHeading>
            }
            dataTestId={dataTestId}
        />
    )
}

export const AddressCardDisplay = ({ serviceAddress, header, dataTestId }: DisplayProps) => (
    <Card
        header={
            header ? (
                header
            ) : (
                <IconHeading>
                    {' '}
                    <MdLocationOn /> Service Address
                </IconHeading>
            )
        }
        dataTestId={dataTestId}
    >
        <AddressDisplay address={serviceAddress} dataTestIdPrefix="address-card-" />
    </Card>
)

interface Props {
    readonly serviceAddress?: Address
    readonly hideEditButton?: boolean
    readonly dataTestId?: string
}

interface DisplayProps {
    readonly header?: React.ReactNode
    readonly serviceAddress?: Address
    readonly dataTestId?: string
}
