import * as React from 'react'

/*
based on UseHooks implemenation. https://usehooks.com/useKeyPress/
*/

export const useOnKeyPress = (targetKey: string, action: () => void) => {
    const downHandler = (e: KeyboardEvent) => {
        if (e.key === targetKey) {
            action()
        }
    }

    React.useEffect(() => {
        window.addEventListener('keydown', downHandler)
        return () => {
            window.removeEventListener('keydown', downHandler)
        }
    }, []) // Empty array ensures that effect is only run on mount and unmount
}
