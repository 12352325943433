import * as React from 'react'

import styled from 'styled-components'

import { Spacing } from '../styles/spacing'
import { borderBoxColor, borderBoxRadius } from '../styles/theme'

export const CardContainer = styled.article<CardContainerProps>`
    display: grid;
    place-items: center;
    grid-template-rows: ${({ $hasHeader }) => ($hasHeader ? 'min-content' : '')} 1fr ${({ $hasFooter }) => ($hasFooter ? 'min-content' : '')};
    grid-template-areas: ${({ $hasHeader }) => ($hasHeader ? "'header'" : '')} 'content' ${({ $hasFooter }) => ($hasFooter ? "'footer'" : '')};
    width: 100%;
    border: 1px solid ${borderBoxColor};
    border-radius: ${borderBoxRadius};
    height: min-content;
    padding-block-end: ${Spacing.Large};
    > header {
        padding-inline: ${Spacing.Large};
        padding-block: ${Spacing.Large} 0;
        grid-area: header;
        border-bottom: 1px solid ${borderBoxColor};
        width: 100%;
        margin-bottom: ${Spacing.Large};
    }
    > footer {
        grid-area: footer;
        padding-inline: ${Spacing.Large};
        padding-block: ${Spacing.Large};
    }
    > div {
        padding-inline: ${Spacing.Large};
        grid-area: content;
        width: 100%;
    }
`
interface CardContainerProps {
    readonly $hasHeader: boolean
    readonly $hasFooter: boolean
}

export const Card = ({ header, children, footer, className, dataTestId }: Props) => (
    <CardContainer className={className} $hasHeader={!!header} $hasFooter={!!footer} data-testid={dataTestId}>
        {header && <header>{header}</header>}
        <div>{children}</div>
        {footer && <footer>{footer}</footer>}
    </CardContainer>
)

interface Props {
    readonly header?: React.ReactNode
    readonly footer?: React.ReactNode
    readonly children?: React.ReactNode
    readonly className?: string
    readonly dataTestId?: string
}
