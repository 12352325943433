import { SupportedCompanies } from '@igs-web/common-components/domain/company/company'

import { defaultCampaign } from '../constants/constants'

export enum Environment {
    Local = 'local',
    Int = 'int',
    Con1 = 'con1',
    Con2 = 'con2',
    Con3 = 'con3',
    Con4 = 'con4',
    Qa = 'qa',
    Prod = 'prod',
}

export const getEnvironment = (): Environment => {
    const {
        location: { hostname },
    } = window

    if (hostname.includes('int.')) {
        return Environment.Int
    } else if (hostname.includes('con1.')) {
        return Environment.Con1
    } else if (hostname.includes('con2.')) {
        return Environment.Con2
    } else if (hostname.includes('con3.')) {
        return Environment.Con3
    } else if (hostname.includes('con4.')) {
        return Environment.Con4
    } else if (hostname.includes('qa.')) {
        return Environment.Qa
    } else if (
        hostname === 'www.igs.com' ||
        hostname === 'igs.com' ||
        hostname === 'www.scanaenergy.com' ||
        hostname === 'scanaenergy.com' ||
        hostname === 'account.scanaenergy.com' ||
        hostname === 'www.scanaenergyregulated.com' ||
        hostname === 'scanaenergyregulated.com' ||
        hostname === 'account.scanaenergyregulated.com' ||
        hostname === 'webpledge.scanaenergy.com'
    ) {
        return Environment.Prod
    }
    return Environment.Local
}

const isIgs = (company: string) => company.toLowerCase() === SupportedCompanies.Igs.toLowerCase()
const isScanaRegulated = (company: string) => company.toLowerCase() === SupportedCompanies.ScanaRegulated.toLowerCase()
const isScana = (company: string) => company.toLowerCase() === SupportedCompanies.Scana.toLowerCase()

export const getCacheBusterValue = (): string => {
    const date = new Date()
    const middleOfMonth = 15
    return date.getMonth() + (date.getDate() > middleOfMonth ? '-first' : '-second')
}

export const getSitefinityApiUrl = (env: Environment, company: string): string => {
    return isIgs(company) ? '' : env === Environment.Prod ? 'https://www.igs.com/' : `https://${env}.igs.com`
}

export const getApiUrl = (env: Environment, company: string): string => {
    if (isScanaRegulated(company)) {
        return env === Environment.Prod ? 'https://website-api.scanaenergyregulated.com' : `https://website-api-scana-regulated.${env}.igs.com`
    }

    if (isScana(company)) {
        return env === Environment.Prod ? 'https://website-api.scanaenergy.com' : `https://website-api-scana.${env}.igs.com`
    }

    return `https://website-api.${env}.igs.com`
}

export const getCdnUrl = (env: Environment): string => {
    return env === Environment.Local ? '/' : `https://cdn.igs.com/${env}-public-assets/scana/`
}

export const getDefaultCampaign = (company: string): string => {
    return isIgs(company) ? defaultCampaign : 'web-new'
}
