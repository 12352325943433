import * as React from 'react'

import styled from 'styled-components'

import { CoverageDetailItem } from '@igs-web/common-models/models/account-detail-model'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeSmall } from '@igs-web/common-ui-components/styles/theme'

import FilteredCoverages from './coverage-filtered-coverages'
import { CoverageTable } from './coverage-table'

const CoverageChartContainer = styled.div`
    text-align: center;
    position: relative;
`
const CoverageChartFootnote = styled.div`
    font-size: ${fontSizeSmall};
 text-align: left;
    margin: ${Spacing.Small};
    > div {
        margin-bottom: ${Spacing.Small};
    }
`

export const CoverageSummary = ({ coverages }: CoverageSummaryProps): JSX.Element => {
    const filteredCoverages = FilteredCoverages(coverages)

    return (
        <CoverageChartContainer>
            <CoverageTable coverages={filteredCoverages} />

            <CoverageChartFootnote>
                <div>Please note that protection limits reset based on your last dated service request. See your Service Agreement for specific details.</div>
                {coverages
                    .map(
                        c =>
                            c.footnote && (
                                <div key={c.footnoteSymbol} id={`${c.coverageCode}-${c.footnoteSymbol}`}>
                                    {c.footnoteSymbol}. {c.footnote}
                                </div>
                            ),
                    )
                    .reverse()}
            </CoverageChartFootnote>
        </CoverageChartContainer>
    )
}

interface CoverageSummaryProps {
    readonly coverages: ReadonlyArray<CoverageDetailItem>
}
