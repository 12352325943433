import * as React from 'react'

import styled from 'styled-components'

import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { lightLight, secondaryDark, smWidth } from '@igs-web/common-ui-components/styles/theme'

const AlertCardContainer = styled.div`
    color: ${lightLight};
    background-color: ${secondaryDark};
`

export const AlertBody = styled.div`
    font-weight: ${FontWeight.Bold};
    font-size: 1.5rem;
    display: flex;
    justify-content: flex-start;
    padding: ${Spacing.Medium};
    align-items: center;
    @media (max-width: ${smWidth}) {
        text-align: center;
        flex-direction: column;
    }
    & > span {
        padding-right: ${Spacing.Large};
    }
`

export const AlertCard = ({ children, className, dataTestId }: AlertCardProps): JSX.Element => (
    <AlertCardContainer className={className} data-testid={dataTestId}>
        <div>
            <AlertBody>{children}</AlertBody>
        </div>
    </AlertCardContainer>
)

export const AlertCardWithLink = styled(AlertCard)`
    .link {
        color: ${lightLight};
    }
`

type HTMLDivElementProps = React.HTMLAttributes<HTMLDivElement>

interface AlertCardProps extends HTMLDivElementProps {
    readonly className?: string
    readonly dataTestId?: string
}
