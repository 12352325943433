import * as React from 'react'

export const EncodedHtml = ({ content, wrapper = 'span', ...rest }: Props): JSX.Element => {
    const props: object = {
        dangerouslySetInnerHTML: {
            __html: content,
        },
        ...rest,
    }
    return React.createElement(wrapper, props)
}

interface Props {
    readonly content: string
    readonly wrapper?: string | React.FunctionComponent
}
