import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'

import { offersActions, OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { useOffersContextDispatch } from '@igs-web/common-components/domain/offers/context/offers-context'
import { LineOfBusinessCode } from '@igs-web/common-models/models'
import { fetchIpAddress } from '@igs-web/common-utilities/api/ip-address-client'

import { useOffersHandler } from '../hooks/use-offers-handler'
import { useOffersQueryStringHandler } from '../hooks/use-offers-query-string-handler'

export const HWOfferLoader = () => {
    const { getHwOffersQueryStringParameters } = useOffersQueryStringHandler()
    const offersHandler = useOffersHandler()
    const { clearOfferState } = useOffersContextDispatch()
    const selectedOffers = useSelector(OfferSelectors.selectSelectedOffers)
   
    const dispatch = useDispatch()
   
    React.useEffect(() => {
        if (selectedOffers.some(o => o.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.Gas)) {
            dispatch(offersActions.unselectOffer(selectedOffers.find(o => o.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.Gas)))
        }
    }, [selectedOffers])

    useEffectOnce(() => {
        clearOfferState()
        const urlParameters = getHwOffersQueryStringParameters()
        offersHandler.selectHwOfferIfAvailable(urlParameters.offerId, urlParameters.campaignCode, urlParameters.zipCode, false)
        fetchIpAddress()
    })

    return null
}