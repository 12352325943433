import { PhoneType } from '@igs-web/common-models/constants/phone'
import { ContactModel } from '@igs-web/common-models/models/contact-model'
import { EnrollmentModel, ShoppingCartItem } from '@igs-web/common-models/models/enrollment-model'
import { EnrollmentTypeCode } from '@igs-web/common-models/models/enrollment-type-code'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'

export interface CheckoutStepProps {
    readonly stepFwdButtonText: string
}
export const defaultContactModel: ContactModel = {
    firstName: '',
    middleInitial: '',
    lastName: '',
    commercialName: '',
    phoneNumber: '',
    emailAddress: '',
    isMobilePhone: false,
    isMobilePhoneMarketable: true,
    isEmailMarketable: false,
    phoneType: PhoneType.Cell,
}
export const defaultShoppingCartModel = (lob: LineOfBusinessCode): ShoppingCartItem => {
    return {
        shoppingCartItemKey: '',
        lineOfBusinessCode: lob,
        contact: defaultContactModel,
        useDefaultContact: true,
    }
}

export const initialEnrollmentModel: EnrollmentModel = {
    shoppingCartItems: [],
    defaultContact: defaultContactModel,
    isBillingSameAsServiceAddress: true,
    isShippingSameAsServiceAddress: true,
    productDetails: {
        GAS: defaultShoppingCartModel(LineOfBusinessCode.Gas),
        ELECTRIC: defaultShoppingCartModel(LineOfBusinessCode.Electric),
        HOMEWARRANTY: defaultShoppingCartModel(LineOfBusinessCode.HomeWarranty),
        COMMERCIAL: defaultShoppingCartModel(LineOfBusinessCode.Commercial),
        COMMUNITYSOLAR: defaultShoppingCartModel(LineOfBusinessCode.CommunitySolar),
    },
    forceMoving: false,
    hasCentralAc: true,
    isPaperless: true,
    hasElectronicCommunicationConsent: true,
    enrollmentTypeCode: EnrollmentTypeCode.Standard,
    electronicAgreementNumber: '',
}
