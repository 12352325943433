import * as React from 'react'
import { useController } from 'react-hook-form'

import { InputLabel, OutlinedInput, OutlinedInputProps } from '@mui/material'

import { InputError, StyledControl } from '../web-framework-inputs'

export const TextInput = ({ name, label, control, type = 'text', dataTestId, rules = {} }: Props) => {
    const {
        field,
        fieldState: { error },
    } = useController({ name, control, rules })

    return (
        <StyledControl variant="outlined" error={!!error}>
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <OutlinedInput {...field} inputProps={{ 'data-testid': dataTestId }} type={type} error={!!error} label={label} />
            {!!error && <InputError>{error.message}</InputError>}
        </StyledControl>
    )
}

interface Props extends OutlinedInputProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly name: string
    readonly control: any
    readonly dataTestId: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly rules?: any
}
