import { Alert } from '@igs-web/common-models/models/alert'
import { apiClient, RequestConfig } from './api-client'

class AlertApiClient {
    public readonly getAlerts = async (config?: RequestConfig) => {
        const alerts = await apiClient.get<ReadonlyArray<Alert>>(`${apiClient.apiBaseUrl}/alerts`, config)
        return alerts
    }

    public readonly addAlertInteraction = async (alertKey: string, interactedWith: boolean, config?: RequestConfig) =>
        await apiClient.post<never>(`${apiClient.apiBaseUrl}/alerts/${alertKey}/interaction`, { interactedWith }, config)
}

export const alertApiClient = new AlertApiClient()
