import * as React from 'react'

import noop from 'lodash-es/noop'
import styled from 'styled-components'

import { lightLight, panelBackground } from '@igs-web/common-ui-components/styles/theme'

import styles from 'root/../styles/abstracts/_variables.scss'

const HoverItem = styled.div`
    height: 100%;
    display: grid;
    align-items: center;

    @media (min-width: ${styles.lgWidth}) {
        position: relative;
        justify-content: flex-end;
    }

    &.panel-dark {
        --panel-background-color: ${panelBackground};
        --panel-text-color: ${lightLight};
    }
`

export const NavbarItem = ({ children, className, dataTestId, hover = noop }: Props) => (
    <HoverItem className={className} data-testid={dataTestId} onMouseEnter={() => hover(true)} onMouseLeave={() => hover(false)}>
        {children}
    </HoverItem>
)

interface Props {
    readonly children: React.ReactNode
    readonly className?: string
    readonly dataTestId?: string
    readonly hover?: (hovering: boolean) => void
}
