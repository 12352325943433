import * as Routes from 'domain/routes'
import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { commonGridCss } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'

const AssistanceGrid = styled.div`
    ${commonGridCss}
    grid-template-columns: 1fr 1fr;
    p:first-of-type {
        margin-top: 0;
    }
`

export const AssistanceFooter = () => {
    const { phoneNumber } = useCompany()
    return (
        <footer>
            <IconHeading>Assistance</IconHeading>
            <AssistanceGrid>
                <div>
                    <StrongText>Already a Customer?</StrongText>
                    <p>
                        <Link to={Routes.LoginRoute}>Log in</Link> to change rate plans.
                    </p>
                    <p>
                        Call <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} /> to transfer or add service.
                    </p>
                </div>
                <div>
                    <StrongText>Looking for Business Service?</StrongText>
                    <p>
                        For more information or to sign up, please call <PhoneNumberLink phoneNumber={phoneNumber.commecialSupport} />.
                    </p>
                </div>
            </AssistanceGrid>
        </footer>
    )
}
