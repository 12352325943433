import { Municipality } from '@igs-web/common-models/models/municipality'
import { apiClient } from '@igs-web/common-utilities/api/api-client'
import { cache } from '@igs-web/common-utilities/api/cache'

class GeocodeApiClient {
    public readonly getCityState = cache(
        'getCityState',
        async (zipCode: string): Promise<Municipality | null> => await apiClient.get<Municipality>(`${apiClient.apiBaseUrl}/geo/CityState/${zipCode}`),
    )
    private readonly googleMapsKey = 'AIzaSyDeRnei872CYCnky71Z-MrX7rCPg-89Huw'

    public readonly getMapUrl = (zipCode: string) => {
        return (
            'https://maps.googleapis.com/maps/api/staticmap?center=' +
            zipCode +
            ',US&zoom=11&size=720x520&maptype=roadmap&key=' +
            this.googleMapsKey +
            '&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C' +
            zipCode +
            ',US'
        )
    }
}

export const geocodeApiClient = new GeocodeApiClient()
