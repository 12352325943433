import * as React from 'react'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { NavigationBanner } from 'molecules/header/navigation-banner'
import { ScrollToTop } from 'molecules/routing/scroll-to-top'

const ThreeColumnPageLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'transferService' 'gasLineService' 'otherService';
    grid-column-gap: ${Spacing.Medium};
    & > * {
        display: grid;
        grid-template-rows: 1fr 2fr;
    }
    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-columns: 1fr;
        max-width: ${Breakpoint.MobileSmall};
        & > * {
            grid-template-rows: 1fr;
        }
    }
`
const TransferService = styled.div`
    grid-area: 'transferService';
`
const GasLineService = styled.div`
    grid-area: 'gasLineService';
`
const OtherService = styled.div`
    grid-area: 'otherService';
`

export const GasServiceRequestPage = () => {
    const { phoneNumber, businessHours } = useCompany()

    return (
        <React.Fragment>
            <ScrollToTop />
            <NavigationBanner title="Service Requests" />
            <PageContentContainer>
                <ThreeColumnPageLayout>
                    <TransferService>
                        <Header3>Transfer or Add Service</Header3>
                        <p>
                            If you need to transfer service from a new address or if you have an additional property that needs service, please call us at{' '}
                            <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />.
                        </p>
                    </TransferService>
                    <GasLineService>
                        <Header3>Gas Line Service</Header3>
                        <p>
                            If you have an issue with your gas pipeline or in case of a gas leak or emergency, please contact Atlanta Gas Light (AGL) at{' '}
                            <PhoneNumberLink phoneNumber={phoneNumber.gasLeak} />.
                        </p>
                    </GasLineService>
                    <OtherService>
                        <Header3>Other</Header3>
                        <p>
                            For any other service needs, contact our Customer Care Center at <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />. Our
                            representatives are available {businessHours.businessHoursFull}.
                        </p>
                    </OtherService>
                </ThreeColumnPageLayout>
            </PageContentContainer>
        </React.Fragment>
    )
}
