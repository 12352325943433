import * as React from 'react'
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import { IoMdCalendar } from 'react-icons/io'

import styled from 'styled-components'

import { AlertCard } from '@igs-web/common-components/domain/alerts/alert-card'
import { CheckoutStepPageIds } from '@igs-web/common-components/domain/checkout/checkout-step-page-ids'
import { EnrollmentModel } from '@igs-web/common-models/models/enrollment-model'
import { EnrollmentTypeCode } from '@igs-web/common-models/models/enrollment-type-code'
import { Card } from '@igs-web/common-ui-components/_atoms/card'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { useWizardDispatch, useWizardState } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { warning } from '@igs-web/common-ui-components/styles/theme'
import { CreditCheckStatus } from '@igs-web/common-utilities/api/enrollment-api-client'
import { formatDate } from '@igs-web/common-utilities/utilities/date-utilities'

import { EditHeading } from '../shared/checkout-components'
import { esitmatedMarketSwitchDate } from '../shared/market-switch-date-service'

const ExclamationPointIcon = styled(BsFillExclamationCircleFill)`
    background-color: ${warning};
`
const AlertCardTopMargin = styled(AlertCard)`
    margin-block-start: ${Spacing.Medium};
`

export const StartDateCard = ({ enrollment }: Props) => {
    const { moveToSubPage } = useWizardDispatch()
    const { activePageId } = useWizardState()
    return (
        <StartDateCardDisplay
            enrollment={enrollment}
            header={
                <EditHeading
                    onClick={() => moveToSubPage(CheckoutStepPageIds.RequestedStartDate, '')}
                    hideEdit={
                        activePageId === CheckoutStepPageIds.RequestedStartDate ||
                        activePageId === CheckoutStepPageIds.CustomerAddress ||
                        activePageId === CheckoutStepPageIds.SelectRate
                    }
                >
                    <IoMdCalendar /> Start Date
                </EditHeading>
            }
        />
    )
}

export const StartDateCardDisplay = ({ enrollment, header }: DisplayProps) => {
    const { enrollmentTypeCode, requestedStartDate } = enrollment
    const estimatedStartDate = enrollmentTypeCode === EnrollmentTypeCode.SwitchService ? esitmatedMarketSwitchDate() : requestedStartDate

    return (
        <Card
            header={
                header ? (
                    header
                ) : (
                    <IconHeading>
                        <IoMdCalendar /> Start Date
                    </IconHeading>
                )
            }
        >
            {enrollmentTypeCode === EnrollmentTypeCode.NewService && (
                <>
                    <div data-testid="start-date-card-type">Type: Turn On</div>
                    <a data-testid="start-date-card-connection-fee">
                        <StrongText as="div">A connection fee up to $95 may apply</StrongText>
                    </a>
                    <div data-testid="start-date-card-date">Requested Start Date: {formatDate(estimatedStartDate)}</div>
                </>
            )}
            {enrollmentTypeCode === EnrollmentTypeCode.SwitchService && (
                <>
                    <div>Type: Switch to SCANA</div>
                    <div>Estimated Start Date: {formatDate(estimatedStartDate)}</div>
                </>
            )}
            {enrollment.creditCheckResults?.energyScoreStatus === CreditCheckStatus.Collect && (
                <AlertCardTopMargin>
                    <span>
                        <ExclamationPointIcon />
                    </span>
                    Deposit Payment Required
                </AlertCardTopMargin>
            )}
        </Card>
    )
}
interface Props {
    readonly enrollment: EnrollmentModel
}
interface DisplayProps extends Props {
    readonly header?: React.ReactNode
}
