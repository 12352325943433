import * as React from 'react'

import { UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'

import { HwMyaccountContextReducer, HwMyaccountContextState, hwMyaccountActions, initialHwMyaccountContextState } from './hw-myaccount-reducer'

interface HwMyaccountContextDispatch {
    readonly setSelectedAddressHash: (address: UserProfileServiceAddress) => void
}

const HwMyaccountStateContext = React.createContext<HwMyaccountContextState | undefined>(undefined)
const HwMyaccountDispatchContext = React.createContext<HwMyaccountContextDispatch | undefined>(undefined)

export const useHwMyaccountState = (): HwMyaccountContextState => {
    const context = React.useContext(HwMyaccountStateContext)
    if (!context) {
        throw Error('Must use HwMyaccountStateContext inside of HwMyaccountContextProvider')
    }

    return context
}

export const useHwMyaccountDispatch = (): HwMyaccountContextDispatch => {
    const context = React.useContext(HwMyaccountDispatchContext)
    if (!context) {
        throw Error('Must use HwMyaccountDispatchContext inside of HwMyaccountContextProvider')
    }

    return context
}

export const HwMyaccountContextProvider = ({ children, hwMyaccountState, storageKey }: HwMyaccountContextProviderProps) => {
    const initalState: HwMyaccountContextState = {
        selectedAddressHash: hwMyaccountState ? hwMyaccountState.selectedAddressHash : initialHwMyaccountContextState.selectedAddressHash,
        storageKey,
    }
    const [state, dispatch] = React.useReducer(HwMyaccountContextReducer, initalState)

    const dispatchValue = {
        setSelectedAddressHash: (address: UserProfileServiceAddress) => dispatch(hwMyaccountActions.setSelectedAddressHash(address.addressHash)),
    }

    return (
        <HwMyaccountStateContext.Provider value={state}>
            <HwMyaccountDispatchContext.Provider value={dispatchValue}>{children}</HwMyaccountDispatchContext.Provider>
        </HwMyaccountStateContext.Provider>
    )
}

interface HwMyaccountContextProviderProps {
    readonly children: React.ReactNode
    readonly hwMyaccountState?: HwMyaccountContextState
    readonly storageKey?: string
}
