import * as React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import { PageChangeLoader } from '@igs-web/common-ui-components/_atoms/page-change-loader'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText, InfoTextType } from '@igs-web/common-ui-components/_molecules/info-text'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { getUrlParameter } from 'root/shared-services/query-string-utilities'

import { NavigationBanner } from 'molecules/header/navigation-banner'

import * as Routes from 'domain/routes'

import { LoginPanel } from './login-panel'
import { RegisterAccountPanel } from './register-account-panel'
import { RequestPasswordPanel } from './request-password-panel'
import { ResetPasswordPanel } from './reset-password-panel'

export const LoginPage = ({ location }: Props): JSX.Element => {
    const returnUrl = getUrlParameter('returnUrl')
    const passwordResetKey = getUrlParameter('key')
    const resetSuccess = getUrlParameter('resetSuccess')
    const email = getUrlParameter('email')
    const isResetting = !!passwordResetKey

    window.scrollTo(0, 0)
    return (
        <>
            <Switch>
                <Route path={[Routes.ForgotPassword, Routes.SignInHelp]}>
                    <NavigationBanner title="Sign In Help" />
                </Route>
                <Route path={Routes.CreateAccount}>
                    <NavigationBanner title="Register My Account" />
                </Route>
                <Route path={Routes.Registration}>
                    <NavigationBanner title="Account Registration" />
                </Route>
                <Route>
                    <NavigationBanner title={isResetting ? 'Reset Password' : 'Sign In'} />
                </Route>
            </Switch>

            <PageChangeLoader />
            {resetSuccess && (
                <>
                    <InfoText $infoTextType={InfoTextType.Success}>Password Reset Successfully</InfoText>
                    <GridSpacer spacing={Spacing.Medium} />
                </>
            )}
            <PageContentContainer>
                <GridSpacer spacing={Spacing.Large} />
                <Switch>
                    <Route path={[Routes.ForgotPassword, Routes.SignInHelp]}>
                        <RequestPasswordPanel />
                    </Route>
                    <Route path={Routes.CreateAccount}>
                        <RegisterAccountPanel />
                    </Route>
                    <Route path={Routes.Registration}>
                        <ResetPasswordPanel passwordResetKey={passwordResetKey} oneTimeRegistration={true} />
                    </Route>
                    <Route>
                        {isResetting ? (
                            <ResetPasswordPanel passwordResetKey={passwordResetKey} />
                        ) : (
                            <LoginPanel to={location.state ? (location.state as string) : returnUrl} email={email} />
                        )}
                    </Route>
                </Switch>
            </PageContentContainer>
        </>
    )
}

interface Props extends RouteComponentProps {
    readonly isCreateAccount?: boolean
}
