import { FieldValidator } from 'final-form'
import _get from 'lodash-es/get'

import { FieldType } from '@igs-web/common-models/models/field-type'

import { zipCodeRegex } from './address-utilities'
import { formatDate } from './date-utilities'
import { isPhoneNumber } from './phone-utilities'

// type FieldValue = string | boolean | number
type ErrorReturn = string | undefined
export type Validator = (value: FieldType, allValues: object) => ErrorReturn
type ValidatorCandidate = Validator | FieldValidator<any> | undefined

// Per https://emailregex.com/
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i

export const socialSecurityRegex = /^\d{3}-\d{2}-(?!0{4})\d{4}$/

export const positiveIntegerRegex = /^[0-9]*$/

export const composeValidators = (...validators: ReadonlyArray<ValidatorCandidate>) => (value: FieldType, allValues: object): any =>
    validators
        .filter(validator => !!validator)
        .map(validator => validator as Validator)
        .reduce((error, validator) => error || validator(value, allValues), undefined)

export const orValidators = (...validators: ReadonlyArray<ValidatorCandidate>) => (value: FieldType, allValues): ErrorReturn => {
    const anyPassed = validators
        .filter(validator => !!validator)
        .map(validator => validator as Validator)
        .some(validator => !validator(value, allValues))

    return anyPassed ? undefined : composeValidators.apply({}, validators)(value, allValues)
}

export const required = (errorMessage?: string) => (value: string | number) => (value || value === 0 ? undefined : errorMessage || 'Required')

export const requiredIf = (condition: boolean, errorMessage?: string) => (value: string | number) => (!condition ? undefined : required(errorMessage)(value))

export const requiredByOtherValues = <T extends object>(otherValues: Partial<T>, errorMessage?: string) => (value: string | number, allValues: T) =>
    Object.keys(otherValues).every(key => otherValues[key] !== allValues[key]) ? undefined : required(errorMessage)(value)

export const oneOfRequired = (fieldPath: string, errorMessage?: string) => (value: string, allValues: { readonly [key: string]: string }) => {
    const otherValue = _get(allValues, fieldPath)
    return !!value || !!otherValue ? undefined : required(errorMessage)(value)
}

export const mustMatch = (field: string, errorMessage?: string) => (value: string, allValues: { readonly [key: string]: string }) =>
    !value || value === _get(allValues, field) ? undefined : errorMessage || 'Fields must match'

export const pattern = (regex: RegExp, errorMessage?: string) => (value: string) =>
    !value || regex.test(value) ? undefined : errorMessage || 'Input not in the correct format'

export const validEmail = (errorMessage?: string) => pattern(emailRegex, errorMessage || 'Invalid email address')

export const validZipCode = (errorMessage?: string) => pattern(zipCodeRegex, errorMessage || 'Invalid zip number')

export const validPhoneNumber = (errorMessage?: string) => (value: string) =>
    !value || isPhoneNumber(value) ? undefined : errorMessage || 'Invalid phone number'

export const validSocialSecurityNumber = (errorMessage?: string) => pattern(socialSecurityRegex, errorMessage || 'Invalid social security number')

export const isIn = (expectedValues: ReadonlyArray<string | boolean>, errorMessage?: string) => (value: string | boolean) =>
    !value || expectedValues.includes(value) ? undefined : errorMessage || 'Invalid input'

export const greaterThan = (expectedNumber: number, errorMessage?: string) => (value: number) =>
    !value || !expectedNumber || +value > +expectedNumber ? undefined : errorMessage || `Number must be greater than ${expectedNumber}`

export const lessThan = (expectedNumber: number, errorMessage?: string) => (value: number) =>
    !value || !expectedNumber || +value < +expectedNumber ? undefined : errorMessage || `Number must be less than than ${expectedNumber}`

export const dateBefore = (date?: number, errorMessage?: string) => (value: number) =>
    !value || !date || +value < +date ? undefined : errorMessage || `Date must be before ${formatDate(date)}`

export const dateAfter = (date?: number, errorMessage?: string) => (value: number) =>
    !value || !date || +value > +date ? undefined : errorMessage || `Date must be after ${formatDate(date)}`

export const dateBetween = (fromDate?: number, toDate?: number, errorMessage?: string) =>
    composeValidators(dateBefore(toDate, errorMessage), dateAfter(fromDate, errorMessage))

export const minLength = (min: number) => (value: string): ErrorReturn => (value && value.length < min ? `Below ${min} character limit` : undefined)

export const maxLength = (max: number) => (value: string): ErrorReturn => (value && value.length > max ? `Over ${max} character limit` : undefined)

export const exactLength = (length: number) => (value: string): ErrorReturn => (value && value.length !== length ? `Must be ${length} characters` : undefined)

const passwordLengthMin = 5
const passwordLengthMax = 400
export const passwordLength = composeValidators(minLength(passwordLengthMin), maxLength(passwordLengthMax))

export const validDollarAmount = (errorMessage = 'Not a valid dollar amount') => pattern(/^\d*(\.\d{2})?$/, errorMessage)
