import { Alert } from '@igs-web/common-models/models/alert'
import { alertApiClient } from '@igs-web/common-utilities/api/alert-api-client'
import { createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'
import { getFromStorage, putInStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

export const alertActionTypes = {
    SET_ALERTS: '[alert] SET_ALERTS',
    ADD_INTERACTION: '[alert] ADD_INTERACTION',
}

const alertsStorageKey = 'filtered-alerts'

interface AlertInteraction {
    readonly alertKey: string
    readonly interactedWith: boolean
}

export const alertActions = {
    setAlerts: createAction<ReadonlyArray<Alert>>(alertActionTypes.SET_ALERTS),
    addInteraction: createAction<AlertInteraction>(alertActionTypes.ADD_INTERACTION),
}

export const alertReducer = reducer<ReadonlyArray<Alert>>([])
    .add<ReadonlyArray<Alert>>(alertActionTypes.SET_ALERTS, (state, alerts) => {
        const filteredAlertKeys = getFromStorage<ReadonlyArray<string>>(alertsStorageKey) || []
        return alerts.filter(x => !filteredAlertKeys.includes(x.alertKey))
    })
    .add<AlertInteraction>(alertActionTypes.ADD_INTERACTION, (state, alertInteraction) => {
        const { alertKey, interactedWith } = alertInteraction

        alertApiClient.addAlertInteraction(alertKey, interactedWith, { showGlobalLoader: false })

        const filteredAlertKeys = getFromStorage<ReadonlyArray<string>>(alertsStorageKey) || []
        putInStorage<ReadonlyArray<string>>([...filteredAlertKeys, alertKey], alertsStorageKey)
        return state.filter(x => !(filteredAlertKeys.includes(x.alertKey) || x.alertKey === alertKey))
    })
    .build()
