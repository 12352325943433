import { LineOfBusinessCode } from './line-of-business'

export interface UtilityLineOfBusinessModel {
    readonly lineOfBusinessCode: LineOfBusinessCode
    readonly utilityLineOfBusinessCode: string
    readonly utility: string
    readonly utilityCode: string
    readonly stateCode: string
    readonly accountNumberPrefix: string
    readonly accountNumberLabel: string
    readonly accountNumberHelpUrl: string
    readonly accountNumberFormat: string
    readonly accountNumberError: string
    readonly accountNumberRegex: string
    readonly meterNumberLabel: string | null | undefined
    readonly meterRegex: string | null | undefined
    readonly initialDepositAmount: number
    readonly meterErrorDisplay: string
    readonly currentPtc: PtcDetailModel
}

export interface PtcDetailModel {
    readonly effectiveMonth: Date
    readonly expirationDate: Date
    readonly ptc?: number
    readonly unitOfMeasureCode: string
}

const emptyPtcDetailModel: PtcDetailModel = {
    effectiveMonth: new Date(),
    expirationDate: new Date(),
    unitOfMeasureCode: '',
}

export const emptyUtilityLineOfBusinessModel: UtilityLineOfBusinessModel = {
    lineOfBusinessCode: LineOfBusinessCode.Commercial,
    utilityLineOfBusinessCode: '',
    utility: '',
    utilityCode: '',
    stateCode: '',
    accountNumberPrefix: '',
    accountNumberLabel: '',
    accountNumberHelpUrl: '',
    accountNumberFormat: '',
    accountNumberError: '',
    accountNumberRegex: '',
    initialDepositAmount: Number.MIN_VALUE,
    meterErrorDisplay: '',
    meterNumberLabel: undefined,
    meterRegex: undefined,
    currentPtc: emptyPtcDetailModel,
}
