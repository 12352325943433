import * as React from 'react'

import { EventCodes } from '@igs-web/common-utilities/constants/constants'
import { GlobalEventer, IgsEventHandler } from '@igs-web/common-utilities/utilities/eventer-utilities'

const FoldingCube = (): JSX.Element => (
    <div className="sk-folding-cube-wrapper">
        <div className="sk-folding-cube">
            <div className="sk-cube1 sk-cube" />
            <div className="sk-cube2 sk-cube" />
            <div className="sk-cube4 sk-cube" />
            <div className="sk-cube3 sk-cube" />
        </div>
    </div>
)

const LoadingIndicator = (): JSX.Element => (
    <div className="loading-indicator">
        <FoldingCube />
        <p>Loading...</p>
    </div>
)

export const GlobalLoader = ({ isLoading }: LoaderProps): JSX.Element => (
    <div className={`loading-container ${isLoading ? 'loading' : ''}`} data-testid="loading-container-global">
        <span className="loading-cover" />
        <LoadingIndicator />
    </div>
)

export const LocalLoader = ({ isLoading }: LoaderProps): JSX.Element => (
    <div className={`loading-container local ${isLoading ? 'loading' : ''}`} data-testid="loading-container-local">
        <LoadingIndicator />
    </div>
)

interface LoaderProps {
    readonly isLoading: boolean
}

export class StatefulLoader extends React.Component<{}, { readonly isLoading: boolean }> {
    // eslint-disable-next-line functional/prefer-readonly-type
    private loadingStart!: IgsEventHandler
    // eslint-disable-next-line functional/prefer-readonly-type
    private loadingEnd!: IgsEventHandler

    constructor(props: {} | Readonly<{}>) {
        super(props)
        this.state = { isLoading: false }
    }

    public componentDidMount(): void {
        this.loadingStart = GlobalEventer.subscribe(EventCodes.loadingStart, () => this.setState({ isLoading: true }))
        this.loadingEnd = GlobalEventer.subscribe(EventCodes.loadingEnd, () => this.setState({ isLoading: false }))
    }

    public componentWillUnmount(): void {
        this.loadingStart.unsubscribe()
        this.loadingEnd.unsubscribe()
    }

    public render(): JSX.Element {
        const {
            state: { isLoading },
        } = this
        return <GlobalLoader isLoading={isLoading} />
    }
}
