import * as React from 'react'

import { getDuration } from '@igs-web/common-utilities/utilities/date-utilities'

export const TermRemainingText = ({ termEndDate }: TermsProps) => {
    if (!termEndDate) {
        return <></>
    }

    const { years, months, days } = getDuration(termEndDate)
    return (
        <>
            <span data-testid="years-remaining">{getYearWords(years)}</span> <span data-testid="months-remaining">{getMonthWords(months)}</span>{' '}
            <span data-testid="days-remaining">{getDayWords(days)}</span> remaining in your contract.
        </>
    )
}

const getDayWords = (days?: number): string => {
    if (!days || days === 0) {
        return '0 days'
    }

    if (days === 1) {
        return `${days} day`
    }

    return `${days} days`
}

const getMonthWords = (months?: number): string => {
    if (!months || months === 0) {
        return ''
    }

    if (months === 1) {
        return `${months} month, and`
    }

    return `${months} months, and`
}

const getYearWords = (years?: number): string => {
    if (!years || years === 0) {
        return ''
    }

    if (years === 1) {
        return `${years} year,`
    }

    return `${years} years,`
}

interface TermsProps {
    readonly termEndDate: number | undefined
}
