import { cache } from 'root/shared-services/api-clients/cache'

import { Address } from '@igs-web/common-models/models/address'
import { AddressVerifyResponse } from '@igs-web/common-models/models/address-verify-response'
import { apiClient } from '@igs-web/common-utilities/api/api-client'

class AddressApiClient {
    public readonly verifyAddress = cache('addressVerify', async (request: Address) => {
        return await apiClient.post<AddressVerifyResponse>(`${apiClient.apiBaseUrl}/Address/Verify`, request)
    })

    public readonly isAddressServiced = cache('isAddressServices', async (request: Address) => {
        return await apiClient.post<boolean>(`${apiClient.apiBaseUrl}/HomeWarranty/IsAddressServiced`, request)
    })
}

export const addressApiClient = new AddressApiClient()
