import * as React from 'react'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { ProductModel } from '@igs-web/common-models/models/offer-model'

export const GetCustomTerms = ({ offer }: GetFeesAndOtherInfoListProps) => {
    const { routes } = useCompany()
    return (
        <>
            {offer.customTerms && (
                <ul>
                    {offer.customTerms.map((t, index) => (
                        <li key={offer.productCode + index}>{t}</li>
                    ))}
                    <li>Your early termination fee has been waived.</li>
                    <li>
                        <a href={routes.product.termsAndConditions(undefined, offer.accountType)}>Terms and conditions apply</a>
                    </li>
                </ul>
            )}
        </>
    )
}

interface GetFeesAndOtherInfoListProps {
    readonly offer: ProductModel
}
