import { FormControl } from '@mui/material'
import styled, { css } from 'styled-components'

import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import {
    Checkbox as WfCheckbox,
    Input as WfInput,
    Radio as WfRadio,
    Select as WfSelect,
    SelectOption as WfSelectOption,
    Textarea as WfTextarea,
} from '@igs/react-styled-components'

import { FontWeight } from '../styles/font-weight'
import { darkLight, error as errorColor, fontFamilyBody, fontSizeSmall } from '../styles/theme'

const LABEL_FONT_SIZE = '1.1rem'
const INPUT_FONT_SIZE = '1.3rem'
const WF_ERROR_COLOR = errorColor
const WF_ERROR_FONT_SIZE = '0.8rem'
const WF_INPUT_COLOR = '#334652'
const WF_LEGEND_FONT_SIZE = '0.9rem'

const InputStyles = css`
    input,
    select {
        padding: 7px 12px 8px 12px;
        margin-top: 12px;
    }
    label {
        top: calc(8px + var(--label-vertical-offset, 0px));
        color: ${darkLight};
        margin-bottom: 0.5rem;
    }
`

export const Input = styled(WfInput)`
    height: auto;
    align-self: flex-start;
    margin-bottom: ${({ hideHelperText }) => (!hideHelperText ? `-${Spacing.Medium}` : 0)};
    font-size: ${LABEL_FONT_SIZE};
    ${InputStyles}

    input {
        font-size: ${INPUT_FONT_SIZE};
        &[readonly] {
            opacity: 0.7;
            cursor: default;
            pointer-events: none;
        }
    }
    input[type='date'] {
        box-sizing: border-box;
    }
`

export const Textarea = styled(WfTextarea)`
    ${InputStyles}
`

// Remove material icons display and add an after to show an arrow down.
export const Select = styled(WfSelect)`
    ${InputStyles}
    select {
        height: auto;
        align-self: flex-start;
        font-size: ${INPUT_FONT_SIZE};
    }
    label {
        font-size: ${LABEL_FONT_SIZE};
    }

    option {
        height: auto;
        align-self: flex-start;
        font-size: ${INPUT_FONT_SIZE};
    }

    i.material-icons {
        visibility: hidden;
        width: 100%;
        &:after {
            visibility: visible;
            content: '▼';
            position: absolute;
            right: ${fontSizeSmall};
            padding-right: ${fontSizeSmall};
            top: 4px;
            font-size: ${INPUT_FONT_SIZE};
        }
    }
    > div:nth-child(even) {
        margin-bottom: -16px;
    }
`
export const SelectOption = styled(WfSelectOption)`
    height: auto;
    align-self: flex-start;
    font-size: ${INPUT_FONT_SIZE};
`

export const Checkbox = styled(WfCheckbox)<CheckboxProps>`
    & ~ span {
        font-size: ${INPUT_FONT_SIZE};
        font-weight: ${FontWeight.Medium};
    }
    & ~ div {
        height: ${Spacing.Medium};
        width: ${Spacing.Medium};
        box-sizing: content-box;
        ${({ $error }) => ($error ? `border-color: ${WF_ERROR_COLOR};` : '')}
    }
    & ~ label {
        margin-bottom: 0;
        align-items: start;
    }
`
interface CheckboxProps {
    readonly $error?: boolean
}

export const LegalCheckBox = styled(Checkbox)`
    & ~ span {
        font-size: 1rem;
        font-weight: ${FontWeight.Default};
    }
`

export const Radio = styled(WfRadio)`
    box-sizing: content-box;
    ~ p {
        margin-bottom: 0;
        margin-top: 0;
        font-size: ${INPUT_FONT_SIZE};
    }
    ~ div {
        box-sizing: content-box;
    }
`

//colors and stled adapted from mui inputs to look similar
export const InlineInput = styled.input`
    padding: 7px 12px 8px 12px;
    border: 1px solid #ccd1d4;
    border-radius: 4px;
    outline: none;
    font-size: 1.3rem;
    &:hover:not(:disabled):not(:focus) {
        border-color: #66747d;
    }
    &:focus {
        border: 2px solid #005cb9;
    }
`

// Styling for MUI components for most recent web framework
export const StyledControl = styled(FormControl)`
    display: grid;
    gap: ${Spacing.ExtraSmall};
    width: 100%;
    && label {
        font-family: ${fontFamilyBody};
        font-size: ${LABEL_FONT_SIZE};
        ${({ error }) => (error ? `color: ${WF_ERROR_COLOR};` : '')}
    }
    && input {
        font-family: ${fontFamilyBody};
        font-size: ${INPUT_FONT_SIZE};
        padding-bottom: ${Spacing.Small};
        font-weight: ${FontWeight.Default};
        color: ${WF_INPUT_COLOR};
    }
    && legend {
        font-size: ${WF_LEGEND_FONT_SIZE};
    }
`
export const InputError = styled.span`
    font-size: ${WF_ERROR_FONT_SIZE};
    color: ${WF_ERROR_COLOR};
`
