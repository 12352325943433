import React from 'react'

import { ActionLink } from '@igs-web/common-ui-components/_atoms/link/action-link'
import { DropdownList, DropdownListItem } from '@igs-web/common-ui-components/_molecules/dropdown-list'
import { PopPanel } from '@igs-web/common-ui-components/_molecules/pop-panel'

export const TableExportMenu = ({ handlePdfDownload, handleCsvDownload }: Props): JSX.Element => {
    return (
        <DropdownList buttonText={'Download'} isLink={true}>
            <PopPanel isOpen={true} alignRight={true} width={'auto'} showOnMobile={true}>
                <DropdownListItem>
                    <ActionLink onClick={handleCsvDownload}>CSV</ActionLink>
                </DropdownListItem>
                <DropdownListItem>
                    <ActionLink onClick={handlePdfDownload}>PDF</ActionLink>
                </DropdownListItem>
            </PopPanel>
        </DropdownList>
    )
}

interface Props {
    readonly handlePdfDownload: () => void
    readonly handleCsvDownload: () => void
}
