import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Action } from 'redux'

import _sortBy from 'lodash-es/sortBy'

import { HwBillingHistoryItem } from '@igs-web/common-models/models'
import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'

import { CommonReduxState } from '../common-redux'
import { DataTable } from '../data-table/data-table'
import { DefaultEmptyTable } from '../data-table/default-empty-table'
import { TableColumn } from '../data-table/models/table-column'
import { TableDataType } from '../data-table/models/table-data-types'

import { hwBillingHistoryActions, hwBillingHistorySelectors } from './hw-billing-history-redux'

const columns: ReadonlyArray<TableColumn> = [
    { header: 'Billing Date', accessor: 'transactionDate', dataType: TableDataType.DateTime, disableSorting: false },
    { header: 'Billing Amount', accessor: 'amount', dataType: TableDataType.Currency, disableSorting: true },
]

export const HwBillingHistoryTab = ({ accountId }: Props) => {
    const dispatch = useDispatch()
    const loadHwBillingHistory = React.useCallback(
        (id: number): Action => dispatch(hwBillingHistoryActions.loadHwAccountBillingHistory({ accountId: id, showGlobalLoader: false })),
        [dispatch],
    )
    const hwBillingHistory = useSelector<CommonReduxState, ReadonlyArray<HwBillingHistoryItem> | undefined>(store =>
        hwBillingHistorySelectors.selectHwBillingHistory(store, accountId),
    )
    const hwBillingHistoryLoading = useSelector<CommonReduxState, boolean>(store => hwBillingHistorySelectors.selectIsLoading(store))
    React.useEffect(() => {
        loadHwBillingHistory(accountId)
    }, [accountId, loadHwBillingHistory])

    const sortedHwBillingHistory = _sortBy(hwBillingHistory, b => b.transactionDate).reverse()

    if (hwBillingHistoryLoading) {
        return (
            <div>
                <LocalLoader isLoading={true} />
            </div>
        )
    }

    return (
        <div>
            <DataTable<HwBillingHistoryItem>
                columns={columns}
                data={sortedHwBillingHistory}
                tableTitle="Bills"
                showWhenEmpty={<DefaultEmptyTable emptyTableMessage="No billing history found for this account" />}
                dataTestId="billing-history-table"
            />
        </div>
    )
}

interface Props {
    readonly accountId: number
}
