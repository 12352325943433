import styles from 'root/../styles/abstracts/_variables.scss'
import styled from 'styled-components'

import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

export const SubTitle = styled.div`
    display: none;
    font-weight: 200;
    font-size: 0.875rem;
    color: ${styles.lightLight};
    letter-spacing: 0;
    line-height: 1.125rem;
    text-transform: none;
    display: inline-block;
    margin-bottom: ${Spacing.ExtraSmall};

    @media (min-width: ${styles.mdWidth}) {
        display: block;
    }
`
