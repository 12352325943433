import { AddAuthorizedUserRequest, AuthorizedUser, PartyAuthorizedUsersResponse } from '@igs-web/common-models/models/authorized-user-model'
import { buildQueryStringUrl } from '@igs-web/common-utilities/utilities/query-string-utilities'

import { apiClient } from './api-client'

class AuthorizedUserApiClient {
    public readonly getPartyAuthorizedUsers = async (userProfileId: number) => {
        const url = buildQueryStringUrl({ userProfileId }, `${apiClient.apiBaseUrl}/AuthorizedUsers?userProfileId=${userProfileId}`)
        return await apiClient.getAuthed<ReadonlyArray<PartyAuthorizedUsersResponse>>(url)
    }
    public readonly addAuthorizedUser = async (request: AddAuthorizedUserRequest) => {
        return await apiClient.postAuthed<AuthorizedUser>(`${apiClient.apiBaseUrl}/AuthorizedUsers/Add`, request)
    }
    public readonly editAuthorizedUser = async (request: AuthorizedUser) => {
        return await apiClient.postAuthed<AuthorizedUser>(`${apiClient.apiBaseUrl}/AuthorizedUsers/Edit`, request)
    }
    public readonly deleteAuthorizedUser = async (authorizedUserId: number) => {
        return await apiClient.postAuthed<boolean>(`${apiClient.apiBaseUrl}/AuthorizedUsers/Delete?authorizedUserId=${authorizedUserId}`)
    }
}

export const authorizedUserApiClient = new AuthorizedUserApiClient()
