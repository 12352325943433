import * as React from 'react'

export const removeNonAlphaNumeric = (val: string): string => val.replace(/[\W_]/g, '')
export const removeHyphen = (val: string): string => val.replace(/-/g, '')

export const removeNonNumeric = (val: string, strict = true): string => {
    const regex = strict ? /[^\d]/g : /[^\d-.()]/g
    return val.replace(regex, '')
}

export const nonBreakingHyphen = '\u2011'

export const formatString = (input: string | undefined | null, formatter: string | undefined, caretPosition = 0) => {
    if (!formatter || !input) {
        return {
            result: input || '',
            caretPosition: caretPosition || 0,
        }
    }

    const formatted = input.split('')

    for (let i = 0; i < formatted.length; i++) {
        const format = formatter[i]
        const char = formatted[i]
        switch (format) {
            case '9':
            case '#':
                if (/[^0-9]/g.test(char)) {
                    formatted.splice(i, 1)
                    if (i < caretPosition) {
                        caretPosition--
                    }
                    i--
                }
                break

            case 'A':
                if (/[^A-Za-z]/g.test(char)) {
                    formatted.splice(i, 1)
                    if (i < caretPosition) {
                        caretPosition--
                    }
                    i--
                }
                break

            case '*':
                if (/[^A-Za-z0-9]/g.test(char)) {
                    formatted.splice(i, 1)
                    if (i < caretPosition) {
                        caretPosition--
                    }
                    i--
                }
                break

            case undefined:
            case null:
            case '':
                formatted.splice(i, 1)
                break

            default:
                if (char !== format) {
                    formatted.splice(i, 0, format)
                    if (i <= caretPosition) {
                        caretPosition++
                    }
                }
        }
    }

    return {
        result: formatted.join(''),
        caretPosition,
    }
}

export const stripFormatting = (input: string | undefined, formatter: string | undefined) => {
    if (!formatter || !input) {
        return input || ''
    }

    const formatted = input.split('')
    const formatterArr = formatter.split('')

    for (let i = 0; i < formatted.length; i++) {
        const format = formatterArr[i]
        const char = formatted[i]
        switch (format) {
            case '9':
            case '#':
            case 'A':
            case '*':
                break
            case undefined:
            case null:
            case '':
                formatted.splice(i, 1)
                break

            default:
                if (char === format) {
                    formatted.splice(i, 1)
                    formatterArr.splice(i, 1)
                    i--
                }
        }
    }

    return formatted.join('')
}

export const reactNodeToString = (reactNode: React.ReactNode): string => {
    // eslint-disable-next-line functional/no-let
    let string = ''
    if (typeof reactNode === 'string') {
        string = reactNode
    } else if (typeof reactNode === 'number') {
        string = reactNode.toString()
    } else if (reactNode instanceof Array) {
        reactNode.forEach(child => {
            string += reactNodeToString(child)
        })
    } else if (React.isValidElement(reactNode)) {
        string += reactNodeToString(reactNode.props.children)
    }
    return string
}

export const capitalizeFirstLetter = (word: string) => {
    word = word.toLowerCase()
    return word.charAt(0).toUpperCase() + word.slice(1)
}
