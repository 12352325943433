import * as React from 'react'

import { ActionLink } from '@igs-web/common-ui-components/_atoms/link/action-link'
import { FaSpinnerAnimated } from '@igs-web/common-ui-components/_molecules/fa-spinner-animated'
import { downloadBlob } from '@igs-web/common-utilities/utilities/document-utilities'
import { fireCustomFullStoryEvent, FsEvent } from '@igs-web/common-utilities/utilities/fs-logger'
import { useLatestInvoice } from '../myaccount/use-latest-invoice'

export const MostRecentInvoiceLink = ({ billingAccountNumber }: MostRecentInvoiceLinkProps) => {
    const { getLatestInvoice } = useLatestInvoice()

    const handleDownloadMostRecentInvoice = async () => {
        const invoice = await getLatestInvoice(billingAccountNumber)
        if (invoice) {
            const filename = `Invoice-${invoice.invoiceNumber}`
            downloadBlob(invoice.blob, filename, 'pdf')
        }
    }

    return <MostRecentInvoiceLinkUi handleDownloadMostRecentInvoice={handleDownloadMostRecentInvoice} />
}

export const MostRecentInvoiceLinkUi = ({ handleDownloadMostRecentInvoice }: MostRecentInvoiceLinkUiProps) => {
    const [invoiceIsDownloading, setInvoiceIsDownloading] = React.useState(false)

    const downloadInvoice = async (): Promise<void> => {
        try {
            setInvoiceIsDownloading(true)
            await handleDownloadMostRecentInvoice()
            setInvoiceIsDownloading(false)
        } catch (error) {
            fireCustomFullStoryEvent(FsEvent.downloadLatestInvoiceError, error)
            setInvoiceIsDownloading(false)
        }
    }

    return (
        <>
            {!invoiceIsDownloading && <ActionLink onClick={downloadInvoice}>Download Bill</ActionLink>}
            {invoiceIsDownloading && (
                <ActionLink onClick={(): void => {}}>
                    <>
                        <>Downloading</>
                        <FaSpinnerAnimated />
                    </>
                </ActionLink>
            )}
        </>
    )
}

interface MostRecentInvoiceLinkProps {
    readonly billingAccountNumber: string
}

interface MostRecentInvoiceLinkUiProps {
    readonly handleDownloadMostRecentInvoice: () => void
}
