export interface CreateAccountRequest {
    readonly firstName?: string
    readonly lastName?: string
    readonly email: string
    readonly password: string
}

export interface CreateAccountResponse {
    readonly success: boolean
    readonly message: string
}

export interface CreateAccountWithLinkRequest extends CreateAccountRequest {
    readonly phoneNumber?: string
    readonly billingAccountNumber: string
}

export interface CreateAccountWithLinkResponse {
    readonly createUserStatus: CreateAccountWithLinkStatus
}

export enum CreateAccountWithLinkStatus {
    SUCCESS = 'Success',
    SUCCESSSENTPASSWORDRESET = 'SuccessSentPasswordReset',
    ERROR = 'Error',
    DUPLICATE = 'Duplicate',
    NOTFOUND = 'NotFound',
    ALREADYLINKED = 'AlreadyLinked',
    INVALIDAPPLICATION = 'InvalidApplication',
    INVALIDBILLINGACCOUNTNUMBER = 'InvalidBillingAccountNumber',
    MYACCOUNTCRMEMAILMISMATCH = 'MyAccountCrmEmailMismatch',
    COULDNOTFINDEXISTINGUSERPROFILE = 'CouldNotFindExistingUserProfile',
}
