export enum PageNavTypes {
    Menu = 'menu',
    MenuRight = 'menu-right',
    Leaf = 'leaf',
    Component = 'component',
    // These are not used in the refresh implementation
    Button = 'button',
    SecondaryButton = 'secondary-button',
    Back = 'back',
    Logout = 'logout',
    Hidden = 'hidden',
    Account = 'account',
    // These three can be retired after brand evolution
    Contact = 'contact',
    Cart = 'cart',
    Search = 'search',
}

export interface PageNavModel {
    readonly type: string
    readonly id: string
    readonly name: string
    readonly url: string
    readonly childPages?: ReadonlyArray<PageNavModel>
    readonly component?: React.ReactNode
    readonly subTitle?: string
    readonly groupNext?: boolean
    readonly isOnRight?: boolean
    readonly switcherValue?: string
}
