import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { get } from 'lodash-es'
import styled from 'styled-components'

import { isTexasElectric } from '@igs-web/common-components/domain/checkout/business/enrollment-service'
import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { EnrollmentModel } from '@igs-web/common-models/models/enrollment-model'
import { Checkbox } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Input, InputHelperText } from '@igs/react-styled-components'

import { useCompany } from '../../company/hooks/useCompany'
import { ReviewOrderLegalese } from '../review-order-legalese'

const StyledReviewConfirmation = styled.div`
    justify-self: right;
`

const InitialsLabel = styled.p`
    margin: 0;
    font-weight: ${FontWeight.Medium};
`

export const ReviewOrderForm = ({ idPrefix, enrollmentState }: Props) => {
    const stateCode = useSelector((store: CommonReduxState) => OfferSelectors.selectStateCode(store))
    const selectedOffers = useSelector((store: CommonReduxState) => OfferSelectors.selectSelectedOffers(store))
    const electronicAgreementNumberDisplay = enrollmentState.electronicAgreementNumber?.split('-').pop()
    const {
        register,
        formState: { errors },
    } = useFormContext()

    const { enrollment } = useCompany()

    const acceptedTermsFieldError = get(errors, 'acceptedTerms')?.message?.toString()
    const initialsFieldError = get(errors, 'initials')?.message?.toString()
    const understandsRightsFieldError = get(errors, 'understandsRights')?.message?.toString()

    return (
        <StyledReviewConfirmation>
            {enrollment.collectInitialsOnSummary && (
                <>
                    {electronicAgreementNumberDisplay && <ReviewOrderLegalese token={electronicAgreementNumberDisplay} />}
                    <InitialsLabel>
                        By entering my initials below I verify that all of the above information looks accurate, and the name displayed is my name.
                    </InitialsLabel>
                    <Input
                        {...register('initials', { required: 'Required' })}
                        dataTestId="order-initials"
                        label="Initials"
                        minLength={2}
                        error={initialsFieldError}
                    />
                </>
            )}
            <Checkbox
                {...register('acceptedTerms', { required: 'Required' })}
                defaultChecked={enrollmentState.acceptedTerms}
                dataTestId={`${idPrefix}accepted-terms`}
                label="I agree to the terms and conditions of these products."
                $error={!!acceptedTermsFieldError}
            />
            <InputHelperText error={acceptedTermsFieldError} currentLength={0} />
            {isTexasElectric(selectedOffers, stateCode) && (
                <>
                    <Checkbox
                        {...register('understandsRights', { required: 'Required', shouldUnregister: true })}
                        defaultChecked={enrollmentState.understandsRights}
                        dataTestId={`${idPrefix}understand-rights`}
                        label="I have reviewed and understand my rights as a customer."
                        $error={!!acceptedTermsFieldError}
                    />
                    <InputHelperText error={understandsRightsFieldError} currentLength={0} />
                </>
            )}
        </StyledReviewConfirmation>
    )
}

interface Props {
    readonly idPrefix: string
    readonly enrollmentState: EnrollmentModel
}
