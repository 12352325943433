import { filterSensitiveEnrollmentData } from '@igs-web/common-components/domain/common-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import { createTransform, persistReducer, persistStore } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import createSagaMiddleware from 'redux-saga'

declare global {
    interface Window {
        readonly __REDUX_DEVTOOLS_EXTENSION__: any
    }
}

const removeSensitiveDataTransform = createTransform(filterSensitiveEnrollmentData, undefined, { whitelist: ['enrollment'] })

const persistConfig = {
    key: 'root',
    storage: storageSession,
    debug: true,
    transforms: [removeSensitiveDataTransform],
    blacklist: ['shoppingCartSlide', 'modal'],
}

export default (rootReducer, rootSaga) => {
    const persistedReducer = persistReducer(persistConfig, rootReducer)
    const sagaMiddleware = createSagaMiddleware()
    const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__
        ? [applyMiddleware(sagaMiddleware), window.__REDUX_DEVTOOLS_EXTENSION__()]
        : [applyMiddleware(sagaMiddleware)]

    const store = createStore(persistedReducer, compose.apply({}, storeEnhancers))

    const persistor = persistStore(store)

    sagaMiddleware.run(rootSaga)

    return { store, persistor }
}
