import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const SolarCircleIcon = ({ altText = 'Solar', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M17.182 8.239c0.024 0.041 0.039 0.090 0.039 0.142 0 0.154-0.125 0.279-0.279 0.279-0.014 0-0.027-0.001-0.041-0.003l0.002 0h-1.836c-0.010 0.001-0.022 0.002-0.033 0.002-0.158 0-0.286-0.128-0.286-0.286 0-0.049 0.012-0.095 0.034-0.136l-0.001 0.002 1.028-2.445c0.095-0.235 0.257-0.235 0.352 0z" />
        <path d="M17.182 23.769c0.023-0.041 0.037-0.089 0.037-0.141 0-0.158-0.128-0.286-0.286-0.286-0.011 0-0.021 0.001-0.031 0.002l0.001-0h-1.836c-0.011-0.002-0.024-0.003-0.038-0.003-0.158 0-0.286 0.128-0.286 0.286 0 0.052 0.014 0.101 0.038 0.143l-0.001-0.001 1.028 2.438c0.095 0.235 0.257 0.235 0.352 0z" />
        <path d="M15.985 22.095c-3.366 0-6.095-2.729-6.095-6.095s2.729-6.095 6.095-6.095c3.366 0 6.095 2.729 6.095 6.095v0c-0.004 3.364-2.73 6.090-6.094 6.095h-0zM15.985 11.080c-0.002 0-0.005 0-0.007 0-2.725 0-4.934 2.209-4.934 4.934s2.209 4.934 4.934 4.934c2.725 0 4.934-2.209 4.934-4.934 0-0.003 0-0.005 0-0.008v0c-0.008-2.718-2.209-4.919-4.926-4.927h-0.001z" />
        <path d="M8.217 17.204c0.041 0.024 0.090 0.038 0.142 0.038 0.158 0 0.286-0.128 0.286-0.286 0-0.013-0.001-0.026-0.003-0.039l0 0.002v-1.836c0.001-0.009 0.002-0.019 0.002-0.030 0-0.158-0.128-0.286-0.286-0.286-0.052 0-0.101 0.014-0.142 0.038l0.001-0.001-2.438 1.021c-0.235 0.095-0.235 0.257 0 0.352z" />
        <path d="M23.747 17.204c-0.041 0.024-0.090 0.038-0.142 0.038-0.158 0-0.286-0.128-0.286-0.286 0-0.013 0.001-0.026 0.003-0.039l-0 0.002v-1.836c-0.001-0.009-0.002-0.019-0.002-0.030 0-0.158 0.128-0.286 0.286-0.286 0.052 0 0.101 0.014 0.142 0.038l-0.001-0.001 2.445 1.021c0.228 0.095 0.228 0.257 0 0.352z" />
        <path d="M11.345 22.337c0.12-0.033 0.206-0.141 0.206-0.269 0-0.091-0.043-0.171-0.11-0.222l-0.001-0-1.3-1.285c-0.051-0.072-0.134-0.118-0.228-0.118-0.131 0-0.241 0.090-0.271 0.212l-0 0.002-0.999 2.453c-0.095 0.235 0 0.345 0.25 0.25z" />
        <path d="M22.322 11.359c-0.035 0.122-0.145 0.21-0.276 0.21-0.090 0-0.17-0.042-0.223-0.107l-0-0.001-1.263-1.3c-0.066-0.053-0.107-0.133-0.107-0.223 0-0.131 0.088-0.241 0.208-0.275l0.002-0.001 2.445-1.006c0.235-0.095 0.352 0 0.257 0.257z" />
        <path d="M20.626 22.337c-0.123-0.031-0.212-0.141-0.212-0.271 0-0.090 0.042-0.17 0.108-0.221l0.001-0 1.3-1.285c0.051-0.072 0.134-0.118 0.228-0.118 0.131 0 0.241 0.090 0.271 0.212l0 0.002 1.006 2.453c0.095 0.235 0 0.345-0.257 0.25z" />
        <path d="M9.641 11.359c0.035 0.122 0.145 0.21 0.276 0.21 0.090 0 0.17-0.042 0.223-0.107l0-0.001 1.3-1.3c0.072-0.051 0.118-0.134 0.118-0.228 0-0.131-0.090-0.241-0.212-0.271l-0.002-0-2.453-1.006c-0.235-0.095-0.345 0-0.25 0.257z" />
        <path d="M15.17 13.217c0.077-0.103 0.199-0.169 0.336-0.169 0.006 0 0.011 0 0.017 0l-0.001-0h2.151c0.147 0 0.206 0.073 0.14 0.162l-1.395 1.843c-0.066 0.088 0 0.184 0.14 0.206l1.469 0.257c0.14 0 0.184 0.117 0.11 0.198l-3.598 3.774c-0.064 0.054-0.147 0.087-0.237 0.087-0.044 0-0.086-0.008-0.125-0.022l0.003 0.001c-0.078-0.019-0.136-0.089-0.136-0.172 0-0.028 0.007-0.055 0.019-0.079l-0 0.001 1.733-2.849c0.059-0.088 0-0.169-0.154-0.184l-1.689-0.117c-0.139 0-0.22-0.103-0.169-0.191z" />
        <path d="M15.985 0c-0.007 0-0.014 0-0.022 0-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-0.002 0-0.005 0-0.007 0-8.827-7.152-15.984-15.977-15.993h-0.001zM15.985 30.458c-0.004 0-0.010 0-0.015 0-7.985 0-14.458-6.473-14.458-14.458s6.473-14.458 14.458-14.458c7.985 0 14.458 6.473 14.458 14.458v0c0 0.004 0 0.010 0 0.015 0 7.977-6.466 14.443-14.443 14.443v0z" />
    </SvgIcon>
)

export default SolarCircleIcon
