import * as React from 'react'

import _isNumber from 'lodash-es/isNumber'
import styled from 'styled-components'

import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { UserProfileAccount } from '@igs-web/common-models/models/user-profile-model'
import { formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'

// TODO: ideally these should map to service-{lob} colors as defined,
// but marketing isn't ready for that overhall via charts & so forth,
// so we keep the old colors
const lineOfBusinessToMyAccountColor = lineOfBusiness => {
    switch (lineOfBusiness) {
        case LineOfBusinessCode.Electric:
            return 'var(--secondary-dark)'
        case LineOfBusinessCode.Gas:
            return 'var(--primary-base)'
        case LineOfBusinessCode.HomeWarranty:
            return 'var(--myaccount-home-warranty)'
        default:
            return 'var(--body-color)'
    }
}

export const PlanRate = styled.div<PlanRateProps>`
    color: ${({ $lineOfBusinessCode }: PlanRateProps) => lineOfBusinessToMyAccountColor($lineOfBusinessCode)};
    font-size: 2.5rem;
    line-height: 1;
    span {
        font-size: var(--default-font-size);
        color: var(--body-color);
    }
`

export const PlanRateContainer = styled.div`
    display: grid;
    grid-area: planRate;
`
interface PlanRateProps {
    readonly $lineOfBusinessCode: LineOfBusinessCode
}

export const PlanRateSection = ({ account }: { readonly account: UserProfileAccount }): JSX.Element => {
    const { rate, lineOfBusinessCode, rateUnit } = account
    const isFixedRate = _isNumber(rate)
    return (
        <PlanRateContainer>
            YOUR PLAN
            <PlanRate $lineOfBusinessCode={lineOfBusinessCode}>
                {isFixedRate ? (
                    <>
                        {formatAsMoney(rate)}
                        <span>/{rateUnit}</span>
                    </>
                ) : (
                    <>Variable</>
                )}
            </PlanRate>
        </PlanRateContainer>
    )
}
