import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { offersActions, OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { OfferAccountType } from '@igs-web/common-models/constants/account-type'
import { getOffers } from '@igs-web/common-utilities/services/offer-retrieval'

import { useSelectOffer } from './use-select-offer'

export const useOffersHandler = () => {
    const dispatch = useDispatch()
    const { selectOffer, unSelectOffer } = useSelectOffer()
    const { name: companyName } = useCompany()

    const offers = useSelector((store: CommonReduxState) => OfferSelectors.selectOffers(store))
    const selectedOffers = useSelector((store: CommonReduxState) => OfferSelectors.selectSelectedOffers(store))
    const offerRequest = useSelector((store: CommonReduxState) => OfferSelectors.selectOfferRequest(store))
    const isOfferAvailable = async (
        offerId: number | null | undefined,
        campaignCode: string | null | undefined,
        priceEffectiveDate: Date | null,
        promoCode: string | null,
        zipCode: string | null | undefined,
        distributionZone?: string,
        showGlobalLoader = true,
    ): Promise<boolean> => {
        if (offerId && campaignCode && priceEffectiveDate && zipCode) {
            const availableOffers = await getOffers(
                zipCode,
                OfferAccountType.Residential,
                campaignCode,
                promoCode || undefined,
                undefined,
                priceEffectiveDate,
                true,
                undefined,
                distributionZone,
                showGlobalLoader,
            )
            const offerIsAvailable = availableOffers.some(ao => ao.offerId === offerId)

            return offerIsAvailable
        }
        return false
    }

    const selectOfferIfAvailable = async (
        offerId: number | null,
        campaignCode: string | null,
        priceEffectiveDate: Date | null,
        promoCode: string | null,
        zipCode: string | null | undefined,
        distributionZone?: string,
        showGlobalLoader = true,
    ) => {
        if (offerId && campaignCode && priceEffectiveDate && zipCode) {
            const offerIsAvailable = await isOfferAvailable(offerId, campaignCode, priceEffectiveDate, promoCode, zipCode, undefined, showGlobalLoader)

            if (offerIsAvailable) {
                dispatch(
                    offersActions.loadOffers({
                        zipCode,
                        offerAccountType: OfferAccountType.Residential,
                        campaignCode,
                        companyName,
                        priceEffectiveDate,
                        ignoreCache: true,
                        invitationCode: promoCode ?? undefined,
                        offerIdFromMarketingSite: offerId,
                        distributionZone,
                        showGlobalLoader,
                    }),
                )
                if (promoCode) {
                    dispatch(offersActions.submitOfferRequestForm({ zipOrInviteCode: promoCode }))
                }
            } else if (selectedOffers[0]) {
                dispatch(offersActions.removeCampaignCode())
                unSelectOffer(selectedOffers[0])
            }
        }
    }

    React.useEffect(() => {
        if (offers.length && offerRequest.offerIdFromMarketingSite) {
            const offer = offers.find(o => o.offerId === offerRequest.offerIdFromMarketingSite)
            if (offer) {
                dispatch(offersActions.applyCampaignCode(offerRequest.campaignCode))
                selectOffer(offer)
            }
        }
    }, [offers.length && offerRequest.offerIdFromMarketingSite])

    const isHwOfferAvailable = async (
        offerId: number | null | undefined,
        campaignCode: string | null | undefined,
        zipCode: string | null | undefined,
        showGlobalLoader = true,
    ): Promise<boolean> => {
        if (offerId && campaignCode && zipCode) {
            const availableOffers = await getOffers(
                zipCode,
                OfferAccountType.Residential,
                campaignCode,
                undefined,
                undefined,
                undefined,
                true,
                undefined,
                undefined,
                showGlobalLoader,
            )
            const offerIsAvailable = availableOffers.some(ao => ao.offerId === offerId)

            return offerIsAvailable
        }
        return false
    }

    const selectHwOfferIfAvailable = async (
        offerId: number | null,
        campaignCode: string | null,
        zipCode: string | null | undefined,
        showGlobalLoader = true,
    ) => {
        if (offerId && campaignCode && zipCode) {
            dispatch(
                offersActions.loadOffers({
                    zipCode,
                    offerAccountType: OfferAccountType.Residential,
                    campaignCode,
                    companyName,
                    priceEffectiveDate: undefined,
                    ignoreCache: true,
                    invitationCode: undefined,
                    offerIdFromMarketingSite: offerId,
                    distributionZone: undefined,
                    showGlobalLoader,
                }),
            )
        } else if (selectedOffers[0]) {
            dispatch(offersActions.removeCampaignCode())
            unSelectOffer(selectedOffers[0])
        }
    }

    return {
        isOfferAvailable,
        selectOfferIfAvailable,
        selectHwOfferIfAvailable,
        isHwOfferAvailable,
    }
}

export interface OfferQueryStringParams {
    readonly offerId: string | null
    readonly campaignCode: string | null
    readonly priceEffectiveDate?: Date | null
    readonly promoCode?: string | null
}
