import * as React from 'react'
import { useEffectOnce } from 'react-use'
import { defaultZipCode } from 'scanaPublic/constants'
import styled from 'styled-components'

import { HomeWarrantyDetailCard, HWCard, HWCardDetails } from '@igs-web/common-components/domain/home-warranty/home-warranty-detail-card'
import { OfferAccountType } from '@igs-web/common-models/constants/account-type'
import { LineOfBusinessCode, OfferModel } from '@igs-web/common-models/models'
import { ColorwayType } from '@igs-web/common-ui-components/_molecules/theming/colorway-container'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeMedium } from '@igs-web/common-ui-components/styles/theme'
import { sitefinityApiClient } from '@igs-web/common-utilities/api/sitefinity-api-client'
import { getOffers } from '@igs-web/common-utilities/services/offer-retrieval'

export const ProductSelectionCardsFlex = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: ${Spacing.ExtraLarge};

    grid-column: 1 / -1;
    ${HWCard} {
        max-width: 300px;
        height: unset;
        h4 {
            font-size: ${fontSizeMedium};
        }
    }
`

export const ProductSelectionController = ({ zipCode, onSelectOffer }: Props) => {
    const [offers, setOffers] = React.useState<ReadonlyArray<OfferModel>>([])
    const [defaultOffers, setDefaultOffers] = React.useState<ReadonlyArray<OfferModel>>([])
    const [offersLoaded, setOffersLoaded] = React.useState(false)
    const [details, setDetails] = React.useState<ReadonlyArray<HWCardDetails>>([])

    useEffectOnce(() => {
        const loadDetails = async () => {
            const result = (await sitefinityApiClient.getHomeWarrantyDetails({ showGlobalLoader: false })) || []
            //only one colorway option on SCANA
            const hwDetails = result.map(d => ({ ...d, colorway: ColorwayType.PrimaryBase })).sort((a, b) => a.sequence - b.sequence)
            setDetails(hwDetails)
        }
        loadDetails()
    })

    React.useEffect(() => {
        const campaignCode = 'web-new'
        const showGlobalLoader = true
        const loadOffers = async () => {
            if (!zipCode && defaultOffers.length > 0) {
                return
            }

            const availableOffers = await getOffers(
                zipCode || defaultZipCode,
                OfferAccountType.Residential,
                campaignCode,
                undefined,
                undefined,
                undefined,
                true,
                undefined,
                undefined,
                showGlobalLoader,
            )
            const hwOffers = availableOffers.filter(o => o.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty)
            if(zipCode) {
                setOffers(hwOffers)
                setOffersLoaded(true)
            }
            if (defaultOffers.length === 0) {
                setDefaultOffers(hwOffers)
            }
        }
        setOffers([])
        setOffersLoaded(false)
        loadOffers()
    }, [zipCode])

    return (
        <ProductSelectionCardsFlex>
            {details.map(d => (
                <HomeWarrantyDetailCard
                    key={d.productCode}
                    isCompact={true}
                    detail={d}
                    offersLoaded={offersLoaded}
                    offer={offers.find(o => o.primaryProduct.productCode === d.productCode)}
                    defaultOffer={defaultOffers.find(o => o.primaryProduct.productCode === d.productCode)}
                    onSelectOffer={onSelectOffer}
                />
            ))}
        </ProductSelectionCardsFlex>
    )
}

interface Props {
    readonly zipCode?: string
    readonly onSelectOffer: (offer: OfferModel) => void
}
