import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const LeafIcon = ({ altText = 'Leaf', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M18.8 5.76c-6.72-0.72-10.4 2.8-11.68 4.32-0.88 0.88-1.6 2.080-2 3.36-0.56 1.84-0.56 3.76 0.080 5.44 3.2-4.24 8.64-5.040 10.16-5.2-1.76 0.88-9.44 5.28-11.2 13.52 0 0.080-0.080 0.16-0.080 0.24-0.080 0.080-0.080 0.24-0.16 0.4 0 0.080-0.080 0.16-0.080 0.32-0.080 0.16-0.080 0.72 0.24 0.8l1.12 0.32c0 0 0.56 0.16 0.56-0.48 0-2.24 0.56-4.64 2-6.16 0.8 0.64 1.76 1.2 2.8 1.52 2.64 0.88 5.44 0.32 7.52-1.2 7.52-4.72 10.8-20.080 10.8-20.080-2.72 3.76-10.080 2.88-10.080 2.88v0zM15.76 13.52v0c0 0 0 0 0 0s0 0 0 0z" />
    </SvgIcon>
)

export default LeafIcon
