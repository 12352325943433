import React from 'react'
import { useHistory } from 'react-router-dom'

import { styled } from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { CreateAccountWithLinkStatus } from '@igs-web/common-models/models/create-account-request'
import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { ExternalLink, Link as StandardLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText, InfoTextType } from '@igs-web/common-ui-components/_molecules/info-text'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { FsEvent, fireCustomFullStoryEvent } from '@igs-web/common-utilities/utilities/fs-logger'
import { parsePhoneNumber } from '@igs-web/common-utilities/utilities/phone-utilities'

import { LayoutSection, LayoutSidebar, LayoutWithSidebar } from 'molecules/layout/layout-styles'

import { parseScanaAccountNumber } from 'domain/account-number/scana-account-number-service'
import * as Routes from 'domain/routes'

import { RegisterAccountForm, RegisterAccountFormFields } from './register-account-form'

const ReturnToSignInContainer = styled.p`
    text-align: right;
`
/* eslint-disable complexity */
export const RegisterAccountPanel = () => {
    const history = useHistory()
    const [status, setStatus] = React.useState<CreateAccountWithLinkStatus | null>(null)
    const [enteredEmail, setEnteredEmail] = React.useState<string>('')
    const { phoneNumber, externalWebsite, companionWebsite } = useCompany()

    const onSubmit = async (request: RegisterAccountFormFields) => {
        setEnteredEmail(request.email)

        const result = await customerInfoApiClient.createUserAccountWithLink({
            ...request,
            phoneNumber: parsePhoneNumber(request.phoneNumber),
            billingAccountNumber: parseScanaAccountNumber(request.billingAccountNumber),
        })

        if (result) {
            if (result.createUserStatus !== CreateAccountWithLinkStatus.SUCCESS && result.createUserStatus !== CreateAccountWithLinkStatus.DUPLICATE) {
                fireCustomFullStoryEvent(FsEvent.scanaAccountRegistrationError, { error: result.createUserStatus })
            }
            setStatus(result.createUserStatus)
        } else {
            setStatus(CreateAccountWithLinkStatus.ERROR)
        }
    }

    return (
        <LayoutWithSidebar>
            <LayoutSection>
                {status !== CreateAccountWithLinkStatus.SUCCESS &&
                    status !== CreateAccountWithLinkStatus.ALREADYLINKED &&
                    status !== CreateAccountWithLinkStatus.DUPLICATE &&
                    status !== CreateAccountWithLinkStatus.INVALIDAPPLICATION && (
                        <>
                            <InfoText data-testid="register-account-info-test">
                                Your billing account number may also be known as your SCANA Account Number
                            </InfoText>
                            <GridSpacer spacing={Spacing.Medium} />
                        </>
                    )}

                {status === CreateAccountWithLinkStatus.SUCCESS && (
                    <>
                        <InfoText $infoTextType={InfoTextType.Success}>
                            Thank you. An email will be sent to {enteredEmail} within the next 5 minutes with instructions to complete setup
                        </InfoText>
                        <GridSpacer spacing={Spacing.Medium} />
                    </>
                )}
                {status === CreateAccountWithLinkStatus.SUCCESSSENTPASSWORDRESET && (
                    <>
                        <InfoText>
                            <p>
                                <strong>Check your email for further sign in instructions.</strong>
                            </p>
                            <p>
                                If we recognize this email address, you will receive an email with password reset instructions. Please remember to check your
                                spam folder.
                            </p>
                            <p>
                                If you don't remember your email, our customer service representatives are here to help at{' '}
                                <PhoneNumberLink phoneNumber={phoneNumber.help} />.
                            </p>
                        </InfoText>
                        <GridSpacer spacing={Spacing.Medium} />
                    </>
                )}
                {(status === CreateAccountWithLinkStatus.DUPLICATE || status === CreateAccountWithLinkStatus.ALREADYLINKED) && (
                    <>
                        <InfoText $infoTextType={InfoTextType.Success}>
                            Your account has been located. Follow the instructions sent to your email address to complete the registration.
                        </InfoText>
                        <GridSpacer spacing={Spacing.Medium} />
                    </>
                )}
                {status === CreateAccountWithLinkStatus.INVALIDAPPLICATION && (
                    <>
                        <InfoText>
                            <ExternalLink href={`${companionWebsite?.myAccountUrl}/login/create-account`}>Click here</ExternalLink> to be directed to the
                            correct site to re-register your online account.
                        </InfoText>
                        <GridSpacer spacing={Spacing.Medium} />
                    </>
                )}
                {(status === CreateAccountWithLinkStatus.MYACCOUNTCRMEMAILMISMATCH ||
                    status === CreateAccountWithLinkStatus.COULDNOTFINDEXISTINGUSERPROFILE) && (
                    <>
                        <InfoText>
                            We were unable to link the billing account number and email address to an account. Check the billing account number and email
                            address, or contact us for support.
                        </InfoText>
                        <GridSpacer spacing={Spacing.Medium} />
                    </>
                )}
                {(status === CreateAccountWithLinkStatus.NOTFOUND ||
                    status === CreateAccountWithLinkStatus.ERROR ||
                    status === CreateAccountWithLinkStatus.INVALIDBILLINGACCOUNTNUMBER) && (
                    <>
                        <InfoText>Unable to locate the specified account. Check the account number or contact us for support.</InfoText>
                        <GridSpacer spacing={Spacing.Medium} />
                    </>
                )}
                {status !== CreateAccountWithLinkStatus.SUCCESS &&
                    status !== CreateAccountWithLinkStatus.SUCCESSSENTPASSWORDRESET &&
                    status !== CreateAccountWithLinkStatus.ALREADYLINKED &&
                    status !== CreateAccountWithLinkStatus.DUPLICATE && <RegisterAccountForm onSubmit={onSubmit} />}
                {(status === CreateAccountWithLinkStatus.SUCCESS ||
                    status === CreateAccountWithLinkStatus.DUPLICATE ||
                    status === CreateAccountWithLinkStatus.ALREADYLINKED) && <Button onClick={() => setStatus(null)}>Register another account?</Button>}
                {status === CreateAccountWithLinkStatus.SUCCESSSENTPASSWORDRESET && (
                    <ReturnToSignInContainer>
                        <Button data-testid="success_password_reset_return_to_signin_button" onClick={() => history.push(Routes.LoginRoute)}>
                            RETURN TO SIGN IN
                        </Button>
                    </ReturnToSignInContainer>
                )}
                <br />
            </LayoutSection>

            <LayoutSidebar>
                <p>
                    <b>Need to sign up for service?</b>
                </p>
                <p>
                    Visit <StandardLink href={`${externalWebsite}/rates-sign-up/new-service`}>set up service</StandardLink> for a list of everything you will
                    need.
                </p>
                <Header3>
                    Need Help?
                    <br />
                    <PhoneNumberLink phoneNumber={phoneNumber.help} />
                </Header3>
            </LayoutSidebar>
        </LayoutWithSidebar>
    )
}
