import * as React from 'react'
import { useSelector } from 'react-redux'

import { useCombobox } from 'downshift'
import debounce from 'lodash-es/debounce'
import styled from 'styled-components'

import { matchServiceAddress } from '@igs-web/common-components/domain/service-address/service-address-form'
import { useBreakpoints } from '@igs-web/common-components/hooks/use-breakpoints'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { UserProfileAccount, UserProfileBillingAccount, UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'
import { Header4 } from '@igs-web/common-ui-components/_atoms/typography'
import { Checkbox } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { GridSpacer, GridSpacerNoPadding } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { getBillingAccountServiceAddresses, getBillingAccountUrl, sortAccounts } from '@igs-web/common-utilities/utilities/billing-account-utilities'

import { UserSelectors } from '../user/user-redux'

import { BillingAccountTile } from './billing-accounts/billing-account-tile'
import { SalesAndServices } from './billing-accounts/sales-and-service'
import { useMyAccountDashboard } from './dashboard-context'
import { BillingAccountGrid, DashboardContainer, InfoColumn, SearchGrid, SearchHeader, SearchInput } from './myaccount-components'

export const matchBillingAccount = (
    billingAccount: UserProfileBillingAccount,
    serviceAddresses: ReadonlyArray<UserProfileServiceAddress>,
    serviceAccounts: ReadonlyArray<UserProfileAccount>,
    inputValue,
) =>
    billingAccount.billingAccountNumber?.includes(inputValue) ||
    billingAccount.name?.includes(inputValue) ||
    getBillingAccountServiceAddresses(billingAccount, serviceAddresses, serviceAccounts).length > 0 ||
    billingAccount.mailDistributions?.some(
        address =>
            address.address1?.toLowerCase().includes(inputValue) ||
            address.address2?.toLowerCase().includes(inputValue) ||
            address.city?.toLowerCase().includes(inputValue) ||
            address.state?.toLowerCase().includes(inputValue) ||
            address.zipCode?.includes(inputValue),
    ) ||
    billingAccount.emailDistributions?.some(x => x.emailAddress.toLowerCase().includes(inputValue))

export const AccountGrid = styled(SearchGrid)`
    grid-template-columns: 2fr 1fr 1fr;
    @media (max-width: ${Breakpoint.Mobile}) {
        grid-template-columns: 1fr;
    }
`
export const StyledInfoColumn = styled(InfoColumn)`
    @media (max-width: ${Breakpoint.Mobile}) {
        place-items: center;
        padding-bottom: 0;
    }
`
export const AccountAndBalancesView = () => {
    const { profile, getBillingAccountStatus } = useMyAccountDashboard()

    const activeBillingAccountSorter = sortAccounts(getBillingAccountStatus)
    const [accounts, setAccounts] = React.useState([...profile.billingAccounts].sort(activeBillingAccountSorter))
    const [hideInactive, setHideInactive] = React.useState(false)
    const [filterString, setFilterString] = React.useState('')

    React.useEffect(() => {
        const filteredServiceAddresses = filterString
            ? serviceAddresses.filter(address => matchServiceAddress(address, filterString, serviceAccounts))
            : serviceAddresses
        const filtered_accounts = [...profile.billingAccounts]
            .filter(account => {
                if (hideInactive && getBillingAccountStatus(account.billingAccountNumber) !== AccountStatus.active && !(account.amountDue > 0)) {
                    return false
                }
                if (filterString) {
                    return matchBillingAccount(account, filteredServiceAddresses, profile.accounts, filterString)
                }
                return true
            })
            .sort(activeBillingAccountSorter)
        setAccounts(filtered_accounts)
    }, [hideInactive, filterString])

    const debouncedSetFilterString = debounce(setFilterString, 500)

    const serviceAddresses = useSelector(UserSelectors.selectUserServiceAddresses)
    const serviceAccounts = useSelector(UserSelectors.selectPartyAccounts)
    const { getMenuProps, getInputProps, getComboboxProps } = useCombobox({
        items: [...accounts],
        onInputValueChange: ({ inputValue }) => {
            debouncedSetFilterString(inputValue?.toLowerCase() || '')
        },
    })

    const comboboxProps = getComboboxProps()
    const { lessThanOrEqualTo } = useBreakpoints()
    const isMobile = lessThanOrEqualTo(Breakpoint.Desktop)

    return (
        <DashboardContainer>
            <GridSpacer spacing={Spacing.Medium} />
            <SearchHeader>
                <Header4>Please select a billing account:</Header4>
                <SearchInput comboboxProps={comboboxProps}>
                    <input placeholder="Search Accounts" {...getInputProps()} />
                </SearchInput>
                <Checkbox
                    id="hide-inactive"
                    dataTestId="hide-inactive-checkbox"
                    type="checkbox"
                    checked={hideInactive}
                    label="Hide all inactive accounts with a $0 balance"
                    onChange={() => {
                        setHideInactive(!hideInactive)
                    }}
                />
            </SearchHeader>
            {!!accounts.length && (
                <BillingAccountGrid {...getMenuProps()}>
                    {accounts.map((billingAccount, i) => {
                        const addresses = getBillingAccountServiceAddresses(billingAccount, serviceAddresses, profile.accounts)
                        const status = getBillingAccountStatus(billingAccount.billingAccountNumber)
                        return (
                            <React.Fragment key={billingAccount.billingAccountNumber}>
                                <BillingAccountTile
                                    billingAccountNumber={billingAccount.billingAccountNumber}
                                    amountDue={billingAccount.amountDue}
                                    dateDue={billingAccount.dueDate}
                                    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                                    className={i % 2 ? 'even' : 'odd'}
                                    mailDistributions={addresses}
                                    status={status}
                                    dashboardUrl={getBillingAccountUrl(billingAccount.billingAccountNumber, profile)}
                                    paymentExtensionDueDate={billingAccount.paymentExtension.exisitingPaymentExtensionDueDate}
                                    email={profile.email}
                                />
                                {isMobile && <GridSpacerNoPadding spacing={Spacing.ExtraSmall} />}
                            </React.Fragment>
                        )
                    })}
                </BillingAccountGrid>
            )}
            {accounts.length === 0 && <Header4>No accounts match your search</Header4>}
            {profile.isCommercialIndustrial && <SalesAndServices />}
        </DashboardContainer>
    )
}
