import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'

import { transactionHistoryRedux } from './transaction-history-redux'

export const useTransationHistory = (accountId: number) => {
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(transactionHistoryRedux.actions.load({ key: accountId }))
    }, [accountId, dispatch])

    const transactionHistory = useSelector((store: CommonReduxState) => transactionHistoryRedux.selectors.selectData(store, accountId))
    const pastDueBalance = useSelector((store: CommonReduxState) => transactionHistoryRedux.selectors.selectPastDueBalance(store, accountId))

    return {
        transactionHistory,
        pastDueBalance,
    }
}
