import { all, put, takeLatest } from 'redux-saga/effects'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { OfferAccountType } from '@igs-web/common-models/constants/account-type'
import { PriceUnit } from '@igs-web/common-models/constants/price-units'
import { Address } from '@igs-web/common-models/models/address'
import { EnrollmentModel } from '@igs-web/common-models/models/enrollment-model'
import { EnrollmentResponse } from '@igs-web/common-models/models/enrollment-response'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { OfferModel, ProductTypeCode } from '@igs-web/common-models/models/offer-model'
import { createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'

import { userActions } from '../../user/user-redux'

export const confirmationActionTypes = {
    SET_CONFIRMATION_INFO: '[confirmation] SET_CONFIRMATION_INFO',
    CLEAR_CONFIRMATION_INFO: '[confirmation] CLEAR_CONFIRMATION_INFO',
}

export const confirmationActions = {
    setConfirmationInfo: createAction<SetConfirmationInfoRequest>(confirmationActionTypes.SET_CONFIRMATION_INFO),
    clearConfirmationInfo: createAction<void>(confirmationActionTypes.CLEAR_CONFIRMATION_INFO),
}

const initialModel = {
    offers: [],
} as ConfirmationInfo

export const confirmationReducer = reducer<ConfirmationInfo>(initialModel)
    .add<void>(confirmationActionTypes.CLEAR_CONFIRMATION_INFO, () => initialModel)
    .add<SetConfirmationInfoRequest>(confirmationActionTypes.SET_CONFIRMATION_INFO, (state, request) => ({
        ...state,
        billingInformation: request.enrollmentModel.billingAddress,
        serviceInformation: request.enrollmentResponse.mailingAddress,
        customerName: [
            request.enrollmentModel.defaultContact.firstName,
            request.enrollmentModel.defaultContact.middleInitial,
            request.enrollmentModel.defaultContact.lastName,
        ]
            .filter(o => o)
            .join(' '),
        offers: [
            ...(state.offers || []),
            ...request.enrollmentResponse.orderItems
                .filter(oi => oi.wasSuccessful)
                .map(orderItemResponse => {
                    const shoppingCartItem = request.enrollmentModel.shoppingCartItems.find(
                        o => o.shoppingCartItemKey === orderItemResponse.shoppingCartItemKey && o.lineOfBusinessCode === orderItemResponse.lineOfBusinessCode,
                    )
                    const offer = shoppingCartItem && request.offers.find(o => o.offerId === shoppingCartItem.offerId)
                    return { orderItemResponse, offer, shoppingCartItem }
                })
                .filter(o => o.offer && o.shoppingCartItem && o.orderItemResponse)
                .map(({ offer, shoppingCartItem, orderItemResponse }) => ({
                    offer: offer!,
                    shoppingCartItem: shoppingCartItem!,
                    orderItemResponse: orderItemResponse!,
                }))
                .map(({ offer, shoppingCartItem, orderItemResponse }) => {
                    return {
                        confirmationNumber: orderItemResponse.confirmationNumber,
                        accountId: orderItemResponse.accountId,
                        termMonths: offer.primaryProduct.termMonths,
                        lineOfBusinessCode: offer.primaryProduct.lineOfBusinessCode,
                        utility: offer.primaryProduct.ulob && offer.primaryProduct.ulob.utilityCode,
                        price: offer.primaryProduct.displayPrice,
                        discountedPrice: offer.primaryProduct.discountedPriceDisplay,
                        discountedPriceUnit: offer.primaryProduct.discountedPriceDisplayUnit,
                        discountAmount: offer.primaryProduct.discountAmountDisplay,
                        priceUnit: offer.primaryProduct.displayPriceUnit,
                        title: offer.primaryProduct.title,
                        discountCode: offer.primaryProduct.discountCode,
                        unitOfMeasure: offer.primaryProduct.unitOfMeasure,
                        secondaryLineOfBusiness: offer.secondaryProduct ? offer.secondaryProduct.lineOfBusinessCode : '',
                        productType: offer.primaryProduct.productType,
                        contactEmail: shoppingCartItem.contact.emailAddress,
                        contactPhone: shoppingCartItem.contact.phoneNumber,
                        contactName: [shoppingCartItem.contact.firstName, shoppingCartItem.contact.middleInitial, shoppingCartItem.contact.lastName]
                            .filter(o => o)
                            .join(' '),
                        isGreen: offer.primaryProduct.isGreen,
                        isFriendsAndFamily: offer.primaryProduct.isFriendsAndFamily,
                        productCode: offer.primaryProduct.productCode,
                        accountType: offer.primaryProduct.accountType,
                        ratePlanName: offer.primaryProduct.ratePlanName,
                        depositReferenceNumber: orderItemResponse.depositReferenceNumber,
                        paymentDescription: orderItemResponse.paymentDescription,
                        campaignCode: offer.primaryProduct.campaignCode,
                        termEndMonth: offer.primaryProduct.termEndMonth,
                    }
                }),
        ],
    }))
    .build()

const sagas = {
    *setconfirmationinfo(action: { readonly payload: SetConfirmationInfoRequest }) {
        const accountIds = action.payload.enrollmentResponse.orderItems.filter(x => x.wasSuccessful && !!x.accountId).map(x => x.accountId)
        yield put(userActions.setHasPendingContract(accountIds))
    },
}
export function* confirmationSaga() {
    yield all([takeLatest(confirmationActionTypes.SET_CONFIRMATION_INFO, sagas.setconfirmationinfo as any)])
}

export class ConfirmationSelectors {
    public static readonly selectConfirmationInfo = (state: CommonReduxState) => state.enrollment.confirmation
}

export interface SetConfirmationInfoRequest {
    readonly enrollmentModel: EnrollmentModel
    readonly enrollmentResponse: EnrollmentResponse
    readonly offers: ReadonlyArray<OfferModel>
}

export interface ConfirmationInfo {
    readonly customerName?: string
    readonly billingInformation?: Address
    readonly serviceInformation?: Address
    readonly offers: ReadonlyArray<ConfirmationOffer>
}

export interface ConfirmationOffer {
    readonly accountId: number | null | undefined
    readonly termMonths: number | null | undefined
    readonly lineOfBusinessCode: LineOfBusinessCode
    readonly utility: string | null | undefined
    readonly price: string
    readonly discountedPrice: string | null | undefined
    readonly discountedPriceUnit?: PriceUnit
    readonly discountAmount: string | null | undefined
    readonly priceUnit: string
    readonly productType: ProductTypeCode
    readonly title: string
    readonly discountCode: string | null | undefined
    readonly unitOfMeasure: string
    readonly contactName: string
    readonly contactEmail: string | null | undefined
    readonly contactPhone: string | null | undefined
    readonly confirmationNumber: string
    readonly secondaryLineOfBusiness: string | null | undefined
    readonly isGreen?: boolean
    readonly isFriendsAndFamily: boolean
    readonly productCode: string
    readonly accountType: OfferAccountType
    readonly ratePlanName?: string
    readonly depositReferenceNumber: string | null
    readonly paymentDescription: string
    readonly campaignCode: string
    readonly termEndMonth?: number
}
