import * as React from 'react'

import { AccountDocument } from '@igs-web/common-models/models/document'

import { ConnectedDataProvider } from '../cached-redux/cached-redux-data-loader'

import { accountDocumentsRedux } from './account-documents-redux'
import AccountDocumentTable from './account-documents-table'

export const DocumentTable = ({ accountId }: Props) => {
    const tableDataType = 'documents'

    return (
        <div className="document-details">
            <ConnectedDataProvider dataKey={accountId} dataStores={[accountDocumentsRedux]} showGlobalLoader={false}>
                {([documents]: readonly [ReadonlyArray<AccountDocument>]) => (
                    <AccountDocumentTable data-testid="document-history-table" accountDocuments={documents} tableDataType={tableDataType} />
                )}
            </ConnectedDataProvider>
        </div>
    )
}

interface Props {
    readonly accountId: number
}
