import * as React from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdReply } from 'react-icons/md'
import { Link, LinkProps } from 'react-router-dom'

import styled, { css } from 'styled-components'

import { ExternalLink, LinkProps as ExternalLinkProps } from '@igs-web/common-ui-components/_atoms/link/link'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeLarge, headerColor } from '@igs-web/common-ui-components/styles/theme'

export const LayoutWithSidebar = styled.main`
    display: grid;

    width: 100%;
    grid-template-columns: 5fr 2fr;
    grid-template-areas: 'main sidebar';
    column-gap: ${Spacing.XxLarge};
    a {
        color: ${headerColor};
        text-decoration: none;
        align-self: center;
    }
    ${Header3} {
        margin: 0;
        font-size: ${fontSizeLarge};
    }

    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-areas: 'main' 'sidebar';
        grid-template-columns: 1fr;
        place-items: center;
    }
    p:first-child {
        margin-top: 0;
    }
`

export const LayoutSection = styled.section`
    grid-area: main;
    width: 100%;
`
export const LayoutSidebar = styled.aside`
    width: 100%;
    grid-area: sidebar;
    @media (max-width: ${Breakpoint.Tablet}) {
        justify-self: center;
    }
`
const arrowCss = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${headerColor};
`

const ArrowLink = styled(Link)`
    ${arrowCss}
`
const ArrowExternalLink = styled(ExternalLink)`
    ${arrowCss}
`
const ReplyArrowLink = styled(Link)`
    ${arrowCss}
`

export const LinkWithArrow = ({ children, arrowLeft = false, ...rest }: LinkArrowProps) => (
    <ArrowLink {...rest}>
        {arrowLeft ? <MdKeyboardArrowLeft /> : <MdKeyboardArrowRight />} {children}
    </ArrowLink>
)
interface LinkArrowProps extends LinkProps {
    readonly arrowLeft?: boolean
}

export const ExternalLinkWithArrow = ({ children, arrowLeft = false, ...rest }: ExternalArrowProps) => (
    <ArrowExternalLink {...rest}>
        {arrowLeft ? <MdKeyboardArrowLeft /> : <MdKeyboardArrowRight />} {children}
    </ArrowExternalLink>
)
interface ExternalArrowProps extends ExternalLinkProps {
    readonly arrowLeft?: boolean
}

export const LinkWithReplyArrow = ({ children, ...rest }: LinkProps) => (
    <ReplyArrowLink {...rest}>
        <>
            <MdReply /> {children}
        </>
    </ReplyArrowLink>
)
