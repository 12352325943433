import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const CircleContactInfo = ({ altText = 'Contact Info', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} altText={altText} width="50" height="50" viewBox="0 0 50 50">
        <g id="Group_533" data-name="Group 533" transform="translate(4838 -3227)">
            <g id="Group_532" data-name="Group 532" transform="translate(-62 -192)">
                <g id="Ellipse_208" data-name="Ellipse 208" transform="translate(-4776 3419)" className="icon-border-circle" strokeWidth="3">
                    <circle cx="25" cy="25" r="25" stroke="none" />
                    <circle cx="25" cy="25" r="23.5" fill="none" />
                </g>
                <path
                    id="address-card-regular"
                    d="M25.091,32H2.281A2.282,2.282,0,0,0,0,34.281V51.008a2.282,2.282,0,0,0,2.281,2.281h22.81a2.282,2.282,0,0,0,2.281-2.281V34.281A2.282,2.282,0,0,0,25.091,32Zm0,19.008H2.281V34.281h22.81ZM9.884,42.645A3.041,3.041,0,1,0,6.843,39.6,3.044,3.044,0,0,0,9.884,42.645ZM5.626,48.727h8.516a1,1,0,0,0,1.064-.912V46.9a2.993,2.993,0,0,0-3.193-2.737,11.473,11.473,0,0,1-2.129.38,11.306,11.306,0,0,1-2.129-.38A2.993,2.993,0,0,0,4.562,46.9v.912A1,1,0,0,0,5.626,48.727Zm11.481-3.041H22.43a.381.381,0,0,0,.38-.38v-.76a.381.381,0,0,0-.38-.38H17.107a.381.381,0,0,0-.38.38v.76A.381.381,0,0,0,17.107,45.686Zm0-3.041H22.43a.381.381,0,0,0,.38-.38V41.5a.381.381,0,0,0-.38-.38H17.107a.381.381,0,0,0-.38.38v.76A.381.381,0,0,0,17.107,42.645Zm0-3.041H22.43a.381.381,0,0,0,.38-.38v-.76a.381.381,0,0,0-.38-.38H17.107a.381.381,0,0,0-.38.38v.76A.381.381,0,0,0,17.107,39.6Z"
                    transform="translate(-4765 3401.355)"
                    className="icon-core"
                />
            </g>
        </g>
    </SvgIcon>
)

export default CircleContactInfo
