import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import { ThemeProvider } from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { offersActions } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { AppOfflineAlerter } from '@igs-web/common-components/domain/errors/app-offline-alerter'
import { ScreenSharePage } from '@igs-web/common-components/domain/genesys-co-browse/screen-share-page'
import { OffersContextProvider } from '@igs-web/common-components/domain/offers/context/offers-context'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { EncodedHtml } from '@igs-web/common-ui-components/_atoms/encode-html'
import { FullWidthMessageBanner } from '@igs-web/common-ui-components/_molecules/banners/message-banner'
import { StatefulLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { defaultTheme } from '@igs-web/common-ui-components/styles/theming/default-theme'
import { SiteMessage, getSiteMessage } from '@igs-web/common-utilities/services/site-message-service'

import 'root/global-error-logger'

import { ScanaFooter } from 'molecules/footer/footer'
import { MyAccountHeader } from 'molecules/header/header'
import { ProtectedRoute } from 'molecules/routing/protected-route'

import { LoginPage } from 'domain/authentication/login-page'
import { Checkout } from 'domain/checkout/gas-checkout/gas-checkout'
import { Confirmation } from 'domain/checkout/gas-checkout/gas-confirmation'
import { HwCheckoutPage } from 'domain/checkout/hw-checkout/hw-checkout'
import { HwEnrollmentConfirmationPage } from 'domain/checkout/hw-checkout/hw-confirmation-page'
import { ContactUsPage } from 'domain/contact-us/contact-us-page'
import { GasFAQPage } from 'domain/myaccount/gas-FAQ-page'
import { DeregulatedMyAccountRouter } from 'domain/navigation/deregulated-myaccount-routes'
import { InboundPaymentPage, PaymentCaptureDuration } from 'domain/payment/inbound-payment-page'
import { BillPayResponse } from 'domain/payment/pay-bill-response'
import * as Routes from 'domain/routes'

import { StyledApp } from './styles/app-styles'

const App = () => {
    const [siteMessage, setSiteMessage] = React.useState<null | SiteMessage>(null)
    const dispatch = useDispatch()
    const company = useCompany()
    dispatch(offersActions.updateOfferRequest({ companyName: company.name }))
    const isLoggedIn = useSelector(UserSelectors.selectLoggedIn)

    useEffectOnce(() => {
        const checkMessage = async () => {
            const message = await getSiteMessage(company)
            setSiteMessage(message)
        }
        checkMessage()
    })

    return (
        <StyledApp $maxWidth={Breakpoint.Desktop}>
            <StatefulLoader />
            <AppOfflineAlerter />
            <ThemeProvider theme={defaultTheme}>
                <OffersContextProvider>
                    {siteMessage && new Date(siteMessage.endDate) > new Date() && (
                        <FullWidthMessageBanner
                            id={siteMessage.id}
                            isDismissable={siteMessage.isDismissable}
                            endDate={siteMessage.endDate}
                            dataTestId="site-message-banner"
                        >
                            <EncodedHtml content={siteMessage.message} />
                        </FullWidthMessageBanner>
                    )}
                    <MyAccountHeader />
                    <Switch>
                        <Route exact={true} path={Routes.BillPaid}>
                            <BillPayResponse />
                        </Route>
                        <ProtectedRoute path={Routes.MyAccountRoute}>
                            <DeregulatedMyAccountRouter />
                        </ProtectedRoute>
                        <Route path={Routes.ContactUsRoutes}>
                            <ContactUsPage />
                        </Route>
                        <Route path={Routes.HelpRoute}>
                            <GasFAQPage />
                        </Route>
                        <Route path={Routes.RedirectSignupRoutes}>
                            <Redirect to={Routes.CheckoutRoute} />
                        </Route>
                        <Route path={Routes.CheckoutRoute}>
                            <Checkout />
                        </Route>
                        <Route path={Routes.ConfirmationRoute}>
                            <Confirmation />
                        </Route>
                        <Route path={Routes.ScreenShare}>
                            <ScreenSharePage />
                        </Route>
                        <Route path={Routes.HomeWarrantCheckout}>
                            <HwCheckoutPage />
                        </Route>
                        <Route path={Routes.HomeWarrantyConfirmation}>
                            <HwEnrollmentConfirmationPage />
                        </Route>
                        <Route path={Routes.OneTimePayment}>
                            <InboundPaymentPage frequency={PaymentCaptureDuration.OneTime} />
                        </Route>
                        <Route path={Routes.RecurringPayment}>
                            <InboundPaymentPage frequency={PaymentCaptureDuration.Recurring} />
                        </Route>
                        {isLoggedIn && <Route component={DeregulatedMyAccountRouter} />}
                        <Route component={LoginPage} />
                    </Switch>
                    <ScanaFooter />
                </OffersContextProvider>
            </ThemeProvider>
        </StyledApp>
    )
}

export default App
