import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { UserProfileAccount } from '@igs-web/common-models/models'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

import { CommonReduxState } from '../common-redux'
import { userActions, UserSelectors } from '../user/user-redux'

export const useAccountConnectionStatus = (partyAccountId?: number): string | null => {
    const dispatch = useDispatch()
    const [connectionStatus, setConnectionStatus] = React.useState<string | null>(null)
    const account = useSelector((state: CommonReduxState) =>
        UserSelectors.selectAccountByIdentifier(state, partyAccountId ? partyAccountId.toString() : ''),
    ) as UserProfileAccount

    //run useEffect after we get the account
    const hasAccount = !!account

    React.useEffect(() => {
        if (hasAccount) {
            if (account.accountConnectionStatus) {
                setConnectionStatus(account.accountConnectionStatus)
            } else {
                getAccountConnectionStatus()
            }
        }
    }, [hasAccount])

    const getAccountConnectionStatus = async () => {
        if (account && partyAccountId) {
            const accountConnectionStatusResult = await customerInfoApiClient.getAccountKeyAndConnectionStatus(account.accountId, {showGlobalLoader: false})
            if (accountConnectionStatusResult) {
                const { partyAccountId: accountId, accountConnectionStatus } = accountConnectionStatusResult
                setConnectionStatus(accountConnectionStatus)
                dispatch(userActions.setAccountConnectionStatus({ accountId, accountConnectionStatus }))
            }
        }
    }

    return connectionStatus
}
