import { HWCardDetails } from '@igs-web/common-components/domain/home-warranty/home-warranty-detail-card'
import { FeatureFlagModel } from '@igs-web/common-models/models/feature-flag-model'
import { PromoCodeInfo } from '@igs-web/common-models/models/promo-code'

import { SiteMessage } from '../services/site-message-service'

import { RequestConfig, apiClient } from './api-client'

class SitefinityApiClient {
    public readonly isAppOffline = async (config: RequestConfig) => {
        return await apiClient.get<boolean>(`${apiClient.sitefinityApiUrl}/data-api/MaintenanceMode/GetMaintenanceModeValue`, config)
    }

    public readonly isFeatureOn = async (feature, config: RequestConfig) => {
        return await apiClient.get<boolean>(`${apiClient.sitefinityApiUrl}/data-api/FeatureFlag/IsFeatureOn${feature ? '?feature=' + feature : ''}`, config)
    }

    public readonly getNoUtilityBillPromoCodes = async () => {
        return await apiClient.get<ReadonlyArray<string>>(`${apiClient.sitefinityApiUrl}/data-api/PromoCode/noutilitybill`)
    }

    public readonly getPromoCode = async (code, config: RequestConfig) => {
        return await apiClient.get<PromoCodeInfo>(`${apiClient.sitefinityApiUrl}/data-api/PromoCode/getpromocode?code=${code}`, config)
    }
    public readonly getFeatureFlags = async (config: RequestConfig) => {
        const cacheKey = 'featureFlags'
        return await apiClient.cachedGet<ReadonlyArray<FeatureFlagModel>>(`${apiClient.apiBaseUrl}/sf/featureflags`, cacheKey, false, config)
    }
    public readonly getSiteMessages = async (config: RequestConfig) => {
        return await apiClient.get<ReadonlyArray<SiteMessage>>(`${apiClient.apiBaseUrl}/sf/sitemessages`, config)
    }
    public readonly getHomeWarrantyDetails = async (config: RequestConfig) => {
        return await apiClient.get<ReadonlyArray<HWCardDetails>>(`${apiClient.apiBaseUrl}/sf/homewarrantydetails`, config)
    }
}

export const sitefinityApiClient = new SitefinityApiClient()
