/* eslint-disable max-len */
import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

const HomeWarrantyIcon = ({ altText = 'Home Warranty', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} id="Layer_1" viewBox="0 0 47.16 44.88" altText={altText}>
        <path d="M44.12,23.79,35.93,15.6v-11H30.19V9.87L24.68,4.35a1.57,1.57,0,0,0-2.2,0L3,23.79A1.57,1.57,0,0,0,3,26l1.47,1.46a1.54,1.54,0,0,0,2.19,0L8,26.16V38.25H5.93V41H41.5V38.25h-2V26.44l1,1a1.55,1.55,0,0,0,2.2,0L44.12,26A1.57,1.57,0,0,0,44.12,23.79ZM36.56,37.7H24.89a18.37,18.37,0,0,0,1.25-2c5.21-3.37,5.19-8.26,3.21-11.62a9.39,9.39,0,0,0-5.48-4.49c-1.57-.41-1.95-2.12-2.1-2.72,0-.15-.23-.08-.23-.08a29.87,29.87,0,0,0-1.79,2.7c-3.48,6-2.38,9.69-.65,12.21,1.47,2.15,2.6,2.24,3.37,2.89a10.39,10.39,0,0,0,2.21-4.86,13,13,0,0,0-1.78-8.08,12.14,12.14,0,0,1,3,7.89c-.07,3.45-2.15,6.22-4.31,8.18H10.88V23.27l11.6-11.6a1.57,1.57,0,0,1,2.2,0L36.56,23.55Z" />
    </SvgIcon>
)

export default HomeWarrantyIcon
