export const billingUtilities: { readonly [key: string]: BillingUtility } = {
    COH: {
        utilityCode: 'COH',
        disclaimer:
            'IGS Home Warranty and IGS Energy are not affiliates of Columbia Gas® of Ohio. ' +
            'Repair and maintenance plans are not regulated utility services. Plans are not provided or guaranteed ' +
            'by the utility, Columbia Gas® of Ohio. Your participation in, and your payments for, these service plans ' +
            'do not affect your utility service. Renters are not eligible.',
    },
}

interface BillingUtility {
    readonly utilityCode: string
    readonly disclaimer: string
}
