
import { Breakpoint, maxWidthQuery } from '@igs-web/common-ui-components/styles/breakpoints'
import { useMedia } from './use-media'

interface BreakpointDescription {
    readonly name: string
    readonly value: Breakpoint
    readonly ordinal: number
}

const breakpointDescriptions: ReadonlyArray<BreakpointDescription> = [
    {
        name: 'MobileSmall',
        value: Breakpoint.MobileSmall,
        ordinal: 0,
    },
    {
        name: 'Mobile',
        value: Breakpoint.Mobile,
        ordinal: 1,
    },
    {
        name: 'Tablet',
        value: Breakpoint.Tablet,
        ordinal: 2,
    },
    {
        name: 'Desktop',
        value: Breakpoint.Desktop,
        ordinal: 3,
    },
    {
        name: 'LargeDesktop',
        value: Breakpoint.LargeDesktop,
        ordinal: 4,
    },
]

const getDescription = (breakpoint: Breakpoint): BreakpointDescription => {
    const description = breakpointDescriptions.find(b => b.value === breakpoint)
    if (!description) {
        throw new Error(`Invalid breakpoint ${breakpoint}`)
    }
    return description
}

export const useBreakpoints = (): UseBreakpointsResult => {
    const queries = breakpointDescriptions.map(b => maxWidthQuery(b.value))
    const values = breakpointDescriptions.map(b => b)
    const largestBreakpoint = getDescription(Breakpoint.LargeDesktop)

    const currentBreakpoint = useMedia<BreakpointDescription>(queries, values, largestBreakpoint)

    const lessThan = (breakpoint: Breakpoint) => currentBreakpoint.ordinal < getDescription(breakpoint).ordinal
    const lessThanOrEqualTo = (breakpoint: Breakpoint) => currentBreakpoint.ordinal <= getDescription(breakpoint).ordinal
    const equalTo = (breakpoint: Breakpoint) => currentBreakpoint.ordinal === getDescription(breakpoint).ordinal
    const greaterThanOrEqualTo = (breakpoint: Breakpoint) => currentBreakpoint.ordinal >= getDescription(breakpoint).ordinal
    const greaterThan = (breakpoint: Breakpoint) => currentBreakpoint.ordinal > getDescription(breakpoint).ordinal

    return {
        lessThan,
        lessThanOrEqualTo,
        equalTo,
        greaterThanOrEqualTo,
        greaterThan,
    }
}

interface UseBreakpointsResult {
    readonly lessThan: (breakpoint: Breakpoint) => boolean
    readonly lessThanOrEqualTo: (breakpoint: Breakpoint) => boolean
    readonly equalTo: (breakpoint: Breakpoint) => boolean
    readonly greaterThanOrEqualTo: (breakpoint: Breakpoint) => boolean
    readonly greaterThan: (breakpoint: Breakpoint) => boolean
}
