import { HomeProtectionPaymentProfileResponse } from '@igs-web/common-models/models/account-detail-model'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

import { CachedReduxDataStateType, setupCachedReduxData } from '../cached-redux/cached-redux-data'

const load = async (accountId: number, config: RequestConfig): Promise<HomeProtectionPaymentProfileResponse | undefined> => {
    try {
        return (await customerInfoApiClient.getHpPaymentProfile(accountId, config)) || undefined
    } catch (e) {
        console.error('There was a problem getting payment profile')
    }
}

const { actions, selectors, saga, reducers } = setupCachedReduxData('homeWarrantyPaymentProfile', load)

export const homeWarrantyPaymentProfileRedux = { actions, selectors }

export const homeWarrantyPaymentProfileSaga = saga
export const homeWarrantyPaymentProfileReducer = reducers

export type homeWarrantyPaymentProfileState = CachedReduxDataStateType<typeof load>
