import { CreditCardType } from '@igs-web/common-models/constants/credit-card-type'

import { LineOfBusinessCode } from './line-of-business'

export class PaymentOption {
    public readonly name: string
    public readonly paymentType: PaymentType
    public readonly existing: boolean
    public readonly payment?: PaymentMethodModel
    public readonly id: string
}

export type PaymentMethodModel = CreditCardFields | BankAccountFields | UtilityBillFields

interface PaymentBase {
    readonly paymentType: PaymentType
    readonly saveAsRecurringPayment?: boolean
}

export interface CreditCardFields extends PaymentBase {
    readonly cardType: CreditCardType
    readonly name: string
    readonly cardNumber: string
    readonly expirationYear: string
    readonly expirationMonth: string
    readonly securityCode: string
    readonly zipCode: string
}

export interface BankAccountFields extends PaymentBase {
    readonly name: string
    readonly bankAccountNumber: string
    readonly bankRoutingNumber: string
    readonly zipCode?: string
}

export interface UtilityBillFields extends PaymentBase {
    readonly billingUtilityCode: string
    readonly billingLineOfBusinessCode: LineOfBusinessCode
    readonly billingUtilityAccountNumber: string
}

export enum PaymentType {
    CreditCard = 'Credit Card',
    Ach = 'ACH',
    UtilityBill = 'Utility Bill',
}

export const creditCardId = 'cc-new'
export const achId = 'ach-new'
export const utilityBillId = 'utility-bill-new'
