import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FaCheck, FaExclamationCircle, FaRegCreditCard } from 'react-icons/fa'
import { useSelector } from 'react-redux'

import { CheckoutStepProps } from '@igs-web/common-components/domain/checkout/checkout-models'
import { useCheckoutDispatch, useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { CreditCheckFormStep } from '@igs-web/common-components/domain/checkout/steps/residential-deposit/credit-check'
import { StepNav } from '@igs-web/common-components/domain/checkout/steps/step-nav'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { useWizardDispatch } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { CreditCheckResultCode, CreditCheckStatus } from '@igs-web/common-utilities/api/enrollment-api-client'
import { getEventModel, pushCreditCheckEvent } from '@igs-web/common-utilities/services/google-analytics-services'

import { useCheckoutWizardMessagesDispatch } from '../checkout-wizard-messages/checkout-wizard-messages-context'
import { SuccessHeading, WarningHeading } from '../shared/checkout-components'

export const CreditCheckStep = ({ stepFwdButtonText, checkIsSelectedOfferExpired }: CreditCheckStepProps) => {
    const { phoneNumber, businessHours } = useCompany()
    const enrollmentState = useCheckoutState()
    const { setEnrollmentState } = useCheckoutDispatch()
    const { creditCheckResults, defaultContact } = enrollmentState
    const { nextPage } = useWizardDispatch()
    const { handleSubmit } = useForm()
    const { setShowServerErrorMessage } = useCheckoutWizardMessagesDispatch()
    const selectedOffers = useSelector(OfferSelectors.selectSelectedOffers)
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    React.useLayoutEffect(() => {
        if (
            creditCheckResults &&
            (creditCheckResults.energyScoreStatus === CreditCheckStatus.Waive || creditCheckResults.energyScoreStatus === CreditCheckStatus.Collect) &&
            (creditCheckResults.contact?.firstName !== defaultContact.firstName ||
                creditCheckResults.contact?.lastName !== defaultContact.lastName ||
                creditCheckResults.contact?.middleInitial !== defaultContact.middleInitial)
        ) {
            setEnrollmentState({
                ...enrollmentState,
                creditCheckResults: undefined,
            })
        }
    }, [])

    React.useEffect(() => {
        if (creditCheckResults) {
            window.scrollTo(0, 0)
        }
    }, [creditCheckResults])

    const handleServerError = () => {
        setShowServerErrorMessage(true)
    }

    const onSubmit = async () => {
        const isSelectedOfferExpired = await checkIsSelectedOfferExpired()
        if (!isSelectedOfferExpired) {
            pushCreditCheckEvent(getEventModel(selectedOffers), nextPage)
        }
    }

    return (
        <>
            <IconHeading data-testid="credit-check-header">
                <FaRegCreditCard /> Credit Check
            </IconHeading>
            {creditCheckResults === undefined && (
                <>
                    <p>
                        We use Equifax to determine service eligibility and deposit requirements. If you meet our credit requirements we will waive the $150
                        deposit.
                    </p>

                    <p>I understand by clicking continue, I authorize SCANA Energy to check my credit.</p>
                    <CreditCheckFormStep
                        stepFwdButtonText="CONTINUE"
                        advanceOnSubmit={false}
                        checkIsSelectedOfferExpired={checkIsSelectedOfferExpired}
                        styleStepBackAsButton={true}
                        onServerError={handleServerError}
                        beforeSubmit={() => setShowServerErrorMessage(false)}
                    />
                </>
            )}
            {creditCheckResults?.energyScoreStatus === CreditCheckStatus.Waive && creditCheckResults?.resultCode !== CreditCheckResultCode.ItpaHold && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <GridSpacer spacing={Spacing.Large} />
                    <SuccessHeading>
                        <FaCheck /> Success
                    </SuccessHeading>
                    <span data-testid="credit-check-result-message">
                        <p>No security deposit is required</p>
                    </span>
                    <StepNav stepFwdButtonText={stepFwdButtonText} styleStepBackAsButton={true} />
                </form>
            )}
            {creditCheckResults?.energyScoreStatus === CreditCheckStatus.Collect &&
                !!creditCheckResults?.depositAmount &&
                creditCheckResults?.depositAmount > 0 && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <GridSpacer spacing={Spacing.Large} />
                        <WarningHeading>
                            <FaExclamationCircle /> Completed
                        </WarningHeading>
                        <span data-testid="credit-check-result-message">
                            <p>
                                A deposit of ${creditCheckResults.depositAmount} is required to continue with service enrollment. It will be refunded to your
                                account after 6 consecutive on-time payments. You will be prompted to pay this deposit after your order is completed.
                            </p>
                            <p>
                                You can also pay over the phone by calling <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />, Select Pay Bill by
                                Phone to make payments 24 hours a day.
                            </p>
                            <p>You may also visit one of our walk-in locations to make a payment in person.</p>
                        </span>
                        <StepNav stepFwdButtonText={stepFwdButtonText} styleStepBackAsButton={true} />
                    </form>
                )}
            {creditCheckResults?.energyScoreStatus === CreditCheckStatus.Deny && (
                <span data-testid="credit-check-result-message">
                    <p>
                        Thank you for your interest in receiving natural gas service with SCANA Energy. Based on the information obtained in a consumer credit
                        report, we are unable to provide you service at this time. As an alternative, SCANA Energy Regulated Division can assist you with
                        obtaining service. Please call <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />, {businessHours.businessHoursFull}.
                    </p>
                </span>
            )}
            {creditCheckResults && creditCheckResults.energyScoreStatus === CreditCheckStatus.DenyDueToCustomerBalance && (
                <>
                    <GridSpacer spacing={Spacing.Large} />
                    <WarningHeading>
                        <FaExclamationCircle /> Completed
                    </WarningHeading>
                    <span data-testid="credit-check-result-message">
                        <p>
                            There is an issue with your enrollment. To complete your enrollment, please call{' '}
                            <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />, {businessHours.businessHoursFull}.
                        </p>
                    </span>
                </>
            )}
            {creditCheckResults?.resultCode === CreditCheckResultCode.ItpaHold && (
                <span data-testid="credit-check-result-message">
                    <p>
                        We received a message from Equifax stating you have placed an Identity Theft Prevention Alert on your credit file. Because you have
                        placed this alert on your file, Federal Law requires that we take steps to verify your identity. Please call{' '}
                        <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />, {businessHours.businessHoursFull}.
                    </p>
                </span>
            )}
            {creditCheckResults?.resultCode === CreditCheckResultCode.NoHit && (
                <span data-testid="credit-check-result-message">
                    <p>
                        There is a discrepancy in the information provided. It will be necessary for you to contact Equifax to resolve the issue. If this is due
                        to a security freeze you have placed on your account, call <PhoneNumberLink phoneNumber="18003499960" />; otherwise, call{' '}
                        <PhoneNumberLink phoneNumber="18883784329" />. Once the issue is resolved with Equifax, please call us at{' '}
                        <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} /> so that we can proceed with your request for service.
                    </p>
                </span>
            )}
        </>
    )
}

interface CreditCheckStepProps extends CheckoutStepProps {
    readonly checkIsSelectedOfferExpired: () => Promise<boolean>
}
