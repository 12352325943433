import { MyAccountState } from '@igs-web/common-components/domain/myaccount-redux'

import { SubscriptionPaymentHistoryState } from './billing/subscription-payment-history-redux'
import { SubscriptionUpcomingPaymentsState } from './billing/subscription-upcoming-payments-redux'
import { BudgetBillingState } from './budget-billing/budget-billing-redux'
import { ClaimsState } from './claims/claims-redux'
import { CoverageState } from './coverage/coverage-redux'
import { AccountDocumentsState } from './documents/account-documents-redux'
import { SubscriptionDocumentsState } from './documents/subscription-documents-redux'
import { ConfirmationInfo } from './enrollment/confirmation/confirmation-redux'
import { EnrollmentReduxState } from './enrollment/enrollment-redux'
import { OfferState } from './enrollment/offer-redux'
import { OWVState } from './enrollment/owv-redux'
import { HwBillingHistoryState } from './hw-billing-history/hw-billing-history-redux'
import { OrganizationAgentState } from './myaccount/billing-accounts/organization-agent-redux'
import { FiserveAutopayState } from './myaccount/fiserv-autopay-redux'
import { LegacyBillState } from './myaccount/legacy-data/legacy-bills-redux'
import { LegacyPaymentState } from './myaccount/legacy-data/legacy-payments-redux'
import { LegacyUsageState } from './myaccount/legacy-data/legacy-usage-redux'
import { NotificationState } from './notification/notification-redux'
import { homeWarrantyPaymentProfileState } from './payment-profile/hw-payment-profile-redux'
import { HistoricRatesState } from './rates/historic-rates-redux'
import { TransactionHistoryState } from './transaction-history/transaction-history-redux'
import { ModalState } from './ui-state/modal-redux'
import { UlobState } from './ulob/ulob-redux'
import { UsageState } from './usage/usage-redux'
import { UserState } from './user/user-redux'
import { UtilityAccountActivityState } from './utility-account/utility-account-activity-redux'
import { UtilityAccountDetailsState } from './utility-account/utility-account-details-redux'
import { IsAutoRenewState } from './myaccount/auto-renew-redux'

export interface CommonReduxState {
    readonly accountActivity: UtilityAccountActivityState
    readonly accountDocuments: AccountDocumentsState
    readonly claims: ClaimsState
    readonly coverage: CoverageState
    readonly hwBillingHistory: HwBillingHistoryState
    readonly enrollment: CombinedEnrollmentReduxState
    readonly historicRates: HistoricRatesState
    readonly homeWarrantyPaymentProfile: homeWarrantyPaymentProfileState
    readonly modal: ModalState
    readonly myaccount: MyAccountState
    readonly notification: NotificationState
    readonly transactionHistory: TransactionHistoryState
    readonly ulob: UlobState
    readonly usage: UsageState
    readonly user: UserState
    readonly utilityAccountDetails: UtilityAccountDetailsState
    readonly onlineWebVerificationDetails: OWVState
    readonly legacyUsage: LegacyUsageState
    readonly legacyBills: LegacyBillState
    readonly legacyPayments: LegacyPaymentState
    readonly subscriptionDocuments: SubscriptionDocumentsState
    readonly subscriptionPaymentHistory: SubscriptionPaymentHistoryState
    readonly subscriptionUpcomingPayments: SubscriptionUpcomingPaymentsState
    readonly organizationAgents: OrganizationAgentState
    readonly budgetBilling: BudgetBillingState
    readonly fiserveAutopay: FiserveAutopayState
    readonly isAutoRenew: IsAutoRenewState
}

interface CombinedEnrollmentReduxState {
    readonly offer: OfferState
    readonly enrollmentCore: EnrollmentReduxState
    readonly confirmation: ConfirmationInfo
}

export const filterSensitiveEnrollmentData = (state: CombinedEnrollmentReduxState): CombinedEnrollmentReduxState => ({
    ...state,
    enrollmentCore: {
        ...state.enrollmentCore,
        autopayPayment: undefined,
        depositPayment: undefined,
        shoppingCartItems: state.enrollmentCore.shoppingCartItems.map(i => ({
            ...i,
            payment: undefined,
        })),
    },
})
