import * as React from 'react'
import styled from 'styled-components'

import { Select } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { Party } from '@igs-web/common-models/models/party'
import { SelectOption } from '@igs/react-styled-components'

const StyledSelect = styled(Select)`
    select:focus {
        ~ label {
            display: none;
        }
    }
    option:empty {
        display: none;
    }
`

export const PartySelector = ({ parties, setSelectedPartyNumber }: Props) => {
    const [hideLabel, setHideLabel] = React.useState<boolean>(false)
    return (
        <StyledSelect
            dataTestId={'change-party-select'}
            onChange={e => {
                if (e.currentTarget.value !== '') {
                    setSelectedPartyNumber(e.currentTarget.value)
                    setHideLabel(true)
                }
            }}
            label={hideLabel ? '' : 'Select A Party'}
        >
            {parties.map((party, i) => {
                const { partyName, partyNumber } = party
                return (
                    <SelectOption key={`change-party${i}`} value={partyNumber} dataTestId={`change-party-selector${partyNumber}`}>
                        {partyName} - {partyNumber}
                    </SelectOption>
                )
            })}
        </StyledSelect>
    )
}

interface Props {
    readonly parties: ReadonlyArray<Party>
    readonly setSelectedPartyNumber: React.Dispatch<React.SetStateAction<string>>
}
