import { AccountDocument } from '@igs-web/common-models/models/document'
import { ActionLink } from '@igs-web/common-ui-components/_atoms/link/action-link'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { downloadBlob } from '@igs-web/common-utilities/utilities/document-utilities'
import * as React from 'react'

const downloadDocument = async (doc: AccountDocument): Promise<void> => {
    if (!doc.documentRoutingId && !doc.documentUrl) {
        return
    }

    const formattedUrl = doc.documentUrl ? doc.documentUrl.replace('http://', 'https://') : ''
    const getDocumentAction = doc.documentRoutingId ? customerInfoApiClient.getDocument(doc) : customerInfoApiClient.getDocumentByUrl(formattedUrl)
    const blob = await getDocumentAction
    const filename = doc.displayName
    downloadBlob(blob, filename, doc.filetype)
}

export const AccountDocumentLink = ({ document }: AccountDocumentLinkProps): JSX.Element => {
    return (
        <ActionLink
            onClick={(): void => {
                downloadDocument(document)
            }}
        >
            {document.displayName}
        </ActionLink>
    )
}

interface AccountDocumentLinkProps {
    readonly document: AccountDocument
}
