import { Usage } from '@igs-web/common-models/models/usage'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

import { CachedReduxDataStateType, setupCachedReduxData } from '../cached-redux/cached-redux-data'

const load = async (accountId: number, config: RequestConfig): Promise<ReadonlyArray<Usage> | undefined> => {
    try {
        const response = await customerInfoApiClient.getUsagesByAccountId(accountId, config)

        if (!response) {
            throw Error(`Usage is not defined for ${accountId}`)
        }

        return response.usageResponseItems.map(u => ({
            ...u,
            fromDate: u.fromDate ? Date.parse(u.fromDate) : undefined,
            toDate: Date.parse(u.toDate),
            meterCycleMonth: u.meterCycleMonth ? Date.parse(u.meterCycleMonth) : undefined,
        }))
    } catch (e) {
        console.error(`There was a problem getting usage: ${e.message}`)
    }
}

const { actions, selectors, saga, reducers } = setupCachedReduxData('usage', load)

export const usageRedux = { actions, selectors }
export const usageSaga = saga
export const usageReducer = reducers
export type UsageState = CachedReduxDataStateType<typeof load>
