import * as React from 'react'
import { MdLocationOn } from 'react-icons/md'

import styled from 'styled-components'

import { Address } from '@igs-web/common-models/models'
import { AddressVerifyResponse } from '@igs-web/common-models/models/address-verify-response'
import { ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { Card } from '@igs-web/common-ui-components/_atoms/card'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { AddressSummary } from '@igs-web/common-ui-components/_molecules/address/address-summary'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { SummarySelector } from 'domain/checkout/shared/checkout-components'

import { AddressSearchResultsWrapper, SmallButton } from '../../shared/checkout-components'

const AddressResultsWrapper = styled.div`
    display: grid;
    grid-row-gap: ${Spacing.Large};
`

export const HwAddressSearchResults = ({ searchResults, selectAddress }: props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <AddressResultsWrapper>
            <IconHeading>
                <MdLocationOn /> Verify Address
            </IconHeading>
            <AddressSearchResultsWrapper>
                <Card>
                    <SummarySelector>
                        <div>
                            <AddressSummary address={searchResults.verifiedAddress} title="Did you mean: " dataTestIdPrefix="hw-service-address-corrected-" />
                        </div>
                        <SmallButton
                            onClick={() => selectAddress(searchResults.verifiedAddress)}
                            dataTestId="hw-service-address-select-corrected-address-button"
                        >
                            Use This Address
                        </SmallButton>
                    </SummarySelector>
                </Card>
                <Card>
                    <SummarySelector>
                        <div>
                            <AddressSummary address={searchResults.originalAddress} title="You entered: " dataTestIdPrefix="hw-service-address-original-" />
                        </div>
                        <SmallButton
                            buttonStyle={ButtonStyleType.Secondary}
                            onClick={() => selectAddress(searchResults.originalAddress)}
                            dataTestId="hw-service-address-select-orginal-address-button"
                        >
                            Use This Address
                        </SmallButton>
                    </SummarySelector>
                </Card>
            </AddressSearchResultsWrapper>
        </AddressResultsWrapper>
    )
}

interface props {
    readonly searchResults: AddressVerifyResponse
    readonly selectAddress: (address: Address) => void
}
