import * as React from 'react'
import styled from 'styled-components'

import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { lightLight, primaryLight } from '@igs-web/common-ui-components/styles/theme'
import { formatPhoneNumberForDisplay } from '@igs-web/common-utilities/utilities/phone-utilities'

//TODO: A lot of css in here is hard coded and should be driven from themes, replace it with proper theme variables
export const FooterContainer = styled.div`
    border-top: 10px solid ${primaryLight};
    padding: ${Spacing.Medium};
    display: grid;
    grid-template-columns: 1fr ${Breakpoint.LargeDesktop} 1fr;
    grid-template-rows: auto;
    row-gap: ${Spacing.Medium};
    > * {
        grid-column: 2;
    }
    && {
        grid-column: 1/-1;
    }
    @media (max-width: 600px) {
        padding: ${Spacing.Medium} 0;
    }
    @media (max-width: ${Breakpoint.LargeDesktop}) {
        grid-template-columns: 1fr;
        max-width: 100%;
        > * {
            grid-column: 1;
            max-width: 100%;
        }
    }
`
export const BusinessInfoContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-between;
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        grid-row-gap: ${Spacing.Medium};
        text-align: center;
        justify-items: center;
    }
`

const StyledEmergencyFooter = styled.div`
    width: 100%;
    background: black;
    min-height: 160px;
    font-size: 36px;
    line-height: 36px;
    color: white;
    font-weight: 600;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, auto);
    justify-content: center;
    column-gap: ${Spacing.Medium};
    grid-column: -1/1;

    @media (max-width: 1024px) {
        grid-template-columns: 100%;
        text-align: center;
        grid-row-gap: ${Spacing.Medium};
        padding: ${Spacing.Medium} 0;
    }
`
const GasLeakMessage = styled.span`
    color: #ffca1f;
`
const GasLeakWho = styled.span`
    font-size: 20px;
    line-height: 20px;
`
const StyledPhoneNumberLink = styled(PhoneNumberLink)`
    color: ${lightLight};
`

export const EmergencyFooter = ({ phoneNumber }: EmergencyFooterProps) => (
    <StyledEmergencyFooter>
        <GasLeakMessage>Gas Leaks &amp; Emergencies</GasLeakMessage> <GasLeakWho>Call Atlanta Gas Light</GasLeakWho>{' '}
        <StyledPhoneNumberLink phoneNumber={phoneNumber}>{formatPhoneNumberForDisplay(phoneNumber)}</StyledPhoneNumberLink>
    </StyledEmergencyFooter>
)
interface EmergencyFooterProps {
    readonly phoneNumber: string
}
