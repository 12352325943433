import * as React from 'react'
import { BsFillExclamationCircleFill } from 'react-icons/bs'

import styled from 'styled-components'

import { AlertCard } from '@igs-web/common-components/domain/alerts/alert-card'
import { ColorwayContainer, ColorwayType } from '@igs-web/common-ui-components/_molecules/theming/colorway-container'
import { secondaryDark } from '@igs-web/common-ui-components/styles/theme'

const ExclamationPointIcon = styled(BsFillExclamationCircleFill)`
    background-color: ${secondaryDark};
`

export const RenewalAlert = () => {
    return (
        <ColorwayContainer colorwayType={ColorwayType.RustOrange}>
            <AlertCard dataTestId="renewal-alert">
                <span>
                    <ExclamationPointIcon />
                </span>
                It's time to renew your rate plan. Compare your options below.
            </AlertCard>
        </ColorwayContainer>
    )
}
