import * as React from 'react'
import { useForm } from 'react-hook-form'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { LegalRoutes } from '@igs-web/common-components/domain/legal/legal-routes'
import { useMyAccountDashboard } from '@igs-web/common-components/domain/myaccount/dashboard-context'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { FormWrapper } from '@igs-web/common-ui-components/_atoms/form-wrapper'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { EmailLink } from '@igs-web/common-ui-components/_atoms/link/email-link'
import { ExternalLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { RouterLinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Header4 } from '@igs-web/common-ui-components/_atoms/typography'
import { Textarea } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { AddressDisplay } from '@igs-web/common-ui-components/_molecules/address/address-display'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText } from '@igs-web/common-ui-components/_molecules/info-text'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

import { NavigationBanner } from 'molecules/header/navigation-banner'
import { ScrollToTop } from 'molecules/routing/scroll-to-top'

import { useHwMyaccountState } from 'domain/home-warranty/hw-myaccount-context'
import { HwMyAccountAddressSelector } from 'domain/home-warranty/hw-service-address-selector'
import { getSelectedHwAccount } from 'domain/home-warranty/scana-hw-service'
import * as Routes from 'domain/routes'

const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: ${Spacing.Medium};
    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-columns: 1fr;
        grid-row-gap: ${Spacing.Large};
    }
`
const StyledAddressSelector = styled(HwMyAccountAddressSelector)`
    grid-template-columns: 1fr;
`

const StyledForm = styled(FormWrapper)`
    ${Textarea} {
        min-height: 150px;
    }
`

export const HwServiceRequestsPage = () => {
    const { profile } = useMyAccountDashboard()
    const { selectedAddressHash } = useHwMyaccountState()
    const { phoneNumber } = useCompany()
    const selectedAccount = getSelectedHwAccount(profile, selectedAddressHash)
    const selectedAddress = profile.serviceAddresses.find(s => s.addressHash === selectedAddressHash)
    const [custServiceRequest, setCustServiceRequest] = React.useState<string>('')
    const company = useCompany()

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<ServiceRequestFormFields>()

    const onSubmit = async (data: ServiceRequestFormFields) => {
        try {
            await customerInfoApiClient.sendHPClaim(selectedAccount.accountId, data.serviceRequest)
            scrollTo(0, 0)
            setCustServiceRequest(data.serviceRequest)
        } catch (e) {
            setError('serviceRequest', { type: 'custom', message: 'Submission failed' })
        }
    }
    if (selectedAccount && custServiceRequest) {
        return (
            <>
                <ScrollToTop />
                <NavigationBanner title="Home Warranty - Service Requests" />
                <GridSpacer spacing={Spacing.Large} />
                <PageContentContainer>
                    <ContentContainer>
                        <div>
                            <Header4>Your service request has been submitted</Header4>
                            <p>
                                <strong>We will be in touch soon.</strong>
                            </p>
                            <p>Your concerns are important to us and we know that when it comes to protecting your home, you want a quick response.</p>
                            <p>
                                One of our home warranty experts will contact you via your preferred method within one business day, during normal business
                                hours, {company.businessHours.homwWarrantyAbbreviated}.
                            </p>
                            <p>
                                <strong>We look forward to assisting you with your home service needs.</strong>
                            </p>
                            <strong>Service Address:</strong>
                            <AddressDisplay address={selectedAddress} />
                            <GridSpacer spacing={Spacing.Medium} />
                            <strong>Service Request:</strong>
                            <div>{custServiceRequest}</div>
                            <GridSpacer spacing={Spacing.Large} />
                            <RouterLinkButton to={Routes.HwAccountSummary} dataTestId="service-request-back-to-account-summary">
                                Back to Account Summary
                            </RouterLinkButton>
                        </div>
                        <ContactUsSideBar />
                    </ContentContainer>
                </PageContentContainer>
                <GridSpacer spacing={Spacing.Large} />
            </>
        )
    }
    if (selectedAccount?.status === AccountStatus.active) {
        return (
            <>
                <ScrollToTop />
                <NavigationBanner title="Home Warranty - Service Requests" />
                <GridSpacer spacing={Spacing.Large} />
                <PageContentContainer>
                    <ContentContainer>
                        <div>
                            <IconHeading>Make a Service Request</IconHeading>
                            <p>
                                If you're having an issue with a protected item, or an item you're not sure is protected, complete the form below. Use as many
                                details as possible to describe your issue. A specialist will contact you within 1 business day to let you know whether your
                                issue is protected and, if so, start the service request process.
                            </p>
                            <p>
                                You can view your <ExternalLink href={LegalRoutes.homeWarrantyServiceAgreement().toString()}>Service Agreement</ExternalLink>{' '}
                                for your plan details.
                            </p>
                            <GridSpacer spacing={Spacing.Large} />
                            <StyledAddressSelector dropdownHeaderText="Select an address to view account details:" showInactive={false} />
                            <GridSpacer spacing={Spacing.Large} />
                            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                                <Textarea
                                    placeholder="Enter a Description of your Issue"
                                    dataTestId="service-request-text-area"
                                    {...register('serviceRequest', { required: 'Required' })}
                                    hideHelperText={!errors.serviceRequest}
                                    error={errors.serviceRequest?.message}
                                />
                                <div>
                                    <Button type="submit">Submit Request</Button>
                                </div>
                            </StyledForm>
                            <GridSpacer spacing={Spacing.Large} />
                            <p>
                                <strong>Please Note:</strong> A representative will contact you within 1 business day to ensure your issue is protected, and if
                                so, will get the service request process started. If you need to report an issue outside of business hours (
                                {company.businessHours.homwWarrantyAbbreviated}) please call <PhoneNumberLink phoneNumber={phoneNumber.homeWarranty} />, and we
                                will transfer you to our 24-hour emergency line.
                            </p>
                        </div>
                        <ContactUsSideBar />
                    </ContentContainer>
                </PageContentContainer>
            </>
        )
    }
    if (!selectedAccount || selectedAccount.status === AccountStatus.inactive) {
        return (
            <>
                <ScrollToTop />
                <NavigationBanner title="Home Warranty - Service Requests" />
                <GridSpacer spacing={Spacing.Large} />
                <PageContentContainer>
                    <ContentContainer>
                        <div>
                            <InfoText>You do not currently have a Home Warranty plan.</InfoText>
                        </div>
                        <ContactUsSideBar />
                    </ContentContainer>
                </PageContentContainer>
                <GridSpacer spacing={Spacing.Large} />
            </>
        )
    }
    return <></>
}

const ContactUsSideBar = () => {
    const { phoneNumber, emails } = useCompany()
    return (
        <div>
            <IconHeading>Contact Us</IconHeading>
            <p>Feel free to call us or email us directly, we are here to help.</p>
            <p>
                <strong>Customer Service:</strong>
                <br />
                <PhoneNumberLink phoneNumber={phoneNumber.homeWarranty} />
            </p>
            <p>
                <strong>Email:</strong>
                <br />
                <EmailLink toAddress={emails.homeWarranty} />
            </p>
        </div>
    )
}

interface ServiceRequestFormFields {
    readonly serviceRequest: string
}
