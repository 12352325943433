import * as React from 'react'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { getIpAddress } from '@igs-web/common-utilities/api/ip-address-client'
import { getLocalDateTimestamp } from '@igs-web/common-utilities/utilities/date-utilities'

export const ReviewOrderLegalese = ({ token }: ReviewOrderLegaleseProps) => {
    const { name } = useCompany()
    const ipAddress = getIpAddress()
    const electronicAgreementNumber = token.split('-').pop()

    return (
        <>
            <p>
                <strong>Date:</strong> {getLocalDateTimestamp()}
            </p>
            {ipAddress && (
                <p>
                    <strong>IP Address:</strong> {ipAddress}
                </p>
            )}
            <p>
                <strong>Electronic Agreement Number:</strong> {electronicAgreementNumber}
            </p>
            <p>
                The utility remains responsible for the delivery of electric power and natural gas to my premise and will continue to respond to any service
                calls or emergencies.
            </p>
            <p>You will receive written notification from the utility confirming a switch to {name}.</p>
            <p>
                {name} is an independent seller of electricity and is not representing, endorsed by, acting on behalf of, a utility or a utility program, a
                consumer group or consumer group program, or a governmental body or a program of governmental body.
            </p>
        </>
    )
}

interface ReviewOrderLegaleseProps {
    readonly token: string
}
