import * as React from 'react'
import { useLocation } from 'react-router-dom'

const isSelectorValid = (dummyElement => selector => {
    try {
        dummyElement.querySelector(selector)
    } catch {
        return false
    }
    return true
})(document.createDocumentFragment())

export const ScrollToTop = () => {
    const { pathname, hash } = useLocation()

    React.useEffect(() => {
        if (hash && isSelectorValid(hash)) {
            const element = document.querySelector(hash)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            } else {
                window.scroll({ top: 0, left: 0, behavior: 'smooth' })
            }
        } else {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' })
        }
    }, [pathname, hash])

    return null
}
