import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

import { CachedReduxDataStateType, setupCachedReduxData } from '../cached-redux/cached-redux-data'

const load = async (accountId: number, config: RequestConfig) => {
    try {
        return (await customerInfoApiClient.getTransactionHistory(accountId, config)) || undefined
    } catch (e) {
        throw Error(`Unable to load transaction history for account: ${accountId}`)
    }
}

const { actions, selectors, saga, reducers } = setupCachedReduxData('transactionHistory', load) as any

export const transactionHistoryRedux = {
    actions,
    selectors: {
        ...selectors,
        selectPastDueBalance: (state: CommonReduxState, accountId: number): number => {
            const activity = selectors.selectData(state, accountId)
            return (activity && activity.pastDueBalance) || 0
        },
        selectInvoices: (state: CommonReduxState, accountId: number) => {
            const data = selectors.selectData(state, accountId)
            return (data && data.invoices) || []
        },
        selectPayments: (state: CommonReduxState, accountId: number) => {
            const data = selectors.selectData(state, accountId)
            return (data && data.payments) || null
        },
        selectIsLoading: (state: CommonReduxState): boolean => {
            const data = state.transactionHistory.isLoading
            return Object.values(data).some(v => v)
        },
    },
}

export const transactionHistorySaga = saga
export const transactionHistoryReducer = reducers
export type TransactionHistoryState = CachedReduxDataStateType<typeof load>
