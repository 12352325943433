/* eslint-disable max-len */
import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

const LightingIcon = ({ altText = 'Lighting', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} id="Layer_1" viewBox="0 0 47.16 44.88" altText={altText}>
        <path d="M30.29,33.61a4.82,4.82,0,0,1-3.68,5.22V40.1h0a4.09,4.09,0,0,1-2,1.73,1.47,1.47,0,0,1-2.08,0,4.09,4.09,0,0,1-2-1.73h0V38.83a4.82,4.82,0,0,1-3.68-5.22.69.69,0,0,1,.66-.57H29.64A.68.68,0,0,1,30.29,33.61Zm-6.71-31A14,14,0,0,0,14.25,27c2,2,2.74,3.26,2.75,4.59l-.09,0h5.27a17.67,17.67,0,0,0,3.46-5.5,6.67,6.67,0,0,0,3.09-8.28A7.78,7.78,0,0,0,25,13.32a2.79,2.79,0,0,1-1.27-2.58c0-.13-.18-.1-.18-.1a22.72,22.72,0,0,0-1.9,1.92c-3.82,4.3-3.54,7.54-2.53,9.89s2.21,2.27,2.6,3.49a8.65,8.65,0,0,0,2.43-3.78,10.86,10.86,0,0,0-.52-6.88,10.08,10.08,0,0,1,1.56,6.87c-.53,3.25-3,5.79-5.05,7.43a13.08,13.08,0,0,0-3.47-5l-.08-.07a10.55,10.55,0,1,1,14,0l-.11.09c-2.27,2.3-3.79,4.38-3.79,7.09h3.44c0-1.34.75-2.6,2.76-4.63A14,14,0,0,0,23.58,2.62Z" />
    </SvgIcon>
)

export default LightingIcon
