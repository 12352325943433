import * as React from 'react'
import styled from 'styled-components'

import { PlanRateContainer, PlanRateSection } from '@igs-web/common-components/domain/plan-at-a-glance/plan-rate-section'
import { UserProfileAccount } from '@igs-web/common-models/models/user-profile-model'
import { Header4 } from '@igs-web/common-ui-components/_atoms/typography'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

const StyledHwAccount = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 'planRate';
    grid-row-gap: ${Spacing.Medium};
    font-size: 1rem;
    ${Header4} {
        margin-block-start:0;
    }
    
`

export const ActiveHwAccount = ({ account }: Props) => {
    const startDate = account.termStartDate ? new Date(account.termStartDate) : undefined
    return (
        <StyledHwAccount>
            <PlanRateSection account={account} />
            <div><strong>{account.protectionPlan}</strong></div>

            {startDate && (
                <div>
                    Effective Date:
                    <br />
                    {startDate.toLocaleDateString()}
                </div>
            )}
        </StyledHwAccount>
    )
}

export const InactiveHwAccount = () => {
    return <StyledHwAccount>
        <PlanRateContainer>
        YOUR PLAN
        <Header4>Inactive Account</Header4>
        </PlanRateContainer>
    </StyledHwAccount>

}

interface Props {
    readonly account: UserProfileAccount
}
