import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'
import { generateUuid } from '@igs-web/common-utilities/utilities/uuid-utilities'
import _omit from 'lodash-es/omit'
import _omitBy from 'lodash-es/omitBy'
import _values from 'lodash-es/values'
import { all, delay, put, takeEvery, takeLatest } from 'redux-saga/effects'

const notificationActionTypes = {
    SHOW_NOTIFICATION: '[notification] SHOW_NOTIFICATION',
    START_CLEAR_ALL_TIMER: '[notification] START_CLEAR_ALL_TIMER',
    CLEAR_NOTIFICATION: '[notification] CLEAR_NOTIFICATION',
    CLEAR_ALL_TIMED_NOTIFICATIONS: '[notification] CLEAR_ALL_TIMED_NOTIFICATIONS',
}

export const notificationActions = {
    showNotification: (payload: NotificationRequest) =>
        createAction<Notification>(notificationActionTypes.SHOW_NOTIFICATION)({
            ...payload,
            notificationId: payload.notificationId || generateUuid(),
            isPersistent: payload.isPersistent || false,
        }),
    startClearAllTimer: createAction(notificationActionTypes.START_CLEAR_ALL_TIMER),
    clearNotification: createAction<string>(notificationActionTypes.CLEAR_NOTIFICATION),
    clearAllTimed: createAction(notificationActionTypes.CLEAR_ALL_TIMED_NOTIFICATIONS),
}

export const notificationReducer = reducer<NotificationState>({})
    .add<Notification>(notificationActionTypes.SHOW_NOTIFICATION, (state, payload) => ({
        ...state,
        [payload.notificationId]: payload,
    }))
    .add<string>(notificationActionTypes.CLEAR_NOTIFICATION, (state, notificationId) => ({
        ..._omit(state, notificationId),
    }))
    .add(notificationActionTypes.CLEAR_ALL_TIMED_NOTIFICATIONS, state => ({
        ..._omitBy(state, (notification: Notification) => !notification.isPersistent),
    }))
    .build()

const DEFAULT_DELAY = 7000

const sagas = {
    *showNotification(action: { readonly payload: Notification }) {
        const { notificationId, isPersistent } = action.payload
        if (!isPersistent) {
            yield delay(DEFAULT_DELAY)
            yield put(notificationActions.clearNotification(notificationId))
        }
    },
    *startClearAllTimer() {
        yield delay(DEFAULT_DELAY)
        yield put(notificationActions.clearAllTimed())
    },
}

export function* notificationSagas() {
    yield all([
        takeEvery(notificationActionTypes.SHOW_NOTIFICATION, sagas.showNotification as any),
        takeLatest([notificationActionTypes.SHOW_NOTIFICATION, notificationActionTypes.START_CLEAR_ALL_TIMER], sagas.startClearAllTimer as any),
    ])
}

export class NotificationSelectors {
    public static readonly message = (state: CommonReduxState, notificationId): string | JSX.Element =>
        state.notification[notificationId] && state.notification[notificationId].message
    public static readonly notifications = (state: CommonReduxState): ReadonlyArray<Notification> => _values(state.notification)
}

export interface NotificationState {
    readonly [key: string]: Notification
}

export interface Notification {
    readonly notificationId: string
    readonly message: string
    readonly isPersistent: boolean
}

export interface NotificationRequest {
    readonly notificationId?: string
    readonly message: string
    readonly isPersistent?: boolean
}
