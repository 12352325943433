import * as React from 'react'

import styled from 'styled-components'

import { CompactTableBase, CompactTableRecord } from '@igs-web/common-ui-components/_atoms/tables'
import { DetailContent, DetailList, DetailListItem } from '@igs-web/common-ui-components/_molecules/detail-list'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { TableProps } from './data-table-responsive'

const StyledDetailList = styled(DetailList)`
    row-gap: ${Spacing.ExtraSmall};
    ${DetailContent} {
        color: ${props => props.color};
    }
`
const NoResultsContainer = styled.div`
    margin: auto;
`

export const DataTableCompact = ({ instance, showWhenEmpty, summaryRowData, dataTestId }: TableProps) => {
    return (
        <CompactTableBase data-testid={dataTestId} {...instance.getTableProps()}>
            {summaryRowData &&
                instance.pageCount &&
                summaryRowData.map((cell, i) => (
                    <CompactTableRecord key={i}>
                        <StyledDetailList>
                            <DetailListItem title={cell.header + ':'}>
                                <span>{cell.formattedValue}</span>
                            </DetailListItem>
                        </StyledDetailList>
                    </CompactTableRecord>
                ))}

            {instance.pageCount ? (
                instance.page.map(row => {
                    instance.prepareRow(row)
                    return (
                        <CompactTableRecord {...row.getRowProps()} key={row.getRowProps().key}>
                            <StyledDetailList color={row.values.isAlertRow ? 'red' : 'inherit'}>
                                {row.cells.map(cell => {
                                    return (
                                        <DetailListItem title={cell.column.Header + ':'} key={cell.getCellProps().key}>
                                            <span {...cell.getCellProps()}>{cell.render('Cell')}</span>
                                        </DetailListItem>
                                    )
                                })}
                            </StyledDetailList>
                        </CompactTableRecord>
                    )
                })
            ) : (
                <CompactTableRecord>
                    <NoResultsContainer>{showWhenEmpty}</NoResultsContainer>
                </CompactTableRecord>
            )}
        </CompactTableBase>
    )
}
