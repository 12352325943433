import * as React from 'react'

export const ConditionalElement = ({ show, children, alternative }: Props): JSX.Element =>
    !!show ? <>{children}</> : alternative ? <>{alternative}</> : <React.Fragment />

interface Props {
    readonly show: boolean
    readonly children: React.ReactNode
    readonly alternative?: React.ReactNode
}
