import React from 'react'

import styled from 'styled-components'

import { CheckoutStepPageIds } from '@igs-web/common-components/domain/checkout/checkout-step-page-ids'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText } from '@igs-web/common-ui-components/_molecules/info-text'
import { useWizardDispatch, useWizardState } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { useCheckoutWizardMessagesState } from './checkout-wizard-messages-context'

const InfoTextHeader = styled.div`
    font-weight: bold;
`

const StyledLink = styled.a`
    cursor: pointer;
`

export const CheckoutWizardMessages = ({ isHomeWarranty = false }: Props) => {
    const { phoneNumber, businessHours } = useCompany()

    const {
        showCantMatchAddressMessage,
        showIsCommercialServiceTypeAddressMessage,
        showSelectedOfferExpiredMessage,
        showServerErrorMessage,
        showOfferNotAvailableForZipMessage,
    } = useCheckoutWizardMessagesState()

    const { setActivePageId } = useWizardDispatch()
    const wizardState = useWizardState()
    return (
        <>
            {wizardState.activePageId === CheckoutStepPageIds.CustomerAddress && (
                <>
                    {showCantMatchAddressMessage && (
                        <>
                            <InfoText>
                                <StrongText as="span">We cannot locate your service address.</StrongText> Please correct your address or call us at{' '}
                                <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} /> ({businessHours.businessHoursFull})
                            </InfoText>
                            <GridSpacer spacing={Spacing.XxLarge} />
                        </>
                    )}
                    {showIsCommercialServiceTypeAddressMessage && (
                        <>
                            <InfoText>
                                The address you entered appears to be for a business. If you need service for a business, please call us at{' '}
                                <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} /> ({businessHours.businessHoursFull})
                            </InfoText>
                            <GridSpacer spacing={Spacing.XxLarge} />
                        </>
                    )}
                </>
            )}
            {showSelectedOfferExpiredMessage && (
                <>
                    <InfoText>
                        <InfoTextHeader>Session Expired</InfoTextHeader>
                        <span>
                            Your session has expired. <StyledLink onClick={() => setActivePageId(CheckoutStepPageIds.SelectRate)}>Select a rate</StyledLink> to
                            continue.
                        </span>
                    </InfoText>
                    <GridSpacer spacing={Spacing.XxLarge} />
                </>
            )}
            {showServerErrorMessage && (
                <>
                    <InfoText>
                        We're sorry. There was a problem submitting your information, if you believe you have entered all of your information correctly, or
                        continue to experience problems please call us at{' '}
                        <PhoneNumberLink phoneNumber={isHomeWarranty ? phoneNumber.homeWarranty : phoneNumber.customerSupport} /> (
                        {isHomeWarranty ? businessHours.homeWarrantyFull : businessHours.businessHoursFull})
                    </InfoText>
                    <GridSpacer spacing={Spacing.XxLarge} />
                </>
            )}
            {showOfferNotAvailableForZipMessage && (
                <>
                    <InfoText>We’re sorry, the offer you selected is not available for your service address. Please select a new offer to continue.</InfoText>
                    <GridSpacer spacing={Spacing.XxLarge} />
                </>
            )}
        </>
    )
}

interface Props {
    readonly isHomeWarranty?: boolean
}
