import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const BillGasCircleIcon = ({ altText = 'Gas Bill', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M20.685 18.298h-9.399c-0.288 0-0.521 0.233-0.521 0.521s0.233 0.521 0.521 0.521h9.399c0.288 0 0.521-0.233 0.521-0.521s-0.233-0.521-0.521-0.521v0z" />
        <path d="M20.685 21.125h-9.399c-0.264 0.032-0.466 0.255-0.466 0.525s0.203 0.493 0.464 0.525l0.003 0h9.399c0.264-0.032 0.466-0.255 0.466-0.525s-0.203-0.493-0.464-0.525l-0.003-0z" />
        <path d="M20.685 15.486h-3.356c-0.264 0.032-0.466 0.255-0.466 0.525s0.203 0.493 0.464 0.525l0.003 0h3.356c0.264-0.032 0.466-0.255 0.466-0.525s-0.203-0.493-0.464-0.525l-0.003-0z" />
        <path d="M8.958 24.151v-16.279h8.76v4.751c0 0.288 0.233 0.521 0.521 0.521v0h4.751v11.014zM18.761 8.591l3.488 3.488h-3.488zM23.901 12.211l-5.25-5.25c-0.097-0.094-0.229-0.152-0.374-0.154h-9.84c-0.288 0-0.521 0.233-0.521 0.521v0 17.344c-0 0.002-0 0.005-0 0.007 0 0.284 0.23 0.514 0.514 0.514 0.003 0 0.005-0 0.008-0h15.096c0.288 0 0.521-0.233 0.521-0.521v0-12.094c-0.003-0.143-0.061-0.273-0.154-0.367l0 0z" />
        <path d="M13.034 9.465l0.051 0.176c0.734 2.32-1.85 3.025-1.755 5.089 0.024 1.092 0.916 1.968 2.011 1.968 0.016 0 0.031-0 0.047-0.001l-0.002 0h0.198c-0.097-0.53-0.316-0.997-0.629-1.386l0.005 0.006c-0.94-1.469-0.073-2.203 0.279-2.68-0.022 0.126-0.035 0.272-0.035 0.42 0 0.604 0.21 1.159 0.56 1.597l-0.004-0.005c0.176 0.277 0.28 0.613 0.28 0.975 0 0.394-0.124 0.759-0.335 1.058l0.004-0.006c0.9-0.232 1.553-1.037 1.553-1.994 0-0.045-0.001-0.090-0.004-0.135l0 0.006c-0.051-1.035-2.203-5.067-2.203-5.067z" />
        <path d="M15.985 0c-0.007 0-0.014 0-0.022 0-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-0.002 0-0.005 0-0.007 0-8.827-7.152-15.984-15.977-15.993h-0.001zM15.985 30.458c-0.004 0-0.010 0-0.015 0-7.985 0-14.458-6.473-14.458-14.458s6.473-14.458 14.458-14.458c7.985 0 14.458 6.473 14.458 14.458v0c0 0.004 0 0.010 0 0.015 0 7.977-6.466 14.443-14.443 14.443v0z" />
    </SvgIcon>
)

export default BillGasCircleIcon
