import { OfferAccountType } from '@igs-web/common-models/constants/account-type'
import { GreenLineOfBusinesses, LineOfBusinessCode } from '@igs-web/common-models/models'
import { OfferModel } from '@igs-web/common-models/models/offer-model'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'

export interface OffersContextModel {
    readonly offersRequest?: OffersRequest
    readonly offersAreLoading: boolean
    readonly offersAreLoaded: boolean
    readonly offers: ReadonlyArray<OfferModel>
    readonly selectedOffer?: OfferModel
    readonly greenLineOfBusinesses: GreenLineOfBusinesses
}

export const initialOffersContextState: OffersContextModel = {
    offersRequest: undefined,
    offersAreLoading: false,
    offersAreLoaded: false,
    offers: [],
    selectedOffer: undefined,
    greenLineOfBusinesses: {
        [LineOfBusinessCode.Electric]: true,
        [LineOfBusinessCode.Gas]: true,
    },
}

export interface OffersRequest {
    readonly ignoreCache: boolean
    readonly offersInContextType: OffersInContextType
    readonly zipCode: string
    readonly accountType?: OfferAccountType
    readonly campaignCode?: string
    readonly invitationCode?: string
    readonly offerId?: string
    readonly priceEffectiveDate?: Date
    readonly partyAccountId?: number
    readonly requestedDateTime?: Date
    readonly distributionZone?: string
    readonly config?: RequestConfig
}

export enum OffersInContextType {
    Renewal = 'Renewal',
    RateChange = 'RateChange',
    Enrollment = 'Enrollment',
}

export interface SelectOfferParameters {
    readonly offerId: number
    readonly navigateToRoute?: string
}
