import * as React from 'react'
import { FaCaretDown } from 'react-icons/fa'

import styled from 'styled-components'

import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { useOnClickOutside } from '@igs-web/common-ui-components/hooks/useOnClickOutside'

import { caretSize } from '../_atoms/caret'
import { Spacing } from '../styles/spacing'
import { lightDark } from '../styles/theme'

const StyledButtonDropdown = styled.span`
    display: inline-block;
    position: relative;
    white-space: nowrap;
    direction: initial;
    font-size: 18px !important;
    line-height: 1;
    * {
        font-size: 18px !important;
    }
    user-select: none;
`

const StyledCaron = styled(FaCaretDown)`
    color: #fff;
    margin-left: 5px;
    line-height: unset;
`

const StyledLink = styled(Link)`
    color: #fff !important;
    text-decoration: underline;
`

const DropDownContainer = styled.div`
    position: absolute;
    width: 100%;
    top: calc(100% + ${caretSize});
`

export const DropdownList = ({ onClickEvent, buttonText, children, isLink }: ButtonDropdownProps): JSX.Element => {
    const [isOpen, setIsOpen] = React.useState(false)
    const ref = React.useRef(null)

    useOnClickOutside(ref, () => setIsOpen(false))

    const toggleOpen = () => {
        if (onClickEvent) {
            onClickEvent()
        }
        setIsOpen(true)
    }

    return (
        <StyledButtonDropdown ref={ref}>
            {!isLink && <Button onClick={toggleOpen}>{buttonText}</Button>}
            {isLink && (
                <>
                    <StyledLink onClick={toggleOpen}>{buttonText}</StyledLink>
                    <StyledLink onClick={toggleOpen}>
                        <StyledCaron />
                    </StyledLink>
                </>
            )}
            {isOpen && !!children && <DropDownContainer>{children}</DropDownContainer>}
        </StyledButtonDropdown>
    )
}

const StyledDropdownListItem = styled.div`
    padding: ${Spacing.Medium};
    text-align: center;

    &:hover {
        background-color: ${lightDark};
        cursor: pointer;
    }
`

export const DropdownListItem = ({ label, children, ...htmlProps }: DropdownListItemProps): JSX.Element => {
    return (
        <StyledDropdownListItem {...htmlProps}>
            {label}
            {children}
        </StyledDropdownListItem>
    )
}

interface ButtonDropdownProps {
    readonly buttonText: string
    readonly children?: React.ReactNode
    readonly isLink?: boolean
    readonly onClickEvent?: () => void
}

interface DropdownListItemProps extends React.HTMLAttributes<HTMLDivElement> {
    readonly label?: string
}
