import * as React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Switch } from 'react-router-dom'

import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { getFromStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

import { ProtectedRoute } from 'molecules/routing/protected-route'

import { HwAccountSummaryPage } from 'domain/dashboard/hw-dashboard-page'
import { ProductSelectionPage } from 'domain/home-warranty/product-selection-page'
import { HwFAQsPage } from 'domain/myaccount/hw-FAQ-page'
import { HwServiceRequestsPage } from 'domain/myaccount/hw-service-request-page'
import { UpdatePaymentPage } from 'domain/payment/update-payment-page'
import { HwAccountInformationPage } from 'domain/profile/hw-my-profile'
import * as Routes from 'domain/routes'

import { LobTab, LobTabs } from '../navigation/lob-tabs'

import { HwMyaccountContextProvider } from './hw-myaccount-context'
import { HwMyaccountContextState } from './hw-myaccount-reducer'

const HW_MYACCOUNT_KEY = 'hwMyaccountKey'
export const HomeWarrantyPages = () => {
    const userProfile = useSelector(UserSelectors.selectProfile)

    //-- WEB-5680: This works, but causes a "Cannot update during an existing state transition" in the console
    if (!userProfile?.hasScanaHW && !userProfile?.isEligibleForScanaHW) {
        return <Redirect to={Routes.AccountSummary} />
    }

    const storedHwMyaccountModel = getFromStorage<HwMyaccountContextState>(HW_MYACCOUNT_KEY)

    return (
        <HwMyaccountContextProvider hwMyaccountState={storedHwMyaccountModel} storageKey={HW_MYACCOUNT_KEY}>
            <LobTabs activeTab={LobTab.HomeWarranty} />
            <Switch>
                <ProtectedRoute exact={true} path={Routes.HwAccountSummary}>
                    {userProfile.hasScanaHW && <HwAccountSummaryPage />}
                    {!userProfile.hasScanaHW && <ProductSelectionPage />}
                </ProtectedRoute>
                <ProtectedRoute exact={true} path={Routes.HwAccountInformation}>
                    <HwAccountInformationPage />
                </ProtectedRoute>
                <ProtectedRoute exact={true} path={Routes.HwManagePayment}>
                    <UpdatePaymentPage />
                </ProtectedRoute>
                <ProtectedRoute exact={true} path={Routes.HwProductSelection}>
                    <ProductSelectionPage />
                </ProtectedRoute>
                <ProtectedRoute exact={true} path={Routes.HwFAQs}>
                    <HwFAQsPage />
                </ProtectedRoute>
                <ProtectedRoute exact={true} path={Routes.HwServiceRequests}>
                    <HwServiceRequestsPage />
                </ProtectedRoute>
            </Switch>
        </HwMyaccountContextProvider>
    )
}
