import { communitySolarRgb, serviceElectric, serviceGas, serviceHomeWarranty } from '@igs-web/common-ui-components/styles/theme'

import { IconType } from './icon-type'

export enum LineOfBusinessCode {
    Gas = 'GAS',
    Electric = 'ELECTRIC',
    HomeWarranty = 'HOMEWARRANTY',
    Commercial = 'COMMERCIAL',
    CommunitySolar = 'COMMUNITYSOLAR',
}

export const PartyLinesOfBusiness = [LineOfBusinessCode.Gas, LineOfBusinessCode.Electric, LineOfBusinessCode.HomeWarranty]
export const SubscriptionLineOfBusiness = [LineOfBusinessCode.CommunitySolar]

export interface LineOfBusiness {
    readonly code: LineOfBusinessCode
    readonly title: string
    readonly isCommodity: boolean
    readonly isPrimary: boolean
    readonly color: string
    readonly icon: IconType
}

export class LineOfBusinessConstant {
    public static readonly electric: LineOfBusiness = {
        code: LineOfBusinessCode.Electric,
        title: 'Electricity',
        isCommodity: true,
        isPrimary: true,
        color: serviceElectric,
        icon: IconType.ElectricIcon,
    }

    public static readonly gas: LineOfBusiness = {
        code: LineOfBusinessCode.Gas,
        title: 'Natural Gas',
        isCommodity: true,
        isPrimary: true,
        color: serviceGas,
        icon: IconType.GasIcon,
    }

    public static readonly homeWarranty: LineOfBusiness = {
        code: LineOfBusinessCode.HomeWarranty,
        title: 'Home Warranty',
        isCommodity: false,
        isPrimary: true,
        color: serviceHomeWarranty,
        icon: IconType.HomeWarrantyIcon,
    }
    public static readonly communitySolar: LineOfBusiness = {
        code: LineOfBusinessCode.CommunitySolar,
        title: 'Community Solar',
        isCommodity: false,
        isPrimary: true,
        color: communitySolarRgb,
        icon: IconType.SolarIcon,
    }

    public static readonly commodityCodes: ReadonlyArray<LineOfBusinessCode> = [LineOfBusinessCode.Electric, LineOfBusinessCode.Gas]

    public static readonly all: ReadonlyArray<LineOfBusiness> = [
        LineOfBusinessConstant.electric,
        LineOfBusinessConstant.gas,
        LineOfBusinessConstant.homeWarranty,
        LineOfBusinessConstant.communitySolar,
    ]

    public static readonly getByCode = (lineOfBusinessCode: LineOfBusinessCode) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return LineOfBusinessConstant.all.find(lob => lob.code === lineOfBusinessCode)!
    }

    public static readonly getLineOfBusinessCode = (lineOfBusiness: string) => {
        switch (lineOfBusiness.toUpperCase()) {
            case 'GAS':
                return LineOfBusinessCode.Gas
            case 'ELECTRIC':
                return LineOfBusinessCode.Electric
            case 'HOMEWARRANTY':
                return LineOfBusinessCode.HomeWarranty
            case 'COMMERCIAL':
                return LineOfBusinessCode.Commercial
            case 'COMMUNITYSOLAR':
                return LineOfBusinessCode.CommunitySolar
            default:
                return null
        }
    }

    public static readonly compare = (lobCode1: LineOfBusinessCode, lobCode2: LineOfBusinessCode) =>
        LineOfBusinessConstant.getLobCodeIndex(lobCode1) - LineOfBusinessConstant.getLobCodeIndex(lobCode2)

    private static readonly getLobCodeIndex = (lineOfBusinessCode: LineOfBusinessCode) =>
        LineOfBusinessConstant.all.indexOf(LineOfBusinessConstant.getByCode(lineOfBusinessCode))
}
