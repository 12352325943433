import React from 'react'
import { TableInstance } from 'react-table'

import styled from 'styled-components'

import { mdWidth } from '@igs-web/common-ui-components/styles/theme'

import { DataTableCompact } from './data-table-compact'
import { DataTableFull } from './data-table-full'

const FullWidthWrapper = styled.div`
    display: block;
    @media screen and (max-width: ${mdWidth}) {
        display: none;
    }
`

const MobileWrapper = styled.div`
    display: none;

    @media screen and (max-width: ${mdWidth}) {
        display: block;
    }
`

export const DataTableResponsive = (props: TableProps): JSX.Element => (
    <>
        <FullWidthWrapper>
            <DataTableFull {...props} />
        </FullWidthWrapper>
        <MobileWrapper>
            <DataTableCompact {...props} />
        </MobileWrapper>
    </>
)

export interface SummaryRowItem {
    readonly header?: string
    readonly formattedValue: string
    readonly span: number
}
export interface TableProps {
    readonly instance: TableInstance
    readonly showWhenEmpty: React.ReactNode
    readonly summaryRowData?: ReadonlyArray<SummaryRowItem>
    readonly dataTestId?: string
}
