/* eslint-disable max-lines */
import * as React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { AccountTypeName } from '@igs-web/common-models/constants/account-type'
import { Link as StandardLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import FaqSection from '@igs-web/common-ui-components/_molecules/faq/faq-section'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'

import { NavigationBanner } from 'molecules/header/navigation-banner'
import { ScrollToTop } from 'molecules/routing/scroll-to-top'

import { LobTab, LobTabs } from 'domain/navigation/lob-tabs'
import * as Routes from 'domain/routes'

export const GasFAQPage = () => {
    const userProfile = useSelector((store: CommonReduxState) => UserSelectors.selectProfile(store))

    const isSmallCommercial = userProfile && !userProfile.isCommercialIndustrial && userProfile.accounts.some(a => a.accountType === AccountTypeName.Commercial)

    const { phoneNumber, externalWebsite, businessHours } = useCompany()
    const mapUrlByEnv = path => `${externalWebsite}/${path}`

    const ratePlansPath = isSmallCommercial ? 'rates-sign-up/business-rates' : 'rates-sign-up/residential-rates'
    return (
        <>
            <ScrollToTop />
            <LobTabs activeTab={LobTab.NaturalGas} />
            <NavigationBanner title="FAQs" />
            <PageContentContainer>
                <Header3>General</Header3>
                <FaqSection
                    FAQs={[
                        {
                            question: 'Are you certified by the Georgia Public Service Commission?',
                            answer: (
                                <p>
                                    Yes, SCANA Energy was certified by the Georgia Public Service Commission at the start of deregulation in 1998. SCANA is one
                                    of the largest natural gas providers in Georgia.
                                </p>
                            ),
                        },
                        {
                            question: 'Do I have to sign a contract?',

                            answer: <p>Only for fixed rate plans. SCANA's variable rate plans don't require a contract.</p>,
                        },
                        {
                            question: 'How long are your contracts?',
                            answer: (
                                <>
                                    <p>We offer fixed rate plans for 6, 12, 18, 24, and 36 months. There are no contracts for our variable rate plans.</p>
                                    <p>
                                        <StandardLink href={mapUrlByEnv(ratePlansPath)}>See Rate Plans</StandardLink>
                                    </p>
                                </>
                            ),
                        },
                    ]}
                />
                <Header3>Billing</Header3>
                <FaqSection
                    FAQs={[
                        {
                            question: 'What if I need to make a late payment?',
                            answer: (
                                <p>
                                    You can request a payment extension by visiting your account summary (home page of your online account) and clicking "Need
                                    more time to pay?" or by calling us at <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />.
                                </p>
                            ),
                        },
                        {
                            question: 'Are there bill payment assistance programs?',
                            answer: (
                                <p>
                                    Yes. <StandardLink href={mapUrlByEnv('paying-my-bill/need-help-paying')}>Find out if you qualify</StandardLink>.
                                </p>
                            ),
                            hide: isSmallCommercial,
                        },
                        {
                            question: 'What are AGL Pass-Through Charges?',
                            answer: (
                                <p>
                                    Atlanta Gas Light (AGL) owns the natural gas pipeline system. The charges for use of that system appear on your monthly gas
                                    bill as AGL Pass-Through Charges. No matter which gas marketer you choose, these charges are passed directly on to the
                                    customer as regulated by the Georgia Public Service Commission.
                                </p>
                            ),
                        },
                        {
                            question: 'Will I be charged a deposit or upfront costs?',
                            answer: (
                                <p>
                                    We will run a credit check and based on the results, we may charge a deposit of up to $150 for residential customers, due
                                    before service begins. Your deposit will be refunded to your account, plus interest if you pay all bills in full and on time
                                    for 6 consecutive months.
                                </p>
                            ),
                            hide: isSmallCommercial,
                        },
                        {
                            question: 'Are your natural gas rates fixed or do they vary?',
                            answer: (
                                <>
                                    <p>We offer both fixed rate and variable rate plans.</p>
                                    <StandardLink href={mapUrlByEnv(ratePlansPath)}>See rate plans.</StandardLink>
                                </>
                            ),
                        },
                        {
                            question: 'How are your natural gas rates priced?',

                            answer: (
                                <>
                                    <p>
                                        Variable rate prices are subject to change on the 5th of every month, depending on the market. The price is calculated
                                        using the rate in effect the first day of the billing cycle.
                                    </p>
                                    <p>Fixed rate prices are determined on the day you sign up and are set for the entirety of your contract.</p>
                                </>
                            ),
                        },
                        {
                            question: 'What are your payment terms?',
                            answer: (
                                <p>
                                    We'll invoice you monthly and provide your next due date. For unpaid balances of $30 or more after the due date, we'll
                                    charge $10 or 1.5%, whichever is more.
                                </p>
                            ),
                        },
                        {
                            question: 'When and how often do you bill?',
                            answer: 'Each billing cycle is about one month, but the actual number of days in each cycle may vary.',
                        },
                        {
                            question: 'Will I be charged if I switch marketers?',
                            answer: (
                                <p>
                                    If you are on a fixed rate plan and switch to another Georgia natural gas marketer before the end of your contract, you will
                                    be charged an early exit fee of up to $300.
                                </p>
                            ),
                        },
                        {
                            question: 'How often can I switch natural gas marketers?',
                            answer: (
                                <p>
                                    As often as you like. Just be aware of each natural gas marketer's exit fee if you have a contract. Atlanta Gas Light will
                                    assess a fee of $7.50 for more than one switch per year. SCANA Energy's early termination fee is up to $300.
                                </p>
                            ),
                        },
                        {
                            question: 'Is there a way to make my monthly payments more predictable?',
                            answer: (
                                <>
                                    <p>Yes, our Budget Billing plan allows you to make equal payments all year.</p>
                                    <StandardLink href={mapUrlByEnv('paying-my-bill/budget-billing')}> Budget Billing</StandardLink>
                                </>
                            ),
                            hide: isSmallCommercial,
                        },
                    ]}
                />
                <Header3>Rate Plan</Header3>
                <FaqSection
                    FAQs={[
                        {
                            question: 'What is my rate plan?',
                            answer: <p>Your current natural gas rate plan is displayed on your bill in the "Current Charges" section.</p>,
                        },
                        {
                            question: 'How do I renew my fixed rate plan?',
                            answer: (
                                <p>
                                    You may either log in to your <Link to={Routes.LoginRoute}>online account</Link> or call{' '}
                                    <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />. You must be within the renewal window to avoid an early exit
                                    fee.
                                </p>
                            ),
                        },
                        {
                            question: "How do I know if I'm up for renewal?",
                            answer: (
                                <p>
                                    You will receive a letter or email (depending on your communication preferences) about one month before your fixed rate
                                    expires.
                                </p>
                            ),
                        },
                        {
                            question: 'When does my fixed rate plan end?',
                            answer: <p>The end date of your current natural gas rate plan is located on your bill in the "Current Charges" section.</p>,
                        },
                        {
                            question: 'When can I change my fixed rate plan?',
                            answer: <p>If you have a fixed rate plan, you can change it during your plan's renewal period.</p>,
                        },
                        {
                            question: 'What about renewing a variable rate plan?',
                            answer: <p>Since variable rate plans don't have contracts, you don't need to renew them.</p>,
                        },
                    ]}
                />
                {!isSmallCommercial && (
                    <>
                        <Header3>Senior Rates</Header3>
                        <FaqSection
                            FAQs={[
                                {
                                    question: 'Do you offer discounted natural gas rates for seniors?',
                                    answer: (
                                        <>
                                            <p>
                                                Yes, we offer special rates for customers who are 65 years and older.{' '}
                                                <StandardLink href={mapUrlByEnv('residential-rates/senior-rates')}> See Senior rate plans.</StandardLink>
                                            </p>
                                            <p>
                                                Atlanta Gas Light (AGL) also offers a discount for customers 65 years and older with a fixed annual income of
                                                less than $24,280. Complete this form to apply:{' '}
                                                <StandardLink
                                                    href={mapUrlByEnv('docs/librariesprovider2/default-document-library/agl_senior_discount_form.pdf?sfvrsn=8')}
                                                >
                                                    AGL Application for Senior Discount
                                                </StandardLink>
                                            </p>
                                        </>
                                    ),
                                },
                            ]}
                        />
                    </>
                )}
                <Header3>Customer Service</Header3>
                <FaqSection
                    FAQs={[
                        {
                            question: 'Is there someone I can call with questions about my natural gas bill?',
                            answer: (
                                <p>
                                    Yes! Our customer service representatives are ready to help. Call{' '}
                                    <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />.
                                </p>
                            ),
                        },
                        {
                            question: 'What are your customer service hours?',
                            answer: <p>Our representatives are available {businessHours.businessHoursFull}.</p>,
                        },
                        {
                            question: 'What is your bill dispute policy?',
                            answer: <p>We have 30 days from the date you report a billing error to correct it or show proof that the bill is correct.</p>,
                        },
                        {
                            question: 'Do you have local walk-in offices?',
                            answer: (
                                <>
                                    <p>We do not have walk-in offices but we do have more than 100 payment centers for you to pay in person.</p>
                                    <StandardLink href={mapUrlByEnv('paying-my-bill/payment-centers')}>Find a payment center</StandardLink>.
                                </>
                            ),
                        },
                        {
                            question: "Who do I call if I'm having trouble with my natural gas service?",
                            answer: (
                                <p>
                                    Call us at <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} /> if you have a question. Call Atlanta Gas Light at{' '}
                                    <PhoneNumberLink phoneNumber={phoneNumber.gasLeak} /> if you have an issue with your natural gas line or suspect a gas leak.
                                </p>
                            ),
                        },
                    ]}
                />
                <Header3>About the Market</Header3>
                <FaqSection
                    FAQs={[
                        {
                            question: 'Who is Atlanta Gas Light?',
                            answer: (
                                <p>
                                    While customers are free to choose who to work with to set up and manage their natural gas service, Atlanta Gas Light (AGL)
                                    owns and maintains the natural gas pipelines in this area of Georgia.
                                </p>
                            ),
                        },
                        {
                            question: 'What is the AGL Pass-Through Charge on my bill?',
                            answer: (
                                <p>
                                    This is the fee AGL charges for delivering gas to your home. This charge is required to be passed through to customers, no
                                    matter which Georgia natural gas marketer you choose.
                                </p>
                            ),
                        },
                        {
                            question: 'Who reads my meter?',
                            answer: (
                                <p>
                                    AGL is responsible for reading your meter each billing cycle. AGL owns and maintains the gas pipelines and your gas meter.
                                </p>
                            ),
                        },
                    ]}
                />
                {!isSmallCommercial && (
                    <>
                        <Header3>Budget Billing</Header3>
                        <FaqSection
                            FAQs={[
                                {
                                    question: 'How is my monthly budget calculated?',

                                    answer: (
                                        <p>
                                            We average the usage from your 12 previous bills and calculate the projected cost of energy for the next 12 months,
                                            then divide your predicted energy use into 12 equal payments.
                                        </p>
                                    ),
                                },
                                {
                                    question: 'Will my bill show my actual charges or just my monthly Budget Billing charge?',

                                    answer: (
                                        <p>
                                            You will only be asked to pay your Budget Billing amount plus any additional non-budget charges, however your bill
                                            will show the energy you used and all actual charges for the month.
                                        </p>
                                    ),
                                },
                                {
                                    question: 'Can my monthly budget amount change during the year?',

                                    answer: (
                                        <p>
                                            Yes, your budget will be periodically reviewed over the course of the year which may result in your monthly budget
                                            amount changing due to rate changes, or if your actual gas usage changes significantly.
                                        </p>
                                    ),
                                },
                                {
                                    question: 'When is my monthly budget amount recalculated?',

                                    answer: (
                                        <p>
                                            Your monthly budget amount will be reviewed periodically throughout the year to determine if a recalculation is
                                            needed.
                                        </p>
                                    ),
                                },
                                {
                                    question: 'What if I pay more than my actual billing amount?',

                                    answer: (
                                        <p>
                                            If the total amount paid is more than your actual billing amount at the time of recalculation, we will apply the
                                            amount we owe you to your recalculated monthly payment amount.
                                        </p>
                                    ),
                                },
                                {
                                    question: 'What if I pay less than my actual billing amount?',

                                    answer: (
                                        <p>
                                            If the total amount paid is less than your actual billing amount at the time of recalculation, we will apply the
                                            amount you owe you to your recalculated monthly payment amount.
                                        </p>
                                    ),
                                },
                                {
                                    question: 'What if I miss a payment?',

                                    answer: (
                                        <p>
                                            The late payment charge of $10 or 1.5%, whichever is greater, will be applied to the unpaid balance. You may also be
                                            removed from Budget Billing or service could be disconnected.
                                        </p>
                                    ),
                                },
                            ]}
                        />
                    </>
                )}
            </PageContentContainer>
        </>
    )
}
