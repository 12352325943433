import { Caption } from '@igs-web/common-ui-components/_atoms/typography'
import { percentOfDateRange } from '@igs-web/common-utilities/utilities/date-utilities'
import * as React from 'react'
import styled from 'styled-components'

const StyledProgress = styled.progress`
    width: 100%;
    background: var(--background);
    border: 1px solid var(--body-color);
    &::-webkit-progress-bar {
        background-color: var(--background);
    }
    &::-webkit-progress-value {
        background-color: var(--primary-dark);
    }
`

const DateContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const ContractLengthGraphic = ({ startDateTicks, endDateTicks }: Props): JSX.Element => {
    const startDate = new Date(startDateTicks)
    const endDate = new Date(endDateTicks)
    const currentDate = new Date()

    const percentage = percentOfDateRange(startDate, endDate, currentDate)

    return (
        <div>
            <StyledProgress max="100" value={percentage} />
            <DateContainer>
                <Caption>{startDate.toLocaleDateString()}</Caption>
                <Caption>{endDate.toLocaleDateString()}</Caption>
            </DateContainer>
        </div>
    )
}

interface Props {
    readonly startDateTicks: number
    readonly endDateTicks: number
}
