import * as React from 'react'

import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { EmailLink } from '@igs-web/common-ui-components/_atoms/link/email-link'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { CheckIcon } from '@igs-web/common-ui-components/_molecules/info-text'

import { useCompany } from '../company/hooks/useCompany'
import { AddAccountForm } from './forms/add-account-form'

export const AddAccountSection = () => {
    const [isAcctAdded, setIsAcctAdded] = React.useState<boolean>(false)
    return (
        <>
            <AddAccountSectionCopy />
            {!isAcctAdded &&
                <AddAccountForm onComplete={() => { setIsAcctAdded(true) }} />
            }
            {isAcctAdded && 
            <>
            <div><CheckIcon/> Account was added</div>
            <div><Button onClick={()=> setIsAcctAdded(false)} type='button'>Add Another Account?</Button></div>
            </>
            }
        </>
    )
}

const AddAccountSectionCopy = () => {
    const { phoneNumber, emails } = useCompany()
    return (
        <div>
            <strong>Linking Multiple Accounts</strong>
            <p>
                If you have more than one account with us, you can consolidate them using MyAccount. All you'll need is your utility account number located
                on your utility bill, and either the property address or phone number associated with the account.
            </p>
            <p>
                If you need help with this process, just give us a call at <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />.
            </p>
            <p className="footnote">
                <strong>NOTE:</strong> If the accounts have different email addresses, you’ll need to first update the email addresses to match before
                linking accounts. You can do this by logging into MyAccount and updating your email address under “Profile.”
            </p>
            <strong>Solar Customer?</strong>
            <p>
                We do not currently support residential solar accounts in MyAccount. If you have any questions about the status of your solar account,
                please give us a call at <PhoneNumberLink phoneNumber={phoneNumber.solarSupport} /> or email us at{' '}
                <EmailLink toAddress={emails.solarSupport} />
            </p>
        </div>
    )
}