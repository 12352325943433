import { StateCode } from '@igs-web/common-models/constants/states'

import { Address } from './address'

export interface HomeWarrantyPaymentInformation {
    readonly amount: number
    readonly merchantName: string
    readonly errorMessage: string
    readonly errorCode?: number
    readonly orderNumber: string
    readonly firstName: string
    readonly lastName: string
    readonly phoneNumber: string
    readonly serviceAddress: Address
}

export const emptyHomeWarrantyPaymentInformation = (): HomeWarrantyPaymentInformation => ({
    amount: 0,
    merchantName: '',
    errorMessage: '',
    orderNumber: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    serviceAddress: {
        address1: '',
        zipCode: '',
        city: '',
        state: StateCode.OH,
    },
})
