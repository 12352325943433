import * as React from 'react'

import { InputField } from '@igs-web/common-ui-components/_atoms/form-inputs/input-field'
import { TypedFieldProps } from '@igs-web/common-ui-components/_atoms/form-inputs/typed-field'
import { composeValidators, validEmail } from '@igs-web/common-utilities/utilities/form-validation'

export const EmailField = <T extends object>(props: TypedFieldProps<T> & React.InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
    return (
        <InputField<T>
            {...props}
            type="email"
            autoComplete="email"
            wrapperClass={'email-field ' + props.wrapperClass || ''}
            validate={composeValidators(props.validate, validEmail())}
        />
    )
}
