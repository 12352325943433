import _difference from 'lodash-es/difference'

import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { UserProfileAccount } from '@igs-web/common-models/models/user-profile-model'
import { nonUnique } from '@igs-web/common-utilities/utilities/array-utilities'

export const selectNonDuplicateAccounts = (accounts: ReadonlyArray<UserProfileAccount>): ReadonlyArray<UserProfileAccount> => {
    const lineOfBusinessCodes = accounts.map(account => account.lineOfBusinessCode)
    const duplicateLobs = nonUnique(lineOfBusinessCodes)
    const duplicateAccounts = accounts
        .filter(account => account.status === AccountStatus.inactive)
        .filter(account => duplicateLobs.includes(account.lineOfBusinessCode))

    return _difference(accounts, duplicateAccounts)
}
