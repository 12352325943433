import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const CircleCreditCheck = ({ altText = 'Credit Check', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} altText={altText} width="50" height="50" viewBox="0 0 50 50">
        <g id="Group_536" data-name="Group 536" transform="translate(4838 -3291)">
            <g id="Ellipse_208" data-name="Ellipse 208" transform="translate(-4838 3291)" className="icon-border-circle" strokeWidth="3">
                <circle cx="25" cy="25" r="25" stroke="none" />
                <circle cx="25" cy="25" r="23.5" fill="none" />
            </g>
            <path id="credit-card-regular" d="M25.086,32H2.286A2.283,2.283,0,0,0,0,34.281V51.008a2.283,2.283,0,0,0,2.286,2.281h22.8a2.283,2.283,0,0,0,2.286-2.281V34.281A2.283,2.283,0,0,0,25.086,32ZM2.571,34.281H24.8a.286.286,0,0,1,.285.285v2H2.286v-2A.286.286,0,0,1,2.571,34.281ZM24.8,51.008H2.571a.286.286,0,0,1-.285-.285V42.645h22.8v8.079A.286.286,0,0,1,24.8,51.008ZM9.124,46.256v1.9a.572.572,0,0,1-.57.57H5.132a.572.572,0,0,1-.57-.57v-1.9a.572.572,0,0,1,.57-.57H8.554A.572.572,0,0,1,9.124,46.256Zm9.124,0v1.9a.572.572,0,0,1-.57.57H11.215a.572.572,0,0,1-.57-.57v-1.9a.572.572,0,0,1,.57-.57h6.463A.572.572,0,0,1,18.248,46.256Z" transform="translate(-4827 3273.355)" className="icon-core" />
        </g>
    </SvgIcon>
)

export default CircleCreditCheck