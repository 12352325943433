import * as React from 'react'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'

export const InelligibleRateChangeMessage = () => {
    const { phoneNumber } = useCompany()
    return (
        <>
            <h2>Rate Change</h2>
            <p>
                Account is ineligible for a rate change. Please call <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />.
            </p>
        </>
    )
}
