import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'

import { Action } from 'redux'

import styled from 'styled-components'

import { LineOfBusiness, LineOfBusinessCode, LineOfBusinessConstant } from '@igs-web/common-models/models/line-of-business'
import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { ErrorField } from '@igs-web/common-ui-components/_atoms/form-inputs/error-field'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Input, Select, SelectOption } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { FeatureFlag } from '@igs-web/common-utilities/constants/constants'
import { isFeatureOn } from '@igs-web/common-utilities/services/feature-flag-service'
import { isZipCode } from '@igs-web/common-utilities/utilities/address-utilities'
import { formattedPhoneNumberRegex, parsePhoneNumber } from '@igs-web/common-utilities/utilities/phone-utilities'
import { removeHyphen } from '@igs-web/common-utilities/utilities/string-formatter'

import { AlertCardWithLink } from '../../alerts/alert-card'
import { AccountNumberField } from '../../checkout/fields/account-number-field'
import { AccountNumberRequirementDescription } from '../../checkout/forms/utility-account-number-form'
import { CommonReduxState } from '../../common-redux'
import { useCompany } from '../../company/hooks/useCompany'
import { myAccountActions } from '../../myaccount-redux'
import { ulobActions, ulobSelectors } from '../../ulob/ulob-redux'
import { userActions } from '../../user/user-redux'

const supportedLinesOfBusiness: ReadonlyArray<LineOfBusiness> = [
    LineOfBusinessConstant.electric,
    LineOfBusinessConstant.gas,
    LineOfBusinessConstant.homeWarranty,
]
const StyledForm = styled.form`
    display: grid;
    grid-gap: ${Spacing.ExtraLarge};
    Button {
        justify-self: right;
    }
`
const FormGroup = styled.div`
    display: grid;
    p {
        margin-bottom: -${Spacing.Medium};
    }
    grid-gap: ${Spacing.Medium};
`

export const AddAccountForm = ({ onComplete }: AddAccountFormProps) => {
    const {
        register,
        watch,
        reset,
        getValues,
        formState: { errors },
        setValue,
        handleSubmit,
    } = useForm<LinkAccountFormFields>()
    const dispatch = useDispatch()
    const allUlobs = useSelector((store: CommonReduxState) => ulobSelectors.selectAllData(store))
    const loadUserProfile = (): Action => dispatch(userActions.loadUser())
    const clearSelectedAddress = () => dispatch(myAccountActions.selectServiceAddress(undefined))
    const [showUtilityChangeMessage, setShowUtilityChangeMessage] = React.useState(false)
    const { phoneNumber: companyPhoneNumber, emails } = useCompany()

    const [loadedZipCode, setLoadedZipCode] = React.useState<string>('')
    const selectedLOB = watch('lineOfBusinessCode')
    const formZipCode = watch('zipCode')
    const filteredUlobs = allUlobs[formZipCode]?.filter(p => p.lineOfBusinessCode === selectedLOB)
    const selectedUlob = filteredUlobs?.find(f => f.utilityLineOfBusinessCode === watch('utilityLineOfBusinessCode'))
    const [submissionError, setSubmissionError] = React.useState<boolean>(false)
    const [isCiSubmissionError, setIsCiSubmissionError] = React.useState<boolean>(false)

    useEffectOnce(() => {
        const getFeatureFlag = async () => {
            const isOn = await isFeatureOn(FeatureFlag.UtiltyChangeMessage)
            setShowUtilityChangeMessage(isOn)
        }
        getFeatureFlag()
    })

    React.useEffect(() => {
        if (loadedZipCode !== formZipCode && isZipCode(formZipCode)) {
            dispatch(ulobActions.load({ showGlobalLoader: true, key: formZipCode }))
            setLoadedZipCode(formZipCode)
        }
    }, [dispatch, formZipCode, loadedZipCode])

    React.useEffect(() => {
        if (filteredUlobs?.length === 1 && watch('utilityLineOfBusinessCode') !== filteredUlobs[0].utilityLineOfBusinessCode) {
            setValue('utilityLineOfBusinessCode', filteredUlobs[0].utilityLineOfBusinessCode)
        }
        if (filteredUlobs === undefined && watch('utilityLineOfBusinessCode') !== undefined) {
            setValue('utilityLineOfBusinessCode', '')
        }
    }, [filteredUlobs, setValue, watch])
    const onSubmit = async (request: LinkAccountFormFields) => {
        const { lineOfBusinessCode, partyNumber, utilityAccountNumber, lastName, phoneNumber, subscriptionReferenceNumber } = request
        try {
            await customerInfoApiClient.linkAccount(
                {
                    lineOfBusinessCode,
                    partyNumber,
                    utilityAccountNumber: utilityAccountNumber ? removeHyphen(utilityAccountNumber) : '',
                    utilityCode: selectedUlob ? selectedUlob.utilityCode : '',
                    lastName,
                    phoneNumber: phoneNumber && parsePhoneNumber(phoneNumber),
                    subscriptionReferenceNumber,
                },
                { showGlobalLoader: false, throwErrorCode: true },
            )

            setSubmissionError(false)
            setIsCiSubmissionError(false)
            loadUserProfile()
            clearSelectedAddress()
            reset()
            onComplete()
        } catch (e) {
            if (e.message === 'UNABLE_TO_LINK_CI_ACCOUNT') {
                setSubmissionError(false)
                setIsCiSubmissionError(true)
            } else {
                setIsCiSubmissionError(false)
                setSubmissionError(true)
            }
        }
    }
    return (
        <StyledForm onSubmit={handleSubmit(data => onSubmit(data))}>
            <FormGroup>
                <Select
                    {...register('lineOfBusinessCode', { required: 'Required' })}
                    label="Account Type"
                    dataTestId="myprofile-add-account-line-of-business"
                    error={errors.lineOfBusinessCode?.message}
                    hideHelperText={!errors.lineOfBusinessCode?.message}
                >
                    {supportedLinesOfBusiness.map(lineOfBusiness => (
                        <SelectOption key={lineOfBusiness.code} value={lineOfBusiness.code} dataTestId={`add-account-select-option-${lineOfBusiness.code}`}>
                            {lineOfBusiness.title}
                        </SelectOption>
                    ))}
                </Select>

                {selectedLOB === LineOfBusinessConstant.homeWarranty.code && (
                    <Input
                        {...register('partyNumber', { required: 'Required', shouldUnregister: true })}
                        label="IGS Home Warranty Customer Number"
                        dataTestId="myprofile-add-account-party-number"
                        error={errors.partyNumber?.message}
                        hideHelperText={!errors.partyNumber?.message}
                    />
                )}
                {selectedLOB === LineOfBusinessConstant.communitySolar.code && (
                    <Input
                        {...register('subscriptionReferenceNumber', { required: 'Required', shouldUnregister: true })}
                        dataTestId="add-account-subscription-number"
                        label="Subscription Reference Number"
                        error={errors.subscriptionReferenceNumber?.message}
                        hideHelperText={!errors.subscriptionReferenceNumber?.message}
                    />
                )}
                {supportedLinesOfBusiness.find(s => s.code === selectedLOB)?.isCommodity && (
                    <>
                        <Input
                            {...register('zipCode', { required: 'Required', shouldUnregister: true })}
                            label="Zip Code"
                            dataTestId="myprofile-add-account-zip-code"
                            error={errors.zipCode?.message}
                            hideHelperText={!errors.zipCode?.message}
                        />
                        {filteredUlobs && (
                            <Select
                                {...register('utilityLineOfBusinessCode', { required: 'Required', shouldUnregister: true })}
                                dataTestId="myprofile-add-account-ulob-code"
                                error={errors.utilityLineOfBusinessCode?.message}
                                hideHelperText={!errors.utilityLineOfBusinessCode?.message}
                                label="Utility"
                            >
                                {filteredUlobs.length > 1 && (
                                    <SelectOption key="default" dataTestId="ulob-select-default" value="">
                                        -- Select --
                                    </SelectOption>
                                )}
                                {filteredUlobs?.map(d => (
                                    <SelectOption
                                        dataTestId={`ulob-select-${d.utilityLineOfBusinessCode}`}
                                        key={d.utilityLineOfBusinessCode}
                                        value={d.utilityLineOfBusinessCode}
                                    >
                                        {d.utility} - {d.lineOfBusinessCode}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                        {selectedUlob && (
                            <>
                                <AccountNumberField
                                    ulob={selectedUlob}
                                    idPrefix="add-account"
                                    accountNumber=""
                                    accountNumberFieldError={errors.utilityAccountNumber?.message}
                                    accountNumberField="utilityAccountNumber"
                                    register={register}
                                />
                                {showUtilityChangeMessage && (
                                    <AlertCardWithLink>
                                        <span>
                                            Due to ongoing utility updates, your account number may have been updated since your last bill. If you are
                                            experiencing issues adding an account, please call{' '}
                                            <PhoneNumberLink dataTestId="utility-change-phone-number" phoneNumber={companyPhoneNumber.customerSupport} /> and we
                                            would be happy to assist.
                                        </span>
                                    </AlertCardWithLink>
                                )}
                                <div>
                                    <AccountNumberRequirementDescription rawRequirementsText={selectedUlob!.accountNumberError!} />
                                    {selectedUlob.accountNumberHelpUrl && (
                                        <Link href={selectedUlob.accountNumberHelpUrl} openInNewTab={true} dataTestId="account-help-link">
                                            Need help finding this on your bill?
                                        </Link>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                )}
            </FormGroup>
            <FormGroup>
                <p>One of the following is required to link an account:</p>
                <Input
                    {...register('phoneNumber', {
                        pattern: { value: formattedPhoneNumberRegex, message: 'Invalid Phone Number' },
                        validate: { required: value => (getValues('lastName') === '' && value === '' ? 'Phone Number or Last Name Required' : undefined) },
                    })}
                    dataTestId="link-account-phone-number"
                    defaultValue=""
                    autoComplete="tel"
                    type="tel"
                    label="Phone Number"
                    error={errors.phoneNumber?.message}
                    hideHelperText={!errors.phoneNumber?.message}
                    onChange={e => (e.target.value = e.target.value.trim())}
                />
                <Input
                    {...register('lastName', {
                        validate: { isRequired: value => (getValues('phoneNumber') === '' && value === '' ? 'Phone Number or Last Name Required' : undefined) },
                    })}
                    dataTestId="link-account-last-name"
                    defaultValue=""
                    label="Last / Business Name"
                    maxLength={100}
                    autoComplete="family-name"
                    error={errors.lastName?.message}
                    hideHelperText={!errors.lastName?.message}
                    onChange={e => (e.target.value = e.target.value.trim())}
                />
                {submissionError && (
                    <ErrorField
                        showErrorMessage={true}
                        errorMessage="We are unable to link this account. If the error continues, please contact customer support."
                    />
                )}
                {isCiSubmissionError && (
                    <ErrorField
                        showErrorMessage={true}
                        errorMessage={
                            <span>
                                To link an account, email <a href={emails.commecialSupport}>{emails.commecialSupport}</a> or call us at{' '}
                                <PhoneNumberLink dataTestId="CI-error-phone-number" phoneNumber={companyPhoneNumber.commecialSupport} />
                            </span>
                        }
                    />
                )}
            </FormGroup>
            <Button type="submit">Link Account</Button>
        </StyledForm>
    )
}

interface LinkAccountFormFields {
    readonly submitError: string
    readonly lineOfBusinessCode: LineOfBusinessCode
    readonly partyNumber: string
    readonly zipCode: string
    readonly utilityLineOfBusinessCode: string
    readonly utilityAccountNumber: string
    readonly phoneNumber: string
    readonly lastName: string
    readonly subscriptionReferenceNumber: string
}

interface AddAccountFormProps {
    readonly onComplete: () => void
}
