import * as React from 'react'

import styled from 'styled-components'

import { IconType } from '@igs-web/common-models/models/icon-type'
import { Caption } from '@igs-web/common-ui-components/_atoms/typography'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { TrackIcon, TrackStrip } from './progress-track'

enum StepperStatus {
    Incomplete = 'incomplete',
    Active = 'active',
    Complete = 'complete',
}

enum StepperPart {
    Icon = 'icon',
    Border = 'border',
    Background = 'background',
    Spacer = 'spacer',
}

const getColor = (type: StepperPart, status: StepperStatus) => ` var(--progress-${type}-${status})`

const StyledProgressTrackStep = styled.span`
    display: grid;
    cursor: pointer;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;

    &:first-child > ${TrackStrip}:first-of-type {
        visibility: hidden;
        width: 100%;
        grid-template-columns: 1fr auto 1fr;
    }
    &:last-child > ${TrackStrip}:last-of-type {
        visibility: hidden;
        width: 100%;
    }
`

const StyledProgressTrackLabel = styled(Caption)<ProgressTrackLabelProps>`
    grid-column: 1/-1;
    justify-self: center;
    font-weight: ${({ $isActive }: ProgressTrackLabelProps) => ($isActive ? FontWeight.Medium : FontWeight.Default)};
    margin-top: ${Spacing.Medium};
    @media (max-width: ${Breakpoint.Tablet}) {
        display: none;
    }
`

const StyledHr = styled.hr<ProgressTrackLabelProps>`
    margin-top: ${Spacing.ExtraSmall};
    margin-bottom: ${Spacing.Small};
    min-width: ${Spacing.ExtraLarge};
    grid-column: 1/-1;
    justify-self: center;
    visibility: ${({ $isActive }: ProgressTrackLabelProps) => ($isActive ? 'visible' : 'hidden')};
    border: ${Spacing.XxSmall} solid ${getColor(StepperPart.Border, StepperStatus.Active)};
    background-color: ${getColor(StepperPart.Border, StepperStatus.Active)};
    border-radius: ${Spacing.Small};
    height: ${Spacing.XxSmall};
    @media (max-width: ${Breakpoint.Tablet}) {
        display: none;
    }
`

interface ProgressTrackLabelProps {
    readonly $isActive?: boolean
}

const getIconType = (iconType: IconType, status: StepperStatus) => {
    return status === StepperStatus.Complete ? IconType.CircleCheckmarkIcon : iconType
}

export const ProgressTrackStep = ({ label, iconType, isActive = false, isComplete = false, ...htmlProps }: Props) => {
    const incomingStatus = isActive || isComplete ? StepperStatus.Complete : StepperStatus.Incomplete
    const status = isActive ? StepperStatus.Active : isComplete ? StepperStatus.Complete : StepperStatus.Incomplete
    const outgoingStatus = isComplete ? StepperStatus.Complete : StepperStatus.Incomplete

    const incomingSpacerColor = getColor(StepperPart.Spacer, incomingStatus)
    const iconColor = getColor(StepperPart.Icon, status)
    const borderColor = getColor(StepperPart.Border, status)
    const backgroundColor = getColor(StepperPart.Background, status)
    const outgoingSpacerColor = getColor(StepperPart.Spacer, outgoingStatus)
    const icon = getIconType(iconType, status)

    return (
        <StyledProgressTrackStep>
            <TrackStrip $color={incomingSpacerColor} />
            <TrackIcon
                isActive={isActive}
                label={label}
                iconType={icon}
                backgroundColor={backgroundColor}
                borderColor={borderColor}
                iconColor={iconColor}
                {...htmlProps}
            />
            <TrackStrip $color={outgoingSpacerColor} />
            <StyledProgressTrackLabel $isActive={isActive}>{label}</StyledProgressTrackLabel>
            <StyledHr $isActive={isActive} />
        </StyledProgressTrackStep>
    )
}

interface Props extends React.HtmlHTMLAttributes<HTMLElement> {
    readonly iconType: IconType
    readonly label: string
    readonly isActive?: boolean
    readonly isComplete?: boolean
}
