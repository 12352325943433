import styled from 'styled-components'

import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { borderRadius, lightDark } from '@igs-web/common-ui-components/styles/theme'

export const StyledRenewalDialogContainer = styled.div`
    display: grid;
    grid-gap: ${Spacing.Medium};
    padding: ${Spacing.Large};
    border: 1px solid ${lightDark};
    border-radius: ${borderRadius};
    text-align: left;
`