import * as React from 'react'
import styled from 'styled-components'

import { OfferModel } from '@igs-web/common-models/models'
import { Button, ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { Card } from '@igs-web/common-ui-components/_atoms/card'
import { EncodedHtml } from '@igs-web/common-ui-components/_atoms/encode-html'
import { ActionLink } from '@igs-web/common-ui-components/_atoms/link/action-link'
import { ErrorText, Header4 } from '@igs-web/common-ui-components/_atoms/typography'
import { ColorwayType, colorwayCss } from '@igs-web/common-ui-components/_molecules/theming/colorway-container'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontFamilyHeader, fontSizeExtraLarge, fontSizeSmall, fontSizeVerySmall } from '@igs-web/common-ui-components/styles/theme'
import { apiClient } from '@igs-web/common-utilities/api/api-client'
import { getPriceWithoutUnitOfMeasureForProduct } from '@igs-web/common-utilities/utilities/offer-utilities'

import { HomeWarrantyPrice, HomeWarrantyPriceDisplay } from './home-warranty-detail-components'
import { HomeWarrantyDetailModal } from './home-warranty-detail-modal'

export interface HWCardDetails {
    readonly productCode: string
    readonly colorway: ColorwayType
    readonly name: string
    readonly cardText?: string
    readonly includesText: string
    readonly includesExamples: string
    readonly notIncludedText: string
    readonly notIncludedExamples: string
    readonly extraFee?: string
    readonly bestValue: boolean
    readonly bestValueText: string
    readonly bestValueColor?: ColorwayType
    readonly sequence: number
}

export const HWCard = styled(Card)`
    background-color: rgba(var(--colorway-rgb), var(--colorway-light-opacity, 0.1));
    overflow: hidden;
    height: 100%;
    align-items: start;
    li {
        list-style-image: var(--list-icon-check, url('${apiClient.getAssetPath('product-selection/check.svg')}'));
    }
    > header {
        position: relative;
        text-align: center;
        background-color: rgba(var(--colorway-rgb), 1);
        color: white;
        display: grid;
        justify-items: center;
        row-gap: ${Spacing.Medium};
        padding-bottom: ${Spacing.Large};
        h3 {
            margin: 0;
            font-family: ${fontFamilyHeader};
            font-weight: ${FontWeight.Bold};
            font-size: ${fontSizeExtraLarge};
            line-height: 1.2;
            color: white;
            max-width: 11ch;
        }
        h4 {
            margin: 0;
            font-weight: ${FontWeight.Bold};
            color: white;
        }
        button {
            --btn-background: transparent;
            --btn-hover-text: rgb(var(--colorway-rgb));
            --btn-hover-background: white;
            border-width: 3px;
        }
    }
    > div {
        height: 100%;
    }
    > footer .link:not([href]) {
        font-size: ${fontSizeSmall};
        color: black;
        text-decoration: underline;
    }
    ${colorwayCss}
    &.unavailable {
        filter: saturate(0);
        opacity: 0.3;
    }
    &.selected {
        border-width: 4px;
        button {
            --btn-background: white;
            --btn-text: rgb(var(--colorway-rgb));
            --btn-hover-text: white;
            --btn-hover-background: transparent;
        }
    }
    &.flagged {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .show-for-modal {
        display: none;
    }
    ${ErrorText} {
        font-size: ${fontSizeVerySmall};
        font-weight: ${FontWeight.Bold};
        text-transform: uppercase;
    }
`

const CompactContent = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content 1fr min-content;
    gap: ${Spacing.Large};
    align-items: start;
    justify-items: center;
    text-align: center;
    height: 100%;
    button {
        max-width: fit-content;
    }
`
const ExtraFee = styled.div`
    font-size: ${fontSizeVerySmall};
    font-weight: ${FontWeight.Light};
    header & {
        position: absolute;
        top: 100%;
    }
`
const Header = ({ name, extraFee = '', offer, onClick, selected }: HeaderProps) => (
    <>
        <h3>{name}</h3>
        {!offer && <span>Enter ZIP Code to View Pricing and Availability</span>}
        {offer && (
            <>
                <HomeWarrantyPriceDisplay>
                    <HomeWarrantyPrice>{getPriceWithoutUnitOfMeasureForProduct(offer.primaryProduct, false)}</HomeWarrantyPrice>/mo.
                    {!!extraFee && <ExtraFee>{extraFee}</ExtraFee>}
                </HomeWarrantyPriceDisplay>
                <span>&nbsp;</span>
                <Button onClick={onClick} buttonStyle={ButtonStyleType.Primary}>
                    {selected ? 'In Cart' : 'Add Plan'}
                </Button>
            </>
        )}
    </>
)

interface HeaderProps {
    readonly name: string
    readonly extraFee?: string
    readonly offer?: OfferModel
    readonly onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
    readonly selected: boolean
}

const FullCard = ({ detail, offer, offersLoaded, selectedOfferId, onSelectOffer }: CardProps) => {
    const [showDetails, setShowDetails] = React.useState(false)

    const selected = !!offer && offer.offerId === selectedOfferId
    const className = `colorway-${detail.colorway}${offersLoaded && !offer ? ' unavailable' : ''}${detail.bestValue ? ' flagged' : ''}${
        selected ? ' selected' : ''
    }`

    const handleOfferSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
        onSelectOffer(offer)
        e.currentTarget.blur()
    }

    const cardText = detail.cardText?.length ? detail.cardText : detail.includesText

    return (
        <HWCard
            header={<Header name={detail.name} offer={offer} extraFee={detail.extraFee} onClick={handleOfferSelect} selected={selected} />}
            footer={<ActionLink onClick={() => setShowDetails(true)}>Plan Details</ActionLink>}
            className={className}
        >
            <EncodedHtml content={cardText} />
            <HomeWarrantyDetailModal detail={detail} offer={offer} isOpen={showDetails} onClose={() => setShowDetails(false)} />
        </HWCard>
    )
}

const CompactCard = ({ detail, offer, defaultOffer, offersLoaded, onSelectOffer }: CardProps) => {
    const [showDetails, setShowDetails] = React.useState(false)
    const className = `colorway-${detail.colorway}`
    
    const getDataTestId = item => `hw-product-card-${item}-${detail.productCode}`

    const handleOfferSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
        onSelectOffer(offer)
        e.currentTarget.blur()
    }

    const offerForPrice = offer || defaultOffer
    return (
        <HWCard header={<Header4 data-testid={getDataTestId('title')}>{detail.name}</Header4>} className={className}>
            <CompactContent>
                {offerForPrice && (
                    <>
                        <HomeWarrantyPriceDisplay data-testid={getDataTestId('price-display')}>
                            <HomeWarrantyPrice>{getPriceWithoutUnitOfMeasureForProduct(offerForPrice.primaryProduct, false)}</HomeWarrantyPrice>/mo.
                            {!!detail.extraFee && <ExtraFee>{detail.extraFee}</ExtraFee>}
                        </HomeWarrantyPriceDisplay>
                    </>
                )}
                {!offerForPrice && <div>&nbsp;</div>}
                <ActionLink data-testid={getDataTestId('details-link')} onClick={() => setShowDetails(true)}>Plan Details</ActionLink>

                <EncodedHtml content={detail.cardText ?? detail.includesText} />

                {!offer && !offersLoaded && (
                    <div>
                        <em>Enter your ZIP Code to Sign Up</em>
                    </div>
                )}
                {!offer && offersLoaded && <ErrorText as="div">Plan not currently available in your area</ErrorText>}
                {offer && (
                    <Button data-testid={getDataTestId('signup-button')} onClick={handleOfferSelect} buttonStyle={ButtonStyleType.Primary}>
                        Sign Up
                    </Button>
                )}
                <HomeWarrantyDetailModal detail={detail} offer={offer} isOpen={showDetails} onClose={() => setShowDetails(false)} />
            </CompactContent>
        </HWCard>
    )
}

interface CardProps {
    readonly detail: HWCardDetails
    readonly offer?: OfferModel
    readonly defaultOffer?: OfferModel
    readonly offersLoaded: boolean
    readonly selectedOfferId?: number
    readonly onSelectOffer: (offer: OfferModel | undefined) => void
}

export const HomeWarrantyDetailCard = ({ isCompact = false, ...rest }: Props) => {
    return isCompact ? <CompactCard {...rest} /> : <FullCard {...rest} />
}

interface Props extends CardProps {
    readonly isCompact?: boolean
}
