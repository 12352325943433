import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const EmailCircleIcon = ({ altText = 'Email', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M13.217 16.587l-6.249 5.625c0.067 0.019 0.144 0.032 0.224 0.037l0.003 0h17.579c0.081-0.006 0.156-0.019 0.228-0.039l-0.008 0.002-6.205-5.625-1.77 1.571c-0.274 0.247-0.639 0.398-1.039 0.398s-0.765-0.151-1.040-0.399l0.001 0.001zM25.7 10.434l-6.183 5.529 6.183 5.595c0.007-0.040 0.011-0.085 0.011-0.132s-0.004-0.092-0.011-0.137l0.001 0.005v-10.61c0.005-0.037 0.008-0.081 0.008-0.125s-0.003-0.087-0.009-0.13l0.001 0.005zM6.344 10.434c-0.022 0.075-0.036 0.16-0.037 0.249v10.611c0.001 0.084 0.014 0.165 0.038 0.241l-0.002-0.006 6.139-5.566zM7.233 9.759c-0.033-0.005-0.071-0.008-0.11-0.008s-0.077 0.003-0.114 0.009l0.004-0.001 8.554 7.717c0.117 0.082 0.262 0.131 0.419 0.131s0.302-0.049 0.421-0.133l-0.002 0.002 8.562-7.688c-0.032-0.005-0.069-0.008-0.106-0.008s-0.074 0.003-0.11 0.009l0.004-0.001z" />
        <path d="M15.985 0c-0.007 0-0.014 0-0.022 0-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-0.002 0-0.005 0-0.007 0-8.827-7.152-15.984-15.977-15.993h-0.001zM15.985 30.458c-0.004 0-0.010 0-0.015 0-7.985 0-14.458-6.473-14.458-14.458s6.473-14.458 14.458-14.458c7.985 0 14.458 6.473 14.458 14.458v0c0 0.004 0 0.010 0 0.015 0 7.977-6.466 14.443-14.443 14.443v0z" />
    </SvgIcon>
)

export default EmailCircleIcon
