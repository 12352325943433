import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import _sortBy from 'lodash-es/sortBy'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { HistoricRate } from '@igs-web/common-models/models/historic-rates'
import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'

import { useCompany } from '../company/hooks/useCompany'
import { DataTable } from '../data-table/data-table'
import { DefaultEmptyTable } from '../data-table/default-empty-table'
import { TableColumn } from '../data-table/models/table-column'
import { TableDataType } from '../data-table/models/table-data-types'

import { historicRatesActions, historicRatesSelectors } from './historic-rates-redux'

const getColumns = (): ReadonlyArray<TableColumn> => [
    { header: 'Beginning Date', accessor: 'fromDate', dataType: TableDataType.DateTime },
    { header: 'End Date', accessor: 'toDate', dataType: TableDataType.DateTime, disableSorting: true },
    { header: 'Contract Rate', accessor: 'rate', dataType: TableDataType.Currency, disableSorting: true },
]

export const RateDetails = ({ accountId }: Props) => {
    const dispatch = useDispatch()
    const historicRates = useSelector((store: CommonReduxState) => historicRatesSelectors.selectData(store, accountId))
    React.useEffect(() => {
        dispatch(historicRatesActions.load({ key: accountId, showGlobalLoader: false }))
    }, [accountId, dispatch])

    const sortedRates = _sortBy(historicRates, historicRate => historicRate.fromDate).reverse()
    const { phoneNumber } = useCompany()

    const columns = getColumns()
    const isLoading = !historicRates

    return (
        <>
            <LocalLoader isLoading={isLoading} />
            <div>
                {!isLoading && (
                    <div>
                        <DataTable<HistoricRate>
                            columns={columns}
                            data={sortedRates}
                            tableTitle={'Historical Rates'}
                            showWhenEmpty={<DefaultEmptyTable tableDataType="rates" phoneNumber={phoneNumber.customerSupport} />}
                            dataTestId="rates-history-table"
                        />
                    </div>
                )}
            </div>
        </>
    )
}

interface Props {
    readonly accountId: number
}
