export enum FontAwesomeIconType{
    Bolt = 'fas fa-bolt',
    Comment = 'far fa-comment',
    CommentDots = 'far fa-comment-dots',
    Envelope = 'far fa-envelope',
    Edit = 'far fa-edit',
    Home = 'fas fa-home',
    Phone = 'fas fa-phone',
    PhoneFlip = 'fas fa-phone fa-flip-horizontal',
    ShoppingCart = 'fas fa-shopping-cart',
    Times = 'fas fa-times',
}