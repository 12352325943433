import { createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'
import { putInStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

export interface HwMyaccountContextState {
    readonly selectedAddressHash: string
    readonly storageKey?: string
}

export const initialHwMyaccountContextState: HwMyaccountContextState = {
    selectedAddressHash: '',
    storageKey: 'HwMyaccountStorageID',
}

const setHwMyaccountContextState = (updatedOffersState: HwMyaccountContextState) => {
    putInStorage(updatedOffersState, updatedOffersState.storageKey, false)
    return updatedOffersState
}

export const hwMyaccountActionTypes = {
    SET_SELECTED_ADDRESS_HASH: '[hwMyaccount] SET_SELECTED_ADDRESS_KEY',
}

export const hwMyaccountActions = {
    setSelectedAddressHash: createAction<string>(hwMyaccountActionTypes.SET_SELECTED_ADDRESS_HASH),
}

export const HwMyaccountContextReducer = reducer<HwMyaccountContextState>(initialHwMyaccountContextState)
    .add<string>(hwMyaccountActionTypes.SET_SELECTED_ADDRESS_HASH, (state, selectedAddressHash) => {
        const updatedState = {
            ...state,
            selectedAddressHash,
        }
        return setHwMyaccountContextState(updatedState)
    })
    .build()
