import * as React from 'react'

import { motion } from 'framer-motion'
import styled from 'styled-components'

import { lightLight, mdWidth, navbarZindex } from '@igs-web/common-ui-components/styles/theme'

import { Spacing } from '../styles/spacing'

const desktopPanelWidth = '300px'

export const BasePanel = styled(props => <motion.div {...props} />)<Props>`
    --_background-color: var(--panel-background-color, ${lightLight});
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0;
    left: 0;
    top: 100%;
    background-color: var(--_background-color);
    @media (min-width: ${({ $showOnMobile }: Props) => ($showOnMobile ? 0 : mdWidth)}) {
        box-shadow: 6px 6px 10px 0 rgba(0, 0, 0, 0.3);
        width: auto;
        height: auto;

        ${({ $alignRight }: Props) => ($alignRight ? 'left:auto;right:0;' : 'left:0;')}
    }
    @media (max-width: ${({ $showOnMobile }: Props) => ($showOnMobile ? mdWidth : 0)}) {
        left: 0 !important;
        right: auto !important;
    }
    @media (max-width: ${mdWidth}) {
        top: ${Spacing.XxLarge};
        height: auto;
    }
`

export const Panel = styled(BasePanel)`
    z-index: ${navbarZindex};
    @media (min-width: ${({ $showOnMobile }: Props) => ($showOnMobile ? 0 : mdWidth)}) {
        min-width: ${({ $width }: Props) => ($width ? $width : desktopPanelWidth)};
    }
`

interface Props {
    readonly $alignRight?: boolean
    readonly $width?: string
    readonly $showOnMobile?: boolean
}
