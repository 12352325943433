import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'

export const useOffersQueryStringHandler = () => {
    const company = useCompany()

    const getGasOffersQueryStringParameters = (): GetGasOffersQueryStringParameters => {
        const search = new URLSearchParams(window.location.search)
        const offerId = search.get('offerid')
        const campaignCodeQs = search.get('campaigncode')
        const priceEffectiveDateQs = search.get('priceeffectivedate')
        const promoCode = search.get('promoCode')

        return {
            offerId: offerId ? parseInt(offerId, 10) : null,
            campaignCode: campaignCodeQs === 'undefined' || !campaignCodeQs ? company.enrollment.campaignCodes.default : campaignCodeQs,
            priceEffectiveDate: priceEffectiveDateQs ? new Date(priceEffectiveDateQs) : null,
            promoCode,
        }
    }
    const getHwOffersQueryStringParameters = (): GetHwOffersQueryStringParameters =>{
        const search = new URLSearchParams(window.location.search)
        const urlCampaignCode = search.get('campaignCode')
        const urlZipCode = search.get('zipCode')
        const offerId = search.get('offerid')
        const addressKey = search.get('addressKey')

        return {
            campaignCode: urlCampaignCode ? urlCampaignCode : null,
            zipCode: urlZipCode ? urlZipCode : null,
            offerId: offerId ? parseInt(offerId, 10) : null,
            addressKey: addressKey ? addressKey : null,
        }
    }

    return {
        getGasOffersQueryStringParameters,
        getHwOffersQueryStringParameters,
    }
}

export interface GetGasOffersQueryStringParameters {
    readonly offerId: number | null
    readonly campaignCode: string | null
    readonly priceEffectiveDate: Date | null
    readonly promoCode: string | null
}

export interface GetHwOffersQueryStringParameters{
      readonly campaignCode: string | null
      readonly zipCode: string | null
      readonly offerId: number | null
      readonly addressKey: string | null
}