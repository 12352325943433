import * as Routes from 'domain/routes'

import { myAccountDashboardUrl } from '@igs-web/common-components/domain/general-routes'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { UserProfile, UserProfileAccount, UserProfileBillingAccount, UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'

import { getSortedAddresses } from './address-utilities'

type BillingAccountSorter = (a: UserProfileBillingAccount, b: UserProfileBillingAccount) => number

export const sortAccounts = (statusFn: (billingAccountNumber: string) => AccountStatus): BillingAccountSorter => {
    return (a: UserProfileBillingAccount, b: UserProfileBillingAccount) => {
        const aStatus = statusFn(a.billingAccountNumber) === AccountStatus.active || a.amountDue > 0
        const bStatus = statusFn(b.billingAccountNumber) === AccountStatus.active || b.amountDue > 0

        if (aStatus !== bStatus) {
            return aStatus ? -1 : 1
        }

        if (a.dueDate !== b.dueDate) {
            if (a.dueDate === null || b.dueDate === null) {
                return a.dueDate === null ? 1 : -1
            } else {
                return a.dueDate <= b.dueDate ? -1 : 1
            }
        }
        return Number(a.billingAccountNumber) < Number(b.billingAccountNumber) ? -1 : 0
    }
}

export const getBillingAccountServiceAddresses = (
    billingAccount: UserProfileBillingAccount,
    serviceAddresses: ReadonlyArray<UserProfileServiceAddress>,
    accounts: ReadonlyArray<UserProfileAccount>,
) => {
    const allAccounts = accounts.filter(a => billingAccount.partyAccountIds.includes(a.accountId))
    const activeAddressKeys = allAccounts.filter(a => a.status === AccountStatus.active).map(a => a.serviceAddressKey)
    const nonActiveAddressKeys = allAccounts.filter(a => a.status !== AccountStatus.active).map(a => a.serviceAddressKey)

    const activeAddresses = getSortedAddresses(serviceAddresses.filter(sa => activeAddressKeys.includes(sa.serviceAddressKey)))
    const nonActiveAddresses = getSortedAddresses(serviceAddresses.filter(sa => nonActiveAddressKeys.includes(sa.serviceAddressKey)))
    //this puts the active addresses first, then sorted in a standard way
    return [...activeAddresses, ...nonActiveAddresses]
}

export const getBillingAccountUrl = (billingAccountNumber: string, userProfile: UserProfile, preferRouting = true) => {
    const address = getDefaultServiceAddress(billingAccountNumber, userProfile)
    return getServiceAccountUrl(billingAccountNumber, address?.serviceAddressKey, preferRouting)
}

export const getServiceAccountUrl = (billingAccountNumber, serviceAddressKey, preferRouting = true) => {
    const encodedKey = encodeURIComponent(serviceAddressKey)
    const baseUrl = preferRouting ? Routes.AccountSummary : myAccountDashboardUrl().asString()
    return `${baseUrl}/${billingAccountNumber}/${encodedKey}`.replace(/\/\//g, '/')
}
export const getDefaultServiceAddress = (billingAccountNumber: string, userProfile: UserProfile) => {
    const billingAccount = userProfile.billingAccounts.find(ba => ba.billingAccountNumber === billingAccountNumber)
    if (!billingAccount) {
        return undefined
    }
    const addresses = getBillingAccountServiceAddresses(billingAccount, userProfile.serviceAddresses, userProfile.accounts)
    return addresses[0]
}

export interface BillingAccountListItem {
    readonly billingAccountNumber: string
    readonly isActive: boolean
}

export const getSortedBillingAccountItems = (
    billingAccounts: ReadonlyArray<UserProfileBillingAccount>,
    statusFn: (billingAccountNumber: string) => AccountStatus,
): ReadonlyArray<BillingAccountListItem> => {
    const sorter = sortAccounts(statusFn)
    return [...billingAccounts]
        .sort(sorter)
        .map(({ billingAccountNumber }) => ({ billingAccountNumber, isActive: statusFn(billingAccountNumber) === AccountStatus.active }))
}
