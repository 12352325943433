export enum IconType {
    BadgeCircleIcon = 'badge-circle-icon',
    BillElectricCircleIcon = 'bill-electric-circle-icon',
    BillGasCircleIcon = 'bill-gas-circle-icon',
    ElectricLineCircleIcon = 'electric-line-circle-icon',
    ElectricCircleIcon = 'electric-circle-icon',
    EmailCircleIcon = 'email-circle-icon',
    GasPipeCircleIcon = 'gas-pipe-circle-icon',
    GasCircleIcon = 'gas-circle-icon',
    HeartCircleIcon = 'heart-circle-icon',
    HomeCircleIcon = 'home-circle-icon',
    IgsCircleIcon = 'igs-circle-icon',
    PartnerCircleIcon = 'partner-circle-icon',
    RealtorCircleIcon = 'realtor-circle-icon',
    RepairsCircleIcon = 'repairs-circle-icon',
    DollarSignCircleIcon = 'dollar-sign-circle-icon',
    ShieldCircleIcon = 'shield-circle-icon',
    SolarCircleIcon = 'solar-circle-icon',
    StarCircleIcon = 'star-circle-icon',
    WindCircleIcon = 'wind-circle-icon',
    BundleAndSaveLogo = 'bundle-and-save-logo',
    ElectricPlugIcon = 'electric-plug-icon',
    FlameIcon = 'flame-icon',
    HomeIcon = 'home-icon',
    LeafIcon = 'leaf-icon',
    IgsEnergyMark = 'igs-energy-mark',
    ExclamationShadowCircleIcon = 'exclamation-shadow-circle-icon',
    ElectricIcon = 'electric-icon',
    GasIcon = 'gas-icon',
    HomeWarrantyIcon = 'home-warranty-icon',
    LightingIcon = 'lighting-icon',
    SolarIcon = 'solar-icon',
    BlobIcon = 'blog-icon',
    CircleCheckmarkIcon = 'checkmark-circle-icon',
    CircleContactInfoIcon = 'circle-contact-info-icon',
    CircleCreditCheckIcon = 'circle-credit-check-icon',
    CircleMyDetailsIcon = 'circle-my-details-icon',
    CirclePaymentIcon = 'circle-payment-icon',
    CircleProductDetailsIcon = 'circle-product-details-icon',
    CircleReviewIcon = 'circle-review-icon',
    CircleServiceAddressIcon = 'circle-service-address-icon',
    CircleStartDateIcon = 'circle-start-date-icon',
    CircleTag = 'circle-tag',
}
