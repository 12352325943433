import * as React from 'react'
import { useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'

import { UserProfile } from '@igs-web/common-models/models'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { AccordionGroup } from '@igs-web/common-ui-components/_molecules/accordion/accordion-group'
import { AccordionPanel } from '@igs-web/common-ui-components/_molecules/accordion/accordion-panel'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText } from '@igs-web/common-ui-components/_molecules/info-text'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

import { CommonReduxState } from '../common-redux'
import { useCompany } from '../company/hooks/useCompany'
import { UserSelectors } from '../user/user-redux'

import { AddAccountSection } from './add-account-section'
import { AuthorizedUsersSection } from './authorized-users-section'
import { ChangePhoneSection } from './change-phone-section'
import { CommunicationPreferencesSection } from './communication-preferences'
import { EditEmailSection, EditNameSection, EditPasswordSection } from './edit-account-information'
import { GetMultipleMembershipWarning } from './multiple-membership-warning-service'
import { RemoveAccountSection } from './remove-account-section'
import { ProfileSection } from './user-profile-routes'

const getHasLinkedAccounts = (userProfile: UserProfile) =>
    (userProfile.accounts && userProfile.accounts.length > 0) || (userProfile.communitySolarSubscriptions && userProfile.communitySolarSubscriptions.length > 0)

export const HWMyProfileAccordion = ({ initialPanel }: HWMyProfileAccordionProps) => {
    const userProfile = useSelector((store: CommonReduxState) => UserSelectors.selectProfile(store))
    const { features, phoneNumber, displayName, name } = useCompany()
    const hasLinkedAccounts = userProfile && getHasLinkedAccounts(userProfile)
    const canChangePhone = !userProfile?.userIsMapped && hasLinkedAccounts
    const canChangeAuthUsers = !userProfile?.userIsMapped && hasLinkedAccounts
    const showChangeCommunicationPreferences = !userProfile?.userIsMapped
    const [emailMemberships, setEmailMemberships] = React.useState<ReadonlyArray<string>>([])
    const canChangeEmail = !userProfile?.userIsMapped

    useEffectOnce(() => {
        if (userProfile?.email) {
            const getMemberships = async () => {
                if (userProfile?.hasMultipleProviders) {
                    const memberships = await customerInfoApiClient.getMembershipsByEmail(userProfile?.email)
                    setEmailMemberships(memberships ?? [])
                }
            }
            getMemberships()
        }
    })

    return (
        <>
            {userProfile?.userIsMapped && (
                <InfoText>
                    To change phone number, authorized users, email, or paperless billing please contact {displayName} at{' '}
                    <PhoneNumberLink phoneNumber={phoneNumber.commecialSupport} />
                </InfoText>
            )}
            {userProfile?.hasMultipleProviders && emailMemberships.length > 1 && (
                <>
                    <GridSpacer spacing={Spacing.Large} />
                    <GetMultipleMembershipWarning companyName={name} memberships={emailMemberships} />
                    <GridSpacer spacing={Spacing.Large} />
                </>
            )}
            <AccordionGroup initiallyExpanded={initialPanel || ProfileSection.ChangeName}>
                {features.canChangeNameFromProfile && (
                    <AccordionPanel panelId={ProfileSection.ChangeName} header="Change Name" dataTestId="change-name-header-div">
                        <EditNameSection />
                    </AccordionPanel>
                )}
                {canChangeEmail && (
                    <AccordionPanel panelId={ProfileSection.ChangeEmail} header="Change Email" dataTestId="change-email-header-div">
                        <EditEmailSection />
                    </AccordionPanel>
                )}
                <AccordionPanel panelId={ProfileSection.ChangePassword} header="Change Password" dataTestId="change-password-header-div">
                    <EditPasswordSection />
                </AccordionPanel>
                {canChangePhone && (
                    <AccordionPanel panelId={ProfileSection.ChangePhone} header="Change Phone" dataTestId="change-phone-header-div">
                        <ChangePhoneSection />
                    </AccordionPanel>
                )}
                {canChangeAuthUsers && (
                    <AccordionPanel panelId={ProfileSection.AuthorizedUsers} header="Change Authorized Users" dataTestId="change-authorized-users-header-div">
                        <AuthorizedUsersSection />
                    </AccordionPanel>
                )}
                {features.canAddAndRemoveAccounts && (
                    <AccordionPanel panelId={ProfileSection.AccountLinking} header="Add Accounts" dataTestId="add-accounts-header-div">
                        <AddAccountSection />
                    </AccordionPanel>
                )}
                {features.canAddAndRemoveAccounts && hasLinkedAccounts && (
                    <AccordionPanel panelId={ProfileSection.AccountRemoval} header="Remove Accounts" dataTestId="remove-accounts-header-div">
                        <RemoveAccountSection />
                    </AccordionPanel>
                )}
                {showChangeCommunicationPreferences && (
                    <AccordionPanel
                        panelId={ProfileSection.CommunicationPreferences}
                        header="Communication Preferences"
                        dataTestId="communication-preferences-header-div"
                    >
                        <CommunicationPreferencesSection />
                    </AccordionPanel>
                )}
            </AccordionGroup>
            <GridSpacer spacing={Spacing.Medium} />
        </>
    )
}

interface HWMyProfileAccordionProps {
    readonly initialPanel?: ReadonlyArray<ProfileSection> | ProfileSection
}
