import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'

export const AccordionAnimation = ({ show, children, animationKey, startHidden = true }: Props) => (
    <AnimatePresence initial={false}>
        {show && (
            <motion.div
                key={animationKey}
                initial={startHidden ? 'hidden' : 'open'}
                animate="open"
                exit="hidden"
                variants={{
                    open: { opacity: 1, height: 'auto' },
                    hidden: { opacity: 0, height: 0 },
                }}
                transition={{ type: 'spring', duration: 0.8 }}
                layout
            >
                {children}
            </motion.div>
        )}
    </AnimatePresence>
)

interface Props {
    readonly show: boolean
    readonly animationKey: string
    readonly layoutId?: string
    readonly children?: React.ReactNode
    readonly delay?: number
    readonly startHidden?: boolean
}
