import { getFromStorage, putInStorage } from '../utilities/storage-utilities'
import { publicIpv4 } from 'public-ip'

const storageKey = 'ipAddress'

export const fetchIpAddress = async (): Promise<string> => {

    const existingIpAddress = getFromStorage<string>(storageKey, true)

    if (!existingIpAddress) {

        let ip = await getIpFromPublicIp()

        if (!ip) {
            ip = await fetchIpFromCloudflare()
        }

        if (!ip) {
            ip = await fetchIpFromIpify()
        }
        
        if (ip) {
            putInStorage<string>(ip, storageKey, true)
        }

        return ip
    }
    
    return existingIpAddress ?? ''
}

const getIpFromPublicIp = async (): Promise<string> => {
    try  {
        return await publicIpv4()
    } catch {
        return ''
    }
}

const fetchIpFromCloudflare = async (): Promise<string> => {
    try  {
        return await fetch('https://www.cloudflare.com/cdn-cgi/trace')
        .then(res => res.text())
        .then(data => {
            const arr = data
                .trim()
                .split('\n')
                .map(e => e.split('='))
            return Object.fromEntries(arr).ip
        }).catch(() => {
            return ''
        })
    } catch {
        return ''
    }
}

const fetchIpFromIpify = async (): Promise<string> => {
    try  {
        return await fetch('https://api.ipify.org?format=json')
        .then(res => res.json()).then(data => {
            return data.ip
        }).catch(() => {
            return ''
        })
    } catch {
        return ''
    }
}

export const getIpAddress = (): string => {
    const existingIpAddress = getFromStorage<string>(storageKey, true)
    if (existingIpAddress) {
        return existingIpAddress
    } 
    return ''
}

export const getIpAddressAsync = async (): Promise<string> => {
    const existingIpAddress = getFromStorage<string>(storageKey, true)
    if (existingIpAddress) {
        return existingIpAddress
    } else {
        return fetchIpAddress()
    }
}