export const getPosition = (element: HTMLElement) => {
    const position = { x: 0, y: 0 }

    while (element) {
        position.x += element.offsetLeft - element.scrollLeft + element.clientLeft
        position.y += element.offsetTop - element.scrollTop + element.clientTop
        element = element.offsetParent as HTMLElement
    }

    return position
}

export const getWindowHeight = () => {
    const body = document.body
    const html = document.documentElement

    return Math.max(body.scrollHeight, body.offsetHeight, !html ? 0 : html.clientHeight, !html ? 0 : html.scrollHeight, !html ? 0 : html.offsetHeight)
}

export const getViewport = () => {
    const clientHeight = document.documentElement ? document.documentElement.clientHeight : 0
    const clientWidth = document.documentElement ? document.documentElement.clientWidth : 0

    return {
        width: Math.max(clientWidth, window.innerWidth || 0),
        height: Math.max(clientHeight, window.innerHeight || 0),
    }
}

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
export const isVisible = (el: HTMLElement) => el && el.offsetParent !== null
