import * as React from 'react'

import styled from 'styled-components'

import { useBreakpoints } from '@igs-web/common-components/hooks/use-breakpoints'
import { FormControlLabel, Switch, SwitchProps } from '@igs/react-mui'

import { Breakpoint } from '../styles/breakpoints'
import { FontWeight } from '../styles/font-weight'
import { fontFamilyBody } from '../styles/theme'

const StyledSwitch = styled(Switch)`
    --_thumb-color-checked: var(--switch-color-checked, #008a00);
    --_thumb-color: var(--switch_color, #d9d9d9);

    && .Mui-checked {
        color: var(--_thumb-color-checked);
    }
    .MuiSwitch-switchBase {
        color: var(--_thumb-color);
    }

    && .Mui-checked + .MuiSwitch-track {
        background-color: var(--_thumb-color-checked);
    }
`

const StyledFormControllabel = styled(FormControlLabel)`
    .MuiTypography-body1 {
        font-family: ${fontFamilyBody};
        font-weight: ${FontWeight.Bold};
    }
`

export const ToggleSwitch = ({ checked = false, label, dataTestId, onChange, ...rest }: Props) => {
    const [isChecked, setIsChecked] = React.useState(checked)
    const { lessThan } = useBreakpoints()
    const isMobile = lessThan(Breakpoint.Mobile)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked)
        if (onChange) {
            onChange(event, event.target.checked)
        }
    }
    return (
        <StyledFormControllabel
            control={<StyledSwitch {...rest} checked={isChecked} onChange={handleChange} data-testid={dataTestId} />}
            label={label || ''}
            labelPlacement={isMobile ? 'top' : 'start'}
            data-testid={`${dataTestId}-label`}
        />
    )
}

interface Props extends SwitchProps {
    readonly label?: string
    readonly dataTestId?: string
}
