import _clamp from 'lodash-es/clamp'
import _round from 'lodash-es/round'

export const MAX_PERCENTAGE = 100

export const precisionRound = (num: number, precision: number): number => _round(num, precision)

export const getPrecision = (num: number): number => {
    const strVal = num.toString()
    const decimalChars = strVal.split('.')[1]
    return decimalChars?.length || 0
}

export const roundToMulitple = (num: number, multiple: number): number => {
    const unit = num / multiple
    const roundedUnit = Math.round(unit)
    return roundedUnit * multiple
}

export const proportionOfRange = (rangeStart: number, rangeEnd: number, value: number): number => {
    const rangeLength = rangeEnd - rangeStart
    const progress = value - rangeStart
    const proportion = progress / rangeLength
    return _clamp(proportion, 0, 1)
}

export const percentOfRange = (rangeStart: number, rangeEnd: number, value: number): number => {
    const proportion = proportionOfRange(rangeStart, rangeEnd, value)
    return proportion * MAX_PERCENTAGE
}

export const formatWithComma = (num: string | number): string => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

/**
 * Default behavior of '%' operator will return a negative result for value n < 0.
 * This function will always return a positve value.
 * Note: https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers
 */
export const modulo = (n: number, m: number) => ((n % m) + m) % m
