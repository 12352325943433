import React from 'react'

import { compareAsc } from 'date-fns'

import { BudgetBillingInformation } from '@igs-web/common-models/models/budget-billing'
import { asDate, dateFormats, formatDate } from '@igs-web/common-utilities/utilities/date-utilities'
import { formatAsCurrency } from '@igs/react'

import { useCompany } from '../company/hooks/useCompany'
import { DataTable } from '../data-table/data-table'
import { SummaryRowItem } from '../data-table/data-table-responsive'
import { DefaultEmptyTable } from '../data-table/default-empty-table'
import { TableColumn } from '../data-table/models/table-column'
import { TableDataType } from '../data-table/models/table-data-types'

interface BillTableRecord {
    readonly billMonth: string
    readonly gasCharges: string
    readonly otherCharges: string
    readonly totalCharges: string
    readonly paymentAndAdjustments: string
    readonly totalBalance: string
}

const mapToTableRecord = (info: BudgetBillingInformation): BillTableRecord => ({
    billMonth: formatDate(info.billMonth, dateFormats.shortMonthYear),
    gasCharges: formatAsCurrency(info.commodityChargesAmount),
    otherCharges: formatAsCurrency(info.otherChargesAmount),
    totalCharges: formatAsCurrency(info.totalCharges),
    paymentAndAdjustments: formatAsCurrency(info.paymentAndAdjustmentsAmount),
    totalBalance: formatAsCurrency(info.accountTotalBalance),
})

const getColumnDefs = (): ReadonlyArray<TableColumn> => [
    { header: 'Bill Month', accessor: 'billMonth', dataType: TableDataType.String, disableSorting: true },
    { header: 'Gas Charges', accessor: 'gasCharges', dataType: TableDataType.String, disableSorting: true },
    { header: 'Other Charges & Credits', accessor: 'otherCharges', dataType: TableDataType.String, disableSorting: true },
    { header: 'Total Charges', accessor: 'totalCharges', dataType: TableDataType.String, disableSorting: true },
    { header: 'Payments/ Adjustments', accessor: 'paymentAndAdjustments', dataType: TableDataType.String, disableSorting: true },
    { header: 'Total Account Balance', accessor: 'totalBalance', dataType: TableDataType.String, disableSorting: true },
]
const BUDGET_BILLING_ROWS = 13

export const BudgetBillingTable = ({ budgetBillInfo, budgetBillBeginningBalance }: Props): JSX.Element => {
    const rowData = [...budgetBillInfo].sort((a, b) => compareAsc(asDate(a.billMonth), asDate(b.billMonth))).map(info => mapToTableRecord(info))
    const { phoneNumber } = useCompany()

    const columnDefs = getColumnDefs()

    const summaryRowData: ReadonlyArray<SummaryRowItem> = [
        {
            header: rowData.length < BUDGET_BILLING_ROWS ? 'Beginning Account Balance' : `Account Balance as of ${rowData[0].billMonth}`,
            span: 2,
            formattedValue: formatAsCurrency(budgetBillBeginningBalance),
        },
    ]

    return (
        <DataTable<BillTableRecord>
            tableTitle={'Budget Billing'}
            columns={columnDefs}
            data={rowData}
            pageSize={BUDGET_BILLING_ROWS}
            showWhenEmpty={<DefaultEmptyTable tableDataType="Budget Billing" phoneNumber={phoneNumber.customerSupport} />}
            summaryRowData={summaryRowData}
            dataTestId="budget-billing-table"
        />
    )
}

interface Props {
    readonly budgetBillInfo: ReadonlyArray<BudgetBillingInformation>
    readonly budgetBillBeginningBalance: number
}
