import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const BillElectricCircleIcon = ({ altText = 'Electric Bill', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M20.685 18.298h-9.399c-0.288 0-0.521 0.233-0.521 0.521s0.233 0.521 0.521 0.521h9.399c0.288 0 0.521-0.233 0.521-0.521s-0.233-0.521-0.521-0.521v0z" />
        <path d="M20.685 21.125h-9.399c-0.264 0.032-0.466 0.255-0.466 0.525s0.203 0.493 0.464 0.525l0.003 0h9.399c0.264-0.032 0.466-0.255 0.466-0.525s-0.203-0.493-0.464-0.525l-0.003-0z" />
        <path d="M20.685 15.486h-3.356c-0.264 0.032-0.466 0.255-0.466 0.525s0.203 0.493 0.464 0.525l0.003 0h3.356c0.264-0.032 0.466-0.255 0.466-0.525s-0.203-0.493-0.464-0.525l-0.003-0z" />
        <path d="M8.958 24.151v-16.279h8.76v4.751c0 0.288 0.233 0.521 0.521 0.521v0h4.751v11.014zM18.761 8.591l3.488 3.488h-3.488zM23.901 12.211l-5.25-5.25c-0.097-0.094-0.229-0.152-0.374-0.154h-9.84c-0.288 0-0.521 0.233-0.521 0.521v0 17.344c-0 0.002-0 0.005-0 0.007 0 0.284 0.23 0.514 0.514 0.514 0.003 0 0.005-0 0.008-0h15.096c0.288 0 0.521-0.233 0.521-0.521v0-12.094c-0.003-0.143-0.061-0.273-0.154-0.367l0 0z" />
        <path d="M13.445 8.929l1.571 5.059-1.52-0.441-0.088 3.495-1.41-4.971 1.395 0.316 0.051-3.458z" />
        <path d="M15.985 0c-0.007 0-0.014 0-0.022 0-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-0.002 0-0.005 0-0.007 0-8.827-7.152-15.984-15.977-15.993h-0.001zM15.985 30.458c-0.004 0-0.010 0-0.015 0-7.985 0-14.458-6.473-14.458-14.458s6.473-14.458 14.458-14.458c7.985 0 14.458 6.473 14.458 14.458v0c0 0.004 0 0.010 0 0.015 0 7.977-6.466 14.443-14.443 14.443v0z" />
    </SvgIcon>
)
export default BillElectricCircleIcon
