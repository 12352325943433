import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const GasCircleIcon = ({ altText = 'Natural Gas', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M15.112 3.95s0.073 0.25 0.184 0.587c2.475 7.717-6.175 10.074-5.874 16.955 0.077 3.635 3.042 6.552 6.688 6.552 0.062 0 0.125-0.001 0.187-0.003l-0.009 0c0.243-0.012 0.468-0.036 0.69-0.071l-0.036 0.005c0-0.734-0.441-2.1-2.071-4.604-3.128-4.81-0.264-7.387 0.925-8.922 0 0-0.859 2.651 1.726 6.689 0.601 0.924 0.958 2.054 0.958 3.267 0 1.31-0.417 2.523-1.125 3.514l0.012-0.018c2.985-0.771 5.155-3.439 5.155-6.615 0-0.158-0.005-0.315-0.016-0.47l0.001 0.021c-0.162-3.451-7.416-16.888-7.416-16.888z" />
        <path d="M15.985 0c-0.007 0-0.014 0-0.022 0-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-0.002 0-0.005 0-0.007 0-8.827-7.152-15.984-15.977-15.993h-0.001zM15.985 30.458c-0.004 0-0.010 0-0.015 0-7.985 0-14.458-6.473-14.458-14.458s6.473-14.458 14.458-14.458c7.985 0 14.458 6.473 14.458 14.458v0c0 0.004 0 0.010 0 0.015 0 7.977-6.466 14.443-14.443 14.443v0z" />
    </SvgIcon>
)

export default GasCircleIcon
