import * as React from 'react'

import { ChartData, Chart as ChartJS, ChartOptions, registerables } from 'chart.js'
import { draw } from 'patternomaly'

import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'

import Bar from './vendor/bar'

ChartJS.register(...registerables)

export const patterns = {
    Diagonal: (color: string) => draw('diagonal-right-left', color),
    ZigZag: (color: string) => draw('zigzag', color),
}

const standardChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                font: {
                    size: 20,
                    weight: 'bold',
                },
                padding: 20,
                usePointStyle: false,
            },
        },
    },
}

const axisOptions = (xLabel: string, yLabel: string): ChartOptions<'bar'> => ({
    scales: {
        y: {
            title: {
                display: true,
                text: yLabel,
                font: {
                    weight: 'bold',
                },
            },

            beginAtZero: true,
        },
        x: {
            grid: {
                display: true,
            },
            title: {
                display: true,
                text: xLabel,
                font: {
                    weight: 'bold',
                },
            },
        },
    },
})

const getChartOptions = (xLabel: string, yLabel: string, options?: ChartOptions<'bar'>): ChartOptions<'bar'> => ({
    ...standardChartOptions,
    ...options,
    ...axisOptions(xLabel, yLabel),
})

export const BarChart = ({ xLabel, yLabel, data, options }: Props): JSX.Element => (
    <React.Suspense fallback={<LocalLoader isLoading={true} />}>
        <Bar data={data} options={getChartOptions(xLabel, yLabel, options)} height={1} width={4} />
    </React.Suspense>
)

interface Props {
    readonly xLabel: string
    readonly yLabel: string
    readonly data: ChartData<'bar'>
    readonly options?: ChartOptions<'bar'>
}
