import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { buildUrl, Url } from '@igs-web/common-utilities/utilities/url-utilities'

export const enrollmentRoutes = {
    checkoutUrl: (): Url => buildUrl('/signup/checkout'),
    residentialEnrollment: (lineOfBusiness?: LineOfBusinessCode) => buildUrl(`/signup${lineOfBusiness ? `?section=${lineOfBusiness}` : ''}`),
    commercialEnrollment: (zipCode?: string, lineOfBusinessCode?: LineOfBusinessCode) =>
        buildUrl(`/signup/small-commercial${zipCode ? `?zipCode=${zipCode}` : ''}${lineOfBusinessCode ? `?section=${lineOfBusinessCode}` : ''}`),
    enrollment: (isCommercial: boolean, zipCode?: string, lineOfBusinessCode?: LineOfBusinessCode) =>
        isCommercial ? enrollmentRoutes.commercialEnrollment(zipCode, lineOfBusinessCode) : enrollmentRoutes.residentialEnrollment(lineOfBusinessCode),
    homeWarranty: (): Url => buildUrl('/for-your-home/home-warranty/products'),
}
