import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { BaseCompany } from '@igs-web/common-components/domain/company/company'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { UserSelectors, userActions } from '@igs-web/common-components/domain/user/user-redux'
import { UserProfile } from '@igs-web/common-models/models'

import * as Routes from 'domain/routes'

export interface MenuItem {
    readonly btnLabel: string
    readonly link: string
    readonly onClick?: () => void
    readonly show: boolean
    readonly external: boolean
    readonly children?: ReadonlyArray<MenuItem>
    readonly hideOnNav?: boolean
}

const isHwRoute = (pathname: string) => {
    return pathname.includes(Routes.HwMyAccountRoute) || pathname.includes(Routes.HomeWarrantCheckout) || pathname.includes(Routes.HomeWarrantyConfirmation)
}

export const useNavigationItems = () => {
    const dispatch = useDispatch()
    const company = useCompany()
    const userProfile = useSelector(UserSelectors.selectProfile)
    const isLoggedIn = useSelector(UserSelectors.selectLoggedIn)

    const { pathname } = useLocation()

    const logout = () => {
        dispatch(userActions.logUserOut())
    }

    const homewarrantyMenuItems = getHwNavigationItems(logout, isLoggedIn)
    const gasMenuItems = getGasNavigationItems(logout, isLoggedIn, company, userProfile)

    const mobileMenuItems: ReadonlyArray<MenuItem> = [
        {
            btnLabel: 'Natural Gas',
            children: gasMenuItems,
            show: true,
            external: false,
            link: Routes.MyAccountRoute,
        },
        {
            btnLabel: 'Home Warranty',
            children: homewarrantyMenuItems,
            show: !!userProfile?.hasScanaHW || !!userProfile?.isEligibleForScanaHW,
            external: false,
            link: Routes.HwMyAccountRoute,
        },
        {
            btnLabel: 'Log Out',
            link: '/',
            onClick: logout,
            show: isLoggedIn,
            external: false,
        },
    ]

    return {
        bannerMenuItems: isHwRoute(pathname) ? homewarrantyMenuItems : gasMenuItems,
        mobileMenuItems: isLoggedIn ? mobileMenuItems : gasMenuItems,
    }
}

export const getGasNavigationItems = (logout: () => void, isLoggedIn: boolean, company: BaseCompany, userProfile: UserProfile | undefined) => {
    const { routes, features } = company

    const billingAccount = userProfile && userProfile.billingAccounts.length > 0 ? userProfile.billingAccounts[0] : undefined
    const isActiveScanaRegCustomerWithHw = userProfile?.hasActiveScanaRegGasAccount && userProfile?.hasScanaHW

    if (isActiveScanaRegCustomerWithHw) {
        const accountSummaryMenuItem: ReadonlyArray<MenuItem> = [
            {
                btnLabel: 'Account Summary',
                link: Routes.AccountSummary,
                show: true,
                external: false,
            },
        ]

        return accountSummaryMenuItem
    }

    const menuItems: ReadonlyArray<MenuItem> = [
        {
            btnLabel: 'Account Summary',
            link: Routes.AccountSummary,
            show: true,
            external: false,
        },
        {
            btnLabel: 'Account Information',
            link: Routes.AccountInformation,
            show: true,
            external: false,
        },
        {
            btnLabel: 'Ways to Pay',
            link: isLoggedIn ? routes.payment.payBill(billingAccount?.billingAccountNumber) : Routes.AccountSummary,
            show: true,
            external: false,
        },
        {
            btnLabel: 'Service Requests',
            link: Routes.ServiceRequest,
            show: isLoggedIn && features.canMakeServiceRequest,
            external: false,
        },
        {
            btnLabel: 'FAQs',
            link: Routes.HelpRoute,
            show: !isLoggedIn || !userProfile?.isCommercialIndustrial,
            external: false,
        },
        {
            btnLabel: 'Log Out',
            link: '/',
            onClick: logout,
            show: isLoggedIn,
            external: false,
            hideOnNav: isLoggedIn,
        },
        {
            btnLabel: 'Create an Account',
            link: Routes.CreateAccount,
            show: !isLoggedIn,
            external: false,
        },
    ]

    return menuItems
}

const getHwNavigationItems = (logout: () => void, isLoggedIn: boolean) => {
    const menuItems: ReadonlyArray<MenuItem> = [
        {
            btnLabel: 'Account Summary',
            link: Routes.HwAccountSummary,
            show: true,
            external: false,
        },
        {
            btnLabel: 'Account Information',
            link: Routes.HwAccountInformation,
            show: true,
            external: false,
        },
        {
            btnLabel: 'Manage Payment',
            link: Routes.HwManagePayment,
            show: true,
            external: false,
        },
        {
            btnLabel: 'Service Requests',
            link: Routes.HwServiceRequests,
            show: true,
            external: false,
        },
        {
            btnLabel: 'FAQs',
            link: Routes.HwFAQs,
            show: true,
            external: false,
        },
        {
            btnLabel: 'Log Out',
            link: '/',
            onClick: logout,
            show: isLoggedIn,
            external: false,
            hideOnNav: isLoggedIn,
        },
        {
            btnLabel: 'Create an Account',
            link: Routes.CreateAccount,
            show: !isLoggedIn,
            external: false,
        },
    ]

    return menuItems
}
