import * as React from 'react'
import { useDispatch } from 'react-redux'

import styled from 'styled-components'

import { Verbiage } from '@igs-web/common-components/domain/company/companyVerbiage'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { useCompany } from '../company/hooks/useCompany'
import { userActions } from '../user/user-redux'

import { ChangeEmailForm } from './forms/change-email-form'
import { ChangePasswordForm } from './forms/change-password-form'
import { ChangeUserNameForm } from './forms/change-username-form'

const EditAccountInformationContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${Spacing.Large};
    @media (max-width: ${Breakpoint.Desktop}) {
        grid-template-columns: 1fr;
    }
`
const FormContainer = styled.div`
    margin-top: ${Spacing.Large};
`

export const EditNameSection = (): JSX.Element => {
    const dispatch = useDispatch()
    const handleOnComplete = () => dispatch(userActions.loadUser())
    return (
        <EditAccountInformationContainer>
            <div>
                <ChangeUserNameForm onComplete={handleOnComplete} />
            </div>
        </EditAccountInformationContainer>
    )
}

export const EditEmailSection = (): JSX.Element => {
    const dispatch = useDispatch()
    const handleOnComplete = () => dispatch(userActions.loadUser())
    const { verbiage } = useCompany()
    return (
        <>
            {verbiage[Verbiage.changeEmail]}
            <EditAccountInformationContainer>
                <FormContainer>
                    <ChangeEmailForm onComplete={handleOnComplete} />
                </FormContainer>
            </EditAccountInformationContainer>
        </>
    )
}

export const EditPasswordSection = () => {
    const dispatch = useDispatch()
    const handleOnComplete = () => dispatch(userActions.loadUser())
    return (
        <EditAccountInformationContainer>
            <FormContainer>
                <ChangePasswordForm onComplete={handleOnComplete} />
            </FormContainer>
        </EditAccountInformationContainer>
    )
}
