import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const CircleReview = ({ altText = 'Review Order', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} altText={altText} width="50" height="50" viewBox="0 0 50 50">
        <g id="Group_534" data-name="Group 534" transform="translate(4776 -3227)">
            <g id="Ellipse_208" data-name="Ellipse 208" transform="translate(-4776 3227)" className="icon-border-circle" strokeWidth="3">
                <circle cx="25" cy="25" r="25" stroke="none" />
                <circle cx="25" cy="25" r="23.5" fill="none" />
            </g>
            <path id="file-alt-regular" d="M14.515,12.5V13.91a.607.607,0,0,1-.6.6H5.443a.607.607,0,0,1-.6-.6V12.5a.607.607,0,0,1,.6-.6H13.91A.607.607,0,0,1,14.515,12.5Zm-.6,3.629H5.443a.607.607,0,0,0-.6.6v1.411a.607.607,0,0,0,.6.6H13.91a.607.607,0,0,0,.6-.6V16.732A.607.607,0,0,0,13.91,16.127Zm5.443-9.48V23.385A2.42,2.42,0,0,1,16.934,25.8H2.419A2.42,2.42,0,0,1,0,23.385V2.419A2.42,2.42,0,0,1,2.419,0H12.705a2.419,2.419,0,0,1,1.708.711l4.228,4.228A2.41,2.41,0,0,1,19.353,6.647ZM12.9,2.616V6.451h3.835L12.9,2.616Zm4.032,20.769V8.87H11.692a1.207,1.207,0,0,1-1.21-1.21V2.419H2.419V23.385Z" transform="translate(-4761 3239)" className="icon-core" />
        </g>
    </SvgIcon>
)

export default CircleReview