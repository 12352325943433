import * as React from 'react'
import styled from 'styled-components'

import { OfferModel } from '@igs-web/common-models/models/offer-model'
import { EncodedHtml } from '@igs-web/common-ui-components/_atoms/encode-html'
import { Header3, Header4, StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { commonGridCss } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Modal } from '@igs-web/common-ui-components/_molecules/modals/modal'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { bodyColor, darkDark, fontSizeExtraLarge, fontSizeLarge, fontSizeMedium } from '@igs-web/common-ui-components/styles/theme'
import { apiClient } from '@igs-web/common-utilities/api/api-client'
import { getPriceWithoutUnitOfMeasureForProduct } from '@igs-web/common-utilities/utilities/offer-utilities'

import { HWCardDetails } from './home-warranty-detail-card'
import { HomeWarrantyPrice, HomeWarrantyPriceDisplay } from './home-warranty-detail-components'

const Covered = styled.div`
    display: contents;
    li {
        background-image: var(--list-icon-check) no-repeat top center;

        background: var(--list-icon-check, url('${apiClient.getAssetPath('product-selection/check.svg')}')) no-repeat left 5px;
        list-style: none;
    }
`
const NotCovered = styled.div`
    display: contents;

    li {
        background: var(--list-icon-cross, url('${apiClient.getAssetPath('product-selection/cross.svg')}')) no-repeat left 5px;
        list-style: none;
    }
`
const StyledModal = styled(Modal)`
    padding-top: ${Spacing.Large};
`

const ModalGrid = styled.article`
    display: grid;
    
    --border-box-color: ${darkDark};
    max-width: ${Breakpoint.Desktop};
    padding: ${Spacing.Large};
    ul {
        ${commonGridCss}
        grid-template-columns: 1fr 1fr;
        padding-inline-start:0;
        li {
            --shift : calc(.7 * ${Spacing.ExtraLarge});
            margin-inline-start: calc(-1 * var(--shift));
            padding-inline-start: var(--shift);
        }

    }
    ${Header4} {
        padding:0;
        padding-block-start: ${Spacing.Large};
        margin:0;
        font-size ${fontSizeLarge};
        font-weight: ${FontWeight.Bold};
        line-height: 1;        
        color: ${bodyColor};
    }
    > header {
        --header-color: var(--igsblue);
        ${Header3} {
            font-size: ${fontSizeExtraLarge};
            font-weight: ${FontWeight.Bold};
            line-height: 1;        
            margin:0;
        }
        ${HomeWarrantyPriceDisplay} {
            font-weight: ${FontWeight.Medium};
            ${HomeWarrantyPrice} {
                font-size: ${fontSizeMedium};
            }
        }
        grid-column: -1 /1;
    }
    ${StrongText} {
        margin: 0;
        padding-block: ${Spacing.Large};
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        width: 90%;
        margin: auto;
    }
`

const listIconStyle = (assetPath: string): React.CSSProperties =>
    ({
        '--list-icon-check': `url('${assetPath}product-selection/check.svg')`,
        '--list-icon-cross': `url('${assetPath}product-selection/cross.svg')`,
    } as React.CSSProperties)

export const HomeWarrantyDetailModal = ({ detail, offer, isOpen, onClose }: Props) => {
    const listStyles = listIconStyle(`${apiClient.cdnUrl}assets/`)

    return (
        <StyledModal isOpen={isOpen} onClose={onClose}>
            <ModalGrid style={listStyles}>
                <header>
                    <Header3>{detail.name}</Header3>
                    {offer && (
                        <HomeWarrantyPriceDisplay>
                            <HomeWarrantyPrice>{getPriceWithoutUnitOfMeasureForProduct(offer.primaryProduct, false)}</HomeWarrantyPrice>/mo.{' '}
                        </HomeWarrantyPriceDisplay>
                    )}
                </header>
                <Covered>
                    <Header4>Protected</Header4>
                    <EncodedHtml wrapper="div" content={detail.includesText} />
                    <EncodedHtml wrapper="div" content={detail.includesExamples} />
                </Covered>
                <NotCovered>
                    <Header4>Not Protected</Header4>
                    <EncodedHtml wrapper="div" content={detail.notIncludedText} />
                    <EncodedHtml wrapper="div" content={detail.notIncludedExamples} />
                </NotCovered>
            </ModalGrid>
        </StyledModal>
    )
}
interface Props {
    readonly detail: HWCardDetails
    readonly offer?: OfferModel
    readonly isOpen: boolean
    readonly onClose: () => void
}
