import * as React from 'react'

import { AccountDetailsCard } from '@igs-web/common-components/domain/account-details/account-details-card/account-details-card'
import { AccountDetailsCardBody } from '@igs-web/common-components/domain/account-details/account-details-card/account-details-card-body'
import { AccountDetailsCardHeader } from '@igs-web/common-components/domain/account-details/account-details-card/account-details-card-header'
import { UserProfileAccount } from '@igs-web/common-models/models'
import { AccountStatus } from '@igs-web/common-models/models/account-model'

import { ActiveGasAccount } from './active-gas-account'
import { ActiveHwAccount, InactiveHwAccount } from './hw-account'
import { InactiveGasAccount } from './inactive-gas-account'
import { PendingAccount } from './pending-account'
import { PendingContractAlert } from './pending-contract-alert'

export const GasPlanAtAGlance = ({ account, hasSmallCommercialAccount, isCommercialIndustrial, isAutoRenew }: GasPlanAtAGlanceProps) => {
    const { status, hasPendingContract } = account
    return (
        <AccountDetailsCard>
            <AccountDetailsCardHeader>Plan at a Glance</AccountDetailsCardHeader>
            <AccountDetailsCardBody>
                {hasPendingContract && <PendingContractAlert />}
                {status === AccountStatus.active && (
                    <ActiveGasAccount account={account} isCommercialIndustrial={isCommercialIndustrial} isAutoRenew={isAutoRenew} />
                )}
                {status === AccountStatus.inactive && <InactiveGasAccount account={account} hasSmallCommercialAccount={hasSmallCommercialAccount} />}
                {status === AccountStatus.pending || (status === AccountStatus.pendingReload && <PendingAccount />)}
            </AccountDetailsCardBody>
        </AccountDetailsCard>
    )
}

export const HwPlanAtAGlance = ({ account }: HwPlanAtAGlanceProps) => {
    if (!account) {
        return (
            <AccountDetailsCard>
                <AccountDetailsCardHeader>Plan at a Glance</AccountDetailsCardHeader>
                <AccountDetailsCardBody>Please select an account to view your plan.</AccountDetailsCardBody>
            </AccountDetailsCard>
        )
    }

    return (
        <AccountDetailsCard>
            <AccountDetailsCardHeader>Plan at a Glance</AccountDetailsCardHeader>
            <AccountDetailsCardBody>
                {account.hasPendingContract && <PendingContractAlert />}
                {account.status === AccountStatus.active && <ActiveHwAccount account={account} />}
                {account.status === AccountStatus.pending && <PendingAccount />}
                {account.status === AccountStatus.inactive && <InactiveHwAccount />}
            </AccountDetailsCardBody>
        </AccountDetailsCard>
    )
}

interface HwPlanAtAGlanceProps {
    readonly account?: UserProfileAccount
}

interface GasPlanAtAGlanceProps {
    readonly account: UserProfileAccount
    readonly hasSmallCommercialAccount: boolean
    readonly isCommercialIndustrial: boolean
    readonly isAutoRenew: boolean
}
