import * as React from 'react'
import { BsFillExclamationCircleFill } from 'react-icons/bs'

import styled from 'styled-components'

import { AlertCard } from '@igs-web/common-components/domain/alerts/alert-card'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { PaymentExtension } from '@igs-web/common-models/models/user-profile-model'
import { Button, ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { LinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { secondaryDark } from '@igs-web/common-ui-components/styles/theme'
import { MoneyFormat, formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'
import { formatDate } from '@igs-web/common-utilities/utilities/date-utilities'

import { PaymentExtensionProps } from './payment-extension-page'

const ExclamationPointIcon = styled(BsFillExclamationCircleFill)`
    background-color: ${secondaryDark};
`
const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: max-content max-content;
    grid-column-gap: ${Spacing.Large};
    @media (max-width: ${Breakpoint.Mobile}) {
        grid-template-columns: max-content;
        grid-row-gap: ${Spacing.Medium};
        > Button {
            grid-row: 1;
        }
    }
`

export const CreatePaymentExtension = ({
    billingAccountNumber,
    amountDue,
    pastDueBalance,
    paymentExtension,
    orginalDueDate,
    handleSubmit,
}: CreatePaymentExtensionProps) => {
    const { routes } = useCompany()

    return (
        <>
            <p>Need more time to pay?</p>
            <p>Create a payment extension to give yourself more time to pay.</p>
            {!!pastDueBalance && (
                <AlertCard>
                    <span>
                        <ExclamationPointIcon />
                    </span>
                    You have a past due account balance of {formatAsMoney(pastDueBalance, MoneyFormat.Dollars)}
                </AlertCard>
            )}
            <p>
                <strong>Extension:</strong> Pay <strong>{formatAsMoney(amountDue, MoneyFormat.Dollars)}</strong> on or before{' '}
                <strong>{formatDate(paymentExtension?.proposedPaymentExtensionDueDate)}</strong>
                <br />
                (original due date was {formatDate(orginalDueDate)})
            </p>
            <ButtonContainer>
                <LinkButton buttonStyle={ButtonStyleType.Secondary} href={routes.payment.payBill(billingAccountNumber)}>
                    CANCEL
                </LinkButton>
                <Button onClick={handleSubmit} buttonStyle={ButtonStyleType.Primary}>
                    CREATE EXTENSION
                </Button>
            </ButtonContainer>
        </>
    )
}

interface CreatePaymentExtensionProps extends PaymentExtensionProps {
    readonly paymentExtension?: PaymentExtension
    readonly handleSubmit: () => void
    readonly orginalDueDate?: number
    readonly pastDueBalance?: number
}
