import React from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'

import { UserProfileAccount, UserProfileBillingAccount, UserProfileServiceAddress, UtilityAccountDetail } from '@igs-web/common-models/models'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { borderBoxColor, fontSizeVerySmall, lightLight } from '@igs-web/common-ui-components/styles/theme'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { getBillingAccountServiceAddresses } from '@igs-web/common-utilities/utilities/billing-account-utilities'

import { BillingAccountSelect } from '../billing/billing-accounts/billing-account-select'
import { CommonReduxState } from '../common-redux'
import { PaymentPortalForm } from '../payment/payment-portal-form'
import { UserSelectors } from '../user/user-redux'

const PaymentsAndBillingContainer = styled.div`
    background: ${lightLight};
    display: grid;
    @media (max-width: ${Breakpoint.Desktop}) {
        display: flex;
        flex-direction: column;
    }
`

//-- TODO: When additional segements are added, add this border back
const BillingAccountSelectWrapper = styled.div`
    // border: 1px solid ${borderBoxColor};
    padding: ${Spacing.Medium};
    & > * {
        max-width: 100%;
    }
`

//-- TODO: When additional segements are added, add this border back
const Segment = styled.div`
    // border: 1px solid ${borderBoxColor};
    display: grid;
    grid-template-areas: 'title status action';
    grid-template-columns: 3fr 6fr 3fr;
    align-items: start;
    padding-inline: ${Spacing.Medium};
    @media (max-width: ${Breakpoint.Desktop}) {
        display: flex;
        flex-direction: column;
        gap: ${Spacing.Medium};
    }
`

const OddSegment = styled(Segment)`
    background: ${lightLight};
    padding-block: ${Spacing.Small};
    height: fit-content;
`

const Title = styled.div`
    grid-area: title;
    font-weight: bold;
`

const Status = styled.div`
    grid-area: status;
`

const StatusValue = styled.p`
    grid-area: title;
    margin: unset;
`

const StatusNote = styled.p`
    font-size: ${fontSizeVerySmall};
    margin: unset;
`

const Action = styled.div`
    grid-area: action;
    width: max-content;
    text-align: end;
    align-self: center;
`

export const PaymentsAndBillingSection = () => {
    // REVISIT This whole file acts like there is only ever one service address/partyaccountid
    const userProfile = useSelector((store: CommonReduxState) => UserSelectors.selectProfile(store))
    const isLoggedIn = useSelector(UserSelectors.selectLoggedIn)
    const billingAccounts = userProfile ? userProfile.billingAccounts : Array<UserProfileBillingAccount>()
    const serviceAddresses = userProfile ? userProfile.serviceAddresses : Array<UserProfileServiceAddress>()
    const serviceAccounts = userProfile ? userProfile.accounts : Array<UserProfileAccount>()

    const [utilityAccountDetail, setUtilityAccountDetail] = React.useState<UtilityAccountDetail | null>(null)
    const [selectedBillingAccount, setSelectedBillingAccount] = React.useState<UserProfileBillingAccount | null>(null)

    const [isAutoPayEnrolled, setIsAutoPayEnrolled] = React.useState(false)

    React.useEffect(() => {
        if (billingAccounts.length === 1 && !selectedBillingAccount) {
            selectBillingAccount(billingAccounts[0])
        }
    }, [billingAccounts.length])

    React.useEffect(() => {
        if (utilityAccountDetail) {
            setIsAutoPayEnrolled(utilityAccountDetail.isFiservAutoPayEnrolled)
        }
    }, [utilityAccountDetail?.isFiservAutoPayEnrolled])

    const serviceAddressForBillingAccount = getBillingAccountServiceAddresses(billingAccounts[0], serviceAddresses, serviceAccounts)

    const selectBillingAccount = async (billingAccount: UserProfileBillingAccount) => {
        if (billingAccount.partyAccountIds) {
            const utilityAccount = await customerInfoApiClient.getUtilityAccountDetails(billingAccount.partyAccountIds[0])

            if (utilityAccount) {
                setUtilityAccountDetail(utilityAccount)
                setSelectedBillingAccount(billingAccount)
            }
        }
    }

    const handleSelect = (billingAccount: UserProfileBillingAccount) => {
        selectBillingAccount(billingAccount)
    }

    return (
        <PaymentsAndBillingContainer>
            {!userProfile || !userProfile.billingAccounts.length ? (
                <p>We could not find any billing accounts.</p>
            ) : (
                <>
                    {billingAccounts.length > 1 && (
                        <BillingAccountSelectWrapper>
                            <BillingAccountSelect
                                serviceAddresses={serviceAddresses}
                                serviceAccounts={serviceAccounts}
                                billingAccounts={userProfile.billingAccounts}
                                selectedBillingAccountKey={selectedBillingAccount ? selectedBillingAccount.billingAccountNumber : null}
                                onSelect={handleSelect}
                            />
                        </BillingAccountSelectWrapper>
                    )}
                    {selectedBillingAccount && (
                        <>
                            <OddSegment>
                                <div>
                                    <Title>Automatic Payments</Title>
                                    <br />
                                    <StatusValue>{isAutoPayEnrolled ? 'Enrolled' : 'Not Enrolled'}</StatusValue>
                                </div>
                                <Status>
                                    <StatusValue>
                                        Please note that when clicking the MANAGE AUTOPAY button, you will be transferred onto our billing platform, BillMatrix.
                                    </StatusValue>
                                    <StatusNote>It may take up to 24 hours for your enrollment status to update.</StatusNote>
                                </Status>
                                <Action>
                                    <PaymentPortalForm
                                        billingAccountNumber={selectedBillingAccount.billingAccountNumber}
                                        email={userProfile.email}
                                        zipCode={serviceAddressForBillingAccount[0].zipCode}
                                        submitButtonText="Manage Autopay"
                                        isAutoPay={true}
                                        isLoggedIn={isLoggedIn}
                                        dataTestId="manage-autopay-button"
                                    />
                                </Action>
                            </OddSegment>
                        </>
                    )}
                </>
            )}
        </PaymentsAndBillingContainer>
    )
}
