import * as React from 'react'

import styled from 'styled-components'

import { lightAccent } from '@igs-web/common-ui-components/styles/theme'

export enum SvgSize {
    Tiny = '.75rem',
    XxSmall = '1rem',
    XSmall = '2rem',
    Small = '3rem',
    Medium = '5rem',
    Large = '7rem',
    FillParent = '100%',
}

const StyledSvg = styled.svg<SvgIconProps>`
    fill: ${({ color }: SvgIconProps) => color};
    height: ${({ size }: SvgIconProps) => size};
    width: ${({ size }: SvgIconProps) => size};
`

const defaultColor = lightAccent

export const SvgIcon = ({ size = SvgSize.Medium, color = defaultColor, altText, children, ...svgProps }: SvgIconProps & React.SVGAttributes<SVGElement>) => {
    return (
        <StyledSvg color={color} size={size} xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            {altText && <title>{altText}</title>}
            {children}
        </StyledSvg>
    )
}

export interface SvgIconProps {
    readonly size?: SvgSize
    readonly color?: string
    readonly altText?: string
    readonly isround?: boolean
}
