import styled from 'styled-components'

import { dashboardLabelCss } from '@igs-web/common-ui-components/_atoms/typography'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { success } from '@igs-web/common-ui-components/styles/theme'

import { AccountDetailsCardBody } from '../account-details/account-details-card/account-details-card-body'

export const DashboardTileBody = styled(AccountDetailsCardBody)`
    grid-template-columns: 1fr 2fr;
    gap: 0;
    align-items: center;
    @media (min-width: ${Breakpoint.Desktop}) {
        .btn {
            letter-spacing: 0.56px;
        }
    }
    @media (max-width: ${Breakpoint.MobileSmall}) {
        grid-gap: ${Spacing.Small};
        grid-template-columns: min-content 1fr;
    }
`
export const HeaderLabel = styled.div`
    ${dashboardLabelCss}
    grid-column: -1/1;

    @media (max-width: ${Breakpoint.MobileSmall}) {
        white-space: nowrap;
        grid-column: 1;
    }
`

export const StatusLabel = styled.div`
    --status-color: ${success};
    color: var(--status-color);
    font-weight: ${FontWeight.Bold};
    white-space: nowrap;
    @media (max-width: ${Breakpoint.MobileSmall}) {
        text-align: right;
    }
`
export const ManageCell = styled.div`
    text-align: right;

    .btn {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    a:not(.btn) {
        padding-block: 12px;
        font-size: var(--btn-font-size);
        display: inline-block;
    }
    @media (max-width: ${Breakpoint.MobileSmall}) {
        justify-self: center;
        grid-column: -1/1;
    }
`
