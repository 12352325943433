import React from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { CurrentInformation } from '@igs-web/common-components/domain/user-profile/current-information'
import { MyProfileAccordion } from '@igs-web/common-components/domain/user-profile/myprofile-accordion'
import { MyProfileProvider } from '@igs-web/common-components/domain/user-profile/myprofile-context'
import { ProfileSection } from '@igs-web/common-components/domain/user-profile/user-profile-routes'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText, InfoTextType } from '@igs-web/common-ui-components/_molecules/info-text'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { NavigationBanner } from 'molecules/header/navigation-banner'
import { ScrollToTop } from 'molecules/routing/scroll-to-top'

const MaxWidthContainer = styled(PageContentContainer)`
    width: 100%;
`

export const GasMyProfilePage = () => {
    const hashValue = location.hash && location.hash.substring(1)
    const userProfile = useSelector((store: CommonReduxState) => UserSelectors.selectProfile(store))

    /*in the event of a redirect from the Update My Information link on the ContentSidebar, returns the ProfileSections 
    for ChangeEmail and ChangePhone, as both of those sections are required to be open in case of that specific redirect*/
    const profileSections: ReadonlyArray<ProfileSection> | undefined = hashValue.split(',').map(section => {
        return ProfileSection[section]
    })

    return (
        <>
            {userProfile && (
                <MyProfileProvider userProfileId={userProfile.userProfileId}>
                    <ScrollToTop />
                    <NavigationBanner title="Account Information" />
                    <MaxWidthContainer>
                        {userProfile.hasScanaHW && (
                            <>
                                <GridSpacer spacing={Spacing.Large} />
                                <InfoText $infoTextType={InfoTextType.Informational}>
                                    Any changes to account information will also be reflected on your SCANA Energy Home Warranty account.
                                </InfoText>
                            </>
                        )}
                        <CurrentInformation />
                        <MyProfileAccordion initialPanel={profileSections.length > 0 ? profileSections : undefined} />
                    </MaxWidthContainer>
                </MyProfileProvider>
            )}
        </>
    )
}
