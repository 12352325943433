import { Contact } from './account-detail-model'
import { Address } from './address'
import { PaymentMethodModel } from './payment-model'

export type HomeWarrantyOneTimePaymentRequest = HomeWarrantyOneTimePaymentModel | HomeWarrantyEnrollmentPaymentModel

interface HomeWarrantyPaymentModelBase {
    readonly amount: number
    readonly token: string
    readonly serviceAddress: Address
    readonly isBillingSameAsServiceAddress: boolean
    readonly billingAddress: Address
    readonly paymentOptionId: string
    readonly payment: PaymentMethodModel
}

export interface HomeWarrantyOneTimePaymentModel extends HomeWarrantyPaymentModelBase {
    readonly accountHolder: Contact
    readonly saveAsRecurringPayment: boolean
}

export interface HomeWarrantyEnrollmentPaymentModel extends HomeWarrantyPaymentModelBase {
    readonly orderNumber: string
    readonly accountHolder: Contact
    readonly deviceId?: string
    readonly hasPaymentAuthorizationConsent: boolean
    readonly paymentFrequency: PaymentFrequency
}
export enum PaymentFrequency {
    Annually = 'Annually',
    Monthly = 'Monthly',
}
