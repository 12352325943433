import * as React from 'react'
import { useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { MyAccountDashboardProvider } from '@igs-web/common-components/domain/myaccount/dashboard-context'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'

import { ProtectedRoute } from 'molecules/routing/protected-route'

import { HomeWarrantyPages } from 'domain/home-warranty/home-warranty-pages'
import { GasPages } from 'domain/myaccount/gas-pages'
import * as Routes from 'domain/routes'

export const DeregulatedMyAccountRouter = () => {
    const userProfile = useSelector((store: CommonReduxState) => UserSelectors.selectProfile(store))

    if (!userProfile) {
        return <></>
    }

    return (
        <MyAccountDashboardProvider profile={userProfile}>
            <Switch>
                <ProtectedRoute path={Routes.HwMyAccountRoute}>
                    <HomeWarrantyPages />
                </ProtectedRoute>
                <ProtectedRoute>
                    <GasPages />
                </ProtectedRoute>
            </Switch>
        </MyAccountDashboardProvider>
    )
}
