import { FiserveAutopayResponse } from '@igs-web/common-components/domain/myaccount/fiserv-autopay-redux'
import { MembershipApplication } from '@igs-web/common-models/constants/membership-application'
import {
    CoverageDetailItem,
    HomeProtectionAccountClaim,
    HomeProtectionPaymentProfileResponse,
    HomeProtectionUpdatePaymentProfileResponse,
    HwBillingHistoryItem,
    UtilityAccountDetail,
} from '@igs-web/common-models/models/account-detail-model'
import { AccountKeyAndConnectionStatus } from '@igs-web/common-models/models/account-key-and-connection-status'
import { IsAutoRenew } from '@igs-web/common-models/models/auto-renew-status-response'
import { BillingAccountTransactionHistory } from '@igs-web/common-models/models/billing-account-transaction-history'
import { BudgetBillingAccountResponse } from '@igs-web/common-models/models/budget-billing'
import { ChoiceTransactionHistory } from '@igs-web/common-models/models/choice-transaction-history'
import {
    CreateAccountRequest,
    CreateAccountResponse,
    CreateAccountWithLinkRequest,
    CreateAccountWithLinkResponse,
} from '@igs-web/common-models/models/create-account-request'
import { DirectBillPaymentRequest } from '@igs-web/common-models/models/direct-bill-payment-request'
import { AccountDocument, AccountDocumentResponse } from '@igs-web/common-models/models/document'
import { HistoricRateResponse } from '@igs-web/common-models/models/historic-rates'
import { LegacyBillResponse, LegacyPaymentResponse, LegacyUsageResponse } from '@igs-web/common-models/models/legacy-data'
import { LinkAccountRequest } from '@igs-web/common-models/models/link-account-request'
import { PaymentMethodModel } from '@igs-web/common-models/models/payment-model'
import { PaymentResponse } from '@igs-web/common-models/models/payment-response'
import { BillingAccountReportRequest } from '@igs-web/common-models/models/report-requests/billing-account-report-request'
import { LegacyBillPdfRequest } from '@igs-web/common-models/models/report-requests/legacy-bill-pdf-request'
import { ApiResponse } from '@igs-web/common-models/models/responses/api-response'
import { UnlinkAccountRequest } from '@igs-web/common-models/models/unlink-account-request'
import { UsageResponse } from '@igs-web/common-models/models/usage'
import { UserProfile } from '@igs-web/common-models/models/user-profile-model'
import { UtilityAccountActivity } from '@igs-web/common-models/models/utility-account-activity'
import { VariableRateResponse } from '@igs-web/common-models/models/variable-rate-response'

import { setUserAuthToken } from '../utilities/auth-token-utilities'
import { getEasternTimezoneDate } from '../utilities/date-utilities'

import { RequestConfig, apiClient } from './api-client'

class CustomerInfoApiClient {
    public readonly getCurrentUserProfile = async (config?: RequestConfig): Promise<UserProfile | undefined> => {
        const response = await apiClient.getAuthed<UserProfile>(`${apiClient.apiBaseUrl}/UserProfile`, config)
        return response
            ? {
                  ...response,
                  accounts: response.accounts.map(a => ({
                      ...a,
                      // This is a little bit of magic. the dates come back from the server as dates, but getEasternTimezoneDate accepts date or number
                      termEndDate: a.termEndDate && getEasternTimezoneDate(a.termEndDate),
                      termStartDate: a.termStartDate && getEasternTimezoneDate(a.termStartDate),
                      earlyTerminationFeeEnforcementEndDate:
                          a.earlyTerminationFeeEnforcementEndDate && getEasternTimezoneDate(a.earlyTerminationFeeEnforcementEndDate),
                  })),
                  communitySolarSubscriptions: response.communitySolarSubscriptions.map(a => ({
                      ...a,
                      termEndDate: a.termEndDate && getEasternTimezoneDate(a.termEndDate),
                      termStartDate: a.termStartDate && getEasternTimezoneDate(a.termStartDate),
                  })),
              }
            : undefined
    }

    public readonly getIsOnPaymentArrangement = async (invoiceGroupId: number, config?: RequestConfig) =>
        await apiClient.getAuthed<boolean>(`${apiClient.apiBaseUrl}/Payment/IsOnPaymentArrangement?invoiceGroupId=${invoiceGroupId}`, config)

    public readonly getIsFiservAutoPayEnrolled = async (request: ReadonlyArray<number>, config?: RequestConfig) =>
        await apiClient.postAuthed<ReadonlyArray<FiserveAutopayResponse>>(`${apiClient.apiBaseUrl}/Payment/IsFiservAutoPayEnrolled`, request, config)

    public readonly getAccountKeyAndConnectionStatus = async (partyAccountId: number, config?: RequestConfig) =>
        await apiClient.getAuthed<AccountKeyAndConnectionStatus>(
            `${apiClient.apiBaseUrl}/UtilityAccount/GetAccountKeyAndConnectionStatus?partyAccountId=${partyAccountId}`,
            config,
        )
    public readonly getVariableRate = async (accountId: number, config?: RequestConfig) =>
        await apiClient.getAuthed<VariableRateResponse>(`${apiClient.apiBaseUrl}/Rates/Variable/${accountId}`, config)

    public readonly getUsagesByAccountId = async (accountId: number, config?: RequestConfig) =>
        await apiClient.getAuthed<UsageResponse>(`${apiClient.apiBaseUrl}/Usage?accountId=${accountId}`, config)

    public readonly getLegacyUsages = async (billingAccountNumber: number, config?: RequestConfig) =>
        await apiClient.getAuthed<ReadonlyArray<LegacyUsageResponse>>(`${apiClient.apiBaseUrl}/Legacy/Usage/${billingAccountNumber}`, config)

    public readonly getLegacyBills = async (billingAccountNumber: number, config?: RequestConfig) =>
        await apiClient.getAuthed<ReadonlyArray<LegacyBillResponse>>(`${apiClient.apiBaseUrl}/Legacy/Bill/${billingAccountNumber}`, config)

    public readonly getLegacyPayments = async (billingAccountNumber: number, config?: RequestConfig) =>
        await apiClient.getAuthed<ReadonlyArray<LegacyPaymentResponse>>(`${apiClient.apiBaseUrl}/Legacy/Payment/${billingAccountNumber}`, config)

    public readonly getLegacyBillPdf = async (request: LegacyBillPdfRequest, config?: RequestConfig) =>
        await apiClient.postBlobAuthed(`${apiClient.apiBaseUrl}/Legacy/Bill/Pdf`, request, config)

    public readonly getHistoricRatesByAccountId = async (accountId: number, config?: RequestConfig) =>
        await apiClient.getAuthed<ReadonlyArray<HistoricRateResponse>>(`${apiClient.apiBaseUrl}/HistoricRates?accountId=${accountId}`, config)

    public readonly getDocumentsByAccountId = async (accountId: number, config?: RequestConfig) =>
        await apiClient.getAuthed<ReadonlyArray<AccountDocumentResponse>>(`${apiClient.apiBaseUrl}/AccountDocuments?partyAccountId=${accountId}`, config)

    public readonly getDocument = async (accountDocument: AccountDocument) => {
        let queryParams = `documentRoutingCode=${accountDocument.documentRoutingCode}`
        queryParams += `&documentRoutingId=${accountDocument.documentRoutingId}`

        if (accountDocument.documentKey) {
            queryParams += `&documentKey=${accountDocument.documentKey}`
        }
        queryParams += `&documentName=${accountDocument.displayName}`
        return await apiClient.getBlobAuthed(`${apiClient.apiBaseUrl}/Document?${queryParams}`)
    }

    public readonly getUsagePdfByAccountId = async (accountId: number, config?: RequestConfig) =>
        await apiClient.getBlobAuthed(`${apiClient.apiBaseUrl}/Report/UsagePdf?accountId=${accountId}`, config)

    public readonly getUsageCsvByAccountId = async (accountId: number, config?: RequestConfig) =>
        await apiClient.getBlobAuthed(`${apiClient.apiBaseUrl}/Report/UsageCsv?accountId=${accountId}`, config)

    public readonly getBillsPdfByAccountId = async (accountId: number, config?: RequestConfig) =>
        await apiClient.getBlobAuthed(`${apiClient.apiBaseUrl}/Report/BillsPdf?accountId=${accountId}`, config)

    public readonly getBillsCsvByAccountId = async (accountId: number, config?: RequestConfig) =>
        await apiClient.getBlobAuthed(`${apiClient.apiBaseUrl}/Report/BillsCsv?accountId=${accountId}`, config)

    public readonly getPaymentsPdfByAccountId = async (accountId: number, config?: RequestConfig) =>
        await apiClient.getBlobAuthed(`${apiClient.apiBaseUrl}/Report/PaymentsPdf?accountId=${accountId}`, config)

    public readonly getPaymentsCsvByAccountId = async (accountId: number, config?: RequestConfig) =>
        await apiClient.getBlobAuthed(`${apiClient.apiBaseUrl}/Report/PaymentsCsv?accountId=${accountId}`, config)

    public readonly getBillingAccountBillsPdfByAccountIds = async (request: BillingAccountReportRequest, config?: RequestConfig) =>
        await apiClient.postBlobAuthed(`${apiClient.apiBaseUrl}/Report/BillingAccountBillsPdf`, request, config)

    public readonly getBillingAccountBillsCsvByAccountIds = async (request: BillingAccountReportRequest, config?: RequestConfig) =>
        await apiClient.postBlobAuthed(`${apiClient.apiBaseUrl}/Report/BillingAccountBillsCsv`, request, config)

    public readonly getBillingAccountPaymentsPdfByAccountIds = async (request: BillingAccountReportRequest, config?: RequestConfig) =>
        await apiClient.postBlobAuthed(`${apiClient.apiBaseUrl}/Report/BillingAccountPaymentsPdf`, request, config)

    public readonly getBillingAccountPaymentsCsvByAccountIds = async (request: BillingAccountReportRequest, config?: RequestConfig) =>
        await apiClient.postBlobAuthed(`${apiClient.apiBaseUrl}/Report/BillingAccountPaymentsCsv`, request, config)

    public readonly getDocumentByUrl = async (url: string) => await apiClient.getBlobAuthed(url)

    public readonly getBudgetBillingInformation = async (request: ReadonlyArray<string>, config?: RequestConfig) =>
        await apiClient.postAuthed<ReadonlyArray<BudgetBillingAccountResponse>>(`${apiClient.apiBaseUrl}/BudgetBilling/Information`, request, config)

    public readonly getHPCoverages = async (accountId: number, config?: RequestConfig) =>
        await apiClient.getAuthed<ReadonlyArray<CoverageDetailItem>>(`${apiClient.apiBaseUrl}/HomeWarranty/HPCoverages?accountId=${accountId}`, config)

    public readonly getHPClaims = async (accountId: number, config?: RequestConfig) =>
        await apiClient.getAuthed<ReadonlyArray<HomeProtectionAccountClaim>>(`${apiClient.apiBaseUrl}/HomeWarranty/HPClaims?accountId=${accountId}`, config)
    public readonly getHpBillingHistory = async (accountId: number, config?: RequestConfig) =>
        await apiClient.getAuthed<ReadonlyArray<HwBillingHistoryItem>>(`${apiClient.apiBaseUrl}/HomeWarranty/GetBillingHistory?accountId=${accountId}`, config)

    public readonly getHpPaymentProfile = async (accountId: number, config?: RequestConfig) =>
        await apiClient.getAuthed<HomeProtectionPaymentProfileResponse>(`${apiClient.apiBaseUrl}/HomeWarranty/GetPaymentProfile?accountId=${accountId}`, config)

    public readonly updatePaymentProfile = async (accountId: number, paymentRequest: PaymentMethodModel, config?: RequestConfig) =>
        await apiClient.postAuthed<HomeProtectionUpdatePaymentProfileResponse>(
            `${apiClient.apiBaseUrl}/HomeWarranty/${accountId}/UpdatePaymentProfile`,
            paymentRequest,
            config,
        )

    public readonly getMembershipsByEmail = async (email: string, config?: RequestConfig) => {
        const cacheKey = `${email}-memberships`
        return await apiClient.cachedGetAuthed<ReadonlyArray<string>>(
            `${apiClient.apiBaseUrl}/UserProfile/GetMembershipsByEmail?email=${email}`,
            cacheKey,
            false,
            config,
        )
    }

    public readonly updateEmailAddress = async (newEmailAddress: string, currentPassword: string, config?: RequestConfig) => {
        const token = await apiClient.postAuthed<ApiResponse<string>>(`${apiClient.apiBaseUrl}/Email/ChangeEmail`, { newEmailAddress, currentPassword }, config)
        if (token && token.data) {
            setUserAuthToken(token.data)
        }
    }

    public readonly createUserAccountWithLink = async (signup: CreateAccountWithLinkRequest, config?: RequestConfig) =>
        await apiClient.post<CreateAccountWithLinkResponse>(`${apiClient.apiBaseUrl}/User/CreateAccountWithLink`, signup, config)

    public readonly createUserAccount = async (signup: CreateAccountRequest, config?: RequestConfig) => {
        const result = await apiClient.post<CreateAccountResponse>(`${apiClient.apiBaseUrl}/User/CreateAccount`, signup, config)

        if (!result) {
            throw Error('Result not found')
        }
        if (!result.success) {
            throw Error(result.message)
        }

        return result
    }
    public readonly updateCommunicationPreferences = async (isPaperless: boolean, config?: RequestConfig) =>
        await apiClient.postAuthed<never>(`${apiClient.apiBaseUrl}/UserProfile/UpdateCommunicationPreferences`, { isPaperless }, config)

    public readonly linkAccount = async (request: LinkAccountRequest, config?: RequestConfig) =>
        await apiClient.postAuthed<ApiResponse<string>>(`${apiClient.apiBaseUrl}/UserProfile/LinkAccount`, request, config)

    public readonly unlinkAccount = async (request: UnlinkAccountRequest, config?: RequestConfig) =>
        await apiClient.postAuthed<never>(`${apiClient.apiBaseUrl}/UserProfile/UnlinkAccount`, request, config)

    public readonly changePassword = async (currentPassword: string, newPassword: string, config?: RequestConfig) => {
        const token = await apiClient.postAuthed<ApiResponse<string>>(
            `${apiClient.apiBaseUrl}/Password/ChangePassword`,
            { currentPassword, newPassword },
            config,
        )
        if (token && token.data) {
            setUserAuthToken(token.data)
        }
    }

    public readonly resetPassword = async (email: string, config?: RequestConfig) =>
        await apiClient.post<never>(`${apiClient.apiBaseUrl}/Email/SendMyAccountPasswordResetEmail`, email, config)

    public readonly updatePasswordFromReset = async (newPassword: string, passwordResetKey: string, config?: RequestConfig) => {
        const token = await apiClient.post<ApiResponse<string>>(
            `${apiClient.apiBaseUrl}/Password/ResetPasswordFromEmailLink`,
            { passwordResetKey, newPassword },
            config,
        )
        if (token && token.data) {
            setUserAuthToken(token.data)
        }
    }

    public readonly sendHPClaim = async (accountId: number, message: string, config?: RequestConfig) => {
        const body = {
            accountId,
            claimText: message,
        }

        return await apiClient.postAuthed<unknown>(`${apiClient.apiBaseUrl}/HomeWarranty/HPSendClaim`, body, config)
    }

    public readonly changeUsername = async (firstName: string, lastName: string, config?: RequestConfig) =>
        await apiClient.postAuthed<never>(`${apiClient.apiBaseUrl}/UserProfile/ChangeUsername`, { firstName, lastName }, config)

    public readonly makePayment = async (request: DirectBillPaymentRequest, config?: RequestConfig) =>
        await apiClient.postAuthed<PaymentResponse>(`${apiClient.apiBaseUrl}/UtilityAccount/MakePayment`, request, config)

    public readonly registerAutopay = async (accountId: number, payment: PaymentMethodModel, config?: RequestConfig) =>
        await apiClient.postAuthed<unknown>(`${apiClient.apiBaseUrl}/UtilityAccount/${accountId}/AutoPay`, payment, config)

    public readonly removeFromAutopay = async (accountId: number, config?: RequestConfig) =>
        await apiClient.postAuthed<unknown>(`${apiClient.apiBaseUrl}/UtilityAccount/RemoveAutoPaySettings?accountId=${accountId}`, null, config)

    public readonly getUtilityAccountActivity = async (accountId: number, config?: RequestConfig) =>
        await apiClient.getAuthed<UtilityAccountActivity>(`${apiClient.apiBaseUrl}/UtilityAccount/Activity?accountId=${accountId}`, config)

    public readonly getTransactionHistory = async (accountId: number, config?: RequestConfig) => {
        return await apiClient.getAuthed<ChoiceTransactionHistory>(`${apiClient.apiBaseUrl}/UtilityAccount/TransactionHistory?accountId=${accountId}`, config)
    }
    public readonly getIsAutoRenew = async (partyAccountId: number, config?: RequestConfig) =>
        await apiClient.getAuthed<IsAutoRenew>(`${apiClient.apiBaseUrl}/UtilityAccount/IsAutoRenew?accountId=${partyAccountId}`, config)

    public readonly getUtilityAccountDetails = async (partyAccountId: number, config?: RequestConfig) =>
        await apiClient.getAuthed<UtilityAccountDetail>(`${apiClient.apiBaseUrl}/UtilityAccount/Details?accountId=${partyAccountId}`, config)

    public readonly getInvoice = async (accountId: number, invoiceNumber: string, config?: RequestConfig) =>
        await apiClient.getBlobAuthed(`${apiClient.apiBaseUrl}/UtilityAccount/${accountId}/Invoice/${invoiceNumber}`, config)

    public readonly requestEmailVerification = async (config?: RequestConfig) =>
        await apiClient.postAuthed<never>(`${apiClient.apiBaseUrl}/Email/SendMyAccountVerificationEmail`, config)

    public readonly verifyEmail = async (authenticationKey: string, config?: RequestConfig) =>
        await apiClient.post<unknown>(`${apiClient.apiBaseUrl}/Email/VerifyEmail`, { authenticationKey }, config)

    public readonly getBillingAccountTransactionhistory = async (accountIds: ReadonlyArray<number>, config?: RequestConfig) =>
        await apiClient.postAuthed<BillingAccountTransactionHistory>(
            `${apiClient.apiBaseUrl}/UtilityAccount/BillingAccountTransactionHistory`,
            accountIds,
            config,
        )

    public readonly getUserNameFromResetKey = async (passwordResetKey: string, config?: RequestConfig) =>
        await apiClient.get<ApiResponse<string>>(`${apiClient.apiBaseUrl}/Email/GetEmailByPasswordResetKey?passwordResetKey=${passwordResetKey}`, config)

    public readonly getMyaccountMembershipsFromResetKey = async (passwordResetKey: string, config?: RequestConfig) =>
        await apiClient.get<ApiResponse<ReadonlyArray<MembershipApplication>>>(
            `${apiClient.apiBaseUrl}/Password/GetMembershipApplicationsFromResetKey?passwordResetKey=${passwordResetKey}`,
            config,
        )
}

export const customerInfoApiClient = new CustomerInfoApiClient()
