import * as React from 'react'

import { motion } from 'framer-motion'
import styled from 'styled-components'

import { lgWidth, lightLight } from '../styles/theme'

export const caretSize = '0.625rem'

export const BaseCaret = styled(props => <motion.div {...props} />)<Props>`
    --_background-color: var(--panel-background-color, ${lightLight});

    display: none;
    @media (min-width: ${({ $showOnMobile }: Props) => ($showOnMobile ? 0 : lgWidth)}) {
        display: block;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: ${caretSize};
        bottom: -1px;
        left: calc(50% - ${caretSize});
    }
`

export const Caret = styled(BaseCaret)`
    @media (min-width: ${({ $showOnMobile }: Props) => ($showOnMobile ? 0 : lgWidth)}) {
        border-bottom-color: var(--_background-color);
    }
`

export const CaretDown = styled(BaseCaret)`
    @media (min-width: ${({ $showOnMobile }: Props) => ($showOnMobile ? 0 : lgWidth)}) {
        bottom: calc(-2 * ${caretSize});
        border-top-color: var(--_background-color);
    }
`

interface Props {
    readonly $showOnMobile: boolean
}
