import * as React from 'react'
import { BsFillExclamationCircleFill } from 'react-icons/bs'

import styled from 'styled-components'

import { primaryLight } from '@igs-web/common-ui-components/styles/theme'
import { formatDate } from '@igs-web/common-utilities/utilities/date-utilities'

import { AlertCard } from '../alerts/alert-card'

const AlertCardStyled = styled(AlertCard)`
    background-color: ${primaryLight};
`

export const PaymentExtensionAlert = ({ paymentExtensionDueDate }: PaymentExtensionAlertProps): JSX.Element => {
    const hasPaymentExtension = !!paymentExtensionDueDate

    return (
        <>
            {hasPaymentExtension && (
                <AlertCardStyled data-testid="payment-extension-scheduled-banner">
                    <span>
                        <BsFillExclamationCircleFill />
                    </span>
                    <span data-testid="payment-extension-scheduled-banner-message">
                        You have scheduled a Payment Extension with a due date of {formatDate(paymentExtensionDueDate)}
                    </span>
                </AlertCardStyled>
            )}
        </>
    )
}

interface PaymentExtensionAlertProps {
    readonly paymentExtensionDueDate?: Date | null
}
