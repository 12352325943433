export interface CheckoutWizardMessagesModel {
    readonly showCantMatchAddressMessage: boolean
    readonly showIsCommercialServiceTypeAddressMessage: boolean
    readonly showAlreadyProvideServiceToAddressMessage: boolean
    readonly showSelectedOfferExpiredMessage: boolean
    readonly showServerErrorMessage: boolean
    readonly showOfferNotAvailableForZipMessage: boolean
}

export const initialCheckoutWizardMessagesModel: CheckoutWizardMessagesModel = {
    showCantMatchAddressMessage: false,
    showIsCommercialServiceTypeAddressMessage: false,
    showAlreadyProvideServiceToAddressMessage: false,
    showSelectedOfferExpiredMessage: false,
    showServerErrorMessage: false,
    showOfferNotAvailableForZipMessage: false,
}
