import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { UserProfile, UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'

export const getHwAccounts = userProfile => {
    return userProfile ? userProfile.accounts.filter(x => x.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty) : []
}

export const getHwServiceAddressList = (userProfile?: UserProfile, includeInactive = true): ReadonlyArray<UserProfileServiceAddress> => {
    if (!userProfile) {
        return []
    }
    const hwAccounts = getHwAccounts(userProfile)
    const addressKeys = hwAccounts.map(h => h.serviceAddressKey)

    const getServiceAddressStatus = (serviceAddressKey: string) => {
        const activeAccount = hwAccounts.find(h => h.serviceAddressKey === serviceAddressKey && h.status === AccountStatus.active)
        return !!activeAccount ? AccountStatus.active : AccountStatus.inactive
    }

    const sortedAddresses = userProfile.serviceAddresses
        .filter(sa => addressKeys.includes(sa.serviceAddressKey))
        .map(address => ({ address, isActive: getServiceAddressStatus(address.serviceAddressKey) === AccountStatus.active }))
        .filter(x => includeInactive || x.isActive)
        .sort((a, b) => {
            if (a.isActive !== b.isActive) {
                return a.isActive ? -1 : 1
            }
            return a.address.address1 + (a.address.address2 || '') >= b.address.address1 + (b.address.address2 || '') ? 1 : -1
        })

    return sortedAddresses.map(x => x.address)
}

export const getSelectedHwAccount = (userProfile: UserProfile, selectedAddressHash: string) => {
    const address = userProfile.serviceAddresses.find(sa => sa.addressHash === selectedAddressHash)
    const hwAccounts = userProfile.accounts.filter(
        a => a.serviceAddressKey === address?.serviceAddressKey && a.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty,
    )
    const activeAccount = hwAccounts.find(h => h.status === AccountStatus.active)
    if (activeAccount) {
        return activeAccount
    }
    //remove multiple inactive handler when multiple hw accounts at a single service address is added.
    return hwAccounts.sort(h => (h.termStartDate ? h.termStartDate : 0))[0]
}
