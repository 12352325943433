import * as React from 'react'

import { UserProfileAccount, UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'

import { AccountDetailsCard } from '../account-details-card/account-details-card'
import { AccountDetailsCardBody } from '../account-details-card/account-details-card-body'
import { AccountDetailsCardHeader } from '../account-details-card/account-details-card-header'
import { AccountDetailList } from './account-detail-list'

export const CustomerInformation = ({ account, serviceAddress, editLinkUrl, omitPlanName }: Props): JSX.Element => {
    if (!serviceAddress) {
        return <></>
    }

    return (
        <AccountDetailsCard>
            <AccountDetailsCardHeader>
                Customer Information
                <Link href={editLinkUrl} dataTestId="account-edit-link">
                    Edit
                </Link>
            </AccountDetailsCardHeader>
            <AccountDetailsCardBody>
                <AccountDetailList account={account} serviceAddress={serviceAddress} omitPlanName={omitPlanName}/>
            </AccountDetailsCardBody>
        </AccountDetailsCard>
    )
}

interface Props {
    readonly account: UserProfileAccount
    readonly serviceAddress?: UserProfileServiceAddress
    readonly editLinkUrl: string
    readonly omitPlanName?: boolean
}
