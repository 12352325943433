import * as React from 'react'
import { FaRegCreditCard } from 'react-icons/fa'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { EnrollmentModel } from '@igs-web/common-models/models/enrollment-model'
import { Card } from '@igs-web/common-ui-components/_atoms/card'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { CreditCheckStatus } from '@igs-web/common-utilities/api/enrollment-api-client'

import { flexSpread } from '../shared/checkout-components'

export const CreditCheckCard = ({ enrollment }: Props) => {
    const { creditCheckResults } = enrollment

    if (!creditCheckResults) {
        return <></>
    }

    const getCCComponent = () => {
        if (creditCheckResults.energyScoreStatus === CreditCheckStatus.Waive) {
            return <WaiveCreditCheck />
        }

        if (creditCheckResults.energyScoreStatus === CreditCheckStatus.Collect) {
            return <CollectCreditCheck enrollment={enrollment} />
        }

        return <DenyCreditCheck />
    }

    return (
        <Card
            header={
                <IconHeading>
                    <FaRegCreditCard /> Credit Check
                </IconHeading>
            }
        >
            {getCCComponent()}
        </Card>
    )
}

const WaiveCreditCheck = () => <div>No Deposit Required</div>

const CollectStrongText = styled(StrongText)`
    ${flexSpread}
`
const CollectCreditCheck = ({ enrollment }: Props) => (
    <CollectStrongText as="div">
        <span>Deposit Required</span> <span>${enrollment.creditCheckResults?.depositAmount}</span>
    </CollectStrongText>
)

const DenyCreditCheck = () => {
    const { phoneNumber } = useCompany()
    return (
        <div>
            You will need to call <PhoneNumberLink phoneNumber={phoneNumber.customerSupport} /> to finish checkout.
        </div>
    )
}

interface Props {
    readonly enrollment: EnrollmentModel
}
