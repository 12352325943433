import * as React from 'react'
import { useDispatch } from 'react-redux'

import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { Address } from '@igs-web/common-models/models/address'
import { BillingAccountTransactionHistory } from '@igs-web/common-models/models/billing-account-transaction-history'
import { UserProfile, UserProfileAccount, UserProfileBillingAccount, UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { getBillingAccountServiceAddresses } from '@igs-web/common-utilities/utilities/billing-account-utilities'

import { useCompany } from '../company/hooks/useCompany'
import { myAccountActions } from '../myaccount-redux'

export enum DashboardView {
    billingAccounts = 'BILLING_ACCOUNTS',
    billingDetails = 'BILLING_DETAILS',
    serviceAccount = 'SERVICE_ACCOUNT',
}

const getView = (userProfile: UserProfile): DashboardView => {
    if (userProfile.billingAccounts) {
        if (userProfile.billingAccounts?.length > 1) {
            return DashboardView.billingAccounts
        }
        return DashboardView.billingDetails
    }
    return DashboardView.serviceAccount
}

const selectAddress = (
    billingAccount: UserProfileBillingAccount,
    serviceAddresses: ReadonlyArray<UserProfileServiceAddress>,
    serviceAccounts: ReadonlyArray<UserProfileAccount>,
): Address => {
    if (billingAccount.mailDistributions.length > 0) {
        return billingAccount.mailDistributions[0]
    }

    if (billingAccount.partyAccountIds.length > 0) {
        const billingAccountaddresses = getBillingAccountServiceAddresses(billingAccount, serviceAddresses, serviceAccounts)
        if (billingAccountaddresses.length > 0) {
            return billingAccountaddresses[0]
        }
    }

    return serviceAddresses[0]
}
interface MyAccountDashboardContext {
    readonly profile: UserProfile
    readonly selectedView: DashboardView
    readonly billingAccount: UserProfileBillingAccount | undefined
    readonly serviceAddress: UserProfileServiceAddress | undefined
    readonly showPaymentDelayMessage: boolean
    readonly reloadProfileTries: number
    readonly setBillingAccount: (billingAccountNumber: string) => void
    readonly clearBillingAccount: () => void
    readonly setServiceAddress: (addressKey: string, billingAccountNumber?: string) => void
    readonly getTransactionHistory: (billingAccountNumber?: string) => Promise<BillingAccountTransactionHistory | null>
    readonly getBillingAddress: (billingAccount: UserProfileBillingAccount) => Address
    readonly getBillingAccountForPartyAccount: (accountID: number) => UserProfileBillingAccount | undefined
    readonly setShowPaymentDelayMessage: (showMessage: boolean) => void
    readonly setReloadProfileTries: (tries: number) => void
    readonly getBillingAccountStatus: (billingAccountNumber: string) => AccountStatus
    readonly getServiceAddressStatus: (serviceAddressKey: string) => AccountStatus
    readonly setIsOnPaymentPlan: (billingAccountNumber?: string, isOnPaymentPlan?: boolean) => void
}

const MyAccountDashboardContext = React.createContext<MyAccountDashboardContext | undefined>(undefined)

export const useMyAccountDashboard = (): MyAccountDashboardContext => {
    const context = React.useContext(MyAccountDashboardContext)
    if (!context) {
        throw Error('Must use useMyAccountDashboard inside of MyAccountDashboardProvider')
    }
    return context
}

export const MyAccountDashboardProvider = ({ children, profile }: Props) => {
    const company = useCompany()
    const dispatch = useDispatch()
    const [billingAccount, setBillingAccountState] = React.useState<UserProfileBillingAccount | undefined>(undefined)
    const [serviceAddress, setServiceAddressState] = React.useState<UserProfileServiceAddress | undefined>(undefined)
    const [selectedView, setSelectedView] = React.useState(getView(profile))
    const [showPaymentDelayMessage, setShowPaymentDelayMessage] = React.useState<boolean>(false)
    const [reloadProfileTries, setReloadProfileTries] = React.useState<number>(0)
    const [transactionHistoryDictionary, setTransactionHistoryDictionary] = React.useState<Record<string, BillingAccountTransactionHistory>>({})
    React.useEffect(() => {
        if (selectedView !== DashboardView.billingAccounts && !billingAccount && profile.billingAccounts) {
            setBillingAccountState(profile.billingAccounts[0])
        }

        if (selectedView === DashboardView.serviceAccount && !serviceAddress && billingAccount) {
            setServiceAddressState(profile.serviceAddresses[0])
        }
    }, [profile, billingAccount, serviceAddress, selectedView])

    const formatTransactionHistory = React.useCallback((billingAccountTransactionHistory: BillingAccountTransactionHistory) => {
        if (company.features.useLegacydata) {
            return {
                ...billingAccountTransactionHistory,
                payments: billingAccountTransactionHistory.payments.filter(pmt => {
                    const pmtDate = new Date(pmt.date)
                    return pmtDate > company.features.dataStartDate
                }),
            }
        }
        return { ...billingAccountTransactionHistory }
    }, [])

    const setIsOnPaymentPlan = React.useCallback(
        (billingAccountNumber?: string, isOnPaymentPlan?: boolean) => {
            if (billingAccountNumber && isOnPaymentPlan && billingAccount?.billingAccountNumber === billingAccountNumber) {
                setBillingAccountState({
                    ...billingAccount,
                    isOnPaymentPlan,
                })
            }
        },
        [billingAccount],
    )
    const setBillingAccount = React.useCallback(
        (billingAccountNumber: string) => {
            const item = profile.billingAccounts.find(account => account.billingAccountNumber === billingAccountNumber)
            if (item) {
                setBillingAccountState(item)
                setSelectedView(DashboardView.billingDetails)
            }
        },
        [profile.billingAccounts],
    )

    const getBillingAccount = React.useCallback(
        (billingAccountNumber: string) => {
            return profile.billingAccounts.find(account => account.billingAccountNumber === billingAccountNumber)
        },
        [profile.billingAccounts],
    )

    const getTransactionHistory = React.useCallback(
        async (billingAccountNumber?: string) => {
            const requestBillingAccount = billingAccountNumber ? getBillingAccount(billingAccountNumber) : billingAccount

            if (!requestBillingAccount) {
                return null
            }
            if (transactionHistoryDictionary[requestBillingAccount.billingAccountNumber]) {
                return transactionHistoryDictionary[requestBillingAccount.billingAccountNumber]
            }

            const transactionHistoryResponse = await customerInfoApiClient.getBillingAccountTransactionhistory(requestBillingAccount.partyAccountIds, {
                showGlobalLoader: false,
            })

            if (transactionHistoryResponse) {
                const newDictionary = {
                    ...transactionHistoryDictionary,
                }
                newDictionary[requestBillingAccount.billingAccountNumber] = formatTransactionHistory(transactionHistoryResponse)

                setTransactionHistoryDictionary(newDictionary)
            }
            return transactionHistoryResponse
        },
        [getBillingAccount, billingAccount, transactionHistoryDictionary, formatTransactionHistory],
    )

    const clearBillingAccount = React.useCallback(() => {
        setBillingAccountState(undefined)
        setSelectedView(DashboardView.billingAccounts)
    }, [])

    const setServiceAddress = React.useCallback(
        (serviceAddressKey: string, billingAccountNumber?: string) => {
            if (company.features.useBillingAccounts) {
                if (!billingAccount && !billingAccountNumber) {
                    throw Error('You must select a billing account first')
                }
                if (billingAccountNumber) {
                    setBillingAccount(billingAccountNumber)
                }
            }
            const item = profile.serviceAddresses.find(address => address.serviceAddressKey === serviceAddressKey)
            setServiceAddressState(item)
            dispatch(myAccountActions.onlySelectServiceAddress(item?.serviceAddressKey))
            setSelectedView(DashboardView.serviceAccount)
        },
        [billingAccount, profile.serviceAddresses, setBillingAccount],
    )

    const getBillingAddress = React.useCallback(
        (account: UserProfileBillingAccount) => {
            return selectAddress(account, profile.serviceAddresses, profile.accounts)
        },
        [profile],
    )

    const getBillingAccountForPartyAccount = React.useCallback(
        (accountId: number) => {
            return profile.billingAccounts.find(account => account.partyAccountIds.includes(accountId))
        },
        [profile],
    )

    const getBillingAccountStatus = React.useCallback(
        (billingAccountNumber: string) => {
            const ba = profile.billingAccounts.find(x => x.billingAccountNumber === billingAccountNumber)
            if (!ba) {
                return AccountStatus.unknown
            }

            const hasActiveAccount = profile.accounts.filter(a => ba.partyAccountIds.includes(a.accountId)).some(a => a.status !== AccountStatus.inactive)

            return hasActiveAccount ? AccountStatus.active : AccountStatus.inactive
        },
        [profile],
    )

    const getServiceAddressStatus = React.useCallback(
        (serviceAddressKey: string) => {
            const hasActiveAccount = profile.accounts.filter(a => a.serviceAddressKey === serviceAddressKey).some(a => a.status !== AccountStatus.inactive)

            return hasActiveAccount ? AccountStatus.active : AccountStatus.inactive
        },
        [profile],
    )
    const context = {
        profile,
        selectedView,
        billingAccount,
        serviceAddress,
        showPaymentDelayMessage,
        reloadProfileTries,
        setBillingAccount,
        clearBillingAccount,
        setServiceAddress,
        getTransactionHistory,
        getBillingAddress,
        getBillingAccountForPartyAccount,
        setShowPaymentDelayMessage,
        setReloadProfileTries,
        getBillingAccountStatus,
        getServiceAddressStatus,
        setIsOnPaymentPlan,
    }

    return <MyAccountDashboardContext.Provider value={context}>{children}</MyAccountDashboardContext.Provider>
}

interface Props {
    readonly profile: UserProfile
    readonly children: React.ReactNode
}
