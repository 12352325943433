import * as React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'
import { loadingManager } from '@igs-web/common-utilities/utilities/loading-manager-utilities'

import { CachedReduxDataActions, CachedReduxDataSelectors } from './cached-redux-data'

// untested
export const useCachedData = <T extends unknown>(dataKey: string | number, dataStore: DataStore) => {
    const dispatch = useDispatch()

    const data = useSelector((state: CommonReduxState) => dataStore.selectors.selectData(state, dataKey)) as T
    const isLoading = useSelector((state: CommonReduxState) => !!dataStore.selectors.selectLoading(state, dataKey))

    const reload = React.useCallback(
        (ignoreCache: boolean) => {
            dispatch(dataStore.actions.load({showGlobalLoader: true, key: dataKey, ignoreCache }))
        },
        [dataKey, dataStore, dispatch],
    )

    React.useEffect(() => {
        reload(false)
    }, [reload])

    return {
        isLoading,
        data,
        reload,
    }
}

class ConnectedDataProviderClass extends React.Component<Props, {}> {
    public componentDidMount() {
        if (this.props.dataKey) {
            this.props.loader()
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.dataKey && this.props.dataKey !== prevProps.dataKey) {
            this.props.loader()
        }
    }
    public render() {
        const { showGlobalLoader: hideLocalLoader, children, data, loader, isLoading, dataStores: caches } = this.props
        const hasAllData = data.length === caches.length
        const hasLoaders = loadingManager.loaders > 0
        return !hasLoaders && !hideLocalLoader && isLoading ? (
            <LocalLoader isLoading={true} />
        ) : !isLoading && hasAllData ? (
            children(data, () => loader(true))
        ) : null
    }
}

interface PassedProps {
    readonly dataKey: string | number
    readonly dataStores: ReadonlyArray<DataStore>
    readonly children: (data: ReadonlyArray<any>, forceCacheRefresh: () => void) => React.ReactNode
    readonly showGlobalLoader: boolean
}

const mapStateToProps = (store: CommonReduxState, { dataStores: caches, dataKey: cacheKey }: PassedProps) => ({
    data: caches.map(c => c.selectors.selectData(store, cacheKey)).filter(o => o !== undefined),
    isLoading: caches.some(c => !!c.selectors.selectLoading(store, cacheKey)),
})

const mapDispatchToProps = (dispatch, { dataStores: caches, dataKey: cacheKey, showGlobalLoader }: PassedProps) => ({
    loader: (ignoreCache?: boolean) => caches.forEach(c => dispatch(c.actions.load({ key: cacheKey, showGlobalLoader, ignoreCache }))),
})

interface DataStore {
    readonly actions: CachedReduxDataActions
    readonly selectors: CachedReduxDataSelectors<any>
}

type StoreProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = PassedProps & StoreProps & DispatchProps

export const ConnectedDataProvider = connect<StoreProps, DispatchProps, PassedProps>(mapStateToProps, mapDispatchToProps)(ConnectedDataProviderClass)
