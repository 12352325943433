import { CachedReduxDataStateType, setupCachedReduxData } from '@igs-web/common-components/domain/cached-redux/cached-redux-data'
import { LegacyBill } from '@igs-web/common-models/models/legacy-data'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

const load = async (billingAccountNumber: number, config: RequestConfig): Promise<ReadonlyArray<LegacyBill> | undefined> => {
    try {
        const bills = await customerInfoApiClient.getLegacyBills(billingAccountNumber, config)

        if (!bills) {
            throw Error(`Bills are not defined for ${billingAccountNumber}`)
        }

        return bills.map(u => ({ ...u }))
    } catch (e) {
        console.error(`There was a problem getting bills: ${e.message}`)
    }
}

const { actions, selectors, saga, reducers } = setupCachedReduxData('legacyBills', load)

export const legacyBillRedux = { actions, selectors }
export const legacyBillSaga = saga
export const legacyBillReducer = reducers
export type LegacyBillState = CachedReduxDataStateType<typeof load>
