export const enum CheckoutStepPageIds {
    SelectMyaccountServiceAddress = 'select-myaccount-service-address',
    SelectRate = 'select-rate-step',
    AccountInfo = 'account-info-step',
    ContactInfo = 'contact-info-step',
    CustomerAddress = 'address-step',
    Confirmation = 'confirmation-step',
    DirectBillPreference = 'direct-bill-preference',
    OrderSummary = 'order-summary-step',
    ResidentialDeposit = 'residential-deposit',
    RequestedStartDate = 'requested-start-date',
}
