import * as React from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

import { LegalRoutes } from '@igs-web/common-components/domain/legal/legal-routes'
import { ExternalLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import { FullWidthMessageBanner } from '@igs-web/common-ui-components/_molecules/banners/message-banner'
import { GridSpacer, commonGridCss } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { heroBackground } from '@igs-web/common-ui-components/styles/theme'

import { NavigationBanner } from 'molecules/header/navigation-banner'
import { ScrollToTop } from 'molecules/routing/scroll-to-top'

import * as Routes from 'domain/routes'

import { HWProductSelection } from './hw-product-selection'

const FooterWrapper = styled.div`
    ${commonGridCss};
    grid-template-columns: 1fr 1fr;
    @media (max-width: ${Breakpoint.Tablet}) {
        padding-inline: ${Spacing.Medium};
    }
`

export const ProductSelectionPage = () => {
    return (
        <>
            <ScrollToTop />
            <NavigationBanner title="Home Warranty" />
            <FullWidthMessageBanner
                id="planForPeaceMessage"
                isDismissable={false}
                solidBackgroundColor={heroBackground}
                isBold={false}
                dataTestId="plan-for-peace-message-banner"
            >
                <h2>Plan for Peace of Mind</h2>
                <p>
                    Did you know many vital systems like water, gas and electric lines, and water heaters aren't typically covered by homeowners insurance? Many
                    find out too late.
                </p>
                <p>SCANA Home Warranty offers plans to protect against unexpected expenses and complicated repair processes.</p>
            </FullWidthMessageBanner>
            <GridSpacer spacing={Spacing.Large} />
            <HWProductSelection />
            <FooterWrapper>
                <div>
                    <Header3>Service Agreement</Header3>
                    <p>
                        Review the <ExternalLink href={LegalRoutes.homeWarrantyServiceAgreement().toString()}>Service Agreement</ExternalLink> for further
                        details and terms.
                    </p>
                </div>
                <div>
                    <Header3>FAQs</Header3>
                    <p>
                        View our <Link to={Routes.HwFAQs}>FAQs</Link> for answers to commonly asked questions.
                    </p>
                </div>
            </FooterWrapper>
        </>
    )
}
