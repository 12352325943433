import * as React from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { Address } from '@igs-web/common-models/models'
import { LinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { AddressDisplay } from '@igs-web/common-ui-components/_molecules/address/address-display'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { ScrollToTop } from 'molecules/routing/scroll-to-top'

import { WarningHeading } from 'domain/checkout/shared/checkout-components'
import * as Routes from 'domain/routes'

const GridWrapper = styled.div`
    display: grid;
    grid-gap: ${Spacing.Large};
`

const StyledButton = styled(LinkButton)`
    width: max-content;
    justify-self: right;
`

export const AddressExistingService = ({ address }: Props) => {
    const { phoneNumber } = useCompany()
    return (
        <GridWrapper>
            <ScrollToTop />
            <WarningHeading>
                <AiOutlineInfoCircle /> Service Already Exists
            </WarningHeading>
            <AddressDisplay address={address} />
            <p>
                This service address is already protected through a Home Warranty product. For more information about your plan, call{' '}
                <PhoneNumberLink phoneNumber={phoneNumber.homeWarranty} />.
            </p>
            <StyledButton href={Routes.LoginRoute}>GO TO ACCOUNT</StyledButton>
        </GridWrapper>
    )
}

interface Props {
    readonly address: Address
}
