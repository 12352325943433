export const HomeRoute = '/'

export const LoginRoute = '/login'
export const CreateAccount = `${LoginRoute}/create-account`
export const ForgotPassword = `${LoginRoute}/forgot-pass`
export const SignInHelp = `${LoginRoute}/sign-in-help`
export const OneTimeRegistration = `${LoginRoute}/onetimeregistration`

export const ScreenShare = '/share'

export const CheckoutRoute = '/Checkout'
export const ConfirmationRoute = '/Confirmation'
export const SignupRoute = '/Signup'
export const GaSignupRoute = '/gasignup'
export const RedirectSignupRoutes = [SignupRoute, GaSignupRoute]

export const HomeWarrantCheckout = '/HomeWarranty/Checkout'
export const HomeWarrantyConfirmation = '/HomeWarranty/Confirmation'

export const ContactRoute = '/contact'
export const ContactUsRoute = '/contact-us'
export const ContactUsRoutes = [ContactRoute, ContactUsRoute]
export const HelpRoute = '/FAQs'

export const OneTimePayment = '/OneTimePayment'
export const RecurringPayment = '/RecurringPayment'
