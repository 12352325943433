import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { HwBillingHistoryItem } from '@igs-web/common-models/models'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'

import { CommonReduxState } from '../common-redux'

export const hwBillingHistoryTypes = {
    LOAD_ACCOUNT_BILLING: '[home-warranty] LOAD_ACCOUNT_BILLING',
    SET_ACCOUNT_BILLING: '[home-warranty] SET_ACCOUNT_BILLING',
    LOAD_ACCOUNT_BILLING_FAIL: '[home-warranty] LOAD_ACCOUNT_BILLING_FAIL',
    LOAD_ACCOUNT_BILLING_COMPLETE: '[home-warranty] LOAD_ACCOUNT_BILLING_COMPLETE',
}

export const hwBillingHistoryActions = {
    loadHwAccountBillingHistory: createAction<RequestConfig & { readonly accountId: number }>(hwBillingHistoryTypes.LOAD_ACCOUNT_BILLING),
    setHwAccountBillingHistory: createAction<UpdateRequest>(hwBillingHistoryTypes.SET_ACCOUNT_BILLING),
    loadHwAccountBillingHistoryFail: createAction<string>(hwBillingHistoryTypes.LOAD_ACCOUNT_BILLING_FAIL),
    loadHwAccountBillingHistoryComplete: createAction<void>(hwBillingHistoryTypes.LOAD_ACCOUNT_BILLING_COMPLETE),
}

export const hwBillingHistoryReducer = reducer<HwBillingHistoryState>({ isLoading: false })
    .add<void>(hwBillingHistoryTypes.LOAD_ACCOUNT_BILLING, state => ({
        ...state,
        isLoading: true,
        error: undefined,
    }))
    .add<UpdateRequest>(hwBillingHistoryTypes.SET_ACCOUNT_BILLING, (state, request) => ({
        ...state,
        isLoading: false,
        [request.accountId]: request.hwBillingHistory,
    }))
    .add<void>(hwBillingHistoryTypes.LOAD_ACCOUNT_BILLING_COMPLETE, state => ({
        ...state,
        isLoading: false,
    }))
    .build()

export class hwBillingHistorySelectors {
    public static readonly selectHwBillingHistory = (state: CommonReduxState, accountId: number): ReadonlyArray<HwBillingHistoryItem> | undefined =>
        state.hwBillingHistory[accountId] || []
    public static readonly selectIsLoading = (state: CommonReduxState): boolean => state.hwBillingHistory.isLoading
}

const sagas = {
    *loadHwBillingHistory(action: { readonly payload: RequestConfig & { readonly accountId: number } }) {
        const accountId = action.payload.accountId
        const currentBillingHistory: ReadonlyArray<HwBillingHistoryItem> = yield select(hwBillingHistorySelectors.selectHwBillingHistory, accountId)
        if (!currentBillingHistory.length) {
            try {
                const response: ReadonlyArray<HwBillingHistoryItem> = yield call(() => customerInfoApiClient.getHpBillingHistory(accountId, action.payload))

                const hwBillingHistory = response.map<HwBillingHistoryItem>(u => ({
                    ...u,
                    transactionDate: u.transactionDate ? Date.parse(u.transactionDate + '') : undefined,
                }))

                yield put(hwBillingHistoryActions.setHwAccountBillingHistory({ accountId, hwBillingHistory }))
            } catch (e) {
                const message = `There was a problem getting billing history: ${e.message}`
                yield put(hwBillingHistoryActions.loadHwAccountBillingHistoryFail(message))
            }
        } else {
            yield put(hwBillingHistoryActions.loadHwAccountBillingHistoryComplete())
        }
    },
}
export function* hwBillingHistorySaga() {
    yield all([takeEvery(hwBillingHistoryTypes.LOAD_ACCOUNT_BILLING, sagas.loadHwBillingHistory as any)])
}

export interface HwBillingHistoryState {
    readonly isLoading: boolean
    readonly [key: number]: ReadonlyArray<HwBillingHistoryItem>
}
interface UpdateRequest {
    readonly accountId: number
    readonly hwBillingHistory: ReadonlyArray<HwBillingHistoryItem>
}
