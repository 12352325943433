import * as Routes from 'domain/routes'
import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import { useMyAccountDashboard } from '@igs-web/common-components/domain/myaccount/dashboard-context'

const defaultTries = 5
export const DashboardReload = () => {
    const { setShowPaymentDelayMessage, setReloadProfileTries } = useMyAccountDashboard()

    useEffectOnce(() => {
        setShowPaymentDelayMessage(true)
        setReloadProfileTries(defaultTries)
    })
    return <Redirect to={Routes.AccountSummary} />
}
