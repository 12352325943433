/* eslint-disable max-len */
import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

const BlobIcon = ({ ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} id="Layer_1" viewBox="0 0 56.68 53.94">
        <path
            fillOpacity={0.2}
            d="M24.25,46.4C35.93,52.69,50.17,41,47.91,27.18,46.05,15.87,40.1,2.68,28.25,7.74,13.51,14,4.93,14.18,4.93,25.64s8,14.65,19.32,20.76"
        />
        <path
            fillOpacity={0.2}
            d="M26.69,6.14C11.56,3.3-2.35,18.66,3.53,31.47,8.34,42,18.37,53.09,31,44.85,46.78,34.6,56.77,32.06,54.1,20.91S41.36,8.9,26.69,6.14"
        />
    </SvgIcon>
)

export default BlobIcon
