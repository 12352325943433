import { FORM_ERROR, FormApi } from 'final-form'

export const formError = (errorMessage: string) => {
    return {
        success: false,
        [FORM_ERROR]: errorMessage,
    }
}

export const clearFormValues = (form: FormApi) => setTimeout(() => {
    form.getRegisteredFields()
        .forEach(f => form.change(f, undefined))

    // the previous changes could lead to fields being hidden/shown
    form.getRegisteredFields()
        .forEach(form.resetFieldState)

    // the typing here is bad, it is possible that initialValues is undefined
    form.initialize(form.getState().initialValues || {})
})