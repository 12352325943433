import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const CircleStartDate = ({ altText = 'Start Date', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} altText={altText} width="50" height="50" viewBox="0 0 50 50">
        <g id="Group_535" data-name="Group 535" transform="translate(4776 -3291)">
            <g id="Ellipse_208" data-name="Ellipse 208" transform="translate(-4776 3291)" className="icon-border-circle" strokeWidth="3">
                <circle cx="25" cy="25" r="25" stroke="none" />
                <circle cx="25" cy="25" r="23.5" fill="none" />
            </g>
            <path id="calendar-alt-regular" d="M7.484,14.563H5.461a.609.609,0,0,1-.607-.607V11.934a.609.609,0,0,1,.607-.607H7.484a.609.609,0,0,1,.607.607v2.023A.609.609,0,0,1,7.484,14.563Zm5.461-.607V11.934a.609.609,0,0,0-.607-.607H10.316a.609.609,0,0,0-.607.607v2.023a.609.609,0,0,0,.607.607h2.023A.609.609,0,0,0,12.945,13.956Zm4.854,0V11.934a.609.609,0,0,0-.607-.607H15.17a.609.609,0,0,0-.607.607v2.023a.609.609,0,0,0,.607.607h2.023A.609.609,0,0,0,17.8,13.956Zm-4.854,4.854V16.788a.609.609,0,0,0-.607-.607H10.316a.609.609,0,0,0-.607.607v2.023a.609.609,0,0,0,.607.607h2.023A.609.609,0,0,0,12.945,18.811Zm-4.854,0V16.788a.609.609,0,0,0-.607-.607H5.461a.609.609,0,0,0-.607.607v2.023a.609.609,0,0,0,.607.607H7.484A.609.609,0,0,0,8.091,18.811Zm9.709,0V16.788a.609.609,0,0,0-.607-.607H15.17a.609.609,0,0,0-.607.607v2.023a.609.609,0,0,0,.607.607h2.023A.609.609,0,0,0,17.8,18.811ZM22.654,5.663v17.8a2.428,2.428,0,0,1-2.427,2.427H2.427A2.428,2.428,0,0,1,0,23.463V5.663A2.428,2.428,0,0,1,2.427,3.236H4.854V.607A.609.609,0,0,1,5.461,0H7.484a.609.609,0,0,1,.607.607V3.236h6.472V.607A.609.609,0,0,1,15.17,0h2.023A.609.609,0,0,1,17.8.607V3.236h2.427A2.428,2.428,0,0,1,22.654,5.663Zm-2.427,17.5V8.091H2.427V23.159a.3.3,0,0,0,.3.3H19.923A.3.3,0,0,0,20.227,23.159Z" transform="translate(-4762 3303)" className="icon-core" />
        </g>
    </SvgIcon>
)

export default CircleStartDate