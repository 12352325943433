import * as React from 'react'
import { useSelector } from 'react-redux'

import { CheckoutStepPageIds } from '@igs-web/common-components/domain/checkout/checkout-step-page-ids'
import { useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { useWizardState } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'

import { AddressCard } from './address-card'
import { CreditCheckCard } from './credit-check-card'
import { MyDetailsCard } from './my-details-card'
import { RateCard } from './rate-card'
import { gasShowAddressCard, gasShowCreditCheckCard, gasShowMyDetailsCard, gasShowRateCard } from './your-plan-service'

export const YourPlanGas = () => {
    const enrollmentState = useCheckoutState()
    const selectedOffers = useSelector((store: CommonReduxState) => OfferSelectors.selectSelectedOffers(store))
    const primaryProduct = selectedOffers[0]?.primaryProduct
    const { activePageId } = useWizardState()
    const offerRequest = useSelector((store: CommonReduxState) => OfferSelectors.selectOfferRequest(store))

    const showRateCard = gasShowRateCard(selectedOffers[0]?.primaryProduct)
    const showAddressCard = gasShowAddressCard(activePageId, enrollmentState.serviceAddress)
    const showMyDetailsCard = gasShowMyDetailsCard(enrollmentState.defaultContact.firstName)
    const showCredicCheckCard = gasShowCreditCheckCard(enrollmentState.creditCheckResults)

    const hideAddressEditButton = activePageId === CheckoutStepPageIds.CustomerAddress
    const hideRateEditButton = activePageId === CheckoutStepPageIds.SelectRate || activePageId === CheckoutStepPageIds.CustomerAddress
    const hideMyDetailsEditButton =
        activePageId === CheckoutStepPageIds.ContactInfo ||
        activePageId === CheckoutStepPageIds.CustomerAddress ||
        activePageId === CheckoutStepPageIds.SelectRate

    return (
        <aside>
            <IconHeading>Your Plan</IconHeading>
            {showRateCard && <RateCard primaryProduct={primaryProduct} offerRequest={offerRequest} hideEditButton={hideRateEditButton} />}
            {showAddressCard && <AddressCard serviceAddress={enrollmentState.serviceAddress} hideEditButton={hideAddressEditButton} />}
            {showMyDetailsCard && <MyDetailsCard enrollment={enrollmentState} supressBillingAccountDisplay={false} hideEditButton={hideMyDetailsEditButton} />}
            {showCredicCheckCard && <CreditCheckCard enrollment={enrollmentState} />}
        </aside>
    )
}
