import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'

import { MyAccountSelectors } from '../myaccount-redux'
import { transactionHistoryRedux } from '../transaction-history/transaction-history-redux'
import { useTransationHistory } from '../transaction-history/use-transaction-history'
import { utilityAccountDetailsRedux } from '../utility-account/utility-account-details-redux'
import { BillingAccountStatus } from './billing-account-status'
import { TransactionHistory } from './transaction-history'

const loadAccountDetails = (id: number) => utilityAccountDetailsRedux.actions.load({ key: id, showGlobalLoader: false })
const loadTransactionHistory = (id: number) => transactionHistoryRedux.actions.load({ key: id, showGlobalLoader: false })

export const BillingDetails = ({ accountId, isPaymentHistory }: Props) => {
    const dispatch = useDispatch()
    const accountActivity = useSelector((store: CommonReduxState) => transactionHistoryRedux.selectors.selectData(store, accountId))
    const accountDetails = useSelector((store: CommonReduxState) => utilityAccountDetailsRedux.selectors.selectData(store, accountId))
    const invoices = useSelector((store: CommonReduxState) => transactionHistoryRedux.selectors.selectInvoices(store, accountId))
    const payments = useSelector((store: CommonReduxState) => transactionHistoryRedux.selectors.selectPayments(store, accountId))
    const account = useSelector((store: CommonReduxState) => MyAccountSelectors.selectSelectedAccount(store))
    const { pastDueBalance } = useTransationHistory(accountId)

    React.useEffect(() => {
        dispatch(loadTransactionHistory(accountId))
        dispatch(loadAccountDetails(accountId))
    }, [accountId, dispatch])

    return !accountActivity || !accountDetails ? (
        <LocalLoader isLoading={true} />
    ) : (
        <>
            {(account && account.status !== AccountStatus.inactive) || pastDueBalance ? (
                <BillingAccountStatus accountId={accountId} accountActivity={accountActivity} accountDetails={accountDetails} />
            ) : (
                <></>
            )}

            <TransactionHistory accountId={accountId} invoices={invoices} payments={payments} isPaymentHistory={isPaymentHistory} />
        </>
    )
}

interface Props {
    readonly accountId: number
    readonly isPaymentHistory: boolean
}
