import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import styled from 'styled-components'

import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

export const RatesTabs = styled(Tabs)`
    -webkit-tap-highlight-color: transparent;
`

export const RatesTabList = styled(TabList)`
    margin: 0 0 0;
    padding: 0;
`
export const RatesTab = styled(Tab)`
    @property --backgroundColor1 {
        syntax: '<color>';
        initial-value: rgba(233, 230, 222, 0.85);
        inherits: false;
    }
    @property --backgroundColor2 {
        syntax: '<color>';
        initial-value: rgba(223, 218, 207, 1);
        inherits: false;
    }
    @property --text-color {
        syntax: '<color>';
        initial-value: var(--brand-accent);
        inherits: false;
    }
    @property --border-color {
        syntax: '<color>';
        initial-value: transparent;
        inherits: false;
    }

    --backgroundColor1: rgba(233, 230, 222, 0.85);
    --backgroundColor2: rgba(223, 218, 207, 1);
    --text-color: var(--brand-accent);
    --border-color: transparent;

    display: inline-block;
    border: 1px solid var(--border-box-color);
    bottom: -1px;
    position: relative;
    list-style: none;
    cursor: pointer;
    padding: ${Spacing.Medium} ${Spacing.XxLarge};
    font-weight: ${FontWeight.Bold};
    font-size: 1.125rem;
    color: var(--text-color);
    background: linear-gradient(180deg, var(--backgroundColor1), var(--backgroundColor2));
    margin: 0 ${Spacing.Medium} 0 0;
    border-radius: ${Spacing.ExtraSmall} ${Spacing.ExtraSmall} 0 0;
    transition:
        --backgroundColor1 200ms linear,
        --backgroundColor2 200ms linear,
        --text-color 200ms linear,
        --border-color 50ms linear;

    @media (max-width: ${Breakpoint.Mobile}) {
        padding: ${Spacing.Medium};
    }
    &:last-child {
        margin-right: 0;
    }
    &.react-tabs__tab--selected {
        --backgroundColor1: var(--white);
        --backgroundColor2: var(--white);
        --text-color: var(--dark-dark);
        --border-color: var(--border-box-color);

        border-bottom: 1px solid white;
        &:hover {
            --backgroundColor1: var(--white);
            --backgroundColor2: var(--white);
            --border-color: var(--border-box-color);
            border-bottom: 1px solid var(--white);
        }
    }

    &.react-tabs__tab--disabled {
        color: grey;
        cursor: default;
    }
    &:hover {
        text-decoration: none;
        --text-color: var(--dark-dark);
        --backgroundColor1: var(--white);
        --backgroundColor2: var(--border-box-color);
        --border-color: var(--border-box-color);
        background: linear-gradient(180deg, var(--backgroundColor1) 40%, var(--backgroundColor2) 100%);
        border: 1px solid var(--border-box-color);
    }

    &.react-tabs__tab:focus {
        outline: none;
    }
`

export const RatesTabPanel = styled(TabPanel)`
    display: none;
    padding: ${Spacing.Medium};
    border: 1px solid var(--border-box-color);
    &.react-tabs__tab-panel--selected {
        display: block;
    }
`
