import * as React from 'react'

import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'

import { useCompany } from '../company/hooks/useCompany'

interface Props {
    readonly merchantName?: string
    readonly isRecurring?: boolean
    readonly lineOfBusinessCode?: LineOfBusinessCode
    readonly isUtilityBillPaymentMethod?: boolean
}

export const PaymentLegalAuth = ({ merchantName, isRecurring, lineOfBusinessCode, isUtilityBillPaymentMethod }: Props): JSX.Element => {
    const company = useCompany()
    const { routes } = company
    const merchantNameDisplay = merchantName ?? company.displayNameByLob(lineOfBusinessCode)
    return (
        <span>
            I have read and agree to the{' '}
            <Link href={routes.payment.termsOfUse()} openInNewTab={true} dataTestId="terms-of-use-link">
                Terms of Use
            </Link>
            ,{' '}
            <Link href={routes.payment.privacyPolicy()} openInNewTab={true} dataTestId="privacy-policy-link">
                Privacy Policy
            </Link>
            , and{' '}
            <Link
                href={routes.payment.authorizationForPayment(isRecurring, lineOfBusinessCode)}
                openInNewTab={true}
                dataTestId="authorization-for-payment-link"
            >
                Authorization for Payment
            </Link>{' '}
            and authorize {merchantNameDisplay} {isUtilityBillPaymentMethod && lineOfBusinessCode === LineOfBusinessCode.HomeWarranty && 'or my utility'} to
            initiate payment from my payment card or bank account as indicated above.
        </span>
    )
}
