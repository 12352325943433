import { AccountTypeName, OfferAccountType } from '@igs-web/common-models/constants/account-type'
import { StateCode } from '@igs-web/common-models/constants/states'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { ContactModel } from '@igs-web/common-models/models/contact-model'
import { EnrollmentModel } from '@igs-web/common-models/models/enrollment-model'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { OfferModel } from '@igs-web/common-models/models/offer-model'
import { PaymentMethodModel } from '@igs-web/common-models/models/payment-model'
import { UserProfile, UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'
import { removeUndefined } from '@igs-web/common-utilities/utilities/array-utilities'
import { generateUuid } from '@igs-web/common-utilities/utilities/uuid-utilities'

import { ConfirmationInfo } from '../../enrollment/confirmation/confirmation-redux'

export const getContactName = (contact: ContactModel) => {
    if (contact.commercialName) {
        return contact.commercialName
    }
    return `${contact.firstName} ${contact.lastName}`
}

export const isTexasElectric = (selectedOffers: ReadonlyArray<OfferModel>, state?: StateCode): boolean => {
    if (state === StateCode.TX && selectedOffers.find(o => o.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.Electric)) {
        return true
    }
    return false
}

export const isDirectBill = (selectedOffers: ReadonlyArray<OfferModel>, state?: StateCode): boolean => {
    if (
        state === StateCode.TX &&
        selectedOffers.some(o => o.primaryProduct.accountType === OfferAccountType.Residential) &&
        selectedOffers.find(o => o.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.Electric)
    ) {
        return true
    }
    if (state === StateCode.GA) {
        return true
    }

    return false
}

export const hasPremiseSearch = (selectedOffers: ReadonlyArray<OfferModel>, state?: StateCode): boolean => {
    if (state === StateCode.TX && selectedOffers.find(o => o.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.Electric)) {
        return true
    }

    if (state === StateCode.GA && selectedOffers.find(o => o.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.Gas)) {
        return true
    }
    return false
}

export const getPremiseSearchLob = (state?: StateCode): LineOfBusinessCode => {
    if (state === StateCode.GA) {
        return LineOfBusinessCode.Gas
    }

    if (state === StateCode.TX) {
        return LineOfBusinessCode.Electric
    }

    throw Error('Premise Search not supported')
}

export const getBillingAddress = (enrollmentState: EnrollmentModel) => {
    if (enrollmentState.isBillingSameAsServiceAddress) {
        return enrollmentState.serviceAddress
    }
    return enrollmentState.billingAddress
}

export const getResidentialDepositOffer = (selectedOffers: ReadonlyArray<OfferModel>, state?: StateCode) => {
    if (state === StateCode.TX) {
        return selectedOffers.find(offer => offer.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.Electric)
    }

    if (state === StateCode.GA) {
        return selectedOffers.find(offer => offer.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.Gas)
    }
}

export const getPreviousPayments = (enrollmentState: EnrollmentModel): ReadonlyArray<PaymentMethodModel> => {
    const possiblePayments = [
        enrollmentState.depositPayment,
        enrollmentState.autopayPayment,
        enrollmentState.productDetails && enrollmentState.productDetails[LineOfBusinessCode.Gas].payment,
        enrollmentState.productDetails && enrollmentState.productDetails[LineOfBusinessCode.Electric].payment,
        enrollmentState.productDetails && enrollmentState.productDetails[LineOfBusinessCode.HomeWarranty].payment,
    ]
    return possiblePayments.filter(removeUndefined) ?? []
}

export const purgePayments = (enrollmentState: EnrollmentModel): EnrollmentModel => {
    return {
        ...enrollmentState,
        depositPayment: undefined,
        autopayPayment: undefined,
        isAutoPay: false,
        productDetails: {
            ...enrollmentState.productDetails!,
            [LineOfBusinessCode.Gas]: {
                ...enrollmentState.productDetails![LineOfBusinessCode.Gas],
                payment: undefined,
            },
            [LineOfBusinessCode.Electric]: {
                ...enrollmentState.productDetails![LineOfBusinessCode.Electric],
                payment: undefined,
            },
            [LineOfBusinessCode.HomeWarranty]: {
                ...enrollmentState.productDetails![LineOfBusinessCode.HomeWarranty],
                payment: undefined,
            },
        },
    }
}

export const createPendingReloadHwAccount = (
    userProfile?: UserProfile,
    enrollmentState?: EnrollmentModel,
    confirmationInfo?: Partial<ConfirmationInfo>,
): Partial<UserProfile> | undefined => {
    if (!enrollmentState || !userProfile || !confirmationInfo) {
        return undefined
    }
    const confirmationOffer = confirmationInfo.offers!.find(o => o.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty)
    const shoppingCartItem = enrollmentState.shoppingCartItems.find(s => s.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty)
    const exsistingAddressKey = userProfile.serviceAddresses.find(s => s.address1 === enrollmentState.serviceAddress?.address1)?.serviceAddressKey
    const newAddressKey = generateUuid()
    const address: UserProfileServiceAddress | undefined =
        !exsistingAddressKey && enrollmentState.serviceAddress
            ? {
                  addressHash: newAddressKey,
                  serviceAddressKey: newAddressKey,
                  hasSmallCommercialAccount: false,
                  hasPendingDisconnectAccount: false,
                  gasAvailable: false,
                  electricAvailable: false,
                  homeWarrantyAvailable: false,
                  address1: enrollmentState.serviceAddress.address1,
                  address2: enrollmentState.serviceAddress.address2,
                  zipCode: enrollmentState.serviceAddress.zipCode,
                  serviceType: AccountTypeName.Residential,
                  city: enrollmentState.serviceAddress.city,
                  state: enrollmentState.serviceAddress.state,
              }
            : undefined
    return {
        accounts: [
            {
                accountId: confirmationOffer?.accountId || 0,
                accountIdentifier: '',
                serviceAddressKey: exsistingAddressKey || newAddressKey,
                partyNumber: '',
                status: AccountStatus.pendingReload,
                lineOfBusinessCode: LineOfBusinessCode.HomeWarranty,
                partyId: shoppingCartItem?.partyId || 0,
                customerFirstName: enrollmentState.defaultContact.firstName || '',
                customerMiddleInitial: enrollmentState.defaultContact.middleInitial,
                customerPhoneTypeCode: enrollmentState.defaultContact.phoneType,
                customerPhoneNumber: enrollmentState.defaultContact.phoneNumber,
                customerEmailAddress: enrollmentState.defaultContact.emailAddress,
                customerLastName: enrollmentState.defaultContact.lastName || '',
                businessName: null,
                isDirectBilledUtilityAccount: true,
                hasPendingContract: true,
                isFriendsAndFamily: false,
                productCampaignCode: '',
                isProductGreen: false,
                accountConnectionStatus: '',
                distributionZone: '',
                accountContractTermKey: '',
                utilityName: undefined,
                accountType: undefined,
                productTypeCode: undefined,
                utilityCode: undefined,
                accountNumberLabel: undefined,
                meterNumberLabel: undefined,
                fullAccountNumber: undefined,
                earlyTerminationFee: undefined,
                protectionPlan: undefined,
                billMethod: undefined,
            },
        ],
        serviceAddresses: address && [address],
    }
}
