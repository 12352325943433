import { GAS_CHECKOUT_WIZARD_MESSAGES_STORAGE_KEY } from '@igs-web/common-utilities/constants/constants'
import { createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'
import { putInStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

import { CheckoutWizardMessagesModel, initialCheckoutWizardMessagesModel } from './checkout-wizard-messages-models'

export interface CheckoutWizardMessagesState {
    readonly checkoutWizardMessagesState: CheckoutWizardMessagesModel
    readonly storageKey: string
}

export const checkoutWizardMessagesInitialState: CheckoutWizardMessagesState = {
    checkoutWizardMessagesState: initialCheckoutWizardMessagesModel,
    storageKey: GAS_CHECKOUT_WIZARD_MESSAGES_STORAGE_KEY,
}

const checkoutWizardMessagesActionTypes = {
    SET_SHOW_CANT_MATCH_ADDRESS_MESSAGE: '[checkout wizard messages] SET_SHOW_CANT_MATCH_ADDRESS_MESSAGE',
    SET_SHOW_IS_COMMERCIAL_SERVICE_TYPE_ADDRESS_MESSAGE: '[checkout wizard messages] SET_SHOW_IS_COMMERCIAL_SERVICE_TYPE_ADDRESS_MESSAGE',
    SET_SHOW_SELECTED_OFFER_EXPIRED_MESSAGE: '[checkout wizard messages] SET_SHOW_SELECTED_OFFER_EXPIRED_MESSAGE',
    SET_SHOW_SERVER_ERROR_MESSAGE: '[checkout wizard messages] SET_SHOW_SERVER_ERROR_MESSAGE',
    SET_SHOW_OFFER_NOT_AVAILABLE_FOR_ZIP_MESSAGE: '[checkout wizard messages] SET_SHOW_OFFER_NOT_AVAILABLE_FOR_ZIP_MESSAGE',
}

export const checkoutWizardMessagesActions = {
    showCantMatchAddressMessage: createAction<boolean>(checkoutWizardMessagesActionTypes.SET_SHOW_CANT_MATCH_ADDRESS_MESSAGE),
    showIsCommsercialServiceTypeAddressMessage: createAction<boolean>(checkoutWizardMessagesActionTypes.SET_SHOW_IS_COMMERCIAL_SERVICE_TYPE_ADDRESS_MESSAGE),
    showSelectedOfferExpiredMessage: createAction<boolean>(checkoutWizardMessagesActionTypes.SET_SHOW_SELECTED_OFFER_EXPIRED_MESSAGE),
    showServerErrorMessage: createAction<boolean>(checkoutWizardMessagesActionTypes.SET_SHOW_SERVER_ERROR_MESSAGE),
    showOfferUnavailableForZipMessage: createAction<boolean>(checkoutWizardMessagesActionTypes.SET_SHOW_OFFER_NOT_AVAILABLE_FOR_ZIP_MESSAGE),
}

const setCheckoutWizardMessages = (state: CheckoutWizardMessagesState, checkoutWizardMessagesState) => {
    putInStorage(checkoutWizardMessagesState, state.storageKey, false)
    return { ...state, checkoutWizardMessagesState }
}

export const checkoutWizardMessagesReducer = reducer<CheckoutWizardMessagesState>(checkoutWizardMessagesInitialState)
    .add<boolean>(
        checkoutWizardMessagesActionTypes.SET_SHOW_CANT_MATCH_ADDRESS_MESSAGE,
        (state, showCantMatchAddressMessage): CheckoutWizardMessagesState => {
            const checkoutWizardMessagesState = {
                ...state.checkoutWizardMessagesState,
                showCantMatchAddressMessage,
            }
            return setCheckoutWizardMessages(state, checkoutWizardMessagesState)
        },
    )
    .add<boolean>(
        checkoutWizardMessagesActionTypes.SET_SHOW_IS_COMMERCIAL_SERVICE_TYPE_ADDRESS_MESSAGE,
        (state, showIsCommercialServiceTypeAddressMessage): CheckoutWizardMessagesState => {
            const checkoutWizardMessagesState = {
                ...state.checkoutWizardMessagesState,
                showIsCommercialServiceTypeAddressMessage,
            }
            return setCheckoutWizardMessages(state, checkoutWizardMessagesState)
        },
    )
    .add<boolean>(
        checkoutWizardMessagesActionTypes.SET_SHOW_SELECTED_OFFER_EXPIRED_MESSAGE,
        (state, showSelectedOfferExpiredMessage): CheckoutWizardMessagesState => {
            const checkoutWizardMessagesState = {
                ...state.checkoutWizardMessagesState,
                showSelectedOfferExpiredMessage,
            }
            return setCheckoutWizardMessages(state, checkoutWizardMessagesState)
        },
    )
    .add<boolean>(
        checkoutWizardMessagesActionTypes.SET_SHOW_SERVER_ERROR_MESSAGE,
        (state, showServerErrorMessage): CheckoutWizardMessagesState => {
            const checkoutWizardMessagesState = {
                ...state.checkoutWizardMessagesState,
                showServerErrorMessage,
            }
            return setCheckoutWizardMessages(state, checkoutWizardMessagesState)
        },
    )
    .add<boolean>(
        checkoutWizardMessagesActionTypes.SET_SHOW_OFFER_NOT_AVAILABLE_FOR_ZIP_MESSAGE,
        (state, showOfferNotAvailableForZipMessage): CheckoutWizardMessagesState => {
            const checkoutWizardMessagesState = {
                ...state.checkoutWizardMessagesState,
                showOfferNotAvailableForZipMessage,
            }
            return setCheckoutWizardMessages(state, checkoutWizardMessagesState)
        },
    )
    .build()
