import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FaRegCreditCard } from 'react-icons/fa'
import { MdLocationOn } from 'react-icons/md'

import styled from 'styled-components'

import { AddressForm } from '@igs-web/common-components/domain/forms/address-form'
import { ElectronicCommunicationConsentNotice } from '@igs-web/common-components/domain/legal/electronic-communication-consent-notice'
import { CreditCheckLayout } from '@igs-web/common-components/domain/payment-capture/credit-card-capture-form'
import { BasicPaymentCaptureForm } from '@igs-web/common-components/domain/payment-capture/payment-capture-form-basic'
import { Address, LineOfBusinessCode, PaymentMethodModel, PaymentType } from '@igs-web/common-models/models'
import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { Checkbox, Input } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { formatAsCurrency } from '@igs/react'

import { PaymentCaptureDuration } from './inbound-payment-page'

const FormWrapper = styled.form`
    display: contents;
    > div {
        display: grid;
        gap: ${Spacing.Large};
    }
    button {
        justify-self: right;
    }
    @media (min-width: ${Breakpoint.Tablet}) {
        ${CreditCheckLayout} {
            --cc-grid-template-areas: 'name name' 'number number' 'type type' 'month year' 'cvv tooltip' 'zip blank';
            --cc-grid-template-columns: 1fr 1fr;
        }
    }
`

export interface PaymentFormData {
    readonly acceptedTerms: boolean
    readonly amountDisplay: string
    readonly billingAddress?: Address
    readonly isBillingSameAsServiceAddress: boolean
    readonly payment: PaymentMethodModel
}

export const PaymentForm = ({ amount, frequency, submit }: Props) => {
    const amountDisplay = formatAsCurrency(amount)
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            isBillingSameAsServiceAddress: true,
        },
    })
    const { handleSubmit, register, watch } = methods
    const billingAddressSame = watch('isBillingSameAsServiceAddress')

    const onSubmit = (data: PaymentFormData) => {
        submit(data)
    }

    return (
        <FormProvider {...methods}>
            <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                <IconHeading className="payment-form-header">
                    <FaRegCreditCard /> Payment
                </IconHeading>
                <IconHeading className="address-form-header">
                    <MdLocationOn /> Billing Address
                </IconHeading>
                <Input
                    className="payment-form-amount"
                    name="amountDisplay"
                    value={amountDisplay}
                    dataTestId="payment-amount"
                    hideHelperText={true}
                    readOnly={true}
                    label="Payment Amount"
                />
                <Checkbox
                    className="address-form-toggle"
                    {...register('isBillingSameAsServiceAddress')}
                    defaultChecked={true}
                    label="My billing address is the same as my service address"
                    dataTestId="billingAddressMatchesServiceAddress"
                />
                <div className="payment-form-capture">
                    <BasicPaymentCaptureForm
                        formKey="[payment]."
                        idPrefix="payment"
                        paymentTypes={[PaymentType.CreditCard, PaymentType.Ach]}
                        defaultPaymentOptionType={PaymentType.CreditCard}
                        isRecurring={frequency === PaymentCaptureDuration.Recurring}
                    />
                    <ElectronicCommunicationConsentNotice lineOfBusinessCode={LineOfBusinessCode.HomeWarranty} />
                </div>
                <Button dataTestId="payment-form-submit-button" className="form-button" type="submit">
                    Submit
                </Button>
                <div className="address-form-capture">
                    {!billingAddressSame && <AddressForm formKey="[billingAddress]." idPrefix="mailing-address-" disableZip={false} labelPrefix="Billing " />}
                </div>
            </FormWrapper>
        </FormProvider>
    )
}

interface Props {
    readonly amount: number
    readonly frequency: PaymentCaptureDuration
    readonly submit: (data: PaymentFormData) => void
}
