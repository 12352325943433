import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { apiClient } from '@igs-web/common-utilities/api/api-client'

export interface PaymentRoutes {
    readonly payBill: (billingAccountNumber?: string, zipCode?: string) => string
    readonly autoPay: (accountId: number) => string
    readonly termsOfUse: () => string
    readonly privacyPolicy: () => string
    readonly authorizationForPayment: (isRecurring: boolean | undefined, lineOfBusinessCode?: LineOfBusinessCode) => string
    readonly paymentExtension: (billingAccountNumber?: string) => string
    readonly nonProdPaymentPortalRegistered: string
    readonly prodPaymentPortalRegistered: string
    readonly nonProdPaymentPortalGuest: string
    readonly prodPaymentPortalGuest: string
}

export const DefaultRoutes: PaymentRoutes = {
    payBill: (billingAccountNumber?: string): string => {
        const billingAccountParam = billingAccountNumber ? `?billingAccountNumber=${billingAccountNumber}` : ''
        return `/MyAccount/PayBill${billingAccountParam}`
    },
    autoPay: (accountId: number): string => `/MyAccount/AutoPay?accountId=${accountId}`,
    termsOfUse: (): string => '/terms-of-use',
    privacyPolicy: (): string => '/privacy-policy',
    authorizationForPayment: (isRecurring: boolean | undefined, lineOfBusinessCode?: LineOfBusinessCode) => {
        const lob =
            lineOfBusinessCode &&
            (lineOfBusinessCode === LineOfBusinessCode.HomeWarranty ||
                lineOfBusinessCode === LineOfBusinessCode.Commercial ||
                lineOfBusinessCode === LineOfBusinessCode.CommunitySolar)
                ? lineOfBusinessCode.toLowerCase()
                : 'commodity'
        return apiClient.getDocPath(`legal/payment/${lob}/${isRecurring ? 'recurring' : 'single'}/Authorization%20for%20Payment.pdf`)
    },
    paymentExtension: (billingAccountNumber?: string): string => {
        const billingAccountParam = billingAccountNumber ? `?billingAccountNumber=${billingAccountNumber}` : ''
        return `/MyAccount/PaymentExtension${billingAccountParam}`
    },
    nonProdPaymentPortalRegistered: 'https://odweb.clienttestmatrix.com',
    prodPaymentPortalRegistered: 'https://webpayments.billmatrix.com',
    nonProdPaymentPortalGuest: 'https://odweb.clienttestmatrix.com',
    prodPaymentPortalGuest: 'https://webpayments.billmatrix.com',
}

export const IgsRoutes = DefaultRoutes
