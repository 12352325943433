import { StateCode } from '@igs-web/common-models/constants/states'

export interface EnrollmentFeatures {
    readonly defaultState: StateCode | undefined
    readonly defaultZipCode: string | undefined
    readonly showPaperlessOption: boolean
    readonly collectAlternatePhone: boolean
    readonly affirmCreditCheckInfo: boolean
    readonly suppressProductsOnSummary: boolean
    readonly displayConnectionFeeOnOrderSummary: boolean
    readonly loadDiscountAsSeparateCall: boolean
    readonly canEnrollFromPublicWebsite: boolean
    readonly campaignCodes: CampaignCodes
    readonly collectInitialsOnSummary: boolean
}

export const defaultCampaignCodes: CampaignCodes = {
    default: 'web-default',
    renewalDefault: 'web-default',
    rateChangeDefault: 'web-default',
}

export const defaultEnrollmentFeatures: EnrollmentFeatures = {
    defaultState: undefined,
    defaultZipCode: undefined,
    showPaperlessOption: false,
    collectAlternatePhone: false,
    affirmCreditCheckInfo: false,
    suppressProductsOnSummary: false,
    displayConnectionFeeOnOrderSummary: false,
    loadDiscountAsSeparateCall: true,
    canEnrollFromPublicWebsite: true,
    campaignCodes: defaultCampaignCodes,
    collectInitialsOnSummary: true,
}

interface CampaignCodes {
    readonly default: string
    readonly renewalDefault: string
    readonly rateChangeDefault: string
}
