import React from 'react'
import { Link } from 'react-router-dom'

import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText } from '@igs-web/common-ui-components/_molecules/info-text'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { NavigationBanner } from 'molecules/header/navigation-banner'

import * as Routes from 'domain/routes'

export const BillPayResponse = () => (
    <>
        <NavigationBanner title="Payment Received" />
        <PageContentContainer>
            <GridSpacer spacing={Spacing.Large} />
            <InfoText>
                Thank you for your payment. You may <Link to={Routes.DashboardReload}>continue back to your dashboard</Link>, or close this tab, and refresh
                your other tab to see your payment reflected on your balance.
            </InfoText>
            <GridSpacer spacing={Spacing.Large} />
        </PageContentContainer>
    </>
)
