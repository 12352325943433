import * as React from 'react'

import { ChartDataset } from 'chart.js'
import _some from 'lodash-es/some'

import { LegacyUsage } from '@igs-web/common-models/models/legacy-data'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { Usage } from '@igs-web/common-models/models/usage'
import { BarChart, patterns } from '@igs-web/common-ui-components/_molecules/charts/bar-chart'
import { rangeInclusive } from '@igs-web/common-utilities/utilities/array-utilities'
import { Month, months } from '@igs-web/common-utilities/utilities/date-utilities'

const now = new Date()
const thisYear = now.getFullYear()
const PREVIOUS_USAGE_YEAR_RANGE = 2
const minUsageYear = thisYear - PREVIOUS_USAGE_YEAR_RANGE
const usageYearRange = rangeInclusive(minUsageYear, thisYear)

// TODO: consider moving this into a utility, or finding a better way
// to use custom properties on charts. (also,documentelement is probably better replaced with a ref to the chart)

const getValueFromCustomProperty = (property: string): string => {
    return window.getComputedStyle(document.documentElement).getPropertyValue(property)
}

const getLobDatasetStyles = (lobCode: LineOfBusinessCode): ReadonlyArray<ChartDataset<'bar'>> => {
    return [
        {
            data: [],
            backgroundColor: patterns.ZigZag(
                lobCode === LineOfBusinessCode.Gas ? getValueFromCustomProperty('--primary-light') : getValueFromCustomProperty('--secondary-light'),
            ),
        },
        {
            data: [],
            backgroundColor: patterns.Diagonal(
                lobCode === LineOfBusinessCode.Gas ? getValueFromCustomProperty('--primary-dark') : getValueFromCustomProperty('--secondary-dark'),
            ),
        },
        {
            data: [],
            backgroundColor: lobCode === LineOfBusinessCode.Gas ? getValueFromCustomProperty('--primary-base') : getValueFromCustomProperty('--secondary-base'),
        },
    ]
}

const getUsageForMonth = (usages: ReadonlyArray<Usage>, legacyUsages: ReadonlyArray<LegacyUsage>, month: Month, year: number): number | null => {
    const usageForMonth = usages.find(usage => {
        const toDate = new Date(usage.toDate)
        const monthIndex = month.ordinal - 1
        return toDate.getMonth() === monthIndex && toDate.getFullYear() === year
    })
    if (usageForMonth) {
        return usageForMonth.quantity
    }
    const legacyUsageForMonth = legacyUsages.find(usage => {
        const toDate = new Date(usage.toDate)
        const monthIndex = month.ordinal - 1
        return toDate.getMonth() === monthIndex && toDate.getFullYear() === year
    })
    return legacyUsageForMonth ? legacyUsageForMonth.quantity : null
}

const getUsagesForYear = (usages: ReadonlyArray<Usage>, legacyUsages: ReadonlyArray<LegacyUsage>, year: number): ReadonlyArray<number> => {
    // TODO: this should not claim that it cannot return null
    return months.map(month => getUsageForMonth(usages, legacyUsages, month, year)!)
}

const getDatasets = (
    usages: ReadonlyArray<Usage>,
    legacyUsages: ReadonlyArray<LegacyUsage>,
    lobCode: LineOfBusinessCode,
): ReadonlyArray<ChartDataset<'bar'>> => {
    const lobDatasetStyles = getLobDatasetStyles(lobCode)
    return usageYearRange.map((year, index) => ({
        ...lobDatasetStyles[index],
        label: '' + year,
        data: [...getUsagesForYear(usages, legacyUsages, year)],
    }))
}

const hasData = (datasets: ReadonlyArray<ChartDataset<'bar'>>): boolean => {
    return _some(datasets, dataset => dataset.data && _some(dataset.data, (data: number) => !!data))
}

const mapToChartData = (datasets: ReadonlyArray<ChartDataset<'bar'>>) => {
    return {
        labels: months.map(m => m.abbreviation),
        datasets: [...datasets],
    }
}

const getUnitOfMeasure = (usages?: ReadonlyArray<Usage>, legacyUsages?: ReadonlyArray<LegacyUsage>) => {
    if (usages && usages.length > 0) {
        return usages[0].unitOfMeasure
    }
    if (legacyUsages && legacyUsages.length > 0) {
        return legacyUsages[0].unitOfMeasure
    }
    return 'TH'
}

const filterDataSets = (datasets: ReadonlyArray<ChartDataset<'bar'>>) => {
    const filteredDatasets = datasets.filter(ds => {
        if (ds.data) {
            return ds.data.some(d => !!d)
        } else {
            return false
        }
    })
    return filteredDatasets
}

export const CustomerUsageChart = ({ usages, legacyUsages, lineOfBusinessCode }: Props): JSX.Element | null => {
    if (!usages && !legacyUsages) {
        return null
    }
    const datasets = getDatasets(usages ?? [], legacyUsages ?? [], lineOfBusinessCode)

    if (!hasData(datasets)) {
        return null
    }

    const filteredDataSets = filterDataSets(datasets)
    const chartData = mapToChartData(filteredDataSets)

    const xLabel = 'MONTH'
    const yLabel = getUnitOfMeasure(usages, legacyUsages)

    return (
        <div data-testid="usage-and-rates-history-chart" className="hide-on-mobile" style={{ height: '300px' }}>
            <BarChart data={chartData} xLabel={xLabel} yLabel={yLabel} />
        </div>
    )
}

interface Props {
    readonly usages?: ReadonlyArray<Usage>
    readonly legacyUsages?: ReadonlyArray<LegacyUsage>
    readonly lineOfBusinessCode: LineOfBusinessCode
}
