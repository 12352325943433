// import './remove-accounts-style.scss'

import * as React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { CommunitySolarAccount } from '@igs-web/common-models/models/community-solar-model'
import { UserProfileAccount, UserProfileServiceAddress } from '@igs-web/common-models/models/user-profile-model'
import { AddressDisplay } from '@igs-web/common-ui-components/_molecules/address/address-display'
import { LineOfBusinessIcon } from '@igs-web/common-ui-components/_molecules/line-of-business-icon'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { darkDark } from '@igs-web/common-ui-components/styles/theme'

import { CommonReduxState } from '../common-redux'
import { UserSelectors } from '../user/user-redux'
import { RemoveAccountButton } from './remove-account-button'

const StyledRemoveAccountItem = styled.div`
    border: 1px solid ${darkDark};
    padding: ${Spacing.Medium};
    margin: ${Spacing.Small};
    width: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
const StyledLineOfBusinessIconContainer = styled.div`
    float: left;
`
const StyledRemoveAccountButtonContainer = styled.div`
    float: right;
`
const StyledRemoveSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`

const ServiceAddressTile = ({ serviceAddress, accountsToBeRemoved, subscriptionsToBeRemoved }: ServiceAddressTileProps): JSX.Element | null => {
    if (!accountsToBeRemoved.length && !subscriptionsToBeRemoved.length) {
        return null
    }
    const lineOfBusinessCodes = [...new Set(accountsToBeRemoved.map(a => a.lineOfBusinessCode).concat(subscriptionsToBeRemoved.map(s => s.lineOfBusinessCode)))]

    return (
        <StyledRemoveAccountItem>
            <AddressDisplay address={serviceAddress} />
            <div>
                <StyledLineOfBusinessIconContainer>
                    {lineOfBusinessCodes.map(lineOfBusinessCode => (
                        <LineOfBusinessIcon key={lineOfBusinessCode} lineOfBusinessCode={lineOfBusinessCode} />
                    ))}
                </StyledLineOfBusinessIconContainer>
                <StyledRemoveAccountButtonContainer className="float-right">
                    <RemoveAccountButton
                        accountsToBeRemoved={accountsToBeRemoved}
                        lineOfBusinessCodes={lineOfBusinessCodes}
                        subscriptionsToBeRemoved={subscriptionsToBeRemoved}
                    />
                </StyledRemoveAccountButtonContainer>
            </div>
        </StyledRemoveAccountItem>
    )
}

interface ServiceAddressTileProps {
    readonly serviceAddress: UserProfileServiceAddress
    readonly accountsToBeRemoved: ReadonlyArray<UserProfileAccount>
    readonly subscriptionsToBeRemoved: ReadonlyArray<CommunitySolarAccount>
}

export const RemoveAccountSection = () => {
    const serviceAddresses = useSelector((store: CommonReduxState) => UserSelectors.selectUserServiceAddresses(store))
    const accounts = useSelector((store: CommonReduxState) => UserSelectors.selectPartyAccounts(store))
    const subscriptions = useSelector((store: CommonReduxState) => UserSelectors.selectSubscriptionAccounts(store))
    return (
        <div>
            <p className="remove-account-text">Select a Service Account to remove from your MyAccount</p>
            <StyledRemoveSection className="container-fluid">
                {serviceAddresses.map(serviceAddress => (
                    <ServiceAddressTile
                        key={serviceAddress.serviceAddressKey}
                        serviceAddress={serviceAddress}
                        accountsToBeRemoved={accounts.filter(account => account.serviceAddressKey === serviceAddress.serviceAddressKey)}
                        subscriptionsToBeRemoved={subscriptions.filter(a => a.serviceAddressKey === serviceAddress.serviceAddressKey)}
                    />
                ))}
            </StyledRemoveSection>
        </div>
    )
}