import { GetIndustrialInvoiceRequest } from '@igs-web/common-models/models/get-industrial-invoice-request'
import { GetIndustrialInvoiceResponse } from '@igs-web/common-models/models/get-industrial-invoice-response'
import { HomeWarrantyOneTimePaymentRequest } from '@igs-web/common-models/models/home-warranty-one-time-payment-request'
import { HomeWarrantyOneTimePaymentResponse } from '@igs-web/common-models/models/home-warranty-one-time-payment-response'
import { HomeWarrantyPaymentInformation } from '@igs-web/common-models/models/home-warranty-payment-information'
import { apiClient, RequestConfig } from '@igs-web/common-utilities/api/api-client'

class HomeWarrantyOneTimePaymentApiClient {
    public readonly getAmountForPayment = async (token: string, type: string, config?: RequestConfig) =>
        await apiClient.get<HomeWarrantyPaymentInformation>(`${apiClient.apiBaseUrl}/HomeWarranty/GetPaymentAmount/${type}/${token}`, config)

    public readonly submitPayment = async (request: HomeWarrantyOneTimePaymentRequest, type: string) =>
        await apiClient.post<HomeWarrantyOneTimePaymentResponse>(`${apiClient.apiBaseUrl}/HomeWarranty/MakePayment/${type}`, request)

    public readonly getIndustrialInvoiceAmount = async (request: GetIndustrialInvoiceRequest) =>
        await apiClient.post<GetIndustrialInvoiceResponse>(`${apiClient.apiBaseUrl}/Commercial/GetIndustrialInvoiceAmount`, request)

    public readonly makeIndustrialPayment = async (request: HomeWarrantyOneTimePaymentRequest) =>
        await apiClient.post<HomeWarrantyOneTimePaymentResponse>(`${apiClient.apiBaseUrl}/Commercial/MakeIndustrialPayment`, request)
}

export const homeWarrantyOneTimePaymentApiClient = new HomeWarrantyOneTimePaymentApiClient()
