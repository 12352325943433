import * as React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { FullWidthNavigationBanner } from '@igs-web/common-components/_molecules/full-width-banner'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { useBreakpoints } from '@igs-web/common-components/hooks/use-breakpoints'
import { ExternalLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'

import { formatDataId } from 'root/shared-services/data-test-id-utilities'

import { NavBannerHeader, NavLinks } from './header-components'
import { MenuItem, useNavigationItems } from './use-navigation-items'

export const NavigationBannerDisplay = ({ title, dataTestId, showNav = true, menuItems, style }: DisplayProps) => (
    <FullWidthNavigationBanner style={style}>
        <NavBannerHeader data-testid={dataTestId}>{title}</NavBannerHeader>
        {showNav && (
            <NavLinks>
                <ul>
                    {menuItems
                        .filter(menuItem => !!menuItem.show)
                        .map(menuItem =>
                            menuItem.external ? (
                                <li key={menuItem.btnLabel}>
                                    <ExternalLink href={menuItem.link} onClick={menuItem.onClick}>
                                        {menuItem.btnLabel}
                                    </ExternalLink>
                                </li>
                            ) : (
                                <li key={menuItem.btnLabel}>
                                    <Link data-testid={formatDataId(menuItem.btnLabel + '-link')} to={menuItem.link} onClick={menuItem.onClick}>
                                        {menuItem.btnLabel}
                                    </Link>
                                </li>
                            ),
                        )}
                </ul>
            </NavLinks>
        )}
    </FullWidthNavigationBanner>
)

export const NavigationBanner = ({ showNav = true, dataTestId = 'page-title', ...rest }: NavigationBannerProps) => {
    const isLoggedIn = useSelector(UserSelectors.selectLoggedIn)
    const { bannerMenuItems } = useNavigationItems()

    const { lessThan } = useBreakpoints()
    const isMobile = lessThan(Breakpoint.Tablet)
    const willShowNav = showNav && isLoggedIn && !isMobile
    return <NavigationBannerDisplay {...rest} showNav={willShowNav} dataTestId={dataTestId} menuItems={bannerMenuItems} />
}

interface NavigationBannerProps {
    readonly title: string
    readonly style?: React.CSSProperties
    readonly showNav?: boolean
    readonly dataTestId?: string
}

interface DisplayProps extends NavigationBannerProps {
    readonly menuItems: ReadonlyArray<MenuItem>
}
