import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'

import { createPendingReloadHwAccount } from '@igs-web/common-components/domain/checkout/business/enrollment-service'
import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { ConfirmationSelectors } from '@igs-web/common-components/domain/enrollment/confirmation/confirmation-redux'
import { enrollmentModelActions } from '@igs-web/common-components/domain/enrollment/enrollment-redux-actions'
import { OfferRequestState, OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { UserSelectors, userActions } from '@igs-web/common-components/domain/user/user-redux'
import { EnrollmentModel, LineOfBusinessCode, UserProfileAccount } from '@igs-web/common-models/models'
import { LinkButton, RouterLinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { HW_CHECKOUT_STORAGE_KEY } from '@igs-web/common-utilities/constants/constants'
import { clearFromStorage, getFromStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

import { NavigationBanner } from 'molecules/header/navigation-banner'
import { ScrollToTop } from 'molecules/routing/scroll-to-top'

import * as Routes from 'domain/routes'

import { Checkout2x2CardGrid } from '../shared/checkout-components'
import { ConfirmationRateCard, ConfirmationTitle, OrderDetailsTitle } from '../shared/confirmation-components'
import { AddressCardDisplay } from '../your-plan/address-card'
import { MyDetailsCardDisplay } from '../your-plan/my-details-card'
import { PaymentCardDisplay } from '../your-plan/payment-card'

export const HwEnrollmentConfirmationPage = () => {
    const confirmation = useSelector((store: CommonReduxState) => ConfirmationSelectors.selectConfirmationInfo(store))
    const storedEnrollmentState = getFromStorage<EnrollmentModel>(HW_CHECKOUT_STORAGE_KEY, false)
    const storedOfferRequest = useSelector((store: CommonReduxState) => OfferSelectors.selectOfferRequest(store))
    const userProfile = useSelector(UserSelectors.selectProfile)
    const history = useHistory()
    const dispatch = useDispatch()
    const company = useCompany()

    const [enrollmentRequest, setEnrollmentRequest] = React.useState<EnrollmentModel | null>(null)
    const [offerRequest, setOfferRequest] = React.useState<OfferRequestState | null>(null)
    const handleOnComplete = (account: UserProfileAccount) => dispatch(userActions.reloadUser(account))
    React.useEffect(() => {
        if (!confirmation || confirmation.offers.filter(o => o.confirmationNumber).length === 0) {
            history.push(Routes.HomeWarrantCheckout)
        }
    }, [confirmation, history])

    React.useEffect(() => {
        if (!!storedEnrollmentState) {
            const pendingReloadUserProfile = createPendingReloadHwAccount(userProfile, storedEnrollmentState, confirmation)
            dispatch(userActions.setUserProfile(pendingReloadUserProfile))
            if (pendingReloadUserProfile?.accounts) {
                handleOnComplete(pendingReloadUserProfile?.accounts[0])
            }

            setEnrollmentRequest(storedEnrollmentState)
            setOfferRequest(storedOfferRequest)
            dispatch(enrollmentModelActions.clearAfterSubmit())
            clearFromStorage(HW_CHECKOUT_STORAGE_KEY)
        }
    }, [dispatch, storedEnrollmentState])

    if (!confirmation || confirmation.offers.length === 0) {
        return <></>
    }

    const confirmationOffer = confirmation.offers[0]

    return (
        <>
            <ScrollToTop />
            <NavigationBanner title="Confirmation" />
            <PageContentContainer>
                <ConfirmationTitle
                    displayName={company.displayNameByLob(LineOfBusinessCode.HomeWarranty)}
                    emailAddress={enrollmentRequest?.defaultContact.emailAddress}
                    confirmationNumber={confirmationOffer.confirmationNumber}
                />
                <GridSpacer spacing={Spacing.ExtraLarge} />
                <OrderDetailsTitle />
                <GridSpacer spacing={Spacing.ExtraLarge} />
                <Checkout2x2CardGrid>
                    {offerRequest && <ConfirmationRateCard offer={confirmationOffer} offerRequest={offerRequest} />}
                    {enrollmentRequest?.serviceAddress && <AddressCardDisplay serviceAddress={enrollmentRequest.serviceAddress} />}
                    {enrollmentRequest && <MyDetailsCardDisplay enrollment={enrollmentRequest} supressBillingAccountDisplay={true} />}
                    {enrollmentRequest?.productDetails && enrollmentRequest.productDetails[LineOfBusinessCode.HomeWarranty] && (
                        <PaymentCardDisplay
                            paymentDescription={confirmationOffer.paymentDescription}
                            billingAddress={enrollmentRequest.billingAddress}
                            isBillingSameAsServiceAddress={enrollmentRequest.isBillingSameAsServiceAddress}
                        />
                    )}
                </Checkout2x2CardGrid>
                <GridSpacer spacing={Spacing.ExtraLarge} />
                <ButtonContainer>
                    {userProfile ? (
                        <RouterLinkButton to={Routes.HwAccountSummary}>BACK TO DASHBOARD</RouterLinkButton>
                    ) : (
                        <LinkButton href={company.externalWebsite}>BACK TO HOMEPAGE</LinkButton>
                    )}
                </ButtonContainer>
                <GridSpacer spacing={Spacing.ExtraLarge} />
            </PageContentContainer>
        </>
    )
}

const ButtonContainer = styled.div`
    text-align: right;
`
