import * as React from 'react'
import { useFormState } from 'react-final-form'

import { Button, ButtonProps } from '@igs-web/common-ui-components/_atoms/buttons/button'

export const SubmitButton = ({ disabled, ...htmlProps }: Props): JSX.Element => {
    const { submitting } = useFormState()

    return <Button type="submit" disabled={submitting || disabled} {...htmlProps} />
}

type Props = Omit<ButtonProps, 'type'>
