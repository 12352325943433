import styled from 'styled-components'

export const LogoContainer = styled.div`
  height: 45px;

  img {
    height: 100%;
    align-self: center;
  }
  width: auto;
  grid-area:logo;
`
