import * as React from 'react'
import styled from 'styled-components'

import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

export const BreadcrumbList = styled.ol`
    font-weight: 500;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    padding: ${Spacing.None};
    margin: ${Spacing.None};
    list-style: none;
    flex-wrap: wrap;
`
export const BreadcumbItem = styled.li`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 0;
    line-height: 1rem;
    vertical-align: top;
    & + & {
        padding-left: 0.5rem;
        &::before {
            display: inline-block;
            padding-right: 0.5rem;
            content: '/';
        }
    }
`
const Breadcrumbs = ({ breadcrumbs }: Props) => (
    <nav aria-label="breadcrumb">
        <BreadcrumbList>
            {breadcrumbs.map((crumb, i, arr) => (
                <BreadcumbItem key={crumb.url}>{i === arr.length - 1 ? crumb.name : <a href={crumb.url}>{crumb.name}</a>}</BreadcumbItem>
            ))}
        </BreadcrumbList>
    </nav>
)

export interface Breadcrumb {
    readonly name: string
    readonly url: string
}
interface Props {
    readonly breadcrumbs: ReadonlyArray<Breadcrumb>
}

export default Breadcrumbs
