import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { MyAccountSelectors } from '@igs-web/common-components/domain/myaccount-redux'
import { useOffersContextDispatch, useOffersContextState } from '@igs-web/common-components/domain/offers/context/offers-context'
import { OffersInContextType } from '@igs-web/common-components/domain/offers/context/offers-context-models'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { OfferAccountType } from '@igs-web/common-models/constants/account-type'
import { UserProfileAccount } from '@igs-web/common-models/models'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { Button, ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'
import { mapAccountTypeNameToOfferAccountType } from '@igs-web/common-utilities/services/account-service'
import { canRenew } from '@igs-web/common-utilities/services/contract-eligibility-service'
import { FsEvent, fireCustomFullStoryEvent } from '@igs-web/common-utilities/utilities/fs-logger'

import * as Routes from 'domain/routes'

import { RenewalOffers } from './renewal-offers'
import { StyledRenewalDialogContainer } from './styled-offers-container'

const SeeMoreRatesButton = styled(Button)`
    width: fit-content;
    place-self: end;
`

export const RenewalOffersComponent = ({
    zipCode,
    renewableAccounts,
    offerAccountType,
    campaignCode,
    displayOnlyHeroProducts,
    showSeeMoreRates,
}: RenewalOffersComponentProps) => {
    const history = useHistory()
    const { offersAreLoading, offers } = useOffersContextState()
    const offersContextDispatch = useOffersContextDispatch()

    const partyAccountId = renewableAccounts.length > 0 ? renewableAccounts[0].accountId : undefined
    const distributionZone = renewableAccounts.find(a => a.distributionZone !== '')?.distributionZone ?? undefined
    React.useEffect(() => {
        if (!offersAreLoading && partyAccountId) {
            offersContextDispatch.loadOffers({
                ignoreCache: true,
                offersInContextType: OffersInContextType.Renewal,
                zipCode,
                accountType: offerAccountType,
                campaignCode,
                priceEffectiveDate: new Date(),
                partyAccountId,
                distributionZone,
                config: {
                    showGlobalLoader: false,
                },
            })
        }
    }, [zipCode, distributionZone, partyAccountId, campaignCode, offerAccountType])

    if (offersAreLoading) {
        return (
            <StyledRenewalDialogContainer>
                <LocalLoader isLoading={true} />
            </StyledRenewalDialogContainer>
        )
    }

    return (
        <StyledRenewalDialogContainer className="myaccount-container">
            {renewableAccounts.map(account => {
                return (
                    <RenewalOffers
                        key={account.accountId}
                        endDate={account.termEndDate}
                        offers={offers}
                        displayOnlyHeroProducts={displayOnlyHeroProducts}
                        campaignCode={campaignCode}
                        distributionZone={distributionZone}
                        zipCode={zipCode}
                    />
                )
            })}
            {showSeeMoreRates && (
                <SeeMoreRatesButton
                    buttonStyle={ButtonStyleType.Secondary}
                    onClick={() => {
                        fireCustomFullStoryEvent(FsEvent.renewalSeeAllRates, { offers, zipCode, campaignCode })
                        history.push(Routes.RateChange)
                    }}
                    dataTestId="see-more-rates-button"
                >
                    See More Rates
                </SeeMoreRatesButton>
            )}
        </StyledRenewalDialogContainer>
    )
}
interface RenewalOffersComponentProps {
    readonly zipCode: string
    readonly renewableAccounts: ReadonlyArray<UserProfileAccount>
    readonly offerAccountType: OfferAccountType
    readonly campaignCode: string
    readonly displayOnlyHeroProducts: boolean
    readonly showSeeMoreRates: boolean
}

const renewableAccounts = (accountsForServiceAddress: ReadonlyArray<UserProfileAccount>, canRenewAnyStatus: boolean) =>
    accountsForServiceAddress.filter(
        a => a.status === AccountStatus.active && a.lineOfBusinessCode !== LineOfBusinessCode.HomeWarranty && canRenew(a, canRenewAnyStatus),
    )

export const RenewalOffersContainer = ({ displayOnlyHeroProducts }: RenewalOffersContainerProps): JSX.Element => {
    const { features, enrollment } = useCompany()
    const match = useRouteMatch(Routes.RateChange)
    const isOnRateChange = match && match.isExact

    const selectedServiceAddress = useSelector(MyAccountSelectors.selectSelectedServiceAddress)
    const accounts = useSelector(UserSelectors.selectPartyAccounts).filter(a => a.serviceAddressKey === selectedServiceAddress?.serviceAddressKey)

    if (!selectedServiceAddress) {
        return <></>
    }

    const activeAccounts = renewableAccounts(accounts, features.canRenewAnyStatus)
    const offerAccountType = accounts.some(a => mapAccountTypeNameToOfferAccountType(a.accountType) === OfferAccountType.Commercial)
        ? OfferAccountType.Commercial
        : OfferAccountType.Residential

    return (
        <>
            {activeAccounts.length > 0 && (
                <RenewalOffersComponent
                    zipCode={selectedServiceAddress.zipCode}
                    renewableAccounts={activeAccounts}
                    offerAccountType={offerAccountType}
                    campaignCode={enrollment.campaignCodes.renewalDefault}
                    displayOnlyHeroProducts={!!displayOnlyHeroProducts}
                    showSeeMoreRates={!isOnRateChange}
                />
            )}
        </>
    )
}

interface RenewalOffersContainerProps {
    readonly displayOnlyHeroProducts?: boolean | undefined
}

export const RenwalOfferssDashboardWrapper = ({ accounts, zipCode, displayOnlyHeroProducts }: RenwalOfferssDashboardWrapperProps) => {
    const { features, enrollment } = useCompany()

    const activeAccounts = renewableAccounts(accounts, features.canRenewAnyStatus)
    const offerAccountType = accounts.some(a => mapAccountTypeNameToOfferAccountType(a.accountType) === OfferAccountType.Commercial)
        ? OfferAccountType.Commercial
        : OfferAccountType.Residential

    return (
        <>
            {activeAccounts.length > 0 && (
                <RenewalOffersComponent
                    zipCode={zipCode}
                    renewableAccounts={activeAccounts}
                    offerAccountType={offerAccountType}
                    campaignCode={enrollment.campaignCodes.renewalDefault}
                    displayOnlyHeroProducts={!!displayOnlyHeroProducts}
                    showSeeMoreRates={true}
                />
            )}
        </>
    )
}

interface RenwalOfferssDashboardWrapperProps extends RenewalOffersContainerProps {
    readonly zipCode: string
    readonly accounts: ReadonlyArray<UserProfileAccount>
}
