import _uniq from 'lodash-es/uniq'

import { createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'
import { fireCustomFullStoryEvent, FsEvent } from '@igs-web/common-utilities/utilities/fs-logger'

const wizardActionTypes = {
    REGISTER_ITEM: '[wizard] REGISTER_ITEM',
    SET_ACTIVE_PAGE: '[wizard] SET_ACTIVE_PAGE',
    SET_ACTIVE_SUBPAGE: '[wizard] SET_ACTIVE_SUBPAGE',
    NEXT_PAGE: '[wizard] NEXT_PAGE',
    PREVIOUS_PAGE: '[wizard] PREVIOUS_PAGE',
    REGISTER_SUBPAGES: '[wizard] REGISTER_SUBPAGES',
}

export const wizardActions = {
    register: createAction<RegisterRequest>(wizardActionTypes.REGISTER_ITEM),
    setActivePage: createAction<string>(wizardActionTypes.SET_ACTIVE_PAGE),
    setActiveSubpage: createAction<string>(wizardActionTypes.SET_ACTIVE_SUBPAGE),
    nextPage: createAction<void>(wizardActionTypes.NEXT_PAGE),
    previousPage: createAction<void>(wizardActionTypes.PREVIOUS_PAGE),
    registerSubPages: createAction<RegisterSubPagesRequest>(wizardActionTypes.REGISTER_SUBPAGES),
}

export interface RegisterRequest {
    readonly pageId: string
    readonly subPageId?: string
}

export interface RegisterSubPagesRequest {
    readonly pageId: string
    readonly subPages: ReadonlyArray<string>
}

export interface WizardState {
    readonly activePageId?: string
    readonly activeSubPageId?: string
    readonly pageIds: ReadonlyArray<string>
    readonly subPages: { readonly [key: string]: ReadonlyArray<string> }
}

export const wizardInitialState: WizardState = {
    activePageId: undefined,
    activeSubPageId: undefined,
    pageIds: [],
    subPages: {},
}

const getCurrentPageIndex = (state: WizardState): number => {
    return state.activePageId ? state.pageIds.indexOf(state.activePageId) : 0
}

const getCurrentSubPageIndex = (state: WizardState): number => {
    return state.activePageId && state.activeSubPageId && state.subPages[state.activePageId]
        ? state.subPages[state.activePageId].indexOf(state.activeSubPageId)
        : 0
}

const canMoveSubPageForward = (state: WizardState, currentSubPageIndex: number): boolean => {
    return state.activePageId && state.activeSubPageId && state.subPages[state.activePageId]
        ? state.subPages[state.activePageId].length > currentSubPageIndex + 1
        : false
}

const canMovePageForward = (state: WizardState, currentPageIndex: number): boolean => {
    return state.pageIds.length > currentPageIndex + 1
}

export const wizardReducer = reducer<WizardState>(wizardInitialState)
    .add<RegisterRequest>(
        wizardActionTypes.REGISTER_ITEM,
        (state, request): WizardState => {
            const { pageId, subPageId } = request
            return {
                ...state,
                pageIds: _uniq([...state.pageIds, pageId]),
                subPages: {
                    ...state.subPages,
                    [pageId]: state.subPages[pageId] && subPageId ? _uniq([...state.subPages[pageId], subPageId]) : subPageId ? [subPageId] : [],
                },
            }
        },
    )
    .add<string>(wizardActionTypes.SET_ACTIVE_PAGE, (state, pageId) => {
        window.scrollTo(0, 0)
        return { ...state, activePageId: pageId, activeSubPageId: state.subPages[pageId][0] }
    })
    .add<string>(wizardActionTypes.SET_ACTIVE_SUBPAGE, (state, subPageId) => {
        window.scrollTo(0, 0)
        return { ...state, activeSubPageId: subPageId }
    })
    .add<void>(wizardActionTypes.NEXT_PAGE, state => {
        window.scrollTo(0, 0)
        const currentPageIndex = getCurrentPageIndex(state)
        const currentSubPageIndex = getCurrentSubPageIndex(state)
        const result = canMoveSubPageForward(state, currentSubPageIndex) && state.activePageId
        ? { ...state, activeSubPageId: state.subPages[state.activePageId][currentSubPageIndex + 1] }
        : canMovePageForward(state, currentPageIndex)
        ? {
              ...state,
              activePageId: state.pageIds[currentPageIndex + 1],
              activeSubPageId:
                  state.subPages[state.pageIds[currentPageIndex + 1]] && state.subPages[state.pageIds[currentPageIndex + 1]].length > 0
                      ? state.subPages[state.pageIds[currentPageIndex + 1]][0]
                      : undefined,
          }
        : state
        fireCustomFullStoryEvent(FsEvent.nextPageClicked, {currentState: state, futureState: result})
        return result
    })
    .add<void>(wizardActionTypes.PREVIOUS_PAGE, state => {
        window.scrollTo(0, 0)
        const currentPageIndex = getCurrentPageIndex(state)
        const currentSubPageIndex = getCurrentSubPageIndex(state)
        const result = currentSubPageIndex > 0 && state.activePageId
            ? { ...state, activeSubPageId: state.subPages[state.activePageId][currentSubPageIndex - 1] }
            : currentPageIndex > 0
            ? {
                  ...state,
                  activePageId: state.pageIds[currentPageIndex - 1],
                  activeSubPageId:
                      state.subPages[state.pageIds[currentPageIndex - 1]] && state.subPages[state.pageIds[currentPageIndex - 1]].length > 0
                          ? state.subPages[state.pageIds[currentPageIndex - 1]][state.subPages[state.pageIds[currentPageIndex - 1]].length - 1]
                          : undefined,
              }
            : state
        fireCustomFullStoryEvent(FsEvent.previousPageClicked, {currentState: state, futureState: result})
        return result
    })
    .add<RegisterSubPagesRequest>(wizardActionTypes.REGISTER_SUBPAGES, (state, request: RegisterSubPagesRequest) => {
        const { pageId, subPages } = request
        return {
            ...state,
            subPages: {
                ...state.subPages,
                [pageId]: subPages,
            },
        }
    })
    .build()
