import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BillingAccountTransactionHistory } from '@igs-web/common-models/models/billing-account-transaction-history'
import { LegacyPayment } from '@igs-web/common-models/models/legacy-data'
import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'

import { CommonReduxState } from '../../common-redux'
import { useCompany } from '../../company/hooks/useCompany'
import { useMyAccountDashboard } from '../../myaccount/dashboard-context'
import { legacyPaymentRedux } from '../../myaccount/legacy-data/legacy-payments-redux'
import { PaymentHistoryChart } from '../payment-history-chart'
import { BillingAccountPaymentHistoryTable } from '../payment-history-table'

export const BillingAccountPaymentHistory = ({ accountIds }: BillingAccountPaymentHistoryTableProps) => {
    const { features } = useCompany()
    const { billingAccount, getTransactionHistory } = useMyAccountDashboard()
    const dispatch = useDispatch()
    const [transactionHistory, setTransactionHistory] = React.useState<BillingAccountTransactionHistory | null>()

    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const legacyIsLoading = useSelector((store: CommonReduxState) =>
        legacyPaymentRedux.selectors.selectLoading(store, billingAccount?.billingAccountNumber || ''),
    )
    const legacyPayments =
        useSelector((store: CommonReduxState) => legacyPaymentRedux.selectors.selectData(store, billingAccount?.billingAccountNumber)) ??
        ([] as ReadonlyArray<LegacyPayment>)

    const currentDate = new Date()
    const paymentStartDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth() + 1, 5) //deal with edge cases

    const showPaymentChart =
        transactionHistory &&
        (transactionHistory.payments.some(p => new Date(p.date) > paymentStartDate) || legacyPayments.some(p => new Date(p.date) > paymentStartDate))

    React.useEffect(() => {
        if (features.useLegacydata && billingAccount?.billingAccountNumber && legacyPayments.length === 0) {
            dispatch(legacyPaymentRedux.actions.load({ key: billingAccount?.billingAccountNumber, showGlobalLoader: false }))
        }

        setIsLoading(true)
        getTransactionHistory().then(result => {
            setIsLoading(false)
            setTransactionHistory(result)
        })
    }, [billingAccount])

    return (
        <>
            <LocalLoader isLoading={isLoading || !!legacyIsLoading} />
            {!isLoading && !legacyIsLoading && (
                <>
                    {showPaymentChart && transactionHistory && <PaymentHistoryChart payments={transactionHistory.payments} legacyPayments={legacyPayments} />}
                    <div>
                        {transactionHistory?.payments && billingAccount && (
                            <BillingAccountPaymentHistoryTable
                                payments={transactionHistory?.payments}
                                legacyPayments={legacyPayments}
                                accountIds={accountIds}
                                billingAccountNumber={billingAccount?.billingAccountNumber}
                                dataTestId="payment-history-table"
                            />
                        )}
                    </div>
                </>
            )}
        </>
    )
}

interface BillingAccountPaymentHistoryTableProps {
    readonly accountIds: ReadonlyArray<number>
}
