import React from 'react'

import { BaseCompany } from '@igs-web/common-components/domain/company/company'
import { igsCompany } from '@igs-web/common-components/domain/company/igs/igs-company'
import { scanaRegulatedCompany } from '@igs-web/common-components/domain/company/scana-regulated/scana-regulated-company'
import { scanaCompany } from '@igs-web/common-components/domain/company/scana/scana-company'
import { ExternalLink } from '@igs-web/common-ui-components/_atoms/link/link'

const getCompanyByMembership = (membership: string): BaseCompany | undefined => {
    const igs = igsCompany()
    const scana = scanaCompany()
    const scanaRegulated = scanaRegulatedCompany()

    switch (membership) {
        case igs.membershipApplicationName:
            return igs
        case scana.membershipApplicationName:
            return scana
        case scanaRegulated.membershipApplicationName:
            return scanaRegulated
        default:
            return undefined
    }
}

export const multipleMembershipMessage = (company: BaseCompany, memberships: ReadonlyArray<string>) => {
    const externalMemberships = memberships.filter(m => m !== company.membershipApplicationName).map(c => getCompanyByMembership(c))
    const externalLinkText = externalMemberships.map((c, index) => {
        if (c) {
            return (
                <>
                    {externalMemberships.length === 1 || index === externalMemberships.length - 1 ? ' and ' : ', '}
                    <ExternalLink href={c.externalWebsite}>{c.displayName}</ExternalLink>
                </>
            )
        }
        return ''
    })

    const companyNamesText = externalMemberships.map((c, index) => {
        if (c) {
            return (
                <>
                    {c.displayName}
                    {externalMemberships.length > 1 && index !== externalMemberships.length - 1 ? ' and ' : ''}
                </>
            )
        }
    })

    return (
        <>
            Your {company.displayName}
            {externalLinkText} accounts are linked.{' '}
            <strong>
                If you update your password here, it will also update your {companyNamesText} {externalMemberships.length > 1 ? 'profiles' : 'profile'}.
            </strong>
        </>
    )
}
