import * as React from 'react'

import { ActionLink } from '@igs-web/common-ui-components/_atoms/link/action-link'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { downloadBlob } from '@igs-web/common-utilities/utilities/document-utilities'
import { dateFormats, formatDate } from '@igs-web/common-utilities/utilities/date-utilities'

const downloadInvoice = async (invoiceUrl: string, invoiceDate: Date | undefined | null): Promise<void> => {
    const blob = await customerInfoApiClient.getLegacyBillPdf({ billUrl: invoiceUrl })
    const date = invoiceDate ? formatDate(invoiceDate, dateFormats.fullDayMonthYear) : ''
    const filename = 'SCANA Energy Bill ' + date
    downloadBlob(blob, filename, 'pdf')
}

export const LegacyInvoicePdfLink = ({ invoiceUrl, label, invoiceDate }: LegacyInvoicePdfLinkProps): JSX.Element => {
    return (
        <ActionLink
            onClick={(): void => {
                downloadInvoice(invoiceUrl, invoiceDate)
            }}
        >
            {label}
        </ActionLink>
    )
}

interface LegacyInvoicePdfLinkProps {
    readonly invoiceUrl: string
    readonly invoiceDate?: Date | undefined | null
    readonly label: string
}
