import React from 'react'
import styled from 'styled-components'

import { SmallText } from '@igs-web/common-ui-components/_atoms/typography'

import { SupportedCompanies } from '../company/company'
import { useCompany } from '../company/hooks/useCompany'

const StyledSmallText = styled(SmallText)`
    margin: 0;
    line-height: normal;
`

export const EmailMarketingConsentNotice = ({ footnoteCalloutSymbol }: Props) => {
    const { displayName, name } = useCompany()
    const formattedDisplayName = name === SupportedCompanies.Scana ? 'We' : displayName
    return (
        <StyledSmallText>
            {footnoteCalloutSymbol}You can opt-out of these communications at any time by clicking the unsubscribe links at the bottom of each email.{' '}
            {formattedDisplayName} will never sell your personal information to a third party.
        </StyledSmallText>
    )
}

interface Props {
    readonly footnoteCalloutSymbol?: string
}
