import * as React from 'react'

import styled from 'styled-components'

import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { NavigationBanner } from 'molecules/header/navigation-banner'
import { LayoutWithSidebar } from 'molecules/layout/layout-styles'

import { ContactSidebar } from './contact-sidebar'
import { ContactUsForm } from './contact-us-form'
import { Emergency } from './emergency-box'

const FormSubmittedText = styled.div`
    grid-area: info;
`

const ContactUsLayout = styled(LayoutWithSidebar)`
    grid-template-columns: 5fr 3fr;
    grid-template-areas:
        'info emergency'
        'main emergency'
        'main sidebar'
        'main sidebar';
    grid-gap: ${Spacing.Medium};
    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-columns: 1fr;
        grid-template-areas:
            'info'
            'emergency'
            'main'
            'sidebar';
    }
`

export const ContactUsPage = () => {
    const [successfulSubmit, setSuccessfulSubmit] = React.useState(false)
    const [isHomeWarrantyRequest, setIsHomeWarrantyRequest] = React.useState(false)
    return (
        <>
            <NavigationBanner title="Contact Us" />
            <PageContentContainer>
                <GridSpacer spacing={Spacing.Large} />
                <ContactUsLayout>
                    <Emergency />
                    {!successfulSubmit && <ContactUsForm afterSubmit={() => setSuccessfulSubmit(true)} setIsHomeWarrantyRequest={setIsHomeWarrantyRequest} />}
                    {successfulSubmit && (
                        <FormSubmittedText>
                            <Header3>Thank you for your inquiry</Header3>
                            <p>We value your input and will respond within {isHomeWarrantyRequest ? '24' : '72'} hours.</p>
                        </FormSubmittedText>
                    )}
                    <ContactSidebar />
                </ContactUsLayout>
            </PageContentContainer>
        </>
    )
}
