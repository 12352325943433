import { Url, buildUrl } from '@igs-web/common-utilities/utilities/url-utilities'

export const userProfileRoutes = {
    userProfileUrl: (link?: ProfileSection): Url => buildUrl(`/MyAccount/MyProfile${link ? `#${link}` : ''}`),
    emailVerificationUrl: (): Url => buildUrl('/MyAccount/Verification'),
}

export enum ProfileSection {
    AccountLinking = 'AccountLinking',
    AccountRemoval = 'AccountRemoval',
    AuthorizedUsers = 'AuthorizedUsers',
    ChangePassword = 'ChangePassword',
    ChangeEmail = 'ChangeEmail',
    ChangeName = 'ChangeName',
    ChangePhone = 'ChangePhone',
    CommunicationPreferences = 'CommunicationPreferences',
    PaymentsAndBilling = 'PaymentsAndBilling',
    RatePlanPreferences = 'RatePlanPreferences',
    CommunitySolarContactMethod = 'CommunitySolarContactMethod',
    CommunitySolarMovingAndCancellation = 'CommunitySolarMovingAndCancellation',
}
