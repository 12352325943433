import * as React from 'react'
import styled from 'styled-components'

import { LineOfBusinessCode } from '@igs-web/common-models/models'
import { ExternalLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { SmallText } from '@igs-web/common-ui-components/_atoms/typography'

import { SupportedCompanies } from '../company/company'
import { useCompany } from '../company/hooks/useCompany'
import { LegalRoutes } from './legal-routes'

const StyledSmallText = styled(SmallText)`
    margin: 0;
    line-height: normal;
`

const getCommDisplayName = (name: SupportedCompanies | '', merchantName: string, displayName: string, lineOfBusinessCode?: LineOfBusinessCode) => {
    if (name === SupportedCompanies.Igs) {
        return displayName
    }
    if (name === SupportedCompanies.ScanaRegulated) {
        return displayName
    }
    if (name === SupportedCompanies.Scana) {
        if (lineOfBusinessCode === LineOfBusinessCode.HomeWarranty) {
            return merchantName
        }
        return displayName
    }
    return displayName
}

export const ElectronicCommunicationConsentNotice = ({ lineOfBusinessCode, footnoteCalloutSymbol }: Props) => {
    const { displayNameByLob: merchantName, displayName, name } = useCompany()
    const communincationDisplayName = getCommDisplayName(name, merchantName(lineOfBusinessCode), displayName, lineOfBusinessCode)

    return (
        <StyledSmallText>
            {footnoteCalloutSymbol}Your confirmation certifies that you are using a valid personal email address and have read and understood the{' '}
            <ExternalLink href={LegalRoutes.electronicCommunicationConsent().toString()}>Consent for Electronic Communications</ExternalLink> and give your
            consent to electronically receive account and service-related communications from {communincationDisplayName}.
        </StyledSmallText>
    )
}

interface Props {
    readonly lineOfBusinessCode?: LineOfBusinessCode
    readonly footnoteCalloutSymbol?: string
}
