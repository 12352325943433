import * as React from 'react'
import styles from 'root/../styles/abstracts/_variables.scss'
import styled from 'styled-components'

import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

const StyledNavIcon = styled.div`
    display: grid;
    align-items: end;
    grid-template-columns: min-content 1fr;
    grid-gap: ${Spacing.Small};
    padding-bottom: ${Spacing.Small};
    cursor: pointer;

    &:hover {
        color: ${styles.primaryLight};
        .nav-react-icon {
            color: ${styles.primaryLight};
        }
    }

    > span.icon-label {
        display: none;
        @media (min-width: ${styles.xxlWidth}) {
            display: block;
        }
        font-family: unset !important;
    }
    > span {
        @media (min-width: ${styles.xxlWidth}) {
            text-transform: uppercase;
            font-size: 0.875rem;
            line-height: 0.875rem;
            color: ${styles.primaryBase};
            font-weight: 300;
        }
    }
`

export const NavIcon = ({ icon, label, ...props }: Props) => (
    <StyledNavIcon {...props}>
        {icon}
        {label && <span className={'icon-label'}>{label}</span>}
    </StyledNavIcon>
)

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly icon: React.ReactNode
    readonly label: React.ReactNode
}
