import * as React from 'react'

import styled from 'styled-components'

import { Address } from '@igs-web/common-models/models'
import { DetailList, DetailListItem } from '@igs-web/common-ui-components/_molecules/detail-list'
import { getFormattedAddress } from '@igs-web/common-utilities/utilities/address-utilities'
import { BillingAccountListItem } from '@igs-web/common-utilities/utilities/billing-account-utilities'

import { AccountDetailsCard } from '../../account-details/account-details-card/account-details-card'
import { AccountDetailsCardBody } from '../../account-details/account-details-card/account-details-card-body'
import { AccountDetailsCardHeader } from '../../account-details/account-details-card/account-details-card-header'
import { wideInformationBoxColumns } from '../myaccount-components'

import { BillingAccountSelectForm } from './billingaccount-select-form'

const StyledBillingInfo = styled(AccountDetailsCard)`
    height: 100%;
`

export const BillingInformation = ({ billingAccountNumber, name, addresses, billingAccounts, selectAccount }: Props) => (
    <StyledBillingInfo>
        <AccountDetailsCardHeader>Billing Information</AccountDetailsCardHeader>
        <AccountDetailsCardBody>
            <DetailList style={{ ...wideInformationBoxColumns }}>
                <DetailListItem
                    title="Billing Account Number:"
                    dataTestId={billingAccounts.length > 1 ? 'billing-account-number-dropdown' : 'billing-account-number-label-value'}
                >
                    {billingAccounts.length > 1 ? (
                        <BillingAccountSelectForm billingAccounts={billingAccounts} onSelect={selectAccount} selectedBillingAccount={billingAccountNumber} />
                    ) : (
                        billingAccountNumber
                    )}
                </DetailListItem>
                <DetailListItem title="Name on Bill:" dataTestId="name-on-bill">
                    {name}
                </DetailListItem>
                <DetailListItem title="Billing Address:" dataTestId="billing-address">
                    {addresses.map((address, i) => (
                        <div key={address.serviceAddressKey || i}>{getFormattedAddress(address)}</div>
                    ))}
                </DetailListItem>
            </DetailList>
        </AccountDetailsCardBody>
    </StyledBillingInfo>
)

interface Props {
    readonly billingAccountNumber: string
    readonly name: string
    readonly addresses: ReadonlyArray<Address>
    readonly billingAccounts: ReadonlyArray<BillingAccountListItem>
    readonly selectAccount: (billlingAccountNumber: string) => void
}
