import styled, { createGlobalStyle, css } from 'styled-components'

import { ContainerGrid } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeVerySmall, linkColor } from '@igs-web/common-ui-components/styles/theme'

const fontFamily = '"Open Sans", Verdana, sans-serif'

export const cssVariables = css`
    /*colors*/
    --scana-yellow: #ffe06c; //callout yellow
    --scana-warning: #ce7b28;
    --max-container-width: 1200px;
    --max-content-width: ${Breakpoint.Desktop};
    --spacing: 25px;
    --white: #fff;
    --default-font-size: 16px;
    --font-size-small: 14px;

    --dark-dark: #231f20;
    --dark-base: #231f20;
    --dark-accent: #ccc;
    --dark-700: #6a6969;
    --dark-light: var(--dark-700);
    --primary-dark: #1468a0;
    --light-light: #effaff;
    --light-accent: #f0f9fe;
    --primary-light: #00a5e4;
    --brand-light: #00a5e4;
    --brand-base: #f0f9fe;
    --brand-accent: #337dc1;
    --light-base: #effaff;
    --service-gas: #00a5e4;
    --primary-base: #337dc1; //arguably should be the lighter color, #00a5e4
    --primary-base-rgb: 0, 165, 239;
    --secondary-dark-rgb: 206, 123, 40; //Should be removed
    --secondary-dark: #ce7b28; //Should be removed
    --secondary-base: #f2a900;
    --black: #231f20;
    --light-dark: #eee;
    --btn-background: #00a5ef;
    --btn-background-gradient: linear-gradient(180deg, #4bcdff, #337dc1);
    --btn-border: #00a5ef;
    --border-radius: 5px;
    --btn-text: white;
    --btn-hover-background: #fff;
    --btn-hover-border: #00a5ef;
    --btn-hover-text: #00a5ef;
    --btn-font-size: 16px;
    --link-hover-text: #337dc1;
    --font-family-header: ${fontFamily};
    --background: white;
    --body-color: var(--black);
    --accent: var(--dark-accent);
    --header-color: var(--primary-light);
    --link-color: var(--primary-light);
    --warning: var(--scana-warning);
    --warningConrast: #231f20;
    --success: #2e7d32;
    --secondary-light: #f2f2f2;
    --primary-linear-gradient: transparent linear-gradient(357deg, #337dc1 0%, #00a5e4 100%) 0% 0% no-repeat padding-box;
    --error: #af272f;
    --sunshine15: var(--scana-yellow);

    --alert-background-color: #f8d7da;
    --alert-border-color: #f5c6cb;
    --callout-background: var(--scana-yellow);
    --callout-foreground: var(--black);
    --callout-link-foreground: var(--black);
    --callout-link-decoration: underline;
    --tab-color: var(--primary-dark);
    --accordion-separator-color: var(--dark-700);
    --border-box-color: #e6e6e6;
    --light-contrast-background-color: #fcf9f0;
    --z-index-modal: 100;
    --myaccount-spacing: ${Spacing.Large};
    --myaccount-home-warranty: var(--primary-base);

    --hero-background: #ccdce9;
    --hero-foreground: var(--black);

    --bannerBackground: var(--primary-linear-gradient);
    --bannerForeground: var(--light-light);
    --modal-button-color: var(--black);
    /*from tokens.css*/
    --size-none: 0;
    --size-xxs: 2px;
    --size-xs: 4px;
    --size-sm: 8px;
    --size-md: 16px;
    --size-lg: 24px;
    --size-xl: 32px;
    --size-xl5: 48px;
    --size-xxl: 64px;
    --size-section-gap: 100px;
    --focus-outline: var(--size-xxs) solid var(--secondary-base);
    --font-family-sans-serif: ${fontFamily};

    --label-vertical-offset: -2px;
`

export const GlobalStyle = createGlobalStyle`
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
:root {
  font-family: ${fontFamily};
    color: #231f20;
    font-size: var(--default-font-size);
    line-height: 24px;
    justify-content: center;
    margin-top: 20px;
    ${cssVariables}
   
  }
    .theme-dashboard-lob-card {
        --background: var(--primary-light);
        --accent: white;
        --horizontal-line: white;
        --header-color: var(--primary-dark);
        --subheader-color: var(--primary-dark);
        --body-color: var(--primary-dark);
        --link-hover-text: var(--primary-dark);
    }
    .theme-details-card {
        --background: var(--primary-dark);
        --accent: var(--dark-accent);
        --horizontal-line: white;
        --header-color: var(--light-light);
        --subheader-color: var(--light-light);
        --body-color: var(--light-light);
        --link-hover-text: var(--primary-light);
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    label {
        margin-bottom: 0.5rem;
    }
    .login-form-page .forgot-password-btn {
        background: var(--btn-background);
        border-color: var(--btn-border);
        :hover {
            background-color: var(--btn-hover-background);
            color: var(--btn-hover-text);
            border-color: var(--btn-hover-border);
        }
    }
    .link {
        cursor:pointer;
    }

    a {
        text-decoration: none;
        color: ${linkColor};
        text-decoration: none;
    }
    a:hover,
    a:focus {
        text-decoration: underline;
    }

    h3 {
        font-weight: bold;
        font-size: 2rem;
        text-transform: initial;
    }

    body {
        margin:0;
    }

    [role=button] {
      cursor: pointer;
    }

    button:not(:disabled),
        [type=button]:not(:disabled),
        [type=reset]:not(:disabled),
        [type=submit]:not(:disabled) {
        cursor: pointer;
    }
    p.footnote {
            margin-top: ${Spacing.Medium};
            font-size: ${fontSizeVerySmall};
            line-height: 1.5;
        }
    `
export const StyledApp = styled(ContainerGrid)`
    position: absolute;
    top: 0;
`
