import { CachedReduxDataStateType, setupCachedReduxData } from '@igs-web/common-components/domain/cached-redux/cached-redux-data'
import { IsAutoRenew } from '@igs-web/common-models/models/auto-renew-status-response'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

const load = async (partyAccountId: number, config: RequestConfig): Promise<IsAutoRenew | undefined> => {
    try {
        return (await customerInfoApiClient.getIsAutoRenew(partyAccountId, config)) || undefined
    } catch (e) {
        console.error(`There was a problem getting usage: ${e.message}`)
    }
}

const { actions, selectors, saga, reducers } = setupCachedReduxData('isAutoRenew', load)

export const isAutoRenewRedux = { actions, selectors }
export const isAutoRenewSaga = saga
export const isAutoRenewReducer = reducers
export type IsAutoRenewState = CachedReduxDataStateType<typeof load>
