import { createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'
import * as React from 'react'

interface EditableGridState {
    readonly isValid: boolean
}

interface EditableGridDispatch {
    readonly setIsValid: (isValid: boolean) => void
}

const EditableGridState = React.createContext<EditableGridState | undefined>(undefined)
const EditableGridDispatch = React.createContext<EditableGridDispatch | undefined>(undefined)

export const useEditableGridState = (): EditableGridState => {
    const context = React.useContext(EditableGridState)
    if (!context) {
        throw Error('Must use useEditable inside of EditableGridProvider')
    }

    return context
}

export const useEditableGridDispatch = (): EditableGridDispatch => {
    const context = React.useContext(EditableGridDispatch)
    if (!context) {
        throw Error('Must use useEditableGridDispatch inside of EditableGridContextProvider')
    }

    return context
}

export const editableGridInitialState: EditableGridState = {
    isValid: false,
}

export const EditableGridContextProvider = ({ children }: EditableGridContextProviderProps) => {
    const initialState: EditableGridState = {
        ...editableGridInitialState,
    }
    const [state, dispatch] = React.useReducer(editableGridReducer, initialState)

    const stateValue: EditableGridState = {
        isValid: state.isValid,
    }

    const dispatchValue: EditableGridDispatch = {
        setIsValid: (isValid: boolean) => dispatch(EditableGridActions.setIsValid(isValid)),
    }

    return (
        <EditableGridState.Provider value={stateValue}>
            <EditableGridDispatch.Provider value={dispatchValue}>{children}</EditableGridDispatch.Provider>
        </EditableGridState.Provider>
    )
}

interface EditableGridContextProviderProps {
    readonly children: React.ReactNode
}

export const editableGridActionTypes = {
    SET_IS_VALID: '[EditableGrid] SET_IS_VALID',
}

export const EditableGridActions = {
    setIsValid: createAction<boolean>(editableGridActionTypes.SET_IS_VALID),
}

export const editableGridReducer = reducer<EditableGridState>(editableGridInitialState)
    .add<boolean>(
        editableGridActionTypes.SET_IS_VALID,
        (state, request): EditableGridState => {
            return {
                ...state,
                isValid: request,
            }
        },
    )
    .build()
