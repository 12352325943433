import React from 'react'

import { useWizardDispatch, useWizardState } from './wizard-context'

export const useWizardItem = (pageId: string, subPageId?: string): WizardItem => {
    const { pageIds, activePageId, activeSubPageId, isRegistered } = useWizardState()
    const { registerPage, setActivePageId, setActiveSubPageId } = useWizardDispatch()

    const isItemRegistered = isRegistered(pageId, subPageId)

    const registerItem = React.useCallback(() => {
        if (!isItemRegistered) {
            registerPage(pageId, subPageId)
        }
    }, [pageId, subPageId, registerPage, isItemRegistered])

    const setActive = React.useCallback(() => {
        setActivePageId(pageId)
        if (subPageId) {
            setActiveSubPageId(subPageId)
        }
    }, [pageId, setActivePageId, subPageId, setActiveSubPageId])

    const pageIndex = pageIds.indexOf(pageId)
    const activePageIndex = !!activePageId ? pageIds.indexOf(activePageId) : -1
    const isBeforeActive = pageIndex < activePageIndex
    const isAfterActive = pageIndex > activePageIndex

    const isActiveDetermination = () => {
        return activePageId === pageId && (subPageId ? activeSubPageId === subPageId : true)
    }

    const isActive = isActiveDetermination()

    return {
        isRegistered: isItemRegistered,
        isActive,
        isBeforeActive,
        isAfterActive,
        registerItem,
        setActive,
    }
}

interface WizardItem {
    readonly isRegistered: boolean
    readonly isActive: boolean
    readonly isBeforeActive: boolean
    readonly isAfterActive: boolean
    readonly setActive: () => void
    readonly registerItem: () => void
}
