import * as Routes from 'domain/routes'
import * as React from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'

import { UserProfile } from '@igs-web/common-models/models/user-profile-model'
import { getDefaultServiceAddress, getServiceAccountUrl, getSortedBillingAccountItems } from '@igs-web/common-utilities/utilities/billing-account-utilities'

import { BillingAccountDashboard } from './billing-account-dashboard'
import { AccountAndBalancesView } from './billing-accounts-view'
import { useMyAccountDashboard } from './dashboard-context'

const noRouteMessage = 'Sorry, we could not find what you are looking for?'

export const MyAccountDashboardContainer = ({ userProfile }: Props) => {
    const match = useRouteMatch()
    const history = useHistory()
    const { getBillingAccountStatus } = useMyAccountDashboard()
    const { url, isExact } = match

    if (isExact) {
        const billingAccountsList = getSortedBillingAccountItems(userProfile.billingAccounts, getBillingAccountStatus)
        const billingAccountNumber = billingAccountsList[0].billingAccountNumber
        const address = getDefaultServiceAddress(billingAccountNumber, userProfile)
        history.push(getServiceAccountUrl(billingAccountNumber, address?.serviceAddressKey))
    }

    return (
        <Switch>
            <Route path={`${url}/:billingAccountNumber/:serviceAddressKey`}>
                <BillingAccountDashboard />
            </Route>
            <Route path={Routes.AccountsAndBalances}>
                <AccountAndBalancesView />
            </Route>
            <Route>
                <span>{noRouteMessage}</span>
            </Route>
        </Switch>
    )
}

interface Props {
    readonly userProfile: UserProfile
}
