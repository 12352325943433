import * as React from 'react'

import { AccountContainer } from '@igs-web/common-components/domain/plan-at-a-glance/account-container'
import { RatesButton } from '@igs-web/common-components/domain/plan-at-a-glance/rates-button'
import { UserProfileAccount } from '@igs-web/common-models/models/user-profile-model'
import { ButtonSection } from '@igs-web/common-ui-components/_atoms/buttons/button-section'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'

export const InactiveGasAccount = ({ account, hasSmallCommercialAccount }: Props) => {
    return (
        <AccountContainer>
            <Header3>Inactive Account</Header3>
            <ButtonSection alignment="center">
                <RatesButton account={account} hasSmallCommercialAccount={hasSmallCommercialAccount} />
            </ButtonSection>
        </AccountContainer>
    )
}

interface Props {
    readonly account: UserProfileAccount
    readonly hasSmallCommercialAccount: boolean
}
