import * as React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { MyAccountSelectors } from '@igs-web/common-components/domain/myaccount-redux'
import { StyledBreadCrumbList } from '@igs-web/common-components/domain/myaccount/myaccount-components'
import { useOffersContextDispatch, useOffersContextState } from '@igs-web/common-components/domain/offers/context/offers-context'
import { OffersInContextType } from '@igs-web/common-components/domain/offers/context/offers-context-models'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { OfferAccountType } from '@igs-web/common-models/constants/account-type'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { BoxContainer } from '@igs-web/common-ui-components/_atoms/box-container'
import { ExternalLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Header5 } from '@igs-web/common-ui-components/_atoms/typography'
import { BreadcumbItem } from '@igs-web/common-ui-components/_molecules/breadcrumbs'
import { ContainerGrid, GridSpacer, Layout1frColumn } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { borderRadius, lightAccent, lightDark } from '@igs-web/common-ui-components/styles/theme'
import { getOfferAccountType } from '@igs-web/common-utilities/services/account-service'
import { canRenew } from '@igs-web/common-utilities/services/contract-eligibility-service'

import { NavigationBanner } from 'molecules/header/navigation-banner'

import { RenewalOffersContainer } from 'domain/rate-change/renewal-offers-container'
import * as Routes from 'domain/routes'

import { InelligibleRateChangeMessage } from './inelligible-rate-change-message'
import { RateChangeOffers } from './rate-change-offers'

const RATE_CHANGE_LOB = LineOfBusinessCode.Gas

const RateChangeContainer = styled.div`
    padding: ${Spacing.Large};
    border: 1px solid ${lightDark};
    border-radius: ${borderRadius};
`

export const RateChange = () => {
    const history = useHistory()
    const company = useCompany()

    const { offersAreLoading, offersRequest } = useOffersContextState()
    const offersContextDispatch = useOffersContextDispatch()

    const selectedServiceAddress = useSelector(MyAccountSelectors.selectSelectedServiceAddress)
    const accounts = useSelector((state: CommonReduxState) => UserSelectors.selectPartyAccountsByAddressKey(state, selectedServiceAddress?.serviceAddressKey))

    const isCommercialIndustrial = useSelector(UserSelectors.selectProfile)?.isCommercialIndustrial
    const activeAccounts = accounts.filter(a => a.status === AccountStatus.active && a.lineOfBusinessCode !== LineOfBusinessCode.HomeWarranty)
    const selectedAccount = activeAccounts?.find(aa => aa.lineOfBusinessCode === RATE_CHANGE_LOB)
    const isRenewal = selectedAccount ? canRenew(selectedAccount, company.features.canRenewAnyStatus) : false
    const offerType = isRenewal ? OffersInContextType.Renewal : OffersInContextType.RateChange

    const campaignCode = isRenewal ? company.enrollment.campaignCodes.renewalDefault : company.enrollment.campaignCodes.rateChangeDefault

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    React.useEffect(() => {
        if (selectedServiceAddress && selectedAccount && offerType !== offersRequest?.offersInContextType && !offersAreLoading) {
            offersContextDispatch.loadOffers({
                ignoreCache: true,
                offersInContextType: offerType,
                zipCode: selectedServiceAddress.zipCode,
                accountType: getOfferAccountType(selectedAccount.accountType ?? ''),
                campaignCode,
                invitationCode: undefined,
                priceEffectiveDate: new Date(),
                partyAccountId: selectedAccount.accountId,
                distributionZone: selectedAccount.distributionZone,
            })
        }
    }, [offerType, offersAreLoading, offersContextDispatch, offersRequest?.offersInContextType, selectedAccount, selectedServiceAddress])

    if (!selectedAccount || !selectedServiceAddress) {
        history.push(Routes.AccountInformation)
        return <></>
    }

    return (
        <>
            <NavigationBanner title={isRenewal ? 'Renewal' : 'Rate Change'} />
            <ContainerGrid $maxWidth={Breakpoint.Desktop}>
                <RateChangeContainer>
                    <GridSpacer spacing={Spacing.Small} />
                    <StyledBreadCrumbList>
                        <BreadcumbItem>{isRenewal ? 'Renewal' : 'Rate Change'}</BreadcumbItem>
                        <BreadcumbItem>
                            {selectedServiceAddress.address1} {selectedServiceAddress.address2}
                        </BreadcumbItem>
                    </StyledBreadCrumbList>
                    <GridSpacer spacing={Spacing.Large} />
                    {isCommercialIndustrial ? (
                        <div>
                            <Layout1frColumn>
                                <InelligibleRateChangeMessage />
                            </Layout1frColumn>
                        </div>
                    ) : (
                        <Layout1frColumn>
                            {!isRenewal && <RateChangeOffers selectedAccount={selectedAccount} />}
                            {isRenewal && <RenewalOffersContainer />}
                            <div>
                                <QuestionsBox $background={lightAccent}>
                                    <StyledHeader5>Questions? Want to explore other rates?</StyledHeader5>

                                    <StyledHeader5>
                                        <PhoneNumberLink phoneNumber={company.phoneNumber.customerSupport} />
                                    </StyledHeader5>
                                </QuestionsBox>
                                <GridSpacer spacing={Spacing.Large} />
                                <AdditionalRenewalInformation accountType={selectedAccount.accountType ?? undefined} />
                            </div>
                        </Layout1frColumn>
                    )}
                </RateChangeContainer>
            </ContainerGrid>
        </>
    )
}

const QuestionsBox = styled(BoxContainer)`
    text-align: center;

    > h3 {
        margin: ${Spacing.Medium};
    }
`

const StyledHeader5 = styled(Header5)`
    font-weight: ${FontWeight.Bold};
    margin: ${Spacing.Small};
`
const StyledP = styled.p`
    margin-top: 0px;
    margin-left: ${Spacing.Medium};
`

const AdditionalRenewalInformation = ({ accountType }: AdditionalRenewalInformationProps) => {
    const company = useCompany()
    return (
        <>
            <StyledHeader5>Customers aged 65 or older</StyledHeader5>
            <StyledP>
                If you&apos;re 65 or older, you may be eligible for a reduced rate. Please call us at{' '}
                <PhoneNumberLink phoneNumber={company.phoneNumber.customerSupport} /> for more information.
            </StyledP>
            <StyledHeader5>What happens if I let my rate expire?</StyledHeader5>
            <StyledP>If we don&apos;t hear from you before your rate expires, your rate will change to the SCANA Variable rate plan</StyledP>
            <StyledHeader5>What happens if I cancel before the end of my contract?</StyledHeader5>
            <StyledP>
                If you are on a fixed rate plan and switch to another Georgia natural gas marketer before the end of your contract, you will be charged an early
                exit fee of up to $300.
            </StyledP>
            <StyledHeader5>
                <ExternalLink href={company.routes.product.termsAndConditions(undefined, accountType)}>Terms &amp; Conditions</ExternalLink>
            </StyledHeader5>
        </>
    )
}

interface AdditionalRenewalInformationProps {
    readonly accountType?: string | OfferAccountType
}
