import { organizationAgentApiClient } from '@igs-web/common-utilities/api/organization-agent-api'

import { CachedReduxDataStateType, setupCachedReduxData } from '../../cached-redux/cached-redux-data'

const load = async (request, config) => {
    const {
        key,
        data: { partyIds },
    } = request
    try {
        const response = await organizationAgentApiClient.getOrganizationAgents(partyIds, config)
        return response ? response.map(r => ({ ...r })) : []
    } catch (e) {
        throw Error(`There was a problem getting organization agents for User Profile ${key}`)
    }
}

const { actions, selectors, saga, reducers } = setupCachedReduxData('organizationAgents', load)

export const buildOrganizationAgentKey = (userProfileId, partyIds: ReadonlyArray<number>) => ({
    key: userProfileId,
    data: {
        partyIds,
    },
})

export const organizationAgentActions = actions
export const organizationAgentSaga = saga
export const organizationAgentReducer = reducers
export type OrganizationAgentState = CachedReduxDataStateType<typeof load>

export const organizationAgentRedux = { actions, selectors }
