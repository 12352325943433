import * as React from 'react'

import { wizardActions, wizardInitialState, wizardReducer, WizardState } from './wizard-reducer'

interface WizardContextState {
    readonly pageIds: ReadonlyArray<string>
    readonly subPages: { readonly [key: string]: ReadonlyArray<string> }
    readonly activePageId?: string
    readonly activeSubPageId?: string
    readonly isRegistered: (pageId: string, subPageId?: string) => boolean
    readonly hasNext: boolean
    readonly hasPrevious: boolean
    readonly subPageIndex: number
    readonly totalSubPages: number
}

interface WizardContextDispatch {
    readonly setActivePageId: (pageId: string) => void
    readonly setActiveSubPageId: (subPageId: string) => void
    readonly moveToSubPage: (pageId: string, subPageId: string) => void
    readonly registerPage: (pageId: string, subPageId?: string) => void
    readonly nextPage: () => void
    readonly previousPage: () => void
    readonly registerSubPages: (pageId: string, subPageIds: ReadonlyArray<string>) => void
}

const WizardContextState = React.createContext<WizardContextState | undefined>(undefined)
const WizardContextDispatch = React.createContext<WizardContextDispatch | undefined>(undefined)

export const useWizardState = (): WizardContextState => {
    const context = React.useContext(WizardContextState)
    if (!context) {
        throw Error('Must use useWizardState inside of WizardContextProvider')
    }

    return context
}

export const useWizardDispatch = (): WizardContextDispatch => {
    const context = React.useContext(WizardContextDispatch)
    if (!context) {
        throw Error('Must use useWizardDispatch inside of WizardContextProvider')
    }

    return context
}

export const WizardContextProvider = ({ children, initiallyActivePageId }: WizardContextProviderProps) => {
    const initialState: WizardState = {
        ...wizardInitialState,
        activePageId: initiallyActivePageId,
    }
    const [state, dispatch] = React.useReducer(wizardReducer, initialState)

    const activePageId = state.activePageId || state.pageIds[0]

    const stateValue: WizardContextState = {
        pageIds: state.pageIds,
        subPages: state.subPages,
        activePageId,
        activeSubPageId: state.activeSubPageId,
        isRegistered: (pageId: string, subPageId?: string) =>
            state.pageIds &&
            state.pageIds.includes(pageId) &&
            (subPageId ? (state.subPages[pageId] ? state.subPages[pageId].includes(subPageId) : false) : true),
        hasPrevious: state.pageIds.indexOf(activePageId) > 0,
        hasNext: state.pageIds.indexOf(activePageId) < state.pageIds.length - 1,
        subPageIndex:
            state.activePageId && state.subPages[state.activePageId] && state.activeSubPageId
                ? state.subPages[state.activePageId].indexOf(state.activeSubPageId)
                : 0,
        totalSubPages: state.activePageId && state.subPages[state.activePageId] ? state.subPages[state.activePageId].length : 0,
    }

    const dispatchValue: WizardContextDispatch = {
        registerPage: (pageId: string, subPageId?: string) => dispatch(wizardActions.register({ pageId, subPageId })),
        setActivePageId: (pageId: string) => dispatch(wizardActions.setActivePage(pageId)),
        setActiveSubPageId: (subPageId: string) => dispatch(wizardActions.setActiveSubpage(subPageId)),
        moveToSubPage: (pageId: string, subPageId: string) => {
            dispatch(wizardActions.setActivePage(pageId))
            dispatch(wizardActions.setActiveSubpage(subPageId))
        },
        nextPage: () => dispatch(wizardActions.nextPage()),
        previousPage: () => dispatch(wizardActions.previousPage()),
        registerSubPages: (pageId: string, subPages: ReadonlyArray<string>) => {
            dispatch(wizardActions.registerSubPages({ pageId, subPages }))
            if (state.activePageId === pageId && state.activeSubPageId && subPages.indexOf(state.activeSubPageId) === -1) {
                dispatch(wizardActions.nextPage())
            }
        },
    }

    return (
        <WizardContextState.Provider value={stateValue}>
            <WizardContextDispatch.Provider value={dispatchValue}>{children}</WizardContextDispatch.Provider>
        </WizardContextState.Provider>
    )
}

interface WizardContextProviderProps {
    readonly initiallyActivePageId?: string
    readonly children: React.ReactNode
}
