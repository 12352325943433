import * as React from 'react'
import { BiSearch } from 'react-icons/bi'
import { useSelector } from 'react-redux'

import { useCombobox } from 'downshift'
import styled from 'styled-components'

import { AlertBody, AlertCard } from '@igs-web/common-components/domain/alerts/alert-card'
import { Verbiage } from '@igs-web/common-components/domain/company/companyVerbiage'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { matchBillingAccount } from '@igs-web/common-components/domain/myaccount/billing-accounts-view'
import { useMyAccountDashboard } from '@igs-web/common-components/domain/myaccount/dashboard-context'
import { InfoColumn, SearchGrid, SearchSelect } from '@igs-web/common-components/domain/myaccount/myaccount-components'
import { PaymentPortalForm } from '@igs-web/common-components/domain/payment/payment-portal-form'
import { matchServiceAddress } from '@igs-web/common-components/domain/service-address/service-address-form'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { Address } from '@igs-web/common-models/models'
import { SvgSize } from '@igs-web/common-ui-components/_atoms/SVGs/svg-icon'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { Header4 } from '@igs-web/common-ui-components/_atoms/typography'
import { AddressDisplay } from '@igs-web/common-ui-components/_molecules/address/address-display'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { borderBoxColor, primaryLight } from '@igs-web/common-ui-components/styles/theme'
import { getBillingAccountServiceAddresses, sortAccounts } from '@igs-web/common-utilities/utilities/billing-account-utilities'
import { formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'
import { formatDate } from '@igs-web/common-utilities/utilities/date-utilities'

const BillingAccountContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: ${Spacing.Medium};
    grid-column-gap: ${Spacing.Medium};
    place-items: start;
    border: 1px solid ${borderBoxColor};
    height: 100%;
    width: 100%;
    align-content: center;
    hr {
        width: 100%;
    }
    &:hover {
        background-color: rgb(230 230 230 / 2);
    }
    form {
        grid-column: 2;
        place-self: end;
    }
    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        grid-row-gap: ${Spacing.Medium};
        form {
            grid-column: 1;
            grid-row: 1;
            place-self: start;
        }
        ${InfoColumn} {
            justify-self: baseline;
        }
    }

    ${InfoColumn} {
        width: 100%;
        gap: 0;
    }
    button: {
        background: transparent;
    }
`
const AutoPayAlert = styled(AlertCard)`
    ${AlertBody} {
        font-size: 1rem;
    }
`
const PaymentButtons = styled.div`
    display: grid;
    grid-row-gap: ${Spacing.Medium};
    width: 100%;
    text-align: center;
`
const HelperText = styled.div`
    font-size: 0.85em;
    justify-self: center;
    width: 100%;
`
const PaymentInfoArea = styled.div`
    display: grid;
    grid-template-columns: 60fr 40fr;
    grid-template-rows: 1fr;
    grid-row-gap: ${Spacing.Medium};
    width: 100%;
    ${InfoColumn}:nth-child(2) {
        width: auto;
        justify-self: start;
    }
`
const SearchIcon = styled(BiSearch)`
    color: ${primaryLight};
    z-index: 1;
    position: absolute;
    margin: ${Spacing.Small};
`
const SearchBar = styled.input`
    width: 100%;
    position: relative;
    text-indent: ${Spacing.X5Large};
`
const ADDRESSES_TO_SHOW = 3

export const BillingAccountSearch = ({ initialSearch }: BillingAccountsListProps) => {
    const { getBillingAddress, profile, getBillingAccountStatus } = useMyAccountDashboard()
    const serviceAddresses = useSelector(UserSelectors.selectUserServiceAddresses)
    const serviceAccounts = useSelector(UserSelectors.selectPartyAccounts)
    const billingAccountSorter = sortAccounts(getBillingAccountStatus)

    const filterAccounts = search => {
        if (!search) {
            return [...profile.billingAccounts].sort(billingAccountSorter)
        }

        const filteredServiceAddresses = serviceAddresses.filter(address => matchServiceAddress(address, search.toLowerCase()))

        return profile.billingAccounts
            .filter(account => {
                return matchBillingAccount(account, filteredServiceAddresses, serviceAccounts, search.toLowerCase())
            })
            .sort(billingAccountSorter)
    }

    const [accounts, setAccounts] = React.useState(filterAccounts(initialSearch))

    const { getMenuProps, getInputProps, getComboboxProps } = useCombobox({
        items: [...accounts],
        initialInputValue: initialSearch,
        onInputValueChange: ({ inputValue }) => {
            setAccounts(filterAccounts(inputValue))
        },
    })

    const comboboxProps = getComboboxProps()

    return (
        <>
            {profile.billingAccounts.length > 1 && (
                <>
                    <SearchSelect comboboxProps={comboboxProps}>
                        <SearchIcon size={SvgSize.XSmall} />
                        <SearchBar placeholder="Find Address" {...getInputProps()} />
                    </SearchSelect>
                    <GridSpacer spacing={Spacing.Medium} />
                </>
            )}
            {!!accounts.length && (
                <SearchGrid {...getMenuProps()}>
                    {accounts.map(account => (
                        <BillingAccountLinkRow
                            billingAddress={getBillingAddress(account)}
                            serviceAddresses={getBillingAccountServiceAddresses(account, serviceAddresses, serviceAccounts)}
                            billingAccountNumber={account.billingAccountNumber}
                            key={account.billingAccountNumber}
                            email={profile.email}
                            amountDue={account.amountDue}
                            dueDate={account.dueDate}
                            paymentExtensionDueDate={account.paymentExtension.exisitingPaymentExtensionDueDate}
                        />
                    ))}
                </SearchGrid>
            )}
            {accounts.length === 0 && <Header4>No accounts match your search</Header4>}
        </>
    )
}

const PaymentDueDateInfo = ({ dueDate, paymentExtensionDueDate }: PaymentInfoProps) => {
    const FeeInfo = () => (
        <InfoColumn>
            <strong>Fee</strong>None
        </InfoColumn>
    )
    if (paymentExtensionDueDate) {
        return (
            <PaymentInfoArea>
                <InfoColumn>
                    <strong>Extension Due Date</strong>
                    {formatDate(paymentExtensionDueDate)}
                </InfoColumn>
                <FeeInfo />
                <InfoColumn>
                    <strong>Original Due Date</strong>
                    {formatDate(dueDate)}
                </InfoColumn>
            </PaymentInfoArea>
        )
    }
    return (
        <PaymentInfoArea>
            <InfoColumn>
                <strong>Due Date</strong>
                {formatDate(dueDate)}
            </InfoColumn>
            <FeeInfo />
        </PaymentInfoArea>
    )
}

const BillingAccountLinkRow = ({
    billingAddress,
    serviceAddresses,
    billingAccountNumber,
    email,
    amountDue,
    dueDate,
    paymentExtensionDueDate,
    isEnrolledInAutoPay = false,
}: BillingAccountRowProps) => {
    const { routes, verbiage } = useCompany()
    const remainderCount = serviceAddresses.length - ADDRESSES_TO_SHOW
    const displayAddresses = serviceAddresses.slice(0, ADDRESSES_TO_SHOW)
    return (
        <BillingAccountContainer>
            <InfoColumn>
                <strong>Billing Account</strong>
                {billingAccountNumber}
            </InfoColumn>
            <InfoColumn>
                <strong>Service Address</strong>
                {displayAddresses.map((a, i) => {
                    const displaySeperator = i !== displayAddresses.length - 1
                    return (
                        <>
                            <AddressDisplay key={a.serviceAddressKey} address={a} breakingAddress={true} />
                            {displaySeperator && <hr />}
                        </>
                    )
                })}
                {remainderCount > 0 && <p>and {remainderCount} more</p>}
            </InfoColumn>
            <InfoColumn>
                <strong>Amount Due</strong>
                {formatAsMoney(amountDue)}
            </InfoColumn>
            <PaymentDueDateInfo dueDate={dueDate} paymentExtensionDueDate={paymentExtensionDueDate} />
            <div>{isEnrolledInAutoPay && <AutoPayAlert>You are enrolled in autopay. Your payment will be posted on the due date.</AutoPayAlert>}</div>
            <PaymentButtons>
                <PaymentPortalForm
                    email={email}
                    zipCode={billingAddress.zipCode}
                    submitButtonText={verbiage[Verbiage.paymentButtonText]?.toString()}
                    billingAccountNumber={billingAccountNumber}
                    isLoggedIn={true}
                />
                <HelperText>
                    <Link href={routes.payment.paymentExtension(billingAccountNumber)}>Create a payment extension</Link>
                </HelperText>
            </PaymentButtons>
        </BillingAccountContainer>
    )
}

interface PaymentInfoProps {
    readonly dueDate: number
    readonly paymentExtensionDueDate?: Date | null
}

interface BillingAccountsListProps {
    readonly initialSearch?: string
}
interface BillingAccountRowProps {
    readonly billingAddress: Address
    readonly serviceAddresses: ReadonlyArray<Address>
    readonly billingAccountNumber: string
    readonly email: string
    readonly amountDue: number
    readonly dueDate: number
    readonly paymentExtensionDueDate?: Date | null
    readonly isEnrolledInAutoPay?: boolean
}
