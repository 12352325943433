import * as React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { CommonReduxState } from '../common-redux'
import { BudgetBillingChart } from './budget-billing-chart'
import { budgetBillingRedux } from './budget-billing-redux'
import { BudgetBillingTable } from './budget-billing-table'

//NOTE(BF) - setting min-height on tablet is to deal with clipping issues for popups;
// setting overflow-x instantiates a new clipping context. The other options is to position
// the menus with javascript which is potentially hairy for a single use-case
const DetailsContainer = styled.div`
    display: grid;
    max-width: 100%;
    grid-gap: ${Spacing.Medium};
    & > * {
        overflow-x: auto;
        @media (min-width: ${Breakpoint.Tablet}) {
            min-height: 300px;
        }
    }
`

export const BudgetBillingDetails = ({ billingAccountNumber }: Props) => {
    const budgetBillResponse = useSelector((store: CommonReduxState) => budgetBillingRedux.selectors.selectData(store, billingAccountNumber))
    const budgerBillingIsLoading = useSelector((store: CommonReduxState) => budgetBillingRedux.selectors.selectLoading(store, billingAccountNumber))
    const budgetBillInfo = budgetBillResponse?.lastInvoiceBudgetBillingInformation || []
    const budgetBillBeginningBalance = budgetBillResponse?.beginningBudgetAccountBalance || 0

    return (
        <>
            <LocalLoader isLoading={budgerBillingIsLoading ?? false} />
            {!budgerBillingIsLoading && (
                <DetailsContainer>
                    <BudgetBillingChart budgetBillInfo={budgetBillInfo} />
                    <BudgetBillingTable budgetBillInfo={budgetBillInfo} budgetBillBeginningBalance={budgetBillBeginningBalance} />
                </DetailsContainer>
            )}
        </>
    )
}
interface Props {
    readonly billingAccountNumber: string
}
