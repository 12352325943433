import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { CoverageDetailItem } from '@igs-web/common-models/models/account-detail-model'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'

export const coverageTypes = {
    LOAD_ACCOUNT_COVERAGES: '[home-warranty] LOAD_ACCOUNT_COVERAGES',
    SET_ACCOUNT_COVERAGES: '[home-warranty] SET_ACCOUNT_COVERAGES',
    LOAD_ACCOUNT_COVERAGES_FAIL: '[home-warranty] LOAD_ACCOUNT_COVERAGES_FAIL',
}

export const coverageActions = {
    loadAccountCoverages: createAction<RequestConfig & { readonly accountId: number }>(coverageTypes.LOAD_ACCOUNT_COVERAGES),
    setAccountCoverages: createAction<UpdateRequest>(coverageTypes.SET_ACCOUNT_COVERAGES),
    loadAccountCoveragesFail: createAction<string>(coverageTypes.LOAD_ACCOUNT_COVERAGES_FAIL),
}

export const coverageReducer = reducer<CoverageState>({})
    .add<UpdateRequest>(coverageTypes.SET_ACCOUNT_COVERAGES, (state, request) => ({
        ...state,
        [request.accountId]: request.coverage,
    }))
    .build()

export class CoverageSelectors {
    public static readonly selectCoverages = (state: CommonReduxState, accountId: number): ReadonlyArray<CoverageDetailItem> | undefined =>
        state.coverage[accountId]
}

const sagas = {
    *loadCoverages(action: { readonly payload: RequestConfig & { readonly accountId: number } }) {
        const accountId = action.payload.accountId
        const currentCoverage: ReadonlyArray<CoverageDetailItem> = yield select(CoverageSelectors.selectCoverages, accountId)
        if (!currentCoverage) {
            try {
                const response: ReadonlyArray<CoverageDetailItem> = yield call(() => customerInfoApiClient.getHPCoverages(accountId, action.payload))
                const footnotes = response.filter(u => u.footnote).reverse()
                const coverage = response.map<CoverageDetailItem>(u => {
                    const footnoteIndex = footnotes.findIndex(f => f.coverageCode === u.coverageCode)
                    return {
                        ...u,
                        effectiveDate: u.effectiveDate ? Date.parse(u.effectiveDate + '') : undefined,
                        footnoteSymbol: footnoteIndex === -1 ? undefined : footnoteIndex + 1,
                    }
                })
                yield put(coverageActions.setAccountCoverages({ accountId, coverage }))
            } catch (e) {
                const message = `There was a problem getting coverages: ${e.message}`
                yield put(coverageActions.loadAccountCoveragesFail(message))
            }
        }
    },
}

export function* coverageSaga() {
    yield all([takeEvery(coverageTypes.LOAD_ACCOUNT_COVERAGES, sagas.loadCoverages as any)])
}

export interface CoverageState {
    readonly [key: number]: ReadonlyArray<CoverageDetailItem>
}

interface UpdateRequest {
    readonly accountId: number
    readonly coverage: ReadonlyArray<CoverageDetailItem>
}
