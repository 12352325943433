import styled, { css } from 'styled-components'

import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

const borderWrapperCss = css`
    border: 1px solid var(--dark-base);
`

const borderRowCss = css`
    border-bottom: 1px solid var(--dark-base);

    &:last-child {
        border-bottom: 0;
    }
`

const borderUnitCss = css`
    border-right: 1px solid var(--dark-base);

    &:last-child {
        border-right: 0;
    }
`

const alternatingRowCss = css`
    &:nth-child(even) {
        background-color: var(--light-accent);
    }
`

export const HeaderRow = styled.tr`
    background: var(--primary-base);
    color: var(--light-light);
`

export const HeaderCell = styled.th`
    padding: ${Spacing.Medium};
    text-align: center;
`

export const TableHead = styled.thead`
    ${borderWrapperCss}

    ${HeaderRow} {
        ${borderRowCss}
    }

    ${HeaderCell} {
        ${borderUnitCss}
    }
`

export const BodyCell = styled.td`
    padding: ${Spacing.Medium};
    text-align: center;
    vertical-align: top;
    min-width: 100px;
`

export const BodyRow = styled.tr`
    color: ${props => props.color};
    ${alternatingRowCss}
`
export const SummaryBodyRow = styled(BodyRow)``

export const TableBody = styled.tbody`
    ${borderWrapperCss}

    ${BodyRow} {
        ${borderRowCss}
    }

    ${BodyCell} {
        ${borderUnitCss}
    }

    ${SummaryBodyRow} ${BodyCell} {
        border-right: 0;
    }
`

export const TableBase = styled.table`
    width: 100%;
`

export const CompactTableRecord = styled.div`
    ${alternatingRowCss}
    padding: ${Spacing.Medium};
`

export const CompactTableBase = styled.div`
    ${borderWrapperCss}

    ${CompactTableRecord} {
        ${borderRowCss}
    }
`
