import * as React from 'react'
import { useForm } from 'react-hook-form'
import { MdLocationOn } from 'react-icons/md'
import { useSelector } from 'react-redux'

import { StepNav } from '@igs-web/common-components/domain/checkout/steps/step-nav'
import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { StateCode } from '@igs-web/common-models/constants/states'
import { EnrollmentModel } from '@igs-web/common-models/models'
import { Address } from '@igs-web/common-models/models/address'
import { FormWrapper } from '@igs-web/common-ui-components/_atoms/form-wrapper'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { Input } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { Grid1fr1fr } from '@igs-web/common-ui-components/_molecules/grid-layout'

export const AddressSearch = ({ enrollmentState, onSubmit }: AddressSearchProps) => {
    const offerRequest = useSelector((store: CommonReduxState) => OfferSelectors.selectOfferRequest(store))
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Address>()

    return (
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <IconHeading>
                <MdLocationOn /> Service Address
            </IconHeading>
            <Input
                {...register('address1', { required: 'Required', maxLength: 100 })}
                label="Service Address"
                defaultValue={enrollmentState.serviceAddress?.address1}
                dataTestId="premise-search-address-1"
                error={errors.address1?.message}
                hideHelperText={!errors.address1}
                maxLength={100}
            />

            <Input
                {...register('address2', { maxLength: 100 })}
                label="Service Address Line 2"
                defaultValue={enrollmentState.serviceAddress?.address2}
                dataTestId="service-address-search-address-2"
                hideHelperText={true}
                maxLength={100}
            />
            <Grid1fr1fr>
                <Input
                    {...register('city', { required: 'Required', maxLength: 100 })}
                    label="City"
                    defaultValue={enrollmentState.serviceAddress?.city}
                    dataTestId="premise-search-city"
                    error={errors.city?.message}
                    hideHelperText={!errors.city}
                    maxLength={100}
                />
                <Input
                    {...register('state')}
                    label="State"
                    defaultValue={StateCode.GA}
                    dataTestId="premise-search-state"
                    readOnly={true}
                    hideHelperText={true}
                />
            </Grid1fr1fr>
            <Input
                {...register('zipCode')}
                label="Zip Code"
                defaultValue={enrollmentState.serviceAddress?.zipCode ?? offerRequest.zipCode}
                dataTestId="premise-search-zipCode"
                maxLength={5}
                readOnly={true}
                hideHelperText={true}
            />
            <div>
                By enrolling, I certify that I own my home and the property it sits on, that I am not using my home for commercial use, and there are no
                pre-existing conditions affecting my protected lines.
            </div>
            <StepNav stepFwdButtonText="CONTINUE" />
        </FormWrapper>
    )
}

interface AddressSearchProps {
    readonly onSubmit: (address: Address) => void
    readonly enrollmentState: EnrollmentModel
}
