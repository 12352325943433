import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useInterval } from 'react-use'

import { useMyAccountDashboard } from '@igs-web/common-components/domain/myaccount/dashboard-context'
import { userActions } from '@igs-web/common-components/domain/user/user-redux'
import { UserProfileBillingAccount } from '@igs-web/common-models/models'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

const defaultDelayMs = 30000 //30 seconds
const initialDelay = 1000 //1 second
export const ProfileReloader = () => {
    const { profile, reloadProfileTries, setReloadProfileTries } = useMyAccountDashboard()
    const [delayMs, setDelayMs] = React.useState(initialDelay)
    const [loading, setIsLoading] = React.useState(false)
    const dispatch = useDispatch()

    //for now, this is the only reason we'd reload the userpofile, we might need more tests and an enum to tell which to run
    // or we can check for rate change too as a test to see if we got something new, the main thing we're trying to do here.
    const isBillingBalanceUnchanged = (account: UserProfileBillingAccount) => {
        const matchedAccount = profile.billingAccounts.find(x => x.billingAccountNumber === account.billingAccountNumber)
        if (!matchedAccount) {
            //well, we have a new profile, so return false
            return false
        }
        return matchedAccount.amountDue === account.amountDue //if they don't match, we got an update
    }
    //a delay of null pauses the interval indeterminately
    useInterval(
        async () => {
            setIsLoading(true)
            const updatedProfile = await customerInfoApiClient.getCurrentUserProfile({ showGlobalLoader: false, ignoreErrors: true })
            setIsLoading(false)
            setReloadProfileTries(reloadProfileTries - 1)
            setDelayMs(defaultDelayMs)
            if (updatedProfile) {
                if (!updatedProfile.billingAccounts.every(isBillingBalanceUnchanged)) {
                    dispatch(userActions.loadUserSuccess(updatedProfile))
                    setReloadProfileTries(0)
                }
            }
        },
        reloadProfileTries > 0 && !loading ? delayMs : null,
    )
    return <></>
}
