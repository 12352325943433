import { ReactNode } from 'react'

import { formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'
import { formatDate } from '@igs-web/common-utilities/utilities/date-utilities'

import { formatWithComma } from 'root/shared-services/number-utilites'

import { TableDataType } from './models/table-data-types'

export const determineSortType = (dataType: TableDataType) => {
    switch (dataType) {
        case TableDataType.Currency:
            return SortType.Alphanumeric
        case TableDataType.DateTime:
            return sortDate
        case TableDataType.Number:
            return SortType.Basic
        case TableDataType.String:
            return SortType.Alphanumeric
        default:
            return SortType.Alphanumeric
    }
}

const sortDate = (a, b, colId) => {
    const a1 = new Date(a.values[colId]).getTime()
    const b1 = new Date(b.values[colId]).getTime()
    if (a1 < b1) {
        return 1
    } else if (a1 > b1) {
        return -1
    } else {
        return 0
    }
}

export const determineFormatter = (dataType: TableDataType, value) => {
    if (!value) {
        return ''
    }
    switch (dataType) {
        case TableDataType.Currency:
            return formatAsMoney(value)
        case TableDataType.DateTime:
            return formatDate(value)
        case TableDataType.Number:
            return formatWithComma(value)
        case TableDataType.ReactNode:
            return value as ReactNode
        default:
            return String(value)
    }
}

export const determineFilterType = (canFilter, dataType, filterTypes) => {
    if (!canFilter) {
        return ''
    }
    switch (dataType) {
        case TableDataType.DateTime:
            return filterTypes.dateRange
        default:
            return filterTypes.text
    }
}

enum SortType {
    Basic = 'basic',
    DateTime = 'datetime',
    Alphanumeric = 'alphanumeric',
}
