import styled from 'styled-components'

import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeSmall, lightDark } from '@igs-web/common-ui-components/styles/theme'

export const LoginFormSubtitle = styled.div`
    color: var(--login-text-color, ${lightDark});
    margin-bottom: ${Spacing.Medium};
    font-size: ${fontSizeSmall};
`
