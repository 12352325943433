import * as React from 'react'
import { useDispatch } from 'react-redux'

import { modalActions } from '@igs-web/common-components/domain/ui-state/modal-redux'
import { useBreakpoints } from '@igs-web/common-components/hooks/use-breakpoints'
import { Button, ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { ConfirmModal } from '@igs-web/common-ui-components/_molecules/modals/confirm-modal'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'

import { EditableGridContextProvider, useEditableGridState } from './editable-grid-context'
import { GridHeader, GridWrapper, GridWrapperActionButtons, NoData } from './grid-styled-compoments'

// eslint-disable-next-line @typescript-eslint/ban-types
export const EditableGrid = <T extends object>({
    headers,
    data,
    DisplayRow,
    EditRow,
    accessor,
    noDataMessage,
    handleSave,
    handleDelete,
    handleAdd,
}: Props<T>) => {
    const [dataState, setDataState] = React.useState(data)
    const { lessThan } = useBreakpoints()
    const isMobile = lessThan(Breakpoint.Tablet)
    React.useEffect(() => {
        setDataState(data)
    }, [data])

    return (
        <>
            {!isMobile && (
                <GridWrapper $columnCount={headers.length + 1}>
                    {dataState.length !== 0 && (
                        <>
                            {headers.map(header => (
                                <GridHeader key={header}>{header}</GridHeader>
                            ))}
                            <div />
                        </>
                    )}
                    {dataState.length === 0 && <NoData>{noDataMessage}</NoData>}
                    {dataState.map((row, i) => (
                        <EditableGridContextProvider key={row[accessor] ?? `new-row-${i}`}>
                            <EditableRow<T>
                                // eslint-disable-next-line @typescript-eslint/dot-notation
                                isNewRow={row[accessor] < 0}
                                row={row}
                                DisplayRow={DisplayRow}
                                EditRow={EditRow}
                                handleDelete={handleDelete}
                                handleSave={handleSave}
                                accessor={accessor}
                            />
                        </EditableGridContextProvider>
                    ))}
                </GridWrapper>
            )}
            {isMobile &&
                dataState.map((row, i) => {
                    return (
                        <>
                            <GridWrapper key={row[accessor] ?? `new-row-${i}-mobile`} $columnCount={headers.length}>
                                {headers.map(header => (
                                    <GridHeader key={`row-${i}-${header}`}>{header}</GridHeader>
                                ))}
                                <EditableGridContextProvider>
                                    <EditableRow<T>
                                        // eslint-disable-next-line @typescript-eslint/dot-notation
                                        isNewRow={row[accessor] < 0}
                                        row={row}
                                        DisplayRow={DisplayRow}
                                        EditRow={EditRow}
                                        handleDelete={handleDelete}
                                        handleSave={handleSave}
                                        accessor={accessor}
                                    />
                                </EditableGridContextProvider>
                            </GridWrapper>
                            <hr />
                        </>
                    )
                })}
            <div className="grid-spacer grid-spacer-default" />
            <Button dataTestId={'add-editable-row'} onClick={() => handleAdd()}>
                Add
            </Button>
        </>
    )
}

interface Props<T> {
    readonly headers: ReadonlyArray<string>
    readonly data: ReadonlyArray<T>
    readonly EditRow: React.FunctionComponent<T>
    readonly DisplayRow: React.ComponentType<T>
    readonly accessor: string
    readonly noDataMessage: string
    readonly handleSave: (row: T) => void
    readonly handleDelete: (row: T) => void
    readonly handleAdd: () => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
const EditableRow = <T extends object>({ DisplayRow, EditRow, row, accessor, handleDelete, handleSave, isNewRow }: EditableRowProps<T>) => {
    const [isEditMode, setIsEditMode] = React.useState<boolean>(isNewRow)
    const [data, setData] = React.useState<T>(row)
    const { isValid } = useEditableGridState()
    const dispatch = useDispatch()
    const openConfirmModal = (modalId: string) => dispatch(modalActions.openModal(modalId))
    const handleCancel = () => {
        if (isNewRow) {
            handleDelete(data)
        } else {
            setIsEditMode(false)
            setData(row)
        }
    }

    React.useEffect(() => {
        setData(row)
    }, [row])

    const modalId = `delete-editable-row-${data[accessor]}`
    return (
        <>
            {!isEditMode && (
                <>
                    <DisplayRow {...data} />
                    <GridWrapperActionButtons>
                        <Button dataTestId={'edit-row-button'} onClick={() => setIsEditMode(true)}>
                            Edit
                        </Button>
                        <Button dataTestId={'delete-row-button'} buttonStyle={ButtonStyleType.Secondary} onClick={() => openConfirmModal(modalId)}>
                            Delete
                        </Button>
                        <ConfirmModal uiKey={modalId} onConfirm={() => handleDelete(data)}>
                            Are you sure you want to delete this row?
                        </ConfirmModal>
                    </GridWrapperActionButtons>
                </>
            )}
            {isEditMode && (
                <>
                    <EditRow {...data} setData={setData} />
                    <GridWrapperActionButtons>
                        <Button dataTestId={'edit-row'} onClick={handleCancel} buttonStyle={ButtonStyleType.Secondary}>
                            Cancel
                        </Button>
                        <Button
                            dataTestId={'update-row'}
                            onClick={() => {
                                handleSave(data)
                                setIsEditMode(false)
                            }}
                            disabled={!isValid}
                        >
                            Save
                        </Button>
                    </GridWrapperActionButtons>
                </>
            )}
        </>
    )
}

interface EditableRowProps<T> {
    readonly row: T
    readonly isNewRow: boolean
    readonly accessor: string
    readonly EditRow: React.ComponentType<T>
    readonly DisplayRow: React.ComponentType<T>
    readonly handleSave: (row: T) => void
    readonly handleDelete: (row: T) => void
}
