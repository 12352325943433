/* eslint-disable max-len */
import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

const ElectricIcon = ({ altText = 'Electric', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} id="Layer_1" viewBox="0 0 47.16 44.88" altText={altText}>
        <path d="M25.74,34.83V29.89H28v4.93a.63.63,0,0,1-.62.63h-1A.63.63,0,0,1,25.74,34.83Zm-4.58.62a.63.63,0,0,0,.62-.63V29.89H19.56v4.93a.63.63,0,0,0,.62.63ZM30,12.35a7.55,7.55,0,0,0-7.77,6.76h-1a3.33,3.33,0,0,0-3.33,3.33v4.23c-.81.16-1.31.39-1.31.65v1.79H31.15V27.32c0-.26-.51-.49-1.31-.65V22.44a3.33,3.33,0,0,0-3.33-3.33H25.7A4.13,4.13,0,0,1,30,15.76c5.58,0,6.76,5.54,6.76,10.18A12.87,12.87,0,0,1,11,26h0a17.6,17.6,0,0,1,.77-5.38c.92-.3,2-.67,3.23-1.09,0,0,11.61-4.35,10.82-16.9a5.64,5.64,0,0,1-3.92,1.4c-2.52-.34-6.87,1-9.62,3C8.36,9.84,5.84,14.16,7.52,18.56,10.54,8.86,22,5.07,22,5.07s-6.82,3.17-10.15,8.64c-.31.42-.62.86-.92,1.31A20.26,20.26,0,0,0,7.6,26h0a16.29,16.29,0,0,0,32.57,0C40.17,13.67,33.06,12.35,30,12.35Z" />
    </SvgIcon>
)

export default ElectricIcon
