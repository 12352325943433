import * as React from 'react'
import { FaExclamationCircle, FaPhone, FaRegCreditCard, FaUserAlt } from 'react-icons/fa'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { PaymentPortalForm } from '@igs-web/common-components/domain/payment/payment-portal-form'
import { ExternalLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { borderBoxColor, fontSizeMedium } from '@igs-web/common-ui-components/styles/theme'

import { WarningHeading, flexSpread } from '../shared/checkout-components'

const SpreadHeading = styled(WarningHeading)`
    ${flexSpread}
`
const SmallerHeading = styled(WarningHeading)`
    font-size: ${fontSizeMedium};
    border-bottom: 0;
    border-top: 1px solid ${borderBoxColor};
    padding-block-start: ${Spacing.Medium};
    @media (min-width: ${Breakpoint.Tablet}) {
        border-top: 0;
        padding-block-start: 0;
    }
`

const PaymentMethods = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    p {
        margin-block: 0;
    }
    @media (min-width: ${Breakpoint.Tablet}) {
        grid-template-columns: 1fr 1fr 1fr;
        p {
            max-width: 30ch;
        }
    }
`
//This only works w/out subgrid b/c we only have one variable size row,
// and all the othes are the same size as their neighbors. If it's different
// than that, you'll need to abandon PaymentMethod in favor of separating content by row
const PaymentMethod = styled.div`
    display: grid;
    grid-template-rows: min-content 1fr min-content min-content;
    align-items: center;
    gap: ${Spacing.Medium};
`
const BorderWrapper = styled.div`
    border: 1px solid ${borderBoxColor};
    padding: ${Spacing.Medium};
`
export const DepositInstructionsDisplay = ({ referenceNumber, depositAmount, paymentPhoneNumber, paymentUrl }: DisplayProps) => {
    return (
        <BorderWrapper>
            <SpreadHeading>
                <span>
                    <FaExclamationCircle /> Deposit
                </span>
                <span>Account Number/Deposit Reference Number: {referenceNumber}</span>
            </SpreadHeading>
            <p>
                You are required to pay a ${depositAmount} deposit to complete the sign-up process and will need to provide your Account Number/Deposit
                Reference Number during payment. All payment options below post immediately. Your deposit will be refunded to your account after six consecutive
                on-time payments.
            </p>
            <StrongText>Your Account Number/Deposit Reference Number: {referenceNumber}</StrongText>
            <p>
                Within two business days after paying the ${depositAmount} deposit, we will send you an email to either confirm the start date you requested, or
                to provide the next available start date if the date you requested cannot be accommodated. We will make every effort to honor your requested
                service start date.
            </p>
            <PaymentMethods>
                <PaymentMethod>
                    <SmallerHeading>
                        <FaRegCreditCard /> Pay Online
                    </SmallerHeading>
                    <PaymentPortalForm isLoggedIn={false} submitButtonText="Pay Deposit" />
                    <div>
                        <StrongText as="span">Fee:</StrongText> $3.50
                        <div>Credit | Debit | Electronic Check</div>
                    </div>
                </PaymentMethod>
                <PaymentMethod>
                    <SmallerHeading>
                        <FaPhone /> Pay By Phone
                    </SmallerHeading>
                    <div>
                        <StrongText as="div">
                            <PhoneNumberLink phoneNumber={paymentPhoneNumber} />
                        </StrongText>
                        <p>Select Pay Bill by Phone to make payments 24 hours a day.</p>
                    </div>
                    <div>
                        <StrongText as="span">Fee:</StrongText> $3.50
                        <div>Credit | Debit | Electronic Check</div>
                    </div>
                </PaymentMethod>
                <PaymentMethod>
                    <SmallerHeading>
                        <FaUserAlt /> Pay in Person
                    </SmallerHeading>
                    <StrongText>
                        <ExternalLink href={paymentUrl}>Find a Payment center</ExternalLink>
                    </StrongText>
                    <div>
                        <StrongText as="span">Fee:</StrongText> $2.25 (cash)
                        <div>Cash | Check | Money Order</div>
                    </div>
                </PaymentMethod>
            </PaymentMethods>
        </BorderWrapper>
    )
}

export const DepositInstructions = ({ referenceNumber, depositAmount }: Props) => {
    const company = useCompany()

    return (
        <DepositInstructionsDisplay
            referenceNumber={referenceNumber}
            depositAmount={depositAmount}
            paymentPhoneNumber={company.phoneNumber.payment || company.phoneNumber.customerSupport}
            paymentUrl={`${company.externalWebsite}/paying-my-bill/payment-centers`}
        />
    )
}

interface Props {
    readonly referenceNumber: string | null
    readonly depositAmount: number
}

interface DisplayProps extends Props {
    readonly paymentPhoneNumber: string
    readonly paymentUrl: string
}
