import * as React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components'

import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeSmall, primaryLight } from '@igs-web/common-ui-components/styles/theme'

const TabContainer = styled.div`
    && {
        grid-column: 1/-1;
    }
    width: ${Breakpoint.LargeDesktop};
    margin-inline: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    @media (max-width: ${Breakpoint.LargeDesktop}) {
        width: 100%;
    }
`

const TabLink = styled(Link)<TabLinkProps>`
    width: 250px;
    font-size: ${fontSizeSmall};
    font-weight: ${FontWeight.Bold};
    text-transform: uppercase;

    letter-spacing: 0.36px;
    text-align: center;

    border-radius: 10px 10px 0px 0px;

    color: black;
    text-decoration: none;

    ${({ $isActive }) =>
        $isActive
            ? `height: 50px;
               background: ${primaryLight};
            padding-block: ${Spacing.Medium};
    `
            : ` height: 40px;
                background: #ddd;
                padding-block: ${Spacing.Small};
            `}
`
interface TabLinkProps extends LinkProps {
    readonly $isActive: boolean
}
export interface LinkTab {
    readonly key: string
    readonly route: string
    readonly label: string
    readonly isActive: boolean
}
export const LinkTabs = ({ linkTabs }: Props) => {
    return (
        <TabContainer>
            {linkTabs.map(l => (
                <TabLink key={l.key} $isActive={l.isActive} to={l.route} data-testid={`tab-${l.key}`} >
                    {l.label}
                </TabLink>
            ))}
        </TabContainer>
    )
}

interface Props {
    readonly linkTabs: ReadonlyArray<LinkTab>
}
