import { BillingAccountInvoice } from '@igs-web/common-models/models/billing-account-invoice'
import { BillingAccountTransactionHistory } from '@igs-web/common-models/models/billing-account-transaction-history'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

import { useMyAccountDashboard } from './dashboard-context'

export const useLatestInvoice = (): LatestinvoiceHook => {
    const { getTransactionHistory } = useMyAccountDashboard()

    const getInvoice = async (transactionHistory: BillingAccountTransactionHistory): Promise<InvoiceReturn | null> => {
        const mostRecentInvoice = transactionHistory?.invoices.reduce((a, b) => getMostRecentInvoice(a, b))
        if (mostRecentInvoice) {
            const blob = await customerInfoApiClient.getInvoice(mostRecentInvoice.accountId, mostRecentInvoice.invoiceNumber, {
                showGlobalLoader: false,
            })
            return { blob, invoiceNumber: mostRecentInvoice.invoiceNumber }
        }
        return null
    }

    const getMostRecentInvoice = (a: BillingAccountInvoice, b: BillingAccountInvoice) => {
        if (a.invoiceDate === undefined) {
            return a
        }
        if (b.invoiceDate === undefined) {
            return b
        }
        return a.invoiceDate > b.invoiceDate ? a : b
    }

    const getLatestInvoice = async (billingAccountNumber: string): Promise<InvoiceReturn | null> => {
        const transactionHistory = await getTransactionHistory(billingAccountNumber)
        if (transactionHistory) {
            return await getInvoice(transactionHistory)
        }
        return null
    }

    return {
        getLatestInvoice,
    }
}

interface InvoiceReturn {
    readonly blob: Blob
    readonly invoiceNumber: string
}

interface LatestinvoiceHook {
    readonly getLatestInvoice: (billingAccountNumber: string) => Promise<InvoiceReturn | null>
}
