import * as React from 'react'

import _sortBy from 'lodash-es/sortBy'

import { AccountDocument } from '@igs-web/common-models/models/document'

import { useCompany } from '../company/hooks/useCompany'
import { DataTable } from '../data-table/data-table'
import { DefaultEmptyTable } from '../data-table/default-empty-table'
import { TableColumn } from '../data-table/models/table-column'
import { TableDataType } from '../data-table/models/table-data-types'

import { AccountDocumentLink } from './account-document-link'

interface AccountDocumentTableRecord extends AccountDocument {
    readonly documentLink: React.ReactNode
}

const mapToTableRecord = (document: AccountDocument): AccountDocumentTableRecord => ({
    ...document,
    documentLink: <AccountDocumentLink document={document} />,
})

const getSortedDocuments = (documents: ReadonlyArray<AccountDocument>): ReadonlyArray<AccountDocument> => {
    return _sortBy(documents, d => d.dateCreated).reverse()
}

const columns: ReadonlyArray<TableColumn> = [
    { header: 'Document Name', accessor: 'documentLink', dataType: TableDataType.ReactNode, disableSorting: true },
    { header: 'Date', accessor: 'dateCreated', dataType: TableDataType.DateTime, disableSorting: true },
]

export const AccountDocumentTable = ({ accountDocuments, tableDataType }: Props) => {
    const sortedDocuments = getSortedDocuments(accountDocuments)
    const tableRecords = sortedDocuments.map(mapToTableRecord)
    const { phoneNumber } = useCompany()

    return (
        <DataTable<AccountDocumentTableRecord>
            tableTitle={'Documents'}
            columns={columns}
            data={tableRecords}
            showWhenEmpty={<DefaultEmptyTable tableDataType={tableDataType} phoneNumber={phoneNumber.customerSupport} />}
            dataTestId="documents-history-table"
        />
    )
}

interface Props {
    readonly accountDocuments: ReadonlyArray<AccountDocument>
    readonly tableDataType?: string
}

export default AccountDocumentTable
