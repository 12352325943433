// Note: .scss files not importing correctly. Requires changes in build configuration
// import styles from './_spacing.scss'

export enum Spacing {
    // TODO: These values should be coming directly from the scss file. Sass loader needed for that
    None = '0',
    XxSmall = '2px',
    ExtraSmall = '4px',
    Small = '8px',
    Medium = '16px',
    Large = '24px',
    ExtraLarge = '32px',
    X5Large = '48px',
    XxLarge = '64px',
    SectionGap = '100px',
}
