import * as React from 'react'
import { useForm } from 'react-hook-form'
import { BsFillTagFill } from 'react-icons/bs'
import { FaRegAddressCard } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'

/* eslint-disable comma-dangle */
import {
    ConfirmationSelectors,
    SetConfirmationInfoRequest,
    confirmationActions,
} from '@igs-web/common-components/domain/enrollment/confirmation/confirmation-redux'

/* eslint-enable comma-dangle */
import { EnrollmentModelSelectors } from '@igs-web/common-components/domain/enrollment/enrollment-redux'
import { enrollmentModelActions } from '@igs-web/common-components/domain/enrollment/enrollment-redux-actions'
import { submitEnrollment } from '@igs-web/common-components/domain/enrollment/enrollment-submission-service'
import { MyAccountSelectors } from '@igs-web/common-components/domain/myaccount-redux'
import { useOffersContextDispatch, useOffersContextState } from '@igs-web/common-components/domain/offers/context/offers-context'
import { OffersInContextType } from '@igs-web/common-components/domain/offers/context/offers-context-models'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { EnrollmentModel, LineOfBusinessCode } from '@igs-web/common-models/models'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { EnrollmentTypeCode } from '@igs-web/common-models/models/enrollment-type-code'
import { OfferModel } from '@igs-web/common-models/models/offer-model'
import { Button, ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { Card } from '@igs-web/common-ui-components/_atoms/card'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { LinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'
import { Header3, StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { Layout1fr1frColumn } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { darkDark, darkLight, fontSizeLarge } from '@igs-web/common-ui-components/styles/theme'
import { canRenew } from '@igs-web/common-utilities/services/contract-eligibility-service'
import { getPriceWithoutUnitOfMeasureForProduct, getProductTitle } from '@igs-web/common-utilities/utilities/offer-utilities'
import { formatPhoneNumberForDisplay } from '@igs-web/common-utilities/utilities/phone-utilities'
import { generateUuid } from '@igs-web/common-utilities/utilities/uuid-utilities'

import { getDeviceId } from 'root/shared-services/device-id-service'

import { NavigationBanner } from 'molecules/header/navigation-banner'

import { AddressCardDisplay } from 'domain/checkout/your-plan/address-card'
import * as Routes from 'domain/routes'

import { GetCustomTerms } from './legal-disclaimers'

const StyledLinkButton = styled(LinkButton)`
    cursor: pointer;
    grid-area: back;
    padding-left: ${Spacing.Medium};
    padding-right: ${Spacing.Medium};
`

const NavButtons = styled.div`
    display: grid;
    grid-gap: ${Spacing.ExtraLarge};
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    grid-column: 2;
    margin-bottom: ${Spacing.Large};
    div:first-child {
        justify-self: end;
    }
    div:last-child {
        justify-self: start;
    }
`
const ReturnNavButton = styled.div`
    display: grid;
    grid-column: 2;
    margin-bottom: ${Spacing.Large};
    justify-self: end;
`

const StyledForm = styled.form`
    @media (max-width: ${Breakpoint.Tablet}) {
        margin: ${Spacing.Small};
    }
`

const ConfirmationTitle = styled.header`
    text-align: center;
    font-size: ${fontSizeLarge};
    h2 {
        font-weight: ${FontWeight.Medium};
        line-height: 1.2;
    }
    p {
        color: ${darkLight};
        font-weight: ${FontWeight.Bold};
    }
    ${StrongText} {
        color: ${darkDark};
    }
`

const RateCard = styled(Card)`
    grid-column: 1 / -1;
`
const RateConfirmationContainer = styled(Layout1fr1frColumn)`
    & > * {
        height: 100%;
        align-items: baseline;
    }
    grid-template-rows: minmax(min-content, 22ch);
    @media (max-width: ${Breakpoint.Tablet}) {
        display: flex;
        flex-direction: column;
    }
    ${IconHeading} {
        border: 0;
    }
`
const MultiLineParagraph = styled.p`
    line-height: 1.5;
`

const RATE_CHANGE_LOB = LineOfBusinessCode.Gas

export const ConfirmRateChange = () => {
    const { handleSubmit } = useForm<ConfirmRateFields>()

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const dispatch = useDispatch()
    const history = useHistory()

    const company = useCompany()

    const userProfile = useSelector(UserSelectors.selectProfile)

    const selectedServiceAddress = useSelector(MyAccountSelectors.selectSelectedServiceAddress)
    const accounts = useSelector((state: CommonReduxState) => UserSelectors.selectPartyAccountsByAddressKey(state, selectedServiceAddress?.serviceAddressKey))

    const activeAccounts = accounts.filter(a => a.status === AccountStatus.active && a.lineOfBusinessCode !== LineOfBusinessCode.HomeWarranty)
    const selectedAccount = activeAccounts?.find(aa => aa.lineOfBusinessCode === RATE_CHANGE_LOB)

    const enrollmentState = useSelector(EnrollmentModelSelectors.selectEnrollmentModel)
    const updateEnrollmentModel = (enrollmentModel: Partial<EnrollmentModel>) => dispatch(enrollmentModelActions.updateEnrollmentModel(enrollmentModel))

    const { offersRequest, selectedOffer } = useOffersContextState()
    const { deselectOffer } = useOffersContextDispatch()

    const setConfirmationInfo = (request: SetConfirmationInfoRequest) => dispatch(confirmationActions.setConfirmationInfo(request))
    const clearConfirmationInfo = () => dispatch(confirmationActions.clearConfirmationInfo())

    const [changeOrRenewConfirmed, setChangeOrRenewConfirmed] = React.useState(false)
    const confirmation = useSelector((store: CommonReduxState) => ConfirmationSelectors.selectConfirmationInfo(store))

    if (!selectedOffer || !selectedAccount) {
        history.push(Routes.RateChange)
        return <></>
    }

    const primaryProduct = selectedOffer.primaryProduct

    const goToConfirmation = () => {
        setChangeOrRenewConfirmed(true)
        window.scrollTo(0, 0)
    }

    const goToAccountSummary = () => {
        window.scrollTo(0, 0)
        history.push(Routes.AccountSummary)
    }

    const onSubmit = () => {
        const enrollmentModel: EnrollmentModel = {
            ...enrollmentState,
            shoppingCartItems: [
                {
                    accountId: selectedAccount.accountId,
                    accountNumber: selectedAccount.accountNumber,
                    partyId: selectedAccount.partyId,
                    meterNumber: selectedAccount.meterNumber,
                    priceEffectiveDate: selectedOffer.priceEffectiveDate,
                    shoppingCartItemKey: generateUuid(),
                    offerId: selectedOffer.offerId,
                    lineOfBusinessCode: primaryProduct.lineOfBusinessCode,
                    contact: enrollmentState.defaultContact,
                    useDefaultContact: true,
                    deferEnrollmentUnitilEndOfCurrentContractTerm: canRenew(selectedAccount, company.features.canRenewAnyStatus),
                },
            ],
            deviceId: getDeviceId(),
            enrollmentTypeCode: EnrollmentTypeCode.Standard,
            isPaperless: userProfile ? userProfile.isPaperless : enrollmentState.isPaperless,
        }

        const selectedOffers = Array<OfferModel>(selectedOffer)

        submitEnrollment(enrollmentModel, selectedOffers, updateEnrollmentModel, setConfirmationInfo, clearConfirmationInfo, goToConfirmation)
    }

    return (
        <>
            <NavigationBanner title="Confirm Rate Choice" />
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                {changeOrRenewConfirmed && (
                    <ConfirmationTitle>
                        <h2>Thank You</h2>
                        <MultiLineParagraph>
                            {offersRequest?.offersInContextType === OffersInContextType.Renewal
                                ? 'Your new rate will take affect at the end of your current contract and you will not be assessed an early termination fee.'
                                : 'Your rate has been changed. Please allow up to 7 days to see the rate reflected on your bill.'}
                        </MultiLineParagraph>
                        <p>
                            Your confirmation number:{' '}
                            <StrongText as="span" data-testid="rate-change-confirmation-number">
                                {confirmation.offers[0].confirmationNumber}
                            </StrongText>
                        </p>
                    </ConfirmationTitle>
                )}
                <Header3>Details</Header3>
                <RateConfirmationContainer>
                    <Card
                        header={
                            <IconHeading>
                                <FaRegAddressCard /> Contact Information
                            </IconHeading>
                        }
                        dataTestId="contact-information-card"
                    >
                        <span data-testid="contact-information-card-customer-name">
                            {selectedAccount.businessName
                                ? selectedAccount.businessName
                                : selectedAccount.customerFirstName + ' ' + selectedAccount.customerLastName}
                        </span>
                        <br />

                        <span data-testid="contact-information-card-customer-phone">{formatPhoneNumberForDisplay(selectedAccount.customerPhoneNumber)}</span>
                        <br />

                        <span data-testid="contact-information-card-customer-email-address">{selectedAccount.customerEmailAddress}</span>
                    </Card>
                    {selectedServiceAddress && <AddressCardDisplay serviceAddress={selectedServiceAddress} dataTestId="address-card" />}
                    <RateCard
                        header={
                            <IconHeading>
                                <BsFillTagFill /> Rate
                            </IconHeading>
                        }
                        dataTestId="rate-card"
                    >
                        <div>
                            <strong data-testid="rate-card-product-title">{getProductTitle(primaryProduct, company)}</strong>
                            <br />
                            <span data-testid="rate-card-product-price-with-uom">
                                <span data-testid="rate-card-product-price">{getPriceWithoutUnitOfMeasureForProduct(primaryProduct, true)}</span> per{' '}
                                {primaryProduct.unitOfMeasure}
                            </span>
                        </div>
                        <br />
                        <div>
                            <strong>Fees and Other Info</strong>
                            <GetCustomTerms offer={primaryProduct} />
                        </div>
                    </RateCard>
                    {!changeOrRenewConfirmed && (
                        <NavButtons>
                            <div>
                                <StyledLinkButton
                                    buttonStyle={ButtonStyleType.Secondary}
                                    dataTestId="step-back"
                                    onClick={() => {
                                        deselectOffer()
                                    }}
                                >
                                    Back
                                </StyledLinkButton>
                            </div>
                            <div>
                                <Button buttonStyle={ButtonStyleType.Primary} dataTestId="confirm-rate-change" type="submit">
                                    Confirm
                                </Button>
                            </div>
                        </NavButtons>
                    )}
                    {changeOrRenewConfirmed && (
                        <ReturnNavButton>
                            <Button dataTestId="return-to-profile-and-settings" buttonStyle={ButtonStyleType.Primary} onClick={() => goToAccountSummary()}>
                                Return to Account Summary
                            </Button>
                        </ReturnNavButton>
                    )}
                </RateConfirmationContainer>
            </StyledForm>
        </>
    )
}

interface ConfirmRateFields {
    readonly enrollAutoRenewal: boolean
}
