import * as React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import styled from 'styled-components'

import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { LineOfBusinessCode, OfferModel, UserProfile, UserProfileServiceAddress } from '@igs-web/common-models/models'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { ActionLink } from '@igs-web/common-ui-components/_atoms/link/action-link'
import { commonGridCss } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { bodyColor } from '@igs-web/common-ui-components/styles/theme'
import { getSortedAddresses } from '@igs-web/common-utilities/utilities/address-utilities'

import { HwAddressSelectorDisplay } from 'domain/home-warranty/hw-service-address-selector'
import * as Routes from 'domain/routes'

import { HWZipCodeSearch } from './hw-zip-code-search'
import { ProductSelectionController } from './product-selection-controller'

const HwProductSelectionWrapper = styled.div`
    --header-color: ${bodyColor};
    ${commonGridCss}
    grid-template-columns: 1fr 1fr;
    ul {
        grid-column: span 2;
    }
    @media (max-width: ${Breakpoint.Tablet}) {
        grid-column-gap: unset;
        padding-inline: ${Spacing.Medium};
        ul {
            grid-column: span 1;
        }
    }
`
const SwitchText = styled.div`
    grid-column: 1 / -1;
    text-align: center;
`

const HwAddressSelectorWrapper = styled.div<HwAddressSelectorWrapperProps>`
    --header-color: ${bodyColor};
    ${commonGridCss}
    grid-column-gap: unset;
    grid-column: span 2;
    grid-template-columns: ${({ serviceAddressCount }) => (serviceAddressCount === 1 ? '1fr 3fr' : '4fr 0fr')};
    align-items: center;
`

interface HwAddressSelectorWrapperProps {
    readonly serviceAddressCount: number
}

const getValidServiceAddresses = (profile: UserProfile): ReadonlyArray<UserProfileServiceAddress> => {
    const activeServiceAddressKeys = profile.accounts
        .filter(a => a.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty && a.status === AccountStatus.active)
        .map(a => a.serviceAddressKey)

    const availableServiceAddresses = profile.serviceAddresses.filter(
        sa => sa.homeWarrantyAvailable && !activeServiceAddressKeys.includes(sa.serviceAddressKey),
    )

    return getSortedAddresses(availableServiceAddresses)
}

const AddressDisplay = ({ setAddress }: AddressDisplayProps) => {
    const userProfile = useSelector(UserSelectors.selectProfile)

    const serviceAddresses = userProfile ? getValidServiceAddresses(userProfile) : []

    const [activeServiceAddress, setActiveServiceAddress] = React.useState<UserProfileServiceAddress | null>(serviceAddresses ? serviceAddresses[0] : null)

    useEffectOnce(() => {
        //first time through set the zip code for the selected address
        if (activeServiceAddress) {
            setAddress(activeServiceAddress)
        }
    })
    const onSetActiveServiceAddress = (address: UserProfileServiceAddress) => {
        setActiveServiceAddress(address)
        setAddress(address)
    }
    return (
        <HwAddressSelectorWrapper serviceAddressCount={serviceAddresses.length}>
            {serviceAddresses.length === 1 && <div>Viewing available plans for:</div>}
            <HwAddressSelectorDisplay
                serviceAddresses={serviceAddresses}
                selectedServiceAddressKey={activeServiceAddress?.serviceAddressKey}
                onSelect={onSetActiveServiceAddress}
                hideTitleOnSingle={true}
                dropdownHeaderText="Select an address to view available plans:"
            />
        </HwAddressSelectorWrapper>
    )
}

interface AddressDisplayProps {
    readonly setAddress: (address: UserProfileServiceAddress) => void
}

enum DisplayPanel {
    Address = 'address',
    ZipCode = 'zipcode',
}

export const HWProductSelection = () => {
    const [zipCode, setZipCode] = React.useState<string | undefined>()
    const [activeServiceAddress, setActiveServiceAddress] = React.useState<UserProfileServiceAddress | null>(null)
    const [displayPanel, setDisplayPanel] = React.useState(DisplayPanel.Address)
    const history = useHistory()
    const changeDisplayPanel = (panel: DisplayPanel) => {
        setZipCode(undefined)
        setDisplayPanel(panel)
    }

    const onSelectOffer = (offer: OfferModel) => {
        const baseUrl = `${Routes.HomeWarrantCheckout}?offerid=${offer.offerId}&campaignCode=web-new&zipCode=${zipCode}`
        if (displayPanel === DisplayPanel.ZipCode || !activeServiceAddress) {
            history.push(baseUrl)
        } else {
            history.push(`${baseUrl}&addressKey=${activeServiceAddress.serviceAddressKey}`)
        }
    }

    const setAddress = (address: UserProfileServiceAddress) => {
        setZipCode(address.zipCode)
        setActiveServiceAddress(address)
    }
    return (
        <HwProductSelectionWrapper>
            {displayPanel === DisplayPanel.Address && (
                <>
                    <AddressDisplay setAddress={setAddress} />
                    <SwitchText>
                        Looking for plans at a different address?{' '}
                        <ActionLink dataTestId="hw-prod-selection-switch-to-zip" onClick={() => changeDisplayPanel(DisplayPanel.ZipCode)}>
                            Search by ZIP code.
                        </ActionLink>
                    </SwitchText>
                </>
            )}
            {displayPanel === DisplayPanel.ZipCode && (
                <>
                    <HWZipCodeSearch setZipCode={setZipCode} />
                    <SwitchText>
                        Looking for plans at an existing address?{' '}
                        <ActionLink dataTestId="hw-prod-selection-switch-to-address" onClick={() => changeDisplayPanel(DisplayPanel.Address)}>
                            Select an address
                        </ActionLink>
                    </SwitchText>
                </>
            )}
            <ProductSelectionController zipCode={zipCode} onSelectOffer={onSelectOffer} />
        </HwProductSelectionWrapper>
    )
}
