import * as React from 'react'

import { InputField } from '@igs-web/common-ui-components/_atoms/form-inputs/input-field'
import { TypedFieldProps } from '@igs-web/common-ui-components/_atoms/form-inputs/typed-field'

export const PasswordField = <T extends object>(props: TypedFieldProps<T> & React.InputHTMLAttributes<HTMLInputElement>): React.ReactNode => {
    const [showText, setShowText] = React.useState(false)
    const toggleShowText = (): void => {
        setShowText(!showText)
    }

    return (
        <InputField<T>
            type={showText ? 'text' : 'password'}
            action={{
                label: showText ? 'hide' : 'show',
                effect: toggleShowText,
            }}
            doNotRecord={true}
            {...props}
        />
    )
}
