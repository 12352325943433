import * as React from 'react'
import styled from 'styled-components'

import { primaryBase } from '@igs-web/common-ui-components/styles/theme'

import { ActionLink } from '../link/action-link'
import { HideText } from '../typography'

//some constants
const hamburgerWidth = 30
const hamburgerHeight = 26
const hamburgerColor = primaryBase
const hamburgerSliceHeight = 6
const hamburgerStartX = 12
const hamburgerStartY = 12
const hamburgerOffset = 3

const HamburgerLine = styled.span`
    transition: all 0.5s ease-in-out;
    display: block;
    position: absolute;
    left: ${hamburgerStartX}px;
    width: ${hamburgerWidth}px;
    height: ${hamburgerSliceHeight}px;
    background-color: ${hamburgerColor};

    &:nth-child(1) {
        top: ${hamburgerStartY}px;
    }

    &:nth-child(2) {
        top: ${hamburgerStartY + hamburgerOffset + hamburgerSliceHeight}px;
        opacity: 1;
    }

    &:nth-child(3) {
        top: ${hamburgerStartY + (hamburgerOffset + hamburgerSliceHeight) * 2}px;
    }
`

const StyledLink = styled(ActionLink)`
    transition: background-color 0.25s ease-in-out;
    background: transparent;
    display: block;
    position: relative;
    width: 60px;
    height: 53px;
    background-color: white;
    border: 0;
    &[aria-expanded='true'] {
        background-color: ${hamburgerColor};

        ${HamburgerLine} {
            background-color: white;

            &:nth-child(1) {
                transform: rotate(135deg);
                top: ${hamburgerHeight}px;
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: rotate(-135deg);
                top: ${hamburgerHeight}px;
            }
        }
    }
`

export const HamburgerButton = ({ isOpen, toggleOpen }: HamburgerButtonProps) => (
    <StyledLink id="mobile-menu-button" aria-expanded={isOpen} aria-controls="collapse--nav" onClick={toggleOpen}>
        <HamburgerLine />
        <HamburgerLine />
        <HamburgerLine />
        <HideText>Menu</HideText>
    </StyledLink>
)

interface HamburgerButtonProps {
    readonly isOpen: boolean
    readonly toggleOpen: () => void
}
