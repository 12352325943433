import * as React from 'react'

/*
based on UseHooks implemenation. https://usehooks.com/useOnClickOutside/
*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useOnClickOutside = (ref: React.MutableRefObject<any>, action: () => void) => {
    React.useEffect(() => {
        const listener = (event: MouseEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return
            }

            action()
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, action])
}
