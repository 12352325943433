import { defaultCampaign } from '@igs-web/common-utilities/constants/constants'
import { Url, buildUrl } from '@igs-web/common-utilities/utilities/url-utilities'

export const homeUrl = (): Url => buildUrl('/')
export const myAccountDashboardUrl = (): Url => buildUrl('/MyAccount/Dashboard')
export const createMyAccountUrl = (): Url => buildUrl('/create-account')
export const termsOfUseUrl = (): Url => buildUrl('/terms-of-use')
export const howItWorksUrl = (): Url => buildUrl('/green')
export const privacyPolicyUrl = (): Url => buildUrl('/privacy-policy')
export const californiaPrivacyNoticeUrl = (): Url => buildUrl('/california-privacy-notice')

export const productSelectionUrlResidential = (campaignCode?: string): Url =>
    buildUrl(`/product-selection${campaignCode && campaignCode !== defaultCampaign ? '?userCode=' + campaignCode : ''}`)
export const productSelectionUrlSmallComm = (campaignCode?: string): Url =>
    buildUrl(`/product-selection/small-commercial${campaignCode && campaignCode !== defaultCampaign ? '?userCode=' + campaignCode : ''}`)
export const signupUrl = (): Url => buildUrl('/signup')
export const signupUrlSmallComm = (): Url => buildUrl('/signup/small-commercial')
export const productSelectionUrl = (campaignCode?: string, isCommercial = false): Url =>
    isCommercial ? productSelectionUrlSmallComm(campaignCode) : productSelectionUrlResidential(campaignCode)
export const onlineWebVerificationUrl = (token?: string): Url => buildUrl(`/online-web-verification?token=${token}`)
export const owvUrl = (token?: string): Url => buildUrl(`/owv?token=${token}`)
export const outboundWebVerificationUrl = (token?: string): Url => buildUrl(`/outboundwebverification?token=${token}`)

//these are defined in sitefinity, so if a different one is used, the logic that depends on thse will break
export const confirmationUrl = (): Url => buildUrl('/confirmation')
export const confirmationUrlSmallComm = (): Url => buildUrl('/small-commercial/ confirmation')
