import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import _sortBy from 'lodash-es/sortBy'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { HomeProtectionAccountClaim } from '@igs-web/common-models/models/account-detail-model'
import { LineOfBusinessCode, LineOfBusinessConstant } from '@igs-web/common-models/models/line-of-business'
import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'

import { DataTable } from '../data-table/data-table'
import { DefaultEmptyTable } from '../data-table/default-empty-table'
import { TableColumn } from '../data-table/models/table-column'
import { TableDataType } from '../data-table/models/table-data-types'

import { ClaimsSelectors, claimsActions } from './claims-redux'

const columns: ReadonlyArray<TableColumn> = [
    { header: 'Line', accessor: 'line', dataType: TableDataType.String, disableSorting: true },
    { header: 'Request Opened', accessor: 'dateOpened', dataType: TableDataType.DateTime, disableSorting: true },
    { header: 'Repair Cost', accessor: 'coveredCosts', dataType: TableDataType.Currency, disableSorting: true },
    { header: 'Protection Limit', accessor: 'coverageLimit', dataType: TableDataType.Currency, disableSorting: true },
    { header: 'Contractor', accessor: 'contractor', dataType: TableDataType.String, disableSorting: true },
]

export const ClaimsDetails = ({ accountId }: Props) => {
    const dispatch = useDispatch()

    const claims = useSelector((store: CommonReduxState) => ClaimsSelectors.selectClaims(store, accountId))
    const claimsLoading = useSelector((store: CommonReduxState) => ClaimsSelectors.selectIsLoading(store))

    React.useEffect(() => {
        dispatch(claimsActions.loadAccountClaims({ accountId, showGlobalLoader: false }))
    }, [accountId, dispatch])

    const lob = LineOfBusinessConstant.getByCode(LineOfBusinessCode.HomeWarranty)
    const sortedClaims = _sortBy(claims, c => c.dateOpened).reverse()

    return (
        <div className="usage-details">
            <LocalLoader isLoading={claimsLoading} />
            {!claimsLoading && (
                <DataTable<HomeProtectionAccountClaim>
                    columns={columns}
                    data={sortedClaims}
                    tableTitle={`Historical ${lob.title} Service Requests`}
                    showWhenEmpty={<DefaultEmptyTable emptyTableMessage="No Service Requests found for this account" />}
                    dataTestId="service-requests-history-table"
                />
            )}
        </div>
    )
}

interface Props {
    readonly accountId: number
}
