import { Address } from '@igs-web/common-models/models/address'
import { ContactModel } from '@igs-web/common-models/models/contact-model'
import { EnrollmentModel, ShoppingCartItem } from '@igs-web/common-models/models/enrollment-model'
import { CHECKOUT_STORAGE_KEY } from '@igs-web/common-utilities/constants/constants'
import { createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'
import { putInStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

import { purgePayments } from '../business/enrollment-service'
import { initialEnrollmentModel } from '../checkout-models'

interface ContactInfo {
    readonly defaultContact: ContactModel
    readonly isPaperless: boolean
}

const checkoutActionTypes = {
    SET_ENROLLMENT: '[checkout] SET_ENROLLMENT',
    SET_CONTACT_INFO: '[checkout] SET_DEFAULT_CONTACT',
    SET_SERVICE_ADDRESS: '[checkout] SET_SERVICE_ADDRESS',
    SET_PRODUCT_DETAILS: '[checkout] SET_PRODUCT_DETAILS',
}

export interface CheckoutState {
    readonly enrollmentState: EnrollmentModel
    readonly storageKey: string
}

export const checkoutActions = {
    setEnrollment: createAction<EnrollmentModel>(checkoutActionTypes.SET_ENROLLMENT),
    setContactInfo: createAction<ContactInfo>(checkoutActionTypes.SET_CONTACT_INFO),
    setServiceAddress: createAction<Address>(checkoutActionTypes.SET_SERVICE_ADDRESS),
    setProductDetail: createAction<ShoppingCartItem>(checkoutActionTypes.SET_PRODUCT_DETAILS),
}

export const checkoutInitialState: CheckoutState = {
    enrollmentState: initialEnrollmentModel,
    storageKey: CHECKOUT_STORAGE_KEY,
}

const setEnrollment = (state: CheckoutState, enrollmentState) => {
    putInStorage(purgePayments(enrollmentState), state.storageKey, false)
    return { ...state, enrollmentState }
}

export const checkoutReducer = reducer<CheckoutState>(checkoutInitialState)
    .add<EnrollmentModel>(checkoutActionTypes.SET_ENROLLMENT, (state, enrollmentState): CheckoutState => {
        return setEnrollment(state, enrollmentState)
    })
    .add<ContactInfo>(checkoutActionTypes.SET_CONTACT_INFO, (state, { defaultContact, isPaperless }): CheckoutState => {
        const enrollmentState = {
            ...state.enrollmentState,
            defaultContact,
            isPaperless,
        }
        return setEnrollment(state, enrollmentState)
    })
    .add<Address>(checkoutActionTypes.SET_SERVICE_ADDRESS, (state, serviceAddress): CheckoutState => {
        const enrollmentState = {
            ...state.enrollmentState,
            serviceAddress,
        }
        return setEnrollment(state, enrollmentState)
    })
    .add<ShoppingCartItem>(checkoutActionTypes.SET_PRODUCT_DETAILS, (state, details): CheckoutState => {
        const enrollmentState = {
            ...state.enrollmentState,
            productDetails: {
                ...state.enrollmentState.productDetails,
                [details.lineOfBusinessCode]: details,
            },
        }
        return setEnrollment(state, enrollmentState)
    })
    .build()
