import { TableColumn } from '@igs-web/common-components/domain/data-table/models/table-column'
import { TableDataType } from '@igs-web/common-components/domain/data-table/models/table-data-types'

const csvColumnOptions: Partial<TableColumn> = { disableSorting: true, hideColumn: true, hideOnCsv: false }

const hwColumnDefs: ReadonlyArray<TableColumn> = [
    { ...csvColumnOptions, header: 'Protection Plan', accessor: 'protectionPlan', dataType: TableDataType.String },
    { ...csvColumnOptions, header: 'Account ID', accessor: 'accountId', dataType: TableDataType.String },
]

const commodityColumnDefs = (hideUtility: boolean) => [
    { ...csvColumnOptions, header: 'Utility Name', accessor: 'utilityName', dataType: TableDataType.String, hideOnCsv: hideUtility },
    { ...csvColumnOptions, header: 'Utility Account #', accessor: 'accountNumber', dataType: TableDataType.String, hideOnCsv: hideUtility },
]

const customerColumnDefs: ReadonlyArray<TableColumn> = [
    { ...csvColumnOptions, header: 'Customer Name', accessor: 'customerName', dataType: TableDataType.String },
    { ...csvColumnOptions, header: 'Service Address', accessor: 'serviceAddressFormatted', dataType: TableDataType.String },
]

export const hwAccountDetailColumnDefs: ReadonlyArray<TableColumn> = [...hwColumnDefs, ...customerColumnDefs]

export const commodityAccountDetailColumnDefs = (hideUtility: boolean) => {
    return [...commodityColumnDefs(hideUtility), ...customerColumnDefs]
}
