import * as React from 'react'

import { convertToClassNames } from '@igs-web/common-utilities/utilities/css-class-utilities'

export interface FieldWrapperProps {
    readonly wrapperClass?: string
    readonly customError?: string | JSX.Element
    readonly label?: string | JSX.Element
    readonly id: string
    readonly error?: string
    readonly touched: boolean | undefined
    readonly children?: React.ReactNode
    readonly hideError?: boolean
    readonly doNotRecord?: boolean
    readonly button?: React.ReactNode
}

const getClassName = ({ button, error, touched, hideError, doNotRecord, wrapperClass }: FieldWrapperProps): string => {
    return convertToClassNames(
        {
            'input-group': button,
            'has-error': error,
            touched,
            'hide-error': hideError,
            'fs-block': doNotRecord,
        },
        'form-label-group',
        'form-group',
        wrapperClass || '',
    )
}

export const FieldWrapper = (props: FieldWrapperProps): JSX.Element => {
    const { id, button, children, label, error, customError, hideError } = props
    return (
        <div className={getClassName(props)}>
            {children}
            <label htmlFor={id}>{label}</label>
            {!!button && <div className="input-group-append">{button}</div>}
            {!hideError && (
                <div id={`${id}-error`} className="invalid-feedback" aria-live="assertive">
                    {customError || error}
                </div>
            )}
        </div>
    )
}
