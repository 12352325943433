import { EventCodes } from '../constants/constants'
import { GlobalEventer } from './eventer-utilities'

declare global {
    interface Window {
        // eslint-disable-next-line
        igs_loadingManager: any
    }
}
class LoadingManager {
    // eslint-disable-next-line functional/prefer-readonly-type
    public loaders = 0
    
    public readonly addLoader = () => {
        this.loaders++
        
        if (this.loaders > 0) {
            GlobalEventer.publish(EventCodes.loadingStart, null)
        }
    }
    
    public readonly removeLoader = () => {
        this.loaders--
        
        if (this.loaders < 1) {
            GlobalEventer.publish(EventCodes.loadingEnd, null)
        }
    }
    
    public readonly addLoaderForDuration = (duration: number) => {
        this.addLoader()
        setTimeout(this.removeLoader, duration)
    }
}

window.igs_loadingManager = window.igs_loadingManager || new LoadingManager()

// eslint-disable-next-line
export const loadingManager: any = window.igs_loadingManager
