import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import * as React from 'react'
import styled from 'styled-components'

const StyledButtonSection = styled.div<InnerProps>`
    margin-top: ${Spacing.ExtraSmall};
    margin-left: -${Spacing.ExtraSmall};
    margin-right: -${Spacing.ExtraSmall};

    > * {
        margin: ${Spacing.ExtraSmall};
    }

    text-align: ${props => props.$alignment};

    @media (max-width: ${Breakpoint.Mobile}) {
        text-align: ${props => (props.$centerOnMobile ? 'center' : '')};
    }
`

interface InnerProps {
    readonly $alignment: string
    readonly $centerOnMobile: boolean
}

export const ButtonSection = ({ alignment = 'left', centerOnMobile = false, ...htmlProps }: ButtonSectionProps): JSX.Element => (
    <StyledButtonSection $alignment={alignment} $centerOnMobile={centerOnMobile} {...htmlProps} />
)

export interface ButtonSectionProps extends React.HTMLAttributes<HTMLDivElement> {
    readonly alignment?: 'left' | 'right' | 'center'
    readonly centerOnMobile?: boolean
}
