import { HistoricRate } from '@igs-web/common-models/models/historic-rates'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { CachedReduxDataStateType, setupCachedReduxData } from '../cached-redux/cached-redux-data'

const load = async (accountId: number, config: RequestConfig) => {
    try {
        const response = (await customerInfoApiClient.getHistoricRatesByAccountId(accountId, config)) || undefined
        return (response || []).map<HistoricRate>(u => ({
            ...u,
            fromDate: Date.parse(u.fromDate),
            toDate: Date.parse(u.toDate),
        }))
    } catch (e) {
        console.error(`There was a problem getting historic rates for account: ${accountId}`)
    }
}

const { actions, selectors, saga, reducers } = setupCachedReduxData('historicRates', load)

export const historicRatesActions = actions
export const historicRatesSelectors = selectors
export const historicRatesSaga = saga
export const historicRatesReducer = reducers
export type HistoricRatesState = CachedReduxDataStateType<typeof load>
