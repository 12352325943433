import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import styled from 'styled-components'

import { GetErrorLoginErrorMessage } from '@igs-web/common-components/domain/authentication/login-error-message-service'
import { LoginForm } from '@igs-web/common-components/domain/authentication/login-form'
import { budgetBillingRedux } from '@igs-web/common-components/domain/budget-billing/budget-billing-redux'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { UserSelectors, userActions } from '@igs-web/common-components/domain/user/user-redux'
import { UserProfile } from '@igs-web/common-models/models'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText, InfoTextType } from '@igs-web/common-ui-components/_molecules/info-text'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { apiClient } from '@igs-web/common-utilities/api/api-client'

import { LayoutSection, LayoutSidebar, LayoutWithSidebar, LinkWithArrow } from 'molecules/layout/layout-styles'

import * as Routes from 'domain/routes'

const ForgotPasswordContainer = styled.div`
    text-align: center;
`

export const LoginPanel = ({ to, email }: LoginPanelProps) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const company = useCompany()
    const { phoneNumber } = company
    const [error, setError] = React.useState<React.ReactNode>(null)
    const logoutMessage = useSelector(UserSelectors.selectLogoutMessage)
    const userProfileError = useSelector(UserSelectors.selectUserProfileLoadError)

    React.useEffect(() => {
        if (logoutMessage) {
            setError(logoutMessage)
            dispatch(userActions.setLogoutMessage(undefined))
        }
    }, [])
    React.useEffect(() => {
        if (userProfileError) {
            setError(userProfileError)
            dispatch(userActions.loadUserFail(undefined))
        }
    }, [userProfileError])

    const onLoginComplete = (user?: UserProfile) => {
        if (user) {
            const billingAccountNumbers = user.billingAccounts.map(x => x.billingAccountNumber)
            dispatch(
                budgetBillingRedux.actions.loadMultiple({
                    keys: billingAccountNumbers,
                    showGlobalLoader: false,
                }),
            )
        }

        if (!user?.hasGasAccount && user?.hasScanaHW) {
            history.push(to ? to : Routes.HwAccountSummary)
        } else {
            history.push(to ? to : Routes.AccountSummary)
        }
    }

    const handleSubmit = async ({ username, password }) => {
        apiClient
            .login(username, password, { overrideNonUserPresentableErrors: 'Login Unsuccessful', showGlobalLoader: true })
            .then(() => {
                dispatch(userActions.logUserInSuccess({ onLoginComplete }))
            })
            .catch(err => {
                setError(GetErrorLoginErrorMessage(err.message, company))
            })
    }

    return (
        <LayoutWithSidebar>
            <LayoutSection>
                {error && (
                    <>
                        <InfoText $infoTextType={InfoTextType.Error}>{error}</InfoText>
                        <GridSpacer spacing={Spacing.Medium} />
                    </>
                )}
                <LoginForm uniqueId="login-page-login-form" onSubmit={handleSubmit} email={email} useFormErrorMessaging={false} />
                <ForgotPasswordContainer>
                    Forgot{' '}
                    <Link data-testid="forgot-email-link" to={Routes.ForgotPassword}>
                        Email address
                    </Link>{' '}
                    or{' '}
                    <Link data-testid="forgot-password-link" to={Routes.ForgotPassword}>
                        Password
                    </Link>
                    ?
                </ForgotPasswordContainer>
                <GridSpacer spacing={Spacing.Large} />
            </LayoutSection>
            <LayoutSidebar>
                <Header3>Need Assistance?</Header3>
                <p>
                    Our customer service representatives are here to help. Contact us at <PhoneNumberLink phoneNumber={phoneNumber.help} />.{' '}
                </p>
                <GridSpacer spacing={Spacing.Medium} />
                <Header3>New to SCANA Energy?</Header3>
                <GridSpacer spacing={Spacing.Medium} />
                <LinkWithArrow data-testid="create-online-account-link" to={Routes.CreateAccount}>
                    Create Online Account
                </LinkWithArrow>
            </LayoutSidebar>
        </LayoutWithSidebar>
    )
}

interface LoginPanelProps {
    readonly to: string
    readonly email: string
}
