import * as React from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'

import { ServiceAddressSelectWrapper } from '@igs-web/common-components/domain/service-address/service-address-form'
import {
    AddressSelectorWrapper,
    ServiceAddressSelectorDisplay,
    ServiceAddressSelectorDisplayProps,
    SingleAddress,
} from '@igs-web/common-components/domain/service-address/service-address-selector'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { LineOfBusinessCode } from '@igs-web/common-models/models'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { Header4 } from '@igs-web/common-ui-components/_atoms/typography'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { darkBase, fontSizeSmall } from '@igs-web/common-ui-components/styles/theme'

import { useHwMyaccountDispatch, useHwMyaccountState } from 'domain/home-warranty/hw-myaccount-context'
import { getHwServiceAddressList } from 'domain/home-warranty/scana-hw-service'

const StyledAddressSelector = styled(ServiceAddressSelectorDisplay)<StyledProps>`
    display: contents;
    span {
        font-weight: unset;
    }
    ${Header4} {
        font-size: ${fontSizeSmall};
        font-weight: ${FontWeight.Bold};
        align-self: center;
        margin: 0;
    }

    &${AddressSelectorWrapper} {
        display: contents;
        text-align: unset;
        & ${Header4} {
            font-size: ${fontSizeSmall};
            font-weight: unset;
            color: ${darkBase};
        }
        ${ServiceAddressSelectWrapper} {
            width: 100%;
            max-width: 100%;
        }
    }
`

const StyledAddressSelectorArea = styled.div<StyledProps>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: ${Spacing.Large};
    grid-column-gap: ${Spacing.Medium};
    border: ${props => (props.$headerText ? 'unset' : '1px solid var(--border-box-color,var(--light-dark))')};
    padding: ${props => (props.$headerText ? 0 : Spacing.Large)};
    ${IconHeading} {
        grid-column: 1/-1;
    }
    ${SingleAddress} {
        display: block;
        grid-column: 1/-1;
        text-align: ${props => (props.$headerText ? 'left' : 'center')};
        font-weight: ${FontWeight.Bold};
    }
    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-columns: 1fr;
    }
`
interface StyledProps {
    readonly $headerText?: string
}

export const HwAddressSelectorDisplay = ({ dropdownHeaderText, headerText, ...props }: DisplayHwAddressSelectorProps) => {
    return (
        <StyledAddressSelectorArea $headerText={headerText} className={props.className}>
            {headerText && <IconHeading className="address-title">{headerText}</IconHeading>}
            <StyledAddressSelector {...props} dropdownHeaderText={dropdownHeaderText} hideTitleOnSingle={true} $headerText={headerText} />
        </StyledAddressSelectorArea>
    )
}

export const HwMyAccountAddressSelector = ({ dropdownHeaderText, headerText, showInactive = true, ...props }: HwMyAccountAddressSelectorProps) => {
    const userProfile = useSelector(UserSelectors.selectProfile)
    const hwServiceAddressList = getHwServiceAddressList(userProfile, showInactive)
    const { setSelectedAddressHash } = useHwMyaccountDispatch()
    const { selectedAddressHash } = useHwMyaccountState()
    const selectedAddressKey = hwServiceAddressList.find(a => a.addressHash === selectedAddressHash)?.serviceAddressKey

    React.useEffect(() => {
        if ((!selectedAddressHash || !hwServiceAddressList.some(a => a.addressHash === selectedAddressHash)) && hwServiceAddressList.length > 0) {
            if (hwServiceAddressList.length === 1) {
                setSelectedAddressHash(hwServiceAddressList[0])
            } else {
                const activeHw = userProfile?.accounts.find(a => a.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty && a.status === AccountStatus.active)
                if (activeHw) {
                    setSelectedAddressHash(hwServiceAddressList.find(h => h.serviceAddressKey === activeHw.serviceAddressKey) || hwServiceAddressList[0])
                } else {
                    setSelectedAddressHash(hwServiceAddressList[0])
                }
            }
        }
    }, [userProfile])

    return (
        <StyledAddressSelectorArea $headerText={headerText} className={props.className}>
            {headerText && <IconHeading className="address-title">{headerText}</IconHeading>}
            <StyledAddressSelector
                serviceAddresses={hwServiceAddressList}
                onSelect={setSelectedAddressHash}
                dropdownHeaderText={dropdownHeaderText}
                selectedServiceAddressKey={selectedAddressKey}
                hideTitleOnSingle={true}
                accounts={userProfile?.accounts}
                $headerText={headerText}
            />
        </StyledAddressSelectorArea>
    )
}

interface DisplayHwAddressSelectorProps extends ServiceAddressSelectorDisplayProps {
    readonly headerText?: string
}

interface HwMyAccountAddressSelectorProps {
    readonly dropdownHeaderText: string
    readonly headerText?: string
    readonly showInactive?: boolean
    readonly className?: string
}
