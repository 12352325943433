import { ContactUsRoute } from 'domain/routes'
import * as React from 'react'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { BbbLogoIcon } from '@igs-web/common-components/scana-myaccount/logos/footer-icons'
import { ScanaBusinessInfo } from '@igs-web/common-components/scana-myaccount/scana-business-info'
import { BusinessInfoContainer, EmergencyFooter, FooterContainer } from '@igs-web/common-components/scana-myaccount/scana-footer'
import { ScanaFooterNavContainer, ScanaFooterNavLink } from '@igs-web/common-components/scana-myaccount/scana-footer-nav'
import {
    ScanaFooterSecondaryNavLink, ScanaFooterSecondayNavContainer, StyledScanaFooterSecondaryNavCopyright
} from '@igs-web/common-components/scana-myaccount/scana-footer-secondary-nav'

import { SocialBar } from './social-bar'

const BbbUrl = 'https://www.bbb.org/us/ga/atlanta/profile/natural-gas-companies/scana-energy-marketing-0443-866'

export const ScanaFooter = () => {
    const { phoneNumber, businessHours, externalWebsite } = useCompany()
    return (
        <React.Fragment>
            <FooterContainer>
                <BusinessInfoContainer>
                    <ScanaBusinessInfo phoneNumber={phoneNumber.help} hours={businessHours.businessHoursAbbreviated} />
                    <SocialBar />
                </BusinessInfoContainer>
                <ScanaFooterNavContainer>
                    <ScanaFooterNavLink href={ContactUsRoute}>Contact Us</ScanaFooterNavLink>
                    <ScanaFooterNavLink href={`${externalWebsite}/community`}>Community</ScanaFooterNavLink>
                    <ScanaFooterNavLink href={`${externalWebsite}/news`}>News</ScanaFooterNavLink>
                    <ScanaFooterNavLink href={`${externalWebsite}/blog`}>Blog</ScanaFooterNavLink>
                    <ScanaFooterNavLink href="https://igsenergy.wd1.myworkdayjobs.com/en-US/SCANAEnergy">Careers</ScanaFooterNavLink>
                </ScanaFooterNavContainer>
                <ScanaFooterSecondayNavContainer>
                    <StyledScanaFooterSecondaryNavCopyright>&copy;2000-{new Date().getFullYear()}</StyledScanaFooterSecondaryNavCopyright>
                    <ScanaFooterSecondaryNavLink href={`${externalWebsite}/terms-conditions`}>Terms &amp; Conditions</ScanaFooterSecondaryNavLink>
                    <ScanaFooterSecondaryNavLink href={`${externalWebsite}/privacy`}>Privacy</ScanaFooterSecondaryNavLink>
                    <ScanaFooterSecondaryNavLink href={BbbUrl} openInNewTab={true}>
                        <BbbLogoIcon />
                    </ScanaFooterSecondaryNavLink>
                </ScanaFooterSecondayNavContainer>
            </FooterContainer>
            <EmergencyFooter phoneNumber={phoneNumber.gasLeak} />
        </React.Fragment>
    )
}
