import styles from 'root/../styles/abstracts/_variables.scss'
import styled from 'styled-components'

import { MasterGrid } from '@igs-web/common-ui-components/_molecules/grid/master-grid'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

export const NavContainer = styled(MasterGrid)`
    width: 100%;
    box-shadow: 0 0 14.85px 0.15px rgba(0, 0, 0, 0.47);
    grid-template-rows: auto 1fr;
    z-index: var(--navbar-zindex);
    position: fixed;
    top: 0;
    height: ${styles.navbarHeight};
    background-color: ${styles.lightLight};
`
export const GradientBar = styled.div`
    grid-column: 1 / -1;
    background: var(--gradient);
    height: ${Spacing.ExtraSmall};
    width: 100%;
`
