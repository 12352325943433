// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"primaryDark": "var(--brand-dark)",
	"primaryBase": "var(--primary-base)",
	"primaryLight": "var(--primary-light)",
	"primaryAccent": "var(--primary-light)",
	"secondaryDark": "var(--secondary-dark)",
	"secondaryBase": "var(--secondary-base)",
	"secondaryLight": "var(--secondary-light)",
	"secondaryAccent": "var(--comm-accent)",
	"tertiaryDark": "var(--tertiary-dark)",
	"tertiaryBase": "var(--tertiary-base)",
	"tertiaryLight": "var(--tertiary-light)",
	"tertiaryAccent": "var(--tertiary-accent)",
	"quadDark": "var(--comm-dark)",
	"quadBase": "var(--comm-base)",
	"quadLight": "var(--quad-light)",
	"quadAccent": "var(--quad-accent)",
	"darkDark": "var(--dark-dark)",
	"darkBase": "var(--dark-base)",
	"darkLight": "var(--dark-light)",
	"darkAccent": "var(--dark-accent)",
	"lightDark": "var(--light-dark)",
	"lightBase": "var(--light-base)",
	"lightLight": "var(--light-light)",
	"lightAccent": "var(--light-accent)",
	"error": "#cb333b",
	"success": "#4c8c2b",
	"smWidth": "576px",
	"mdWidth": "768px",
	"lgWidth": "992px",
	"xxlWidth": "1200px",
	"maxContainerWidth": "1100px",
	"maxContentWidth": "1440px",
	"navbarHeight": "63px",
	"navbarMaxWidth": "1440px",
	"fullBleedWidth": "1800px",
	"navIconSize": "25px",
	"navIconSizeMobile": "32px",
	"navLogoSize": "79px",
	"navLogoSizeMobile": "49px",
	"gridGap": "20px",
	"gridGap2col": "55px",
	"gridGap3col": "42px",
	"gridGap4col": "48px"
};
module.exports = exports;
