import * as React from 'react'

import { UserProfileServiceAddress } from '@igs-web/common-models/models'
import { SmallText } from '@igs-web/common-ui-components/_atoms/typography'
import { DetailList, DetailListItem } from '@igs-web/common-ui-components/_molecules/detail-list'
import { TypeaheadSelect } from '@igs-web/common-ui-components/_molecules/typeahead/typeahead-select'
import { getFormattedAddress } from '@igs-web/common-utilities/utilities/address-utilities'

import { AccountDetailsCard } from '../account-details/account-details-card/account-details-card'
import { AccountDetailsCardBody } from '../account-details/account-details-card/account-details-card-body'
import { AccountDetailsCardHeader } from '../account-details/account-details-card/account-details-card-header'
import { matchServiceAddress } from '../service-address/service-address-form'

import { bgLightAccent, wideInformationBoxColumns } from './myaccount-components'

const itemToString = (item: AddressSelectorBoxListItem | null) => (item ? `${getFormattedAddress(item.address)}${!item.isActive ? ' - INACTIVE' : ''}` : '')
const filter = (item: AddressSelectorBoxListItem, inputValue: string) => matchServiceAddress(item.address, inputValue)

export interface AddressSelectorBoxListItem {
    readonly address: UserProfileServiceAddress
    readonly isActive: boolean
}

export const ServiceAddressesSelectorBox = ({ address, addresses, selectAddress }: Props) => {
    const selectedItem = addresses.find(x => x.address.serviceAddressKey === address.serviceAddressKey) || null
    return (
        <AccountDetailsCard>
            <AccountDetailsCardHeader>Service Addresses</AccountDetailsCardHeader>
            <AccountDetailsCardBody style={{ ...bgLightAccent }}>
                <DetailList style={{ ...wideInformationBoxColumns }}>
                    <DetailListItem title="Service Address:">
                        <SmallText as="span">Select Service Address</SmallText>
                        <TypeaheadSelect
                            items={addresses}
                            selectedItem={selectedItem}
                            onSelect={(a: AddressSelectorBoxListItem) => selectAddress(a.address.serviceAddressKey)}
                            itemToString={itemToString}
                            filter={filter}
                            label="Select Service Address"
                            dataTestId="service-address-selector-box"
                        />
                    </DetailListItem>
                </DetailList>
            </AccountDetailsCardBody>
        </AccountDetailsCard>
    )
}

interface Props {
    readonly address: UserProfileServiceAddress
    readonly addresses: ReadonlyArray<AddressSelectorBoxListItem>
    readonly selectAddress: (addressKey: string) => void
}
