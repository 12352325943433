import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { AccountDocument } from '@igs-web/common-models/models/document'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { CachedReduxDataStateType, setupCachedReduxData } from '../cached-redux/cached-redux-data'

const load = async (accountId: number, config: RequestConfig) => {
    try {
        const response = await customerInfoApiClient.getDocumentsByAccountId(accountId, config)
        return (response || []).map<AccountDocument>(r => ({
            ...r,
            dateCreated: r.dateCreated ? Date.parse(r.dateCreated) : undefined,
        }))
    } catch (e) {
        throw Error(`There was a problem getting documents for account: ${accountId}`)
    }
}

const { actions, selectors, saga, reducers } = setupCachedReduxData('accountDocuments', load)

export const accountDocumentsActions = actions
export const accountDocumentsSelectors = {
    selectData: (state: CommonReduxState, accountId: number) => selectors.selectData(state, accountId) || [],
}
export const accountDocumentsSaga = saga
export const accountDocumentsReducer = reducers
export type AccountDocumentsState = CachedReduxDataStateType<typeof load>

export const accountDocumentsRedux = { actions, selectors }
