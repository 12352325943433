import { AccountTypeName } from '@igs-web/common-models/constants/account-type'
import { MembershipApplication } from '@igs-web/common-models/constants/membership-application'
import { StateCode } from '@igs-web/common-models/constants/states'
import { LineOfBusinessCode } from '@igs-web/common-models/models'
import { getAccountTypeName } from '@igs-web/common-utilities/services/account-service'
import { MoneyFormat } from '@igs-web/common-utilities/utilities/currency-utilities'
import { Environment, getEnvironment } from '@igs-web/common-utilities/utilities/environment-utilities'

import { BaseCompany, SupportedCompanies } from '../company'
import { defaultCompanyFeatures } from '../companyFeatures'
import { defaultCampaignCodes, defaultEnrollmentFeatures } from '../enrollmentFeatures'
import { defaultRouteFeatures } from '../routeFeatures'

import { scanaVerbiage } from './scana-verbiage'

const getExternalWebsite = () => {
    const baseUrl = 'scanaenergy'
    const env = getEnvironment()
    switch (env) {
        case Environment.Prod:
            return 'https://scanaenergy.com'
        case Environment.Qa:
            return `https://${baseUrl}.qa.igsenergy.net`
        default:
            return `https://${baseUrl}.${env}.igsenergy.net`
    }
}

const getWebsite = (baseUrl: string) => {
    const env = getEnvironment()
    switch (env) {
        case Environment.Prod:
            return `https://account.${baseUrl}.com`
        case Environment.Local:
            return 'https://localhost:9000'
        default:
            return `https://account-${baseUrl}.${env}.igsenergy.net`
    }
}

const externalWebsite = getExternalWebsite()

export const scanaCompany = (): BaseCompany => ({
    name: SupportedCompanies.Scana,
    displayName: 'SCANA Energy',
    displayNameByLob: lob => (lob === LineOfBusinessCode.HomeWarranty ? 'SCANA Home Warranty' : 'SCANA Energy'),
    myAccountUrl: getWebsite('scanaenergy'),
    externalWebsite,
    membershipApplicationName: MembershipApplication.Scana,
    address: 'SCANA Energy, P.O. Box 100157, Columbia, SC 29202-3157',
    lockboxPaymentCompanyName: 'SCANA Energy',
    lockboxPaymentPOBox: 'P.O. Box 105046',
    lockboxPaymentCityStateZip: 'Atlanta, GA 30348-5046',
    businessHours: {
        businessHoursFull: 'Monday-Friday 7am-8pm, Saturday 8am-5pm',
        businessHoursAbbreviated: 'Mon - Fri, 7am to 8pm | Sat, 8am to 5pm',
        businessHoursAbbreviatedWeek: 'Mon - Fri, 7am to 8pm',
        businessHoursAbbreviatedWeekend: 'Sat, 8am to 5pm',
        homwWarrantyAbbreviated: 'Mon - Fri, 8am to 6pm',
        homeWarrantyFull: 'Monday through Friday, 8am–6pm',
    },
    emails: {
        customerSupport: '',
        commecialSupport: '',
        solarSupport: '',
        communitySolar: '',
        homeWarranty: 'CustomerCare@SCANAHomeWarranty.com',
    },
    phoneNumber: {
        help: '18774672262',
        gasLeak: '18774274321',
        heatProgram: '18663538640',
        customerSupport: '18774672262',
        commecialSupport: '8886218828',
        payment: '18774672262',
        solarSupport: '',
        communitySolar: '',
        homeWarranty: '18777712877',
        supportFax: '',
        sales: '',
    },
    verbiage: { ...scanaVerbiage },
    graphics: {
        colorLogo: '/assets/scana-energy-logo.svg',
        monochromeLogo: '/assets/scana-energy-logo.svg',
        loginBackground: '',
    },
    features: {
        ...defaultCompanyFeatures,
        displayPlanAtAGlanceTerminationFee: false,
        displayPlanAtAGlanceProgressCircle: false,
        displayMoreTimeToPay: true,
        displayUtilityInCustomerInformation: false,
        displayDashboardProspects: false,
        canEnrollInHeat: false,
        canMyAccountRateChange: true,
        canChangeNameFromProfile: false,
        hasSolarMyAccount: false,
        canAddAndRemoveAccounts: false,
        preferRoutingOverHref: true,
        displayPaymentHistoryChart: true,
        hasOnlineChat: false,
        displayLoginPageSubheader: false,
        useDashboardScrollIntoView: false,
        canRenewAnyStatus: false,
        displayCreditCheckDisclosure: true,
        displayFieldSpecificAddressErrors: true,
        canManagePaymentsAndBilling: true,
        forceDisplayUsageTableRateInDollars: true,
        variableRateFormatDecimalPlaces: 4,
        variablRateFormatMoneyPrecision: 4,
        variablRateMoneyFormat: MoneyFormat.Dollars,
        displayDataNotAvailableMessage: false,
        useLegacydata: true,
        dataStartDate: new Date('October 08, 2022'),
        displayLegacyDataMessage: true,
        useBillingAccounts: true,
        canCreateOnlinePaymentExtension: true,
    },
    includeNonBreakingHyphenInTermMonths: false,
    includeLobTitleInProductTitle: false,
    useCompanyCommodityTitle: true,
    enrollment: {
        ...defaultEnrollmentFeatures,
        defaultState: StateCode.GA,
        defaultZipCode: '30306',
        collectAlternatePhone: false,
        affirmCreditCheckInfo: true,
        suppressProductsOnSummary: true,
        displayConnectionFeeOnOrderSummary: true,
        loadDiscountAsSeparateCall: false,
        campaignCodes: {
            ...defaultCampaignCodes,
            default: 'web-new',
            renewalDefault: 'rollover_pitch_default',
            rateChangeDefault: 'web-default',
        },
        collectInitialsOnSummary: false,
    },
    routes: {
        ...defaultRouteFeatures,
        general: {
            faq: `${externalWebsite}/blog/faqs`,
        },
        product: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            termsAndConditions: (productCode, accountType, zipCode?, offerCode?, campaignCode?) => {
                const accountTypeName = accountType ? getAccountTypeName(accountType) : accountType
                if (accountTypeName === AccountTypeName.Commercial) {
                    return `${externalWebsite}/rates-sign-up/business-rates/conditions-of-service`
                }
                if (!accountType || accountTypeName === AccountTypeName.Residential.toString()) {
                    return `${externalWebsite}/rates-sign-up/residential-rates/conditions-of-service`
                }
                console.debug(`${productCode}, ${zipCode}, ${offerCode}, ${campaignCode}`)
            },
            productSelection: () => {
                return `${getWebsite('scanaenergy')}/MyAccount/AccountUpdate`
            },
        },
        payment: {
            ...defaultRouteFeatures.payment,
            nonProdPaymentPortalRegistered: 'https://odweb.clienttestmatrix.com/IGSScanaUnregulated/Payment/PostData',
            prodPaymentPortalRegistered: 'https://webpayments.billmatrix.com/IGSScanaUnregulated/Payment/PostData',
            nonProdPaymentPortalGuest: 'https://odweb.clienttestmatrix.com/IGSScanaUnRegulatedGuest',
            prodPaymentPortalGuest: 'https://webpayments.billmatrix.com/IGSScanaUnRegulatedGuest',
            termsOfUse: () => getExternalWebsite() + '/terms-conditions',
            privacyPolicy: () => getExternalWebsite() + '/privacy',
        },
    },
    companionWebsite: {
        displayName: 'SCANA Energy Regulated',
        myAccountUrl: getWebsite('scanaenergyregulated'),
    },
})
