import styled from 'styled-components'

import { Spacing } from '../styles/spacing'

export const BoxContainer = styled.div<StyledContainerProps>`
    background: ${({ $background }) => $background};
    padding: ${Spacing.Medium};
`
interface StyledContainerProps {
    readonly $background?: string
}
