import * as React from 'react'

import styled from 'styled-components'

import { LinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Header5 } from '@igs-web/common-ui-components/_atoms/typography'
import { ScrollableFullPageError } from '@igs-web/common-ui-components/_molecules/error-pages/full-page-error'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { primaryAccent } from '@igs-web/common-ui-components/styles/theme'
import { apiClient } from '@igs-web/common-utilities/api/api-client'
import { sitefinityApiClient } from '@igs-web/common-utilities/api/sitefinity-api-client'
import { Environment, getEnvironment } from '@igs-web/common-utilities/utilities/environment-utilities'

import { SupportedCompanies } from '../company/company'
import { useCompany } from '../company/hooks/useCompany'

const CHECK_AGAIN_TIMER = 30000

const StyledHeader5 = styled(Header5)`
    color: ${primaryAccent};
    margin: 0;
`
const StyledGasLeak = styled(StyledHeader5)`
    font-size: 1.25rem;
`
const StyledLinkbutton = styled(LinkButton)`
    width: max-content;
`
const StyledPhoneNumberLink = styled(PhoneNumberLink)`
    color: ${primaryAccent};
    font-weight: 800;
`

const AppOfflineErrorPage = () => {
    const company = useCompany()
    const isScana = company.name === SupportedCompanies.Scana || company.name === SupportedCompanies.ScanaRegulated
    const websiteUrl = isScana ? company.externalWebsite : company.myAccountUrl

    return (
        <ScrollableFullPageError header="Site Down for Maintenance">
            <div>
                We sincerely apologize for the inconvenience. Our site is currently undergoing scheduled maintenance and upgrades, but will return shortly.
            </div>
            <div>Thank you for your patience.</div>
            <StyledHeader5>
                Questions? Concerns? We're happy to help. Contact us at <StyledPhoneNumberLink phoneNumber={company.phoneNumber.customerSupport} />
            </StyledHeader5>
            <StyledLinkbutton href={websiteUrl}>Return to the main site</StyledLinkbutton>
            {isScana && (
                <>
                    <GridSpacer spacing={Spacing.Medium} />
                    <StyledGasLeak>
                        <strong>
                            For Gas Leaks & Emergencies Call Atlanta Gas Light <StyledPhoneNumberLink phoneNumber={company.phoneNumber.gasLeak} />
                        </strong>
                    </StyledGasLeak>
                </>
            )}
        </ScrollableFullPageError>
    )
}

export const AppOfflineAlerter = () => {
    const [isOffline, setIsOffline] = React.useState(false)
    const environment = getEnvironment()
    const isLocal = environment === Environment.Local

    React.useEffect(() => {
        const checkForOffline = async () => {
            try {
                const isMaintenanceModeOn = (await sitefinityApiClient.isAppOffline({ showGlobalLoader: false })) || false
                const apiAndCrmAvailable = !isMaintenanceModeOn ? await apiClient.isApiAndCrmAvailable({ showGlobalLoader: false }) : false

                setIsOffline(!isLocal && (isMaintenanceModeOn || !apiAndCrmAvailable))

                if (isOffline) {
                    setTimeout(checkForOffline, CHECK_AGAIN_TIMER)
                }
            } catch (e) {
                console.error('Unable to check for app offline', e)
                setIsOffline(false)
            }
        }
        if (isLocal) {
            setIsOffline(false)
        } else {
            checkForOffline()
        }
    }, [isOffline])

    return isOffline ? <AppOfflineErrorPage /> : null
}
