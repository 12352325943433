import React from 'react'

import { BoxedSuccessAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { ExternalLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { InfoText } from '@igs-web/common-ui-components/_molecules/info-text'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

import { useCompany } from '../company/hooks/useCompany'
import { scanaRegulatedCompany } from '../company/scana-regulated/scana-regulated-company'

import { multipleMembershipMessage } from './multiple-membership-message-service'

export const MultipleMembershipBoxedAlert = ({ passwordResetKey }: MultipleMembershipBoxedAlertProps) => {
    const company = useCompany()
    const [userMemberships, setUserMemberships] = React.useState<ReadonlyArray<string> | undefined>(undefined)
    const getUserMemberships = async (key: string) => {
        const response = await customerInfoApiClient.getMyaccountMembershipsFromResetKey(key)
        if (response && response.data) {
            setUserMemberships(response.data)
        }
    }

    React.useEffect(() => {
        getUserMemberships(passwordResetKey)
    }, [passwordResetKey])

    return (
        <>
            {userMemberships && userMemberships.length > 1 && (
                <>
                    <BoxedSuccessAlert show={true} message={multipleMembershipMessage(company, userMemberships)} />
                </>
            )}
        </>
    )
}

interface MultipleMembershipBoxedAlertProps {
    readonly passwordResetKey: string
}

export const ActiveScanaRegulatedGasCustomerWithScanaHwAlert = () => {
    const scanaRegulated = scanaRegulatedCompany()
    return (
        <InfoText>
            To access your {scanaRegulated.displayName} Account, click <ExternalLink href={`${scanaRegulated.myAccountUrl}`}>here</ExternalLink>.
        </InfoText>
    )
}
