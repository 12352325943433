import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeMedium, primaryDark, primaryLight } from '@igs-web/common-ui-components/styles/theme'

export const MainNavLink = styled(RouterLink)`
    display: grid;
    align-items: center;
    grid-template-columns: fit-content(${Spacing.Large}) 1fr;
    column-gap: ${Spacing.Small};
    color: ${primaryDark};
    fill: ${primaryLight};
    text-decoration: none;
    font-size: ${fontSizeMedium};
    font-weight: ${FontWeight.Bold};
    :hover {
        color: ${primaryLight};
        fill: ${primaryDark};
        transition: 200ms;
    }
    svg {
        height: 25px;
        width: auto;
    }
`

export const ExternalNavLink = styled(Link)`
    display: grid;
    align-items: center;
    grid-template-columns: fit-content(${Spacing.Large}) 1fr;
    column-gap: ${Spacing.Small};
    color: ${primaryDark};
    fill: ${primaryLight};
    text-decoration: none;
    font-size: ${fontSizeMedium};
    font-weight: ${FontWeight.Bold};
    :hover {
        color: ${primaryLight};
        fill: ${primaryDark};
        transition: 200ms;
    }
    svg {
        height: 25px;
        width: auto;
    }
`
export const PhoneLink = styled(props => <PhoneNumberLink {...props} />)`
    font-weight: 700;
    text-decoration: none;
    color: ${primaryDark};
`
