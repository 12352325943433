import { ReactNode } from 'react'

import { TableDataType } from './table-data-types'

export interface TableColumn {
    readonly header: ReactNode
    readonly accessor: string
    readonly dataType: TableDataType
    readonly disableSorting?: boolean
    readonly filter?: (unknown) => React.JSX.Element
    readonly hideColumn?: boolean
    readonly hideOnExport?: boolean
    readonly hideOnScreen?: boolean
    readonly hideOnCsv?: boolean
    readonly id?: string
}

const getFirstDefined = (...values: ReadonlyArray<boolean | undefined>): boolean | undefined => values.filter(val => val !== undefined)[0]

export const isHiddenOnCsv = (column: TableColumn) => getFirstDefined(column.hideOnCsv, column.hideOnExport, column.hideColumn)

export const isHiddenOnExport = (column: TableColumn) => getFirstDefined(column.hideOnExport, column.hideColumn)

export const isHiddenOnScreen = (column: TableColumn) => getFirstDefined(column.hideOnScreen, column.hideColumn)

export const getColumnId = (column: TableColumn) => column.id || column.accessor
