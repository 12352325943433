import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'

const modalActionTypes = {
    OPEN_MODAL: '[modal] OPEN_MODAL',
    CLOSE_MODAL: '[modal] CLOSE_MODAL',
}

export const modalActions = {
    openModal: createAction<string>(modalActionTypes.OPEN_MODAL),
    closeModal: createAction<string>(modalActionTypes.CLOSE_MODAL),
}

export const modalReducer = reducer<ModalState>({})
    .add<string>(modalActionTypes.OPEN_MODAL, (state, modalId) => ({
        ...state,
        [modalId]: true,
    }))
    .add<string>(modalActionTypes.CLOSE_MODAL, (state, modalId) => ({
        ...state,
        [modalId]: false,
    }))
    .build()

export class ModalSelectors {
    public static readonly isOpen = (state: CommonReduxState, modalId: string): boolean => !!state.modal[modalId]
}

export interface ModalState {
    readonly [key: string]: boolean
}
