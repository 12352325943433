import { StateCode } from '@igs-web/common-models/constants/states'

import { apiClient, RequestConfig } from './api-client'

export interface ContactUsRequest {
    readonly contactInfo: ContactUsRequest
    readonly commercialName: string
    readonly fullName: string
    readonly phoneNumber: string
    readonly emailAddress: string
    readonly mailingAddress: string
    readonly inquiryRegarding: InquiryType
    readonly message: string
    readonly currentCustomer: boolean
    readonly interestedIn: ReadonlyArray<Interest>
    readonly zipCode?: string
    readonly state?: StateCode
}
export enum InquiryType {
    residential = 'residential',
    commercial = 'commercial',
    general = 'general',
}

export enum Interest {
    solar = 'Solar',
    electric = 'Electric',
    naturalGas = 'Natural Gas',
    homeWarranty = 'Home Warranty',
    led = 'LED',
    chp = 'CHP',
    cng = 'CNG',
    careerOpportunities = 'Career Opportunities',
    employmentVerification = 'Employment Verification',
    other = 'Other',
}

export interface ContactUsQuestion {
    readonly question: string
    readonly fullName: string
    readonly billingAccountNumber?: string
    readonly noBillingAccountNumber: boolean
    readonly phoneNumber: string
    readonly email: string
    readonly serviceAddress: string
    readonly zipCode: string
    readonly message: string
}
class ContactUsAPiClient {
    public readonly contactUs = async (request: ContactUsQuestion, config?: RequestConfig) => {
        await apiClient.post<void>(`${apiClient.apiBaseUrl}/Email/ContactUsQuestion`, request, config)
    }
    public readonly submit = async (request: ContactUsRequest, config?: RequestConfig) =>
        await apiClient.post<void>(`${apiClient.apiBaseUrl}/Email/ContactUs`, request, config)
}

export const contactUsApiClient = new ContactUsAPiClient()
