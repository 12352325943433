import * as React from 'react'

import { WizardContextProvider } from './wizard-context'

export const Wizard = ({ children, initiallyActivePageId }: WizardProps) => (
    <div>
        <WizardContextProvider initiallyActivePageId={initiallyActivePageId}>{children}</WizardContextProvider>
    </div>
)

export interface WizardProps {
    readonly initiallyActivePageId?: string
    readonly children: React.ReactNode
}
