import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const HomeCircleIcon = ({ altText = 'Home', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M13.672 15.163h1.916v1.916h-1.916v-1.916z" />
        <path d="M16.477 15.163h1.916v1.916h-1.916v-1.916z" />
        <path d="M13.672 17.975h1.916v1.916h-1.916v-1.916z" />
        <path d="M16.477 17.975h1.916v1.916h-1.916v-1.916z" />
        <path d="M9.898 22.227v-7.078l6.087-6.080 6.153 6.153v7.005zM26.787 16.83l-10.801-10.809-6.087 6.087v-2.563h-2.284v4.846l-2.438 2.438h2.416v7.681h16.822v-7.622h2.35z" />

        <path d="M15.985 0c-0.007 0-0.014 0-0.022 0-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-0.002 0-0.005 0-0.007 0-8.827-7.152-15.984-15.977-15.993h-0.001zM15.985 30.458c-0.004 0-0.010 0-0.015 0-7.985 0-14.458-6.473-14.458-14.458s6.473-14.458 14.458-14.458c7.985 0 14.458 6.473 14.458 14.458v0c0 0.004 0 0.010 0 0.015 0 7.977-6.466 14.443-14.443 14.443v0z" />
    </SvgIcon>
)

export default HomeCircleIcon
