import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { MostRecentInvoiceLink } from '@igs-web/common-components/domain/billing/most-recent-invoice-link'
import { Verbiage } from '@igs-web/common-components/domain/company/companyVerbiage'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { PaymentExtensionMessage } from '@igs-web/common-components/domain/payment/payment-extension-message'
import { PaymentPortalForm } from '@igs-web/common-components/domain/payment/payment-portal-form'
import { Address } from '@igs-web/common-models/models'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeExtraLarge } from '@igs-web/common-ui-components/styles/theme'
import { formatAsMoney, MoneyFormat } from '@igs-web/common-utilities/utilities/currency-utilities'
import { formatDate } from '@igs-web/common-utilities/utilities/date-utilities'

import { InactiveStyle, InfoColumn, InfoHeaderStyle } from '../myaccount-components'

const DueDisplay = styled.div`
    font-size: ${fontSizeExtraLarge};
    font-weight: ${FontWeight.Bold};
    line-height: 1.2;
`

const Addresses = styled.div`
    flex-grow: 1;
    & > div {
        max-width: 30ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

const MobileBR = styled.br`
    @media (min-width: ${Breakpoint.Mobile}) {
        display: none;
    }
`

const ActionButtons = styled(InfoColumn)`
    min-width: 215px;
`

const alignCenter = { '--align-items': 'center' } as React.CSSProperties
const mediumGap = { '--gap': Spacing.Large } as React.CSSProperties
const noGap = { '--gap': '0' } as React.CSSProperties
const ADDRESSES_TO_SHOW = 3

export const BillingAccountTile = ({
    className,
    mailDistributions,
    billingAccountNumber,
    amountDue,
    dateDue,
    status,
    dashboardUrl,
    paymentExtensionDueDate,
    email,
}: BillingAccountTileProps) => {
    const { routes, verbiage } = useCompany()
    const payBillUrl = routes.payment.payBill(billingAccountNumber)
    const paymentButtonText = verbiage[Verbiage.paymentButtonText]
    const zipCode = mailDistributions[0] ? mailDistributions[0].zipCode : undefined

    return (
        <BillingAccountTileUi
            className={className}
            mailDistributions={mailDistributions}
            billingAccountNumber={billingAccountNumber}
            amountDue={amountDue}
            dateDue={dateDue}
            status={status}
            dashboardUrl={dashboardUrl}
            paymentExtensionDueDate={paymentExtensionDueDate}
            downloadLatestInvoiceLink={<MostRecentInvoiceLink key={billingAccountNumber} billingAccountNumber={billingAccountNumber} />}
            payBillUrl={payBillUrl}
            paymentButtonText={paymentButtonText}
            billingAddressZipCode={zipCode}
            email={email}
        />
    )
}

export const BillingAccountTileUi = ({
    className,
    mailDistributions,
    billingAccountNumber,
    amountDue,
    dateDue,
    status,
    dashboardUrl,
    paymentExtensionDueDate,
    downloadLatestInvoiceLink,
    email,
    paymentButtonText,
}: BillingAccountTileUiProps) => {
    const remainderCount = mailDistributions.length - ADDRESSES_TO_SHOW
    const displayAddresses = mailDistributions.slice(0, ADDRESSES_TO_SHOW)

    return (
        <>
            <InfoColumn className={className} style={noGap}>
                <InfoHeaderStyle>Service Address:</InfoHeaderStyle>
                <Addresses>
                    {displayAddresses.map(a => (
                        <div key={`${a.address1}.${a.address2}.${a.city}.${a.state}.${a.zipCode}`}>
                            {a.address1}
                            {a.address2 ? ` ${a.address2}` : ''}, {a.city}, {a.state} {a.zipCode}
                        </div>
                    ))}
                    {remainderCount > 0 ? <StrongText as="div">and {remainderCount} more</StrongText> : ''}
                </Addresses>

                <Link to={dashboardUrl}>View Account Summary</Link>
            </InfoColumn>
            <InfoColumn className={className} style={mediumGap}>
                <InfoHeaderStyle>
                    Billing Account Number:
                    <MobileBR /> {billingAccountNumber}
                </InfoHeaderStyle>
                <div>
                    <InfoHeaderStyle>Amount Due:</InfoHeaderStyle>
                    <DueDisplay>{formatAsMoney(amountDue, MoneyFormat.Dollars)}</DueDisplay>
                </div>

                {paymentExtensionDueDate && (
                    <InfoHeaderStyle>
                        EXTENSION DUE DATE: &nbsp;
                        <MobileBR />
                        {formatDate(paymentExtensionDueDate)}
                    </InfoHeaderStyle>
                )}
                <InfoHeaderStyle>
                    {dateDue && (
                        <>
                            {paymentExtensionDueDate ? 'ORIGINAL ' : ''} DUE DATE: &nbsp;
                            <MobileBR />
                            {formatDate(dateDue)}
                        </>
                    )}
                </InfoHeaderStyle>
            </InfoColumn>
            <ActionButtons className={className} style={{ ...mediumGap, ...alignCenter }}>
                {status === AccountStatus.inactive && <InactiveStyle>INACTIVE</InactiveStyle>}

                {(status === AccountStatus.active || amountDue > 0) && (
                    <div>
                        <PaymentPortalForm
                            email={email}
                            zipCode={mailDistributions[0].zipCode}
                            submitButtonText={paymentButtonText?.toString()}
                            billingAccountNumber={billingAccountNumber}
                            isLoggedIn={true}
                        />
                    </div>
                )}
                {(status === AccountStatus.active || status === AccountStatus.inactive) && amountDue !== 0 && downloadLatestInvoiceLink}
                {status === AccountStatus.active && <PaymentExtensionMessage billingAccountNumber={billingAccountNumber} />}
            </ActionButtons>
        </>
    )
}

interface BillingAccountTileProps {
    readonly className: string
    readonly mailDistributions: ReadonlyArray<Address>
    readonly billingAccountNumber: string
    readonly amountDue: number
    readonly dateDue: number | Date
    readonly status: AccountStatus
    readonly dashboardUrl: string
    readonly paymentExtensionDueDate?: Date | null
    readonly email: string
    readonly billingAddressZipCode?: string
}

interface BillingAccountTileUiProps extends BillingAccountTileProps {
    readonly downloadLatestInvoiceLink: React.ReactNode
    readonly payBillUrl: string
    readonly paymentButtonText: React.ReactNode | React.ReactElement<any, string | React.JSXElementConstructor<any>>
}
