import * as React from 'react'

export const BbbLogoIcon = () => (
    <svg viewBox="0 0 1007.97 387.98">
        <path
            d="M949.69,0.89c15.96,0,29.61,5.32,40.97,15.96c11.36,10.63,17.04,23.45,17.04,38.43v277.74
	c0,14.98-5.68,27.92-17.04,38.8s-25.01,16.31-40.97,16.33H346.37v-0.73H62.82c-17.4,0-32.27-5.93-44.59-17.77
	S-0.26,343.64-0.26,327.2l0,0l0,0V60.34c0-16.44,6.16-30.58,18.49-42.42S45.41,0.16,62.82,0.16h594.64L949.69,0.89z M12.08,327.2
	c0,13.05,4.95,24.3,14.86,33.71s21.87,14.13,35.89,14.13h283.53V12.46H62.82c-14.02,0-25.99,4.72-35.89,14.13
	c-9.92,9.43-14.86,20.66-14.86,33.71L12.08,327.2L12.08,327.2z M78.06,354.77v-73.24h34.08c8.22,0,14.5,2.18,18.85,6.53
	c3.39,3.39,5.08,7.49,5.08,12.32v0.73c0,6.78-3.14,11.84-9.43,15.23c9.18,4.35,13.79,10.4,13.79,18.14v1.45
	c0,12.57-9.18,18.85-27.55,18.85L78.06,354.77L78.06,354.77z M94.01,340.26h18.85c7.74,0,11.61-2.42,11.61-7.26v-0.73
	c0-5.32-4.1-7.97-12.32-7.97H94.01V340.26L94.01,340.26z M94.01,311.26h17.4c5.8,0,8.7-2.66,8.7-7.97v-1.45
	c0-3.87-3.62-5.8-10.88-5.8H94.01C94.01,296.05,94.01,311.26,94.01,311.26z M111.41,248.89l4.35-14.5h124l4.35,14.5h-32.63
	l-6.53,20.31h-54.39l-5.8-20.31C144.76,248.89,111.41,248.89,111.41,248.89z M130.99,172.75c-1.45-7.26,0.25-14.5,5.08-21.76
	l15.96-21.76l4.35,3.62l-1.45,3.62c-0.48,1.93,0.48,4.1,2.91,6.53l29.73,21.76c6.28,4.35,10.15,10.63,11.61,18.85
	c0,0.48,0.12,1.21,0.37,2.18s0.37,1.69,0.37,2.18c0,6.28-1.93,12.09-5.8,17.4l-15.96,21.76l-4.35-3.62
	c0.96-0.96,1.45-2.66,1.45-5.08c0-1.45-0.96-3.14-2.91-5.08l-29.73-21.76C136.31,187.25,132.45,180.97,130.99,172.75L130.99,172.75
	L130.99,172.75z M146.22,95.88v-6.53c0-9.67,2.66-17.89,7.97-24.66l23.93-32.64l2.91,2.18c-1.93,3.87-2.66,7.26-2.18,10.15
	s2.18,5.8,5.08,8.7l44.96,32.64c9.18,6.28,14.98,15.48,17.4,27.55c0,0.96,0.12,2.18,0.37,3.62s0.37,2.42,0.37,2.91
	c0,9.18-2.91,17.41-8.7,24.66l-29.01,40.61l-3.62-2.18c2.91-4.35,4.35-8.95,4.35-13.79v-3.62c-1.45-6.28-4.58-11.11-9.43-14.5
	l-36.99-26.84C154.44,116.91,148.64,107.48,146.22,95.88L146.22,95.88L146.22,95.88z M146.95,354.77v-73.24h34.08
	c8.22,0,14.5,2.18,18.85,6.53c3.39,3.39,5.08,7.49,5.08,12.32v0.73c0,6.78-3.14,11.84-9.43,15.23c8.7,3.39,13.3,9.18,13.79,17.41
	v3.62c0,11.61-9.18,17.41-27.55,17.41H146.95L146.95,354.77z M162.91,340.26h18.85c7.74,0,11.61-2.42,11.61-7.26v-0.73
	c0-5.32-4.1-7.97-12.32-7.97h-18.14V340.26L162.91,340.26z M162.91,311.26h16.68c6.28,0,9.43-2.66,9.43-7.97v-1.45
	c0-3.87-3.39-5.8-10.15-5.8h-15.96V311.26z M215.84,354.77v-73.24h34.08c8.7,0,14.98,2.18,18.85,6.53
	c3.39,3.39,5.08,7.49,5.08,12.32v0.73c0,6.28-3.14,11.11-9.43,14.5c9.18,3.87,13.79,9.91,13.79,18.12v0.73
	c0,13.54-9.18,20.29-27.55,20.29L215.84,354.77L215.84,354.77z M231.8,340.26h18.85c7.74,0,11.61-2.42,11.61-7.26v-0.73
	c0-5.32-4.1-7.97-12.32-7.97h-18.12v15.96L231.8,340.26L231.8,340.26z M231.8,311.26h15.96c6.76,0,10.15-2.66,10.15-7.97v-0.73
	c0-4.35-3.39-6.53-10.15-6.53H231.8V311.26z M402.22,185.8h13.05l5.08-14.5h24.66l5.08,14.5h13.05l-24.66-68.16h-11.61L402.22,185.8
	L402.22,185.8z M407.29,288.06h30.46c6.28,0,11.36-1.93,15.23-5.8c4.83-3.87,7.26-8.7,7.26-14.5c0-6.28-2.91-10.88-8.7-13.78
	c4.35-3.87,6.53-8.45,6.53-13.78s-2.18-10.15-6.53-14.5c-3.87-3.39-8.95-5.08-15.23-5.08h-29.01V288.06L407.29,288.06z
	 M419.62,275.72v-15.23h18.12c2.42,0,4.83,0.73,7.26,2.18c1.93,1.93,2.91,3.62,2.91,5.08c0,1.93-0.96,3.87-2.91,5.8
	c-2.42,1.45-4.83,2.18-7.26,2.18L419.62,275.72L419.62,275.72z M419.62,248.16v-15.23h16.67c2.91,0,5.08,0.73,6.53,2.18
	c1.93,1.45,2.91,3.14,2.91,5.08c0,2.42-0.96,4.35-2.91,5.8c-1.45,1.45-3.62,2.18-6.53,2.18L419.62,248.16L419.62,248.16z
	 M424.69,158.96l7.97-21.03l7.26,21.03H424.69L424.69,158.96z M461.68,151.72c0,9.67,3.39,17.89,10.15,24.66
	c7.26,7.26,15.71,10.88,25.38,10.88s18.14-3.62,25.38-10.88l-0.73-2.91l-7.26-7.26c-5.32,5.8-11.11,8.7-17.4,8.7
	c-6.28,0-11.84-2.42-16.67-7.26c-4.35-4.35-6.53-9.67-6.53-15.96c0-6.28,2.18-11.84,6.53-16.67c4.83-4.35,10.4-6.53,16.67-6.53
	s12.09,2.66,17.4,7.97l9.43-8.7c-7.74-7.74-16.67-11.61-26.84-11.61c-9.18,0-17.64,3.39-25.38,10.15
	C465.06,134.07,461.68,142.54,461.68,151.72L461.68,151.72L461.68,151.72z M465.3,261.95c0,7.74,2.66,14.27,7.97,19.58
	s11.84,7.97,19.58,7.97c8.22,0,14.75-2.66,19.58-7.97c5.32-4.83,7.97-11.36,7.97-19.58v-41.34h-12.32v41.34
	c0,4.35-1.45,7.97-4.35,10.88s-6.53,4.35-10.88,4.35c-3.39,0-7.01-1.45-10.88-4.35c-2.91-2.91-4.35-6.53-4.35-10.88v-41.34H465.3
	L465.3,261.95L465.3,261.95z M526.22,266.3c0,7.74,3.14,14.02,9.43,18.85c4.83,2.91,10.88,4.35,18.14,4.35s13.29-1.45,18.14-4.35
	c6.28-4.35,9.43-9.91,9.43-16.67c0-7.74-4.1-13.54-12.32-17.41c-2.42,0-4.1-0.25-5.08-0.73c-0.48,0-1.21-0.12-2.18-0.36
	s-1.93-0.36-2.91-0.36c-0.48,0-1.21-0.25-2.18-0.73s-1.69-0.73-2.18-0.73c-0.96,0-1.94-0.12-2.91-0.36s-1.69-0.36-2.18-0.36
	c-6.28-1.45-9.43-4.1-9.43-7.97c0-2.91,1.45-5.08,4.35-6.53s6.04-2.18,9.43-2.18c4.35,0,7.49,0.73,9.43,2.18
	c2.91,2.42,4.35,5.32,4.35,8.7h12.32c0-7.74-2.91-13.78-8.7-18.12c-5.8-3.39-11.61-5.08-17.41-5.08c-6.28,0-11.84,1.69-16.67,5.08
	c-6.28,4.35-9.43,9.67-9.43,15.96c0,8.7,4.1,14.5,12.32,17.41c0.48,0,1.33,0.25,2.54,0.73s2.06,0.73,2.54,0.73s1.33,0.12,2.54,0.36
	s2.06,0.6,2.54,1.1v-0.73c0.48,0,1.33,0.12,2.54,0.36s2.06,0.6,2.54,1.08c0.48,0,1.33,0.12,2.54,0.36s2.06,0.36,2.54,0.36l3.62,0.73
	c3.39,0.96,5.08,3.14,5.08,6.53s-1.69,5.8-5.08,7.26c-2.91,0.96-6.28,1.45-10.15,1.45c-2.91,0-6.28-0.48-10.15-1.45
	c-3.39-1.45-5.08-4.6-5.08-9.43L526.22,266.3L526.22,266.3z M527.66,151.72c0,10.15,3.39,18.37,10.15,24.66
	c6.28,7.26,14.75,10.88,25.38,10.88c9.67,0,17.89-3.62,24.66-10.88v-2.91l-7.26-7.26c-5.8,5.8-11.61,8.7-17.41,8.7
	c-6.76,0-12.32-2.42-16.67-7.26c-4.35-4.35-6.53-9.67-6.53-15.96c0-6.28,2.18-11.84,6.53-16.67c4.35-4.35,9.91-6.53,16.67-6.53
	c5.32,0,11.11,2.66,17.41,7.97l8.7-8.7c-7.74-7.74-16.44-11.61-26.11-11.61c-10.15,0-18.62,3.39-25.38,10.15
	C531.05,133.1,527.68,141.57,527.66,151.72L527.66,151.72L527.66,151.72z M587.87,288.06h12.32v-67.45h-12.32V288.06z M595.11,185.8
	h12.32v-28.28h7.26c4.83,0,8.22,1.69,10.15,5.08l14.5,23.2h63.09v-13.05h-36.26v-15.23h28.28v-12.32h-28.28v-15.23h36.26v-12.32
	h-48.58v66.72l-18.14-26.84c0-0.48-0.48-1.21-1.45-2.18c2.91-1.45,5.32-3.14,7.26-5.08c2.91-3.39,4.35-7.49,4.35-12.32
	c0-5.8-2.18-10.63-6.53-14.5c-4.83-3.87-9.91-5.8-15.23-5.8h-29.01L595.11,185.8L595.11,185.8z M607.43,288.06h12.32v-44.24
	l31.9,44.24h10.88v-67.45h-12.32v44.24l-31.19-44.24h-11.61L607.43,288.06z M607.43,145.19v-15.23h16.67c2.91,0,5.08,0.73,6.53,2.18
	c1.93,1.45,2.91,3.39,2.91,5.8c0,1.93-0.96,3.62-2.91,5.08c-1.45,1.45-3.62,2.18-6.53,2.18L607.43,145.19L607.43,145.19z
	 M669.81,288.06h48.6v-12.32h-36.26V260.5h28.28v-12.32h-28.28v-15.23h36.26v-12.32h-48.59L669.81,288.06L669.81,288.06z
	 M709.69,185.8h26.11c9.18,0,17.16-3.39,23.93-10.15c6.78-6.78,10.15-14.75,10.15-23.93s-3.39-17.16-10.15-23.93
	c-6.76-6.76-14.75-10.15-23.93-10.15h-26.11V185.8L709.69,185.8z M722.01,172.75v-42.78h13.78c5.8,0,10.88,2.18,15.23,6.53
	s6.53,9.43,6.53,15.23c0,5.8-2.18,10.88-6.53,15.23c-3.87,3.87-8.95,5.8-15.23,5.8L722.01,172.75L722.01,172.75z M724.91,266.3
	c0,7.74,3.14,14.02,9.43,18.85c4.83,2.91,10.88,4.35,18.14,4.35s13.29-1.45,18.14-4.35c6.28-4.35,9.43-9.91,9.43-16.67
	c0-7.74-4.1-13.54-12.32-17.41c-2.42,0-4.1-0.25-5.08-0.73c-0.48,0-1.21-0.12-2.18-0.36s-1.94-0.36-2.91-0.36
	c-0.48-0.48-1.33-0.85-2.54-1.1s-2.06-0.36-2.54-0.36s-1.21-0.12-2.18-0.36s-1.69-0.36-2.18-0.36c-6.28-1.45-9.43-4.1-9.43-7.97
	c0-2.91,1.45-5.08,4.35-6.53s6.04-2.18,9.43-2.18s6.53,0.73,9.43,2.18c2.91,2.42,4.35,5.32,4.35,8.7h12.32
	c0-7.74-2.91-13.78-8.7-18.12c-5.8-3.39-11.61-5.08-17.41-5.08s-11.59,1.69-17.41,5.08c-5.8,4.35-8.7,9.67-8.7,15.96
	c0,8.7,4.1,14.5,12.32,17.41c0.48,0,1.33,0.25,2.54,0.73s2.06,0.73,2.54,0.73s1.33,0.12,2.54,0.36s2.06,0.6,2.54,1.1v-0.73
	c0.48,0.48,1.33,0.73,2.54,0.73s2.06,0.25,2.54,0.73c0.48,0,1.33,0.12,2.54,0.36s2.06,0.36,2.54,0.36c5.8,1.45,8.7,3.87,8.7,7.26
	s-1.69,5.8-5.08,7.26c-2.91,0.96-6.28,1.45-10.15,1.45c-2.91,0-6.28-0.48-10.15-1.45c-3.39-1.45-5.08-4.6-5.08-9.43L724.91,266.3
	L724.91,266.3z M776.4,185.8h12.32v-68.16H776.4V185.8z M785.83,266.3c0,8.22,3.14,14.5,9.43,18.85c4.83,2.91,10.88,4.35,18.12,4.35
	c7.26,0,13.29-1.45,18.14-4.35c6.28-4.35,9.43-9.91,9.43-16.67c0-7.74-4.1-13.54-12.32-17.41c-2.42,0-4.1-0.25-5.08-0.73
	c-0.48,0-1.21-0.12-2.18-0.36s-1.93-0.36-2.91-0.36c-0.48-0.48-1.33-0.85-2.54-1.1s-2.06-0.36-2.54-0.36s-1.21-0.12-2.18-0.36
	s-1.69-0.36-2.18-0.36c-6.28-1.45-9.43-4.1-9.43-7.97c0-2.91,1.45-5.08,4.35-6.53s6.04-2.18,9.43-2.18s6.53,0.73,9.43,2.18
	c2.91,2.42,4.35,5.32,4.35,8.7h12.32c0-7.74-2.91-13.78-8.7-18.12c-5.8-3.39-11.61-5.08-17.41-5.08s-11.59,1.69-17.41,5.08
	c-5.8,4.35-8.7,9.67-8.7,15.96c0,8.7,4.1,14.5,12.32,17.41c0.48,0,1.33,0.25,2.54,0.73s2.06,0.73,2.54,0.73s1.33,0.25,2.54,0.73
	s2.06,0.73,2.54,0.73v-0.73c0.48,0.48,1.33,0.73,2.54,0.73s2.06,0.25,2.54,0.73c0.48,0,1.21,0.12,2.18,0.36s1.93,0.36,2.91,0.36
	c5.8,1.45,8.7,3.87,8.7,7.26s-1.69,5.8-5.08,7.26c-3.87,0.96-7.26,1.45-10.15,1.45s-6.28-0.48-10.15-1.45
	c-3.39-1.45-5.08-4.6-5.08-9.43h-12.34L785.83,266.3L785.83,266.3z M792.36,129.96h21.03v55.84h12.32v-55.84h20.31v-12.32h-53.66
	V129.96z M850.37,185.8h48.58v-13.05H862.7v-15.23h28.28v-12.32H862.7v-15.23h36.26v-12.32h-48.58V185.8L850.37,185.8z
	 M906.21,185.8h26.11c9.18,0,17.16-3.39,23.93-10.15c6.28-6.28,9.43-14.27,9.43-23.93s-3.14-17.65-9.43-23.93
	c-6.78-6.76-14.75-10.15-23.93-10.15h-26.11V185.8L906.21,185.8z M918.53,172.75v-42.78h13.78c5.8,0,10.88,2.18,15.23,6.53
	c3.87,3.87,5.8,8.95,5.8,15.23s-1.93,11.36-5.8,15.23c-3.87,3.87-8.95,5.8-15.23,5.8L918.53,172.75L918.53,172.75z"
        />
    </svg>
)
