import _countBy from 'lodash-es/countBy'
import _isFunction from 'lodash-es/isFunction'
import _range from 'lodash-es/range'

export const conditionalArrayItem = <T, K>(condition: K | undefined | null, value: T | ((c: K) => T)) => [
    ...(condition ? (_isFunction(value) ? [(value as (c: K) => T)(condition)] : [value as T]) : []),
]

// deprecated. Name is confusing since this operates on a single object, not a full array
export const conditionalArray = conditionalArrayItem

export const conditionalArrayItems = <T>(condition: boolean, array: Readonly<T>) => (condition ? array : [])

export const rangeInclusive = (start: number, end: number) => _range(start, end + 1)
export { _range as range }

export const nonUnique = <T>(arr: ReadonlyArray<T>) => {
    const dict = _countBy(arr, x => x)
    const nonUniqueValues = Object.entries(dict)
        .filter(entry => entry[1] > 1)
        .map(entry => entry[0])
    return nonUniqueValues
}

type asArryInput<T> = undefined | ReadonlyArray<T> | T
export const asArray = <T>(input: asArryInput<T>): ReadonlyArray<T> => {
    const createdArray: ReadonlyArray<T> = !input ? new Array<T>() : Array.isArray(input) ? [...input] : [input]
    return createdArray
}

export const removeUndefined = <S>(value: S | undefined): value is S => value !== undefined
