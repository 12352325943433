import React from 'react'
import { Link } from 'react-router-dom'

import { multipleMembershipMessage } from '@igs-web/common-components/domain/authentication/multiple-membership-message-service'
import { ResetPasswordForm } from '@igs-web/common-components/domain/authentication/reset-password-form'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText, InfoTextType } from '@igs-web/common-ui-components/_molecules/info-text'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

import { LayoutSection, LayoutSidebar, LayoutWithSidebar, LinkWithArrow } from 'molecules/layout/layout-styles'

import * as Routes from 'domain/routes'

export const ResetPasswordPanel = ({ passwordResetKey }: Props) => {
    const company = useCompany()
    const [error, setError] = React.useState<string | undefined>(undefined)

    const [userMemberships, setUserMemberships] = React.useState<ReadonlyArray<string> | undefined>(undefined)
    const getUserMemberships = async (key: string) => {
        const response = await customerInfoApiClient.getMyaccountMembershipsFromResetKey(key)
        if (response && response.data) {
            setUserMemberships(response.data)
        }
    }

    React.useEffect(() => {
        getUserMemberships(passwordResetKey)
    }, [passwordResetKey])

    return (
        <LayoutWithSidebar>
            <LayoutSection>
                {userMemberships && userMemberships.length > 1 && (
                    <>
                        <InfoText $infoTextType={InfoTextType.Informational}>{multipleMembershipMessage(company, userMemberships)}</InfoText>
                        <GridSpacer spacing={Spacing.ExtraLarge} />
                    </>
                )}
                {error && (
                    <>
                        <InfoText $infoTextType={InfoTextType.Error}>
                            This password reset link has already been used. Please look for the most recent email from us in your inbox or request a new{' '}
                            <Link to={Routes.ForgotPassword}>password reset email.</Link>
                        </InfoText>
                        <GridSpacer spacing={Spacing.ExtraLarge} />
                    </>
                )}
                <ResetPasswordForm uniqueId="reset-password-form" passwordResetKey={passwordResetKey} onError={e => setError(e)} />
                <GridSpacer spacing={Spacing.XxLarge} />
            </LayoutSection>
            <LayoutSidebar>
                <Header3>Need Assistance?</Header3>
                <p>
                    Our customer service representatives are here to help. Contact us at <PhoneNumberLink phoneNumber={company.phoneNumber.help} />.{' '}
                </p>

                <LinkWithArrow to={Routes.CreateAccount}>Register New Account</LinkWithArrow>
            </LayoutSidebar>
        </LayoutWithSidebar>
    )
}
interface Props {
    readonly passwordResetKey: string
    readonly oneTimeRegistration?: boolean
}
