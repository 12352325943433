import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const ElectricCircleIcon = ({ altText = 'Electric', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M7.086 12.387v4.978c0.012 1.519 1.247 2.746 2.768 2.746 0.008 0 0.016-0 0.023-0h6.086c0.007 0 0.014 0 0.022 0 1.521 0 2.756-1.227 2.768-2.745v-4.98z" />
        <path d="M9.223 6.418h1.454v5.243h-1.454v-5.243z" />
        <path d="M15.156 6.418h1.454v5.243h-1.454v-5.243z" />
        <path d="M24.606 19.965c-0 0-0.001 0-0.001 0-0.393 0-0.713 0.308-0.733 0.696l-0 0.002c0.002 0.054 0.003 0.117 0.003 0.18 0 2.84-2.303 5.143-5.143 5.143-2.777 0-5.040-2.201-5.14-4.954l-0-0.009h-1.402l0.044 0.316v0.286c0.654 3.016 3.3 5.243 6.466 5.243 3.513 0 6.385-2.741 6.596-6.201l0.001-0.019c-0.020-0.389-0.341-0.698-0.733-0.698-0 0-0.001 0-0.001 0h0z" />
        <path d="M15.985 0c-0.007 0-0.014 0-0.022 0-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-0.002 0-0.005 0-0.007 0-8.827-7.152-15.984-15.977-15.993h-0.001zM15.985 30.458c-0.004 0-0.010 0-0.015 0-7.985 0-14.458-6.473-14.458-14.458s6.473-14.458 14.458-14.458c7.985 0 14.458 6.473 14.458 14.458v0c0 0.004 0 0.010 0 0.015 0 7.977-6.466 14.443-14.443 14.443v0z" />
    </SvgIcon>
)

export default ElectricCircleIcon
