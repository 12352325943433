import _sortBy from 'lodash-es/sortBy'

import { Address } from '@igs-web/common-models/models/address'

export const zipCodeRegex = /^\d{5}$/

export const isZipCode = (str: string) => zipCodeRegex.test(str)

export const getFormattedAddress = (address: Partial<Address>): string => {
    return [[[address.address1, address.address2, address.city].filter(a => a).join(' '), address.state].filter(a => a).join(', '), address.zipCode]
        .filter(a => a)
        .join(' ')
}

export const getSortedAddresses = <T extends Address>(addresses: ReadonlyArray<T>): ReadonlyArray<T> => {
    return _sortBy(
        addresses,
        a => a.state,
        a => a.city,
        a => a.zipCode,
        a => a.address1,
        a => a.address2,
    )
}
