import * as React from 'react'
import { FaCaretSquareDown, FaCaretSquareRight } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import styled from 'styled-components'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { useBreakpoints } from '@igs-web/common-components/hooks/use-breakpoints'
import { SvgSize } from '@igs-web/common-ui-components/_atoms/SVGs/svg-icon'
import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { NavItemLink as ExternalNavItemLink, NavItem, NavbarItem, StyledNavItem, navItemLinkStyle } from '@igs-web/common-ui-components/_atoms/navigation'
import { HamburgerButton } from '@igs-web/common-ui-components/_atoms/navigation/hamburger-button'
import { Panel } from '@igs-web/common-ui-components/_atoms/panel'
import { NoWrapText } from '@igs-web/common-ui-components/_atoms/typography'
import { AccordionGroupProvider } from '@igs-web/common-ui-components/_molecules/accordion/accordion-group-context'
import { useAccordionItem } from '@igs-web/common-ui-components/_molecules/accordion/accordion-item'
import { PopPanel } from '@igs-web/common-ui-components/_molecules/pop-panel'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { primaryAccent, primaryDark, primaryLight } from '@igs-web/common-ui-components/styles/theme'

import { AccordionAnimation } from 'root/views/product-selection-new/accordion-animation'

import { MenuItem, useNavigationItems } from 'molecules/header/use-navigation-items'

import * as Routes from 'domain/routes'

const MyAccountItem = styled(NavbarItem)`
    --panel-background-color: ${primaryDark};
    grid-auto-flow: column;
    grid-column-gap: ${Spacing.Small};
    button {
        line-height: 1;
        text-transform: none;
        max-height: 135px;
        font-weight: ${FontWeight.Medium};
        padding: ${Spacing.Small};
        fill: ${primaryLight};
        &:hover {
            fill: white;
        }
        svg {
            height: ${SvgSize.Tiny};
            width: auto;
        }
    }
    &:last-child {
        border-bottom: 0;
    }
    ${StyledNavItem} + div:not(${StyledNavItem}) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        width: 100%;
        > * {
            --indent: 3rem;
            position: relative;
            padding-inline-start: calc(${Spacing.Large} + var(--indent));
            &:before {
                content: '•';
                position: absolute;
                left: var(--indent);
            }
        }
    }
    ${Panel} {
        top: 100%;
    }
`

const NavItemLink = styled(Link)`
    ${navItemLinkStyle}
    &:hover {
        color: ${primaryAccent};
    }
`
const ExternalLink = styled(ExternalNavItemLink)`
    &:hover {
        color: ${primaryAccent};
    }
`
const GreetingText = styled.p`
    font-size: small;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 20px;
    text-transform: capitalize;
`
export const MyAccountPanel = () => {
    const [isOpen, setIsOpen] = React.useState(false)
    const history = useHistory()
    const { lessThanOrEqualTo } = useBreakpoints()
    const isMobile = lessThanOrEqualTo(Breakpoint.Tablet)

    const isLoggedIn = useSelector((state: CommonReduxState) => UserSelectors.selectLoggedIn(state))
    const userProfile = useSelector((state: CommonReduxState) => UserSelectors.selectProfile(state))

    const showLogin = () => {
        setIsOpen(false)
        history.push(Routes.LoginRoute)
    }

    const { mobileMenuItems } = useNavigationItems()

    const handleClick = (menuItem: MenuItem) => {
        setIsOpen(false)
        if (menuItem.onClick) {
            menuItem.onClick()
        }
    }

    const LoginButton = () => (
        <Button onClick={showLogin}>
            <NoWrapText>Customer Login</NoWrapText>
        </Button>
    )

    const handleHover = open => {
        if (isMobile) {
            return
        }
        setIsOpen(open)
    }

    if (!isMobile) {
        if (isLoggedIn && !!userProfile?.firstName) {
            const firstname = userProfile.firstName

            return <GreetingText>Hello, {firstname.toLowerCase()}</GreetingText>
        }
        return <></>
    }

    return (
        <MyAccountItem hover={handleHover} dataTestId="my-account-nav-menu-header">
            <HamburgerButton isOpen={isOpen} toggleOpen={() => setIsOpen(!isOpen)} />

            <PopPanel isOpen={isOpen} alignRight={true}>
                {!isLoggedIn && (
                    <NavItem dataTestId="account-login-button">
                        <LoginButton />
                    </NavItem>
                )}
                <AccordionGroupProvider onlyOneExpanded={true}>
                    <MenuItems menuItems={mobileMenuItems} handleClick={handleClick} />
                </AccordionGroupProvider>
            </PopPanel>
        </MyAccountItem>
    )
}

const MenuItems = ({ menuItems, handleClick }: MenuItemsProps) => (
    <>
        {menuItems
            .filter(item => !!item.show && !item.hideOnNav)
            .map((menuItem: MenuItem) => (
                <MenuItemDisplay key={menuItem.btnLabel} menuItem={menuItem} handleClick={handleClick} />
            ))}
    </>
)

const MenuItemAcordion = ({ menuItem, handleClick }: MenuItemDisplayProps) => {
    const { isExpanded, toggleExpanded } = useAccordionItem(menuItem.btnLabel)
    if (!menuItem.children) {
        return <></>
    }
    return (
        <>
            <NavItem dataTestId={menuItem.btnLabel} onClick={toggleExpanded}>
                {menuItem.btnLabel} {isExpanded ? <FaCaretSquareDown /> : <FaCaretSquareRight />}
            </NavItem>
            <AccordionAnimation show={isExpanded} animationKey={menuItem.btnLabel}>
                <MenuItems menuItems={menuItem.children} handleClick={handleClick} />
            </AccordionAnimation>
        </>
    )
}

const MenuItemDisplay = ({ menuItem, handleClick }: MenuItemDisplayProps) => {
    if (menuItem.children) {
        return <MenuItemAcordion menuItem={menuItem} handleClick={handleClick} />
    }

    return menuItem.external ? (
        <ExternalLink href={menuItem.link} onClick={() => handleClick(menuItem)}>
            {menuItem.btnLabel}
        </ExternalLink>
    ) : (
        <NavItemLink to={menuItem.link} onClick={() => handleClick(menuItem)}>
            {menuItem.btnLabel}
        </NavItemLink>
    )
}
interface MenuItemsProps {
    readonly menuItems: ReadonlyArray<MenuItem>
    readonly handleClick: (menuItem: MenuItem) => void
}
interface MenuItemDisplayProps {
    readonly menuItem: MenuItem
    readonly handleClick: (menuItem: MenuItem) => void
}
