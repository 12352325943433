import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FaRegCreditCard } from 'react-icons/fa'
import { MdLocationOn } from 'react-icons/md'
import { useSelector } from 'react-redux'

import { useCheckoutDispatch, useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { StepNav } from '@igs-web/common-components/domain/checkout/steps/step-nav'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { AddressForm } from '@igs-web/common-components/domain/forms/address-form'
import { ElectronicCommunicationConsentNotice } from '@igs-web/common-components/domain/legal/electronic-communication-consent-notice'
import { BasicPaymentCaptureForm } from '@igs-web/common-components/domain/payment-capture/payment-capture-form-basic'
import { Address } from '@igs-web/common-models/models/address'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { PaymentMethodModel, PaymentType } from '@igs-web/common-models/models/payment-model'
import { FormWrapper } from '@igs-web/common-ui-components/_atoms/form-wrapper'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { Checkbox } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { useWizardDispatch } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { getEventModel, pushAddedPaymentMethodEvent } from '@igs-web/common-utilities/services/google-analytics-services'

import { ScrollToTop } from 'molecules/routing/scroll-to-top'

export const HwPaymentStep = () => {
    const enrollmentState = useCheckoutState()
    const { nextPage } = useWizardDispatch()
    const { setEnrollmentState } = useCheckoutDispatch()
    const selectedOffers = useSelector(OfferSelectors.selectSelectedOffers)
    const formMethods = useForm<HwPaymentFields>()
    const { handleSubmit, register, watch, setValue } = formMethods

    setValue('lineOfBusinessCode', LineOfBusinessCode.HomeWarranty)
    const onSubmit = (formData: HwPaymentFields) => {
        setEnrollmentState({
            ...enrollmentState,
            billingAddress: formData.billingAddress,
            isBillingSameAsServiceAddress: formData.isBillingSameAsServiceAddress,
            productDetails: {
                ...enrollmentState.productDetails!,
                [formData.lineOfBusinessCode]: {
                    ...enrollmentState.productDetails![formData.lineOfBusinessCode],
                    ...formData,
                },
            },
        })
        pushAddedPaymentMethodEvent(getEventModel(selectedOffers), nextPage)
    }

    const isBillingSameAsServiceAddress = watch('isBillingSameAsServiceAddress', enrollmentState.isBillingSameAsServiceAddress ?? true)

    return (
        <FormProvider {...formMethods}>
            <ScrollToTop />
            <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                <IconHeading>
                    <FaRegCreditCard /> Payment Method
                </IconHeading>
                <Checkbox
                    {...register('isBillingSameAsServiceAddress')}
                    dataTestId="hw-is-billing-address-same"
                    defaultChecked={enrollmentState.isBillingSameAsServiceAddress ?? true}
                    label="My billing address is the same as my service address"
                />

                <BasicPaymentCaptureForm
                    formKey="[payment]."
                    isRecurring={true}
                    idPrefix="hw"
                    defaultPayment={enrollmentState.productDetails![LineOfBusinessCode.HomeWarranty].payment}
                    paymentTypes={[PaymentType.CreditCard, PaymentType.Ach]}
                    defaultPaymentOptionType={PaymentType.CreditCard}
                />
                <ElectronicCommunicationConsentNotice lineOfBusinessCode={LineOfBusinessCode.HomeWarranty} />
                {!isBillingSameAsServiceAddress && (
                    <>
                        <IconHeading>
                            <MdLocationOn /> Billing Address
                        </IconHeading>
                        <AddressForm
                            address={enrollmentState.billingAddress}
                            formKey="[billingAddress]."
                            idPrefix="billing-address-"
                            disableZip={false}
                            labelPrefix="Billing "
                        />
                    </>
                )}
                <StepNav stepFwdButtonText="CONTINUE" styleStepBackAsButton={true} />
            </FormWrapper>
        </FormProvider>
    )
}

interface HwPaymentFields {
    readonly isBillingSameAsServiceAddress: boolean
    readonly billingAddress: Address
    readonly payment: PaymentMethodModel
    readonly lineOfBusinessCode: LineOfBusinessCode
}
