import { UtilityLineOfBusinessModel } from '@igs-web/common-models/models/utility-line-of-business-model'
import { RequestConfig, apiClient } from '@igs-web/common-utilities/api/api-client'

class UtilityApiClient {
    public readonly getUlobsByZipCode = async (zipCode: string, config?: RequestConfig) =>
        await apiClient.get<ReadonlyArray<UtilityLineOfBusinessModel>>(`${apiClient.apiBaseUrl}/UtilityLineOfBusinesses?zipCode=${zipCode}`, config)

    public readonly getUlobByUtility = async (utilityCode: string | undefined, lineOfBusinessCode: string | undefined, config?: RequestConfig) =>
        await apiClient.get<UtilityLineOfBusinessModel>(
            `${apiClient.apiBaseUrl}/UtilityLineOfBusiness?utilityCode=${utilityCode}&lineOfBusinessCode=${lineOfBusinessCode}`,
            config,
        )
}

export const utilityApiClient = new UtilityApiClient()
