import { StateCode } from '@igs-web/common-models/constants/states'
import { ChoicePayment } from '@igs-web/common-models/models/choice-transaction-history'

export const isErcotUtility = (stateCode?: StateCode): boolean =>{
    if (stateCode === StateCode.TX) {
        return true
    }
    
    return false
}

export const dedupeChoicePayments = (payments: ReadonlyArray<ChoicePayment>) => {
    return payments.filter((payment, index, self) => index === self.findIndex(t => (
        t.transactionIdentifier === payment.transactionIdentifier
    )))
}