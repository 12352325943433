import * as React from 'react'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'

import { PaymentExtensionNavigationButtons } from './payment-extension-nav-buttons'

export const PaymentExtensionError = ({ billingAccountNumber }: PaymentExtensionErrorProps) => {
    const { phoneNumber } = useCompany()
    return (
        <>
            <p>Your Payment Extension was not created.</p>
            <p>
                We were unable to create a payment extension for you. if you need further assistance please contact 
                us at{' '}<PhoneNumberLink phoneNumber={phoneNumber.customerSupport} />
            </p>
            <PaymentExtensionNavigationButtons billingAccountNumber={billingAccountNumber} />
        </>
    )
}

interface PaymentExtensionErrorProps {
    readonly billingAccountNumber?: string
}