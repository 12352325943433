import * as React from 'react'
import { useController } from 'react-hook-form'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'

import { IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'

import { InputError, StyledControl } from '../web-framework-inputs'

export const PasswordInput = ({ name, label, control, dataTestId, rules = {} }: Props) => {
    const [showPassword, setShowPassword] = React.useState(false)

    const {
        field,
        fieldState: { error },
    } = useController({ name, control, rules })

    const handleClickShowPassword = () => setShowPassword(show => !show)
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }
    return (
        <StyledControl variant="outlined" error={!!error}>
            <InputLabel htmlFor={name}>{label}</InputLabel>

            <OutlinedInput
                {...field}
                inputProps={{ 'data-testid': dataTestId }}
                type={showPassword ? 'text' : 'password'}
                error={!!error}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label={showPassword ? 'hide the password' : 'display the password'}
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseUpPassword}
                            edge="end"
                        >
                            {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                        </IconButton>
                    </InputAdornment>
                }
                label={label}
            />
            {!!error && <InputError>{error.message}</InputError>}
        </StyledControl>
    )
}

interface Props {
    readonly name: string
    readonly label: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly control: any
    readonly dataTestId: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly rules?: any
}
