import { BaseTheme, lightTheme } from '@igs/react-styled-components'

const frameworkTheme = lightTheme()
export const defaultTheme = (): BaseTheme => ({
    ...frameworkTheme,
    name: 'igsWeb',
    isDarkTheme: false,
    primary: {
        color: '#005cb9',
        variant: '004987',
        textOn: '#FFF',
    },
    secondary: {
        color: '#005cb9',
        variant: '#004987',
        textOn: '#FFF',
    },
})
