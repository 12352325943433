import { CachedReduxDataStateType, setupCachedReduxData } from '@igs-web/common-components/domain/cached-redux/cached-redux-data'
import { BudgetBillingAccountResponse } from '@igs-web/common-models/models/budget-billing'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

const load = async (billingAccountNumber: string, config: RequestConfig): Promise<BudgetBillingAccountResponse | undefined> => {
    try {
        const billingAccountResult = await customerInfoApiClient.getBudgetBillingInformation([billingAccountNumber], config)

        const billingAccountInfo = billingAccountResult?.find(x => x.billingAccountNumber === billingAccountNumber)

        return billingAccountInfo
    } catch (e) {
        console.error(`There was a problem getting usage: ${e.message}`)
    }
}

const loadMultiple = async (
    billingAccountNumbers: ReadonlyArray<string>,
    config: RequestConfig,
): Promise<Record<string, BudgetBillingAccountResponse | undefined>> => {
    const billingAccountResult = await customerInfoApiClient.getBudgetBillingInformation(billingAccountNumbers, config)

    if (billingAccountResult) {
        const response = billingAccountResult?.reduce(
            (o, cur) => ({
                ...o,
                [cur.billingAccountNumber]: cur,
            }),
            {},
        )

        return response
    }
    return billingAccountNumbers.reduce(
        (o, cur) => ({
            ...o,
            [cur]: undefined,
        }),
        {},
    )
}

const { actions, selectors, saga, reducers } = setupCachedReduxData('budgetBilling', load, loadMultiple)

export const budgetBillingRedux = { actions, selectors }
export const budgetBillingSaga = saga
export const budgetBillingReducer = reducers
export type BudgetBillingState = CachedReduxDataStateType<typeof load>
