import * as React from 'react'

/**
 * Based on recipe defined at https://usehooks.com/useMedia/
 * @param queries Media queries
 * @param values maps to above media queries by array index
 * @param defaultValue value used if no matching media query is defined
 */
export const useMedia = <T extends unknown>(queries: ReadonlyArray<string>, values: ReadonlyArray<T>, defaultValue: T) => {
    const mediaQueryLists = queries.map(q => window.matchMedia(q))

    const getValue = () => {
        const index = mediaQueryLists.findIndex(mql => mql.matches)
        return typeof values[index] !== 'undefined' ? values[index] : defaultValue
    }

    const [value, setValue] = React.useState(getValue)

    React.useEffect(
        () => {
            const handler = () => setValue(getValue)

            // Set a listener for each media query with above handler as callback.
            mediaQueryLists.forEach(mql => mql.addListener(handler))

            // Remove listeners on cleanup
            return () => mediaQueryLists.forEach(mql => mql.removeListener(handler))
        },
        [], // Empty array ensures effect is only run on mount and unmount
    )

    return value
}
