import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { homeWarrantyPaymentProfileRedux } from '@igs-web/common-components/domain/payment-profile/hw-payment-profile-redux'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'
import { HomeProtectionPaymentProfileResponse, LineOfBusinessCode, UserProfile } from '@igs-web/common-models/models'
import { ErrorText } from '@igs-web/common-ui-components/_atoms/typography'
import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'

enum WarningState {
    NoWarning = 'nowarning',
    WillChange = 'willchange',
    WontChange = 'wontchange',
}

const getWarningState = (userProfile?: UserProfile, paymentProfile?: HomeProtectionPaymentProfileResponse): WarningState => {
    if (!userProfile || !paymentProfile) {
        return WarningState.NoWarning
    }

    const hwAccounts = userProfile.accounts.filter(a => a.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty)
    // If just 1 account, we don't need a warning
    if (hwAccounts.length === 1) {
        return WarningState.NoWarning
    }
    // We have 2 accounts (the current business limit)

    if (paymentProfile.accountIds.length > 1) {
        // this payment profile is used on more than one account, so will change
        return WarningState.WillChange
    }

    // The payment profile is only used on this account, so won't change
    return WarningState.WontChange
}

export const HWCurrentPaymentMethod = ({ accountId }: Props) => {
    const userProfile = useSelector(UserSelectors.selectProfile)
    const paymentProfile = useSelector((store: CommonReduxState) => homeWarrantyPaymentProfileRedux.selectors.selectData(store, accountId))
    const isLoading = useSelector((store: CommonReduxState) => homeWarrantyPaymentProfileRedux.selectors.selectLoading(store, accountId))
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(homeWarrantyPaymentProfileRedux.actions.load({ key: accountId, showGlobalLoader: false }))
    }, [accountId])

    if (isLoading || !paymentProfile) {
        return (
            <p>
                <LocalLoader isLoading={true} />
            </p>
        )
    }

    const warningState = getWarningState(userProfile, paymentProfile)

    return (
        <>
            <p data-testid="current-payment-description">{paymentProfile.description}</p>
            {warningState === WarningState.WillChange && (
                <ErrorText>Updating this payment method will automatically update the payment method for your other home warranty service address.</ErrorText>
            )}
            {warningState === WarningState.WontChange && (
                <ErrorText>
                    Updating this payment method will not automatically update the payment method for your other home warranty service address.
                </ErrorText>
            )}
        </>
    )
}

interface Props {
    readonly accountId: number
}
