import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'

/* eslint-disable max-lines */
import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { OfferAccountType } from '@igs-web/common-models/constants/account-type'
import { GreenLineOfBusinesses } from '@igs-web/common-models/models/green-line-of-business-model'
import { InvitationCodeDetailsResponse } from '@igs-web/common-models/models/invitation-code-detail-response'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { Municipality } from '@igs-web/common-models/models/municipality'
import { OfferModel } from '@igs-web/common-models/models/offer-model'
import { PromoCodeInfo } from '@igs-web/common-models/models/promo-code'
import { apiClient } from '@igs-web/common-utilities/api/api-client'
import { geocodeApiClient } from '@igs-web/common-utilities/api/geocode-api-client'
import { invitationCodeApiClient } from '@igs-web/common-utilities/api/invitation-code-api-client'
import { getAndApplyDiscounts, getOffers } from '@igs-web/common-utilities/services/offer-retrieval'
import { isZipCode } from '@igs-web/common-utilities/utilities/address-utilities'
import { asArray } from '@igs-web/common-utilities/utilities/array-utilities'
import { fireCustomFullStoryEvent, FsEvent } from '@igs-web/common-utilities/utilities/fs-logger'
import { createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'
import { buildUrl } from '@igs-web/common-utilities/utilities/url-utilities'

import { selectCompany } from '../company/hooks/useCompany'
import { enrollmentModelActions, enrollmentModelActionTypes, UpdateServiceAddressRequest } from './enrollment-redux-actions'
import { enrollmentRoutes } from './enrollment-routes'

const offersActionTypes = {
    LOAD_OFFERS: '[offer] LOAD_OFFERS // REQUEST',
    LOAD_OFFERS_SUCCESS: '[offer] LOAD_OFFERS // SUCCESS',
    LOAD_OFFERS_FAIL: '[offer] LOAD_OFFERS // FAIL',

    SELECT_OFFER: '[offer] SELECT_OFFER',
    UNSELECT_OFFER: '[offer] UNSELECT_OFFER',
    PRESELECT_OFFER_IDS: '[offer] PRESELECT_OFFER_IDS',
    PRESELECT_PRODUCT_CODE: '[offer] PRESELECT_PRODUCT_CODE',
    CLEAR_PRESELECTED_OFFERS: '[offer] CLEAR_PRESELECTED_OFFERS',
    UPDATE_OFFER_REQUEST: '[offer] UPDATE_OFFER_REQUEST',
    INITIALIZE: '[offer] INITIALIZE',
    LOAD_DISCOUNTS: '[offer] LOAD_DISCOUNTS',
    SET_GREEN_LINE_OF_BUSINESS: '[offer] SET_GREEN_PRODUCT',
    TOGGLE_GREEN_LINE_OF_BUSINESS: '[offer] TOGGLE_GREEN_PRODUCT',
    SET_ULOB_CODE: '[offer] SET_ULOB_CODE',
    CHANGE_REQUEST_ZIP_CODE: '[offer] CHANGE_REQUEST_ZIP_CODE',
    REMOVE_INVITATION_CODE: '[offer] REMOVE_INVITATION_CODE',
    TOGGLE_MINIMUM_USAGE: '[offer] TOGGLE_MINIMUM_USAGE',
    SET_CAMPAIGN_INFO: '[offer] SET_CAMPAIGN_INFO',

    LOAD_INVITATION_DETAILS: '[offer] LOAD_INVITATION_DETAILS // REQUEST',
    LOAD_INVITATION_DETAILS_SUCCESS: '[offer] LOAD_INVITATION_DETAILS // SUCCESS',
    LOAD_INVITATION_DETAILS_FAIL: '[offer] LOAD_INVITATION_DETAILS // FAIL',

    LOAD_MUNICIPALITY: '[offer] LOAD_MUNICIPALITY // REQUEST',
    LOAD_MUNICIPALITY_SUCCESS: '[offer] LOAD_MUNICIPALITY // SUCCESS',
    LOAD_MUNICIPALITY_FAIL: '[offer] LOAD_MUNICIPALITY // FAIL',

    SUBMIT_OFFER_REQUEST_FORM: '[offer] SUBMIT_OFFER_REQUEST_FORM',
    SELECT_OFFER_ACCOUNT_TYPE: '[offer] SELECT_ACCOUNT_TYPE',
    APPLY_CAMPAIGN_CODE: '[offer] APPLY_CAMPAIGN_CODE',
    REMOVE_CAMPAIGN_CODE: '[offer] REMOVE_CAMPAIGN_CODE',

    GO_TO_CHECKOUT_WITH_SELECTED_OFFER: '[offer] GO_TO_CHECKOUT_WITH_SELECTED_OFFER',
}

export const offersActions = {
    loadOffers: createAction<LoadOffersRequest>(offersActionTypes.LOAD_OFFERS),
    loadOffersSuccess: createAction<ReadonlyArray<OfferModel>>(offersActionTypes.LOAD_OFFERS_SUCCESS),
    loadOffersFail: createAction<string>(offersActionTypes.LOAD_OFFERS_FAIL),
    selectOffer: createAction<OfferModel>(offersActionTypes.SELECT_OFFER),
    unselectOffer: createAction<OfferModel>(offersActionTypes.UNSELECT_OFFER),
    preselectOfferIds: createAction<ReadonlyArray<number>>(offersActionTypes.PRESELECT_OFFER_IDS),
    preselectProductCode: createAction<string>(offersActionTypes.PRESELECT_PRODUCT_CODE),
    clearPreselectedOffers: createAction(offersActionTypes.CLEAR_PRESELECTED_OFFERS),
    setGreenLineOfBusiness: createAction<UpdateGreenLineOfBusinessRequest>(offersActionTypes.SET_GREEN_LINE_OF_BUSINESS),
    toggleGreenLineOfBusiness: createAction<LineOfBusinessCode>(offersActionTypes.TOGGLE_GREEN_LINE_OF_BUSINESS),
    setUlobCode: createAction<UpdateUlobCodeRequest>(offersActionTypes.SET_ULOB_CODE),
    updateOfferRequest: createAction<Partial<OfferRequestState>>(offersActionTypes.UPDATE_OFFER_REQUEST),
    initialize: createAction<OfferInitializationModel>(offersActionTypes.INITIALIZE),
    loadDiscounts: createAction(offersActionTypes.LOAD_DISCOUNTS),
    changeRequestZipCode: createAction<string>(offersActionTypes.CHANGE_REQUEST_ZIP_CODE),
    removeInvitationCode: createAction(offersActionTypes.REMOVE_INVITATION_CODE),
    loadInvitationDetails: createAction<string>(offersActionTypes.LOAD_INVITATION_DETAILS),
    loadInvitationDetailsSuccess: createAction<InvitationSuccess>(offersActionTypes.LOAD_INVITATION_DETAILS_SUCCESS),
    loadInvitationDetailsFail: createAction<InvitationError>(offersActionTypes.LOAD_INVITATION_DETAILS_FAIL),
    loadMunicipality: createAction<string>(offersActionTypes.LOAD_MUNICIPALITY),
    loadMunicipalitySuccess: createAction<Municipality>(offersActionTypes.LOAD_MUNICIPALITY_SUCCESS),
    loadMunicipalityFail: createAction(offersActionTypes.LOAD_MUNICIPALITY_FAIL),
    submitOfferRequestForm: createAction<OfferRequestSubmission>(offersActionTypes.SUBMIT_OFFER_REQUEST_FORM),
    selectOfferAccountType: createAction<OfferAccountType>(offersActionTypes.SELECT_OFFER_ACCOUNT_TYPE),
    applyCampaignCode: createAction<string>(offersActionTypes.APPLY_CAMPAIGN_CODE),
    removeCampaignCode: createAction(offersActionTypes.REMOVE_CAMPAIGN_CODE),
    toggleMinimumUsage: createAction(offersActionTypes.TOGGLE_MINIMUM_USAGE),
    goToCheckoutWithSelectedOffer: createAction(offersActionTypes.GO_TO_CHECKOUT_WITH_SELECTED_OFFER),
    setCampaigntInfo: createAction<PromoCodeInfo>(offersActionTypes.SET_CAMPAIGN_INFO),
}

const initialState = (): OfferState => {
    return {
        offerRequest: {
            campaignCode: apiClient.defaultCampaign,
            offerAccountType: OfferAccountType.Residential,
            isCodeExpired: false,
            isCodeInvalid: false,
        },
        ulobCodes: {},
        municipality: undefined,
        greenLineOfBusinesses: {
            [LineOfBusinessCode.Electric]: true,
            [LineOfBusinessCode.Gas]: true,
        },
        offersLoaded: false,
        offers: [],
        selectedOffers: {},
        preselectedOfferIds: [],
        preselectedProductCode: undefined,
        showMinimumUsage: false,
        campaignInfo: undefined,
    }
}

export const offersReducer = reducer<OfferState>(initialState())
    .add(enrollmentModelActionTypes.CLEAR_AFTER_SUBMIT, () => initialState())
    .add<OfferRequestState>(offersActionTypes.UPDATE_OFFER_REQUEST, (state, request) => ({
        ...state,
        offerRequest: {
            ...state.offerRequest,
            ...request,
        },
    }))
    .add<UpdateUlobCodeRequest>(offersActionTypes.SET_ULOB_CODE, (state, request) => ({
        ...state,
        ulobCodes: {
            ...state.ulobCodes,
            [request.lineOfBusinessCode]: request.ulobCode,
        },
    }))
    .add<Municipality>(offersActionTypes.LOAD_MUNICIPALITY_SUCCESS, (state, request) => ({
        ...state,
        municipality: request,
    }))
    .add<LoadOffersRequest>(offersActionTypes.LOAD_OFFERS, state => ({
        ...state,
        offersLoaded: false,
        offerRetrievalError: undefined,
    }))
    .add<ReadonlyArray<OfferModel>>(offersActionTypes.LOAD_OFFERS_SUCCESS, (state, offers) => ({
        ...state,
        offers,
        offersLoaded: true,
    }))
    .add<string>(offersActionTypes.LOAD_OFFERS_FAIL, (state, error) => ({
        ...state,
        offerRetrievalError: error,
    }))
    .add<OfferModel>(offersActionTypes.SELECT_OFFER, (state, offer) => ({
        ...state,
        selectedOffers: {
            ...state.selectedOffers,
            [offer.primaryProduct.lineOfBusinessCode]: offer.offerId,
        },
    }))
    .add<OfferModel>(offersActionTypes.UNSELECT_OFFER, (state, offer) => ({
        ...state,
        selectedOffers: {
            ...state.selectedOffers,
            [offer.primaryProduct.lineOfBusinessCode]: undefined,
        },
    }))
    .add<string>(offersActionTypes.PRESELECT_PRODUCT_CODE, (state, productCode) => ({
        ...state,
        preselectedProductCode: productCode,
    }))
    .add<ReadonlyArray<number>>(offersActionTypes.PRESELECT_OFFER_IDS, (state, offerIds) => ({
        ...state,
        preselectedOfferIds: offerIds,
    }))
    .add(offersActionTypes.CLEAR_PRESELECTED_OFFERS, state => ({
        ...state,
        preselectedOfferIds: [],
        preselectedProductCode: undefined,
    }))
    .add<UpdateGreenLineOfBusinessRequest>(offersActionTypes.SET_GREEN_LINE_OF_BUSINESS, (state, request) => ({
        ...state,
        greenLineOfBusinesses: {
            ...state.greenLineOfBusinesses,
            [request.lineOfBusinessCode]: request.isGreen,
        },
    }))
    .add<LineOfBusinessCode>(offersActionTypes.TOGGLE_GREEN_LINE_OF_BUSINESS, (state, lineOfBusinessCode) => ({
        ...state,
        greenLineOfBusinesses: {
            ...state.greenLineOfBusinesses,
            [lineOfBusinessCode]: !state.greenLineOfBusinesses[lineOfBusinessCode],
        },
    }))
    .add<string>(offersActionTypes.CHANGE_REQUEST_ZIP_CODE, (state, zipCode) => ({
        ...state,
        selectedOffers: state.offerRequest.zipCode === zipCode ? state.selectedOffers : {},
        offerRequest: {
            ...state.offerRequest,
            zipCode,
        },
    }))
    .add(enrollmentModelActionTypes.CLEAR_SERVICE_ADDRESS, state => ({
        ...state,
        offerRequest: {
            ...state.offerRequest,
            addressHash: undefined,
        },
    }))
    .add<UpdateServiceAddressRequest>(enrollmentModelActionTypes.UPDATE_SERVICE_ADDRESS, (state, { address }) => ({
        ...state,
        offerRequest: {
            ...state.offerRequest,
            zipCode: address.zipCode,
            addressHash: address.addressHash,
        },
        selectedOffers: state.offerRequest.zipCode === address.zipCode ? state.selectedOffers : {},
        municipality: {
            city: address.city,
            state: address.state,
        },
    }))
    .add(offersActionTypes.REMOVE_INVITATION_CODE, state => ({
        ...state,
        offerRequest: {
            ...initialState().offerRequest,
            zipCode: state.offerRequest.zipCode,
        },
    }))
    .add(offersActionTypes.LOAD_INVITATION_DETAILS, state => ({
        ...state,
        offerRequest: {
            ...state.offerRequest,
            isLoading: true,
        },
    }))
    .add<InvitationSuccess>(offersActionTypes.LOAD_INVITATION_DETAILS_SUCCESS, (state, successResponse) => ({
        ...state,
        selectedOffers: !successResponse.zipCode || successResponse.zipCode === state.offerRequest.zipCode ? state.selectedOffers : {},
        offerRequest: {
            ...initialState().offerRequest,
            isLoading: false,
            ...successResponse,
            zipCode: successResponse.zipCode || state.offerRequest.zipCode,
        },
    }))
    .add<InvitationError>(offersActionTypes.LOAD_INVITATION_DETAILS_FAIL, (state, failResponse) => ({
        ...state,
        selectedOffers: !failResponse.zipCode || failResponse.zipCode === state.offerRequest.zipCode ? state.selectedOffers : {},
        offerRequest: {
            ...initialState().offerRequest,
            isLoading: false,
            ...failResponse,
            zipCode: failResponse.zipCode || state.offerRequest.zipCode,
        },
    }))
    .add<OfferAccountType>(offersActionTypes.SELECT_OFFER_ACCOUNT_TYPE, (state, offerAccountType) => ({
        ...state,
        offerRequest: {
            ...state.offerRequest,
            offerAccountType,
        },
    }))
    .add<string>(offersActionTypes.APPLY_CAMPAIGN_CODE, (state, campaignCode) => ({
        ...state,
        offerRequest: {
            ...initialState().offerRequest,
            offerIdFromMarketingSite: state.offerRequest.offerIdFromMarketingSite,
            zipCode: state.offerRequest.zipCode,
            offerRequestTimestamp: state.offerRequest.offerRequestTimestamp,
            companyName: state.offerRequest.companyName,
            campaignCode,
        },
    }))
    .add<string>(offersActionTypes.REMOVE_CAMPAIGN_CODE, state => ({
        ...state,
        offerRequest: {
            ...initialState().offerRequest,
            companyName: state.offerRequest.companyName,
            zipCode: state.offerRequest.zipCode,
        },
    }))
    .add<boolean>(offersActionTypes.TOGGLE_MINIMUM_USAGE, state => ({
        ...state,
        showMinimumUsage: !state.showMinimumUsage,
    }))
    .add<PromoCodeInfo>(offersActionTypes.SET_CAMPAIGN_INFO, (state, campaignInfo) => ({
        ...state,
        campaignInfo,
    }))
    .build()

export class OfferSelectors {
    public static readonly selectOfferRequest = (state: CommonReduxState) => state.enrollment.offer.offerRequest
    public static readonly selectOfferRequestTimestamp = (state: CommonReduxState) => state.enrollment.offer.offerRequest.offerRequestTimestamp
    public static readonly selectOffersLoaded = (state: CommonReduxState) => state.enrollment.offer.offersLoaded
    public static readonly selectOffers = (state: CommonReduxState): ReadonlyArray<OfferModel> => state.enrollment.offer.offers
    public static readonly selectOffersByLob = (state: CommonReduxState): Record<LineOfBusinessCode, ReadonlyArray<OfferModel>> => {
        const emptyOfferModel: Record<LineOfBusinessCode, ReadonlyArray<OfferModel>> = {
            GAS: [],
            ELECTRIC: [],
            HOMEWARRANTY: [],
            COMMERCIAL: [],
            COMMUNITYSOLAR: [],
        }

        return state.enrollment.offer.offers.reduce((acc, offer) => {
            const lob = offer.primaryProduct.lineOfBusinessCode
            if (lob) {
                acc[offer.primaryProduct.lineOfBusinessCode] = [...asArray(acc[offer.primaryProduct.lineOfBusinessCode]), offer]
            }

            return acc
        }, emptyOfferModel)
    }
    public static readonly selectSelectedOffers = (state: CommonReduxState): ReadonlyArray<OfferModel> =>
        OfferSelectors.selectOffers(state).filter(o => OfferSelectors.selectIsOfferSelected(state)(o))
    public static readonly selectIsOfferSelected = (state: CommonReduxState) => (offer: OfferModel): boolean =>
        state.enrollment.offer.selectedOffers[offer.primaryProduct.lineOfBusinessCode] === offer.offerId
    public static readonly selectPreselectedOffers = (state: CommonReduxState) => {
        const { offers, preselectedProductCode, preselectedOfferIds } = state.enrollment.offer
        return offers.filter(
            o =>
                (preselectedProductCode && preselectedProductCode === o.primaryProduct.productCode) ||
                (preselectedOfferIds && preselectedOfferIds.includes(o.offerId)),
        )
    }
    public static readonly selectSelectedProductCode = (state: CommonReduxState) => state.enrollment.offer.preselectedProductCode
    public static readonly selectCampaignCode = (state: CommonReduxState) => OfferSelectors.selectOfferRequest(state).campaignCode
    public static readonly selectGreenProduct = (state: CommonReduxState) => state.enrollment.offer.greenLineOfBusinesses
    public static readonly selectZipCode = (state: CommonReduxState) => OfferSelectors.selectOfferRequest(state).zipCode
    public static readonly selectZipCodeRequired = (state: CommonReduxState) =>
        (OfferSelectors.selectCodeEnteredByCustomer(state) || OfferSelectors.selectInvitationCode(state)) &&
        !OfferSelectors.selectIsCodeExpired(state) &&
        !OfferSelectors.selectIsCodeNotFound(state) &&
        !OfferSelectors.selectOfferRequest(state).zipCode
    public static readonly selectOfferAccountType = (state: CommonReduxState) =>
        OfferSelectors.selectOfferRequest(state).offerAccountType || OfferAccountType.Residential
    public static readonly selectInvitationCode = (state: CommonReduxState) => OfferSelectors.selectOfferRequest(state).invitationCode
    public static readonly selectCodeEnteredByCustomer = (state: CommonReduxState) => OfferSelectors.selectOfferRequest(state).codeEnteredByCustomer
    public static readonly selectIsCodeExpired = (state: CommonReduxState) => OfferSelectors.selectOfferRequest(state).isCodeExpired || false
    public static readonly selectIsCodeNotFound = (state: CommonReduxState) => !!OfferSelectors.selectOfferRequest(state).isCodeInvalid
    public static readonly selectInvitationValid = (state: CommonReduxState): boolean => {
        const offerRequest = OfferSelectors.selectOfferRequest(state)
        return !!offerRequest.invitationCode && !offerRequest.isCodeInvalid && !offerRequest.isCodeExpired
    }
    public static readonly selectOfferRetrievalError = (state: CommonReduxState) => state.enrollment.offer.offerRetrievalError
    public static readonly selectNoOffersFound = (state: CommonReduxState) =>
        (state.enrollment.offer.offersLoaded && !OfferSelectors.selectOffers(state).length) || !OfferSelectors.selectOfferRequest(state).zipCode
    public static readonly selectCity = (state: CommonReduxState) => state.enrollment.offer.municipality && state.enrollment.offer.municipality.city
    public static readonly selectStateCode = (state: CommonReduxState) => state.enrollment.offer.municipality && state.enrollment.offer.municipality.state
    public static readonly selectUlob = (state: CommonReduxState, lineOfBusinessCode: LineOfBusinessCode) => {
        const { ulobCodes } = state.enrollment.offer
        const ulobCode = ulobCodes && ulobCodes[lineOfBusinessCode]
        if (!ulobCode) {
            return undefined
        }
        const offers = OfferSelectors.selectOffers(state)
        const ulobs = offers.map(o => o.primaryProduct.ulob)
        return ulobs.find(u => !!u && u.utilityLineOfBusinessCode === ulobCode)
    }
    public static readonly selectShowMinimumUsage = (state: CommonReduxState) => state.enrollment.offer.showMinimumUsage
    public static readonly selectCampaignInfo = (state: CommonReduxState) => state.enrollment.offer.campaignInfo
}

const sagas = {
    *initialize(action: { readonly payload: OfferInitializationModel }) {
        const { offerAccountType, campaignCode, invitationCode, isGreen, offerIds, productCode, zipCode } = action.payload

        if (offerIds.length) {
            yield put(offersActions.preselectOfferIds(offerIds))
        }
        if (productCode) {
            yield put(offersActions.preselectProductCode(productCode))
        }
        yield call(sagas.applyPreselectedOffers)

        if (isGreen) {
            yield put(offersActions.setGreenLineOfBusiness({ lineOfBusinessCode: LineOfBusinessCode.Electric, isGreen: true }))
            yield put(offersActions.setGreenLineOfBusiness({ lineOfBusinessCode: LineOfBusinessCode.Gas, isGreen: true }))
        }

        if (offerAccountType) {
            yield put(offersActions.selectOfferAccountType(offerAccountType))
        }

        if (zipCode) {
            yield put(offersActions.changeRequestZipCode(zipCode))
        }

        if (campaignCode) {
            yield put(offersActions.applyCampaignCode(campaignCode))
        }

        if (invitationCode) {
            yield put(offersActions.loadInvitationDetails(invitationCode))
        }
    },
    *loadOffers(action: { readonly payload: LoadOffersRequest }) {
        const request = action.payload
        const offerRequest: OfferRequestState = yield select(OfferSelectors.selectOfferRequest)
        const selectedOffers: ReadonlyArray<OfferModel> = yield select(OfferSelectors.selectSelectedOffers)
        const company = selectCompany(request.companyName || '')
        let offers: ReadonlyArray<OfferModel> = []
        fireCustomFullStoryEvent(FsEvent.offerSearch, { zipCode: request.zipCode })
        try {
            offers = yield getOffers(
                request.zipCode,
                request.offerAccountType,
                request.campaignCode,
                request.invitationCode,
                request.offerId,
                request.priceEffectiveDate,
                request.ignoreCache ?? false,
                undefined,
                request.distributionZone,
                request.showGlobalLoader,
            )
        } catch (e) {
            yield put(offersActions.loadOffersFail('We did not find any products for the information you entered'))
        }
        if (offers.length) {
            if (company.enrollment.loadDiscountAsSeparateCall) {
                const discountedOffers = yield applyDiscounts(offers, selectedOffers, offerRequest)
                yield put(offersActions.loadOffersSuccess(discountedOffers))
            } else {
                yield put(offersActions.loadOffersSuccess(offers))
            }
        } else {
            fireCustomFullStoryEvent(FsEvent.noOffers, { zip: request.zipCode })
            yield put(offersActions.loadOffersSuccess([]))
        }
        const offerRequestTimestamp = Date.now()
        yield put(
            offersActions.updateOfferRequest({
                ...request,
                zipCode: request.zipCode,
                offerAccountType: request.offerAccountType,
                campaignCode: request.campaignCode,
                offerRequestTimestamp,
                offerIdFromMarketingSite: request.offerIdFromMarketingSite,
            }),
        )
    },
    *applyPreselectedOffers() {
        const preselectedOffers: ReadonlyArray<OfferModel> = yield select(OfferSelectors.selectPreselectedOffers)
        if (!!preselectedOffers.length) {
            for (const offer of preselectedOffers) {
                yield put(offersActions.selectOffer(offer))
            }
            yield put(offersActions.clearPreselectedOffers())
        }
    },
    *selectOffer(action: { readonly payload: OfferModel }) {
        const offerRequest: OfferRequestState = yield select(OfferSelectors.selectOfferRequest)
        const companyName = offerRequest.companyName || ''
        const selectedOffer = action.payload
        const { offerId, primaryProduct } = selectedOffer
        const { lineOfBusinessCode } = primaryProduct

        yield put(enrollmentModelActions.updateShoppingCartItem({ lineOfBusinessCode, offerId }))
        yield put(offersActions.loadDiscounts(companyName))
    },

    *unselectOffer(action: { readonly payload: OfferModel }) {
        const offerRequest: OfferRequestState = yield select(OfferSelectors.selectOfferRequest)
        const companyName = offerRequest.companyName || ''

        const offer: OfferModel = action.payload
        yield put(
            enrollmentModelActions.updateShoppingCartItem({
                lineOfBusinessCode: offer.primaryProduct.lineOfBusinessCode,
                offerId: null,
            }),
        )
        yield put(offersActions.loadDiscounts(companyName))
    },
    *setGreenProduct(action: { readonly payload: LineOfBusinessCode }) {
        const lineOfBusinessCode = action.payload

        const isGreen = yield select(OfferSelectors.selectGreenProduct)
        const selectedOffers: ReadonlyArray<OfferModel> = yield select(OfferSelectors.selectSelectedOffers)
        const lobOffer = selectedOffers.find(o => o.primaryProduct.lineOfBusinessCode === lineOfBusinessCode)

        if (lobOffer && lobOffer.primaryProduct.isGreen !== isGreen) {
            yield put(offersActions.unselectOffer(lobOffer))
        }
    },
    *loadDiscounts(action: { readonly payload: string }) {
        const companyName = action.payload
        const company = selectCompany(companyName)
        if (company.enrollment.loadDiscountAsSeparateCall) {
            const originalOffers = yield select(OfferSelectors.selectOffers)
            const selectedOffers = yield select(OfferSelectors.selectSelectedOffers)
            const offerRequest: OfferRequestState = yield select(OfferSelectors.selectOfferRequest)
            const discountedOffers = yield applyDiscounts(originalOffers, selectedOffers, offerRequest)
            yield put(offersActions.loadOffersSuccess(discountedOffers))
        }
    },
    *loadMunicipality(action: { readonly payload: string }) {
        const zipCode = action.payload

        try {
            const cityState: Municipality | null = yield geocodeApiClient.getCityState(zipCode)
            if (cityState) {
                yield put(offersActions.loadMunicipalitySuccess(cityState))
            }
        } catch (e) {
            yield put(offersActions.loadMunicipalityFail())
        }
    },
    *loadInvitationDetails(action: { readonly payload: string }) {
        const code = action.payload
        const response: InvitationCodeDetailsResponse | null = yield invitationCodeApiClient.getInvitationCodeDetails(code)
        if (response) {
            const { campaignCode, zipCode, isInvitationCode, isExpired, isValid } = response
            if (isExpired || !isValid) {
                yield put(offersActions.loadInvitationDetailsFail({ codeEnteredByCustomer: code, isCodeExpired: isExpired, isCodeInvalid: !isValid, zipCode }))
                return
            }

            const successResponse: InvitationSuccess = {
                invitationCode: isInvitationCode ? code : undefined,
                codeEnteredByCustomer: code,
                campaignCode: campaignCode || undefined,
                zipCode: zipCode || undefined,
            }
            yield put(offersActions.loadInvitationDetailsSuccess(successResponse))
        }
    },
    *submitOfferRequestForm(action: { readonly payload: OfferRequestSubmission }) {
        const { zipOrInviteCode, goToEnrollment, goToProductSelection, isSmallCommercial } = action.payload
        const isZip = isZipCode(zipOrInviteCode)
        if (isZip) {
            yield put(offersActions.changeRequestZipCode(zipOrInviteCode))
            yield put(enrollmentModelActions.clearServiceAddress())
        } else {
            yield put(offersActions.loadInvitationDetails(zipOrInviteCode))

            yield take([offersActionTypes.LOAD_INVITATION_DETAILS_SUCCESS, offersActionTypes.LOAD_INVITATION_DETAILS_FAIL])
            const zipCode: string | undefined = yield select(OfferSelectors.selectZipCode)

            if (zipCode) {
                yield put(enrollmentModelActions.clearServiceAddress())
            }
        }
        if (!!window.IgsReduxPersistor) {
            window.IgsReduxPersistor.flush()
        }
        if (goToEnrollment) {
            const navigationSuccessful = yield sagas.attemptGoToCampaignPage()
            if (navigationSuccessful) {
                return
            }

            yield call(goToEnrollment)
        } else if (goToProductSelection) {
            const zipCode: string | undefined = yield select(OfferSelectors.selectZipCode)
            const campaignCode: string | undefined = yield select(OfferSelectors.selectCampaignCode)
            if (zipCode && isZip) {
                goToProductSelection(campaignCode, isSmallCommercial, zipCode)
            }
        }
    },
    *getCampaignPageUrl(campaignCode: string) {
        const campaignPageUrl = `/landing-page/${campaignCode}`
        const campaignPageExists = yield invitationCodeApiClient.getPageExists(campaignPageUrl)
        return campaignPageExists ? campaignPageUrl : undefined
    },
    *attemptGoToCampaignPage() {
        const campaignCode: string | undefined = yield select(OfferSelectors.selectCampaignCode)
        if (campaignCode) {
            const campaignPageUrl = yield sagas.getCampaignPageUrl(campaignCode)
            if (campaignPageUrl) {
                yield call(() => buildUrl(campaignPageUrl).go())
                return true
            }
        }
        return false
    },
    *setMinimumUsage(showMinimumUsage: boolean) {
        const selectedOffers: ReadonlyArray<OfferModel> = yield select(OfferSelectors.selectSelectedOffers)

        const selectedLobOffer: OfferModel | undefined = selectedOffers.find(o => o.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.Electric)

        const selectedHasMinimumUsage = selectedLobOffer && !!selectedLobOffer.primaryProduct.monthlyCharge

        if (!!selectedHasMinimumUsage && selectedHasMinimumUsage !== showMinimumUsage) {
            yield put(offersActions.unselectOffer(selectedLobOffer))
        }
    },
    *goToCheckoutWithSelectedOffer(action: { readonly payload: OfferModel }) {
        const offerModel = action.payload

        yield put(offersActions.selectOffer(offerModel))
        if (!!window.IgsReduxPersistor) {
            window.IgsReduxPersistor.flush()
        }
        enrollmentRoutes.checkoutUrl().go()
    },
}

async function applyDiscounts(offers: ReadonlyArray<OfferModel>, selectedOffers: ReadonlyArray<OfferModel>, offerRequest: OfferRequestState) {
    const addressHash = offerRequest.addressHash
    let discountedOffers: ReadonlyArray<OfferModel>
    try {
        discountedOffers = await getAndApplyDiscounts(offers, selectedOffers, addressHash)
        return discountedOffers
    } catch (e) {
        console.error('There was a problem getting discounts', e)
        return offers
    }
}

export function* offersSaga() {
    yield all([
        takeLatest(offersActionTypes.INITIALIZE, sagas.initialize as any),
        takeLatest(offersActionTypes.LOAD_OFFERS, sagas.loadOffers as any),
        takeLatest(offersActionTypes.LOAD_OFFERS_SUCCESS, sagas.applyPreselectedOffers),
        takeLatest(offersActionTypes.SELECT_OFFER, sagas.selectOffer as any),
        takeLatest(offersActionTypes.UNSELECT_OFFER, sagas.unselectOffer as any),
        takeLatest(offersActionTypes.LOAD_DISCOUNTS, sagas.loadDiscounts as any),
        takeLatest(offersActionTypes.SET_GREEN_LINE_OF_BUSINESS, sagas.setGreenProduct as any),
        takeLatest(offersActionTypes.TOGGLE_GREEN_LINE_OF_BUSINESS, sagas.setGreenProduct as any),
        takeLatest(offersActionTypes.LOAD_MUNICIPALITY, sagas.loadMunicipality as any),
        takeLatest(offersActionTypes.LOAD_INVITATION_DETAILS, sagas.loadInvitationDetails as any),
        takeLatest(offersActionTypes.SUBMIT_OFFER_REQUEST_FORM, sagas.submitOfferRequestForm as any),
        takeLatest(offersActionTypes.CHANGE_REQUEST_ZIP_CODE, sagas.loadMunicipality as any),
        takeLatest(offersActionTypes.TOGGLE_MINIMUM_USAGE, sagas.setMinimumUsage as any),
        takeLatest(offersActionTypes.GO_TO_CHECKOUT_WITH_SELECTED_OFFER, sagas.goToCheckoutWithSelectedOffer as any),
    ])
}

export interface OfferState {
    readonly offersLoaded: boolean
    readonly greenLineOfBusinesses: GreenLineOfBusinesses
    readonly municipality?: Municipality
    readonly ulobCodes: {
        readonly [LineOfBusinessCode.Gas]?: string
        readonly [LineOfBusinessCode.Electric]?: string
    }
    readonly offerRequest: OfferRequestState
    readonly offers: ReadonlyArray<OfferModel>
    readonly selectedOffers: {
        readonly [lineOfBusinessCode: string]: number | undefined
    }
    readonly preselectedOfferIds: ReadonlyArray<number>
    readonly preselectedProductCode?: string
    readonly offerRetrievalError?: string
    readonly showMinimumUsage: boolean
    readonly campaignInfo?: PromoCodeInfo
}

export interface OfferInitializationModel {
    readonly zipCode?: string
    readonly invitationCode?: string
    readonly campaignCode?: string
    readonly offerAccountType?: OfferAccountType
    readonly offerIds: ReadonlyArray<number>
    readonly isGreen: boolean
    readonly productCode?: string
}

export interface OfferRequestState {
    readonly zipCode?: string
    readonly invitationCode?: string
    readonly campaignCode?: string
    readonly campaignText?: string
    readonly hideCampaign?: boolean
    readonly codeEnteredByCustomer?: string
    readonly offerAccountType?: OfferAccountType
    readonly addressHash?: string
    readonly isCodeExpired?: boolean
    readonly isCodeInvalid?: boolean
    readonly companyName?: string
    readonly isLoading?: boolean
    readonly offerRequestTimestamp?: number
    readonly offerIdFromMarketingSite?: number
}

interface UpdateUlobCodeRequest {
    readonly lineOfBusinessCode: LineOfBusinessCode
    readonly ulobCode: string
}

interface UpdateGreenLineOfBusinessRequest {
    readonly lineOfBusinessCode: LineOfBusinessCode
    readonly isGreen: boolean
}

export interface LoadOffersRequest {
    readonly zipCode: string
    readonly offerAccountType: OfferAccountType
    readonly campaignCode?: string
    readonly invitationCode?: string
    readonly companyName?: string
    readonly offerId?: string
    readonly priceEffectiveDate?: Date
    readonly ignoreCache?: boolean
    readonly offerIdFromMarketingSite?: number
    readonly distributionZone?: string
    readonly showGlobalLoader?: boolean
}

interface OfferRequestSubmission {
    readonly zipOrInviteCode: string
    readonly goToEnrollment?: () => void
    readonly goToProductSelection?: (campaignCode?, isSmallCommercial?, zipCode?) => void
    readonly isSmallCommercial?: boolean
}

interface InvitationSuccess {
    readonly zipCode?: string
    readonly campaignCode?: string
    readonly invitationCode?: string
    readonly codeEnteredByCustomer?: string
}

interface InvitationError {
    readonly codeEnteredByCustomer: string
    readonly isCodeExpired: boolean
    readonly isCodeInvalid: boolean
    readonly zipCode?: string
}
