import * as React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CheckoutStepPageIds } from '@igs-web/common-components/domain/checkout/checkout-step-page-ids'
import { useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { useWizardState } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'

import * as Routes from 'domain/routes'
import { ExternalHwSignUp } from 'domain/routes/external-routes'

import { HwEnrollmentType } from '../hw-checkout/hw-checkout'

import { AddressCard } from './address-card'
import { MyDetailsCard } from './my-details-card'
import { RateCard } from './rate-card'
import { hwShowAddressCard, hwShowMyDetailsCard } from './your-plan-service'

export const YourPlanHW = ({ hwEnrollmentType }: YourPlanProps) => {
    const { externalWebsite } = useCompany()
    const enrollmentState = useCheckoutState()
    const { activePageId } = useWizardState()
    const history = useHistory()
    const selectedOffers = useSelector((store: CommonReduxState) => OfferSelectors.selectSelectedOffers(store))
    const primaryProduct = selectedOffers[0]?.primaryProduct
    const showRateCard = selectedOffers.length > 0
    const hideMyDetailsEditButton =
        activePageId === CheckoutStepPageIds.ContactInfo ||
        activePageId === CheckoutStepPageIds.CustomerAddress ||
        hwEnrollmentType !== HwEnrollmentType.standard

    const offerRequest = useSelector((store: CommonReduxState) => OfferSelectors.selectOfferRequest(store))

    const editButtonAction = () => {
        if (hwEnrollmentType === HwEnrollmentType.userAddress || hwEnrollmentType === HwEnrollmentType.userZip) {
            history.push(Routes.HwProductSelection)
        } else {
            window.location.href = ExternalHwSignUp(externalWebsite)
        }
    }

    return (
        <aside>
            <IconHeading>Your Plan</IconHeading>
            {showRateCard && (
                <RateCard primaryProduct={primaryProduct} offerRequest={offerRequest} hideEditButton={false} editButtonAction={editButtonAction} />
            )}
            {hwShowAddressCard(activePageId) && (
                <AddressCard
                    serviceAddress={enrollmentState.serviceAddress}
                    hideEditButton={activePageId === CheckoutStepPageIds.CustomerAddress || hwEnrollmentType === HwEnrollmentType.userAddress}
                />
            )}
            {hwShowMyDetailsCard(activePageId) && (
                <MyDetailsCard enrollment={enrollmentState} supressBillingAccountDisplay={true} hideEditButton={hideMyDetailsEditButton} />
            )}
        </aside>
    )
}

interface YourPlanProps {
    readonly hwEnrollmentType: HwEnrollmentType
}
