import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import { parseISO } from 'date-fns'
import { get } from 'lodash-es'
import styled from 'styled-components'

import { useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { PhoneType } from '@igs-web/common-models/constants/phone'
import { ElementWithTooltip } from '@igs-web/common-ui-components/_atoms/tooltips/tool-tip'
import { StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { Input, LegalCheckBox } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { InfoIcon } from '@igs-web/common-ui-components/_molecules/info-text'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { CreditCheckStatus } from '@igs-web/common-utilities/api/enrollment-api-client'
import { addDays, dateFormats, dateToInputTypeDateString, formatDate, isWeekendDay } from '@igs-web/common-utilities/utilities/date-utilities'
import { InputHelperText } from '@igs/react-styled-components'

const DATEVALIDAFTER = 1
const DATEVALIDBEFORE = 60

//min height fixes ios issue at odd non-standard size--BF
const StyledDatePicker = styled(Input)`
    width: fit-content;
    min-height: 3.5rem;
    min-width: 50%;
`
const StyledIsEnrolledInKeepMeInformedToolTip = styled(ElementWithTooltip)`
    display: inline-block;
    margin-left: ${Spacing.ExtraSmall};
    .tooltip-text {
        top: ${Spacing.X5Large};
    }
`

export const NewServiceStartDate = () => {
    const enrollmentState = useCheckoutState()
    const formMethods = useFormContext<StartDateFields>()
    const { requestedStartDate } = enrollmentState
    const todaysDate = new Date(new Date().setHours(0, 0, 0, 0))
    const minDate = addDays(todaysDate, DATEVALIDAFTER)
    const maxDate = addDays(todaysDate, DATEVALIDBEFORE)
    const {
        register,
        unregister,
        trigger,
        formState: { errors },
    } = formMethods

    React.useEffect(() => {
        return () => {
            unregister('requestedStartDate')
            unregister('confirmStartDateTerms')
            unregister('technicianPermission')
        }
    }, [])

    const confirmStartDateTermsFieldError = get(errors, 'confirmStartDateTerms.message')
    const technicianPermissionFieldError = get(errors, 'technicianPermission.message')

    return (
        <>
            <StyledDatePicker
                {...register('requestedStartDate', {
                    required: 'Required',
                    shouldUnregister: true,
                    validate: {
                        isWeekDay: value => !isWeekendDay(parseISO(value.toString())) || 'Please select a week day',
                        isAfter: value =>
                            minDate.getTime() <= parseISO(value.toString()).getTime() ||
                            `The date selected is not available. Please select date after ${formatDate(
                                addDays(minDate, -1),
                                dateFormats.slashSeparatedMMDDYYYY,
                            )}.`,
                        isBefore: value =>
                            maxDate.getTime() >= parseISO(value.toString()).getTime() ||
                            `The date selected is not available. Please select date before ${formatDate(
                                addDays(maxDate, 1),
                                dateFormats.slashSeparatedMMDDYYYY,
                            )}.`,
                    },
                })}
                defaultValue={requestedStartDate ? formatDate(requestedStartDate, dateFormats.dashSeparatedyyyyMMdd) : ''}
                min={dateToInputTypeDateString(addDays(todaysDate, DATEVALIDAFTER))}
                max={dateToInputTypeDateString(addDays(todaysDate, DATEVALIDBEFORE))}
                label="Requested Start Date"
                dataTestId="requested-start-date-selector"
                hideHelperText={!errors.requestedStartDate}
                error={errors.requestedStartDate?.message}
                type="date"
                onBlur={() => trigger('requestedStartDate')}
            />

            <div>
                We cannot guarantee this date. Technicians are not available on weekends or holidays. We will contact you if your requested date cannot be
                accommodated.
            </div>

            {enrollmentState.defaultContact.phoneType === PhoneType.Cell && (
                <div>
                    <LegalCheckBox
                        {...register('isEnrolledInKeepMeInformed')}
                        defaultChecked={true}
                        dataTestId="keep-me-informed-check-box"
                        label={
                            <p>
                                I agree to receive text notifications from Atlanta Gas Light Company regarding my service order.
                                <StyledIsEnrolledInKeepMeInformedToolTip
                                    defaultTooltipWidth={600}
                                    tooltipContents={
                                        <>
                                            <p>
                                                You will receive a confirmation text when your order is processed, a reminder the day before your appointment,
                                                as well as a notification text when the Field Technician is enroute to your location.
                                            </p>
                                            <p>
                                                Message and data rates may apply. To stop receiving text notifications regarding your order, follow the
                                                instructions provided in the text.
                                            </p>
                                        </>
                                    }
                                >
                                    <InfoIcon />
                                </StyledIsEnrolledInKeepMeInformedToolTip>
                            </p>
                        }
                    />
                </div>
            )}

            {enrollmentState.creditCheckResults?.energyScoreStatus === CreditCheckStatus.Collect && (
                <StrongText>The security deposit must be paid before your request for service can be scheduled.</StrongText>
            )}
            <StrongText>Before the technician arrives:</StrongText>
            <div>
                <LegalCheckBox
                    {...register('confirmStartDateTerms', { required: 'Required', shouldUnregister: true })}
                    defaultChecked={enrollmentState.confirmStartDateTerms}
                    dataTestId="confirm-start-date-terms"
                    label="My water and electricity will be turned on, my meter and appliances will be accessible, and my pets will be secured."
                    $error={!!confirmStartDateTermsFieldError}
                />
                <InputHelperText error={confirmStartDateTermsFieldError} currentLength={0} />
            </div>
            <div>
                <LegalCheckBox
                    {...register('technicianPermission', { required: 'Required', shouldUnregister: true })}
                    defaultChecked={enrollmentState.technicianPermission}
                    dataTestId="technician-permission"
                    label={
                        <>
                            An AGL technician may need to enter this property to complete the request.
                            <b> An adult (age 18 or older) must be present at the time of service.</b>
                        </>
                    }
                    $error={!!technicianPermissionFieldError}
                />
                <InputHelperText error={technicianPermissionFieldError} currentLength={0} />
            </div>
        </>
    )
}

interface StartDateFields {
    readonly requestedStartDate: Date
    readonly confirmStartDateTerms: boolean
    readonly technicianPermission: boolean
    readonly isEnrolledInKeepMeInformed: boolean
}
