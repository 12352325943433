import * as React from 'react'

import styled from 'styled-components'

import { captionCss, dashboardLabelCss } from '@igs-web/common-ui-components/_atoms/typography'

import { Breakpoint } from '../styles/breakpoints'
import { Spacing } from '../styles/spacing'

export const DetailList = styled.div`
    --template-columns: 1fr 1fr;
    display: grid;
    grid-template-columns: var(--template-columns);

    align-items: start;
    grid-gap: ${Spacing.Medium};
    ${captionCss};

    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-columns: auto 1fr;
    }

    @media (max-width: ${Breakpoint.MobileSmall}) {
        grid-template-columns: 1fr;
    }
`

const DetailTitle = styled.div`
    ${dashboardLabelCss}
`

export const DetailContent = styled.div<ContentProps>`
    ${({ $large }: ContentProps) => ($large ? 'font-size: var(--default-font-size)' : captionCss)};
`
interface ContentProps {
    readonly $large: boolean
}

export const DetailListItem = ({ title, children, large = false, dataTestId }: DetailListItemProps): JSX.Element => (
    <>
        <DetailTitle>{title}</DetailTitle>
        <DetailContent data-testid={dataTestId} $large={large}>
            {children}
        </DetailContent>
    </>
)

interface DetailListItemProps {
    readonly title: string | React.ReactNode
    readonly children?: React.ReactNode
    readonly large?: boolean
    readonly dataTestId?: string
}
