import { PaymentType } from './payment-model'

export interface AccountDetail {
    readonly accountId: number
}

export interface CommodityAccountDetail {
    readonly autoPayRegistered: boolean
    readonly eBillRegistered: boolean
}

export interface HomeProtectionPaymentProfileResponse {
    readonly paymentProfile: string
    readonly description: string
    readonly accountIds: ReadonlyArray<number>
    readonly billCycle: number
    readonly isUpdateAllowed: boolean
    readonly accountBalance: number
}
export interface HomeProtectionUpdatePaymentProfileResponse {
    readonly paymentProfileReference: string
    readonly merchant: string
    readonly description: string
    readonly isSuccessful: boolean
    readonly message: string
    readonly howToFix: string
}

export interface HwBillingHistoryItem {
    readonly transactionDate: number | null | undefined
    readonly amount: number
}

export interface HomeProtectionAccountClaim {
    readonly line: string
    readonly dateOpened: number | null | undefined
    readonly coverageLimit: number
    readonly coveredCosts: number | null
    readonly contractor: string
    readonly status: string
}

export interface CoverageDetailItem {
    readonly limit: number
    readonly coverageName: string
    readonly coverageCode: string
    readonly coverageType: string
    readonly effectiveDate: number | null | undefined
    readonly footnote?: string
    readonly footnoteSymbol?: number
}

enum StatusType {
    Unknown = 'Unknown',
    Active = 'Active',
    Pending = 'Pending',
    InActive = 'Inactive',
}

export interface Contact {
    readonly firstName: string
    readonly middleInitial: string | null | undefined
    readonly lastName: string
    readonly businessName: string | null | undefined
    readonly emailAddress: string | null | undefined
    readonly phoneNumber: string | null | undefined
}

export interface UtilityAccountDetail extends AccountDetail {
    readonly isRegisteredForAutopay: boolean
    readonly isFiservAutoPayEnrolled: boolean
    readonly autopayDetails: PaymentMethodDescription | undefined
    readonly accountType: string
    readonly lineOfBusinessCode: string
    readonly accountNumber: string
    readonly contact: Contact
    readonly accountId: number
    readonly status: StatusType
    readonly allowAchPayments: boolean
    readonly allowCreditCardPayments: boolean
    readonly allowAutopayPaymentProfiles: boolean
    readonly allowOneTimePayments: boolean
}

interface CreditCardDescription {
    readonly last4: string
    readonly creditCardExpirationMonth: number
    readonly creditCardExpirationYear: number
}

interface AchDescription {
    readonly last4: string
}

export interface PaymentMethodDescription extends AchDescription, CreditCardDescription {
    readonly paymentType: PaymentType
}
