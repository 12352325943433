import { OfferAccountType } from '@igs-web/common-models/constants/account-type'
import { PriceUnit } from '@igs-web/common-models/constants/price-units'

import { LineOfBusinessCode } from './line-of-business'
import { PriceInformationModel } from './price-information-model'
import { UtilityLineOfBusinessModel } from './utility-line-of-business-model'

export interface OfferModel {
    readonly offerId: number
    readonly priceEffectiveDate?: Date
    readonly primaryProduct: ProductModel
    readonly secondaryProduct?: ProductModel | null | undefined
}

export interface ProductModel {
    readonly title: string
    readonly description: string
    readonly price: number
    readonly displayPrice: string
    readonly displayPriceUnit: PriceUnit
    readonly unitOfMeasure: string
    readonly monthlyCharge?: number
    readonly productCode: string
    readonly productType: ProductTypeCode
    readonly discountedPrice?: number | null | undefined
    readonly discountedPriceDisplay?: string | null | undefined
    readonly discountedPriceDisplayUnit?: PriceUnit
    readonly discountCode?: string | null | undefined
    readonly discountId?: number | null | undefined
    readonly discountAmount?: number | null | undefined
    readonly discountAmountDisplay?: string | null | undefined
    readonly discountAmountType?: string | null | undefined
    readonly isGreen?: boolean
    readonly isSpecialPricing?: boolean | null | undefined
    readonly isHeroProduct?: boolean | null | undefined
    readonly preventDiscounts?: boolean | null | undefined
    readonly termMonths?: number
    readonly termEndMonth?: number
    readonly accountType: OfferAccountType
    readonly hpOfferCode?: string | null | undefined
    readonly territoryCode?: string | null | undefined
    readonly lineOfBusinessCode: LineOfBusinessCode
    readonly ulob?: UtilityLineOfBusinessModel
    readonly supportedUtilities?: ReadonlyArray<UtilityLineOfBusinessModel> | null | undefined
    readonly isFriendsAndFamily: boolean
    readonly cancelFee: number
    readonly cancelFeeDescription: string
    readonly tosLink?: string
    readonly customTerms?: ReadonlyArray<string>
    readonly supplementalPriceInformation?: ReadonlyArray<PriceInformationModel>
    readonly ratePlanName?: string
    readonly overrideCampaignCode?: string
    readonly campaignCode: string
    readonly invitationCode?: string
}

export enum ProductTypeCode {
    Fixed = 'FIXED',
    Variable = 'VARBL',
}

export const ProductTypeCodeSortOrder = [ProductTypeCode.Fixed, ProductTypeCode.Variable]

export enum ProductTypeDescription {
    Fixed = 'Fixed',
    Variable = 'Variable',
}

export enum PricingTier {
    All = 'ALL',
    Group1 = 'GROUP1',
    Group2 = 'GROUP2',
}

export interface ScanaProduct {
    readonly productCode: string
    readonly fixedPrice?: number
    readonly discountedFixedPrice?: number
    readonly monthlyCharge?: number
    readonly discountedMonthlyCharge: number
    readonly cancelFeeTypeCode: string
    readonly cancelFeeAmount?: number
    readonly productSwitchFeeAmount: number
    readonly productSwitchFeeTypeCode: string
    readonly legacyContractTermId: number
    readonly legacySalesProductId: number
    readonly isGreen: boolean
    readonly termMonths: number
    readonly termEndMonth?: number
    readonly UtilityCode: string
    readonly lineOfBusinessCode: LineOfBusinessCode
    readonly adder: number
    readonly indexDescription: string
    readonly type: string
    readonly subType: string
    readonly entryDate: string
    readonly description: string
    readonly espCode: string
    readonly unitOfMeasureCode: string
    readonly preventDiscounts: boolean
    readonly productTerms: ReadonlyArray<string>
}
