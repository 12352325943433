import styled from 'styled-components'

import { Breakpoint } from '../styles/breakpoints'
import { FontWeight } from '../styles/font-weight'
import { Spacing } from '../styles/spacing'
import { fontFamilyHeader, fontSizeExtraLarge, fontSizeLarge, lightAccent } from '../styles/theme'

export const StatusBox = styled.section<StatusBoxProps>`
    background-color: ${lightAccent};
    padding: ${Spacing.Large};
    display: grid;
    gap: ${Spacing.Medium};
    grid-template-rows: min-content 1fr;
    grid-template-columns: repeat(${({ $columns }) => $columns}, 1fr);
    place-items: center start;
    width: 100%;
    text-align: left;
    header {
        grid-column: 1 / -1;
        grid-row: 1;
        font-size: ${fontSizeExtraLarge};
        font-weight: ${FontWeight.Bold};
        text-decoration: underline;
        font-family: ${fontFamilyHeader};
    }
    footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        grid-column: 1 / -1;
    }
    @media (max-width: ${Breakpoint.Tablet}) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: ${Breakpoint.Mobile}) {
        grid-template-columns: 1fr;
        place-items: left;
    }
`
interface StatusBoxProps {
    readonly $columns: number
}
export const StatusHeader = styled.div`
    text-transform: uppercase;
`
export const StatusData = styled.div`
    font-size: ${fontSizeLarge};
`
export const StatusItem = styled.div`
    @media (max-width: ${Breakpoint.Mobile}) {
        text-align: left;
    }
`
