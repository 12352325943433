export const convertToClassNames = (obj: { readonly [key: string]: any }, ...rest: string[]) => {
    const result = rest.filter(r => r) || []

    for (const key in obj) {
        if (!!obj[key]) {
            result.push(key)
        }
    }

    return result.join(' ')
}

export const addClass = (element: HTMLElement, className: string) => {
    const list = element.className.split(' ')

    if (!list.includes(className)) {
        list[list.length] = className
    }

    element.className = list.join(' ')
}

export const removeClass = (element: HTMLElement, className: string) => {
    const list = element.className.split(' ')
    const newList = list.filter(x => x !== className)

    element.className = newList.join(' ')
}
