import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { PasswordInput } from '@igs-web/common-ui-components/_atoms/form-inputs/password-input'
import { BoxedErrorAlert, BoxedSuccessAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { GridForm } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { SessionStorageVariables } from '@igs-web/common-utilities/constants/constants'
import { passwordLength } from '@igs-web/common-utilities/utilities/form-validation'
import { clearFromStorage } from '@igs-web/common-utilities/utilities/storage-utilities'

import { urlHelper } from 'root/shared-services/url-helper'

import { useCompany } from '../company/hooks/useCompany'

const clearPasswordResetKey = (): void => clearFromStorage(SessionStorageVariables.PasswordResetToken)

export const ResetPasswordForm = ({ passwordResetKey, uniqueId, onError }: Props) => {
    const [submitError, setSubmitError] = React.useState<string | undefined>(undefined)
    const history = useHistory()
    const company = useCompany()

    const [userEmail, setUserEmail] = React.useState<string | undefined>(undefined)
    const showErrors = !onError

    const {
        control,
        handleSubmit,
        watch,
        formState: { isSubmitSuccessful },
    } = useForm<ResetPasswordFormFields>()

    React.useEffect(() => {
        getUserEmailFromPasswordResetKey(passwordResetKey)
    }, [passwordResetKey])

    const getUserEmailFromPasswordResetKey = async (key: string) => {
        if (key) {
            const response = await customerInfoApiClient.getUserNameFromResetKey(key)
            if (response && response.data) {
                setUserEmail(response.data)
            }
        }
    }

    const submitForm = async (submission: ResetPasswordFormFields) => {
        setSubmitError(undefined)
        try {
            await customerInfoApiClient.updatePasswordFromReset(submission.newPassword, passwordResetKey, {
                showGlobalLoader: true,
                overrideNonUserPresentableErrors: 'This password reset link is expired.',
            })

            clearPasswordResetKey()
            if (company.features.preferRoutingOverHref) {
                const loginUrl = urlHelper.login(false, userEmail, true)
                const loginUrlAsString = loginUrl.asString()
                history.push(loginUrlAsString)
            } else {
                urlHelper.login(false, userEmail, true).go()
            }
        } catch (e) {
            console.log(e)
            if (onError) {
                onError(e.message)
            }
            setSubmitError(e.message)
        }
    }

    return (
        <GridForm onSubmit={handleSubmit(async data => await submitForm(data))}>
            <BoxedSuccessAlert show={isSubmitSuccessful} message="Password Reset Successfully" />
            {showErrors && <BoxedErrorAlert message={submitError} />}

            <PasswordInput
                control={control}
                name="newPassword"
                dataTestId={`reset-password-${uniqueId}`}
                label="New Password"
                rules={{ required: 'Required', validate: passwordLength }}
            />
            <PasswordInput
                control={control}
                name="newPasswordConfirm"
                dataTestId={`reset-password-confirm-${uniqueId}`}
                label="Confirm New Password"
                rules={{
                    required: 'Required',
                    validate: val => {
                        if (watch('newPassword') !== val) {
                            return 'Passwords must match'
                        }
                    },
                }}
            />
            <Button type="submit" id="reset-password-submit" dataTestId="reset-password-submit" className="reset-password-btn">
                Submit
            </Button>
        </GridForm>
    )
}
interface ResetPasswordFormFields {
    readonly newPassword: string
    readonly newPasswordConfirm: string
}
interface Props {
    readonly uniqueId: string
    readonly passwordResetKey: string
    readonly onError?: (error: string) => void
}
