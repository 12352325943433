import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'

import styled from 'styled-components'

import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { ButtonSection } from '@igs-web/common-ui-components/_atoms/buttons/button-section'
import { PasswordInput } from '@igs-web/common-ui-components/_atoms/form-inputs/password-input'
import { TextInput } from '@igs-web/common-ui-components/_atoms/form-inputs/text-input'
import { BoxedErrorAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { GridForm } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { SolarMyAccountLanguage } from '@igs-web/common-ui-components/_molecules/solar-my-account-language'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

import { useCompany } from '../company/hooks/useCompany'
import { UserSelectors, userActions } from '../user/user-redux'

import { LoginFormSubtitle } from './login-form-components'

export interface LoginFormFields {
    readonly username: string
    readonly password: string
}

const WideLayoutSubmitButtonContainer = styled.div`
    padding-bottom: ${Spacing.Large};
    & > button {
        border-radius: 0;
        width: 100%;
    }
`

export const LoginForm = ({ onSubmit, uniqueId, useFormErrorMessaging, email }: Props): JSX.Element => {
    const [submitError, setSubmitError] = React.useState<string | undefined>(undefined)
    const { features } = useCompany()
    const dispatch = useDispatch()
    const { hasSolarMyAccount } = features
    const userProfileError = useSelector(UserSelectors.selectUserProfileLoadError)
    const logoutMessage = useSelector(UserSelectors.selectLogoutMessage)
    const [logoutMessageState, setLogoutMessage] = React.useState<string | undefined>(undefined)

    const { control, handleSubmit } = useForm<LoginFormFields>()

    useEffectOnce(() => {
        if (logoutMessage && useFormErrorMessaging) {
            setLogoutMessage(logoutMessage)
            dispatch(userActions.setLogoutMessage(undefined))
        }
    })

    return (
        <GridForm
            onSubmit={handleSubmit(async data => {
                const error = await onSubmit(data)
                if (error) {
                    setSubmitError(error)
                } else {
                    setSubmitError(undefined)
                }
            })}
        >
            <FormErrorMessage
                useFormErrorMessaging={useFormErrorMessaging}
                submitError={submitError}
                userProfileError={userProfileError}
                logoutMessageState={logoutMessageState}
            />
            {features.displayLoginPageSubheader && (
                <LoginFormSubtitle>Log in to access your account details, renew, or shop for additional products.</LoginFormSubtitle>
            )}

            <TextInput
                control={control}
                name="username"
                type="email"
                dataTestId={`myaccount-login-email-${uniqueId}`}
                defaultValue={email}
                label="Email Address"
                autoFocus={!email}
                rules={{ required: 'Required' }}
            />

            <PasswordInput
                control={control}
                name="password"
                dataTestId={`myaccount-login-password-${uniqueId}`}
                label="Password"
                rules={{ required: 'Required' }}
            />
            <ButtonSection alignment="center" className="login-button-container">
                {features.displayLoginPageSubheader ? (
                    <Button type="submit" dataTestId={`myaccount-login-submit-${uniqueId}`}>
                        LOG IN
                    </Button>
                ) : (
                    <WideLayoutSubmitButtonContainer>
                        <Button type="submit" dataTestId={`myaccount-login-submit-${uniqueId}`}>
                            Sign In
                        </Button>
                    </WideLayoutSubmitButtonContainer>
                )}
            </ButtonSection>
            <SolarMyAccountLanguage show={!!submitError && hasSolarMyAccount} />
        </GridForm>
    )
}

const FormErrorMessage = ({ useFormErrorMessaging, submitError, userProfileError, logoutMessageState }: FormErrorMessageProps) => {
    const getErrorMessage = (): string | undefined => {
        if (submitError) {
            return submitError.toString()
        } else if (userProfileError) {
            return userProfileError
        } else if (logoutMessageState) {
            return logoutMessageState
        } else {
            return undefined
        }
    }
    if (!useFormErrorMessaging || !getErrorMessage()) {
        return <></>
    } else {
        return <BoxedErrorAlert>{getErrorMessage()}</BoxedErrorAlert>
    }
}

interface FormErrorMessageProps {
    readonly useFormErrorMessaging: boolean
    readonly submitError: unknown | undefined
    readonly userProfileError: string | undefined
    readonly logoutMessageState: string | undefined
}
interface Props {
    readonly uniqueId: string
    readonly onSubmit: (values: LoginFormFields) => Promise<string | void>
    readonly email?: string
    readonly useFormErrorMessaging: boolean
}
