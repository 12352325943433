import { Address } from '@igs-web/common-models/models/address'
import { CommunitySolarAccount } from '@igs-web/common-models/models/community-solar-model'
import { UserProfileAccount } from '@igs-web/common-models/models/user-profile-model'
import { getFormattedAddress } from '@igs-web/common-utilities/utilities/address-utilities'
import { formatPhoneNumberForDisplay } from '@igs-web/common-utilities/utilities/phone-utilities'
import { formatName } from '@igs-web/common-utilities/utilities/user-utilities'

export interface AccountDetailBase {
    readonly customerName: string
    readonly serviceAddressFormatted?: string
    readonly serviceAddress?: Address
    readonly customerEmailAddress?: string
    readonly customerPhoneNumber?: string
}

export interface CommodityAccountDetails extends AccountDetailBase {
    readonly utilityName: string
    readonly accountNumberLabel: string
    readonly accountNumber: string
    readonly businessName: string
    readonly meterNumberLabel?: string
    readonly meterNumber?: string
}

export interface HwAccountDetails extends AccountDetailBase {
    readonly protectionPlan: string
    readonly partyNumber: string
}

export interface CSAccountDetails extends AccountDetailBase {
    readonly utilityName: string
    readonly utilityAccountNumber: string
    readonly subscriptinReferenceNumber: string
}
const mapToAccountDetailBase = (account: UserProfileAccount, serviceAddress: Address): AccountDetailBase => ({
    customerName: formatName(account.customerFirstName, account.customerLastName),
    serviceAddressFormatted: getFormattedAddress(serviceAddress),
    serviceAddress,
    customerEmailAddress: account.customerEmailAddress || undefined,
    customerPhoneNumber: formatPhoneNumberForDisplay(account.customerPhoneNumber),
})

export const mapToHwAccountDetails = (account: UserProfileAccount, serviceAddress: Address): HwAccountDetails => ({
    ...mapToAccountDetailBase(account, serviceAddress),
    protectionPlan: account.protectionPlan || '',
    partyNumber: account.partyNumber || '',
})

export const mapToCommodityAccountDetails = (account: UserProfileAccount, serviceAddress: Address): CommodityAccountDetails => ({
    ...mapToAccountDetailBase(account, serviceAddress),
    utilityName: account.utilityName || '',
    accountNumberLabel: account.accountNumberLabel || '',
    accountNumber: account.accountNumber || '',
    businessName: account.businessName || '',
    meterNumberLabel: account.meterNumberLabel || '',
    meterNumber: account.meterNumber || '',
})

export const mapToCSAccountDetails = (account: CommunitySolarAccount, serviceAddress: Address): CSAccountDetails => ({
    customerName: formatName(account.contact.firstName, account.contact.lastName),
    serviceAddressFormatted: getFormattedAddress(serviceAddress),
    serviceAddress,
    customerEmailAddress: account.contact.email || undefined,
    customerPhoneNumber: formatPhoneNumberForDisplay(account.contact.phoneNumber),
    utilityName: account.utilityAccount.utility.name,
    utilityAccountNumber: account.utilityAccount.utilityAccountNumber,
    subscriptinReferenceNumber: account.subscriptionReferenceNumber,
})
