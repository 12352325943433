import * as React from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Header3, StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { lightDark } from '@igs-web/common-ui-components/styles/theme'

const EmergencyBox = styled.aside`
    display: grid;
    grid-row-gap: ${Spacing.Medium};
    grid-area: emergency;
    padding: ${Spacing.Medium};
    background-color: ${lightDark};
    width: 100%;
    p {
        font-size: 0.9rem;
        margin: 0;
    }
    ${Header3} {
        font-size: 1.2rem;
    }
`
const EmergencyPhones = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`
export const Emergency = () => {
    const { phoneNumber } = useCompany()
    return (
        <EmergencyBox>
            <Header3>
                <FaExclamationTriangle /> Emergencies
            </Header3>
            <StrongText>In any dangerous situation, dial 911.</StrongText>
            <p>Suspect a gas leak? Leave immediately and call:</p>
            <Header3>Atlanta Gas Light</Header3>
            <EmergencyPhones>
                <p>
                    Inside Metro Atlanta
                    <br />
                    <StrongText>
                        <PhoneNumberLink phoneNumber="17709074231" />
                    </StrongText>
                </p>
                <p>
                    Outside Metro Atlanta
                    <br />
                    <StrongText>
                        <PhoneNumberLink phoneNumber={phoneNumber.gasLeak} />
                    </StrongText>
                </p>
            </EmergencyPhones>
        </EmergencyBox>
    )
}
