import * as React from 'react'
import { useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'

import { CheckoutStepPageIds } from '@igs-web/common-components/domain/checkout/checkout-step-page-ids'
import { useCheckoutDispatch } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { useBreakpoints } from '@igs-web/common-components/hooks/use-breakpoints'
import { PhoneType } from '@igs-web/common-models/constants/phone'
import { ContactModel, IconType, UserProfile } from '@igs-web/common-models/models'
import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'
import { ProgressTrack } from '@igs-web/common-ui-components/_molecules/progress-track/progress-track'
import { Wizard } from '@igs-web/common-ui-components/_molecules/wizard/wizard'
import { useWizardState } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { WizardPage, WizardPageSection } from '@igs-web/common-ui-components/_molecules/wizard/wizard-page'
import { WizardProgressTrackStep } from '@igs-web/common-ui-components/_molecules/wizard/wizard-progress-track'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { background } from '@igs-web/common-ui-components/styles/theme'
import { HW_CHECKOUT_WIZARD_MESSAGES_STORAGE_KEY } from '@igs-web/common-utilities/constants/constants'
import { getFromStorage } from '@igs-web/common-utilities/utilities/storage-utilities'
import { capitalizeFirstLetter } from '@igs-web/common-utilities/utilities/string-formatter'

import { CheckoutWizardMessages } from '../checkout-wizard-messages/checkout-wizard-messages'
import { CheckoutWizardMessagesProvider } from '../checkout-wizard-messages/checkout-wizard-messages-context'
import { CheckoutWizardMessagesModel } from '../checkout-wizard-messages/checkout-wizard-messages-models'
import { CheckoutLayout } from '../shared/checkout-components'
import { YourPlanHW } from '../your-plan/your-plan-hw'

import { HwEnrollmentType } from './hw-checkout'
import { HwCheckoutReviewStep } from './hw-checkout-review-step'
import { HwPaymentStep } from './hw-payment-step'
import { HwServiceAddressStep } from './service-address/hw-address-step'

const WizardPagesUserZip = () => {
    const offerRequest = useSelector(OfferSelectors.selectOfferRequest)

    const { activePageId } = useWizardState()

    const { lessThanOrEqualTo } = useBreakpoints()
    const isMobile = lessThanOrEqualTo(Breakpoint.Tablet)

    const showYourPlan = !isMobile && activePageId !== CheckoutStepPageIds.OrderSummary

    if (!offerRequest.zipCode) {
        return <LocalLoader isLoading={true} />
    }

    return (
        <>
            <CheckoutWizardMessages isHomeWarranty={true} />
            <CheckoutLayout $showSidebar={showYourPlan}>
                <WizardPageSection gridarea="content">
                    <WizardPage pageId={CheckoutStepPageIds.CustomerAddress}>
                        <HwServiceAddressStep />
                    </WizardPage>
                    <WizardPage pageId={CheckoutStepPageIds.DirectBillPreference}>
                        <HwPaymentStep />
                    </WizardPage>
                    <WizardPage pageId={CheckoutStepPageIds.OrderSummary}>
                        <HwCheckoutReviewStep hwEnrollmentType={HwEnrollmentType.userZip} />
                    </WizardPage>
                </WizardPageSection>
                {showYourPlan && <YourPlanHW hwEnrollmentType={HwEnrollmentType.userZip} />}
            </CheckoutLayout>
        </>
    )
}

export const HwCheckoutExperienceUserZip = ({ userProfile }: Props) => {
    const storedCheckoutWizardMessagesModel = getFromStorage<CheckoutWizardMessagesModel>(HW_CHECKOUT_WIZARD_MESSAGES_STORAGE_KEY, false)
    const { setContactInfo } = useCheckoutDispatch()

    useEffectOnce(() => {
        const account = userProfile.accounts[0]
        const contact: ContactModel = {
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            middleInitial: '',
            emailAddress: userProfile.email,
            phoneNumber: account?.customerPhoneNumber,
            phoneType: PhoneType[capitalizeFirstLetter(account?.customerPhoneTypeCode || 'Home')],
            commercialName: undefined,
        }
        setContactInfo(contact, userProfile.isPaperless)
    })

    return (
        <Wizard initiallyActivePageId={CheckoutStepPageIds.CustomerAddress}>
            <ProgressTrack bgColor={background}>
                <WizardProgressTrackStep pageId={CheckoutStepPageIds.CustomerAddress} label="Address" iconType={IconType.CircleServiceAddressIcon} />
                <WizardProgressTrackStep pageId={CheckoutStepPageIds.DirectBillPreference} label="Payment Method" iconType={IconType.CircleCreditCheckIcon} />
                <WizardProgressTrackStep pageId={CheckoutStepPageIds.OrderSummary} label="Review" iconType={IconType.CircleReviewIcon} />
            </ProgressTrack>
            <CheckoutWizardMessagesProvider
                checkoutWizardMessagesState={storedCheckoutWizardMessagesModel}
                storageKey={HW_CHECKOUT_WIZARD_MESSAGES_STORAGE_KEY}
            >
                <WizardPagesUserZip />
            </CheckoutWizardMessagesProvider>
        </Wizard>
    )
}

interface Props {
    readonly userProfile: UserProfile
}
