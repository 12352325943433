import * as React from 'react'

import styled from 'styled-components'

import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { accordionSeparatorColor } from '@igs-web/common-ui-components/styles/theme'

import { ExpandHeader } from './expand-header'
import { ExpandableToggle } from './expandable'

const StyledPrimarySection = styled.div`
    position: relative;
    border-bottom: 2px solid ${accordionSeparatorColor};
    padding: ${Spacing.Medium};
    &:first-of-type {
        border-top: 2px solid ${accordionSeparatorColor};
    }
`

export const ExpandSection = ({ isExpanded, toggleExpand, children, header, dataTestId, ...htmlProps }: ExpandSectionProps): JSX.Element => (
    <StyledPrimarySection {...htmlProps}>
        <ExpandHeader isExpanded={isExpanded} toggleExpand={toggleExpand} dataTestId={dataTestId}>
            {header}
        </ExpandHeader>
        {isExpanded && <div>{children}</div>}
    </StyledPrimarySection>
)

export interface ExpandSectionProps extends ExpandableToggle, React.HTMLAttributes<HTMLDivElement> {
    readonly header: string | JSX.Element
    readonly children: React.ReactNode
    readonly dataTestId: string
}
