import * as React from 'react'

import { CoverageDetailItem } from '@igs-web/common-models/models/account-detail-model'
import { MoneyFormat, formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'

import { DataTable } from '../data-table/data-table'
import { DefaultEmptyTable } from '../data-table/default-empty-table'
import { TableColumn } from '../data-table/models/table-column'
import { TableDataType } from '../data-table/models/table-data-types'

interface CoverageTableRecord extends CoverageDetailItem {
    readonly protectionLimit: JSX.Element
}
const buildFootnoteLink = (coverage: CoverageDetailItem) => {
    if (coverage.footnote) {
        return (
            <a href={`#${coverage.coverageCode}-${coverage.footnoteSymbol}`}>
                <sup>{coverage.footnoteSymbol}</sup>
            </a>
        )
    }
    return <></>
}

const mapToTableRecord = (coverage: CoverageDetailItem): CoverageTableRecord => ({
    ...coverage,
    protectionLimit: (
        <>
            up to {formatAsMoney(coverage.limit, MoneyFormat.Dollars, true, 0, 0)} {buildFootnoteLink(coverage)}
        </>
    ),
})

const getColumns = (): ReadonlyArray<TableColumn> => [
    { header: 'Name', accessor: 'coverageName', dataType: TableDataType.String },
    { header: 'Protection Limit', accessor: 'protectionLimit', dataType: TableDataType.ReactNode, disableSorting: true },
    { header: 'Effective Date', accessor: 'effectiveDate', dataType: TableDataType.DateTime, disableSorting: true },
]

export const CoverageTable = ({ coverages }: Props): JSX.Element => {
    const columns = getColumns()
    const tableRecords = coverages.map(mapToTableRecord)
    return (
        <div>
            <DataTable<CoverageDetailItem>
                tableTitle={'HOME WARRANTY PROTECTION AMOUNTS'}
                columns={columns}
                data={tableRecords}
                showWhenEmpty={<DefaultEmptyTable emptyTableMessage="No protection found for this account" />}
                dataTestId="protection-history-table"
            />
        </div>
    )
}
interface Props {
    readonly coverages: ReadonlyArray<CoverageDetailItem>
}
