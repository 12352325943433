import * as React from 'react'

import { asArray } from '@igs-web/common-utilities/utilities/array-utilities'

interface AccordionGroupContext {
    readonly expandedIds: ReadonlyArray<string>
    readonly onlyOneExpanded: boolean
    readonly setExpandedIds: (ids: ReadonlyArray<string>) => void
}

const AccordionGroupContext = React.createContext<AccordionGroupContext | undefined>(undefined)

export const useAccordionGroup = (): AccordionGroupContext => {
    const context = React.useContext(AccordionGroupContext)
    if (!context) {
        throw Error('Must use useAccordionGroupContext inside of AccordionGroupProvider')
    }

    return context
}

export const AccordionGroupProvider = ({ children, initiallyExpanded, onlyOneExpanded = false }: AccordionGroupProviderProps) => {
    const initiallyExpandedArr = asArray<string>(initiallyExpanded)

    const [expandedIds, setExpandedIds] = React.useState(initiallyExpandedArr)

    return <AccordionGroupContext.Provider value={{ expandedIds, setExpandedIds, onlyOneExpanded }}>{children}</AccordionGroupContext.Provider>
}
interface AccordionGroupProviderProps {
    readonly initiallyExpanded?: ReadonlyArray<string> | string
    readonly onlyOneExpanded?: boolean
    readonly children: React.ReactNode
}
