import * as React from 'react'

import styled from 'styled-components'

import { AccordionGroup } from '@igs-web/common-ui-components/_molecules/accordion/accordion-group'
import { AccordionPanel } from '@igs-web/common-ui-components/_molecules/accordion/accordion-panel'

import { FaqEntry } from './faq-entry'

const FaqContainer = styled.div`
    width: 100%;
`

export const FaqSection = ({ FAQs }: Props) => {
    return (
        <FaqContainer>
            <AccordionGroup>
                {FAQs.map(({ question, answer, hide = false }) =>
                    hide ? (
                        <></>
                    ) : (
                        <AccordionPanel key={question} panelId={question} header={question} dataTestId="faq-section-header-div">
                            <FaqEntry answer={answer} />
                        </AccordionPanel>
                    ),
                )}
            </AccordionGroup>
        </FaqContainer>
    )
}

interface Props {
    readonly FAQs: ReadonlyArray<Faq>
}

interface Faq {
    readonly question: string
    readonly answer: React.ReactNode | string
    readonly hide?: boolean
}

export default FaqSection
