import { PhoneType } from '@igs-web/common-models/constants/phone'

import { formatString, removeNonNumeric } from './string-formatter'

const domesticStorageFormat = '9999999999'
const internationalStorageFormat = '99999999999'

const domesticPhoneDisplayFormat = '999.999.9999'
const internationalPhoneDisplayFormat = '9.999.999.9999'

export const domesticPhoneNumberRegex = /^\d{10}$/
export const internationalPhoneNumberRegex = /^\d{11}$/
// eslint-disable-next-line max-len
export const formattedPhoneNumberRegex = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
export const allSameDigitPhoneNumber = /^(?=.{2})(\d)\1*$/

export const isPhoneNumber = (str: string): boolean => isDomesticPhoneNumber(str) || isInternationalPhoneNumber(str)

const isDomesticPhoneNumber = (str: string): boolean => domesticPhoneNumberRegex.test(str)
const isInternationalPhoneNumber = (str: string): boolean => internationalPhoneNumberRegex.test(str)

export const formatPhoneNumberForStorage = (input: string | undefined | null): string => {
    const storageFormat = input && isInternationalPhoneNumber(input) ? internationalStorageFormat : domesticStorageFormat
    return formatString(input, storageFormat).result
}

export const formatPhoneNumberForDisplay = (input: string | undefined | null): string => {
    const displayFormat = input && isInternationalPhoneNumber(input) ? internationalPhoneDisplayFormat : domesticPhoneDisplayFormat
    return formatString(input, displayFormat).result
}

export const parsePhoneNumber = (input?: string): string | undefined => {
    const digits = input ? removeNonNumeric(input) : ''
    return isPhoneNumber(digits) ? digits : undefined
}

export const getPhoneType = (input: string | undefined | null): PhoneType | undefined | null => {
    switch (input) {
        case PhoneType.Cell:
            return PhoneType.Cell
        case PhoneType.Home:
            return PhoneType.Home
        case PhoneType.Work:
            return PhoneType.Work
        default:
            return undefined
    }
}
