export class IgsEventHandler {

    get subscribed() {
        return this._subscribed
    }
    public readonly eventCode: string

    public readonly handleEvent: (data: any) => void
    // eslint-disable-next-line functional/prefer-readonly-type
    private _subscribed = true

    constructor(eventCode: string, fn: (data: any) => void) {
        this.eventCode = eventCode
        this.handleEvent = fn
    }

    public readonly unsubscribe = () => this._subscribed = false
}

class Eventer {
    // eslint-disable-next-line functional/prefer-readonly-type
    private readonly handlers: IgsEventHandler[] = []

    public readonly publish = <T>(eventCode: string, data: T) => {
        this.handlers.filter(h => h.subscribed)
            .filter(h => h.eventCode === eventCode)
            .forEach(h => h.handleEvent(data))
    }

    public readonly subscribe = <T>(eventCode: string, fn: (data: T) => void) => {
        const handler = new IgsEventHandler(eventCode, fn)
        this.handlers.push(handler)

        return handler
    }
}

const global = window as any
global.Igs = global.Igs || {}
global.Igs.Eventer = global.Igs.Eventer || new Eventer()

export const GlobalEventer = global.Igs.Eventer
