import * as React from 'react'
import styled from 'styled-components'

import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { primaryLight } from '@igs-web/common-ui-components/styles/theme'

export const ScanaFooterSecondayNavContainer = styled.div<ScanaFooterSecondayNavContainerProps>`
    display: grid;
    grid-template-columns: repeat(${p => React.Children.count(p.children)}, max-content);
    column-gap: ${Spacing.Medium};
    text-align: center;
    font-size: 14px;
    justify-content: center;
    @media (max-width: 500px) {
        grid-template-columns: 100%;
        row-gap: ${Spacing.Small};
    }
`

export const StyledScanaFooterSecondaryNavCopyright = styled.div`
    font-weight: 700;
`

export const ScanaFooterSecondaryNavLink = styled(Link)`
    color: ${primaryLight};
    svg {
        height: 32px;
        fill: ${primaryLight};
    }
`

type ScanaFooterSecondayNavContainerProps = React.HTMLAttributes<HTMLDivElement>
