import { formatWithComma, precisionRound } from './number-utilites'

const CentsPerDollar = 100
export const DefaultMoneyPrecision = 2

export const asDollarValue = (dollarAmount: number, moneyPrecision = DefaultMoneyPrecision, fixedLength = 0): string => {
    const amountRoundedToCents = Math.abs(precisionRound(dollarAmount, moneyPrecision))
    if (fixedLength > 0) {
        return amountRoundedToCents.toFixed(fixedLength)
    }
    return amountRoundedToCents.toFixed(moneyPrecision)
}

//TODO: remove wholeNumber param
const formatAsDollars = (
    dollarAmount: number | undefined,
    wholeNumber = false,
    fixedLength = 0,
    useComma = true,
    moneyPrecision = DefaultMoneyPrecision,
): string => {
    if (dollarAmount === null || dollarAmount === undefined) {
        return ''
    }
    const prefix = dollarAmount < 0 ? '-$' : '$'
    const precision = wholeNumber ? 0 : moneyPrecision
    const amountToDisplay = asDollarValue(dollarAmount, precision, fixedLength)
    return prefix + (useComma ? formatWithComma(amountToDisplay) : amountToDisplay)
}

const formatAsCents = (dollarAmount: number, fixedLength = 0, moneyPrecision = DefaultMoneyPrecision): string => {
    const centAmount = precisionRound(dollarAmount * CentsPerDollar, moneyPrecision)
    if (fixedLength > 0) {
        return centAmount.toFixed(fixedLength) + '¢'
    }
    return centAmount + '¢'
}

export const formatAsMoney = (
    dollarAmount?: number,
    moneyFormat = MoneyFormat.Money,
    useComma = true,
    fixedLength = 0,
    moneyPrecision = DefaultMoneyPrecision,
): string => {
    if (dollarAmount === null || dollarAmount === undefined) {
        return ''
    } else if (moneyFormat !== MoneyFormat.Cents && (moneyFormat === MoneyFormat.Dollars || dollarAmount >= 1 || dollarAmount <= -1 || dollarAmount === 0)) {
        return formatAsDollars(dollarAmount, false, fixedLength, useComma, moneyPrecision)
    } else {
        return formatAsCents(dollarAmount, fixedLength, moneyPrecision)
    }
}

export enum MoneyFormat {
    Dollars = 'Dollars',
    Cents = 'Cents',
    Money = 'Money', // Dollars or Cents
}
