/* eslint-disable max-len */
import * as React from 'react'

import { ElectronicCommunicationConsentNotice } from '../../legal/electronic-communication-consent-notice'
import { CompanyVerbiage, Verbiage } from '../companyVerbiage'

export const igsVerbiage: CompanyVerbiage = {
    [Verbiage.paymentButtonText]: 'Make a Payment',
    [Verbiage.paymentNavigationText]: 'Make a Payment',
    [Verbiage.billsTableAmountDueHeaderText]: 'Amount Due',
    [Verbiage.usageTableHeadersQuantity]: 'Quantity',
    [Verbiage.usageTableHeadersChargesPerUnit]: 'Charges per Unit',
    [Verbiage.paperlessCommunicationMessage]: (
        <>
            <p>
                Yes! Let’s Go Green by going paperless. I want to receive my account details by email, and I verify that the email address I’ve provided is my
                personal email.
            </p>
            <ElectronicCommunicationConsentNotice />
        </>
    ),
    [Verbiage.creditCheckVerificationMessage]: 'Please verify that your name and social security number were entered correctly before proceeding.',
    [Verbiage.changeEmail]: 'Your email is used for communication with IGS and to log in to My Account.',
    [Verbiage.loginUserNotFound]: 'No account associated with that email address.',
    [Verbiage.tooManyLoginAttempts]: <>Too many logins attempted. Please reset your password to unlock.</>,
}
