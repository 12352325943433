import * as React from 'react'
import { FilterProps } from 'react-table'

import styled from 'styled-components'

import { ErrorAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { mdWidth } from '@igs-web/common-ui-components/styles/theme'

const StyledControl = styled.div`
    text-align: left;
    margin-bottom: ${Spacing.Small};
    display: grid;
    grid-gap: ${Spacing.ExtraSmall};
    grid-column-gap: ${Spacing.Small};
    grid-template-columns: [label] 1fr [input] 1fr;

    @media (max-width: ${mdWidth}) {
        grid-template-columns: 1fr;
    }
`

const StyledLabel = styled.label`
    grid-column: label;
    align-self: center;
`
const StyledInput = styled.input`
    grid-column: input;
    align-self: center;
    min-width: min-content;
`
const StyledErrorAlert = styled(ErrorAlert)`
    padding: 0px;
    margin-bottom: ${Spacing.Small};
    text-align: right;
`
const MINDATE = new Date('January 01, 1970')

export const DateFilter = ({ setFilterValue, column, setDisableSubmit }: FilterProps<object>) => {
    const [startDateError, setStartDateError] = React.useState(false)
    const [endDateError, setEndDateError] = React.useState(false)

    const today = new Date(Date.now()).toISOString().slice(0, 10)

    const [currentFilter, setCurrentFilter] = React.useState(column.filterValue)

    const minToDate = currentFilter && currentFilter.startDate ? currentFilter.startDate : MINDATE
    const maxFromDate = currentFilter && currentFilter.endDate ? currentFilter.endDate : today
    const SetDateRangeAndFilterValue = (value: DateRange) => {
        setCurrentFilter(value)
        setFilterValue(value)
    }

    React.useEffect(() => {
        if (startDateError || endDateError || !currentFilter?.startDate || !currentFilter?.endDate) {
            setDisableSubmit(true)
        } else {
            setDisableSubmit(false)
        }
    }, [startDateError, endDateError, setDisableSubmit, currentFilter])

    return (
        <div>
            <StyledControl>
                <StyledLabel>From Date</StyledLabel>
                <StyledInput
                    type={'date'}
                    onChange={e => {
                        const val = e.target.value
                        const dateVal = Date.parse(val)
                        setStartDateError(isNaN(dateVal) || !!(currentFilter?.endDate && dateVal > Date.parse(currentFilter.endDate)))
                        const filterValue = { ...currentFilter, startDate: val } as DateRange
                        SetDateRangeAndFilterValue(filterValue)
                    }}
                    value={currentFilter ? currentFilter.startDate : 'mm-dd-yyyy'}
                    max={maxFromDate}
                />
            </StyledControl>
            <StyledErrorAlert show={startDateError} message={'Invalid From Date Selected'} />
            <StyledControl>
                <StyledLabel>To Date</StyledLabel>
                <StyledInput
                    type={'date'}
                    onChange={e => {
                        const val = e.target.value
                        const dateVal = Date.parse(val)
                        setEndDateError(isNaN(dateVal) || !!(currentFilter?.startDate && dateVal < Date.parse(currentFilter.startDate)))
                        const filterValue = { ...currentFilter, endDate: val } as DateRange
                        SetDateRangeAndFilterValue(filterValue)
                    }}
                    value={currentFilter ? currentFilter.endDate : 'mm-dd-yyyy'}
                    max={today}
                    min={minToDate}
                />
            </StyledControl>
            <StyledErrorAlert show={endDateError} message={'Invalid To Date Selected'} />
        </div>
    )
}

export interface DateRange {
    readonly startDate: string
    readonly endDate: string
}
