import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { UtilityAccountBillingHistory } from '@igs-web/common-models/models/utility-account-billing-history'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { CachedReduxDataStateType, setupCachedReduxData } from '../cached-redux/cached-redux-data'

const load = async (accountId: number, config: RequestConfig) => {
    try {
        return (await customerInfoApiClient.getUtilityAccountActivity(accountId, config)) || undefined
    } catch (e) {
        throw Error(`There was a problem getting activity for account: ${accountId}`)
    }
}

const { actions, selectors, saga, reducers } = setupCachedReduxData('accountActivity', load)

export const utilityAccountActivityRedux = {
    actions,
    selectors: {
        ...selectors,
        selectPastDueBalance: (state: CommonReduxState, accountId: number): number => {
            const activity = selectors.selectData(state, accountId)
            return (activity && activity.pastDueBalance) || 0
        },
        selectBillingHistory: (state: CommonReduxState, accountId: number): ReadonlyArray<UtilityAccountBillingHistory> => {
            const activity = selectors.selectData(state, accountId)
            return (activity && activity.billingHistory) || []
        },
    },
}

export const utilityAccountActivitySaga = saga
export const utilityAccountActivityReducer = reducers
export type UtilityAccountActivityState = CachedReduxDataStateType<typeof load>
