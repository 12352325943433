import { CreditCardType } from '@igs-web/common-models/constants/credit-card-type'

export const getCardType = (cardNumber: string): CreditCardType | undefined => {
    switch (cardNumber[0]) {
        case '3':
            return CreditCardType.Amex
        case '4':
            return CreditCardType.Visa
        case '2':
        case '5':
            return CreditCardType.Mastercard
        case '6':
            return CreditCardType.Discover
        default:
            // unknown Card Type
            return undefined
    }
}

export const getCardTypeRegex = (cardType: CreditCardType): RegExp => {
    switch (cardType) {
        case CreditCardType.Amex:
            return /^3\d{14}$/
        case CreditCardType.Visa:
            return /^4(\d{13}|\d{15})$/
        case CreditCardType.Mastercard:
            return /^(5|2)\d{15}$/
        case CreditCardType.Discover:
            return /^6\d{15}$/
        default:
            throw Error(`Invalid card type: ${cardType}`)
    }
}

const cvvLen_Standard = 3
const cvvLen_Amex = 4
export const getSecurityCodeLength = (cardType?: CreditCardType) => (!!cardType && cardType === CreditCardType.Amex ? cvvLen_Amex : cvvLen_Standard)

export const getSecurityCodeRegex = (cardType?: CreditCardType) => new RegExp(`^[0-9]{${getSecurityCodeLength(cardType)}}$`)
