import React from 'react'
import ReactModel from 'react-modal'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { Button, ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { ActionLink } from '@igs-web/common-ui-components/_atoms/link/action-link'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { Environment, getApiUrl, getEnvironment } from '@igs-web/common-utilities/utilities/environment-utilities'

const ModalControls = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${Spacing.Large};
    grid-row-gap: ${Spacing.Medium};
    @media (max-width: ${Breakpoint.Mobile}) {
        grid-column-gap: ${Spacing.Small};
        > Button {
            grid-column: 1 / span 2;
        }
    }
    > div {
        grid-column: 1 / span 2;
    }
`
const ModalImage = styled.img`
    width: 100%;
`

export const PaymentPortalForm = ({
    email,
    zipCode,
    billingAccountNumber = undefined,
    submitButtonText = 'Go to BillMatrix',
    isAutoPay = false,
    isLoggedIn,
    buttonStyle = ButtonStyleType.Primary,
    showAsLink = false,
    dataTestId,
}: PaymentPortalFormProps) => {
    const { routes, name } = useCompany()
    const env = getEnvironment()
    const search = new URLSearchParams(window.location.search)
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    ReactModel.setAppElement('#root')
    billingAccountNumber = billingAccountNumber ? billingAccountNumber : search.get('billingAccountNumber') ? search.get('billingAccountNumber')! : undefined
    const closeModal = () => {
        setIsModalOpen(false)
    }
    const paymentPortalAction = () => {
        if (isLoggedIn) {
            if (env === Environment.Prod) {
                return routes.payment.prodPaymentPortalRegistered
            } else {
                return routes.payment.nonProdPaymentPortalRegistered
            }
        } else {
            if (env === Environment.Prod) {
                return routes.payment.prodPaymentPortalGuest
            } else {
                return routes.payment.nonProdPaymentPortalGuest
            }
        }
    }

    const getScanaMyAccountRedirectUri = () => {
        const environment = getEnvironment()
        const publicWebsiteApiUrlBase = getApiUrl(environment, name)
        const publicWebsiteApiUrl = `${publicWebsiteApiUrlBase}/api/Redirect/ScanaMyAccount${email ? `?email=${email}` : ''}`
        return publicWebsiteApiUrl
    }
    const formRef = React.useRef<HTMLFormElement>(null)
    const handleClick = () => {
        if (isLoggedIn) {
            if (formRef.current) {
                formRef.current.submit()
            }
        } else {
            window.open(paymentPortalAction(), '_blank')
        }
        closeModal()
    }

    return (
        <div>
            {showAsLink ? (
                <ActionLink onClick={() => setIsModalOpen(true)}>{submitButtonText}</ActionLink>
            ) : (
                <Button onClick={() => setIsModalOpen(true)} buttonStyle={buttonStyle} dataTestId={dataTestId}>
                    {submitButtonText}
                </Button>
            )}
            <form action={paymentPortalAction()} method="post" id="payment-portal-form" target="_blank" ref={formRef}>
                <input readOnly={true} type="text" id="accountNumber" name="AccountNumber" value={billingAccountNumber} hidden />
                <input readOnly={true} type="text" id="zipCode" name="ZipCode" value={zipCode} hidden />
                <input readOnly={true} type="text" id="emailAddress" name="EmailAddress" value={email} hidden />
                <input readOnly={true} type="text" id="redirectURLIndicator" name="RedirectURLIndicator" value="Y" hidden />
                <input readOnly={true} type="text" id="redirectURL" name="RedirectURL" value={getScanaMyAccountRedirectUri()} hidden />
                <input readOnly={true} type="text" id="destination" name="Destination" value={isAutoPay ? 'ManageAutoPay' : 'PaymentInformation'} hidden />
                <ReactModel
                    isOpen={isModalOpen}
                    onRequestClose={() => {
                        setIsModalOpen(false)
                    }}
                    style={{
                        overlay: {
                            display: 'grid',
                            zIndex: '2',
                        },
                        content: {
                            position: 'relative',
                            width: 'fit-content',
                            display: 'grid',
                            margin: 'auto',
                            inset: '0',
                            justifyItems: 'center',
                            templateColumns: '1fr',
                            zIndex: '2',
                        },
                    }}
                >
                    <ModalImage src="/assets/scana-energy-to-billmatrix-infographic.png" alt="Scana Energy redirecting to BillMatrix" />
                    <p>You are leaving our site and will be directed to BillMatrix to complete your payment.</p>
                    <ModalControls>
                        <div>
                            <strong>Do you wish to continue?</strong>
                        </div>
                        <Button
                            buttonStyle={ButtonStyleType.Secondary}
                            dataTestId="bill-matrix-cancel-link-button"
                            onClick={() => {
                                setIsModalOpen(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button buttonStyle={ButtonStyleType.Primary} onClick={handleClick} dataTestId="bill-matrix-submit-link-button" type="button">
                            Yes, Proceed
                        </Button>
                    </ModalControls>
                </ReactModel>
            </form>
        </div>
    )
}

PaymentPortalForm.displayName = 'PaymentPortalForm'
interface PaymentPortalFormProps {
    readonly email?: string | undefined
    readonly zipCode?: string | undefined
    readonly billingAccountNumber?: string | undefined
    readonly submitButtonText?: string
    readonly isAutoPay?: boolean
    readonly isLoggedIn: boolean
    readonly buttonStyle?: ButtonStyleType
    readonly showAsLink?: boolean
    readonly dataTestId?: string
}
