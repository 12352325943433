import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CommonReduxState } from '../common-redux'
import { UserSelectors, userActions } from '../user/user-redux'
import { ProductTypeDescription, UserProfileAccount } from '@igs-web/common-models/models'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'

export const useVariableRates = (partyAccountId?: number): number | undefined => {
    const dispatch = useDispatch()
    const [variableRate, setVariableRate] = React.useState<number | undefined>(undefined)
    const account = useSelector((state: CommonReduxState) =>
        UserSelectors.selectAccountByIdentifier(state, partyAccountId ? partyAccountId.toString() : ''),
    ) as UserProfileAccount

    const hasAccount = !!account

    React.useEffect(() => {
        if (hasAccount) {
            if (account.rate) {
                setVariableRate(account.rate)
            } else {
                if (account.productTypeCode?.toUpperCase() === ProductTypeDescription.Variable.toUpperCase()) {
                    getVariableRate()
                }
            }
        }
    }, [hasAccount])

    const getVariableRate = async () => {
        if (account && partyAccountId) {
            const variableRateResponse = await customerInfoApiClient.getVariableRate(partyAccountId)
            if (variableRateResponse) {
                const rate = variableRateResponse.rate
                setVariableRate(rate)
                dispatch(userActions.setVariableRate({ accountId: partyAccountId, variableRate: rate }))
            }
        }
    }

    return variableRate
}
