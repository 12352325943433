import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const RepairsCircleIcon = ({ altText = 'Repairs', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M8.584 8.628c0.073-0.074 0.174-0.119 0.286-0.119s0.213 0.046 0.286 0.119l0 0 4.472 4.464c0.072 0.074 0.116 0.175 0.116 0.286s-0.044 0.212-0.116 0.286l0-0c-0.073 0.073-0.174 0.118-0.285 0.118-0.003 0-0.006-0-0.009-0h0c-0.001 0-0.003 0-0.005 0-0.11 0-0.21-0.045-0.282-0.117l-0-0-4.464-4.457c-0.075-0.072-0.122-0.174-0.122-0.286s0.047-0.214 0.122-0.286l0-0zM11.837 16.154c0.164 0.16 0.363 0.285 0.583 0.364l0.011 0.003 4.068-4.039c-0.079-0.233-0.205-0.432-0.367-0.595l0 0-4.743-4.751c-0.551-0.548-1.31-0.887-2.149-0.887-0.802 0-1.531 0.309-2.075 0.815l0.002-0.002c-0.566 0.533-0.92 1.286-0.925 2.121v0.001c-0 0.016-0 0.035-0 0.053 0 0.89 0.359 1.696 0.941 2.282l-0-0z" />
        <path d="M25.7 23.431l-1.953-2.511c-0.074-0.094-0.188-0.154-0.316-0.154h-0.58l-4.303-4.296-2.1 2.071 4.303 4.288v0.573c0.002 0.13 0.061 0.245 0.154 0.323l0.001 0.001 2.511 1.975c0.067 0.054 0.154 0.087 0.248 0.087 0.113 0 0.216-0.048 0.288-0.124l0-0 1.681-1.667c0.065-0.070 0.104-0.165 0.104-0.268s-0.040-0.198-0.105-0.268l0 0z" />
        <path d="M21.177 8.811l0.852-0.822c0.075-0.076 0.179-0.123 0.294-0.123 0.037 0 0.072 0.005 0.106 0.014l-0.003-0.001 1.116 0.301h0.081c0.074 0.037 0.132 0.095 0.168 0.167l0.001 0.002c0.003 0.013 0.004 0.028 0.004 0.044s-0.001 0.031-0.004 0.046l0-0.002 0.301 1.109c0.009 0.032 0.014 0.069 0.014 0.107 0 0.113-0.044 0.215-0.117 0.29l0-0-0.815 0.822c-0.076 0.068-0.176 0.111-0.285 0.117l-0.001 0h-0.103l-1.116-0.301h-0.088c-0.072-0.039-0.129-0.097-0.168-0.167l-0.001-0.002c-0.001-0.012-0.001-0.026-0.001-0.040s0-0.028 0.001-0.042l-0 0.002-0.301-1.116c-0.008-0.031-0.013-0.066-0.013-0.103 0-0.115 0.047-0.219 0.123-0.293l0-0zM21.544 12.351c0.32 0.125 0.691 0.198 1.079 0.198h0c0.861-0.001 1.64-0.352 2.203-0.918l0-0c0.583-0.571 0.944-1.366 0.944-2.245 0-1.736-1.407-3.143-3.143-3.143s-3.143 1.407-3.143 3.143c0 0.398 0.074 0.778 0.209 1.129l-0.007-0.021-9.245 9.171c-0.32-0.12-0.691-0.189-1.077-0.189-0.872 0-1.662 0.353-2.234 0.923l0-0c-0.564 0.569-0.913 1.353-0.913 2.218 0 0.44 0.090 0.859 0.253 1.239l-0.008-0.021c0.047 0.124 0.154 0.216 0.284 0.242l0.002 0c0.026 0.006 0.055 0.009 0.085 0.009 0.113 0 0.215-0.045 0.289-0.119l1.351-1.351 0.888 0.881-1.351 1.351c-0.073 0.073-0.118 0.174-0.118 0.286 0 0.029 0.003 0.057 0.009 0.084l-0-0.003c0.026 0.135 0.118 0.243 0.24 0.293l0.003 0.001c0.355 0.153 0.769 0.242 1.203 0.242 0.003 0 0.006 0 0.009 0h-0c1.731-0.007 3.131-1.411 3.131-3.143 0-0.39-0.071-0.764-0.201-1.108l0.007 0.022z" />
        <path d="M15.985 0c-0.007 0-0.014 0-0.022 0-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-0.002 0-0.005 0-0.007 0-8.827-7.152-15.984-15.977-15.993h-0.001zM15.985 30.458c-0.004 0-0.010 0-0.015 0-7.985 0-14.458-6.473-14.458-14.458s6.473-14.458 14.458-14.458c7.985 0 14.458 6.473 14.458 14.458v0c0 0.004 0 0.010 0 0.015 0 7.977-6.466 14.443-14.443 14.443v0z" />
    </SvgIcon>
)
export default RepairsCircleIcon
