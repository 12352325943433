import { Spacing } from '@igs/react'

export const xsWidth = '575px'
export const smWidth = '576px'
export const mdWidth = '768px'
export const lgWidth = '992px'
export const xlWidth = '1200px'
export const xxlWidth = '1200px'
export const maxContainerWidth = '1100px'
export const maxContentWidth = '1440px'
export const fullBleedWidth = '1800px'

export const navIconSize = 25

export const fontSizeTiny = '.625rem'
export const fontSizeVerySmall = '.75rem'
export const fontSizeSmall = '1rem'
export const fontSizeMedium = '1.25rem'
export const fontSizeLarge = '1.5rem'
export const fontSizeExtraLarge = '2rem'
export const fontSizeHeading = '3rem'
export const navbarFontSize = '0.875rem'

export const fontFamilyHeader = 'var(--font-family-header)'
export const fontFamilyBody = 'var(--font-family-sans-serif)'

export const zIndexNotification = 'var(--z-index-notification, 100)'
export const navbarZindex = 'var(--z-index-modal)'
export const modalZindex = 'var(--z-index-modal)'

export const primaryDark = 'var(--primary-dark)'
export const primaryLight = 'var(--primary-light)'
export const primaryAccent = 'var(--primary-accent)'
export const secondaryDark = 'var(--secondary-dark)'
export const secondaryLight = 'var(--secondary-light)'
export const secondaryBase = 'var(--secondary-base)'
export const darkBase = 'var(--dark-base)'
export const darkAccent = 'var(--dark-accent)'
export const darkLight = 'var(--dark-light)'
export const darkDark = 'var(--dark-dark)'
export const lightDark = 'var(--light-dark)'
export const lightLight = 'var(--light-light)'
export const brandLight = 'var(--brand-light)'

export const darkDarkRgb = 'var(--dark-dark-rgb)'
export const secondaryDarkRgb = 'var(--secondary-dark-rgb)'
export const primaryBaseRgb = 'var(--primary-base-rgb)'
export const primaryBase = 'var(--primary-base)'

export const myaccountHomeWarrantyRgb = 'var(--myaccount-home-warranty-rgb)'
export const communitySolarRgb = 'var(--sunsetsky-rgb)'
export const primaryLinearGradient = 'var(--primary-linear-gradient)'
export const serviceElectric = 'var(--service-electric)'
export const serviceGas = 'var(--service-gas)'
export const serviceHomeWarranty = 'var(--rustorange)'

export const enrollmentLight = 'var(--enrollment-accent-light)'
export const enrollmentDark = 'var(--enrollment-accent-dark)'

export const defaultFontSize = 'var(--default-font-size)'
export const background = 'var(--white)'
export const accent = 'var(--primary-dark)'
export const lightAccent = 'var(--light-accent)'
export const horizontalLine = 'var(--primary-light)'
export const bodyColor = 'var(--black)'

export const sunshine15 = 'var(--sunshine15)'

export const success = 'var(--success)'
export const error = 'var(--error)'
export const warning = 'var(--warning)'
export const warningContrast = 'var(--warningContrast)'
export const white = 'var(--white)'

export const headerColor = 'var(--header-color)'
export const headerConstrastColor = 'var(--header-contrast-color)'
export const subheaderColor = 'var(--subheader-color)'
export const linkColor = 'var(--link-color)'
export const btnBackground = 'var(--btn-background)'
export const btnBorder = 'var(--btn-border)'
export const borderRadius = 'var(--border-radius)'
export const btnText = 'var(--btn-text)'
export const btnHoverBackground = 'var(--btn-hover-background)'
export const btnHoverBorder = 'var(--btn-hover-border)'
export const btnHoverText = 'var(--btn-hover-text)'
export const btnHighlightBackground = 'var(--btn-highlight-background)'
export const btnHighlightHover = 'var(--btn-highlight-hover)'
export const btnHighlightText = 'var(--btn-highlight-text)'
export const btnFontSize = 'var(--btn-font-size)'
export const linkHoverText = 'var(--link-hover-text)'
export const panelBackground = 'var(--primary-dark)'
export const boxShadowColor = '#00000026'
export const alertBackgroundColor = 'var(--alert-background-color)'
export const alertBorderColor = 'var(--alert-border-color)'
export const tabColor = 'var(--tab-color, var(--primary-base))'
export const stripeColor1 = 'var(--stripe-color-1, var(--light-accent) )'
export const stripeColor2 = 'var(--stripe-color-2, var(--light-dark) )'
export const accordionSeparatorColor = 'var(--accordion-separator-color)'
export const borderBoxColor = 'var(--border-box-color, var(--light-dark))'
export const borderBoxRadius = `var(--border-box-radius, ${Spacing.extraSmall})`
export const lightContrastBackgroundColor = 'var(--light-contrast-background-color, var(--light-accent))'
export const myaccountSpacing = `var(--myaccount-spacing, ${Spacing.medium})`

export const marketingBannerBackground = 'var(--callout-background)'
export const marketingBannerForeground = 'var(--callout-foreground)'
export const marketingBannerLinkForeground = 'var(--callout-link-foreground)'
export const marketingBannerLinkDecoration = 'var(--callout-link-decoration)'

//BF - header color is actually the color of the h1, h2, h3 on igs, but we're using as a background
// on scana. we should have some better naming convention here
export const bannerBackground = 'var(--bannerBackground, var(--header-color))'
export const bannerForeground = 'var(--bannerForeground, var(--light-light))'
export const heroBackground = 'var(--hero-background)'

export const modalButtonColor = 'var(--modal-button-color, var(--primary-base))'

export const clampReadingWidth = 'min(50ch,100%)'
