import { PartyPhoneContactResponse } from '@igs-web/common-models/models/phone-contact-response'
import { phoneContactApiClient } from '@igs-web/common-utilities/api/phone-contact-api-client'
import * as React from 'react'

interface MyProfileContext {
    readonly partyPhoneContacts: ReadonlyArray<PartyPhoneContactResponse> | undefined | null
    readonly getPartyPhoneContacts: () => void
}

const MyProfileContext = React.createContext<MyProfileContext | undefined>(undefined)

export const useMyProfile = (): MyProfileContext => {
    const context = React.useContext(MyProfileContext)
    if (!context) {
        throw Error('Must use useMyProfile inside of MyProfileProvider')
    }
    return context
}

export const MyProfileProvider = ({ children, userProfileId }: MyProfileProviderProps) => {
    const [partyPhoneContacts, setPartyPhoneContactsState] = React.useState<ReadonlyArray<PartyPhoneContactResponse> | undefined | null>(undefined)
    const getPartyPhoneContacts = async () => {
        if (userProfileId !== undefined) {
            const partyPhoneContactsResponse = await phoneContactApiClient.getPartyPhoneContacts(userProfileId)
            setPartyPhoneContactsState(partyPhoneContactsResponse)
        }
    }
    React.useEffect(() => {
        getPartyPhoneContacts()
    }, [])

    const context = {
        partyPhoneContacts,
        getPartyPhoneContacts,
    }

    return <MyProfileContext.Provider value={context}>{children}</MyProfileContext.Provider>
}

interface MyProfileProviderProps {
    readonly userProfileId: number
    readonly children: React.ReactNode
}
