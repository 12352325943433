import { getFromStorage, putInStorage } from '@igs-web/common-utilities/utilities/storage-utilities'
import { generateUuid } from '@igs-web/common-utilities/utilities/uuid-utilities'

const storageKey = 'deviceId'

export const getDeviceId = (): string => {
    const existingDeviceId = getFromStorage<string>(storageKey, true)
    if (!!existingDeviceId) {
        return existingDeviceId
    }

    const newDeviceId = generateUuid()
    putInStorage<string>(newDeviceId, storageKey, true)
    return newDeviceId
}
