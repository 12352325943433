import * as React from 'react'

import styled from 'styled-components'

import { BoxedErrorAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { LinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { MasterGrid } from '@igs-web/common-ui-components/_molecules/grid/master-grid'

/* eslint-disable react/destructuring-assignment */
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { primaryAccent } from '@igs-web/common-ui-components/styles/theme'
import { FsEvent, fireCustomFullStoryEvent } from '@igs-web/common-utilities/utilities/fs-logger'

import { BaseCompany } from '../company/company'
import { CompanyWrapperForClassComponents } from '../company/hooks/useCompany'

const StyledMasterGrid = styled(MasterGrid)`
    padding: ${Spacing.SectionGap};
    grid-column: -1 /1;
`

const StyledBoxedErrorAlert = styled(BoxedErrorAlert)`
    text-align: center;
    padding: ${Spacing.ExtraLarge};
`
const StyledLinkbutton = styled(LinkButton)`
    width: max-content;
    margin-top: ${Spacing.SectionGap};
`
const StyledPhoneNumberLink = styled(PhoneNumberLink)`
    color: ${primaryAccent};
    font-weight: 800;
`

interface ErrorBoundaryProps {
    readonly children: React.ReactNode
}

interface ErrorBoundaryWrapperProps extends ErrorBoundaryProps {
    readonly company: BaseCompany
}

export const ErrorBoundary = (props: ErrorBoundaryWrapperProps) => (
    <CompanyWrapperForClassComponents>{(company: BaseCompany) => <ErrorBoundaryInner {...props} company={company} />}</CompanyWrapperForClassComponents>
)

class ErrorBoundaryInner extends React.Component<ErrorBoundaryWrapperProps, { readonly hasError: boolean }> {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        fireCustomFullStoryEvent(FsEvent.genericError, { error, errorInfo })
    }

    render() {
        if (this.state.hasError) {
            return (
                <StyledMasterGrid>
                    <StyledBoxedErrorAlert>
                        <p>Our site ran into an error. We sincerely apologize for the inconvenience.</p>
                        <p>
                            Please give us a call {this.props.company.businessHours.businessHoursAbbreviated} at{' '}
                            <StyledPhoneNumberLink phoneNumber={this.props.company.phoneNumber.help} />, or email us anytime at{' '}
                            {this.props.company.emails.customerSupport}
                        </p>
                    </StyledBoxedErrorAlert>

                    <StyledLinkbutton href={this.props.company.myAccountUrl}>Return to the home page</StyledLinkbutton>
                </StyledMasterGrid>
            )
        }

        return this.props.children
    }
}
