export interface Locale {
    readonly name: string
    readonly language: string
    readonly timeZone: string
}

export const easternLocale: Locale = {
    name: 'Eastern',
    language: 'en-US',
    timeZone: 'America/New_York',
}

export const centralLocale: Locale = {
    name: 'Central',
    language: 'en-US',
    timeZone: 'America/Chicago',
}

export const locales = {
    eastern: easternLocale,
    central: centralLocale
}