import { loadingManager } from '@igs-web/common-utilities/utilities/loading-manager-utilities'
import * as React from 'react'

let loaderAdded = false

export const PageChangeLoader = (): JSX.Element => {
    if (!loaderAdded) {
        window.addEventListener('beforeunload', () => loadingManager.addLoaderForDuration(500))
        window.addEventListener('unload', loadingManager.removeLoader)
        loaderAdded = true
    }

    return <></>
}
