import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import { isValid } from 'date-fns'
import { get } from 'lodash-es'
import styled from 'styled-components'

import { BoxedErrorAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { Select, SelectOption } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { Layout1fr1fr1frColumn } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { rangeInclusive } from '@igs-web/common-utilities/utilities/array-utilities'
import { getDayRange, months, subtractYears } from '@igs-web/common-utilities/utilities/date-utilities'

const StyledBoxedErrorAlert = styled(BoxedErrorAlert)`
    margin-top: ${Spacing.Medium};
`

const today = new Date()

//-- This will limit the selectable years to between the last 120 and 18 years
const MIN_YEAR_RANGE = 18
const MAX_YEAR_RANGE = 120
const defaultDateMin = subtractYears(today, MIN_YEAR_RANGE)
const defaultDateMax = subtractYears(today, MAX_YEAR_RANGE)

const DEFAULT_ERR_MSG = 'You have entered an invalid date of birth. Please re-enter your date of birth.'

const getYearRange = (minDate: Date, maxDate: Date) => rangeInclusive(maxDate.getFullYear(), minDate.getFullYear()).reverse()

export const DateInputForm = ({
    formKey = '',
    fieldName = 'date',
    idPrefix,
    date,
    minDate = defaultDateMin,
    maxDate = defaultDateMax,
    minValidationFailedMessage = DEFAULT_ERR_MSG,
    maxValidationFailedMessage = DEFAULT_ERR_MSG,
}: Props) => {
    const {
        register,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext()

    const yearRange = getYearRange(minDate, maxDate)

    const monthField = `${formKey}month`
    const dayField = `${formKey}day`
    const yearField = `${formKey}year`
    const dateField = `${formKey}${fieldName}`

    const selectedMonth: number = parseInt(watch(monthField), 10)
    const selectedDay: number = parseInt(watch(dayField), 10)
    const selectedYear: number = parseInt(watch(yearField), 10)
    const [dateErrorMessage, setDateErrorMessage] = React.useState<JSX.Element | string>('')

    const dateFieldErrors = get(errors, `${dateField}`)

    register(dateField, {
        validate: value => {
            if (isValid(value)) {
                if (value > minDate) {
                    setDateErrorMessage(minValidationFailedMessage)
                    return false
                }
                if (value < maxDate) {
                    setDateErrorMessage(maxValidationFailedMessage)
                    return false
                }
            }
            return true
        },
    })
    setValue(dateField, new Date(selectedYear, selectedMonth, selectedDay))

    return (
        <div>
            <Layout1fr1fr1frColumn>
                <Select
                    {...register(monthField, { required: 'Required' })}
                    dataTestId={`${idPrefix}-month-field`}
                    defaultValue={date?.getMonth()}
                    label="Date of Birth"
                    error={get(errors, `${monthField}`)?.message?.toString()}
                    hideHelperText={!get(errors, `${monthField}`)}
                    allowClear={false}
                >
                    {months.map(m => (
                        <SelectOption dataTestId={`${idPrefix}-${m.name}`} key={m.name} value={m.ordinal - 1}>
                            {m.name}
                        </SelectOption>
                    ))}
                </Select>
                <Select
                    {...register(dayField, { required: 'Required' })}
                    dataTestId={`${idPrefix}-day-field`}
                    defaultValue={date?.getDay()}
                    error={get(errors, `${dayField}`)?.message?.toString()}
                    hideHelperText={!get(errors, `${dayField}`)}
                    allowClear={false}
                    label="Day"
                >
                    {getDayRange(watch(monthField)).map(d => (
                        <SelectOption dataTestId={`${idPrefix}-day-${d}`} key={d} value={d}>
                            {d}
                        </SelectOption>
                    ))}
                </Select>
                <Select
                    {...register(yearField, { required: 'Required' })}
                    dataTestId={`${idPrefix}-year-field`}
                    defaultValue={date?.getFullYear()}
                    error={get(errors, `${yearField}`)?.message?.toString()}
                    hideHelperText={!get(errors, `${yearField}`)}
                    allowClear={false}
                    label="Year"
                >
                    {yearRange.map(y => (
                        <SelectOption dataTestId={`${idPrefix}-year-${y}`} key={y} value={y}>
                            {y}
                        </SelectOption>
                    ))}
                </Select>
            </Layout1fr1fr1frColumn>
            {dateFieldErrors && <StyledBoxedErrorAlert message={dateErrorMessage} />}
        </div>
    )
}

interface Props {
    readonly formKey?: string
    readonly idPrefix: string
    readonly fieldName?: string
    readonly date?: Date | null
    readonly minDate?: Date
    readonly maxDate?: Date
    readonly minValidationFailedMessage?: JSX.Element | string
    readonly maxValidationFailedMessage?: JSX.Element | string
}
