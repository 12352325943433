import { CheckoutStepPageIds } from '@igs-web/common-components/domain/checkout/checkout-step-page-ids'
import { ProductModel } from '@igs-web/common-models/models'
import { Address } from '@igs-web/common-models/models/address'
import { CreditCheckResponse } from '@igs-web/common-utilities/api/enrollment-api-client'

export const hwShowAddressCard = (activePageId?: string) => {
    switch (activePageId) {
        case CheckoutStepPageIds.ContactInfo:
        case CheckoutStepPageIds.DirectBillPreference:
        case CheckoutStepPageIds.OrderSummary:
            return true
        default:
            return false
    }
}

export const hwShowMyDetailsCard = (activePageId?: string) => {
    switch (activePageId) {
        case CheckoutStepPageIds.DirectBillPreference:
        case CheckoutStepPageIds.OrderSummary:
            return true
        default:
            return false
    }
}
export const hwShowYourPlan = (activePageId?: string) => {
    if (activePageId === CheckoutStepPageIds.OrderSummary) {
        return false
    }
    return true
}

export const gasShowAddressCard = (activePageId?: string, address?: Address) => {
    return activePageId !== CheckoutStepPageIds.CustomerAddress && !!address && !!address.address1 && !!address.city && !!address.state && !!address.zipCode
}

export const gasShowMyDetailsCard = (firstName: string | null | undefined) => {
    return !!firstName
}
export const gasShowCreditCheckCard = (creditCheckResults: CreditCheckResponse | undefined) => {
    return !!creditCheckResults
}

export const gasShowRateCard = (product: ProductModel | undefined) => {
    return !!product
}
