import { CommunitySolarAccount } from './community-solar-model'
import { LineOfBusinessCode } from './line-of-business'
import { UserProfileAccount } from './user-profile-model'

export enum AccountStatus {
    unknown = 'Unknown',
    active = 'Active',
    pending = 'Pending',
    inactive = 'InActive',
    pendingReload = 'PendingReload',
}

export interface BaseAccount {
    readonly accountIdentifier: string //SubscriptionKey or PartyAccountId
    readonly serviceAddressKey: string
    readonly status: AccountStatus
    readonly lineOfBusinessCode: LineOfBusinessCode
    readonly termStartDate?: number
    readonly termEndDate?: number
}

export type Account = UserProfileAccount | CommunitySolarAccount
