import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { CachedReduxDataStateType, setupCachedReduxData } from '../cached-redux/cached-redux-data'

const load = async (accountId: number, config: RequestConfig) => {
    try {
        return (await customerInfoApiClient.getUtilityAccountDetails(accountId, config)) || undefined
    } catch (e) {
        console.error(`There was a problem getting account details for account: ${accountId}`)
    }
}

const { actions, selectors, saga, reducers } = setupCachedReduxData('utilityAccountDetails', load)

export const utilityAccountDetailsRedux = {
    actions,
    selectors: {
        ...selectors,
        selectIsAutoPayRegistered: (state: CommonReduxState, accountId: number) => {
            const details = selectors.selectData(state, accountId)
            return details && details.isRegisteredForAutopay
        },
    },
}

export const utilityAccountDetailsSaga = saga
export const utilityAccountDetailsReducer = reducers
export type UtilityAccountDetailsState = CachedReduxDataStateType<typeof load>
