import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const CircleCheckmark = ({ altText = 'Completed', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} altText={altText} width="50" height="50" viewBox="0 0 50 50">
        <g id="Group_541" data-name="Group 541" transform="translate(4776 -3166)">
            <g id="Ellipse_208" data-name="Ellipse 208" transform="translate(-4776 3166)" className="icon-border-circle" strokeWidth="3">
                <circle cx="25" cy="25" r="25" stroke="none" />
                <circle cx="25" cy="25" r="23.5" fill="none" />
            </g>
            <path id="check-solid" d="M8.681,83.783.375,75.477a1.278,1.278,0,0,1,0-1.807l1.807-1.807a1.278,1.278,0,0,1,1.807,0l5.6,5.6L21.57,65.473a1.278,1.278,0,0,1,1.807,0l1.807,1.807a1.278,1.278,0,0,1,0,1.807l-14.7,14.7a1.278,1.278,0,0,1-1.807,0Z" transform="translate(-4763.78 3116.372)" className="icon-core" />
        </g>
    </SvgIcon>
)

export default CircleCheckmark