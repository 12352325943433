import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { Input } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

const StyledForm = styled.div`
    display: grid;
    grid-template-areas: 'realtorName realtorId';
    align-items: center;
    gap: ${Spacing.ExtraLarge};
    margin-bottom: ${Spacing.Medium};
`

const StyledRealtorName = styled(Input)`
    grid-area: realtorName;
`

const StyledRealtorId = styled(Input)`
    grid-area: realtorId;
`

export const RealtorForm = () => {
    const { register, formState: { errors } } = useFormContext<RealtorFormFields>()
    const enrollmentState = useCheckoutState()
    return (
        <StyledForm>
            <StyledRealtorName
                {...register('realtorName', { required: 'Required', shouldUnregister: true })}
                dataTestId="select-rate-realtor-name"
                defaultValue={enrollmentState.realtorName}
                label="Realtor Name"
                hideHelperText={!errors.realtorName?.message}
                error={errors.realtorName?.message}
            />
            <StyledRealtorId
                {...register('realtorId', { shouldUnregister: true })}
                dataTestId="select-rate-realtor-id"
                defaultValue={enrollmentState.realtorId}
                label="Realtor Id"
                hideHelperText={true}
            />
        </StyledForm>
    )
}

export interface RealtorFormFields{
    readonly realtorName: string
    readonly realtorId: string
}