import { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { CreatedAction, createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'

export type CachedReduxDataStateType<T> = CachedReduxDataState<T extends CachedReduxLoader<infer U> ? U : T>

export interface CachedReduxDataState<T> {
    readonly data: {
        readonly [key: string]: T | undefined
    }
    readonly error: {
        readonly [key: string]: string | undefined
    }
    readonly isLoading: {
        readonly [key: string]: boolean | undefined
    }
}

interface CachedReduxDataRequest extends RequestConfig {
    readonly key: CacheKey
    readonly ignoreCache?: boolean
}
interface CachedReduxDataMultipleRequest extends RequestConfig {
    readonly keys: ReadonlyArray<CacheKey>
}

type CacheKey = string | number | CacheObject

interface CacheObject {
    readonly key: string | number
    readonly data: any
}

interface CachedReduxDataResponse<T> {
    readonly key: string | number
    readonly data?: T | undefined
    readonly error?: string
}

interface CachedReduxDataMultipleResponse<T> {
    readonly data: Record<string | number, T>
    readonly error?: string
}

type CachedReduxLoader<T> = (key: string | number | any, config: RequestConfig) => Promise<T>
type CacheReduxLoadMultiple<T> = (keys: ReadonlyArray<string | number | any>, config: RequestConfig) => Promise<Record<string | number, T>>

export interface CachedReduxDataActions {
    readonly load: CreatedAction<CachedReduxDataRequest>
    readonly loadMultiple: CreatedAction<CachedReduxDataMultipleRequest>
    readonly success: CreatedAction<CachedReduxDataResponse<any>>
    readonly fail: CreatedAction<CachedReduxDataResponse<any>>
    readonly clear: CreatedAction<string | number>
    readonly clearAll: CreatedAction<void>
}

export interface CachedReduxDataSelectors<T> {
    readonly selectData: (state: CommonReduxState, key: string | number | any) => T | undefined
    readonly selectAllData: (state: CommonReduxState) => { readonly [key: string]: T | undefined }
    readonly selectLoading: (state: CommonReduxState, key: string | number) => boolean | undefined
    readonly selectError: (state: CommonReduxState, key: string | number) => string | undefined
}

export interface CachedReduxData<T> {
    readonly actions: CachedReduxDataActions
    readonly selectors: CachedReduxDataSelectors<T>
    readonly saga: () => SagaIterator
    readonly reducers: (state: CachedReduxDataState<T> | undefined, action: any) => CachedReduxDataState<T>
}

export const setupCachedReduxData = <T>(
    name: keyof CommonReduxState,
    loader: CachedReduxLoader<T>,
    loaderMultiple?: CacheReduxLoadMultiple<T>,
): CachedReduxData<T> => {
    const actionTypes = {
        LOAD: `[${name}] GET CACHED DATA // LOAD`,
        SUCCESS: `[${name}] GET CACHED DATA // SUCCESS`,
        FAIL: `[${name}] GET CACHED DATA // FAIL`,
        LOADMULTIPLE: `[${name}] GET CACHED DATA // LOAD MULTIPLE`,
        SUCCESSMULTIPLE: `[${name}] GET CACHED DATA // SUCCESS MULTIPLE`,
        CLEAR: `[${name}] GET CACHED DATA // CLEAR`,
        CLEARALL: `[${name}] GET CACHED DATA // CLEARALL`,
    }

    const actions = {
        load: createAction<CachedReduxDataRequest>(actionTypes.LOAD),
        success: createAction<CachedReduxDataResponse<any>>(actionTypes.SUCCESS),
        fail: createAction<CachedReduxDataResponse<any>>(actionTypes.FAIL),
        loadMultiple: createAction<CachedReduxDataMultipleRequest>(actionTypes.LOADMULTIPLE),
        successMultiple: createAction<CachedReduxDataMultipleResponse<any>>(actionTypes.SUCCESSMULTIPLE),
        clear: createAction<string | number>(actionTypes.CLEAR),
        clearAll: createAction<void>(actionTypes.CLEARALL),
    }

    const processKey = (key: CacheKey): number | string => (typeof key === 'object' ? key.key : key)

    const reducers = reducer<CachedReduxDataState<T>>({ data: {}, isLoading: {}, error: {} })
        .add<CachedReduxDataRequest>(actionTypes.LOAD, (state, request) => ({
            ...state,
            isLoading: {
                ...state.isLoading,
                [processKey(request.key)]: true,
            },
        }))
        .add<CachedReduxDataResponse<any>>(actionTypes.SUCCESS, (state, response) => ({
            ...state,
            isLoading: {
                ...state.isLoading,
                [processKey(response.key)]: false,
            },
            data: {
                ...state.data,
                [processKey(response.key)]: response.data,
            },
        }))
        .add<string | number>(actionTypes.CLEAR, (state, key) => ({
            ...state,
            data: {
                ...state.data,
                [key]: undefined,
            },
        }))
        .add<void>(actionTypes.CLEARALL, state => ({
            ...state,
            data: {},
        }))
        .add<CachedReduxDataResponse<any>>(actionTypes.FAIL, (state, response) => ({
            ...state,
            isLoading: {
                ...state.isLoading,
                [processKey(response.key)]: false,
            },
            error: {
                ...state.error,
                [processKey(response.key)]: response.error,
            },
        }))
        .add<CachedReduxDataMultipleRequest>(actionTypes.LOADMULTIPLE, (state, request) => {
            const isLoading = request.keys.reduce(
                (o, key) => ({
                    ...o,
                    [processKey(key)]: true,
                }),
                {},
            )

            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    ...isLoading,
                },
            }
        })
        .add<CachedReduxDataMultipleResponse<any>>(actionTypes.SUCCESSMULTIPLE, (state, response) => {
            const { data } = response

            const keys = Object.keys(data)
            const isLoading = keys.reduce(
                (o, key) => ({
                    ...o,
                    [processKey(key)]: false,
                }),
                {},
            )

            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    ...isLoading,
                },
                data: {
                    ...state.data,
                    ...data,
                },
            }
        })
        .build()

    const getStateObj = (state: CommonReduxState) => (state[name] as unknown) as CachedReduxDataState<T>

    const selectors = {
        selectData: (state: CommonReduxState, key: string | number) => getStateObj(state).data[key],
        selectAllData: (state: CommonReduxState) => getStateObj(state).data,
        selectLoading: (state: CommonReduxState, key: string | number) => getStateObj(state).isLoading[key],
        selectError: (state: CommonReduxState, key: string | number) => getStateObj(state).error[key],
    }

    function* load(action: { readonly payload: CachedReduxDataRequest }) {
        const { key, ignoreCache } = action.payload
        const cacheId = processKey(key)
        const cachedValue = (yield select(selectors.selectData, cacheId)) as T
        if (ignoreCache) {
            yield put(actions.clear(cacheId))
        }

        if ((!cachedValue || ignoreCache) && loader) {
            try {
                const data = (yield call(() => loader(key, action.payload))) as T
                yield put(actions.success({ key: cacheId, data }))
            } catch (e) {
                yield put(actions.fail({ key: cacheId, error: e }))
            }
        } else if (cachedValue) {
            yield put(actions.success({ key: cacheId, data: cachedValue }))
        }
    }

    function* loadMultiple(action: { readonly payload: CachedReduxDataMultipleRequest }) {
        const { keys } = action.payload

        if (keys.length && loaderMultiple) {
            try {
                const data = (yield call(() => loaderMultiple(keys, action.payload))) as Record<string | number, T>
                yield put(actions.successMultiple({ data }))
            } catch (e) {
                yield put(actions.fail({ key: 'multiple', error: e }))
            }
        }
    }

    function* saga(): SagaIterator {
        yield all([takeEvery(actionTypes.LOAD, load as any)])
        yield all([takeEvery(actionTypes.LOADMULTIPLE, loadMultiple as any)])
    }
    return {
        actions,
        saga,
        reducers,
        selectors,
    }
}
