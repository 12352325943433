import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import { CommunitySolarAccount } from '@igs-web/common-models/models/community-solar-model'
import { LineOfBusinessCode, LineOfBusinessConstant } from '@igs-web/common-models/models/line-of-business'
import { UserProfileAccount } from '@igs-web/common-models/models/user-profile-model'
import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { ConfirmModal } from '@igs-web/common-ui-components/_molecules/modals/confirm-modal'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { getFormattedAddress } from '@igs-web/common-utilities/utilities/address-utilities'

import { CommonReduxState } from '../common-redux'
import { useCompany } from '../company/hooks/useCompany'
import { modalActions } from '../ui-state/modal-redux'
import { UserSelectors, userActions } from '../user/user-redux'

const StyledButton = styled(Button)`
    padding-right: 0.75rem;
    padding-left: 0.75rem;
`
const delayedReloadTimer = 100

const AccountList = ({ accounts, subscriptions }: AccountListProps) => {
    const addressKeys = [...new Set(accounts.map(a => a.serviceAddressKey).concat(subscriptions.map(s => s.serviceAddressKey)))]
    const serviceAddresses = useSelector((store: CommonReduxState) => UserSelectors.selectUserServiceAddresses(store))
    const filteredAddresses = serviceAddresses.filter(s => addressKeys.includes(s.serviceAddressKey))

    return (
        <>
            {filteredAddresses.map(address => (
                <div key={address.serviceAddressKey}>
                    <div>
                        <strong>{getFormattedAddress(address)}</strong>
                    </div>
                    {accounts
                        .filter(({ serviceAddressKey }) => serviceAddressKey === address.serviceAddressKey)
                        .map(account => {
                            const lob = LineOfBusinessConstant.getByCode(account.lineOfBusinessCode)
                            const accountDescription = [account.utilityName, lob ? lob.title : null, account.accountNumber].filter(o => !!o).join(' - ')

                            return <div key={account.accountId}>{accountDescription}</div>
                        })}
                    {subscriptions
                        .filter(s => s.serviceAddressKey === address.serviceAddressKey)
                        .map(subscription => {
                            const lob = LineOfBusinessConstant.getByCode(subscription.lineOfBusinessCode)
                            return (
                                <div key={subscription.subscriptionKey}>
                                    {lob.title} - {subscription.subscriptionReferenceNumber}{' '}
                                </div>
                            )
                        })}
                </div>
            ))}
        </>
    )
}
interface AccountListProps {
    readonly accounts: ReadonlyArray<UserProfileAccount>
    readonly subscriptions: ReadonlyArray<CommunitySolarAccount>
}

export const RemoveAccountButton = ({ lineOfBusinessCodes, accountsToBeRemoved, subscriptionsToBeRemoved, ...htmlProps }: RemoveAccountButtonProps) => {
    const dispatch = useDispatch()
    const { phoneNumber } = useCompany()
    const loadUserProfile = () => dispatch(userActions.loadUser())
    const openConfirmModal = (modalId: string) => dispatch(modalActions.openModal(modalId))
    const commodityLobs = lineOfBusinessCodes.filter(l => LineOfBusinessConstant.commodityCodes.some(c => c === l))
    const removeAccount = async (): Promise<void> => {
        if (commodityLobs.length > 0 && accountsToBeRemoved.length > 0) {
            await customerInfoApiClient.unlinkAccount({ lineOfBusinessCode: commodityLobs[0], accountIdentifier: accountsToBeRemoved[0].partyId.toString() })
        }
        if (lineOfBusinessCodes.includes(LineOfBusinessCode.CommunitySolar) && subscriptionsToBeRemoved.length > 0) {
            subscriptionsToBeRemoved.forEach(async s => {
                await customerInfoApiClient.unlinkAccount({ lineOfBusinessCode: LineOfBusinessCode.CommunitySolar, accountIdentifier: s.subscriptionKey })
            })
        }
        await new Promise(resolve => setTimeout(resolve, delayedReloadTimer))
        await loadUserProfile()
    }

    const modalId = `remove-account-confirm-${Math.random()}`

    return (
        <>
            <StyledButton onClick={() => openConfirmModal(modalId)} {...htmlProps}>
                Remove
            </StyledButton>
            <ConfirmModal uiKey={modalId} onConfirm={removeAccount} showCancel={true}>
                This will remove the following accounts from your Energy Account list. If you are moving or would like to cancel these accounts, please contact
                us at <PhoneNumberLink dataTestId="remove-account-phonenumber-link" phoneNumber={phoneNumber.help} />.
                <AccountList accounts={accountsToBeRemoved} subscriptions={subscriptionsToBeRemoved} />
            </ConfirmModal>
        </>
    )
}

interface RemoveAccountButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    readonly lineOfBusinessCodes: ReadonlyArray<LineOfBusinessCode>
    readonly accountsToBeRemoved: ReadonlyArray<UserProfileAccount>
    readonly subscriptionsToBeRemoved: ReadonlyArray<CommunitySolarAccount>
}
