import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const CircleTag = ({ altText = 'Contact Info', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} altText={altText} width="50" height="50" viewBox="0 0 50 50">
        <g id="Group_533" data-name="Group 533">
            <g id="Group_532" data-name="Group 532">
                <g id="Ellipse_208" data-name="Ellipse 208" className="icon-border-circle" strokeWidth="3">
                    <circle cx="25" cy="25" r="25" id="circle3732" stroke="none" />
                    <circle cx="25" cy="25" r="23.5" id="circle3734" fill="#ffffff" />
                </g>
            </g>
            <path
                className="icon-core"
                d="m 13.877187,11.394044 a 1.9200793,2.0029692 0 0 0 -1.92008,2.00297 v 9.185616 a 1.9200793,2.0029692 0 0 0 0.562584,1.4161 l 13.440554,14.020784 a 1.9200793,2.0029692 0 0 0 2.714992,0 l 8.805484,-9.185617 a 1.9200793,2.0029692 0 0 0 0,-2.832198 L 24.040167,11.980914 a 1.9200793,2.0029692 0 0 0 -1.357497,-0.58687 z m 7.680316,7.010392 a 2.880119,3.004454 0 1 1 -5.760238,0 2.880119,3.004454 0 0 1 5.760238,0 z"
                id="path3738"
                clipRule="evenodd"
                fillRule="evenodd"
                strokeWidth="1.96108639"
            />
        </g>
    </SvgIcon>
)

export default CircleTag
