import * as React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { AuthorizedUser, PartyAuthorizedUsersResponse } from '@igs-web/common-models/models/authorized-user-model'
import { ErrorAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { Input } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { EditableGrid } from '@igs-web/common-ui-components/_molecules/editable-grid/editable-grid'
import { useEditableGridDispatch } from '@igs-web/common-ui-components/_molecules/editable-grid/editable-grid-context'
import { error } from '@igs-web/common-ui-components/styles/theme'
import { authorizedUserApiClient } from '@igs-web/common-utilities/api/authorized-user-api-client'

import { CommonReduxState } from '../common-redux'
import { PartySelector } from '../party'
import { UserSelectors } from '../user/user-redux'

const StyledInput = styled(Input)`
    span {
        color: ${error};
    }
`

const DisplayRow = ({ firstName, lastName }: AuthorizedUser) => {
    return (
        <>
            <p>{firstName}</p>
            <p>{lastName}</p>
        </>
    )
}

const EditRow = ({ firstName, lastName, authorizedUserId, setData }: EditRowProps) => {
    const [formState, setFormState] = React.useState<FormState>({ firstName, lastName, firstNameError: undefined, lastNameError: undefined })
    const { firstName: firstNameInput, lastName: lastNameInput, firstNameError, lastNameError } = formState
    const { setIsValid } = useEditableGridDispatch()

    React.useEffect(() => {
        setData({ firstName: firstNameInput, lastName: lastNameInput, authorizedUserId })
    }, [firstNameInput, lastNameInput, authorizedUserId, setData])

    React.useEffect(() => {
        if (formState.firstName.length && formState.lastName.length) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [formState])

    return (
        <>
            <StyledInput
                dataTestId={'edit-first-name'}
                onChange={e => {
                    const value = e.currentTarget.value
                    if (value && value !== '') {
                        setFormState({ ...formState, firstName: value, firstNameError: undefined })
                    } else {
                        setFormState({ ...formState, firstName: value, firstNameError: 'First name is required' })
                    }
                }}
                helperText={firstNameError}
                defaultValue={firstName ?? ''}
                maxLength={100}
            />
            <StyledInput
                dataTestId={'edit-last-name'}
                onChange={e => {
                    const value = e.currentTarget.value
                    if (value && value !== '') {
                        setFormState({ ...formState, lastName: value, lastNameError: undefined })
                    } else {
                        setFormState({ ...formState, lastName: value, lastNameError: 'Last name is required' })
                    }
                }}
                defaultValue={lastName ?? ''}
                helperText={lastNameError}
                maxLength={100}
            />
        </>
    )
}

interface EditRowProps extends AuthorizedUser {
    readonly setData: (authorizedUser: AuthorizedUser) => void
}

const defaultAuthorizedUser: AuthorizedUser = {
    firstName: '',
    lastName: '',
}

interface FormState extends AuthorizedUser {
    readonly firstNameError: string | undefined
    readonly lastNameError: string | undefined
}

export const AuthorizedUsersSection = () => {
    const userProfile = useSelector((store: CommonReduxState) => UserSelectors.selectProfile(store))
    const [selectedPartyNumber, setSelectedPartyNumber] = React.useState<string | null>(null)
    const [partyAuthorizedUsers, setPartyAuthorizedUsers] = React.useState<ReadonlyArray<PartyAuthorizedUsersResponse> | null>(null)
    const [selectedPartyAuthorizedUsers, setSelectedPartyAuthorizedUsers] = React.useState<PartyAuthorizedUsersResponse | null>(null)
    const [authorizedUsers, setAuthorizedUsers] = React.useState<ReadonlyArray<AuthorizedUser>>([])
    const [requestError, setRequestError] = React.useState<string>()

    const headers = ['First Name', 'Last Name']
    const noDataMessage = 'No authorized users found for this account.'

    const getPartyAuthorizedUsers = async () => {
        if (userProfile !== undefined) {
            const response = await authorizedUserApiClient.getPartyAuthorizedUsers(userProfile.userProfileId)
            if (response !== null) {
                setPartyAuthorizedUsers(response)
                if (response.length === 1) {
                    setSelectedPartyNumber(response[0].partyNumber)
                    setAuthorizedUsers(response[0].authorizedUsers)
                } else if (response.some(pau => pau.partyNumber === selectedPartyNumber)) {
                    setAuthorizedUsers(response.filter(pau => pau.partyNumber === selectedPartyNumber)[0].authorizedUsers)
                }
            }
        }
    }

    const handleSave = async (authorizedUser: AuthorizedUser) => {
        const { firstName, lastName, authorizedUserId } = authorizedUser
        if (!selectedPartyNumber) {
            return
        }
        if (authorizedUserId && authorizedUserId > 0) {
            try {
                await authorizedUserApiClient.editAuthorizedUser({
                    firstName,
                    lastName,
                    authorizedUserId,
                })
                setRequestError(undefined)
                getPartyAuthorizedUsers()
            } catch (e) {
                setRequestError(e.message)
            }
        } else {
            try {
                await authorizedUserApiClient.addAuthorizedUser({
                    PartyNumber: selectedPartyNumber,
                    firstName,
                    lastName,
                })
                setRequestError(undefined)
                getPartyAuthorizedUsers()
            } catch (e) {
                setRequestError(e.message)
            }
        }
    }

    const handleDelete = async (authorizedUser: AuthorizedUser) => {
        if (!selectedPartyNumber || !authorizedUser.authorizedUserId || authorizedUser.authorizedUserId < 1) {
            setAuthorizedUsers(authorizedUsers.filter(au => au.authorizedUserId !== authorizedUser.authorizedUserId))
            return
        }
        try {
            await authorizedUserApiClient.deleteAuthorizedUser(authorizedUser.authorizedUserId)
            setAuthorizedUsers(authorizedUsers.filter(au => au.authorizedUserId !== authorizedUser.authorizedUserId))
            getPartyAuthorizedUsers()
        } catch (e) {
            setRequestError(e.message)
        }
    }

    const handleAdd = () => {
        const minIndex = Math.min(...authorizedUsers.map(x => x.authorizedUserId || 0))
        setAuthorizedUsers([...authorizedUsers, { ...defaultAuthorizedUser, authorizedUserId: minIndex < 0 ? minIndex - 1 : -1 }])
    }

    React.useEffect(() => {
        getPartyAuthorizedUsers()
    }, [userProfile])

    React.useEffect(() => {
        const party = partyAuthorizedUsers?.find(pau => pau.partyNumber === selectedPartyNumber)
        if (party) {
            setSelectedPartyAuthorizedUsers(party)
            setAuthorizedUsers(party.authorizedUsers)
        }
    }, [selectedPartyNumber, partyAuthorizedUsers])

    return (
        <>
            {partyAuthorizedUsers && partyAuthorizedUsers.length > 1 && (
                <PartySelector parties={partyAuthorizedUsers} setSelectedPartyNumber={setSelectedPartyNumber} />
            )}
            {requestError && <ErrorAlert message={requestError} />}
            {selectedPartyAuthorizedUsers && (
                <EditableGrid<AuthorizedUser>
                    headers={headers}
                    data={authorizedUsers}
                    DisplayRow={DisplayRow}
                    EditRow={EditRow}
                    accessor={'authorizedUserId'}
                    noDataMessage={noDataMessage}
                    handleSave={handleSave}
                    handleDelete={handleDelete}
                    handleAdd={handleAdd}
                />
            )}
        </>
    )
}
