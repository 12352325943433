import * as React from 'react'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { LegalRoutes } from '@igs-web/common-components/domain/legal/legal-routes'
import { EmailLink } from '@igs-web/common-ui-components/_atoms/link/email-link'
import { ExternalLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import FaqSection from '@igs-web/common-ui-components/_molecules/faq/faq-section'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'

import { NavigationBanner } from 'molecules/header/navigation-banner'
import { ScrollToTop } from 'molecules/routing/scroll-to-top'

import * as Routes from 'domain/routes'

export const HwFAQsPage = () => {
    const company = useCompany()

    return (
        <>
            <ScrollToTop />
            <NavigationBanner title="Home Warranty - FAQs" />
            <PageContentContainer>
                <Header3>About SCANA Home Warranty</Header3>
                <FaqSection
                    FAQs={[
                        {
                            question: 'What is a home warranty?',
                            answer: (
                                <p>
                                    A home warranty is a plan that provides you with protection against the cost of unexpected repairs due to normal wear and
                                    tear of systems in your home. A good home warranty will also make it easy to get a quality independent contractor out to
                                    your home.
                                </p>
                            ),
                        },
                        {
                            question: 'Who is SCANA Home Warranty?',
                            answer: (
                                <p>
                                    SCANA Home Warranty is an affiliate of SCANA Energy, one of the largest natural gas marketers in Georgia, serving homes and
                                    businesses across the state since 1998.
                                </p>
                            ),
                        },
                        {
                            question: 'What makes SCANA Home Warranty different?',
                            answer: (
                                <p>
                                    We know Georgia, so we know how to protect Georgia's homes and work with the community. We are available to help you
                                    understand what's protected (and what's not) and get you connected with one of our trusted independent contractors who can
                                    help.
                                </p>
                            ),
                        },
                        {
                            question: 'What is the difference between a home warranty and homeowners insurance?',
                            answer: (
                                <p>
                                    Homeowners insurance covers events that may happen to your home, such as theft or damage due to natural disasters or
                                    accidents. Insurance claims typically require you to pay a deductible and often result in increased premiums. When many of
                                    the systems in your home fail due to normal wear and tear, they are typically not covered by insurance. A home warranty
                                    picks up where your homeowners insurance leaves off and helps pay for the repairs that you'll need from failures that will
                                    happen over time.
                                </p>
                            ),
                        },
                        {
                            question: 'Can I buy a SCANA home warranty for a rental property or mobile home?',
                            answer: (
                                <p>
                                    No, our home warranty plans are available only for your primary residence or second home, owned by you and permanently
                                    attached to land also owned by you. In addition, there can be no commercial use of your home.
                                </p>
                            ),
                        },
                        {
                            question: 'Can I buy a SCANA home warranty for a duplex I own and live in?',
                            answer: (
                                <p>
                                    Yes, but our plans do not protect shared lines. Outside lines are typically shared in a multi-family building. However, you
                                    can use one of our plans to protect inside lines and non-shared items like your own water heater or heating and cooling
                                    system.
                                </p>
                            ),
                        },
                        {
                            question: 'What is a “failure”?',
                            answer: (
                                <p>
                                    A protected item fails when it becomes inoperable and cannot perform its primary purpose as intended by the item's
                                    manufacturer.
                                </p>
                            ),
                        },
                        {
                            question: 'What is “normal wear and tear"?',
                            answer: <p>Normal wear and tear occurs over time as a result of standard use as intended by the item's manufacturer.</p>,
                        },
                        {
                            question: 'What does my protection plan include?',
                            answer: (
                                <p>
                                    Depending on your plan, our{' '}
                                    <ExternalLink href={LegalRoutes.homeWarrantyServiceAgreement().toString()}>Service Agreement</ExternalLink> describes what's
                                    included and outlines what's not included. If it's still not clear, just call{' '}
                                    <PhoneNumberLink phoneNumber={company.phoneNumber.homeWarranty} /> and a customer care representative will help.
                                </p>
                            ),
                        },
                    ]}
                />
                <Header3>About SCANA Home Warranty</Header3>
                <FaqSection
                    FAQs={[
                        {
                            question: 'How do I request service/repairs?',
                            answer: (
                                <p>
                                    Call us at <PhoneNumberLink phoneNumber={company.phoneNumber.homeWarranty} /> and we will walk you through the process. If
                                    it's not urgent, you can also <a href={Routes.HwServiceRequests}>fill out this form</a> and a customer care representative
                                    will be in touch.
                                </p>
                            ),
                        },
                        {
                            question: 'When can I start using my home warranty?',
                            answer: <p>There is a 15-day waiting period before you can request service.</p>,
                        },
                        {
                            question: 'Do you use local independent contractors? ',
                            answer: (
                                <p>
                                    Yes, we select the highest-quality independent contractors and service technicians for the areas we serve. When you call,
                                    we'll find the right contractor for your situation. Please note that in rare cases when you may need to work with someone
                                    outside of our network, you'll need to speak with us for approval first.
                                </p>
                            ),
                        },
                        {
                            question: 'Do you protect pre-existing conditions?',
                            answer: (
                                <p>
                                    Like all home warranty companies, we do not repair items that have failed before the start of your protection plan period.
                                    We provide service for failures due to normal wear and tear that occur during your protection plan period.
                                </p>
                            ),
                        },
                        {
                            question: 'Will I have any out-of-pocket expenses?',
                            answer: (
                                <p>
                                    Even though our plans provide competitive protection limits, it’s possible for a repair to exceed them. We’ll work with you
                                    and the independent contractor to make sure you know what’s going on, especially if you need to pay any additional charges
                                    before any work is performed.
                                </p>
                            ),
                        },
                        {
                            question: 'What happens to my plan if I move or sell my home?',
                            answer: (
                                <p>
                                    Contact us at <PhoneNumberLink phoneNumber={company.phoneNumber.homeWarranty} /> to cancel or move your current plan. There
                                    are no early termination fees or other fees if you want to make changes to your plan.
                                </p>
                            ),
                        },
                        {
                            question: 'What is the typical wait time for a repair?',
                            answer: (
                                <p>
                                    The goal is to have an independent contractor contact you within 1 business day. If your situation is urgent, we will
                                    expedite emergency service whenever possible.
                                </p>
                            ),
                        },
                        {
                            question: 'What happens if I need a replacement?',
                            answer: (
                                <p>
                                    Sometimes, repairs are not practical because a key part may not be available or the cost to repair is greater than to
                                    replace the item. In these cases, you may choose to have the item replaced and we will provide a credit or payment equal to
                                    the average cost for a similar repair or the fair market value of the protected item, whichever is less, per your plan's
                                    protection limit.
                                </p>
                            ),
                        },
                        {
                            question: 'Do you guarantee repairs?',
                            answer: (
                                <p>
                                    Yes, repairs authorized by us and performed by one of our independent contractors are guaranteed against defects in
                                    materials and workmanship for 90 calendar days from the date of repair.
                                </p>
                            ),
                        },
                        {
                            question: 'Can I pick and choose which protected items I want to have in my plan?',
                            answer: (
                                <p>
                                    Our protection plans are packaged together and are designed to give you the best choices for your home and budget. You may
                                    add extra protection for additional water heaters or heating and cooling units for an additional cost. If you have
                                    additional units you'd like protected, call us at <PhoneNumberLink phoneNumber={company.phoneNumber.homeWarranty} /> to
                                    learn more.
                                </p>
                            ),
                        },
                        {
                            question: 'Do my protection limits reset?',
                            answer: (
                                <>
                                    <p>
                                        Protection limits apply over a rolling 12-month period. Each expense we pay on your behalf will reduce your remaining
                                        protection for the following 12 months. Protection limits may differ by plan line.
                                    </p>
                                    <p>
                                        Example: if you have an inside gas line failure, your protection limit is $2,000, and we pay $1,500 to diagnose and
                                        repair the failure, then you'll have $500 of inside gas line protection remaining for the next 12 months.
                                    </p>
                                </>
                            ),
                        },
                    ]}
                />
                <Header3>Managing Your Account</Header3>
                <FaqSection
                    FAQs={[
                        {
                            question: 'How can I access my account information?',
                            answer: (
                                <>
                                    <p>
                                        You can access your account information by logging in to My Account, where you can manage your{' '}
                                        <a href={Routes.AccountInformation}>SCANA Energy</a> or <a href={Routes.HwAccountInformation}>SCANA Home Warranty</a>{' '}
                                        accounts.
                                    </p>
                                    <p>
                                        Or you can call <PhoneNumberLink phoneNumber={company.phoneNumber.homeWarranty} />. We are available{' '}
                                        {company.businessHours.homeWarrantyFull}.
                                    </p>
                                </>
                            ),
                        },
                        {
                            question: 'How does my account renew?',
                            answer: <p>Your plan renews automatically each month based on your payment preferences.</p>,
                        },
                        {
                            question: 'How do I cancel?',
                            answer: (
                                <p>
                                    To cancel your protection plan, please call <PhoneNumberLink phoneNumber={company.phoneNumber.homeWarranty} /> or email us
                                    at <EmailLink toAddress={company.emails.homeWarranty} />.
                                </p>
                            ),
                        },
                        {
                            question: 'Can I view the service agreement?',
                            answer: (
                                <p>
                                    You can find our <ExternalLink href={LegalRoutes.homeWarrantyServiceAgreement().toString()}>Service Agreement</ExternalLink>{' '}
                                    here. If you'd like a copy mailed to you, just give us a call at{' '}
                                    <PhoneNumberLink phoneNumber={company.phoneNumber.homeWarranty} />.
                                </p>
                            ),
                        },
                    ]}
                />
            </PageContentContainer>
        </>
    )
}
