import styled from 'styled-components'

import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { mdWidth } from '@igs-web/common-ui-components/styles/theme'

export const TableContainer = styled.div``

export const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: 'empty title actions';
    background: var(--header-color);
    border: 1px solid var(--dark-base);
    padding: ${Spacing.Large};

    @media (max-width: ${mdWidth}) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
            'title'
            'actions';
    }
`

export const TableTitle = styled.div`
    grid-area: title;
    color: #fff;
    @media (max-width: ${mdWidth}) {
        justify-self: center;
        text-align: center;
    }
    font-weight: bold;
    font-size: 22px;
    text-transform: uppercase;
`
