import * as React from 'react'

export const LinkBase = ({ openInNewTab, dataTestId, onClick, ...rest }: LinkProps): JSX.Element => (
    <a
        target={openInNewTab ? '_blank' : undefined}
        onClick={e => {
            e.stopPropagation()
            if (onClick) {
                onClick(e)
            }
        }}
        data-testid={dataTestId}
        {...rest}
    />
)

export const Link = ({ className, ...rest }: LinkProps): JSX.Element => <LinkBase className={`link ${className || ''}`} {...rest} />

export const ExternalLink = ({ ...rest }: Omit<LinkProps, 'openInNewTab'>): JSX.Element => <Link openInNewTab={true} {...rest} />

export interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    readonly openInNewTab?: boolean
    readonly dataTestId?: string
}
