/* eslint-disable max-len */
import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

const BadgeCircleIcon = ({ altText = 'Badge', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M24.018 11.014h-5.353v2.643h-5.36v-2.643h-5.36c-0.724 0.004-1.312 0.579-1.336 1.297l-0 0.002v10.72c0 0.002-0 0.005-0 0.007 0 0.734 0.595 1.329 1.329 1.329 0.003 0 0.005 0 0.008-0h16.073c0.002 0 0.005 0 0.007 0 0.738 0 1.336-0.598 1.336-1.336 0 0 0 0 0-0v0-10.72c-0.024-0.723-0.616-1.3-1.343-1.3-0 0-0 0-0.001 0h0zM11.961 15.67c0.738 0 1.336 0.598 1.336 1.336s-0.598 1.336-1.336 1.336c-0.738 0-1.336-0.598-1.336-1.336v0c0-0.738 0.598-1.336 1.336-1.336v0zM14.642 21.023h-5.353v-1.571c0.368-0.309 0.816-0.535 1.309-0.642l0.020-0.004c0.369 0.282 0.836 0.452 1.343 0.455h0.001c0.51-0.004 0.979-0.174 1.357-0.459l-0.006 0.004c0.513 0.112 0.961 0.337 1.334 0.65l-0.005-0.004zM21.984 20.354h-5.36v-1.336h5.404zM21.984 17.674h-5.36v-1.336h5.404z" />
        <path d="M17.99 9.641c0.002-0.036 0.004-0.079 0.004-0.121 0-1.111-0.901-2.012-2.012-2.012s-2.012 0.901-2.012 2.012c0 0.043 0.001 0.085 0.004 0.127l-0-0.006v3.348h4.017zM15.978 10.654c-0.56 0-1.013-0.454-1.013-1.013s0.454-1.013 1.013-1.013c0.56 0 1.013 0.454 1.013 1.013v0c0 0.56-0.454 1.013-1.013 1.013v0z" />
        <path d="M15.985 0c-0.007 0-0.014 0-0.022 0-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-0.002 0-0.005 0-0.007 0-8.827-7.152-15.984-15.977-15.993h-0.001zM15.985 30.458c-0.004 0-0.010 0-0.015 0-7.985 0-14.458-6.473-14.458-14.458s6.473-14.458 14.458-14.458c7.985 0 14.458 6.473 14.458 14.458v0c0 0.004 0 0.010 0 0.015 0 7.977-6.466 14.443-14.443 14.443v0z" />
    </SvgIcon>
)
export default BadgeCircleIcon
