import styled from 'styled-components'

export const EllipsisOverflowText = styled.span<Props>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: ${({ $width }: Props) => $width};
    display: inline-block;
    ${({ $showHover }: Props) => $showHover && '&:hover { overflow:visible; }'}
`
interface Props {
    readonly $width: string
    readonly $showHover: boolean
}
