import * as React from 'react'
import { FaRegFileAlt } from 'react-icons/fa'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { ConfirmationOffer } from '@igs-web/common-components/domain/enrollment/confirmation/confirmation-redux'
import { OfferRequestState } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { OverflowWrap, StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { darkDark, darkLight, fontSizeLarge } from '@igs-web/common-ui-components/styles/theme'
import { enrollmentApiClient } from '@igs-web/common-utilities/api/enrollment-api-client'
import { getPriceDisplay, getProductTitle } from '@igs-web/common-utilities/utilities/offer-utilities'
import { capitalizeFirstLetter } from '@igs-web/common-utilities/utilities/string-formatter'

import { RateCardDisplay } from '../your-plan/rate-card'

export const StyledConfirmationTitle = styled.header`
    text-align: center;
    font-size: ${fontSizeLarge};
    h2 {
        font-weight: ${FontWeight.Medium};
        line-height: 1.2;
    }
    p {
        color: ${darkLight};
        font-weight: ${FontWeight.Bold};
    }
    ${StrongText} {
        color: ${darkDark};
    }
`

export const ConfirmationContainer = styled.div`
    @media (max-width: ${Breakpoint.Tablet}) {
        padding-inline: ${Spacing.Medium};
    }
`

export const OrderDetailsTitle = () => (
    <IconHeading>
        <FaRegFileAlt /> Order details
    </IconHeading>
)

export const ConfirmationTitle = ({ displayName, emailAddress, confirmationNumber }: ConfirmationTitleProps) => (
    <StyledConfirmationTitle>
        <h2>Thank you for choosing {displayName}</h2>
        {emailAddress && (
            <p data-testid="confirmation-email">
                Details have been emailed to <OverflowWrap>{emailAddress}</OverflowWrap>
            </p>
        )}
        <p>
            Your confirmation number:{' '}
            <StrongText as="span" data-testid="confirmation-confirmation-number">
                {confirmationNumber}
            </StrongText>
        </p>
    </StyledConfirmationTitle>
)

export const ConfirmationRateCard = ({ offer, offerRequest }: ConfirmationRateCardProps) => {
    const company = useCompany()
    if (!offerRequest) {
        return <></>
    }
    const ratePriceDisplay = getPriceDisplay(
        offer.price,
        offer.priceUnit,
        capitalizeFirstLetter(offer.unitOfMeasure),
        offer.discountedPrice,
        offer.discountedPriceUnit,
        true,
        true,
    )
    const getTermsAndConditionsUrl = () =>
        enrollmentApiClient.getTermsAndConditionsUrl(offer.productCode, offer.accountType, offerRequest.zipCode, null, offer.campaignCode)
    const codeEnteredByCustomer =
        offerRequest && offerRequest.codeEnteredByCustomer && !offerRequest.isCodeInvalid ? offerRequest.codeEnteredByCustomer : undefined
    return (
        <RateCardDisplay
            title={getProductTitle(offer, company, true)}
            priceDisplay={ratePriceDisplay}
            termsUrl={getTermsAndConditionsUrl()}
            codeEnteredByCustomer={codeEnteredByCustomer}
        />
    )
}

interface ConfirmationRateCardProps {
    readonly offer: ConfirmationOffer
    readonly offerRequest: OfferRequestState
}
interface ConfirmationTitleProps {
    readonly displayName: string
    readonly emailAddress?: string | null
    readonly confirmationNumber: string
}
