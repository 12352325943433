import * as React from 'react'

/* eslint-disable complexity */
import { IconType } from '@igs-web/common-models/models/icon-type'

import BadgeCircleIcon from './badge-circle-icon-icon'
import BillElectricCircleIcon from './bill-electric-circle-icon'
import BillGasCircleIcon from './bill-gas-circle-icon'
import BlobIcon from './blob-icon'
import CircleCheckmarkIcon from './circle-checkmark'
import CircleContactInfoIcon from './circle-contact-info'
import CircleCreditCheckIcon from './circle-credit-check'
import CircleMyDetailsIcon from './circle-my-details'
import CirclePaymentIcon from './circle-payment'
import CircleProductDetailIcon from './circle-product-details'
import CircleReviewIcon from './circle-review'
import CircleServiceAddressIcon from './circle-service-address'
import CircleStartDateIcon from './circle-start-date'
import CircleTag from './circle-tag'
import DollarSignCircleIcon from './dollar-sign-circle-icon'
import ElectricCircleIcon from './electric-circle-icon'
import ElectricIcon from './electric-icon'
import ElectricLineCircleIcon from './electric-line-circle-icon'
import ElectricPlugIcon from './electric-plug-icon'
import EmailCircleIcon from './email-circle-icon'
import ExclamationShadowCircle from './exclamation-shadow-circle-icon'
import FlameIcon from './flame-icon'
import GasCircleIcon from './gas-circle-icon'
import GasIcon from './gas-icon'
import GasPipeCircleIcon from './gas-pipe-circle-icon'
import HeartCircleIcon from './heart-circle-icon'
import HomeCircleIcon from './home-circle-icon'
import HomeIcon from './home-icon'
import HomeWarrantyIcon from './home-warranty-icon'
import IgsCircleIcon from './igs-circle-icon'
import IgsEnergyMark from './igs-energy-mark'
import LeafIcon from './leaf-icon'
import LightingIcon from './lighting-icon'
import PartnerCircleIcon from './partner-circle-icon'
import RealtorCircleIcon from './realtor-circle-icon'
import RepairsCircleIcon from './repairs-circle-icon'
import ShieldCircleIcon from './shield-circle-icon'
import SolarCircleIcon from './solar-circle-icon'
import SolarIcon from './solar-icon'
import StarCircleIcon from './star-circle-icon'
import { SvgIconProps } from './svg-icon'
import WindCircleIcon from './wind-circle-icon'

export const IconSvg = ({ iconType, ...svgIconProps }: IconSvgProps) => {
    switch (iconType) {
        case IconType.BadgeCircleIcon:
            return <BadgeCircleIcon {...svgIconProps} />

        case IconType.BillElectricCircleIcon:
            return <BillElectricCircleIcon {...svgIconProps} />

        case IconType.BillGasCircleIcon:
            return <BillGasCircleIcon {...svgIconProps} />

        case IconType.ElectricLineCircleIcon:
            return <ElectricLineCircleIcon {...svgIconProps} />

        case IconType.ElectricCircleIcon:
            return <ElectricCircleIcon {...svgIconProps} />

        case IconType.EmailCircleIcon:
            return <EmailCircleIcon {...svgIconProps} />

        case IconType.GasPipeCircleIcon:
            return <GasPipeCircleIcon {...svgIconProps} />

        case IconType.GasCircleIcon:
            return <GasCircleIcon {...svgIconProps} />

        case IconType.HeartCircleIcon:
            return <HeartCircleIcon {...svgIconProps} />

        case IconType.HomeCircleIcon:
            return <HomeCircleIcon {...svgIconProps} />

        case IconType.IgsCircleIcon:
            return <IgsCircleIcon {...svgIconProps} />

        case IconType.PartnerCircleIcon:
            return <PartnerCircleIcon {...svgIconProps} />

        case IconType.RealtorCircleIcon:
            return <RealtorCircleIcon {...svgIconProps} />

        case IconType.RepairsCircleIcon:
            return <RepairsCircleIcon {...svgIconProps} />

        case IconType.DollarSignCircleIcon:
            return <DollarSignCircleIcon {...svgIconProps} />

        case IconType.ShieldCircleIcon:
            return <ShieldCircleIcon {...svgIconProps} />

        case IconType.SolarCircleIcon:
            return <SolarCircleIcon {...svgIconProps} />

        case IconType.StarCircleIcon:
            return <StarCircleIcon {...svgIconProps} />

        case IconType.WindCircleIcon:
            return <WindCircleIcon {...svgIconProps} />

        case IconType.FlameIcon:
            return <FlameIcon {...svgIconProps} />

        case IconType.HomeIcon:
            return <HomeIcon {...svgIconProps} />

        case IconType.ElectricPlugIcon:
            return <ElectricPlugIcon {...svgIconProps} />

        case IconType.LeafIcon:
            return <LeafIcon {...svgIconProps} />

        case IconType.IgsEnergyMark:
            return <IgsEnergyMark {...svgIconProps} />

        case IconType.ExclamationShadowCircleIcon:
            return <ExclamationShadowCircle {...svgIconProps} />

        case IconType.ElectricIcon:
            return <ElectricIcon {...svgIconProps} />

        case IconType.GasIcon:
            return <GasIcon {...svgIconProps} />

        case IconType.HomeWarrantyIcon:
            return <HomeWarrantyIcon {...svgIconProps} />

        case IconType.LightingIcon:
            return <LightingIcon {...svgIconProps} />

        case IconType.SolarIcon:
            return <SolarIcon {...svgIconProps} />

        case IconType.BlobIcon:
            return <BlobIcon {...svgIconProps} />

        case IconType.CircleCheckmarkIcon:
            return <CircleCheckmarkIcon {...svgIconProps} />

        case IconType.CircleContactInfoIcon:
            return <CircleContactInfoIcon {...svgIconProps} />

        case IconType.CircleCreditCheckIcon:
            return <CircleCreditCheckIcon {...svgIconProps} />

        case IconType.CircleMyDetailsIcon:
            return <CircleMyDetailsIcon {...svgIconProps} />

        case IconType.CirclePaymentIcon:
            return <CirclePaymentIcon {...svgIconProps} />

        case IconType.CircleProductDetailsIcon:
            return <CircleProductDetailIcon {...svgIconProps} />

        case IconType.CircleReviewIcon:
            return <CircleReviewIcon {...svgIconProps} />

        case IconType.CircleServiceAddressIcon:
            return <CircleServiceAddressIcon {...svgIconProps} />

        case IconType.CircleStartDateIcon:
            return <CircleStartDateIcon {...svgIconProps} />
        case IconType.CircleTag:
            return <CircleTag {...svgIconProps} />
        default:
            throw Error(`Unsupported icon: ${iconType}`)
    }
}

export interface IconSvgProps extends SvgIconProps {
    readonly iconType: IconType
}
