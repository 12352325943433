/* eslint-disable max-len */
import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

const ElectricPlugIcon = ({ altText = 'Electric Plug', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} id="Layer_1" viewBox="0 0 30 35" altText={altText}>
        <defs>
            <polygon id="path-1" points="0 35 30 35 30 0 0 0" />
        </defs>
        <g id="Symbols" fillRule="evenodd">
            <g id="Electric-Icon">
                <g id="Page-1">
                    <path
                        d="M0,10 L0,18.3750554 C0,20.9325388 2.03513221,23 4.54432551,23 L14.4542573,23 C16.962742,23 19,20.9325388 19,18.3750554 L19,10 L0,10 Z"
                        id="Fill-1"
                    />
                    <mask id="mask-2" fill="#fff">
                        <use xlinkHref="#path-1" />
                    </mask>
                    <polygon id="Fill-3" mask="url(#mask-2)" points="4 9 6 9 6 0 4 0" />
                    <polygon id="Fill-5" mask="url(#mask-2)" points="13 9 15 9 15 0 13 0" />
                    <path
                        d="M28.8071455,23 C28.1509296,23 27.6150201,23.5269447 27.6150201,24.1714661 C27.6150201,28.8458597 23.744805,32.6513323 18.9901568,32.6513323 C14.4411229,32.6513323 10.7043383,29.1627435 10.3893547,24.7686701 L8,24.7686701 C8.00364564,24.780141 8.0102078,24.7959135 8.0102078,24.7959135 L8.07582938,25.3199904 L8.11155669,25.8053531 C8.86693401,31.0475565 13.4436748,35 18.9901568,35 C25.0586949,35 30,30.1435058 30,24.1714661 C30,23.5269447 29.4604448,23 28.8071455,23"
                        id="Fill-6"
                        mask="url(#mask-2)"
                    />
                </g>
            </g>
        </g>
    </SvgIcon>
)

export default ElectricPlugIcon
