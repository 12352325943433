import styled from 'styled-components'

import { Spacing } from '@igs-web/common-ui-components/styles/spacing'

export const AccountDetailsCardBody = styled.div`
    --bg-color: transparent;
    display: grid;
    align-items: start;
    grid-gap: ${Spacing.Medium};
    padding: ${Spacing.Medium};
    background-color: var(--bg-color);
`
