const stripQueryParams = (url: string) => {
    const baseUrlEndIndex = indexOrUndefined(url, '?') || indexOrUndefined(url, '#')
    return url.substring(0, baseUrlEndIndex)
}

const indexOrUndefined = (str: string, term: string) => {
    const termIndex = str.indexOf(term)
    return termIndex === -1 ? undefined : termIndex
}

export interface Url {
    readonly asString: () => string
    readonly go: (config?: GoActionConfig) => void
    readonly isCurrentPage: () => boolean
}
export interface GoActionConfig {
    readonly preventIfInDesignMode: boolean
}

export const buildUrl = (url: string): Url => ({
    asString: () => url,
    go: (config?: GoActionConfig) => {
        if (config && config.preventIfInDesignMode && (window as any).isSitefinityDesignMode) {
            return
        }

        window.location.assign(url)
    },
    isCurrentPage: (): boolean => {
        const baseUrl = stripQueryParams(url)
        return location.pathname.toLowerCase() === baseUrl.toLowerCase()
    },
})
