import { Address } from './address'

export interface EnrollmentResponse {
    readonly orderId: number
    readonly orderItems: ReadonlyArray<EnrollmentOrderItemResponse>
    readonly errorMessage: string | undefined
    readonly enrollmentErrorCode: string | undefined
    readonly mailingAddress?: Address
}

export enum EnrollmentErrorCode {
    matchingPartyFoundOfTypeOrganization = 'MATCHING_PARTY_FOUND_OF_TYPE_ORGANIZATION',
    matchingPartyFoundOfTypeIndividual = 'MATCHING_PARTY_FOUND_OF_TYPE_INDIVIDUAL',
    accountIsBlocked = 'ACCOUNT_IS_BLOCKED',
    accountIsObsolete = 'ACCOUNT_IS_OBSOLETE',
    matchingAccountForbidden = 'MATCHING_ACCOUNT_FORBIDDEN',
}

export interface EnrollmentOrderItemResponse {
    readonly wasSuccessful: boolean
    readonly lineOfBusinessCode: string
    readonly errorCode: string | undefined
    readonly confirmationNumber: string
    readonly accountId: number | undefined
    readonly orderItemId: number
    readonly shoppingCartItemKey: string
    readonly isFriendsAndFamily: boolean
    readonly depositReferenceNumber: string | null
    readonly paymentDescription: string
}
