import { Action, Store, combineReducers } from 'redux'
import { Persistor } from 'redux-persist'
import { all } from 'redux-saga/effects'

import { budgetBillingReducer, budgetBillingSaga } from '@igs-web/common-components/domain/budget-billing/budget-billing-redux'
import { claimsReducer, claimsSaga } from '@igs-web/common-components/domain/claims/claims-redux'
import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { coverageReducer, coverageSaga } from '@igs-web/common-components/domain/coverage/coverage-redux'
import { accountDocumentsReducer, accountDocumentsSaga } from '@igs-web/common-components/domain/documents/account-documents-redux'
import { confirmationReducer, confirmationSaga } from '@igs-web/common-components/domain/enrollment/confirmation/confirmation-redux'
import { enrollmentModelReducer, enrollmentModelSaga } from '@igs-web/common-components/domain/enrollment/enrollment-redux'
import { offersReducer, offersSaga } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { hwBillingHistoryReducer, hwBillingHistorySaga } from '@igs-web/common-components/domain/hw-billing-history/hw-billing-history-redux'
import { myAccountReducer, myAccountSagas } from '@igs-web/common-components/domain/myaccount-redux'
import { organizationAgentReducer, organizationAgentSaga } from '@igs-web/common-components/domain/myaccount/billing-accounts/organization-agent-redux'
import { fiserveAutopayReducer, fiserveAutopaySaga } from '@igs-web/common-components/domain/myaccount/fiserv-autopay-redux'
import { legacyBillReducer, legacyBillSaga } from '@igs-web/common-components/domain/myaccount/legacy-data/legacy-bills-redux'
import { isAutoRenewReducer, isAutoRenewSaga } from '@igs-web/common-components/domain/myaccount/auto-renew-redux'
import { legacyPaymentReducer, legacyPaymentSaga } from '@igs-web/common-components/domain/myaccount/legacy-data/legacy-payments-redux'
import { legacyUsageReducer, legacyUsageSaga } from '@igs-web/common-components/domain/myaccount/legacy-data/legacy-usage-redux'
import { notificationReducer, notificationSagas } from '@igs-web/common-components/domain/notification/notification-redux'
import { homeWarrantyPaymentProfileReducer, homeWarrantyPaymentProfileSaga } from '@igs-web/common-components/domain/payment-profile/hw-payment-profile-redux'
import { historicRatesReducer, historicRatesSaga } from '@igs-web/common-components/domain/rates/historic-rates-redux'
import { transactionHistoryReducer, transactionHistorySaga } from '@igs-web/common-components/domain/transaction-history/transaction-history-redux'
import { modalReducer } from '@igs-web/common-components/domain/ui-state/modal-redux'
import { ulobReducer, ulobSaga } from '@igs-web/common-components/domain/ulob/ulob-redux'
import { usageReducer, usageSaga } from '@igs-web/common-components/domain/usage/usage-redux'
import { userActionTypes, userReducer, userSaga } from '@igs-web/common-components/domain/user/user-redux'
import { utilityAccountActivityReducer, utilityAccountActivitySaga } from '@igs-web/common-components/domain/utility-account/utility-account-activity-redux'
import { utilityAccountDetailsReducer, utilityAccountDetailsSaga } from '@igs-web/common-components/domain/utility-account/utility-account-details-redux'

import configureStore from './configure-store'

declare global {
    interface Window {
        // eslint-disable-next-line functional/prefer-readonly-type
        ScanaReduxStore: Store<{}>
        // eslint-disable-next-line functional/prefer-readonly-type
        ScanaReduxPersistor: Persistor
    }
}

const appReducer = combineReducers({
    user: userReducer,
    usage: usageReducer,
    ulob: ulobReducer,
    claims: claimsReducer,
    coverage: coverageReducer,
    hwBillingHistory: hwBillingHistoryReducer,
    historicRates: historicRatesReducer,
    utilityAccountDetails: utilityAccountDetailsReducer,
    accountDocuments: accountDocumentsReducer,
    accountActivity: utilityAccountActivityReducer,
    transactionHistory: transactionHistoryReducer,
    enrollment: combineReducers({
        offer: offersReducer,
        enrollmentCore: enrollmentModelReducer,
        confirmation: confirmationReducer,
    }),
    myaccount: myAccountReducer,
    modal: modalReducer,
    notification: notificationReducer,
    homeWarrantyPaymentProfile: homeWarrantyPaymentProfileReducer,
    legacyUsage: legacyUsageReducer,
    legacyBills: legacyBillReducer,
    legacyPayments: legacyPaymentReducer,
    organizationAgents: organizationAgentReducer,
    budgetBilling: budgetBillingReducer,
    fiserveAutopay: fiserveAutopayReducer,
    isAutoRenew: isAutoRenewReducer,
})

const rootReducer = (state: CommonReduxState | undefined, action: Action) => {
    if (action.type === userActionTypes.LOGOUT_SUCCESS) {
        sessionStorage.removeItem('persist:root')
        state = undefined
    }

    return appReducer(state, action)
}

function* rootSaga() {
    yield all([
        userSaga(),
        offersSaga(),
        usageSaga(),
        hwBillingHistorySaga(),
        ulobSaga(),
        historicRatesSaga(),
        utilityAccountActivitySaga(),
        transactionHistorySaga(),
        accountDocumentsSaga(),
        utilityAccountDetailsSaga(),
        myAccountSagas(),
        enrollmentModelSaga(),
        notificationSagas(),
        claimsSaga(),
        coverageSaga(),
        homeWarrantyPaymentProfileSaga(),
        confirmationSaga(),
        legacyUsageSaga(),
        legacyBillSaga(),
        legacyPaymentSaga(),
        organizationAgentSaga(),
        budgetBillingSaga(),
        fiserveAutopaySaga(),
        isAutoRenewSaga(),
    ])
}

if (!window.ScanaReduxStore) {
    const configuredStore = configureStore(rootReducer, rootSaga)
    window.ScanaReduxStore = configuredStore.store
    window.ScanaReduxPersistor = configuredStore.persistor
}

export const store: Store<CommonReduxState> = window.ScanaReduxStore as Store<CommonReduxState>
export const persistor = window.ScanaReduxPersistor
