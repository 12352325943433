import * as React from 'react'
import { FaSearch } from 'react-icons/fa'
import { MdLocationOn } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import { CheckoutStepPageIds } from '@igs-web/common-components/domain/checkout/checkout-step-page-ids'
import { useCheckoutDispatch, useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { OfferSelectors, offersActions } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { useBreakpoints } from '@igs-web/common-components/hooks/use-breakpoints'
import { AccountTypeName } from '@igs-web/common-models/constants/account-type'
import { Address, PremiseResponse } from '@igs-web/common-models/models'
import { Button, ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { Card } from '@igs-web/common-ui-components/_atoms/card'
import { AddressDisplay } from '@igs-web/common-ui-components/_molecules/address/address-display'
import { useWizardDispatch } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { getEventModel, pushAddedServiceAddressEvent } from '@igs-web/common-utilities/services/google-analytics-services'
import { asDate } from '@igs-web/common-utilities/utilities/date-utilities'

import { ExternalLinkWithArrow } from 'molecules/layout/layout-styles'

import { useCheckoutWizardMessagesDispatch } from '../../checkout-wizard-messages/checkout-wizard-messages-context'
import { useOffersHandler } from '../../hooks/use-offers-handler'
import { useOffersQueryStringHandler } from '../../hooks/use-offers-query-string-handler'
import { useSelectOffer } from '../../hooks/use-select-offer'
import { AddressSearchResultsWrapper, HeadingWithButton, SmallButton, SummarySelector } from '../../shared/checkout-components'

import { ServiceAddressSearchSteps } from './service-address-step'

const BackButtonWrapper = styled.div`
    display: grid;
    grid-template-areas: 'button';
    justify-items: end;
`

const BackButton = styled(Button)`
    grid-area: 'button';
    width: fit-content;
`

const maxResultsToShow = 100

export const ResultsStep = ({ searchResults, setCurrentStep }: ResultsProps) => {
    const dispatch = useDispatch()
    const enrollmentState = useCheckoutState()
    const { setEnrollmentState } = useCheckoutDispatch()
    const { nextPage, setActivePageId } = useWizardDispatch()
    const selectedOffers = useSelector((store: CommonReduxState) => OfferSelectors.selectSelectedOffers(store))

    const offerRequest = useSelector((store: CommonReduxState) => OfferSelectors.selectOfferRequest(store))

    const { lessThanOrEqualTo } = useBreakpoints()
    const isMobile = lessThanOrEqualTo(Breakpoint.Tablet)

    const { unSelectOffer } = useSelectOffer()

    const { setShowIsCommercialServiceTypeAddressMessage, setShowOfferNotAvailableForZipMessage } = useCheckoutWizardMessagesDispatch()
    const { isOfferAvailable } = useOffersHandler()
    const { getGasOffersQueryStringParameters } = useOffersQueryStringHandler()

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleSearchForAnotherAddress = () => {
        setEnrollmentState({ ...enrollmentState, serviceAddress: undefined })
        setShowIsCommercialServiceTypeAddressMessage(false)
        setCurrentStep(ServiceAddressSearchSteps.search)
    }

    const updateEnrollmentState = React.useCallback(
        (premise: PremiseResponse) => {
            const serviceAddress: Address = {
                address1: premise.address1,
                address2: premise.address2,
                city: premise.city,
                state: premise.state,
                zipCode: premise.zipCode,
                serviceType: premise.serviceType,
            }
            setEnrollmentState({
                ...enrollmentState,
                forceMoving: false,
                serviceAddress,
                distributionZone: premise.distributionZone,
            })
        },
        [enrollmentState, setEnrollmentState],
    )

    const advancePage = (goToPage: CheckoutStepPageIds) => pushAddedServiceAddressEvent(getEventModel(selectedOffers), () => setActivePageId(goToPage))

    const selectPremise = React.useCallback(
        async (premise: PremiseResponse) => {
            setShowIsCommercialServiceTypeAddressMessage(false)
            if (premise.serviceType === AccountTypeName.Commercial) {
                setShowIsCommercialServiceTypeAddressMessage(true)
                setCurrentStep(ServiceAddressSearchSteps.search)
            } else {
                dispatch(offersActions.loadMunicipality(premise.zipCode))
                updateEnrollmentState(premise)

                //-- If there is a selected offer in Redux
                if (selectedOffers.length) {
                    //-- Validate that the selected offer is available for the newly selected premise
                    const offerAvailableForZip =
                        (offerRequest.codeEnteredByCustomer || offerRequest.campaignCode) && !offerRequest.isCodeInvalid
                            ? await isOfferAvailable(
                                  selectedOffers[0].offerId,
                                  offerRequest.campaignCode!,
                                  selectedOffers[0].priceEffectiveDate ? asDate(selectedOffers[0].priceEffectiveDate) : null,
                                  offerRequest.invitationCode!,
                                  premise.zipCode,
                                  premise.distributionZone,
                              )
                            : false

                    //-- If selected offer is available for the premise
                    if (offerAvailableForZip) {
                        setShowOfferNotAvailableForZipMessage(false)

                        const queryStringParameters = getGasOffersQueryStringParameters()

                        //-- If the selected offer matches what is in the query string (handles skipping the rates page if coming from the marketing site)
                        if (queryStringParameters.offerId === selectedOffers[0].offerId) {
                            //-- Skip rates page; Go to customer info
                            advancePage(CheckoutStepPageIds.ContactInfo)
                        } else {
                            //-- Go to rates page
                            advancePage(CheckoutStepPageIds.SelectRate)
                        }

                        //-- Else the selected offer is unavailable for the premise
                    } else {
                        //-- Unselect offer
                        //-- Show not avail in zip message
                        //-- Show rates page with offers available for that premise (getting the offers for the premise is handled by the rates page)
                        unSelectOffer(selectedOffers[0])
                        setShowOfferNotAvailableForZipMessage(true)
                        advancePage(CheckoutStepPageIds.SelectRate)
                    }
                    //-- No currently selected offers
                } else {
                    //-- Go to rates page
                    advancePage(CheckoutStepPageIds.SelectRate)
                }
            }
        },
        [setShowOfferNotAvailableForZipMessage, setShowIsCommercialServiceTypeAddressMessage, setCurrentStep, updateEnrollmentState, nextPage],
    )

    return (
        <AddressSearchResultsWrapper>
            <HeadingWithButton
                headingDataTestId="verify-address-header"
                buttonText={
                    <>
                        <FaSearch /> Search for another address
                    </>
                }
                onClick={() => handleSearchForAnotherAddress()}
                hideButton={isMobile}
                buttonDataTestId="address-search-results-search-for-another-address-button"
            >
                <MdLocationOn /> Verify Address
            </HeadingWithButton>
            {searchResults.map((premise, index) => (
                <Card key={index}>
                    {index < maxResultsToShow && (
                        <SummarySelector>
                            <AddressDisplay address={premise} dataTestIdPrefix={`address-search-results-${index}-`} />
                            <SmallButton data-testid={`address-search-results-${index}-use-this-address-button`} onClick={() => selectPremise(premise)}>
                                Use This Address
                            </SmallButton>
                        </SummarySelector>
                    )}
                </Card>
            ))}
            <BackButtonWrapper>
                <BackButton buttonStyle={ButtonStyleType.Secondary} onClick={() => setCurrentStep(ServiceAddressSearchSteps.search)}>
                    BACK
                </BackButton>
            </BackButtonWrapper>
            {isMobile && (
                <ExternalLinkWithArrow onClick={() => handleSearchForAnotherAddress()} arrowLeft={true}>
                    Back to Address
                </ExternalLinkWithArrow>
            )}
        </AddressSearchResultsWrapper>
    )
}

interface ResultsProps {
    readonly searchResults: ReadonlyArray<PremiseResponse>
    readonly setCurrentStep: React.Dispatch<React.SetStateAction<string>>
}
