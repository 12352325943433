export interface CallToActionModel {
    readonly mode: CallToActionMode
    readonly href?: string
    readonly linkText?: string
    readonly linkStyle?: string
    readonly queryParameterName?: string
    readonly inputLabel?: string
    readonly inputRegex?: RegExp
    readonly inputId?: string
    readonly campaignCode?: string
    readonly invitationCode?: string
    readonly agentCode?: string
    readonly accountType?: string
    readonly isGreen?: boolean
    readonly productCode?: string
    readonly referralId?: string
    readonly exactTargetId?: string
    readonly queryString?: string
    readonly marketGroup?: string
    readonly openInNewWindow?: boolean
    readonly isSmallCommercial?: boolean
}

export enum CallToActionMode {
    none = 0,
    hyperlink = 1,
    querystring = 2,
    campaign = 3,
    subscribe = 4,
    marketLink = 5,
    emailMeRates = 6,
    productSelection = 8,
    landingCampaign = 9,
}
