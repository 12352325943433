declare global {
    interface Navigator {
        readonly msSaveBlob?: (blob: any, defaultName?: string) => boolean
    }
}
export const downloadBlob = (blob: Blob | null, filename: string, extension: string): void => {
    if (blob) {
        const fullFilename = extension.startsWith('.') ? `${filename}${extension.toLowerCase()}` : `${filename}.${extension.toLowerCase()}`
        if (typeof window.navigator.msSaveBlob === 'function') {
            window.navigator.msSaveBlob(blob, fullFilename)
        } else {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = fullFilename
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }
}
