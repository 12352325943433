import {
    DeletePartyPhonecontactRequest,
    PartyPhoneContact,
    PartyPhoneContactResponse,
    PhoneContactRequest,
    UpdatePartyPhoneContactRequest,
} from '@igs-web/common-models/models/phone-contact-response'
import { buildQueryStringUrl } from '@igs-web/common-utilities/utilities/query-string-utilities'

import { apiClient } from './api-client'

class PhoneContactApiClient {
    public readonly getPartyPhoneContacts = async (userProfileId: number) => {
        const url = buildQueryStringUrl({ userProfileId }, `${apiClient.apiBaseUrl}/PhoneContact?userProfileId=${userProfileId}`)
        return await apiClient.getAuthed<ReadonlyArray<PartyPhoneContactResponse>>(url)
    }
    public readonly createPartyPhoneContact = async (request: PhoneContactRequest) => {
        await apiClient.postAuthed<PartyPhoneContact>(`${apiClient.apiBaseUrl}/PhoneContact`, request)
    }
    public readonly updatePartyPhoneContact = async (request: UpdatePartyPhoneContactRequest) => {
        await apiClient.putAuthed<PartyPhoneContact>(`${apiClient.apiBaseUrl}/PhoneContact`, request)
    }
    public readonly deletePartyPhoneContact = async (request: DeletePartyPhonecontactRequest) => {
        await apiClient.postAuthed<boolean>(`${apiClient.apiBaseUrl}/PhoneContact/Delete`, request)
    }
}

export const phoneContactApiClient = new PhoneContactApiClient()
