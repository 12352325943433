import * as React from 'react'
import styles from 'root/../styles/abstracts/_variables.scss'
import styled from 'styled-components'

interface BaseMenuLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  readonly dataTestId: string
}

export const StyledMenuLink = styled.a`
  @media (min-width:${styles.mdWidth}) {
    font-size: 0.875rem;
    white-space: nowrap;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
      color: ${styles.primaryLight};
    }
  }
`
export const MenuLink = ({ dataTestId, ...LinkProps }: BaseMenuLinkProps) =>
  <StyledMenuLink data-testid={dataTestId} {...LinkProps} />

MenuLink.displayName = 'MenuLink'
