import * as React from 'react'
import { BsTagFill } from 'react-icons/bs'

import { CheckoutStepPageIds } from '@igs-web/common-components/domain/checkout/checkout-step-page-ids'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { OfferRequestState } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { LegalRoutes } from '@igs-web/common-components/domain/legal/legal-routes'
import { LineOfBusinessCode, ProductModel } from '@igs-web/common-models/models'
import { Card } from '@igs-web/common-ui-components/_atoms/card'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { StrongText } from '@igs-web/common-ui-components/_atoms/typography'
import { useWizardDispatch } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { enrollmentApiClient } from '@igs-web/common-utilities/api/enrollment-api-client'
import { getPriceDisplay, getProductTitle } from '@igs-web/common-utilities/utilities/offer-utilities'
import { capitalizeFirstLetter } from '@igs-web/common-utilities/utilities/string-formatter'

import { EditHeading } from '../shared/checkout-components'

export const RateCard = ({ primaryProduct, offerRequest, hideEditButton = false, editButtonAction }: Props) => {
    const company = useCompany()
    const { moveToSubPage } = useWizardDispatch()
    const editAction = editButtonAction
        ? editButtonAction
        : () => {
              moveToSubPage(CheckoutStepPageIds.SelectRate, '')
          }
    const getTermsAndConditionsUrl = (product: ProductModel) =>
        enrollmentApiClient.getTermsAndConditionsUrl(
            product.productCode,
            product.accountType,
            offerRequest.zipCode,
            product.hpOfferCode,
            primaryProduct.campaignCode,
        )

    const ratePriceDisplay = primaryProduct
        ? getPriceDisplay(
              primaryProduct.displayPrice,
              primaryProduct.displayPriceUnit,
              capitalizeFirstLetter(primaryProduct.unitOfMeasure),
              primaryProduct.discountedPriceDisplay,
              primaryProduct.discountedPriceDisplayUnit,
              true,
              true,
          )
        : ''

    return (
        <RateCardDisplay
            priceDisplay={ratePriceDisplay}
            termsUrl={getTermsAndConditionsUrl(primaryProduct)}
            title={getProductTitle(primaryProduct, company)}
            codeEnteredByCustomer={offerRequest.codeEnteredByCustomer && !offerRequest.isCodeInvalid ? offerRequest.codeEnteredByCustomer : undefined}
            lobCode={primaryProduct.lineOfBusinessCode}
            header={
                <EditHeading onClick={editAction} hideEdit={hideEditButton} data-testid="rate-card-edit-button">
                    <BsTagFill /> {primaryProduct.lineOfBusinessCode === LineOfBusinessCode.HomeWarranty ? 'Plan' : 'Rate'}
                </EditHeading>
            }
        />
    )
}

export const RateCardDisplay = ({ title = 'Selected Rate', priceDisplay, termsUrl, codeEnteredByCustomer, header, lobCode }: RateCardDisplayProps) => (
    <Card
        data-testid="rate-card"
        header={
            header ? (
                header
            ) : (
                <IconHeading>
                    <BsTagFill /> Rate
                </IconHeading>
            )
        }
    >
        <StrongText as="div" data-testid="rate-card-offer-title">
            {title}
        </StrongText>
        <div data-testid="rate-card-rate">{priceDisplay}</div>
        {codeEnteredByCustomer && <div data-testid="rate-card-invitation-code">Invitation Code {codeEnteredByCustomer} applied.</div>}
        <br />
        {termsUrl && (
            <Link
                dataTestId="rate-card-term-service-agreement"
                href={lobCode === LineOfBusinessCode.HomeWarranty ? LegalRoutes.homeWarrantyServiceAgreement().toString() : termsUrl}
                target="_blank"
            >
                View and Print {lobCode === LineOfBusinessCode.HomeWarranty ? 'Service Agreement' : 'Terms'}
            </Link>
        )}
    </Card>
)

interface Props {
    readonly primaryProduct: ProductModel
    readonly offerRequest: OfferRequestState
    readonly hideEditButton?: boolean
    readonly editButtonAction?: () => void
}

interface RateCardDisplayProps {
    readonly title?: string
    readonly priceDisplay: string
    readonly termsUrl?: string
    readonly codeEnteredByCustomer?: string
    readonly lobCode?: LineOfBusinessCode
    readonly header?: React.ReactNode
}
