import styled, { css } from 'styled-components'

import { Breakpoint } from '../styles/breakpoints'
import { FontWeight } from '../styles/font-weight'
import { error, fontFamilyBody, fontFamilyHeader, fontSizeSmall, fontSizeVerySmall, headerColor, subheaderColor } from '../styles/theme'

const smWidth = '333px'
// TODO add other semantic elements, including other headers to this,
// including moving over the things from typography.scss

export const captionCss = css`
    font-size: ${fontSizeSmall};
`

export const Caption = styled.span`
    ${captionCss}
`

export const Header2 = styled.h2`
    color: ${headerColor};
    line-height: 1;
    font-weight: ${FontWeight.Light};
    font-family: ${fontFamilyHeader};
`

export const Header3 = styled.h3`
    line-height: 1.1;
    font-family: ${fontFamilyHeader};
`

export const Header4 = styled.h4`
    line-height: 1.5;
    font-size: 26px;
    font-weight: ${FontWeight.Medium};
    font-family: ${fontFamilyBody};
    color: ${headerColor};
`

export const Header5 = styled.h5`
    line-height: 1.4375;
    font-weight: ${FontWeight.Medium};
    font-family: ${fontFamilyHeader};
    font-size: 1rem;
`

export const Header6 = styled.h6`
    font-weight: ${FontWeight.Bold};
    font-size: 18px;
`

export const NoWrapText = styled.span`
    white-space: nowrap;
`
export const OverflowWrap = styled.span`
    overflow-wrap: anywhere;
`
export const NoWrapExceptMobile = styled.span`
    white-space: nowrap;
    @media (max-width: ${Breakpoint.Mobile}) {
        white-space: normal;
        display: block;
    }
`

const headlineCss = css`
    font-family: ${fontFamilyHeader};
    text-transform: none;
    letter-spacing: -0.64px;
    color: ${headerColor};
    margin: 0;
`

export const SectionHeadline = styled.h2`
    ${headlineCss}
    font-size: 46px;
    line-height: 54px;
    @media (max-width: ${smWidth}) {
        font-size: min(50px, 10vw);
        line-height: 1.3;
    }
`
export const SubSectionHeadline = styled.h3`
    ${headlineCss}
    font-size: 36px;
    line-height: 44px;
`

export const SectionSubheadline = styled.p`
    font-weight: normal;
    font-size: 24px;
    text-transform: uppercase;
    color: ${subheaderColor};
    margin-bottom: 0;
`
export const StrongText = styled.p`
    font-weight: ${FontWeight.Bold};
`
export const ErrorText = styled.p`
    color: ${error};
`

export const SmallText = styled.p`
    font-size: ${fontSizeVerySmall};
    line-height: 0.7;
    margin-bottom: 0;
`
export const SmallBodyCss = css`
    font-size: ${fontSizeVerySmall};
    line-height: 1;
`

//equivlanet to the 'text-hide' bootstrap class
export const HideText = styled.span`
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
`
export const dashboardLabelCss = css`
    font-weight: bold;
    text-transform: uppercase;
`
