import * as React from 'react'
import { css } from 'styled-components'

export enum ColorwayType {
    CarbonNeutral = 'carbonneutral',
    DeepSea = 'deepsea',
    EnergyGreen = 'energygreen',
    FreshGrass = 'freshgrass',
    Spruce = 'spruce',
    White = 'white',
    WhiteBlue = 'white-blue',
    IGSBlue = 'igsblue',
    Transparent = 'transparent',
    DashboardLobCard = 'dashboard-lob-card',
    DetailsCard = 'details-card',
    SunshineLight = 'sunshine-light',
    RobinsEgg = 'robinsegg',
    SunsetSky = 'sunsetsky',
    RustOrange = 'rustorange',
    PrimaryBase = 'primarybase',
}

export const ColorwayContainer = ({ colorwayType, className, ...rest }: Props) => <div className={`themed theme-${colorwayType} ${className}`} {...rest} />
export const colorwayCss = css`
    &.colorway-${ColorwayType.EnergyGreen} {
        --colorway-rgb: var(--energygreen-rgb);
    }
    &.colorway-${ColorwayType.RobinsEgg} {
        --colorway-rgb: var(--robinsegg-rgb);
    }
    &.colorway-${ColorwayType.FreshGrass} {
        --colorway-rgb: var(--freshgrass-rgb);
    }
    &.colorway-${ColorwayType.DeepSea} {
        --colorway-rgb: var(--deepsea-rgb);
    }
    &.colorway-${ColorwayType.SunsetSky} {
        --colorway-rgb: var(--sunsetsky-rgb);
    }
    &.colorway-${ColorwayType.RustOrange} {
        --colorway-rgb: var(--rustorange-rgb);
    }
    &.colorway-${ColorwayType.PrimaryBase} {
        --colorway-rgb: var(--primary-base-rgb);
    }
`
interface Props {
    readonly colorwayType: ColorwayType | string
    readonly className?: string
    readonly children?: React.ReactNode
}
