import * as React from 'react'

import { PublicWebsiteErrorCode } from '@igs-web/common-models/models/public-website-errors'
import { ExternalLink } from '@igs-web/common-ui-components/_atoms/link/link'

import { BaseCompany } from '../company/company'
import { Verbiage } from '../company/companyVerbiage'

export const GetErrorLoginErrorMessage = (errorCode: PublicWebsiteErrorCode, company: BaseCompany) => {
    switch (errorCode) {
        case PublicWebsiteErrorCode.badCredentials:
            return 'Invalid Credentials'
        case PublicWebsiteErrorCode.userNotFound:
            return company.verbiage[Verbiage.loginUserNotFound]
        case PublicWebsiteErrorCode.accountLocked:
            return company.verbiage[Verbiage.tooManyLoginAttempts]
        case PublicWebsiteErrorCode.wrongScanaApplication:
            return (
                <>
                    No account associated with that email address. Looking for your {company.companionWebsite?.displayName} Account?{' '}
                    <ExternalLink href={company.companionWebsite?.myAccountUrl}>Click here</ExternalLink>
                </>
            )
        default:
            return 'Login Unsuccessful'
    }
}
