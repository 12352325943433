import React from 'react'
import { Column, TableInstance, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'

import styled from 'styled-components'

import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { DropdownList } from '@igs-web/common-ui-components/_molecules/dropdown-list'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { bodyColor, mdWidth } from '@igs-web/common-ui-components/styles/theme'
import { reactNodeToString } from '@igs-web/common-utilities/utilities/string-formatter'

import { DataTableResponsive, SummaryRowItem } from './data-table-responsive'
import { DefaultEmptyTable } from './default-empty-table'
import { DateRange } from './filters/date-filter'
import { FilterPageResponsive } from './filters/filter-page'
import { TableColumn, getColumnId, isHiddenOnScreen } from './models/table-column'
import { PageNavigation } from './page-navigation'
import { TableContainer, TableHeader, TableTitle } from './table-container'
import { TableExportMenu } from './table-export-menu'
import { determineFilterType, determineFormatter, determineSortType } from './table-utilities'

const LinkGroup = styled.div`
    text-align: center;
    margin: ${Spacing.Medium};

    > * {
        margin: ${Spacing.Small};
        color: ${bodyColor};
    }
`

const StyledHeaderButtonContainer = styled.div`
    grid-area: actions;
    display: grid;
    grid-template-columns: min-content min-content;
    justify-content: end;
    column-gap: 20px;
    @media (max-width: ${mdWidth}) {
        justify-content: center;
        grid-template-columns: unset;
    }
    > div:first-child {
        @media (max-width: ${mdWidth}) {
            justify-self: end !important;
            width: 100%;
        }
    }
`

export const defaultPageSize = 12

const filterTypes = {
    text: (rows, id, filterValue) => {
        return rows.filter(row => {
            const rowValue = row.values[id]
            return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true
        })
    },
    dateRange: (rows: ReadonlyArray<any>, id: any, filterValue: DateRange) => {
        return rows.filter(row => {
            const rowValue = new Date(row.values[id])
            return rowValue !== undefined ? rowValue >= new Date(filterValue.startDate) && rowValue <= new Date(filterValue.endDate) : true
        })
    },
}

const mapToColumn = (columnDef: TableColumn): Column => ({
    Header: reactNodeToString(columnDef.header),
    id: columnDef.id,
    accessor: columnDef.accessor,
    sortType: determineSortType(columnDef.dataType),
    disableSortBy: columnDef.disableSorting,
    disableFilters: columnDef.filter ? false : true,
    Filter: columnDef.filter ? columnDef.filter : undefined,
    filter: determineFilterType(columnDef.filter ? true : false, columnDef.dataType, filterTypes),
    Cell: cell => determineFormatter(columnDef.dataType, cell.value),
})
//TODO: DESTROY THIS
export const DataTable = <T,>({
    columns,
    data,
    tableTitle,
    canPaginate = true,
    pageSize = defaultPageSize,
    handlePdfDownload,
    handleCsvDownload,
    showWhenEmpty,
    summaryRowData,
    dataTestId,
}: DataTableProps<T>): JSX.Element => {
    const reactTableColumns = React.useMemo(() => columns.map(mapToColumn), [columns])

    const reactTableData = React.useMemo(() => data as ReadonlyArray<object>, [data])

    const initialState = {
        pageSize,
        hiddenColumns: columns.filter(isHiddenOnScreen).map(getColumnId),
    }

    const instance: TableInstance = useTable(
        { columns: reactTableColumns, data: reactTableData as object[], filterTypes, initialState, rowProps: {} },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    )

    const { setPageSize, rows, pageCount } = instance

    const isShowingAll = pageCount === 1

    const showAllRows = () => setPageSize(rows.length)
    const showPagedRows = () => setPageSize(pageSize)

    const toggleShowAll = () => {
        if (!isShowingAll) {
            showAllRows()
        } else {
            showPagedRows()
        }
    }

    const tableRef = React.useRef(null)

    const needsMulitplePages = data.length > pageSize

    const showPageNavigation = canPaginate && !isShowingAll
    const showShowAll = needsMulitplePages

    const [showFilterPage, setShowFilterPage] = React.useState<boolean>(false)
    const toggleShowFilterPage = () => {
        setShowFilterPage(!showFilterPage)
    }

    return (
        <TableContainer ref={tableRef}>
            <TableHeader>
                <TableTitle>{tableTitle}</TableTitle>
                <StyledHeaderButtonContainer>
                    {handlePdfDownload && handleCsvDownload && <TableExportMenu handlePdfDownload={handlePdfDownload} handleCsvDownload={handleCsvDownload} />}
                    {instance.allColumns.some(c => c.canFilter) && (
                        <DropdownList
                            onClickEvent={() => {
                                toggleShowFilterPage()
                            }}
                            buttonText={'Filter'}
                            isLink={true}
                        >
                            {showFilterPage && (
                                <FilterPageResponsive
                                    instance={instance}
                                    onClose={() => {
                                        setShowFilterPage(false)
                                    }}
                                />
                            )}
                        </DropdownList>
                    )}
                </StyledHeaderButtonContainer>
            </TableHeader>
            <DataTableResponsive
                dataTestId={dataTestId}
                instance={instance}
                showWhenEmpty={showWhenEmpty || <DefaultEmptyTable />}
                summaryRowData={summaryRowData}
            />
            {(showPageNavigation || showShowAll) && (
                <LinkGroup>
                    {showPageNavigation && <PageNavigation instance={instance} />}
                    {showShowAll && instance.state.filters.length < 1 && <Link onClick={toggleShowAll}>{isShowingAll ? 'Collapse' : 'View All'}</Link>}
                </LinkGroup>
            )}
        </TableContainer>
    )
}

export interface DataTableProps<T> {
    readonly columns: ReadonlyArray<TableColumn>
    readonly data: ReadonlyArray<T>
    readonly tableTitle: string
    readonly canPaginate?: boolean
    readonly pageSize?: number
    readonly handlePdfDownload?: () => void
    readonly handleCsvDownload?: () => void
    readonly showWhenEmpty?: React.ReactNode
    readonly summaryRowData?: ReadonlyArray<SummaryRowItem>
    readonly dataTestId?: string
}
