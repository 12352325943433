import { IconType } from '@igs-web/common-models/models/icon-type'
import * as React from 'react'
import { ProgressTrackStep } from '../progress-track/progress-track-step'
import { useWizardItem } from './use-wizard-item'

export const WizardProgressTrackStep = ({ pageId, label, iconType }: WizardProgressTrackStepProps) => {
    const { setActive, isActive, isBeforeActive, registerItem, isRegistered } = useWizardItem(pageId)

    React.useEffect(() => {
        if (!isRegistered) {
            registerItem()
        }
    }, [registerItem, isRegistered])

    return <ProgressTrackStep label={label} onClick={() => { if (isBeforeActive) { setActive() } }}
        iconType={iconType} isActive={isActive} isComplete={isBeforeActive} />
}

interface WizardProgressTrackStepProps {
    readonly pageId: string
    readonly iconType: IconType
    readonly label: string
}
