import styled from 'styled-components'

import { GridCell } from '@igs-web/common-ui-components/_molecules/grid/grid-cell'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { navIconSize } from '@igs-web/common-ui-components/styles/theme'

import styles from 'root/../styles/abstracts/_variables.scss'

export const Nav = styled(GridCell)`
    display: grid;
    grid-gap: ${Spacing.Medium};
    grid-template-areas: 'left logo right';
    grid-template-columns: 1fr min-content 1fr;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-items: left;
    .nav-react-icon {
        height: ${navIconSize}px;
        width: ${navIconSize}px;
    }

    @media (min-width: ${styles.mdWidth}) {
        max-width: ${styles.navbarMaxWidth};
        grid-template-areas: 'logo left right';
        grid-template-columns: min-content 1fr 1fr;
        .nav-react-icon {
            height: ${navIconSize}px;
            width: ${navIconSize}px;
        }
        @media (max-width: ${styles.navbarMaxWidth}) and (min-width: ${styles.mdWidth}) {
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }
`
