import * as React from 'react'
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from 'react-icons/ti'
import { HeaderGroup } from 'react-table'

import { BodyCell, BodyRow, HeaderCell, HeaderRow, SummaryBodyRow, TableBase, TableBody, TableHead } from '@igs-web/common-ui-components/_atoms/tables'

import { TableProps } from './data-table-responsive'
import { TableFiller } from './table-filler'

const SortIcon = ({ sortDirection }: SortIconProps) => {
    return sortDirection === undefined ? <TiArrowUnsorted /> : sortDirection === 'down' ? <TiArrowSortedDown /> : <TiArrowSortedUp />
}

interface SortIconProps {
    readonly sortDirection?: 'up' | 'down'
}

const HeaderGroupsDisplay = ({ headerGroups }: HeaderGroupsDisplayProps) => {
    return (
        <TableHead className="no-highlight">
            {headerGroups.map(headerGroup => (
                <HeaderRow {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
                    {headerGroup.headers.map(column => (
                        <HeaderCell {...column.getHeaderProps(column.getSortByToggleProps())} key={column.getHeaderProps().key}>
                            {column.Header}
                            {column.canSort && (
                                <>
                                    {' '}
                                    <SortIcon sortDirection={column.isSorted ? (column.isSortedDesc ? 'down' : 'up') : undefined} />
                                </>
                            )}
                        </HeaderCell>
                    ))}
                </HeaderRow>
            ))}
        </TableHead>
    )
}

interface HeaderGroupsDisplayProps {
    readonly headerGroups: ReadonlyArray<HeaderGroup>
}

export const DataTableFull = ({ instance, showWhenEmpty, summaryRowData, dataTestId }: TableProps) => {
    const { columns, pageCount, page } = instance
    const { pageSize } = instance.state

    const hasMulitplePages = pageCount > 1
    const fillerRowCount = pageSize - page.length

    return (
        <TableBase data-testid={dataTestId} {...instance.getTableProps()}>
            <HeaderGroupsDisplay headerGroups={instance.headerGroups} />
            <TableBody {...instance.getTableBodyProps()}>
                {summaryRowData &&
                    pageCount &&
                    summaryRowData.map((cell, i) => (
                        <SummaryBodyRow key={i}>
                            <BodyCell colSpan={columns.length - (cell.span + 1)}>&nbsp;</BodyCell>
                            <BodyCell colSpan={cell.span}>{cell.header}</BodyCell>
                            <BodyCell>{cell.formattedValue}</BodyCell>
                        </SummaryBodyRow>
                    ))}
                {pageCount ? (
                    instance.page.map(row => {
                        instance.prepareRow(row)
                        return (
                            <BodyRow {...row.getRowProps()} key={row.getRowProps().key} color={row.values.isAlertRow ? 'red' : 'inherit'}>
                                {row.cells.map(cell => {
                                    return (
                                        <BodyCell {...cell.getCellProps()} key={cell.getCellProps().key}>
                                            {cell.render('Cell')}
                                        </BodyCell>
                                    )
                                })}
                            </BodyRow>
                        )
                    })
                ) : (
                    <BodyRow>
                        <BodyCell colSpan={columns.length}>{showWhenEmpty}</BodyCell>
                    </BodyRow>
                )}
                {hasMulitplePages && <TableFiller rowCount={fillerRowCount} columnCount={columns.length} />}
            </TableBody>
        </TableBase>
    )
}
