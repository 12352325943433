import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const ElectricLineCircleIcon = ({ altText = 'Electic Lines', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M15.985 0c-0.007 0-0.014 0-0.022 0-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-0.002 0-0.005 0-0.007 0-8.827-7.152-15.984-15.977-15.993h-0.001zM15.985 30.458c-0.004 0-0.010 0-0.015 0-7.985 0-14.458-6.473-14.458-14.458s6.473-14.458 14.458-14.458c7.985 0 14.458 6.473 14.458 14.458v0c0 0.004 0 0.010 0 0.015 0 7.977-6.466 14.443-14.443 14.443v0z" />
        <path d="M19.517 16.536l-0.095-1.505 2.423 1.505zM18.783 18.115l1.777 5.199-3.671-3.444zM11.44 23.313l1.777-5.199 1.894 1.755zM12.593 15.031l-0.11 1.505h-2.364zM13.136 6.77l-0.11 1.696h-2.82zM17.681 6.609l0.125 1.894h-3.671l0.147-1.894zM21.764 8.503h-2.768l-0.11-1.696zM15.104 11.587l-1.219 1.109 0.139-2.093zM15.985 10.787l-1.204-1.094h2.401zM17.182 13.489h-2.401l1.204-1.094zM16.888 11.55l1.079-0.984 0.14 2.093zM17.461 17.718l-1.469 1.344-1.469-1.344zM13.665 16.536l0.125-1.902h4.42l0.147 1.902zM26.045 17.718l-6.689-4.141-0.264-3.921h3.796v1.307h-0.595v1.182h2.372v-1.182h-0.595v-1.307h2.056l-7.255-4.274h-5.779l-7.218 4.274h2.049v1.307h-0.58v1.182h2.35v-1.182h-0.587v-1.307h3.789l-0.257 3.921-6.689 4.141h1.968v1.307h-0.573v1.19h2.35v-1.19h-0.587v-1.307h2.996l-2.555 7.247h-1.513v1.659h4.274v-1.659h-0.947l4.626-4.281 4.626 4.281h-0.947v1.659h4.266v-1.659h-1.52l-2.541-7.24h2.996v1.307h-0.595v1.19h2.372v-1.197h-0.595v-1.307z" />
    </SvgIcon>
)

export default ElectricLineCircleIcon
