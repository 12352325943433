import { OfferModel, UserProfileAccount, UtilityLineOfBusinessModel } from '@igs-web/common-models/models'
import { AepUlobs } from '@igs-web/common-utilities/constants/constants'

//All Products for AEP are sold as OHP and corrected during the enrollment
export const reMapAepUlob = (utilityCode: string | undefined | null): string | undefined | null => {
    if (utilityCode === AepUlobs.CSP) {
        return AepUlobs.OHP
    }

    return utilityCode
}

export const getAccountUlob = (offers: ReadonlyArray<OfferModel>, activeAccount: UserProfileAccount): UtilityLineOfBusinessModel | undefined => {
    const accountUtilityCode = reMapAepUlob(activeAccount.utilityCode)
    return offers
        .map(offer => offer.primaryProduct.ulob)
        .filter(ulob => !!ulob)
        .find(ulob => ulob?.utilityCode === accountUtilityCode && ulob?.lineOfBusinessCode === activeAccount.lineOfBusinessCode)
}
