import * as React from 'react'
import { FormRenderProps } from 'react-final-form'

import { FormApi } from 'final-form'
import styled from 'styled-components'

import { SubmitButton } from '@igs-web/common-components/_molecules/final-form/submit-button'
import { ChangePasswordRequest } from '@igs-web/common-models/models/user-profile-model'
import { ErrorAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { HtmlForm } from '@igs-web/common-ui-components/_atoms/forms/html-form'
import { TypedForm } from '@igs-web/common-ui-components/_atoms/forms/typed-form'
import { PasswordField } from '@igs-web/common-ui-components/_molecules/form-inputs/password-field'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { InfoText, InfoTextType } from '@igs-web/common-ui-components/_molecules/info-text'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { clearFormValues, formError } from '@igs-web/common-utilities/services/form-service'
import { composeValidators, mustMatch, passwordLength, required } from '@igs-web/common-utilities/utilities/form-validation'

const SubmitContainer = styled.div`
    text-align: right;
`

export const ChangePasswordForm = ({ onComplete }: Props): JSX.Element => {
    const changePassword = async (request: ChangePasswordRequest, form: FormApi): Promise<object | null | void> => {
        try {
            const result = await customerInfoApiClient.changePassword(request.currentPassword, request.newPassword)
            onComplete()
            clearFormValues(form)
            return result
        } catch (e) {
            return formError('Update password failed')
        }
    }
    return (
        <TypedForm<ChangePasswordRequest>
            onSubmit={changePassword}
            render={({ handleSubmit, submitSucceeded, submitError }: FormRenderProps): JSX.Element => (
                <HtmlForm id="myprofile-change-password-form" onSubmit={handleSubmit} autoComplete="none">
                    {submitSucceeded && (
                        <>
                            <InfoText $infoTextType={InfoTextType.Success}>Password Changed Successfully</InfoText>
                            <GridSpacer spacing={Spacing.Small} />
                        </>
                    )}
                    <ErrorAlert message={submitError} />
                    <PasswordField<ChangePasswordRequest>
                        name="currentPassword"
                        id="myprofile-update-password-confirm"
                        dataTestId="myprofile-update-password-confirm"
                        validate={required('Current Password is required')}
                        label="Current Password"
                    />

                    <PasswordField<ChangePasswordRequest>
                        name="newPassword"
                        id="myprofile-update-new-password"
                        dataTestId="myprofile-update-new-password"
                        validate={composeValidators(required('New Password is required'), passwordLength)}
                        label="New Password"
                    />

                    <PasswordField
                        name="newPasswordConfirm"
                        id="myprofile-update-new-password-confirm"
                        dataTestId="myprofile-update-new-password-confirm"
                        validate={composeValidators(required('New Password Confirmation is required'), mustMatch('newPassword', 'Passwords must match'))}
                        label="Confirm New Password"
                    />
                    <SubmitContainer>
                        <SubmitButton id="change-password-submit" dataTestId="change-password-submit">
                            SAVE CHANGES
                        </SubmitButton>
                    </SubmitContainer>
                </HtmlForm>
            )}
        />
    )
}

interface Props {
    readonly onComplete: () => void
}
