import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Action } from 'redux'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { CoverageDetailItem } from '@igs-web/common-models/models/account-detail-model'
import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'

import { CoverageSelectors, coverageActions } from './coverage-redux'
import { CoverageSummary } from './coverage-summary'

export const CoverageTab = ({ accountId }: Props) => {
    const dispatch = useDispatch()

    const loadCoverages = React.useCallback(
        (id: number): Action => dispatch(coverageActions.loadAccountCoverages({ accountId: id, showGlobalLoader: false })),
        [dispatch],
    )

    const coverages = useSelector<CommonReduxState, ReadonlyArray<CoverageDetailItem> | undefined>(store => CoverageSelectors.selectCoverages(store, accountId))

    React.useEffect(() => {
        loadCoverages(accountId)
    }, [accountId, loadCoverages])

    return <div className="usage-details">{!coverages ? <LocalLoader isLoading={true} /> : <CoverageSummary coverages={coverages} />}</div>
}

interface Props {
    readonly accountId: number
}
