export enum FontIconType {
    Checkbox = 'icon-checkbox',
    Account = 'icon-account',
    ArrowCarrotLeftCircle = 'icon-arrow-carrot-left-circle',
    ArrowCarrotLeft = 'icon-arrow-carrot-left',
    ArrowCarrotRightCircle = 'icon-arrow-carrot-right-circle',
    ArrowCarrotRight = 'icon-arrow-carrot-right',
    ArrowTriangleDown = 'icon-arrow-triangle-down',
    ArrowTriangleLeft = 'icon-arrow-triangle-left',
    ArrowTriangleRight = 'icon-arrow-triangle-right',
    ArrowTriangleUp = 'icon-arrow-triangle-up',
    ArrowUp = 'icon-arrow-up',
    ArrowUpLeft = 'icon-arrow-up-left',
    ArrowRight = 'icon-arrow-right',
    ArrowDownRight = 'icon-arrow-down-right',
    ArrowDown = 'icon-arrow-down',
    ArrowDownLeft = 'icon-arrow-down-left',
    ArrowLeft = 'icon-arrow-left',
    CarrotUp = 'icon-carrot-up',
    Chat = 'icon-chat',
    Chp = 'icon-chp',
    Close = 'icon-close',
    Cng = 'icon-cng',
    Lighting = 'icon-lighting',
    Edit = 'icon-edit',
    Electric = 'icon-electric',
    Envelope = 'icon-envelope',
    Error = 'icon-error',
    ExclamationTooltipDefault = 'icon-exclamation-tooltip-default',
    ExclamationTooltipHover = 'icon-exclamation-tooltip-hover',
    Factory = 'icon-factory',
    Gas = 'icon-gas',
    GasStation = 'icon-gasstation',
    GridView = 'icon-grid-view',
    Hamburger = 'icon-hamburger',
    Handshake = 'icon-handshake',
    Heart = 'icon-heart',
    HomeWarranty = 'icon-home-warranty',
    House = 'icon-house',
    InfoTooltipDefault = 'icon-info-tooltip-default',
    InfoTooltipHover = 'icon-info-tooltip-hover',
    ListView = 'icon-list-view',
    QuestionTooltipDefault = 'icon-question-tooltip-default',
    QuestionTooltipHover = 'icon-question-tooltip-hover',
    Search = 'icon-search',
    SocialFacebookCircle = 'icon-social-facebook-circle',
    SocialFacebook = 'icon-social-facebook',
    SocialInstagramCircle = 'icon-social-instagram-circle',
    SocialInstagram = 'icon-social-instagram',
    SocialLinkedinCircle = 'icon-social-linkedin-circle',
    SocialLinkedin = 'icon-social-linkedin',
    SocialPinterestCircle = 'icon-social-pinterest-circle',
    SocialPinterest = 'icon-social-pinterest',
    SocialTwitterCircle = 'icon-social-twitter-circle',
    SocialTwitter = 'icon-social-twitter',
    SocialYoutubeCircle = 'icon-social-youtube-circle',
    SocialYoutube = 'icon-social-youtube',
    SocialYoutubePlay = 'icon-youtube-play',
    Solar = 'icon-solar',
    Star = 'icon-star',
    Tower = 'icon-tower',
    Undo = 'icon-undo',
    Redo = 'icon-redo',
    Undo2 = 'icon-undo2',
    Redo2 = 'icon-redo2',
    Spinner11 = 'icon-spinner11',
    Loop2 = 'icon-loop2'
}