import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FaRegFileAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useCheckoutDispatch, useCheckoutState } from '@igs-web/common-components/domain/checkout/hooks/use-checkout'
import { StepNav } from '@igs-web/common-components/domain/checkout/steps/step-nav'
import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { SetConfirmationInfoRequest, confirmationActions } from '@igs-web/common-components/domain/enrollment/confirmation/confirmation-redux'
import { enrollmentModelActions } from '@igs-web/common-components/domain/enrollment/enrollment-redux-actions'
import { submitEnrollment } from '@igs-web/common-components/domain/enrollment/enrollment-submission-service'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { EnrollmentModel } from '@igs-web/common-models/models/enrollment-model'
import { LineOfBusinessCode } from '@igs-web/common-models/models/line-of-business'
import { FormWrapper } from '@igs-web/common-ui-components/_atoms/form-wrapper'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { PageContentContainer } from '@igs-web/common-ui-components/_molecules/page-content-container'
import { getEventModel, pushReviewOrderEvent } from '@igs-web/common-utilities/services/google-analytics-services'

import { ScrollToTop } from 'molecules/routing/scroll-to-top'

import * as Routes from 'domain/routes'

import { useCheckoutWizardMessagesDispatch } from '../checkout-wizard-messages/checkout-wizard-messages-context'
import { Checkout2x2CardGrid } from '../shared/checkout-components'
import { mapToEnrollmentModel } from '../shared/checkout-service'
import { AddressCard } from '../your-plan/address-card'
import { MyDetailsCard } from '../your-plan/my-details-card'
import { PaymentCard } from '../your-plan/payment-card'
import { RateCard } from '../your-plan/rate-card'

import { HwEnrollmentType } from './hw-checkout'

export const HwCheckoutReviewStep = ({ hwEnrollmentType }: Props) => {
    const methods = useForm()
    const enrollmentState = useCheckoutState()
    const history = useHistory()
    const selectedOffers = useSelector((store: CommonReduxState) => OfferSelectors.selectSelectedOffers(store))
    const primaryProduct = selectedOffers[0]?.primaryProduct
    const offerRequest = useSelector((store: CommonReduxState) => OfferSelectors.selectOfferRequest(store))
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
    const dispatch = useDispatch()
    const { setEnrollmentState } = useCheckoutDispatch()

    const updateEnrollmentModel = (enrollmentModel: Partial<EnrollmentModel>) => dispatch(enrollmentModelActions.updateEnrollmentModel(enrollmentModel))
    const setConfirmationInfo = (request: SetConfirmationInfoRequest) => dispatch(confirmationActions.setConfirmationInfo(request))
    const clearConfirmationInfo = () => dispatch(confirmationActions.clearConfirmationInfo())
    const { setShowServerErrorMessage } = useCheckoutWizardMessagesDispatch()
    const goToConfirmation = () => {
        history.push(Routes.HomeWarrantyConfirmation)
    }

    React.useEffect(() => {
        pushReviewOrderEvent(getEventModel(selectedOffers))
    }, [])

    const onSubmit = async () => {
        setIsSubmitting(true)
        const enrollmentModel = mapToEnrollmentModel(enrollmentState, selectedOffers)
        setEnrollmentState({ ...enrollmentModel })
        try {
            setShowServerErrorMessage(false)
            await submitEnrollment(enrollmentModel, selectedOffers, updateEnrollmentModel, setConfirmationInfo, clearConfirmationInfo, goToConfirmation)
        } catch (e) {
            window.scroll({ top: 0, behavior: 'smooth' })
            console.error(e)
            setShowServerErrorMessage(true)
            setIsSubmitting(false)
        }
    }

    const hideAddressCardEditButton = hwEnrollmentType === HwEnrollmentType.userAddress
    const hideMyDetailsCardEditButton = hwEnrollmentType !== HwEnrollmentType.standard

    return (
        <PageContentContainer>
            <FormProvider {...methods}>
                <ScrollToTop />
                <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
                    <IconHeading>
                        <FaRegFileAlt /> Review
                    </IconHeading>
                    <Checkout2x2CardGrid>
                        <RateCard primaryProduct={primaryProduct} offerRequest={offerRequest} hideEditButton={true} />
                        <AddressCard serviceAddress={enrollmentState.serviceAddress} hideEditButton={hideAddressCardEditButton} />
                        <MyDetailsCard enrollment={enrollmentState} supressBillingAccountDisplay={true} hideEditButton={hideMyDetailsCardEditButton} />
                        <PaymentCard
                            payment={enrollmentState.productDetails![LineOfBusinessCode.HomeWarranty].payment}
                            billingAddress={enrollmentState.billingAddress}
                            isBillingSameAsServiceAddress={enrollmentState.isBillingSameAsServiceAddress}
                        />
                    </Checkout2x2CardGrid>
                    <StepNav disableFwdButton={isSubmitting} stepFwdButtonText="SUBMIT" styleStepBackAsButton={true} />
                </FormWrapper>
            </FormProvider>
        </PageContentContainer>
    )
}

interface Props {
    readonly hwEnrollmentType: HwEnrollmentType
}
