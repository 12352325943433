import * as Routes from 'domain/routes'
import * as React from 'react'

import { Verbiage } from '@igs-web/common-components/domain/company/companyVerbiage'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { ButtonStyleType } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { LinkButton } from '@igs-web/common-ui-components/_atoms/link/link-button'

export const PaymentExtensionNavigationButtons = ({ billingAccountNumber }: PaymentExtensionNavigationButtonsProps) => {
    const { verbiage, routes } = useCompany()
    return (
        <>
            <LinkButton buttonStyle={ButtonStyleType.Primary} href={routes.payment.payBill(billingAccountNumber && billingAccountNumber)}>
                {verbiage[Verbiage.paymentNavigationText]}
            </LinkButton>
            <Link href={Routes.AccountSummary}>Go to your Account Summary</Link>
        </>
    )
}

interface PaymentExtensionNavigationButtonsProps {
    readonly billingAccountNumber?: string
}
