import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { HomeProtectionAccountClaim } from '@igs-web/common-models/models/account-detail-model'
import { RequestConfig } from '@igs-web/common-utilities/api/api-client'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { createAction, reducer } from '@igs-web/common-utilities/utilities/reducer-utilities'

export const homeWarrantyActionTypes = {
    LOAD_ACCOUNT_CLAIMS: '[home-warranty] LOAD_ACCOUNT_CLAIMS // REQUEST',
    LOAD_ACCOUNT_CLAIMS_SUCCESS: '[home-warranty] LOAD_ACCOUNT_CLAIMS // SUCCESS',
    LOAD_ACCOUNT_CLAIMS_FAIL: '[home-warranty] LOAD_ACCOUNT_CLAIMS // FAILURE',
    LOAD_ACCOUNT_CLAIMS_COMPLETE: '[home-warranty] LOAD_ACCOUNT_CLAIMS // COMPLETE',
}

export const claimsActions = {
    loadAccountClaims: createAction<RequestConfig & { readonly accountId: number }>(homeWarrantyActionTypes.LOAD_ACCOUNT_CLAIMS),
    loadAccountClaimsSuccess: createAction<UpdateAccountDetailsRequest>(homeWarrantyActionTypes.LOAD_ACCOUNT_CLAIMS_SUCCESS),
    loadAccountClaimsFail: createAction<string>(homeWarrantyActionTypes.LOAD_ACCOUNT_CLAIMS_FAIL),
    loadAccountClaimsComplete: createAction<void>(homeWarrantyActionTypes.LOAD_ACCOUNT_CLAIMS_COMPLETE),
}

export const claimsReducer = reducer<ClaimsState>({ isLoading: false })
    .add<void>(homeWarrantyActionTypes.LOAD_ACCOUNT_CLAIMS, state => ({
        ...state,
        isLoading: true,
        error: undefined,
    }))
    .add<UpdateAccountDetailsRequest>(homeWarrantyActionTypes.LOAD_ACCOUNT_CLAIMS_SUCCESS, (state, request) => ({
        ...state,
        [request.accountId]: request.claims,
    }))
    .add<string>(homeWarrantyActionTypes.LOAD_ACCOUNT_CLAIMS_FAIL, (state, error) => ({
        ...state,
        error,
    }))
    .add<void>(homeWarrantyActionTypes.LOAD_ACCOUNT_CLAIMS_COMPLETE, state => ({
        ...state,
        isLoading: false,
    }))
    .build()

export class ClaimsSelectors {
    public static readonly selectClaims = (state: CommonReduxState, accountId: number): ReadonlyArray<HomeProtectionAccountClaim> =>
        state.claims[accountId] || []
    public static readonly selectIsLoading = (state: CommonReduxState): boolean => state.claims.isLoading
}

const sagas = {
    *loadClaims(action: { readonly payload: RequestConfig & { readonly accountId: number } }) {
        const accountId = action.payload.accountId
        const currentClaims: ReadonlyArray<HomeProtectionAccountClaim> = yield select(ClaimsSelectors.selectClaims, accountId)
        if (!currentClaims.length) {
            try {
                const response: ReadonlyArray<HomeProtectionAccountClaim> = yield call(() => customerInfoApiClient.getHPClaims(accountId, action.payload))

                const claims = response.map<HomeProtectionAccountClaim>(u => ({
                    ...u,
                    dateOpened: u.dateOpened ? Date.parse(u.dateOpened + '') : undefined,
                }))
                yield put(claimsActions.loadAccountClaimsSuccess({ accountId, claims }))
            } catch (e) {
                const message = `There was a problem getting claims: ${e.message}`
                yield put(claimsActions.loadAccountClaimsFail(message))
            }
        }
        yield put(claimsActions.loadAccountClaimsComplete())
    },
}

export function* claimsSaga() {
    yield all([takeEvery(homeWarrantyActionTypes.LOAD_ACCOUNT_CLAIMS, sagas.loadClaims as any)])
}

export interface ClaimsState {
    readonly isLoading: boolean
    readonly error?: string
    readonly [key: number]: ReadonlyArray<HomeProtectionAccountClaim>
}

interface UpdateAccountDetailsRequest {
    readonly accountId: number
    readonly claims: ReadonlyArray<HomeProtectionAccountClaim>
}
