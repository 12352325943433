import * as React from 'react'

import styled from 'styled-components'

import { accordionSeparatorColor, fontSizeLarge, headerColor } from '@igs-web/common-ui-components/styles/theme'

import { Expandable, ExpandableToggle } from './expandable'

const StyledExpandIcon = styled.span`
    font-size: 4rem;
    line-height: 1.1;
    color: ${accordionSeparatorColor};
`
const ExpandIcon = ({ isExpanded, ...htmlProps }: Props): JSX.Element => <StyledExpandIcon {...htmlProps}>{isExpanded ? '-' : '+'}</StyledExpandIcon>

interface Props extends Expandable, React.HTMLAttributes<HTMLSpanElement> {}

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: ${headerColor};
    align-items: center;
`
const StyledH3 = styled.h3`
    font-size: ${fontSizeLarge};
`

export const ExpandHeader = ({ isExpanded, children, toggleExpand, dataTestId }: ExpandHeaderProps): JSX.Element => (
    <div data-testid={dataTestId}>
        <StyledHeader onClick={toggleExpand}>
            <StyledH3>{children}</StyledH3>
            <ExpandIcon isExpanded={isExpanded} />
        </StyledHeader>
    </div>
)

export interface ExpandHeaderProps extends ExpandableToggle {
    readonly children: React.ReactNode
    readonly dataTestId: string
}
