import * as React from 'react'

import styled from 'styled-components'

import { Address } from '@igs-web/common-models/models/address'

const AddressInlineBreak = styled.span`
    display: inline-block;
`
const AddressBreak = styled.span`
    display: block;
`

export const AddressDisplay = ({ address, breakingAddress = false, dataTestIdPrefix }: AddressDisplayProps): JSX.Element => {
    if (!address) {
        return <></>
    }

    return (
        <div>
            <div data-testid={`${dataTestIdPrefix}address-display-address`}>
                {!breakingAddress && (
                    <>
                        <AddressInlineBreak>{address.address1}</AddressInlineBreak>{' '}
                        {address.address2 && <AddressInlineBreak>{address.address2}</AddressInlineBreak>}
                    </>
                )}
                {breakingAddress && (
                    <>
                        <AddressBreak>{address.address1}</AddressBreak>
                        {address.address2 && <AddressBreak>{address.address2}</AddressBreak>}
                    </>
                )}
            </div>
            <div data-testid={`${dataTestIdPrefix}address-display-city-state-zip`}>
                {address.city}, {address.state} {address.zipCode}
            </div>
        </div>
    )
}

interface AddressDisplayProps {
    readonly address?: Address
    readonly breakingAddress?: boolean
    readonly dataTestIdPrefix?: string
}
