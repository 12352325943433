import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { getOffers, getRenewalOffers } from '@igs-web/common-utilities/services/offer-retrieval'

import { IOffersActions, offersActionCreators } from './offers-context-actions'
import { initialOffersContextState, OffersContextModel, OffersInContextType, SelectOfferParameters } from './offers-context-models'
import { OffersContextReducer } from './offers-context-reducer'

export const OFFERS_CONTEXT_STORAGE_KEY = 'offersStateId'

const OffersContextState = React.createContext<OffersContextModel | undefined>(undefined)
const OffersContextDispatch = React.createContext<IOffersActions | undefined>(undefined)

export const useOffersContextState = (): OffersContextModel => {
    const context = React.useContext(OffersContextState)
    if (!context) {
        throw Error('Must use useOffersContextState inside of OffersContextProvider')
    }

    return context
}

export const useOffersContextDispatch = (): IOffersActions => {
    const context = React.useContext(OffersContextDispatch)
    if (!context) {
        throw Error('Must use useOffersContextDispatch inside of OffersContextProvider')
    }

    return context
}

export const OffersContextProvider = ({ children, offersContextState }: OffersProviderProps): JSX.Element => {
    const history = useHistory()

    const initialState: OffersContextModel = offersContextState ? { ...initialOffersContextState, ...offersContextState } : { ...initialOffersContextState }

    const [state, dispatch] = React.useReducer(OffersContextReducer, initialState)

    const dispatchActions = {
        loadOffers: async ({
            ignoreCache,
            offersInContextType,
            zipCode,
            accountType,
            campaignCode,
            invitationCode,
            offerId,
            priceEffectiveDate,
            partyAccountId,
            distributionZone,
            config,
        }) => {
            if (offersInContextType === OffersInContextType.Enrollment || offersInContextType === OffersInContextType.RateChange) {
                dispatch(
                    offersActionCreators.loadOffers({
                        ignoreCache,
                        offersInContextType,
                        zipCode,
                        partyAccountId,
                        accountType,
                        campaignCode,
                        invitationCode,
                        offerId,
                        priceEffectiveDate,
                        requestedDateTime: new Date(),
                        distributionZone,
                    }),
                )
                const normalOffers = await getOffers(
                    zipCode,
                    accountType,
                    campaignCode,
                    invitationCode,
                    offerId,
                    priceEffectiveDate,
                    ignoreCache,
                    undefined,
                    distributionZone,
                )
                dispatch(offersActionCreators.setAvailableOffers(normalOffers))
            } else if (partyAccountId) {
                dispatch(
                    offersActionCreators.loadOffers({
                        ignoreCache,
                        offersInContextType: OffersInContextType.Renewal,
                        campaignCode,
                        accountType,
                        zipCode,
                        partyAccountId,
                        requestedDateTime: new Date(),
                        distributionZone,
                    }),
                )

                const renewalOffers = await getRenewalOffers(zipCode, partyAccountId, ignoreCache, config)

                if (renewalOffers.length > 0) {
                    dispatch(offersActionCreators.setAvailableOffers(renewalOffers))
                } else {
                    dispatch(
                        offersActionCreators.loadOffers({
                            ignoreCache,
                            offersInContextType: OffersInContextType.Renewal,
                            zipCode,
                            partyAccountId,
                            accountType,
                            campaignCode,
                            invitationCode,
                            offerId,
                            priceEffectiveDate,
                            requestedDateTime: new Date(),
                            distributionZone,
                        }),
                    )
                    const normalOffers = await getOffers(
                        zipCode,
                        accountType,
                        campaignCode,
                        invitationCode,
                        offerId,
                        priceEffectiveDate,
                        ignoreCache,
                        undefined,
                        distributionZone,
                    )
                    dispatch(offersActionCreators.setAvailableOffers(normalOffers))
                }
            }
        },
        selectOffer: ({ offerId, navigateToRoute }: SelectOfferParameters) => {
            const selectedOffer = offerId ? state.offers.find(o => o.offerId === offerId) ?? null : null
            dispatch(offersActionCreators.selectOffer(selectedOffer))
            if (navigateToRoute) {
                history.push(navigateToRoute)
            }
        },
        deselectOffer: (navigateToRoute?: string) => {
            dispatch(offersActionCreators.deselectOffer())
            if (navigateToRoute) {
                history.push(navigateToRoute)
            }
        },
        clearOfferState: () => {
            dispatch(offersActionCreators.clearOfferState())
        },
    }

    return (
        <OffersContextState.Provider value={state}>
            <OffersContextDispatch.Provider value={dispatchActions}>{children}</OffersContextDispatch.Provider>
        </OffersContextState.Provider>
    )
}

interface OffersProviderProps {
    readonly children: React.ReactNode
    readonly offersContextState?: OffersContextModel
}
