import * as React from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { useOffersContextState } from '@igs-web/common-components/domain/offers/context/offers-context'
import { LineOfBusinessCode } from '@igs-web/common-models/models'
import { OfferModel, ProductTypeCode } from '@igs-web/common-models/models/offer-model'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { RatesTab, RatesTabList, RatesTabPanel, RatesTabs } from '@igs-web/common-ui-components/_atoms/tabs/rates-tabs'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { RateDetailPanel } from '@igs-web/common-ui-components/_molecules/rates/rate-detail-panel'
import { RatesListView } from '@igs-web/common-ui-components/_molecules/rates/rates-list-view'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeLarge } from '@igs-web/common-ui-components/styles/theme'
import { formatPhoneNumberForDisplay } from '@igs-web/common-utilities/utilities/phone-utilities'

const RatesContainer = styled.div`
    padding-inline: ${Spacing.ExtraSmall};
`
export const RatesSectionHeading = styled(Header3)`
    font-size: ${fontSizeLarge};
    margin-block: 0;
`
const DefaultHeroOfferCount = 2

const filterFixedOffers = (offers: ReadonlyArray<OfferModel>, showHeroOnly: boolean) => {
    const fixedOffers = offers.filter(
        offer => offer.primaryProduct.productType === ProductTypeCode.Fixed && offer.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.Gas,
    )
    if (showHeroOnly) {
        return fixedOffers.filter(o => o.primaryProduct.isHeroProduct).length > 0
            ? offers.filter(o => o.primaryProduct.isHeroProduct)
            : offers.slice(0, DefaultHeroOfferCount)
    }
    return fixedOffers
}

export const OfferListView = ({ offers, handleSelectedOffer, showHeroOnly: showHeroOnly = false }: OfferListViewProps) => {
    const { phoneNumber } = useCompany()

    const { selectedOffer, offersAreLoaded: offersLoadedInContext, offersAreLoading: offersAreLoadingInContext } = useOffersContextState()

    const offersLoadedInRedux = useSelector((store: CommonReduxState) => OfferSelectors.selectOffersLoaded(store))
    const offerRequestInRedux = useSelector((store: CommonReduxState) => OfferSelectors.selectOfferRequest(store))
    const selectedOffersInRedux = useSelector((store: CommonReduxState) => OfferSelectors.selectSelectedOffers(store))

    const offersAreLoaded = offersLoadedInContext || offersLoadedInRedux
    const offersAreLoading = offersAreLoadingInContext || !!offerRequestInRedux.isLoading
    const selectedOffers = selectedOffer ? new Array<OfferModel>().fill(selectedOffer) : selectedOffersInRedux

    const fixedOffers = filterFixedOffers(offers, showHeroOnly)
    const variableOffers = offers.filter(
        offer => offer.primaryProduct.productType !== ProductTypeCode.Fixed && offer.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.Gas,
    )

    const showOfferTypeTabs = fixedOffers.length > 0 && variableOffers.length > 0

    return (
        <RatesContainer id="rates-container" data-testid="rates-container">
            {selectedOffers.length > 0 && offers.some(o => selectedOffers.some(s => s.offerId === o.offerId)) && (
                <>
                    <RatesSectionHeading>Selected Rate</RatesSectionHeading>
                    <GridSpacer spacing={Spacing.Small} />
                    <RateDetailPanel
                        offer={selectedOffers[0]}
                        setSelectedOffer={handleSelectedOffer}
                        btnMessage="Sign Up"
                        selectedOffers={selectedOffers}
                        dataTestIdPrefix="selected-offer"
                    />
                    <GridSpacer spacing={Spacing.Medium} />
                </>
            )}
            {showHeroOnly && fixedOffers.length > 0 && (
                <RatesListView
                    offers={fixedOffers}
                    setSelectedOffer={handleSelectedOffer}
                    btnMessage="Sign Up"
                    dataTestIdPrefix="fixed-offer"
                    showCarbonNeutral={true}
                />
            )}
            {!showHeroOnly && (
                <RatesTabs>
                    {showOfferTypeTabs && (
                        <RatesTabList>
                            {fixedOffers.length > 0 && <RatesTab>Fixed Rates</RatesTab>}
                            {variableOffers.length > 0 && <RatesTab>Variable Rates</RatesTab>}
                        </RatesTabList>
                    )}

                    {fixedOffers.length > 0 && (
                        <RatesTabPanel>
                            <RatesListView
                                offers={fixedOffers}
                                setSelectedOffer={handleSelectedOffer}
                                btnMessage="Sign Up"
                                dataTestIdPrefix="fixed-offer"
                                showCarbonNeutral={true}
                            />
                        </RatesTabPanel>
                    )}
                    {variableOffers.length > 0 && (
                        <RatesTabPanel>
                            <RatesListView
                                offers={variableOffers}
                                setSelectedOffer={handleSelectedOffer}
                                btnMessage="Sign Up"
                                dataTestIdPrefix="variable-offer"
                                showCarbonNeutral={true}
                            />
                        </RatesTabPanel>
                    )}
                </RatesTabs>
            )}
            {offersAreLoaded && !offersAreLoading && !fixedOffers.length && !variableOffers.length && (
                <p>
                    Certain rates are not available in all locations. For further information regarding availability, please call{' '}
                    <Link href={`tel:${phoneNumber.customerSupport}`}>{formatPhoneNumberForDisplay(phoneNumber.customerSupport)}</Link>.
                </p>
            )}
        </RatesContainer>
    )
}
interface OfferListViewProps {
    readonly offers: ReadonlyArray<OfferModel>
    readonly handleSelectedOffer: (offer: OfferModel) => void
    readonly showHeroOnly?: boolean
    readonly showCarbonNeutral?: boolean
}
