import { Caption } from '@igs-web/common-ui-components/_atoms/typography'
import * as React from 'react'
import styled from 'styled-components'
import { ContractLengthGraphic } from './contract-length-graphic'

const ContractLengthContainer = styled.div`
    text-align: left;
    width: 100%;
`

export const ContractLength = ({ startDateTicks, endDateTicks }: ContractLengthProps): JSX.Element => (
    <ContractLengthContainer>
        <Caption>Term:</Caption>
        <ContractLengthGraphic startDateTicks={startDateTicks} endDateTicks={endDateTicks} />
    </ContractLengthContainer>
)
interface ContractLengthProps {
    readonly startDateTicks: number
    readonly endDateTicks: number
}
