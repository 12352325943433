import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeSmall } from '@igs-web/common-ui-components/styles/theme'
import { Checkbox } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { Layout2fr1frColumn } from '@igs-web/common-ui-components/_molecules/grid-layout'
import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const CheckboxDescription = styled.p`
    font-size: ${fontSizeSmall};
    margin-left: ${Spacing.ExtraLarge};
`

export const EmailMarketingCheckbox = ({ formKey, idPrefix, isEmailMarketable }: EmailMarketingProps) => {
    const { register } = useFormContext()

    return (
        <div>
            <Layout2fr1frColumn>
                <Checkbox
                    {...register(`${formKey}isEmailMarketable`, { shouldUnregister: true })}
                    dataTestId={`${idPrefix}is-email-marketable`}
                    defaultChecked={isEmailMarketable}
                    label="Sign up for email communications."
                />
            </Layout2fr1frColumn>
            <Layout2fr1frColumn>
                <CheckboxDescription>
                    Sign up for useful articles and information created and curated for you by our energy experts. Also, we’ll be sure to keep you posted on
                    great promotions and new products.*
                </CheckboxDescription>
            </Layout2fr1frColumn>
        </div>
    )
}

interface EmailMarketingProps {
    readonly formKey: string
    readonly idPrefix: string
    readonly isEmailMarketable: boolean
}
