import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { OrganizationAgent } from '@igs-web/common-models/models/organization-agent-model'
import { EmailLink } from '@igs-web/common-ui-components/_atoms/link/email-link'
import { PhoneNumberLink } from '@igs-web/common-ui-components/_atoms/link/link-phone-number'
import { LocalLoader } from '@igs-web/common-ui-components/_molecules/loading-indicator'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'

import { AccountDetailsCard } from '../../account-details/account-details-card/account-details-card'
import { AccountDetailsCardBody } from '../../account-details/account-details-card/account-details-card-body'
import { AccountDetailsCardHeader } from '../../account-details/account-details-card/account-details-card-header'
import { CommonReduxState } from '../../common-redux'
import { useCompany } from '../../company/hooks/useCompany'
import { useMyAccountDashboard } from '../dashboard-context'
import { InfoColumn, InfoHeaderStyle } from '../myaccount-components'
import { buildOrganizationAgentKey, organizationAgentRedux } from './organization-agent-redux'

const SalesAndServicesBody = styled(AccountDetailsCardBody)`
    grid-template-columns: 1fr 1fr;
    background: var(--stripe-color-1, var(--light-accent));

    ${InfoColumn} {
        place-self: start;
    }
    &:has(.loading-container) {
        padding: 0;
    }
    .loading-container {
        grid-column: -1 / 1;
    }

    @media (max-width: ${Breakpoint.MobileSmall}) {
        grid-template-columns: 1fr;
    }
`

export const SalesAndServiceDisplay = ({ agent, isLoading }: Props) => {
    const company = useCompany()

    return (
        <AccountDetailsCard data-testid="sales-and-service-component">
            <AccountDetailsCardHeader>Sales and Service</AccountDetailsCardHeader>
            <SalesAndServicesBody>
                {isLoading && <LocalLoader isLoading={isLoading} />}
                {!isLoading && (
                    <>
                        <InfoColumn>
                            {agent && (
                                <>
                                    <InfoHeaderStyle>For rate and contract questions contact:</InfoHeaderStyle>
                                    <div>
                                        {agent.name}
                                        <br />
                                        <EmailLink toAddress={agent.emailAddress} />
                                        <br />
                                        <PhoneNumberLink phoneNumber={agent.phoneNumber} />
                                    </div>
                                </>
                            )}
                        </InfoColumn>

                        <InfoColumn>
                            <InfoHeaderStyle>For other service requests contact:</InfoHeaderStyle>
                            <div>
                                {company.name} Customer Service
                                <br />
                                <PhoneNumberLink phoneNumber={company.phoneNumber.customerSupport} />
                            </div>
                        </InfoColumn>
                    </>
                )}
            </SalesAndServicesBody>
        </AccountDetailsCard>
    )
}

interface Props {
    readonly agent?: OrganizationAgent
    readonly isLoading: boolean
}

export const SalesAndServices = () => {
    const dispatch = useDispatch()
    const { profile } = useMyAccountDashboard()
    const agents = useSelector((state: CommonReduxState) => organizationAgentRedux.selectors.selectData(state, profile.userProfileId))
    const isLoading = useSelector((state: CommonReduxState) => organizationAgentRedux.selectors.selectLoading(state, profile.userProfileId))
    React.useEffect(() => {
        if (!agents) {
            const partyIds = profile.accounts.map(a => a.partyId)

            dispatch(organizationAgentRedux.actions.load({ key: buildOrganizationAgentKey(profile.userProfileId, partyIds), showGlobalLoader: false }))
        }
    }, [profile, agents])

    const agent = agents && agents.length ? agents[0] : undefined
    return <SalesAndServiceDisplay agent={agent} isLoading={!!isLoading} />
}
