import * as React from 'react'

import { SvgIcon, SvgIconProps } from './svg-icon'

/* eslint-disable max-len */

const DollarSignCircleIcon = ({ altText = 'Dollar Sign', ...svgIconProps }: SvgIconProps) => (
    <SvgIcon {...svgIconProps} height="32" width="32" version="1.1" viewBox="0 0 32 32" altText={altText}>
        <path d="M16.888 22.205v2.063h-1.983v-1.924c-1.262-0.036-2.442-0.355-3.489-0.895l0.045 0.021 0.609-2.379c0.965 0.549 2.115 0.881 3.342 0.903l0.006 0c1.16 0 1.946-0.448 1.946-1.256s-0.646-1.256-2.144-1.762c-2.203-0.734-3.671-1.748-3.671-3.708 0-1.784 1.256-3.187 3.422-3.613v-1.924h1.99v1.814c1.069 0.017 2.076 0.261 2.982 0.687l-0.044-0.019-0.551 2.269c-0.857-0.433-1.867-0.687-2.936-0.69h-0.001c-1.314 0-1.74 0.565-1.74 1.138s0.734 1.094 2.43 1.74c2.416 0.852 3.385 1.968 3.385 3.789s-1.263 3.341-3.598 3.745zM15.985 5.14c-5.994 0-10.853 4.859-10.853 10.853s4.859 10.853 10.853 10.853c5.991 0 10.849-4.855 10.853-10.845v-0c0-0.002 0-0.005 0-0.007 0-5.994-4.859-10.853-10.853-10.853v0z" />
        <path d="M15.985 0c-0.007 0-0.014 0-0.022 0-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16v0c0-0.002 0-0.005 0-0.007 0-8.827-7.152-15.984-15.977-15.993h-0.001zM15.985 30.458c-0.004 0-0.010 0-0.015 0-7.985 0-14.458-6.473-14.458-14.458s6.473-14.458 14.458-14.458c7.985 0 14.458 6.473 14.458 14.458v0c0 0.004 0 0.010 0 0.015 0 7.977-6.466 14.443-14.443 14.443v0z" />
    </SvgIcon>
)
export default DollarSignCircleIcon
