import * as React from 'react'
import { BsTagFill } from 'react-icons/bs'

import { CheckoutStepPageIds } from '@igs-web/common-components/domain/checkout/checkout-step-page-ids'
import { FullStory } from '@igs-web/common-models/constants/full-story'
import { Address } from '@igs-web/common-models/models/address'
import { PaymentMethodModel } from '@igs-web/common-models/models/payment-model'
import { Card } from '@igs-web/common-ui-components/_atoms/card'
import { IconHeading } from '@igs-web/common-ui-components/_atoms/icon-heading'
import { AddressSummary } from '@igs-web/common-ui-components/_molecules/address/address-summary'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { useWizardDispatch, useWizardState } from '@igs-web/common-ui-components/_molecules/wizard/wizard-context'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { getLongPaymentDescription } from '@igs-web/common-utilities/services/payment-info-service'

import { EditHeading } from '../shared/checkout-components'

export const PaymentCard = ({ payment, isBillingSameAsServiceAddress, billingAddress }: PaymentCardProps) => {
    const { activePageId } = useWizardState()
    const { moveToSubPage } = useWizardDispatch()
    const paymentDescription = payment ? getLongPaymentDescription(payment) : ''

    return (
        <PaymentCardDisplay
            paymentDescription={paymentDescription}
            isBillingSameAsServiceAddress={isBillingSameAsServiceAddress}
            billingAddress={billingAddress}
            paymentType={payment?.paymentType}
            header={
                <EditHeading
                    onClick={() => moveToSubPage(CheckoutStepPageIds.DirectBillPreference, '')}
                    hideEdit={activePageId === CheckoutStepPageIds.DirectBillPreference}
                    data-testid="payment-card-edit-button"
                >
                    <BsTagFill /> Payment Method
                </EditHeading>
            }
        />
    )
}

export const PaymentCardDisplay = ({ header, paymentDescription, isBillingSameAsServiceAddress, billingAddress, paymentType }: Props) => {
    return (
        <Card
            header={
                header ? (
                    header
                ) : (
                    <IconHeading>
                        <BsTagFill /> Payment Method
                    </IconHeading>
                )
            }
        >
            <strong data-testid="payment-card-payment-type">{paymentType}</strong>
            {paymentDescription && (
                <>
                    <div className={FullStory.Exclude} data-testid="payment-card-payment-description">
                        {paymentDescription}
                    </div>
                </>
            )}
            <GridSpacer spacing={Spacing.ExtraLarge} />
            {isBillingSameAsServiceAddress ? (
                <div data-testid="payment-card-billing-address-is-same">Billing address is the same as service address</div>
            ) : (
                <AddressSummary title="Billing Address" address={billingAddress} dataTestIdPrefix="payment-card-billing-address" />
            )}
        </Card>
    )
}
interface PaymentCardProps {
    readonly payment?: PaymentMethodModel
    readonly isBillingSameAsServiceAddress?: boolean
    readonly billingAddress?: Address
}

interface Props {
    readonly paymentDescription?: string
    readonly paymentType?: string
    readonly isBillingSameAsServiceAddress?: boolean
    readonly billingAddress?: Address
    readonly header?: React.ReactNode
}
