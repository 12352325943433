import * as React from 'react'
import { UseFormRegister } from 'react-hook-form'

import { withHookFormMask } from 'use-mask-input'

import { UtilityLineOfBusinessModel } from '@igs-web/common-models/models'
import { Input } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { isSpecialCaseAccountNumberMask, stripQuestionMarksFromAccountNumberMask } from '@igs-web/common-utilities/services/account-number-service'

export const AccountNumberField = ({ ulob, idPrefix, accountNumber = '', accountNumberFieldError, accountNumberField, register }: AccountNumberProps) => {
    const isSpecialCaseMask = isSpecialCaseAccountNumberMask(ulob.utilityCode)

    return (
        <Input
            {...withHookFormMask(
                register(accountNumberField, {
                    required: 'Required',
                    pattern: {
                        value: new RegExp(ulob.accountNumberRegex),
                        message: 'The number you entered is not in the correct format',
                    },
                    shouldUnregister: true,
                    setValueAs: v => v.replaceAll('_', ''),
                }),
                isSpecialCaseMask ? '' : stripQuestionMarksFromAccountNumberMask(ulob.accountNumberFormat),
                {
                    regex: isSpecialCaseMask ? ulob.accountNumberRegex : undefined,
                    placeholder: '_',
                },
            )}
            dataTestId={`${idPrefix}-accountNumber`}
            label={ulob?.accountNumberLabel ?? 'Account Number'}
            error={accountNumberFieldError}
            hideHelperText={!accountNumberFieldError}
            defaultValue={accountNumber}
            name={accountNumberField}
        />
    )
}

interface AccountNumberProps {
    readonly ulob: UtilityLineOfBusinessModel
    readonly idPrefix: string
    readonly accountNumber: string
    readonly accountNumberFieldError: any
    readonly accountNumberField: string
    readonly register: UseFormRegister<any>
}
