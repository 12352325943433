import * as React from 'react'
import styled from 'styled-components'

import { PartySelector } from '@igs-web/common-components/domain/party/party-selector'
import { PhoneType, phoneTypes } from '@igs-web/common-models/constants/phone'
import { PartyPhoneContact, PartyPhoneContactResponse } from '@igs-web/common-models/models/phone-contact-response'
import { ErrorAlert } from '@igs-web/common-ui-components/_atoms/forms/alert'
import { Input, Select } from '@igs-web/common-ui-components/_atoms/web-framework-inputs'
import { EditableGrid, useEditableGridDispatch } from '@igs-web/common-ui-components/_molecules/editable-grid'
import { error } from '@igs-web/common-ui-components/styles/theme'
import { phoneContactApiClient } from '@igs-web/common-utilities/api/phone-contact-api-client'
import { formatPhoneNumberForDisplay, isPhoneNumber } from '@igs-web/common-utilities/utilities/phone-utilities'
import { Checkbox, SelectOption } from '@igs/react-styled-components'

import { useMyProfile } from './myprofile-context'

const PhoneTypeDisplay = styled.p`
    text-transform: capitalize;
`

const defaultPhoneContact: PartyPhoneContact = {
    phoneNumber: '',
    phoneType: PhoneType.Home,
    isPrimary: false,
}

const StyledInput = styled(Input)`
    span {
        color: ${error};
    }
`

const StyledSelect = styled(Select)`
    span {
        color: ${error};
    }
    option:empty {
        display: none;
    }
`

const DisplayRow = ({ phoneType, phoneNumber, isPrimary }: PartyPhoneContact) => {
    return (
        <>
            <PhoneTypeDisplay>{phoneType.toLowerCase()}</PhoneTypeDisplay>
            <p>{formatPhoneNumberForDisplay(phoneNumber)}</p>
            <Checkbox dataTestId={`edit-isprimary${phoneNumber}`} disabled={true} checked={isPrimary} type="checkbox" />
        </>
    )
}

const EditRow = ({ phoneType, phoneNumber, isPrimary, phoneContactId, setData }: EditRowProps) => {
    const [formState, setFormState] = React.useState<FormState>({ phoneType, phoneNumber, isPrimary, phoneContactId })
    const { phoneType: phoneTypeInput, phoneNumber: phoneNumberInput, isPrimary: isPrimaryInput, phoneTypeError, phoneNumberError } = formState
    const { setIsValid } = useEditableGridDispatch()

    React.useEffect(() => {
        setData({ phoneType: phoneTypeInput, phoneNumber: phoneNumberInput, isPrimary: isPrimaryInput, phoneContactId })
    }, [phoneTypeInput, phoneNumberInput, isPrimaryInput, phoneContactId, setData])

    React.useEffect(() => {
        if (formState.phoneType && formState.phoneNumber) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [formState])

    return (
        <>
            <StyledSelect
                defaultValue={phoneType}
                dataTestId={'change-phone-type'}
                onChange={e => {
                    if (e.currentTarget.value !== '') {
                        setFormState({ ...formState, phoneType: PhoneType[e.currentTarget.value], phoneTypeError: undefined })
                    } else {
                        setFormState({ ...formState, phoneTypeError: 'Select a phone type' })
                    }
                }}
                helperText={phoneTypeError ? 'Select a phone type' : ''}
            >
                {phoneTypes.map(type => {
                    return (
                        <SelectOption key={type} value={type} dataTestId={`change-phone-type${type}`}>
                            {type}
                        </SelectOption>
                    )
                })}
            </StyledSelect>
            <StyledInput
                dataTestId="edit-phone-number"
                type="tel"
                defaultValue={phoneNumber ?? ''}
                maxLength={10}
                onChange={e => {
                    const value = e.currentTarget.value
                    const isValidPhoneNumber = isPhoneNumber(value)
                    if (!isValidPhoneNumber) {
                        setFormState({ ...formState, phoneNumberError: 'Invalid phone number' })
                    } else {
                        setFormState({ ...formState, phoneNumber: value, phoneNumberError: undefined })
                    }
                }}
                helperText={phoneNumberError}
            />
            <Checkbox
                dataTestId={`edit-isprimary${phoneNumber}`}
                defaultChecked={isPrimary}
                type="checkbox"
                onChange={e => {
                    setFormState({ ...formState, isPrimary: e.currentTarget.checked })
                }}
            />
        </>
    )
}

interface EditRowProps extends PartyPhoneContact {
    readonly setData: (partyPhoneContact: PartyPhoneContact) => void
}

interface FormState extends PartyPhoneContact {
    readonly phoneNumberError?: string
    readonly phoneTypeError?: string
}

export const ChangePhoneSection = () => {
    const [partyPhoneContactsState, setPartyPhoneContactsState] = React.useState<ReadonlyArray<PartyPhoneContactResponse> | null>(null)
    const [selectedPartyNumber, setSelectedPartyNumber] = React.useState<string | undefined>(undefined)
    const [selectedParty, setSelectedParty] = React.useState<PartyPhoneContactResponse | null>(null)
    const [phoneContacts, setPhoneContacts] = React.useState<ReadonlyArray<PartyPhoneContact>>([])
    const [requestError, setRequestError] = React.useState<string>()

    const { partyPhoneContacts, getPartyPhoneContacts } = useMyProfile()
    const setPartyPhoneContacts = React.useCallback(async () => {
        if (partyPhoneContacts !== null && partyPhoneContacts !== undefined) {
            setPartyPhoneContactsState(partyPhoneContacts)
            if (partyPhoneContacts.length === 1) {
                setSelectedPartyNumber(partyPhoneContacts[0].partyNumber)
                setPhoneContacts(partyPhoneContacts[0].myAccountPartyPhoneContacts)
            }
            if (partyPhoneContacts.some(pcr => pcr.partyNumber === selectedPartyNumber)) {
                setPhoneContacts(partyPhoneContacts.filter(pcr => pcr.partyNumber === selectedPartyNumber)[0].myAccountPartyPhoneContacts)
            }
        }
    }, [partyPhoneContacts, selectedPartyNumber])

    React.useEffect(() => {
        setPartyPhoneContacts()
    }, [partyPhoneContacts, setPartyPhoneContacts])

    React.useEffect(() => {
        const party = partyPhoneContacts?.find(ppc => ppc.partyNumber === selectedPartyNumber)
        if (party) {
            setSelectedParty(party)
            setPhoneContacts(party.myAccountPartyPhoneContacts)
        }
    }, [selectedPartyNumber, partyPhoneContacts])

    const headers = ['Type', 'Phone', 'Primary']

    const handleSave = async (partyPhoneContact: PartyPhoneContact) => {
        const { phoneContactId, phoneNumber, phoneType, isPrimary } = partyPhoneContact
        if (!selectedParty) {
            return
        }

        if (phoneContactId) {
            try {
                await phoneContactApiClient.updatePartyPhoneContact({
                    partyNumber: selectedParty.partyNumber,
                    phoneContactId,
                    phoneNumber,
                    phoneType,
                    isPrimary,
                })
                setRequestError(undefined)
                getPartyPhoneContacts()
            } catch (e) {
                setRequestError(e.message)
            }
        } else {
            try {
                phoneContactApiClient.createPartyPhoneContact({
                    partyNumber: selectedParty.partyNumber,
                    phoneNumber,
                    phoneType,
                    isPrimary,
                })
                setRequestError(undefined)
                getPartyPhoneContacts()
            } catch (e) {
                setRequestError(e.message)
            }
        }
    }

    const handleDelete = async (partyPhoneContact: PartyPhoneContact) => {
        if (!selectedParty || !partyPhoneContact.phoneContactId || partyPhoneContact.phoneContactId < 1) {
            setPhoneContacts(phoneContacts.filter(pc => pc.phoneContactId !== partyPhoneContact.phoneContactId))
            return
        }
        try {
            await phoneContactApiClient.deletePartyPhoneContact({ partyNumber: selectedParty.partyNumber, phoneContactId: partyPhoneContact.phoneContactId })
            setPhoneContacts(phoneContacts.filter(pc => pc.phoneContactId !== partyPhoneContact.phoneContactId))
            setRequestError(undefined)
            getPartyPhoneContacts()
        } catch (e) {
            setRequestError(e.message)
        }
    }

    const handleAdd = () => {
        const minIndex = Math.min(...phoneContacts.map(x => x.phoneContactId || 0))
        setPhoneContacts([...phoneContacts, { ...defaultPhoneContact, phoneContactId: minIndex < 0 ? minIndex - 1 : -1 }])
    }

    const noDataMessage = 'No phone contacts found for this account.'

    return (
        <>
            {partyPhoneContactsState !== null && partyPhoneContactsState.length > 1 && (
                <PartySelector parties={partyPhoneContactsState} setSelectedPartyNumber={setSelectedPartyNumber} />
            )}
            {requestError && <ErrorAlert message={requestError} />}
            {selectedParty && (
                <EditableGrid<PartyPhoneContact>
                    headers={headers}
                    data={phoneContacts}
                    DisplayRow={DisplayRow}
                    EditRow={EditRow}
                    handleSave={handleSave}
                    handleDelete={handleDelete}
                    handleAdd={handleAdd}
                    accessor={'phoneContactId'}
                    noDataMessage={noDataMessage}
                />
            )}
        </>
    )
}
